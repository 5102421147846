import React, { useState } from "react";
import userIcon from "../../assets/icons/usericon1.svg";
import searchCloseIcon from "../../assets/icons/icon-modal-close.svg";
import iconSearch from "../../assets/icons/icon-search.svg";
import ToggleHeaderNew from "./ToggleHeaderNew";
import { useHistory } from "react-router-dom";

const ChannelHeaderNew = ({
  title = "",
  HeaderIcon = () => <></>,
  showToggle = false,
  addReminderBtn = false,
  setIsActive,
  isActive,
  hideText = false,
  isBoxScreen = false,
  isFilterClicked,
  setIsFilterClicked,
}) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  let history = useHistory();

  const nextScreen = () => {
    history.push("/dashboard-facebook");
  };
  return (
    <div
      className="dashboard__main__header"
      style={{ padding: "20px 25px 20px 40px" }}
    >
      <div className="flex items-center dashboard__main__header__section-left">
        <HeaderIcon />
        <div className="ml-5">
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
      </div>
      {showToggle && (
        <ToggleHeaderNew setIsActive={setIsActive} isActive={isActive} />
      )}
      <div className="flex items-center dashboard__main__header__section-right mr-4">
        <div
          className={`search-container ${showSearchInput ? "is-open" : ""
            } pr-3`}
        >
          <div className="relative search-input-wrapper-sm">
            <input
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              placeholder="Search...."
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
              className="search-input input-field input-field--md input-field--icon-search"
            />
            <div
              className={`dropdown-menu dropdown-menu--with-border py-4 ${searchInputValue.length >= 1 && "open"
                }`}
              style={{
                top: "calc(100% + 4px)",
                maxHeight: 250,
                overflowY: "auto",
              }}
            >
              <p className="text-13 text-grey-600 px-3">Searching...</p>
              <div className="dropdown-menu__list">
                <img src={iconSearch} alt="file" className="mr-3" />
                alexfoster_dp@gmail.com
              </div>
              <div className="dropdown-menu__list">
                <img src={iconSearch} alt="file" className="mr-3" />
                alexfoster_dp@gmail.com
              </div>
              <div className="dropdown-menu__list">
                <img src={iconSearch} alt="file" className="mr-3" />
                alexfoster_dp@gmail.com
              </div>
              <div className="dropdown-menu__list">
                <img src={iconSearch} alt="file" className="mr-3" />
                alexfoster_dp@gmail.com
              </div>
            </div>
          </div>
          {!addReminderBtn && (
            <figure className="flex items-center" style={{ height: 40 }}>
              <img
                onClick={() => setShowSearchInput(true)}
                src={iconSearch}
                alt="icon-search"
                className="search-icon ml-5"
              />
            </figure>
          )}
          <img
            src={searchCloseIcon}
            alt=""
            onClick={() => setShowSearchInput(false)}
            className="search-close-icon ml-5"
          />
        </div>
        <div
          className="bg-grey-400 ml-1 mr-4"
          style={{ height: 26, width: 1 }}
        ></div>
        <div className="flex items-center cursor-pointer">
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.14798 1.57141C7.84835 1.57141 8.44085 2.08573 8.53469 2.77513L8.66657 3.74397L10.0373 4.53749L11.0511 4.12932C11.6979 3.86892 12.4389 4.12172 12.7882 4.7219L12.8834 4.88539C13.2377 5.49421 13.0784 6.26994 12.5124 6.6923L11.6904 7.3056L11.6201 8.83459L12.434 9.44272C12.9996 9.86522 13.1585 10.6406 12.8044 11.2492L12.7092 11.4128C12.36 12.013 11.619 12.2658 10.9723 12.0055L9.96146 11.5988L8.66657 12.2775L8.53608 13.2267C8.44141 13.9153 7.84928 14.4286 7.14961 14.4286H6.85344C6.15377 14.4286 5.56164 13.9153 5.46697 13.2267L5.33648 12.2775L4.04609 11.5875L3.0323 11.9957C2.3855 12.2561 1.64441 12.0033 1.29515 11.4031L1.1955 11.2319C0.843151 10.6264 0.998601 9.85513 1.55847 9.43104L2.28908 8.87762L2.20915 7.25637L1.4793 6.70345C0.919478 6.27935 0.764062 5.50813 1.1164 4.90266L1.21589 4.73168C1.56508 4.13163 2.30595 3.87878 2.95268 4.13895L3.96224 4.54508L5.33648 3.60694L5.45911 2.76284C5.55846 2.07896 6.14847 1.57141 6.84409 1.57141H7.14798Z"
              stroke="#4C5254"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M7.00007 9.78603C7.98629 9.78603 8.78578 8.98654 8.78578 8.00031C8.78578 7.01409 7.98629 6.2146 7.00007 6.2146C6.01385 6.2146 5.21436 7.01409 5.21436 8.00031C5.21436 8.98654 6.01385 9.78603 7.00007 9.78603Z"
              stroke="#4C5254"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </svg>
          <p className="text-13 font-medium ml-1" onClick={nextScreen} >Channels</p>
        </div>
        {isBoxScreen && (
          <div className="flex ml-3 gap-1">
            <button
              className={`btn btn--xs gap-1 ${isFilterClicked ? "bg-grey-90" : "border border-basic-button"
                }`}
              onClick={() => setIsFilterClicked(!isFilterClicked)}
            >
              {isFilterClicked ? (
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.875 7.01299C7.28921 7.01299 7.625 7.36186 7.625 7.79221C7.625 8.19182 7.33547 8.52117 6.96247 8.56619L6.875 8.57143H5.125C4.71079 8.57143 4.375 8.22256 4.375 7.79221C4.375 7.39259 4.66453 7.06324 5.03753 7.01823L5.125 7.01299H6.875ZM9.5 3.50649C9.91421 3.50649 10.25 3.85536 10.25 4.28571C10.25 4.68533 9.96047 5.01468 9.58747 5.05969L9.5 5.06493H2.5C2.08579 5.06493 1.75 4.71607 1.75 4.28571C1.75 3.8861 2.03953 3.55675 2.41253 3.51174L2.5 3.50649H9.5ZM11.25 0C11.6642 0 12 0.348869 12 0.779221C12 1.17883 11.7105 1.50819 11.3375 1.5532L11.25 1.55844H0.75C0.335786 1.55844 0 1.20957 0 0.779221C0 0.379609 0.28953 0.050254 0.662534 0.00524221L0.75 0H11.25Z"
                    fill="#005C6F"
                  />
                </svg>
              ) : (
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.875 7.01299C7.28921 7.01299 7.625 7.36186 7.625 7.79221C7.625 8.19182 7.33547 8.52117 6.96247 8.56619L6.875 8.57143H5.125C4.71079 8.57143 4.375 8.22256 4.375 7.79221C4.375 7.39259 4.66453 7.06324 5.03753 7.01823L5.125 7.01299H6.875ZM9.5 3.50649C9.91421 3.50649 10.25 3.85536 10.25 4.28571C10.25 4.68533 9.96047 5.01468 9.58747 5.05969L9.5 5.06493H2.5C2.08579 5.06493 1.75 4.71607 1.75 4.28571C1.75 3.8861 2.03953 3.55675 2.41253 3.51174L2.5 3.50649H9.5ZM11.25 0C11.6642 0 12 0.348869 12 0.779221C12 1.17883 11.7105 1.50819 11.3375 1.5532L11.25 1.55844H0.75C0.335786 1.55844 0 1.20957 0 0.779221C0 0.379609 0.28953 0.050254 0.662534 0.00524221L0.75 0H11.25Z"
                    fill="#636566"
                  />
                </svg>
              )}

              <span className={`text-13 font-medium ${isFilterClicked ? 'text-primary' : ''}`}>Filter</span>
            </button>
            <button className="btn btn--primary btn--xs gap-1">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1V9"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M9 5L1 5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
              <span className="text-13 font-medium">Enquiry</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChannelHeaderNew;
