import React, { useState } from 'react'
import user1Icon from '../assets/icons/icon-avatar-image-1.svg';
import user2Icon from '../assets/icons/icon-avatar-image-2.svg';
import user3Icon from '../assets/icons/icon-avatar-image-3.svg';
import user4Icon from '../assets/icons/icon-avatar-image-4.svg';
import movedToIcon from '../assets/icons/icon-moved-to.svg';
import instaIcon from '../assets/icons/icon-instaIcon.svg';
import notesIcon from '../assets/icons/icon-noteIcon.svg';


const History = ({isContactPage}) => {
	const [showFilter, setShowFilter] = useState(false)
	const DropdownStyle = isContactPage ? {top: 30} : {};
	return (
		<div className={`${isContactPage ? 'h-full pb-10' : '' } overflow-y-auto`}>
			<div className={`${isContactPage ? 'px-10 mt-8' : 'history'} flex justify-between items-center`}>
				<div className={`${isContactPage ? 'text-lg' : 'text-15'} font-semibold`}>All History</div>
				<div className='relative'>
					{isContactPage ? 
						<button className='border border-grey-400 rounded-md flex text-13 px-3 py-1 text-primary font-medium items-center' onClick={() => setShowFilter(!showFilter)}>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.66699 4.88281H13.3337" stroke="#005C6F" stroke-width="1.5" stroke-linecap="round" />
								<circle cx="10.2225" cy="4.88628" r="1.47222" fill="white" stroke="#005C6F" stroke-width="1.5" />
								<path d="M2.66699 11.1016H13.3337" stroke="#005C6F" stroke-width="1.5" stroke-linecap="round" />
								<circle cx="5.77813" cy="11.105" r="1.47222" fill="white" stroke="#005C6F" stroke-width="1.5" />
							</svg>
							Filter
						</button> : 
					<div className='text-13 font-medium text-primary cursor-pointer' onClick={() => setShowFilter(!showFilter)}>Filter</div>}
					<div className={`dropdown-menu dropdown-menu--filter ${showFilter ? 'open' : ''}`} style={DropdownStyle}>
						<div className="dropdown-menu__list">All history</div>
						<div className="dropdown-menu__list">Notes</div>
						<div className="dropdown-menu__list selected">Reminders</div>
						<div className="dropdown-menu__list">New enquiries</div>
						<div className="dropdown-menu__list">Replies received</div>
						<div className="dropdown-menu__list">Replies sent</div>
						<div className="dropdown-menu__list">Assigned to</div>
						<div className="dropdown-menu__list">Moved to</div>
					</div>
				</div>
			</div>
			<div className='history__body'>
				<div className='flex'>
					<div>
						<figure
							className="avatar-container avatar-container--contain"
							style={{ width: 31, height: 28 }}
						>
							<img src={user1Icon} alt='' style={{width: 28, height: 28}}/>
							<img src={instaIcon} alt='insta-icon' className="absolute top-0 right-0" style={{width: 12, height: 12}}/>
						</figure>
					</div>
					<div className='history__right-side border br-newEnquiry ml-2'>
						<div className='flex justify-between history__new-enquiry'>
							<div className='text-xs font-semibold'>New Enquiry</div>
							<div className='text-grey-700 text-xs'>now</div>
						</div>
						<div className='py-3 pl-4 text-13'>Initial enquiry made via <span className='font-semibold'>Hunley Weddings</span> by <span className='font-semibold'>Lisa Fotios</span>.</div>
					</div>
				</div>
				<div className='flex'>
					<div>
						<figure
							className="avatar-container avatar-container--contain"
							style={{ width: 31, height: 28 }}
						>
							<img src={user2Icon} alt='' style={{ width: 28, height: 28 }} />
							<img src={instaIcon} alt='insta-icon' className="absolute top-0 right-0" style={{ width: 12, height: 12 }} />
						</figure>
					</div>
					<div className='history__right-side border br-reply ml-2'>
						<div className='flex justify-between history__reply-recieved'>
							<div className='text-xs font-semibold'>Reply received</div>
							<div className='text-grey-700 text-xs'>2m</div>
						</div>
						<div className='py-3 pl-4 text-13'>Reply received from <span className='font-semibold'>Charles Wundeng</span> 19h-27m-10s after initial enquiry.</div>
					</div>
				</div>
				<div className='flex'>
					<div>
					<figure
							className="avatar-container avatar-container--contain"
							style={{ width: 31, height: 28 }}
						>
							<img src={user3Icon} alt='' style={{ width: 28, height: 28 }} />
							<img src={notesIcon} alt='insta-icon' className="absolute top-0 right-0" style={{ width: 12, height: 12 }} />
						</figure>
					</div>
					<div className='history__right-side border br-note ml-2'>
						<div className='flex justify-between history__note'>
							<div className='text-xs font-semibold'>Note</div>
							<div className='text-grey-700 text-xs'>5m</div>
						</div>
						<div className='py-3 pl-4 text-13'>Need to follow up with customer <span className='font-semibold'>@Lisa Fotios</span> Can you take this?</div>
					</div>
				</div>
				<div className='flex'>
					<div>
						<figure
							className="avatar-container avatar-container--contain"
							style={{ width: 31, height: 28 }}
						>
							<img src={user4Icon} alt='' style={{ width: 28, height: 28 }} />
							<img src={instaIcon} alt='insta-icon' className="absolute top-0 right-0" style={{ width: 12, height: 12 }} />
						</figure>
					</div>
					<div className='history__right-side border br-reminder ml-2'>
						<div className='flex justify-between history__reminder'>
							<div className='text-xs font-semibold'>Reminder</div>
							<div className='text-grey-700 text-xs'>3d</div>
						</div>
						<div className='pt-10 pl-15 text-xs font-medium'>Monday morning, May 5, 09:00am</div>
						<div className='pt-1 pl-15 text-13 pd-10'>Call Lisa about the wedding proposal</div>
					</div>
				</div>
				<div className='flex'>
					<div>
						<figure
							className="avatar-container avatar-container--contain"
							style={{ width: 31, height: 28 }}
						>
							<img src={user2Icon} alt='' style={{ width: 28, height: 28 }} />
							<img src={instaIcon} alt='insta-icon' className="absolute top-0 right-0" style={{ width: 12, height: 12 }} />
						</figure>
					</div>
					<div className='history__right-side border br-assign ml-2'>
						<div className='flex justify-between history__assignTo'>
							<div className='text-xs font-semibold'>Assigned to</div>
							<div className='text-grey-700 text-xs'>2m</div>
						</div>
						<div className='py-3 pl-4 text-13'>Enquiry assigned to <span className='font-semibold'>Charles Wundeng</span> by <span className='font-semibold'>Alex Foster</span></div>
					</div>
				</div>
				<div className='flex'>
					<div>
						<figure
							className="avatar-container avatar-container--contain"
							style={{ width: 31, height: 28 }}
						>
							<img src={user1Icon} alt='' style={{ width: 28, height: 28 }} />
							<img src={instaIcon} alt='insta-icon' className="absolute top-0 right-0" style={{ width: 12, height: 12 }} />
						</figure>
					</div>
					<div className='history__right-side border br-moved ml-2'>
						<div className='flex justify-between history__movedTo'>
							<div className='text-xs font-semibold'>Moved to</div>
							<div className='text-grey-700 text-xs'>now</div>
						</div>
						<div className='pt-10 pl-15 text-13'>Enquiry moved to the Sales Box by <span className='font-semibold'>Lisa Fotios</span> and note added</div>
						<button className='btn btn--history bg-yellow-200 border br-note mt-1 ml-15 mb-3'>
							<img src={movedToIcon} alt='' />
							<span className='text-11 ml-1 text-grey-700'>Here’s som...</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default History;