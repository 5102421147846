import React from "react";

const DashboardListProfiles = ({ lists }) => {
  return (
    <div>
      <ul className="mt-4">
        {lists.map((item, index) => (
          <li className="teams-avatar-list flex items-center" key={index}>
            <figure
              className="avatar-container avatar-container--full"
              style={{ width: 32, height: 32 }}
            >
              <img src={item.profilePic} alt="avatar-pic" />
            </figure>
            <div className="ml-3 text-sm font-medium">{item.name}</div>
            {!item.isInvited && (
              <div className="ml-auto hover-visible">
                <span className="text-sm font-semibold text-primary">
                  Invite
                </span>
              </div>
            )}
            {item.isInvited && (
              <div className="flex items-center ml-auto gap-2">
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                  <path
                    d="M1 5.37637L4.13194 9L11 1"
                    stroke="#14AE5C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span className="text-13 text-green-900">Invited</span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default DashboardListProfiles;
