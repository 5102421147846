import { useEffect, useRef, useState } from "react";
import ChatUserName from "./ChatUserName";
import avatarPic from "../../assets/images/avatar-pic.png";
import arrowRightGreen from "../../assets/icons/icon-arrow-right-green.svg";
import iconEmojiFire from "../../assets/icons/icon-emoji-fire.svg";
import InputField from "./InputField";
import iconRefresh from "../../assets/icons/icon-refresh.svg";

const assignToUsers = [
  {
    name: "Madison Inouye",
  },
  {
    name: "Olivia Rhye",
  },
  {
    name: "Charles Wundeng",
  },
  {
    name: "Lisa Fotios",
  },
  {
    name: "Peter Parker",
  },
];
const moveToOptions = [
  {
    name: "Sales",
    icon: iconEmojiFire,
    bgColor: "#4B7FE6",
  },
  {
    name: "General",
    icon: iconEmojiFire,
    bgColor: "#57D1F8",
  },
  {
    name: "Potential Leads",
    icon: iconEmojiFire,
    bgColor: "#F55E5E",
  },
  {
    name: "Marketing Leads",
    icon: iconEmojiFire,
    bgColor: "#EC9E54",
  },
  {
    name: "Product Enquiries",
    icon: iconEmojiFire,
    bgColor: "#35CC71",
  },
];

const popups = {
  showUsers: "showUsers",
  note: "note",
};

const ChatMessageHeader = ({
  user = { name: "Lisa Fotios" },
  setOpenTabs,
  openTabs,
  showrestroed = false,
  onRestoreChannel = () => { },
}) => {
  const node = useRef();
  const [selectedUser, setSelectedUser] = useState({ name: null });
  const [inputValue, setInputValue] = useState("");
  const [isDropdown, setIsdropdown] = useState(0);
  const [activeScreen, setActiveScreen] = useState(null);
  const [showAssignToUsersList, setShowAssignToUsersList] = useState(false);
  const data = isDropdown === 1 ? moveToOptions : assignToUsers;

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setActiveScreen(null);
    setIsdropdown(0);
  };

  const ShowUsersList = ({ }) => {
    return (
      <div>
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <InputField
            placeholder="Search"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
        </div>
        <div
          className={`no-hover ${isDropdown === 1 ? "" : "dropdown-menu__overflow-y-scroll"
            } mt-4`}
        >
          <p className="text-grey-700 px-3 text-xs" style={{ marginBottom: 6 }}>
            {isDropdown === 1 ? "Move to" : "Assign To"}
          </p>
          {data.map((user, index) => {
            return (
              <div
                key={index}
                className="dropdown-menu__list font-medium"
                style={{ color: "#2B3639" }}
                onClick={() => {
                  setSelectedUser(user);
                  setActiveScreen(popups.note);
                }}
              >
                {isDropdown === 1 ? (
                  <img
                    className="mr-3"
                    src={user.icon}
                    style={{ width: 20, height: 20 }}
                    alt=""
                  />
                ) : (
                  <img
                    className="mr-3"
                    src={avatarPic}
                    style={{ width: 30, height: 30 }}
                    alt=""
                  />
                )}
                {user.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const SendNote = ({ }) => {
    return (
      <>
        <div
          className="flex items-center pb-2 px-6 text-13 text-primary cursor-pointer font-medium"
          onClick={() => {
            setSelectedUser({ name: null });
            setActiveScreen(popups.showUsers);
          }}
        >
          <img
            className="mr-1"
            src={arrowRightGreen}
            style={{ width: 10, transform: "rotate(180deg)" }}
            alt=""
          />
          Back
        </div>
        <div className="flex items-center border-b border-grey-400 border-t pl-5 py-2">
          {isDropdown === 1 ? (
            <img
              src={selectedUser.icon}
              className="mr-3"
              style={{ width: 20, height: 20 }}
              alt=""
            />
          ) : (
            <img
              className="mr-3"
              src={avatarPic}
              style={{ width: 30, height: 30 }}
              alt=""
            />
          )}
          <p
            className="font-semibold text-grey-900"
            style={{ fontSize: "14px" }}
          >
            {selectedUser?.name}
          </p>
        </div>
        <div className="no-hover px-4 pt-5">
          {isDropdown === 1 ? (
            <textarea
              name=""
              rows="3"
              cols="5"
              placeholder="Write a note, tag a team member by typing @"
              className="input-field input-field__textarea border text-base-1 text-grey-900"
            ></textarea>
          ) : (
            <textarea
              name=""
              rows="3"
              cols="5"
              placeholder={`Write a note for ${selectedUser?.name?.split(" ")[0]
                } (Optional)`}
              className="input-field input-field__textarea border text-base-1 text-grey-900"
            ></textarea>
          )}
          <div className="flex items-center justify-end gap-3 mt-10">
            <button
              className="btn btn--cancel btn--md"
              style={{ width: "27%" }}
              onClick={() => {
                setSelectedUser({ name: null });
                setActiveScreen(popups.showUsers);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn--primary btn--md"
              style={{ width: "27%" }}
            >
              {isDropdown === 1 ? "Move" : "Attach"}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="chat-message-header flex items-center">
      <ChatUserName
        name={user.name}
        onClick={() => {
          setOpenTabs(!openTabs);
        }}
        onClose={() => {
          setOpenTabs(false);
        }}
      />
      <div className="flex items-center gap-2 ml-auto relative">
        {showrestroed && <div className="flex gap-1 text-sm">
          <p className="text-grey-700">Deleted 4 days ago by </p>
          <h3 className="font-semibold"> Sarah Smith</h3>
        </div>}
        {showrestroed && (
          <button
            className={`btn btn--primary gap-2 text-13 font-medium`}
            onClick={onRestoreChannel}
            style={{ padding: "6px 12px" }}
          >
            <img src={iconRefresh} alt="icon-refresh" />
            Restore to channel
          </button>
        )}
        {!showrestroed && (
          <>
            <button
              className={`btn btn--primary gap-2 text-13 ${isDropdown === 1 ? "clicked" : ""
                }`}
              onClick={() => {
                setActiveScreen(popups.showUsers);
                setIsdropdown(1);
              }}
              style={{ 'padding': '4px 14px' }}
            >
              <figure>
                <svg
                  className="chat-message-header-button-icon"
                  viewBox="0 0 14 13"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.99743 2.50257H3.5C3.34825 2.50257 3.19913 2.51384 3.05343 2.53558C3.23782 1.80097 3.90257 1.25701 4.69437 1.25701H9.84937C11.2755 1.25701 12.4315 2.41309 12.4315 3.83919V8.99418C12.4315 9.86735 11.7701 10.586 10.9209 10.6764C10.971 10.459 10.9974 10.2326 10.9974 10V5.50257C10.9974 3.84572 9.65429 2.50257 7.99743 2.50257ZM0.5 5.50257C0.5 4.41956 1.07388 3.47059 1.93413 2.94316C1.93716 1.42129 3.1718 0.188526 4.69437 0.188526H9.84937C11.8656 0.188526 13.5 1.82298 13.5 3.83919V8.99418C13.5 10.5186 12.2642 11.7544 10.7398 11.7544H10.4312C9.88634 12.509 8.99923 13 7.99743 13H3.5C1.84314 13 0.5 11.6569 0.5 10L0.5 5.50257ZM5.01124 8.30863C4.80384 8.09877 4.80583 7.76051 5.01569 7.5531L7.07225 5.52063H6.07702C5.78197 5.52063 5.54278 5.28144 5.54278 4.98639C5.54278 4.69133 5.78197 4.45214 6.07702 4.45214H8.35425C8.37216 4.45151 8.39009 4.45178 8.40797 4.45294C8.53923 4.46012 8.66365 4.51552 8.75697 4.60907C8.8572 4.70953 8.91334 4.84574 8.913 4.98765L8.90757 7.28396C8.90688 7.57902 8.66712 7.81764 8.37207 7.81694C8.07702 7.81624 7.83839 7.57649 7.83909 7.28144L7.8415 6.26264L5.76677 8.31308C5.5569 8.52048 5.21865 8.51849 5.01124 8.30863Z"
                  />
                </svg>
              </figure>
              Move To
            </button>
            <button
              className={`btn btn--primary gap-2 text-13 ${isDropdown === 2 ? "clicked" : ""
                }`}
              onClick={() => {
                setActiveScreen(popups.showUsers);
                setIsdropdown(2);
              }}
              style={{ 'padding': '4px 14px' }}
            >
              {selectedUser?.name !== null ? (
                <img
                  className="chat-message-header-button-icon"
                  src={avatarPic}
                  alt=""
                />
              ) : (
                <figure>
                  <svg
                    className="chat-message-header-button-icon"
                    viewBox="0 0 14 14"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.24016 7.00016C1.24016 3.819 3.819 1.24016 7.00016 1.24016C10.1813 1.24016 12.7602 3.819 12.7602 7.00016C12.7602 8.27684 12.3448 9.45652 11.6418 10.4114C11.6008 9.4488 10.8232 8.575 9.72928 8.575H4.47417C3.30989 8.575 2.46516 9.56838 2.53515 10.6393C1.72564 9.64727 1.24016 8.38041 1.24016 7.00016ZM7.00016 0.160156C3.22253 0.160156 0.160156 3.22253 0.160156 7.00016C0.160156 10.7778 3.22253 13.8402 7.00016 13.8402C10.7778 13.8402 13.8402 10.7778 13.8402 7.00016C13.8402 3.22253 10.7778 0.160156 7.00016 0.160156ZM9.36262 5.425C9.36262 6.72977 8.30489 7.7875 7.00012 7.7875C5.69535 7.7875 4.63762 6.72977 4.63762 5.425C4.63762 4.12023 5.69535 3.0625 7.00012 3.0625C8.30489 3.0625 9.36262 4.12023 9.36262 5.425Z"
                    />
                  </svg>
                </figure>
              )}
              {selectedUser?.name !== null ? selectedUser?.name : "Unassigned"}
            </button>
          </>
        )}
        <div
          ref={node}
          className={`dropdown-menu dropdown-menu--with-border mt-3 ${activeScreen !== null && "open"
            }`}
          style={{
            right: "25px",
            left: "auto",
            width: "500px",
            top: "30px",
            padding:
              activeScreen === popups.showUsers
                ? "18px 8px 8px 8px"
                : "11px 0px 18px",
            transition: "all 300ms ease-in",
          }}
        >
          {activeScreen === popups.showUsers ? (
            <ShowUsersList />
          ) : activeScreen === popups.note ? (
            <SendNote />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ChatMessageHeader;
