import React from "react";
import iconDelete from "../assets/icons/bin.svg";
import iconDragandDrop from "../assets/icons/Union.svg";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";

const BoxSettingsCreateCard = () => {
  return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />

				<section className="dashboard__main general-settings">
					<GeneralSettingsHedder text={"General Box Settings"} />
					<div className="dashboard__main__body">
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<BoxSettingsNavLinks activeScreen={2} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay p-10">
							<h2 className="text-2xl font-semibold text-center">
								Design your enquiry card
							</h2>
							<div className="general-settings__box-card-bg py-8 px-12 mt-12">
								<div className="px-12">
									<h5 className="text-base-1 font-semibold text-grey-800">
										Preview of the Card
									</h5>
									<div className="flex mt-3 p-3 bg-white rounded-xl">
										<figure
											className="avatar-container bg-blue-800"
											style={{ width: 48, height: 48 }}
										>
											{/* <img src={avtarPic} alt="" /> */}
											<p className="font-semibold text-white">KD</p>
										</figure>
										<div className="ml-5">
											<h4 className="font-semibold">King Dental Cosmetics</h4>
											<p className="text-base-1 font-semibold mt-2">John Doe</p>
											<p className="text-base-1 text-grey-800">
												Contract Value: $12,509
											</p>
										</div>
									</div>
								</div>
								<div className="mt-10">
									<label className="input-field-label">Name</label>
									<input
										type="text"
										className="input-field input-field--md"
										placeholder="John Doe"
									/>
								</div>
								<div className="mt-6">
									<div className="flex justify-between">
										<label className="input-field-label">
											Most recent message
										</label>
										<div className="ml-auto mb-2">
											<div className="toggle-slider">
												<input
													type="checkbox"
													id="message"
													className="toggle-slider__checkbox"
													name="checkedA"
												/>
												<label
													htmlFor="message"
													className="toggle-slider__label"
												>
													<code className="toggle-slider__ball"></code>
												</label>
											</div>
										</div>
									</div>

									<input
										type="text"
										className="input-field input-field--md"
										placeholder="Message"
									/>
								</div>
								<div className="relative mt-6">
									<label className="input-field-label">Question 1</label>
									<input
										className="input-field input-field--md input-field--select"
										placeholder="Question 1"
									/>
									<img
										src={iconDelete}
										alt=""
										className="absolute cursor-pointer"
										style={{ right: -23, top: 39 }}
									/>
									<img
										src={iconDragandDrop}
										alt=""
										className="absolute cursor-pointer"
										style={{ left: -18, top: 40 }}
									/>
									<div className="dropdown-menu open p-2">
										<div className="mt-2 px-3">
											<input
												type="text"
												className="input-field input-field--sm input-field--icon-search input-field--icon-search--pos-left"
												placeholder="Search"
											/>
											<p className="text-xs text-grey-700 font-medium mt-4 mb-2">
												Questions
											</p>
										</div>
										<div className="dropdown-menu__list">Company name</div>
										<div className="dropdown-menu__list">Company name2</div>
										<div className="dropdown-menu__list">Company name3</div>
									</div>
								</div>
								<div className="relative mt-6">
									<label className="input-field-label">Question 2</label>
									<input
										className="input-field input-field--md input-field--select"
										placeholder="Question 2"
									/>
									<div className="dropdown-menu">
										<div className="dropdown-menu__list">Company name</div>
										<div className="dropdown-menu__list">Company name2</div>
										<div className="dropdown-menu__list">Company name3</div>
									</div>
								</div>
								<p className="text-13 text-grey-800 mt-3">
									Maximum number of questions for card reached
								</p>
							</div>
							<div className="mt-10 flex justify-end">
								<button
									className="btn btn--primary btn--md"
									style={{ minWidth: 120 }}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</section>
			</section>
		</main>
  );
};

export default BoxSettingsCreateCard;
