import React from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";

const AddLabel = ({ addLabel, setAddLabel }) => {
  return (
    <div className={`modal modal--CustomBox ${addLabel ? "show" : ""}`}>
      <div className="modal__container">
        <div className="modal__header rounded-t-xl">
          <h2 className="modal__heading pr-5">Create label</h2>
          <div className="modal__close-icon" onClick={() => setAddLabel(false)}>
            <img
              src={modalCloseIcon}
              alt=""
              style={{ width: 10, height: 10 }}
            />
          </div>
        </div>
        <div className="modal__body" style={{ padding: "25px 30px 30px" }}>
          <label className="text-15 font-semibold">Label name</label>
          <input
            className="input-field input-field--md mt-2"
            placeholder="Type name"
          />
          <div
            className="flex items-center justify-end gap-3 mt-10"
          >
            <button className="btn btn--grey btn--md w-1/4" onClick={() => setAddLabel(false)}>Cancel</button>
            <button className="btn btn--primary btn--md w-1/4">Create</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLabel;
