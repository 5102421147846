import React from 'react'
import avatarPic1 from "../../assets/icons/icon-avatar.svg";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";

const ReminderDetails = ({openViewEnquiry, setOpenViewEnquiry}) => {
	return (
		<div className={`modal modal--CustomBox ${openViewEnquiry ? 'show' : ''}`}>
				<div className="modal__container">
				<div className="modal__header" style={{justifyContent: 'flex-end'}}>
					<div className="modal__close-icon" onClick={() => setOpenViewEnquiry(false)}>
						<img src={modalCloseIcon} alt="" style={{ width: 10, height: 10 }} />
					</div>
				</div>			
					<div className="modal__body" style={{ padding: '45px 30px 30px' }}>		
					<div className='flex justify-center'>
						<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className='cursor-pointer'>
							<circle cx="30" cy="30" r="30" fill="#357AF5" />
							<path d="M42.5893 28V24.3594C42.5893 22.1502 40.7984 20.3594 38.5893 20.3594H20.875C18.6659 20.3594 16.875 22.1502 16.875 24.3594V37.2522C16.875 39.4614 18.6659 41.2522 20.875 41.2522H31.5" stroke="white" stroke-width="2" stroke-linecap="round" />
							<path d="M23.3047 18.75V19.5536" stroke="white" stroke-width="2" stroke-linecap="round" />
							<path d="M36.1602 18.75V19.5536" stroke="white" stroke-width="2" stroke-linecap="round" />
							<path d="M16.875 25.9844H42.5893" stroke="white" stroke-width="2" />
							<circle cx="41" cy="38" r="7" fill="white" />
							<path fill-rule="evenodd" clip-rule="evenodd" d="M43.5 36L40.0625 39.4375L38.5 37.875" fill="white" />
							<path d="M43.5 36L40.0625 39.4375L38.5 37.875" stroke="#357AF5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</div>	
					<div className="mt-6 rounded-lg flex p-4"style={{border: '1px solid rgba(53, 122, 245, 0.2)'}}>
						<figure
							className="avatar-container avatar-container--contain bg-white mr-3"
							style={{
								width: 34,
								height: 34,
								border: "1px solid rgba(0, 0, 0, 0.05)"
							}}
						>
							<img src={avatarPic1} alt="" style={{ width: 30, height: 26 }} />
						</figure>
						<p className='text-sm'>No problem sam, I will remind you to review the designs for <span className='font-semibold'>Bipin monday morning 09.00am</span></p>
					</div>		
					</div>
				</div>
			</div> 
	)
}

export default ReminderDetails