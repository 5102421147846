import React, { useState } from "react";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import iconEdit from "../assets/icons/icon-edit.svg";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic2 from "../assets/images/avatar-pic2.png";
import ChannelHeader from "./Common/ChannelHeader";
import fileIcon from "../assets/icons/icon-file-tab.svg";
import historyIcon from "../assets/icons/icon-history-grey.svg";
import iconContactGreen from "../assets/icons/icon-contact-green.svg";
import ModalAddSingleContactWarningMessage from "./ModalAddSingleContactWarningMessage";

const users = [
  {
    id: 1,
    name: "John Leon",
    image: avatarPic,
    active: false,
  },
  {
    id: 2,
    name: "Madison Inouye",
    image: avatarPic,
    active: false,
  },
  {
    id: 7,
    name: "John Leon",
    image: avatarPic,
    active: false,
  },
  {
    id: 3,
    name: "Olivia Rhye",
    image: avatarPic,
    active: false,
  },
  {
    id: 6,
    name: "Alex Foster",
    image: avatarPic,
    active: true,
  },
  {
    id: 4,
    name: "Charles Wundeng",
    image: avatarPic,
    active: false,
  },
  {
    id: 5,
    name: "Lisa Fotios",
    image: avatarPic,
    active: false,
  },
];

const AddSingleContactWarningMessage = () => {
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />
        <section className="dashboard__main">
          <ChannelHeader
            text={"All Contacts"}
            linkText="1,532 Contacts"
            HeaderIcon={() => (
              <figure className="avatar-container">
                <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          <div className="dashboard__main__body ">
            <div className="dashboard__main__body__section-left overflow-overlay">
              <ul>
                {users.map((ele, index) => {
                  return (
                    <li
                      key={index}
                      className={`teams-profile-list bg-color-on-hover ${
                        ele.active ? "active":""
                      }`}
                    >
                      <div className="flex justify-start items-center">
                        <figure
                          className="avatar-container avatar-container--full"
                          style={{ width: 42, height: 42 ,text:"center",background:"#4B7FE6" ,color:"#FFFFFF",fontWeight:"600"}}
                        >
                          {index === 0 ? (
                            <span >{ele.name.split(" ")[0][0].toUpperCase()}{ele.name.split(" ")[1][0].toUpperCase()}</span>
                          ) : index === 3 ? (
                            <span >{ele.name.split(" ")[0][0].toUpperCase()}{ele.name.split(" ")[1][0].toUpperCase()}</span>
                          ) : (
                            <img src={ele.image} alt="avatar-pic" />
                          )}
                        </figure>
                        <div className="font-semibold text-base ml-4">
                          {ele.name}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay">
              <div className="tabs-wrapper tabs-wrapper--contact justify-center">
                <div className="tabs-header tabs-header__active">
                  <img src={iconContactGreen} alt="" />
                  <div className="ml-1 ">Contact</div>
                </div>
                <div className="tabs-header">
                  <img src={historyIcon} alt="" />
                  <div className="ml-1">History</div>
                </div>
                <div className="tabs-header">
                  <img src={fileIcon} alt="" />
                  <div className="ml-1">Files</div>
                </div>
              </div>
              <div className="m-10">
                <div className="card-box flex p-5 border-radius-14 ">
                  <figure
                    className="avatar-container avatar-container--cover bg-grey-100 p-1"
                    style={{ width: "76px", height: "76px" }}
                  >
                    <img
                      src={avatarPic2}
                    />
                  </figure>
                  <div className="pl-6 w-full">
                    <h3 className="text-base-1 font-bold">Alex Foster</h3>
                    <div className="edit-signle-line bg-color-on-hover bg-color-on-hover--br-6 mt-1 py-2">
                      <span className="text-sm">lisafotios_458@gmail.com</span>
                      <img
                        src={iconEdit}
                        alt="icon-edit"
                        className="hover-visible-icon"
                      />
                    </div>
                    <p className="text-sm text-grey-800 mt-1">
                      (514) 531-8471
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ModalAddSingleContactWarningMessage />
      </section>
    </main>
  );
};

export default AddSingleContactWarningMessage;
