import React, { useState } from 'react'
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";

const AddReminder = ({openReminder, setOpenReminder, setOpenViewEnquiry}) => {
	const [showDayDropDown, setShowDayDropDown] = useState(false)
	const [showReminderTime,setShowReminderTime] = useState(false)
	return (
		<div className={`modal modal--CustomBox ${openReminder ? 'show' : ''}`}>
				<div className="modal__container">
					<div className="modal__header">
						<h2 className="modal__heading pr-5">Add Reminder</h2>
						<div className="modal__close-icon" onClick={() => setOpenReminder(false)}>
							<img src={modalCloseIcon} alt="" style={{ width: 10, height: 10 }} />
						</div>
					</div>
					<div className="modal__body" style={{ padding: 30 }}>
					<div className="border-radius-10 bg-blue-100" style={{border: '1px solid rgba(53, 122, 245, 0.2)'}}>
						<textarea
							className="input-field input-field--add-reminder resize-none"
							rows="3"
							cols="10"
							placeholder="Type what you want to be reminded about..."
						>
						</textarea>
						<div className='flex ml-4 mb-4'>
							<button className='btn btn--basic bg-white mr-1 border border-grey-400 relative' onClick={() => setShowDayDropDown(!showDayDropDown)}>
								<div className='pr-2 border-r border-grey-400'>
									<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 3.75C1 2.64543 1.89543 1.75 3 1.75H11C12.1046 1.75 13 2.64543 13 3.75V9.5C13 10.6046 12.1046 11.5 11 11.5H3C1.89543 11.5 1 10.6046 1 9.5V3.75Z" stroke="#636566" stroke-width="1.2" />
										<path d="M4 1V1.375" stroke="#636566" stroke-width="1.5" stroke-linecap="round" />
										<path d="M10 1V1.375" stroke="#636566" stroke-width="1.5" stroke-linecap="round" />
										<path d="M1 4.375H13" stroke="#636566" stroke-width="1.2" />
									</svg>
								</div>
								<p className='ml-2'>Monday morning</p>
								<div
									className={`dropdown-menu dropdown-menu--sm ${showDayDropDown && "open"
										}`}
									style={{ width: "240px", bottom: "30px", left: "0px" }}
								>
									<div className="dropdown-menu__list">Tomorrow morning</div>
									<div className="dropdown-menu__list">Monday morning</div>
									<div className="dropdown-menu__list">Custom date</div>
								</div>
							</button>
							<button className='btn btn--basic bg-white mr-1 border border-grey-400 relative' onClick={() => setShowReminderTime(!showReminderTime)}>
								<div className='pr-2 border-r border-grey-400'>
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9.49976 9.5L6.8125 7.70921V4" stroke="#636566" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M11.2426 2.75736C13.5858 5.10051 13.5858 8.8995 11.2426 11.2426C8.89949 13.5858 5.1005 13.5858 2.75736 11.2426C0.414213 8.89949 0.414213 5.1005 2.75736 2.75736C5.10051 0.414213 8.8995 0.414213 11.2426 2.75736" stroke="#636566" stroke-width="1.2" />
									</svg>
								</div>
								<p className='ml-2'>09:00am</p>
								<div
									className={`dropdown-menu dropdown-menu--sm ${showReminderTime && "open"
										}`}
										style={{ width: "120px", left: "0px", bottom: "30px" }}
								>
									<div className="dropdown-menu__overflow-y-scroll">
										<div className="dropdown-menu__list">12:00am</div>
										<div className="dropdown-menu__list">1:00am</div>
										<div className="dropdown-menu__list">2:00am</div>
										<div className="dropdown-menu__list">3:00am</div>
										<div className="dropdown-menu__list">4:00am</div>
										<div className="dropdown-menu__list">5:00am</div>
										<div className="dropdown-menu__list">6:00am</div>
										<div className="dropdown-menu__list">7:00am</div>
									</div>							
								</div>
							</button>
						</div>					
					</div>				
						<div className="flex items-center justify-end gap-3" style={{ marginTop: 30 }}>
							<button
								className="btn btn--grey btn--sm w-1/5"
							>
								Cancel
							</button>
							<button className="btn btn--primary btn--sm w-1/5" onClick={() => {setOpenReminder(false);setOpenViewEnquiry(true)}}>
								Add
							</button>
						</div>
					</div>
				</div>
			</div> 
	)
}

export default AddReminder