import React from 'react';
import closeIcon from "../../assets/icons/icon-modal-close.svg"

const GeneralSettingsHedder = ({
  text = "",

}) => {
  return (
    <div className="dashboard__main__header">
      <h3 className="text-xl font-semibold">{text}</h3>
      <div className="ml-auto">
        <img src={closeIcon} className="cursor-pointer" alt='close-icon' />
      </div>
    </div>
  )
}

export default GeneralSettingsHedder