import React, { useState, useRef, useEffect } from 'react';
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import ChannelHeader from "./Common/ChannelHeader";
import salesIcon from '../assets/icons/icon-sales-box.svg';
import deleteIcon from '../assets/icons/icon-Delete.svg';
import iconMoreRound from '../assets/icons/icon-more-round.svg';
import user1Icon from '../assets/icons/icon-avatar-image-1.svg';
import user2Icon from '../assets/icons/icon-avatar-image-2.svg';
import user3Icon from '../assets/icons/icon-avatar-image-3.svg';
import iconFb from '../assets/icons/Icon-fb-20.svg';
import iconInsta from '../assets/icons/Icon-Instagram-20.svg';
import ChatMessageHeader from "./Common/ChatMessageHeader";
import avatarPic from "../assets/images/avatar-pic.png";
import Details from "./Details";
import { Link } from "react-router-dom";
import ChatFooterInputBox from "./ChatFooterInputBox";
import SidebarLinksNew from './Common/SideBarLinksNew';
import SideBarExpandedNew from './Common/SideBarExpandedNew';
import ChannelHeaderNew from './Common/ChannelHeaderNew';

const replyBy = [
	{
		title: "Email",
	},
	{
		title: "Facebook",
	},
];

const ClickedEnquiryView = () => {
	const [showDelete, setShowDelete] = useState(0);
	const [openTabs, setOpenTabs] = useState(false);
	const [showImageModal, setShowImageModal] = useState(0);
	const [openUpload, setOpenUpload] = useState(false);
	const [showReplyOptions, setShowReplyOptions] = useState(false);
	const [showAssignToUsersList, setShowAssignToUsersList] = useState(false);
	const [showMoveHelper,setShowMoveHelper] = useState(true)
  const [isActive, setIsActive] = useState(1);
	const node = useRef();

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	const handleClick = (e) => {
		if (node.current?.contains(e.target)) {
			return;
		}
		setShowReplyOptions(false);
		setShowAssignToUsersList(false);
	};

	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinksNew />
				<SideBarExpandedNew />
				<section className="dashboard__main">
					<ChannelHeaderNew
						showToggle={true}
            setIsActive={setIsActive}
            isActive={isActive}
            HeaderIcon={() => {
              return (
                <Link to="/sales-box-first-view">
                  <button className="btn btn--back rounded btn--xs border border-grey-400">
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M4.91895 1L1.00195 5L4.91895 9"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 4.99634L11 4.99634"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="ml-2 text-13 font-medium text-primary">
                      Back to Stages
                    </span>
                  </button>
                </Link>
              );
            }}
					/>
					<div className="dashboard__main__body">
						<div className="dashboard__main__body__section-left" style={{
							padding: "17px 20px 20px 26px"
						}}>
							<div className="box-card-container mr-5 clip-path mt-15" style={{ minWidth: '100%' }}>
								<div className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white" style={{
									width: 61,
									height: 24,
									border: "1px solid rgba(4, 145, 171, 0.3)",
									borderRadius: "20px",
								}}>$153
								</div>
								<div className="flex justify-between ml-3 mt-2">
									<div className='font-semibold text-lg'>Won</div>
									<div className='flex items-center'>
										<div className='cursor-pointer relative' onClick={() => setShowDelete(1)}>
											<img src={iconMoreRound} alt='' style={{ width: 16, height: 4 }} />
											<div className={`dropdown-menu dropdown-menu--more ${showDelete === 1 ? 'open' : ''}`} style={{ top: 10 }}>
												<div className="dropdown-menu__list red-text">
													<img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Delete
												</div>
											</div>
										</div>
										<div>
											<div className="text-sm flex justify-center text-white ml-3 font-semibold bg-secondary" style={{
												width: 32,
												height: 22,
												borderRadius: "20px",
											}}>3</div>
										</div>
									</div>
								</div>
								<div className='mt-5'>
									<div className='box-card' style={{width: "100%"}}>
										<div className='flex mt-1'>
											<figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
												<img src={user2Icon} alt='' />
												<figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
													<img src={iconFb} alt='' className="" style={{ width: 12, height: 12 }} />
												</figure>
											</figure>
											<div>
												<div className='font-semibold'>Alex Foster</div>
												<div className='text-15 truncate'>How are you !</div>
											</div>
										</div>
										<div>
											<div className='flex justify-end text-sm' style={{marginTop: 30}}>May 29</div>
										</div>
									</div>
									<div className='box-card mt-3' style={{width: "100%"}}>
										<div className='flex mt-1'>
											<figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
												<img src={user1Icon} alt='' />
												<figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
													<img src={iconInsta} alt='' className="" style={{ width: 12, height: 12 }} />
												</figure>
											</figure>
											<div>
												<div className='font-semibold'>Lisa Fotios</div>
												<div className='text-15 truncate'>Hey</div>
											</div>
										</div>
										<div>											
											<div className='flex justify-end text-sm' style={{marginTop: 30}}>30m</div>
										</div>
									</div>
									<div className='box-card mt-3' style={{width: "100%"}}>
										<div className='flex mt-1'>
											<figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
												<img src={user3Icon} alt='' />
												<figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
													<img src={iconFb} alt='' className="" style={{ width: 12, height: 12 }} />
												</figure>
											</figure>
											<div>
												<div className='font-semibold'>Merva Sahin</div>
												<div className='text-15 truncate'>Good night</div>
											</div>
										</div>
										<div>		
											<div className="text-white text-10 font-semibold flex justify-center bg-green-700" style={{
												width: 36,
												height: 16,
												borderRadius: "20px",
												marginLeft: "calc(100% - 35px)"
											}}>
												New
											</div>									
											<div className='flex justify-end text-sm' style={{marginTop: 14}}>May 31</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="dashboard__main__body__section-right chat-message-container relative">
							<ChatMessageHeader setOpenTabs={setOpenTabs} openTabs={openTabs}/>
							<div className="chat-message-body">
								<div className="chat-message-list flex">
									<figure
										className="avatar-container avatar-container--full"
										style={{ width: 32, height: 32 }}
									>
										<img src={avatarPic} alt="avatar-pic" />
									</figure>
									<div className="" style={{ marginLeft: 6 }}>
										<div>
											<div
												className="inline-block border border-grey-400 text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
												}}
											>
												Hi dude...
											</div>
										</div>
										<div>
											<div
												className="inline-block border border-grey-400 text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
													marginTop: 6,
												}}
											>
												How is your job?
											</div>
										</div>
										<div>
											<div
												className="inline-block border border-grey-400 text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
													marginTop: 6,
												}}
											>
												Are you doing anything fun this weekend at home?
											</div>
										</div>
										<div>
											<div
												className="text-xs text-grey-700"
												style={{ marginTop: 6 }}
											>
												2d
											</div>
										</div>
									</div>
								</div>
								<div className="chat-message-list chat-message-list--sender flex">
									<figure
										className="avatar-container avatar-container--full"
										style={{ width: 32, height: 32 }}
									>
										<img src={avatarPic} alt="avatar-pic" />
									</figure>
									<div className="" style={{ marginRight: 6 }}>
										<div>
											<div
												className="inline-block  text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
													backgroundColor: "#F4F9F9",
													border: "1px solid rgba(0, 0, 0, 0.05)",
												}}
											>
												Hi dude...
											</div>
										</div>
										<div>
											<div
												className="inline-block  text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
													marginTop: 6,
													backgroundColor: "#F4F9F9",
													border: "1px solid rgba(0, 0, 0, 0.05)",
												}}
											>
												How is your job?
											</div>
										</div>
										<div>
											<div
												className="inline-block text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
													marginTop: 6,
													backgroundColor: "#F4F9F9",
													border: "1px solid rgba(0, 0, 0, 0.05)",
												}}
											>
												Are you doing anything fun this weekend at home?
											</div>
										</div>
										<div>
											<div
												className="text-xs text-grey-700"
												style={{ marginTop: 6 }}
											>
												2d
											</div>
										</div>
									</div>
								</div>
								<div className="chat-message-list flex">
									<figure
										className="avatar-container avatar-container--full"
										style={{ width: 32, height: 32 }}
									>
										<img src={avatarPic} alt="avatar-pic" />
									</figure>
									<div className="" style={{ marginLeft: 6 }}>
										<div>
											<div
												className="inline-block border border-grey-400 text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
												}}
											>
												Tristique bibendum finibus nec, consectetu.
											</div>
										</div>
										<div>
											<div
												className="text-xs text-grey-700"
												style={{ marginTop: 6 }}
											>
												Now
											</div>
										</div>
									</div>
								</div>
							</div>
							<ChatFooterInputBox showMoveHelper={showMoveHelper} setShowMoveHelper={setShowMoveHelper}/>
							<section className={`modal modal--contact ${openTabs ? 'show' : ''}`}>
								<div className="modal__container">
									<Details setShowImageModal={setShowImageModal} setOpenUpload={setOpenUpload} />
								</div>
							</section>
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default ClickedEnquiryView