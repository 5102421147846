import React, {useState} from 'react';
import iconUserRound from '../../assets/icons/icon-user-round.svg';

const ToggleHeader = () => {
	const [showMineTooltip,setShowMineTooltip]= useState(false);
	const [showAllTooltip,setShowAllTooltip]= useState(false);
	return (
		<div className="toggle-container relative">
			<div className="toggle-container__switch">
				<input type="checkbox" name="color_mode" id="color_mode" value="1" />
				<label for="color_mode" className="toggle-container__switch-inner flex justify-between items-center">
					<span className="cursor-pointer all-text text-white w-1/2" style={{ zIndex: 9 }} onMouseEnter={() => setShowAllTooltip(true)}
						onMouseLeave={() => setShowAllTooltip(false)}>All</span>
					<div className='flex w-1/2 cursor-pointer' onMouseEnter={() => setShowMineTooltip(true)}
							onMouseLeave={() => setShowMineTooltip(false)}>
						<figure className='avatar-container' style={{ width: 18, height: 18, zIndex: 9, marginRight: 5, marginTop: 1 }}>
							<img src={iconUserRound} alt='' />
						</figure>
						<span className="cursor-pointer mine-text text-grey-800" style={{ zIndex: 9 }}>Mine
						</span>
					</div>				
				</label>
			</div>
			{showMineTooltip && <span
				className='tooltiptext tooltiptext__toggle text-13'
			>
				Enquiries assigned to me
			</span>}
			{showAllTooltip && <span
				className='tooltiptext tooltiptext__all-tooltip text-13'
			>
				All enquiries
			</span>}
		</div>
	)
}

export default ToggleHeader