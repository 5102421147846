import React from "react";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import iconReplyArrow from "../assets/icons/icon-reply-white.svg";
import iconForward from "../assets/icons/icon-forward.svg";

const ChatPreviewList = () => {
  return (
    <ul className="">
    <li className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm">
      <div className="flex items-center gap-4 flex-1 truncate">
        <figure className="chats-preview__avatar-container">
          <img src={iconAvatar} className="" />
        </figure>
        <div className="truncate pr-4">
          <h3 className="chats-preview__title">John Leon</h3>
          <p className="chats-preview__msg">
            You are now connected wedding
          </p>
        </div>
      </div>
      <div className="chats-preview__add-info">
        <label className="notification-label notification-label--new-msg">
          New
        </label>
        <p className="chats-preview__time">1m</p>
      </div>
    </li>
    <li className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm">
      <div className="flex items-center gap-4 flex-1 truncate">
        <figure className="chats-preview__avatar-container">
          <img src={iconAvatar} className="" />
        </figure>
        <div className="truncate pr-4">
          <h3 className="chats-preview__title">Madison Inouye</h3>
          <p className="chats-preview__msg">Hey</p>
        </div>
      </div>
      <div className="chats-preview__add-info">
        <label className="notification-label notification-label--new-msg">
          New
        </label>
        <p className="chats-preview__time">30m</p>
      </div>
    </li>
    <li className="chats-preview chats-preview--reply-received bg-color-on-hover bg-color-on-hover--sm">
      <div className="flex items-center gap-4 flex-1 truncate">
        <figure className="chats-preview__avatar-container">
          <img src={iconAvatar} className="" />
        </figure>
        <div className="truncate pr-4">
          <h3 className="chats-preview__title">Madison Inouye</h3>
          <p className="chats-preview__msg">Hey</p>
        </div>
      </div>
      <div className="chats-preview__add-info">
        <label className="notification-label notification-label--new-msg">
          2
          <img src={iconReplyArrow} alt="arrow-reply" />
        </label>
        <p className="chats-preview__time">26m</p>
      </div>
    </li>
    <li className="chats-preview bg-color-on-hover bg-color-on-hover--sm">
      <div className="flex items-center gap-4 flex-1 truncate">
        <figure className="chats-preview__avatar-container">
          <img src={iconAvatar} className="" />
        </figure>
        <div className="truncate pr-4">
          <h3 className="chats-preview__title">John Leon</h3>
          <p className="chats-preview__msg">
            You are now connected wedding chadsfjds
          </p>
        </div>
      </div>
      <div className="chats-preview__add-info">
        <p className="chats-preview__time">Apr 30</p>
      </div>
    </li>
    <li className="chats-preview chats-preview--reply-sent bg-color-on-hover bg-color-on-hover--sm">
      <div className="flex items-center gap-4 flex-1 truncate">
        <figure className="chats-preview__avatar-container">
          <img src={iconAvatar} className="" />
        </figure>
        <div className="truncate pr-4">
          <h3 className="chats-preview__title">Alex Foster</h3>
          <div className="flex items-center gap-1">
            <img src={iconForward} alt="" />
            <p className="chats-preview__msg">
              You are now connected wedding jfsdflkdsf
            </p>
          </div>
        </div>
      </div>
      <div className="chats-preview__add-info">
        <p className="chats-preview__time">Apr 30</p>
      </div>
    </li>
  </ul>
  )
}
export default ChatPreviewList;