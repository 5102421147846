import React from "react";
import iconAvatar from "../assets/icons/icon-avatar.svg";

const ChatPreviewListSample = () => {
  return (
    <ul className="">
      <li className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm active">
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="chats-preview__avatar-container">
            <img src={iconAvatar} className="" />
          </figure>
          <div className="truncate pr-4">
            <h3 className="chats-preview__title">Enquiry Assistant</h3>
            <p className="chats-preview__msg">Connect your Contact Form</p>
          </div>
        </div>
      </li>
    </ul>
  );
};
export default ChatPreviewListSample;
