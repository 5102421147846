import React, { useState, useRef } from "react";
import iconGroupChannel from "../assets/icons/Group132462.svg";
import iconGroupCardDesign from "../assets/icons/Group13220.svg";
import iconTeamAccess from "../assets/icons/Group13230.svg";
import iconAutomate from "../assets/icons/Group13224.svg";
import iconBoxForms from "../assets/icons/Group13237.svg";
import iconImportContacts from "../assets/icons/Group13225.svg";
import iconIntegrations from "../assets/icons/Group13229.svg";
import iconBoringStuff from "../assets/icons/Group13245.svg";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import tourVideoPlaceholderPic from "../assets/images/product-tour-placeholder.png";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import iconDownload from "../assets/icons/icon-new-download.svg";
import iconUpload from "../assets/icons/icon-upload.svg";
import iconRollBack from "../assets/icons/icon-rollback.svg";
import HelperVideo from "./Common/HelperVideo";
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";


const ModalBoxSettingsUploaded = () => {
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });
  const [modalShow, hideModalShow] = useState(true);

  const [type, setType] = useState(false);
  const snackbarRef = useRef(null);

  const handleChange = (event) => {
    snackbarRef.current.show();
    event.target.checked ? setType("success") : setType("fail");
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main">
          <GeneralSettingsHedder text={"General Box Settings"} />
          <div className="dashboard__main__body general-settings">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
            <BoxSettingsNavLinks activeScreen={6} />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay p-10">
							<div>
								<h2 className="text-2xl font-semibold text-center">
									Box contacts
								</h2>
								<p className="text-grey-800 text-sm text-center mt-3 px-16">
									Download or upload contacts
								</p>
							</div>             
              <div className="mt-8 mx-auto">
								<HelperVideo />
                {/* <img src={tourVideoPlaceholderPic} alt="" /> */}
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
                <h3 className="font-semibold">
                  Download all box contacts (456)
                </h3>
                <button className="btn btn--primary btn--sm text-13 w-3/12 gap-1">
                  <img className="" src={iconDownload} alt="" />
                  Download
                </button>
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
                <h3 className="font-semibold">Upload File</h3>
                <button className="btn btn--primary btn--sm text-13 w-3/12 gap-1">
                  <img className="" src={iconUpload} alt="" />
                  Upload
                </button>
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 bg-white mt-10 px-6 py-4">
                <div className="w-full">
                  <h3 className="text-grey-900 font-semibold self-center text-base">
                    Upload history
                  </h3>
                  <table className="tabel-upload-history">
                    <thead></thead>
                    <tbody>
                      <tr className="bg-grey-200">
                        <td>General box.csv</td>
                        <td>256</td>
                        <td>Jun 29, 2022</td>
                        <td>hello</td>
                      </tr>
                    </tbody>
                  </table>
                  <button className="btn btn--sm btn--default ml-auto mt-2">
                    <img src={iconRollBack} alt="" className="mr-2" />
                    Rollback
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {modalShow && (
        <div className="modal show">
          <div className="modal__container">
            <div className="modal__header">
              <h2 className="modal__heading pr-5">Upload contacts</h2>
              <div
                className="modal__close-icon"
                onClick={() => hideModalShow(false)}
              >
                <img src={modalCloseIcon} alt="" />
              </div>
            </div>
            <div className="modal__body px-8 py-12 pb-10">
              <div className="text-center">
                <div
                  className="inline-flex items-center justify-center border-2 border-green-900 rounded-full"
                  style={{
                    width: 60,
                    height: 60,
                    backgroundColor: "rgba(20, 174, 92, 0.05)",
                  }}
                >
                  <svg width="20" height="20" viewBox="0 0 12 10" fill="none">
                    <path
                      d="M1 5.37637L4.13194 9L11 1"
                      stroke="#14AE5C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <h4 className="text-lg font-semibold mt-6">
                  500 Contacts successfully uploaded.
                </h4>
              </div>
              <div className="flex justify-end mt-16">
                <button
                  className="btn btn--primary btn--sm min-w-120"
                  onClick={() => hideModalShow(false)}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default ModalBoxSettingsUploaded;
