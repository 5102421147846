import React from "react";
import EnquiryAssistantNavLinks from "./Common/EnquiryAssistantNavLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import iconAvatar from "../assets/icons/icon-enquiry-assistant-1.svg";

const EnquiryAssistantSettings = () => {
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />
        <section className="dashboard__main">
          <div className="dashboard__main__header">
            <h3 className="font-semibold text-2xl">
              Enquiry assistant settings
            </h3>
          </div>
          <div
            className="dashboard__main__body"
            style={{ height: "calc(100% - 83px)" }}
          >
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <EnquiryAssistantNavLinks activeScreen={1} />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay  p-10">
              <div>
                <h2 className="text-2xl font-semibold text-center">Avatar</h2>
                <p className="text-grey-800 text-15 text-center mt-3">
                  Change your enquiry assistants avatar
                </p>
              </div>
              <div className="card-box px-6 py-5 mt-6">
                <h3 className="font-semibold text-lg">
                  Select the avatar for your enquiry assistant
                </h3>
                <div className="flex flex-wrap mt-5">
                  <div className="enquiry-assistant__card">
                    <figure
                      className="enquiry-assistant__card--avatar"
                      style={{ width: 64, height: 64 }}
                    >
                      <img
                        src={iconAvatar}
                        alt=""
                        className="absolute bottom-0 left-2"
                        style={{ width: 45, height: 54 }}
                      />
                    </figure>
                    <p className="flex justify-center mt-2 text-xs font-medium">
                      Mike
                    </p>
                  </div>
                  <div className="enquiry-assistant__card">
                    <figure
                      className="enquiry-assistant__card--avatar"
                      style={{ width: 64, height: 64 }}
                    >
                      <img
                        src={iconAvatar}
                        alt=""
                        className="absolute bottom-0 left-2"
                        style={{ width: 45, height: 54 }}
                      />
                    </figure>
                    <p className="flex justify-center mt-2 text-xs font-medium">
                      Jess
                    </p>
                  </div>
                  <div className="enquiry-assistant__card">
                    <figure
                      className="enquiry-assistant__card--avatar"
                      style={{ width: 64, height: 64 }}
                    >
                      <img
                        src={iconAvatar}
                        alt=""
                        className="absolute bottom-0 left-2"
                        style={{ width: 45, height: 54 }}
                      />
                    </figure>
                    <p className="flex justify-center mt-2 text-xs font-medium">
                      Jake
                    </p>
                  </div>
                  <div className="enquiry-assistant__card">
                    <figure
                      className="enquiry-assistant__card--avatar"
                      style={{ width: 64, height: 64 }}
                    >
                      <img
                        src={iconAvatar}
                        alt=""
                        className="absolute bottom-0 left-2"
                        style={{ width: 45, height: 54 }}
                      />
                    </figure>
                    <p className="flex justify-center mt-2 text-xs font-medium">
                      Jenny
                    </p>
                  </div>
                  <div className="enquiry-assistant__card">
                    <figure
                      className="enquiry-assistant__card--avatar"
                      style={{ width: 64, height: 64 }}
                    >
                      <img
                        src={iconAvatar}
                        alt=""
                        className="absolute bottom-0 left-2"
                        style={{ width: 45, height: 54 }}
                      />
                    </figure>
                    <p className="flex justify-center mt-2 text-xs font-medium">
                      Asha
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default EnquiryAssistantSettings;
