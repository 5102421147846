import React from 'react'

const Header = ({ name, id, mainId }) => {

	window.onload = function(){
		document.getElementById(mainId).addEventListener("scroll", myFunction)
			function myFunction() {
				var header = document.getElementById(id);
				var sticky = 80;
				var scrollY = document.getElementById(mainId).scrollTop;
				if (scrollY >= sticky) {
					header.classList.add("sticky");
				} else {
					header.classList.remove("sticky");
				}
			}
		}
	return (
		<h3 className="card-heading text-grey-900 font-semibold" id={id}>
			{name}
		</h3>
	)
}

export default Header