import React from "react";

const ToggleReportLink = () => {
  return (
    <ul>
      <div
        to="/add-new-account-account-details"
        className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm block`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="general-settings__card-list__avatar-container">
            <svg
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 3.56V2.5C1 1.67157 1.67157 1 2.5 1H4.35238"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 16.44L1 17.5C1 18.3284 1.67157 19 2.5 19H4.35238"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23 3.56V2.5C23 1.67157 22.3284 1 21.5 1H19.6476"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23 16.44V17.5C23 18.3284 22.3284 19 21.5 19H19.6476"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 11.5V12.3333C18 13.438 17.1047 14.3333 16 14.3333H12.6667L10.1457 16.3501C9.8183 16.612 9.33333 16.3789 9.33333 15.9597V14.3333H8C6.89533 14.3333 6 13.438 6 12.3333V7C6 5.89533 6.89533 5 8 5H12"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle cx="17" cy="6" r="3" fill="#005C6F" />
            </svg>
          </figure>
          <div className="truncate pr-4">
            <h2 className="general-settings__card-list__title">Snapshot</h2>
            <p className="general-settings__card-list__desc">
              Full snapshot of your enquiries
            </p>
          </div>
        </div>
      </div>
      <div
        to="/add-new-account-select-account-type"
        className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="general-settings__card-list__avatar-container">
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.1333 9.73333V6.97051C17.1333 6.43491 16.9185 5.92168 16.537 5.54579L12.5068 1.57528C12.1326 1.20664 11.6284 1 11.1031 1H4C2.34315 1 1 2.34315 1 4V17.8C1 19.4569 2.34315 20.8 4 20.8H9.43333"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.6333 1.22534V4.49951C11.6333 5.60425 12.529 6.49975 13.6337 6.49951L16.9995 6.49878"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.93359 11.7344H9.93359"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.93359 8.79688H9.93359"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.93359 14.6641H7.80026"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.1699 12.0781C19.069 12.0781 21.4199 14.428 21.4199 17.3281C21.4199 20.1673 19.0049 22.5813 16.1657 22.5781C13.2677 22.576 10.9199 20.2261 10.9199 17.3281C10.9199 14.4291 13.2698 12.0781 16.1699 12.0781Z"
                fill="#005C6F"
              />
              <path
                d="M14.4199 17.3264H17.9199"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.1699 15.5768V19.0768"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </figure>
          <div className="truncate pr-4">
            <h3 className="general-settings__card-list__title">
              Channel reports
            </h3>
            <p className="general-settings__card-list__desc">
              Invoices, payment method, upgrade
            </p>
          </div>
        </div>
      </div>
      <div
        to="/add-new-account-select-payment"
        className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="general-settings__card-list__avatar-container">
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3754 1.42186C15.2712 3.31769 15.2712 6.39143 13.3754 8.28727C11.4796 10.1831 8.40584 10.1831 6.51001 8.28727C4.61418 6.39144 4.61418 3.31771 6.51001 1.42186C8.40583 -0.473955 11.4796 -0.473955 13.3754 1.42186Z"
                fill="#005C6F"
              />
              <path
                d="M12.2493 19.2831C12.2374 19.2833 12.2254 19.2834 12.2134 19.2834H2.68088C1.75256 19.2834 1 18.5309 1 17.6026L1.00044 17.5644C1.00486 17.3696 1.0142 17.2307 1.02843 17.1476C1.52274 14.2633 3.90439 12.0411 6.82473 11.9217V11.9217C6.98689 11.9184 7.14645 11.9599 7.29018 12.0351C8.19353 12.5074 9.09751 12.7435 10.0021 12.7435C10.6632 12.7435 11.3242 12.6174 11.9853 12.3652C12.6286 12.1198 13.3011 11.888 13.9791 12.008C14.1575 12.0396 14.3332 12.0791 14.5059 12.1261"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M20.9998 15.7366V13.4382H18.7014"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.9985 13.4382L17.5509 16.8858L14.432 14.5874L11.8049 16.8858"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </figure>
          <div className="truncate pr-4">
            <h3 className="general-settings__card-list__title">
              Team performance reports
            </h3>
            <p className="general-settings__card-list__desc">
              Add and manage questions
            </p>
          </div>
        </div>
      </div>
      <div
        to="/invite-account-owner"
        className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="general-settings__card-list__avatar-container">
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.1333 9.73333L17.0451 3.9542C17.0201 2.3154 15.6844 1 14.0455 1H7.92284H4C2.34315 1 1 2.34315 1 4V17.8C1 19.4569 2.34315 20.8 4 20.8H9.43333"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 17H6.87"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.25 12C19.149 12 21.5 14.3499 21.5 17.25C21.5 20.0892 19.085 22.5031 16.2458 22.5C13.3478 22.4979 11 20.148 11 17.25C11 14.3509 13.3499 12 16.25 12Z"
                fill="#005C6F"
              />
              <path
                d="M17.5 17.5H16V15"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 10V7"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M12 10V6"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M6 10V9"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </figure>
          <div className="truncate pr-4">
            <h3 className="general-settings__card-list__title">
              Scheduled reports
            </h3>
            <p className="general-settings__card-list__desc">
              Manage third party Integrations
            </p>
          </div>
        </div>
      </div>
      <div
        to="/complete-payment"
        className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="general-settings__card-list__avatar-container">
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.9918 3.00928C19.2448 4.26222 19.2448 6.29236 17.9918 7.5453C16.7389 8.79824 14.7088 8.79824 13.4558 7.5453C12.2029 6.29236 12.2029 4.26222 13.4558 3.00928C14.7088 1.75635 16.74 1.75635 17.9918 3.00928Z"
                stroke="#005C6F"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5406 3.4633C18.5433 4.46596 18.5433 6.09058 17.5406 7.09324C16.538 8.0959 14.9133 8.0959 13.9107 7.09324C12.908 6.09058 12.908 4.46596 13.9107 3.4633C14.9133 2.46064 16.5388 2.46064 17.5406 3.4633Z"
                stroke="#005C6F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.7239 2.06906V1"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.7239 8.48694V9.556"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9309 5.27832H20"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5163 5.27832H11.4473"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.99 3.00903L18.749 2.25"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.4573 7.53772L12.6982 8.29675"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.991 7.53772L18.75 8.29675"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.4573 3.00903L12.6982 2.25"
                stroke="#005C6F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.01318 17.5774V8.00562"
                stroke="#005C6F"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                d="M2 17.581V11.8628"
                stroke="#005C6F"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                d="M12.0264 17.5797V12.1101"
                stroke="#005C6F"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                d="M16.9985 17.5829V14.9579"
                stroke="#005C6F"
                stroke-width="2.5"
                stroke-linecap="round"
              />
            </svg>
          </figure>
          <div className="truncate pr-4">
            <h3 className="general-settings__card-list__title">
              Reporting Integrations
            </h3>
            <p className="general-settings__card-list__desc">
              Connect Google ads & analytics
            </p>
          </div>
        </div>
      </div>
    </ul>
  );
};

export default ToggleReportLink;
