import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/eb-logo.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic1 from "../assets/icons/icon-avatar.svg";
import iconGeneral from "../assets/icons/icon-table-tennis.svg";
import iconSales from "../assets/icons/icon-suitcase.svg";
import { useHistory } from "react-router-dom";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import ChannelHeader from "./Common/ChannelHeader";
import ChatPreviewListSample from "./ChatPreviewListSample";


const DashboardGmailConnect = () => {
  let history = useHistory();

  const nextScreen = () => {
    history.push("/facebook-page-existing-team-member");
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

         
        <section className="dashboard__main">
        <ChannelHeader
            text={"Facebook Page"}
            linkText="Settings & Notifications"
            HeaderIcon={() => (
              <figure className="avatar-container">
                <svg width="22" height="22" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left">
              <ChatPreviewListSample />
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
                <div>
                  <button className="btn btn--back btn--text">
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M4.91895 1L1.00195 5L4.91895 9"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 4.99634L11 4.99634"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <span className="ml-2 text-sm font-medium text-primary">
                      Back
                    </span>
                  </button>
                  <p className="text-grey-900 font-semibold mt-5">
                    Sign in to connect
                  </p>
                  <button className="btn btn--gmail-connect btn--wide-sm gap-2 mt-8" onClick={nextScreen}>
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none">
                      <path
                        d="M3.85674 4.78414V9.74352H2.4209C1.59247 9.74352 0.920898 9.07195 0.920898 8.24352V2.79423C0.920898 1.14897 2.79673 0.207445 4.11597 1.19054L7.35505 3.60432L10.6172 1.18113C11.9368 0.200891 13.8098 1.14279 13.8098 2.78664V8.24352C13.8098 9.07195 13.1382 9.74352 12.3098 9.74352H10.8602V4.78414L7.35505 7.38387L3.85674 4.78414Z"
                        fill="white"
                      />
                    </svg>
                    Sign in and connect
                  </button>
                  <p className="text-13 text-grey-800 mt-6 py-3 px-4 bg-grey-200 rounded-xl">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. <span className="text-primary font-medium cursor-pointer">Learn how to forward</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default DashboardGmailConnect;
