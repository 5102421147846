import React from "react";
import logo from "../../assets/icons/eb-logo.svg";
import qrcodeIcon from "../../assets/icons/qrcode.svg";
import scanQRCodeImg from '../../assets/icons/scanqrcode.svg';
const ScanQRCode = ({ isVerfy = true }) => {
    return (
        <main className="authentication-module">
            <div className="logo flex items-center justify-center mb-8">
                <img src={logo} alt="logo" />
            </div>
            <div className="authentication-module__container">
                {isVerfy ? <h1 className="text-2xl text-center authentication-module__heading mb-8 xxl:mb-12">
                    Scan the QR code
                </h1>
                    :
                    <h1 className="text-2xl text-center authentication-module__heading mb-8 xxl:mb-12">
                        Visit your Google <br />Authenticator app
                    </h1>
                }
                <div className="flex items-center justify-center m-auto" style={{
                    width: '250px',
                    height: '250px'
                }}>
                    <img src={qrcodeIcon} alt="" className="cursor-pointer" />
                </div>
                <div className="relative card-box m-auto mt-8" style={{ width: '304px', heigh: '130px' }}>
                    <img src={scanQRCodeImg} alt="" className="" style={{ padding: "33px 42px" }} />
                    <div className="flex items-center justify-center absolute -top-4 left-0 right-0 mx-auto text-grey-800 text-11 font-semibold uppercase bg-grey-400" style={{ width: 84, padding: '5px 8px', borderRadius: 20, 'box-shadow': ' inset 0px 0px 0px 1px #FFFFFF' }}>Example</div>
                </div>

                <p className="text-lg font-semibold text-grey-900 text-center mt-8">
                    Can’t scan the QR code?
                </p>
                <p className="text-sm text-grey-900 text-center font-medium mt-4">
                    Enter the 6-digit code from the mobile authetication app.
                </p>
                <div className="flex items-center justify-center mt-8">
                    <input
                        type="password"
                        className="input-field input-field__border input-field--sm"
                        style={{ width: '230px' }}
                    />
                </div>
                <button className="btn btn--primary btn--sm m-auto mt-8 text-base-1" style={{ width: '230px' }}>Verify</button>
            </div>
        </main>
    );
};
export default ScanQRCode;
