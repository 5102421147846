import React, { useState } from "react";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";
import iconTemplateHeader from "../assets/icons/icon-template-heder.svg";
import BoxTemplateHeader from "./Common/BoxTemplateHeader";
import closeGreen from "../assets/icons/icon-close-green.svg";
import { Link } from "react-router-dom";
import VideoIcon from "../assets/icons/bigVideoIcon.svg";

const BoxTemplate = () => {
  const [openBoxInfo, setOpenBoxInfo] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew setOpenBoxInfo={setOpenBoxInfo} />
        <section className="dashboard__main">
          <BoxTemplateHeader
            setModalShow={setModalShow}
            title="Recruitment"
            HeaderIcon={() => (
              <figure
                className="avatar-container avatar-container--contain"
                style={{ width: 50, height: 50 }}
              >
                <img
                  src={iconTemplateHeader}
                  alt="header-icon"
                  style={{ width: 28, height: 28 }}
                />
              </figure>
            )}
          />
          <div
            className="bg-grey-300 py-8 px-6 overflow-y-auto dashboard__main__body__section-right"
            style={{ height: "calc(100% - 90px)" }}
          >
            <div className="text-center">
              <h2 className="font-semibold text-xl">
                Create the perfect box to manage specific things
              </h2>
              <p className="mt-2 text-15 text-grey-800">
                Use one of our expert built, tried and tested templates or build
                a box from scratch
              </p>
            </div>
            <div className="flex mt-9" style={{ height: "auto" }}>
              <div className="dashboard__main--box-card py-10 flex justify-center">
                <div>
                  <figure
                    className="bg-primary flex justify-center items-center mb-2 ml-5"
                    style={{ width: 38, height: 38, borderRadius: "50%" }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 1V15"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 8H1"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </figure>
                  <div>
                    <p className="text-15 font-medium">
                      Build a Box <br />
                      from scratch
                    </p>
                  </div>
                </div>
              </div>
              <Link
                className="dashboard__main--box-card p-5"
                to="/box-template-preview"
              >
                <h3 className="dashboard__main--box-card__header">
                  Sales pipeline
                </h3>
                <p className="dashboard__main--box-card__desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div className="flex justify-between mt-8 items-center">
                  <button
                    className="btn text-13 text-primary border border-grey-400"
                    style={{ padding: "6px 15px" }}
                  >
                    Use this
                  </button>
                  <div className="flex items-center">
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z"
                        fill="#005C6F"
                      />
                    </svg>
                    <span className="ml-1 text-13 font-semibold text-primary">
                      Preview
                    </span>
                  </div>
                </div>
              </Link>
              <Link
                className="dashboard__main--box-card p-5"
                to="/box-template-preview"
              >
                <h3 className="dashboard__main--box-card__header">
                  Job applications
                </h3>
                <p className="dashboard__main--box-card__desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div className="flex justify-between mt-8 items-center">
                  <button
                    className="btn text-13 text-primary border border-grey-400"
                    style={{ padding: "6px 15px" }}
                  >
                    Use this
                  </button>
                  <div className="flex items-center">
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z"
                        fill="#005C6F"
                      />
                    </svg>
                    <span className="ml-1 text-13 font-semibold text-primary">
                      Preview
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="flex mt-5" style={{ height: "auto" }}>
              <Link
                className="dashboard__main--box-card p-5"
                to="/box-template-preview"
              >
                <h3 className="dashboard__main--box-card__header">
                  Customer Support
                </h3>
                <p className="dashboard__main--box-card__desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div className="flex justify-between mt-8 items-center">
                  <button
                    className="btn text-13 text-primary border border-grey-400"
                    style={{ padding: "6px 15px" }}
                  >
                    Use this
                  </button>
                  <div className="flex items-center">
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z"
                        fill="#005C6F"
                      />
                    </svg>
                    <span className="ml-1 text-13 font-semibold text-primary">
                      Preview
                    </span>
                  </div>
                </div>
              </Link>
              <Link
                className="dashboard__main--box-card p-5"
                to="/box-template-preview"
              >
                <h3 className="dashboard__main--box-card__header">
                  Competition entries
                </h3>
                <p className="dashboard__main--box-card__desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div className="flex justify-between mt-8 items-center">
                  <button
                    className="btn text-13 text-primary border border-grey-400"
                    style={{ padding: "6px 15px" }}
                  >
                    Use this
                  </button>
                  <div className="flex items-center">
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z"
                        fill="#005C6F"
                      />
                    </svg>
                    <span className="ml-1 text-13 font-semibold text-primary">
                      Preview
                    </span>
                  </div>
                </div>
              </Link>
              <Link
                className="dashboard__main--box-card p-5"
                to="/box-template-preview"
              >
                <h3 className="dashboard__main--box-card__header">
                  New employee onboarding
                </h3>
                <p className="dashboard__main--box-card__desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div className="flex justify-between mt-8 items-center">
                  <button
                    className="btn text-13 text-primary border border-grey-400"
                    style={{ padding: "6px 15px" }}
                  >
                    Use this
                  </button>
                  <div className="flex items-center">
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z"
                        fill="#005C6F"
                      />
                    </svg>
                    <span className="ml-1 text-13 font-semibold text-primary">
                      Preview
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="flex mt-5" style={{ height: "auto" }}>
              <Link
                className="dashboard__main--box-card p-5"
                to="/box-template-preview"
              >
                <h3 className="dashboard__main--box-card__header">
                  New customers
                </h3>
                <p className="dashboard__main--box-card__desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div className="flex justify-between mt-8 items-center">
                  <button
                    className="btn text-13 text-primary border border-grey-400"
                    style={{ padding: "6px 15px" }}
                  >
                    Use this
                  </button>
                  <div className="flex items-center">
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z"
                        fill="#005C6F"
                      />
                    </svg>
                    <span className="ml-1 text-13 font-semibold text-primary">
                      Preview
                    </span>
                  </div>
                </div>
              </Link>
              <Link
                className="dashboard__main--box-card p-5"
                to="/box-template-preview"
              >
                <h3 className="dashboard__main--box-card__header">
                  Cold lead follow up
                </h3>
                <p className="dashboard__main--box-card__desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div className="flex justify-between mt-8 items-center">
                  <button
                    className="btn text-13 text-primary border border-grey-400"
                    style={{ padding: "6px 15px" }}
                  >
                    Use this
                  </button>
                  <div className="flex items-center">
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z"
                        fill="#005C6F"
                      />
                    </svg>
                    <span className="ml-1 text-13 font-semibold text-primary">
                      Preview
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </section>
      {modalShow && (
        <div className="modal show" style={{ top: "0px", height: "100%" }}>
          <div
            className="modal__container"
            style={{ background: "transparent", maxHeight: "none" }}
          >
            <div
              className="modal__header modal__header--plain"
              style={{
                justifyContent: "flex-end",
                paddingRight: "0px",
                paddingBottom: "0.5rem",
                marginTop: "48px",
              }}
            >
              <div
                className="flex border border-grey-400 cursor-pointer border-radius-30"
                style={{ background: "white" }}
              >
                <div
                  className="flex items-center py-1 pr-4 pl-3"
                  onClick={() => setModalShow(false)}
                >
                  <img
                    src={closeGreen}
                    alt="arrow-right"
                    className="cursor-pointer"
                  />
                  <p
                    className="text-13 font-medium ml-2"
                    style={{ color: "#005C6F" }}
                  >
                    Close Video
                  </p>
                </div>
              </div>
            </div>
            <div className="" style={{ padding: "0px" }}>
              <img className="cursor-pointer" src={VideoIcon} alt="" />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default BoxTemplate;
