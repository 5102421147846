import React, {useState} from 'react'
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import closeIcon from "../../assets/icons/icon-close-red.svg";

const MatchingFieldsModal = ({matchingFieldsModal, setMatchingFieldsModal, setShowUploadedModal}) => {
	const [popUpShow, setPopUpShow] = useState(false);
	const [openDropdown, setOpenDropdown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
	const [modalShow, hideModalShow] = useState(true);
	const fieldsName = [
    {
      name: "First Name",
    },
    {
      name: "Last Name",
    },
    {
      name: "Contact Type",
    },
    {
      name: "Organization",
    },
    {
      name: "Phone",
    },
    {
      name: "Phone type",
    },
    {
      name: "Email",
    },
    {
      name: "Billing address",
    },
  ];
	return (
		<div className={`modal modal--match-fields ${matchingFieldsModal ? 'show' : ''}`}>
			<div className="modal__container">
				<div className={`modal__header`}>
					<h2 className="modal__heading pr-5">Match your fields</h2>
					<div className="modal__close-icon" onClick={() => setMatchingFieldsModal(false)}>
						<img src={modalCloseIcon} alt="" />
					</div>
				</div>
				<div
					className={`relative modal__body px-12 py-6 ${popUpShow ? "blur-bg" : ""
						}`}
				>
					<div className="flex -mx-5">
						<div className="w-3/6 px-4">
							<label className="font-semibold">CSV column names</label>
						</div>
						<div className="w-3/6 px-4">
							<label className="font-semibold">Enquirybox questions</label>
						</div>
					</div>
					{fieldsName.map((item, index) => {
						return (
							<div className="flex -mx-5 mt-5">
								<div className="w-3/6 px-5">
									<div
										className="w-full flex items-center px-5 py-1 bg-grey-300 rounded-lg truncate"
										style={{
											boxShadow:
												"inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
											height: 42,
										}}
									>
										<label className="text-base-1 truncate">
											{item.name}
										</label>
									</div>
								</div>
								<div className="relative w-3/6 px-5">
									<input
										className={`input-field input-field--select input-field--md ${popUpShow && "error"
											}`}
										placeholder={`Select ${item.name}`}
										onClick={() => {
											setActiveIndex(index);
											setOpenDropdown(true);
										}}
									/>
									{index === activeIndex && openDropdown && (
										// <div
										//   className={`dropdown-menu dropdown-menu__upload shadow-none open`}>
										//   {dropdownItems.map((item) => (
										//     <div
										//       className="dropdown-menu__list"
										//       onClick={() => {
										//         setOpenDropdown(false);
										//       }}>
										//       {item.value}
										//     </div>
										//   ))}
										// </div>
										<div className="dropdown-menu dropdown-menu--sm p-2 open">
											<div className="pb-2 border-b border-grey-400">
												<h6 className="text-xs font-medium text-grey-700 mb-1 mt-2 ml-3">
													Other
												</h6>
												<div className="dropdown-menu__list">
													Do not import
												</div>
												<div className="dropdown-menu__list">
													Create a question
												</div>
											</div>
											<div className="pb-2 border-b border-grey-400">
												<h6 className="text-xs font-medium text-grey-700 mb-1 mt-2 ml-3">
													Group heading
												</h6>
												<div className="dropdown-menu__list">
													Anniversary
												</div>
												<div className="dropdown-menu__list">Birthday</div>
											</div>
											<div className="">
												<h6 className="text-xs font-medium text-grey-700 mb-1 mt-2 ml-3">
													Group heading
												</h6>
												<div className="dropdown-menu__list">Phone 1</div>
												<div className="dropdown-menu__list">
													Phone 1 ext
												</div>
												<div className="dropdown-menu__list">
													Phone 1 ext
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						);
					})}
					<div className="flex items-center justify-end gap-3 mt-12">
						<button className="btn btn--secondary btn--md min-w-120">
							Cancel
						</button>
						<button
							className="btn btn--error btn--md btn--wide-20 min-w-120"
							onClick={() => {
								setPopUpShow(true);
							}}
						>
							Finish upload
						</button>
					</div>
				</div>
				<div
					className={`match-field-error-popup ${popUpShow ? "show" : ""}`}
				>
					<p className="text-sm text-red-900 text-center">
						Please match all imported fields to enquirybox questions.
						Unmatched fields are marked in red
					</p>
					<img
						src={closeIcon}
						alt="icon-close"
						className="absolute top-3 right-3 cursor-pointer"
						onClick={() => {setShowUploadedModal(true); setMatchingFieldsModal(false)}}
					/>
				</div>
			</div>
		</div>
	)
}

export default MatchingFieldsModal