import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/eb-logo.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic1 from "../assets/icons/icon-avatar.svg";
import steadLogo from "../assets/images/stead-logo.png";
import facebookLogo from "../assets/images/facebook-logo.png";
import iconCheckCircle from "../assets/icons/icon-green-check-circle.svg";
import Tab from "./Tabs";
import iconGeneral from "../assets/icons/icon-table-tennis.svg";
import iconSales from "../assets/icons/icon-suitcase.svg";
import { useHistory } from "react-router-dom";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import ChatPreviewListSample from "./ChatPreviewListSample";
import ChannelHeader from "./Common/ChannelHeader";
import HelperVideo from "./Common/HelperVideo";

const FacebookPageNewTeamMember = () => {
  const [tabsView, setTabsView] = useState(true);
  let history = useHistory();

  const nextScreen = () => {
    history.push("/dashboard-microsoft-connect");
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main">
          <ChannelHeader
            text={"Facebook Page"}
            linkText="Settings & Notifications"
            HeaderIcon={() => (
              <figure className="avatar-container">
                <svg width="22" height="22" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left">
              <ChatPreviewListSample />
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
                <div className="flex items-center gap-2">
                  <img src={iconCheckCircle} alt="icon-check-cirlce" />
                  <h3 className="text-grey-900 font-semibold">
                    Facebook page is connected
                  </h3>
                </div>
								<div className="mt-4">
									<HelperVideo />	
								</div>						
                {/* <div className="video-container mt-4">
                  <img
                    src={contactFormPlaceholder}
                    alt=""
                    className="w-full h-full"
                  />
                </div> */}
                <div className="mt-6">
                  <h3 className="font-bold" onClick={nextScreen}>
                    Invite the team
                  </h3>
                  <div className="mt-5">
                    <Tab
                      tabs={[
                        {
                          name: "Existing team member",
                          onClick: () => setTabsView(true),
                        },
                        {
                          name: "New team member",
                          onClick: () => setTabsView(false),
                        },
                      ]}
                    />
                    {tabsView ? (
                      <ul className="mt-4">
                        <li className="teams-avatar-list flex items-center">
                          <figure
                            className="avatar-container avatar-container--full"
                            style={{ width: 32, height: 32 }}
                          >
                            <img src={avatarPic} alt="avatar-pic" />
                          </figure>
                          <div className="ml-3 text-sm font-medium">
                            Charles Wundeng
                          </div>
                          <div className="ml-auto hover-visible">
                            <span className="text-sm font-semibold text-primary">
                              Invite
                            </span>
                          </div>
                          <div className="flex items-center ml-auto gap-2">
                            <svg
                              width="12"
                              height="10"
                              viewBox="0 0 12 10"
                              fill="none"
                            >
                              <path
                                d="M1 5.37637L4.13194 9L11 1"
                                stroke="#14AE5C"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="text-13 text-green-900">
                              Invited
                            </span>
                          </div>
                        </li>
                        <li className="teams-avatar-list flex items-center">
                          <figure
                            className="avatar-container avatar-container--full"
                            style={{ width: 32, height: 32 }}
                          >
                            <img src={avatarPic} alt="avatar-pic" />
                          </figure>
                          <div className="ml-3 text-sm font-medium">
                            Charles Wundeng
                          </div>
                          <div className="ml-auto hover-visible">
                            <span className="text-sm font-semibold text-primary">
                              Invite
                            </span>
                          </div>
                          <div className="flex items-center ml-auto gap-2">
                            <svg
                              width="12"
                              height="10"
                              viewBox="0 0 12 10"
                              fill="none"
                            >
                              <path
                                d="M1 5.37637L4.13194 9L11 1"
                                stroke="#14AE5C"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="text-13 text-green-900">
                              Invited
                            </span>
                          </div>
                        </li>
                        <li className="teams-avatar-list flex items-center">
                          <figure
                            className="avatar-container avatar-container--full"
                            style={{ width: 32, height: 32 }}
                          >
                            <img src={avatarPic} alt="avatar-pic" />
                          </figure>
                          <div className="ml-3 text-sm font-medium">
                            Charles Wundeng
                          </div>
                          <div className="ml-auto hover-visible">
                            <span className="text-sm font-semibold text-primary">
                              Invite
                            </span>
                          </div>
                          <div className="flex items-center ml-auto gap-2">
                            <svg
                              width="12"
                              height="10"
                              viewBox="0 0 12 10"
                              fill="none"
                            >
                              <path
                                d="M1 5.37637L4.13194 9L11 1"
                                stroke="#14AE5C"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="text-13 text-green-900">
                              Invited
                            </span>
                          </div>
                        </li>
                        <li className="teams-avatar-list flex items-center">
                          <figure
                            className="avatar-container avatar-container--full"
                            style={{ width: 32, height: 32 }}
                          >
                            <img src={avatarPic} alt="avatar-pic" />
                          </figure>
                          <div className="ml-3 text-sm font-medium">
                            Charles Wundeng
                          </div>
                          <div className="ml-auto hover-visible">
                            <span className="text-sm font-semibold text-primary">
                              Invite
                            </span>
                          </div>
                          <div className="flex items-center ml-auto gap-2">
                            <svg
                              width="12"
                              height="10"
                              viewBox="0 0 12 10"
                              fill="none"
                            >
                              <path
                                d="M1 5.37637L4.13194 9L11 1"
                                stroke="#14AE5C"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="text-13 text-green-900">
                              Invited
                            </span>
                          </div>
                        </li>
                        <li className="teams-avatar-list flex items-center">
                          <figure
                            className="avatar-container avatar-container--full"
                            style={{ width: 32, height: 32 }}
                          >
                            <img src={avatarPic} alt="avatar-pic" />
                          </figure>
                          <div className="ml-3 text-sm font-medium">
                            Charles Wundeng
                          </div>
                          <div className="ml-auto hover-visible">
                            <span className="text-sm font-semibold text-primary">
                              Invite
                            </span>
                          </div>
                          <div className="flex items-center ml-auto gap-2">
                            <svg
                              width="12"
                              height="10"
                              viewBox="0 0 12 10"
                              fill="none"
                            >
                              <path
                                d="M1 5.37637L4.13194 9L11 1"
                                stroke="#14AE5C"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="text-13 text-green-900">
                              Invited
                            </span>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <div className="mt-4">
                        <div className="">
                          <label className="input-field-label">Email</label>
                          <input
                            type="text"
                            className="input-field input-field--md"
                            placeholder="Email"
                          />
                          <button className="btn btn--primary btn--md mt-10 ml-auto">
                            Send Invite
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default FacebookPageNewTeamMember;
