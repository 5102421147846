import React,{useState} from 'react';
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import ChannelHeader from "./Common/ChannelHeader";
import salesIcon from '../assets/icons/icon-sales-box.svg';
import deleteIcon from '../assets/icons/icon-Delete.svg';
import iconMoreRound from '../assets/icons/icon-more-round.svg';
import user1Icon from '../assets/icons/icon-avatar-image-1.svg';
import user2Icon from '../assets/icons/icon-avatar-image-2.svg';
import user3Icon from '../assets/icons/icon-avatar-image-3.svg';
import iconFb from '../assets/icons/Icon-fb-20.svg';
import iconInsta from '../assets/icons/Icon-Instagram-20.svg';
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import { Link } from "react-router-dom";
import HelperVideo from './Common/HelperVideo';

const ManageChannelConnectionMessage = () => {
	const [showDelete, setShowDelete] = useState(0);
	return (
		<main className="dashboard">
		<section className="dashboard__container flex items-start">
			<SidebarLinks />
			<SidebarExpanded />
			<section className="dashboard__main">
				<ChannelHeader
					text={"Sales"}
					linkText="Settings & Notifications"
					HeaderIcon={() => (
						<figure className="avatar-container avatar-container--contain">
							<img src={salesIcon} alt='' style={{ width: 30, height: 30 }} />
						</figure>
					)}
					showToggle={true}
				/>
				<div className="dashboard__main__body">
						<div className="dashboard__main__body__section-left" style={{
							padding: "17px 20px 20px 26px"
						}}>
							<Link to="/sales-box-first-view">
								<button className="btn btn--back rounded btn--xs border border-grey-400">
									<svg width="12" height="10" viewBox="0 0 12 10" fill="none">
										<path
											d="M4.91895 1L1.00195 5L4.91895 9"
											stroke="#005C6F"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M1 4.99634L11 4.99634"
											stroke="#005C6F"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<span className="ml-2 text-13 font-medium text-primary">
										Back to Stages
									</span>
								</button>
							</Link>
							<div className="box-card-container mr-5 clip-path mt-15" style={{ minWidth: '100%' }}>
								<div className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white" style={{
									width: 61,
									height: 24,
									border: "1px solid rgba(4, 145, 171, 0.3)",
									borderRadius: "20px",
								}}>$153
								</div>
								<div className="flex justify-between ml-3 mt-2">
									<div className='font-semibold text-lg'>Won</div>
									<div className='flex items-center'>
										<div className='cursor-pointer relative' onClick={() => setShowDelete(1)}>
											<img src={iconMoreRound} alt='' style={{ width: 16, height: 4 }} />
											<div className={`dropdown-menu dropdown-menu--more ${showDelete === 1 ? 'open' : ''}`} style={{ top: 10 }}>
												<div className="dropdown-menu__list red-text">
													<img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Delete
												</div>
											</div>
										</div>
										<div>
											<div className="text-sm flex justify-center text-white ml-3 font-semibold bg-secondary" style={{
												width: 32,
												height: 22,
												borderRadius: "20px",
											}}>3</div>
										</div>
									</div>
								</div>
								<div className='mt-5'>
									<div className='box-card' style={{width: "100%"}}>
										<div className='flex mt-1'>
											<figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
												<img src={user2Icon} alt='' />
												<figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
													<img src={iconFb} alt='' className="" style={{ width: 12, height: 12 }} />
												</figure>
											</figure>
											<div>
												<div className='font-semibold'>Alex Foster</div>
												<div className='text-15 truncate'>How are you !</div>
											</div>
										</div>
										<div>
											<div className='flex justify-end text-sm' style={{marginTop: 30}}>May 29</div>
										</div>
									</div>
									<div className='box-card mt-3' style={{width: "100%"}}>
										<div className='flex mt-1'>
											<figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
												<img src={user1Icon} alt='' />
												<figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
													<img src={iconInsta} alt='' className="" style={{ width: 12, height: 12 }} />
												</figure>
											</figure>
											<div>
												<div className='font-semibold'>Lisa Fotios</div>
												<div className='text-15 truncate'>Hey</div>
											</div>
										</div>
										<div>											
											<div className='flex justify-end text-sm' style={{marginTop: 30}}>30m</div>
										</div>
									</div>
									<div className='box-card mt-3' style={{width: "100%"}}>
										<div className='flex mt-1'>
											<figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
												<img src={user3Icon} alt='' />
												<figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
													<img src={iconFb} alt='' className="" style={{ width: 12, height: 12 }} />
												</figure>
											</figure>
											<div>
												<div className='font-semibold'>Merva Sahin</div>
												<div className='text-15 truncate'>Good night</div>
											</div>
										</div>
										<div>		
											<div className="text-white text-10 font-semibold flex justify-center bg-green-700" style={{
												width: 36,
												height: 16,
												borderRadius: "20px",
												marginLeft: "calc(100% - 35px)"
											}}>
												New
											</div>									
											<div className='flex justify-end text-sm' style={{marginTop: 14}}>May 31</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
                <div className="mb-5">
                  <div className="flex items-center gap-2">
                    <h3 className="text-grey-900 font-semibold">
											Manage channel connections
                    </h3>
                  </div>
									<div className='mt-4'>
										<HelperVideo />
									</div>							
                  {/* <div className="video-container mt-4">
                    <img
                      src={contactFormPlaceholder}
                      alt=""
                      className="w-full h-full"
                    />
                  </div> */}
                </div>
								<div>
									<h3 className="text-grey-900 font-semibold">
										Your Channels
									</h3>
									<div className='mt-5'>
										<div className='channels__list'>
											<div className='flex'>
												<figure className="avatar-container">
													<svg width="17" height="17" viewBox="0 0 16 16" fill="none">
														<path
															d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
															fill="#4C5254"
														/>
													</svg>
												</figure>
												<h2 className='ml-3 text-sm font-medium'>Stead</h2>
											</div>
											<div>
												<div className="channels__list--toggle-slider">
													<input type="checkbox" id="1" className="channels__list--toggle-slider__checkbox" />
													<label htmlFor="1" className="channels__list--toggle-slider__label">
														<code className="channels__list--toggle-slider__ball" />
													</label>
												</div>
											</div>
										</div>
										<div className='channels__list' style={{marginTop: 2}}>
											<div className='flex'>
												<figure className="avatar-container">
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
														<path
															d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
															fill="#4C5254"
														/>
													</svg>
												</figure>
												<h2 className='ml-3 text-sm font-medium'>Hunley Weddings</h2>
											</div>
											<div>
												<div className="channels__list--toggle-slider">
													<input type="checkbox" id="2" className="channels__list--toggle-slider__checkbox" />
													<label htmlFor="2" className="channels__list--toggle-slider__label">
														<code className="channels__list--toggle-slider__ball" />
													</label>
												</div>
											</div>
										</div>
										<div className='channels__list' style={{marginTop: 2}}>
											<div className='flex'>
												<figure className="avatar-container">
													<svg width="17" height="17" viewBox="0 0 16 16" fill="none">
														<path
															d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
															fill="#4C5254"
														/>
													</svg>
												</figure>
												<h2 className='ml-3 text-sm font-medium'>Facebook pages</h2>
											</div>
											<div>
												<div className="channels__list--toggle-slider">
													<input type="checkbox" id="3" className="channels__list--toggle-slider__checkbox" />
													<label htmlFor="3" className="channels__list--toggle-slider__label">
														<code className="channels__list--toggle-slider__ball" />
													</label>
												</div>
											</div>
										</div>
										<div className='channels__list' style={{marginTop: 2}}>
											<div className='flex'>
												<figure className="avatar-container">
													<svg width="17" height="15" viewBox="0 0 16 14" fill="none">
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M0 3.5V10.5C0 12.433 1.59188 14 3.55556 14H12.4444C14.4081 14 16 12.433 16 10.5V3.5C16 1.567 14.4081 0 12.4444 0H3.55556C1.59188 0 0 1.567 0 3.5ZM3.3009 2.82049C2.93328 2.57031 2.43658 2.67171 2.1915 3.04699C1.94642 3.42228 2.04576 3.92932 2.41338 4.17951L6.9223 7.24808C7.5749 7.69221 8.4251 7.69221 9.0777 7.24808L13.5866 4.17951C13.9543 3.92932 14.0536 3.42228 13.8085 3.04699C13.5634 2.67171 13.0667 2.57031 12.6991 2.82049L8.19018 5.88905C8.07502 5.96743 7.92498 5.96743 7.80982 5.88905L3.3009 2.82049Z"
															fill="#4C5254"
														/>
													</svg>
												</figure>
												<h2 className='ml-3 text-sm font-medium'>Wedding email</h2>
											</div>
											<div>
												<div className="channels__list--toggle-slider">
													<input type="checkbox" id="4" className="channels__list--toggle-slider__checkbox" checked/>
													<label htmlFor="4" className="channels__list--toggle-slider__label">
														<code className="channels__list--toggle-slider__ball" />
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
              </div>
            </div>
					</div>
			</section>
		</section>
		</main>
	)
}

export default ManageChannelConnectionMessage