import React, { useState } from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import { useHistory } from "react-router-dom";
import avatarIcon1 from "../../assets/icons/icon-avatar-image-2.svg";
import avatarIcon2 from "../../assets/icons/icon-avatar-image-1.svg";
import backIcon from "../../assets/icons/icon-green-back.svg";
import iconBorderCheckCircle from "../../assets/icons/icon-border-check-circle.svg";

const RequestSent = ({setOpenSuccessModal, openSuccessModal}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [clickedName, setClickedName] = useState("");
  const [clickedImage, setClickedImage] = useState(null);

  let history = useHistory();

  const goBack = () => {
    history.push('/channel-hide-or-request')
  };
  return (
    <div className={`modal modal--type2 ${openSuccessModal ? 'show' : ''}`}>
      <div className="modal__container">
        <div className="modal__header">
          <h2 className="modal__heading">
            Joining request sent
          </h2>
          <div className="modal__close-icon" onClick={() => setOpenSuccessModal(false)}>
            <img
              src={modalCloseIcon}
              alt=""
              // onClick={goBack}
            />
          </div>
        </div>
        <div className="modal__body mt-10 pb-8">
          <img
            src={iconBorderCheckCircle}
            alt="icon-border-check-circle"
            className="mx-auto"
          />
          <p className="text-lg font-semibold text-center mt-4">
            Joining request sent to
          </p>
          <div className="flex items-center justify-center mt-6">
            <figure
              className="avatar-container mr-3"
              style={{
                width: 26,
                height: 26,
                "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
              }}
            >
              <img src={avatarIcon1} alt="" className="" />
            </figure>
            <p className="text-sm font-semibold">Charles Wundeng</p>
          </div>
          <p className="text-sm text-center w-6/12 mx-auto mt-6">
            Once accepted, you will receive a notification from your enquiry
            assistant
          </p>
          <div className="flex justify-end pr-30 mt-32">
            <button className="btn btn--primary btn--wide-md" onClick={() => setOpenSuccessModal(false)}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestSent;
