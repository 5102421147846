import React from 'react';
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";

const DeleteConfirmation = ({ openDeleteConfirmation, setOpenDeleteConfirmation }) => {
	return (
		<div className={`modal modal--channels-request ${openDeleteConfirmation ? 'show' : ''}`}>
			<div className="modal__container">
				<div className=" modal__header modal__header--plain flex" style={{ justifyContent: 'flex-end' }}>
					<div className="modal__close-icon" onClick={() => setOpenDeleteConfirmation(false)}>
						<img src={modalCloseIcon} alt="" />
					</div>
				</div>
				<div className="modal__body pb-10">
					<div className="flex items-center justify-center gap-2 mt-6">

						<h2 className="text-xl font-semibold text-center">
							Are you sure you want to delete this channel?
						</h2>
					</div>
					<div className="text-13 text-center mt-3 px-16 ">
						Once deleted you will not be able to get it back. You will have to reconnect it again. Contacts in the channel will be stored in your all contacts list.
					</div>
					<div className="flex items-center gap-2 justify-center px-8 mt-6">
						<button className="btn btn--wide-md-40 btn--cancel text-base-1 mr-3" onClick={() => setOpenDeleteConfirmation(false)}>
							Cancel
						</button>
						<button className="btn btn--wide-md-40 text-base-1 bg-red-900 text-white mr-3">
							Delete
						</button>
					</div>
				</div>
			</div>
		</div >
	)
}

export default DeleteConfirmation;