import React, { useState } from "react";
import DeleteConfirmation from "./modal/DeleteConfirmation";
import iconVideoPlayer from "../assets/icons/icon-video-player.svg";

const SetUp = ({ data }) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  return (
    <div>
      <h2 className="text-2xl font-semibold text-center mb-8">Setup</h2>
      {data.map((ele, index) => {
        return (
          <div
            className="flex justify-between card-box py-5 px-6 mb-5"
            key={index}
          >
            <div className="font-semibold">{ele.title}</div>
            {ele.isDelete ? (
              <div>
                <button
                  className="btn bg-red-900 text-13 text-white"
                  style={{ padding: "5px 16px 5px 12px", borderRadius: 6 }}
                  onClick={() => setOpenDeleteConfirmation(true)}
                >
                  <img
                    src={ele.icon}
                    alt=""
                    style={{ width: 14, height: 14, marginRight: 4 }}
                  />
                  {ele.iconTitle}
                </button>
              </div>
            ) : (
              <div>
                <button
                  className={`btn text-13 text-white ${
                    ele.isFb
                      ? "btn--facebook"
                      : ele.isEmail
                      ? "btn--gmail-connect"
                      : "btn--primary"
                  }`}
                  style={{ padding: "5px 16px 5px 12px", borderRadius: 6 }}
                >
                  <img
                    src={ele.icon}
                    alt=""
                    style={{ width: 14, height: 14, marginRight: 4 }}
                  />
                  {ele.iconTitle}
                </button>
              </div>
            )}
          </div>
        );
      })}
      <div className="card-box pt-5 px-6" style={{ paddingBottom: 35 }}>
        <div className="font-semibold">Email forwarding</div>
        <div className="flex justify-between items-center py-3 pr-5 pl-4 bg-grey-90 rounded-md mt-3">
          <div className="font-semibold text-sm pr-3">
            Understand email forwarding in 1 minute
          </div>
          <div className="flex items-center flex-shrink-0">
            <img
              src={iconVideoPlayer}
              alt="player"
              style={{ width: 16, height: 16, marginRight: 4 }}
            />
            <div className="font-medium text-13 text-primary">Watch video</div>
          </div>
        </div>
        <div className="text-15 text-grey-800 mt-5">
          To recieve and reply to emails from your email address, copy the email
          forwarding address below and in your email provider forward your
          emails to this address
        </div>
        <div className="relative mt-7">
          <label className="block font-semibold text-sm">
            Email forwarding address
          </label>
          <div className="mt-4 relative">
            <input
              className="input-field input-field--md"
              style={{ paddingRight: 110 }}
              placeholder="folders02/1axsRtXwQ_H6JOxbYzQPqPeXDrEs..."
            />
            <button className="btn btn--primary btn--sm btn--copy w-2/12">
              Copy
            </button>
          </div>
        </div>
      </div>
      <DeleteConfirmation
        openDeleteConfirmation={openDeleteConfirmation}
        setOpenDeleteConfirmation={setOpenDeleteConfirmation}
      />
    </div>
  );
};

export default SetUp;
