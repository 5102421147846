import React, { useState, useRef } from "react";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GlobelNavLinks from "./Common/GlobelNavlinks";
import imgIcon from "../assets/icons/img-icon.svg";
import ProgressBar from "./Common/ProgressBar";
import sampleLogo from "../assets/images/sample-logo.png";
const GlobalBusinessInfo = ({ data }) => {
  const [activeScreen, setActiveScreen] = useState(0);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });
  const [showUpload, setShowUpload] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const snackbarRef = useRef(null);
  const [type, setType] = useState(false);
  const handleChange = (event) => {
    snackbarRef.current.show();
    event.target.checked ? setType("success") : setType("fail");
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const toggleBtn = () => {
    return (
      <div className="toggle-slider">
        <input
          type="checkbox"
          id={`externalEmail-2345`}
          // defaultChecked={false}
          className="toggle-slider__checkbox"
          name="checkedA"
          value={state.checkedA}
          onChange={handleChange}
        />
        <label htmlFor={`externalEmail-2345`} className="toggle-slider__label">
          <code className="toggle-slider__ball"></code>
        </label>
      </div>
    );
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"Account settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <GlobelNavLinks
                setActiveScreen={setActiveScreen}
                activeScreen={6}
              />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay p-10">
              <div className="flex flex-col items-center">
                <h2 className="text-2xl font-semibold">Business info</h2>
                <p className="text-grey-800 text-base-1 mt-2">
                  Opening hours, logo & branding
                </p>
              </div>

              <div className="card-box mt-6 px-6 py-5">
                <h4 className="text-lg font-semibold">Logo & Branding</h4>
                <p className="text-base-1 text-grey-800">
                  Your logo and branding colours will be used on any public
                  facing form pages.
                </p>
                <div className="mt-6 p-10 border border-dashed border-grey-500 border-radius-10 bg-white">
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center">
                      <figure
                        className="avatar-container avatar--full bg-grey-300"
                        style={{
                          width: 70,
                          height: 70,
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <img src={imgIcon} />
                      </figure>
                    </div>
                    <p
                      className="text-grey-900 text-sm mt-3"
                      onClick={() => setShowUpload(true)}
                    >
                      Drag or{" "}
                      <span className="text-primary-900 font-semibold">
                        Upload
                      </span>{" "}
                      your company logo
                    </p>
                    <p className="text-grey-600 text-xs mt-3">Max - 100kb</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <span className="text-13 font-semibold">
                        sensu logo1.png
                      </span>
                      <div className="flex items-center gap-2">
                        <span
                          className="text-13 text-grey-800"
                          onClick={() => setUploaded(true)}
                        >
                          15 KB
                        </span>
                        <span className="text-primary text-sm font-medium cursor-pointer">
                          Cancel
                        </span>
                      </div>
                    </div>
                    <div className="mt-3">
                      <ProgressBar progress="20%" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <figure
                          className="avatar-container avatar-container--contain bg-grey-200"
                          style={{
                            width: 70,
                            height: 66,
                            borderRadius: 6,
                            padding: 6,
                          }}
                        >
                          <img src={sampleLogo} alt="logo" />
                        </figure>
                        <div className="flex flex-col ml-4">
                          <span className="text-sm font-medium">
                            Sensu logo1.png
                          </span>
                          <span className="text-grey-700 text-xs">60kb</span>
                        </div>
                      </div>
                      <div className="">
                        <input
                          type="file"
                          id="fileUpload"
                          className="file-upload__input"
                        />
                        <label for="fileUpload" className="text-primary text-13 font-medium bg-white border border-secondary rounded-md cursor-pointer" style={{ padding: '6px 15px', }}>Replace Logo</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="flex items-center justify-between">
                    <h4 className="text-base-1 font-semibold">Primary colour</h4>
                    <div className="w-5/12 flex items-center justify-between bg-white rounded-lg" style={{ border: '1.5px solid #C4C2C0', padding: '5px 8px 5px 15px' }}>
                      <span className="text-base-1">#005C6F</span>
                      <code className="bg-primary rounded-md" style={{ width: 40, height: 26, }}></code>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <h4 className="text-base-1 font-semibold">Secondary colour</h4>
                    <div className="w-5/12 flex items-center justify-between bg-white rounded-lg" style={{ border: '1.5px solid #C4C2C0', padding: '5px 8px 5px 15px' }}>
                      <span className="text-base-1">#005C6F</span>
                      <code className="bg-secondary rounded-md" style={{ width: 40, height: 26, }}></code>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card-box mt-6 px-6 py-5"
              >
                <h3 className="text-lg font-semibold">Opening hours</h3>
                <p className="text-base-1 text-grey-800 mt-2">
                  Your opening hours will be used if you are running time
                  specific automations such as emails or notification during or
                  out of your opening hours.
                </p>
                <div>
                  <div className="mt-5">
                    <div className="flex">
                      <h3 className="flex-1 text-13 font-semibold ml-64">
                        From
                      </h3>
                      <h3 className="flex-1 text-13 font-semibold">To</h3>
                    </div>
                    <div className="bg-grey-300 border-radius-14 p-5 mt-2">
                      <div className="flex justify-between">
                        <div className="flex-1 text-15 font-semibold flex items-center">
                          Sunday
                        </div>
                        <div className="toggle-slider flex-1 flex items-center">
                          <input
                            type="checkbox"
                            id="check3"
                            className="toggle-slider__checkbox"
                            name="check3"
                          />
                          <label
                            htmlFor="check3"
                            className="toggle-slider__label flex text-15 gap-2 items-center"
                          >
                            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                            Closed
                          </label>
                        </div>
                        <div className="flex-1 mr-2">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="0.00 AM"
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="0.00 PM"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between mt-4">
                        <div className="flex-1 text-15 font-semibold flex items-center">
                          Monday
                        </div>
                        <div className="toggle-slider flex-1 flex items-center">
                          <input
                            checked
                            type="checkbox"
                            id="check3"
                            className="toggle-slider__checkbox"
                            name="check3"
                          />
                          <label
                            htmlFor="check3"
                            className="toggle-slider__label flex text-15 gap-2 items-center"
                          >
                            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                            Open
                          </label>
                        </div>
                        <div className="flex-1 mr-2">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="9.00 AM"
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="5.00 PM"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between mt-4">
                        <div className="flex-1 text-15 font-semibold flex items-center">
                          Tuesday
                        </div>
                        <div className="toggle-slider flex-1 flex items-center">
                          <input
                            checked
                            type="checkbox"
                            id="check3"
                            className="toggle-slider__checkbox"
                            name="check3"
                          />
                          <label
                            htmlFor="check3"
                            className="toggle-slider__label flex items-center text-15 gap-2"
                          >
                            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                            Open
                          </label>
                        </div>
                        <div className="flex-1 mr-2">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="9.00 AM"
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="5.00 PM"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between mt-4">
                        <div className="flex-1 text-15 font-semibold flex items-center">
                          Wednesday
                        </div>
                        <div className="toggle-slider flex-1 flex items-center">
                          <input
                            checked
                            type="checkbox"
                            id="check3"
                            className="toggle-slider__checkbox"
                            name="check3"
                          />
                          <label
                            htmlFor="check3"
                            className="toggle-slider__label flex items-center text-15 gap-2"
                          >
                            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                            Open
                          </label>
                        </div>
                        <div className="flex-1 mr-2">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="9.00 AM"
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="5.00 PM"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between mt-4">
                        <div className="flex-1 text-15 font-semibold flex items-center">
                          Thursday
                        </div>
                        <div className="toggle-slider flex-1 flex items-center">
                          <input
                            checked
                            type="checkbox"
                            id="check3"
                            className="toggle-slider__checkbox"
                            name="check3"
                          />
                          <label
                            htmlFor="check3"
                            className="toggle-slider__label flex items-center text-15 gap-2"
                          >
                            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                            Open
                          </label>
                        </div>
                        <div className="flex-1 mr-2">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="9.00 AM"
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="5.00 PM"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between mt-4">
                        <div className="flex-1 text-15 font-semibold flex items-center">
                          Friday
                        </div>
                        <div className="toggle-slider flex-1 flex items-center">
                          <input
                            checked
                            type="checkbox"
                            id="check3"
                            className="toggle-slider__checkbox"
                            name="check3"
                          />
                          <label
                            htmlFor="check3"
                            className="toggle-slider__label flex items-center text-15 gap-2"
                          >
                            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                            Open
                          </label>
                        </div>
                        <div className="flex-1 mr-2">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="9.00 AM"
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="5.00 PM"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between mt-4">
                        <div className="flex-1 text-15 font-semibold flex items-center">
                          Saturday
                        </div>
                        <div className="toggle-slider flex-1 flex items-center">
                          <input
                            type="checkbox"
                            id="check3"
                            className="toggle-slider__checkbox"
                            name="check3"
                          />
                          <label
                            htmlFor="check3"
                            className="toggle-slider__label flex items-center text-15 gap-2"
                          >
                            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                            Closed
                          </label>
                        </div>
                        <div className="flex-1 mr-2">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="0.00 AM"
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="number"
                            className="input-field input-field--sm-38"
                            placeholder="0.00 PM"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default GlobalBusinessInfo;
