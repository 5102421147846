import React, { useState, useRef } from "react";
import iconDownload from "../assets/icons/icon-new-download.svg";
import iconUpload from "../assets/icons/icon-upload.svg";
import iconRollBack from "../assets/icons/icon-rollback.svg";
import { useHistory } from "react-router-dom";
import HelperVideo from "./Common/HelperVideo";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";
import BoxSettingsUploadContact from "./BoxSettingsUploadContact";
import ModalBoxSettingsUploading from "./modal/ModalBoxSettingsUploading";
import MatchingFieldsModal from "./modal/MatchingFieldsModal";
import BoxSettingsUploadedModal from "./modal/BoxSettingsUploaded";

const GeneralSettingsBoxContacts = () => {
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });
  let history = useHistory();

  const nextScreen = () => {
    history.push("/box-settings-upload-contact");
  };
  const [type, setType] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
	const [openUploadingModal,setOpenUploadingModal] = useState(false);
	const [matchingFieldsModal, setMatchingFieldsModal] = useState(false);
	const [showUploadedModal, setShowUploadedModal] = useState(false);
  const snackbarRef = useRef(null);

  const handleChange = (event) => {
    snackbarRef.current.show();
    event.target.checked ? setType("success") : setType("fail");
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"General Box Settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <BoxSettingsNavLinks activeScreen={6} />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay  p-10">
              <div>
                <h2 className="text-2xl font-semibold text-center">
                  Box contacts
                </h2>
                <p className="text-grey-800 text-sm text-center mt-3">
                  Download or upload contacts
                </p>
              </div>
              <div className="mt-8">
                <HelperVideo />
                {/* <img src={tourVideoPlaceholderPic} alt="" className="mx-auto" /> */}
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
                <h3 className="font-semibold">
                  Download all box contacts (456)
                </h3>
                <button className="btn btn--primary btn--sm text-13 w-3/12 gap-1">
                  <img className="" src={iconDownload} alt="" />
                  Download
                </button>
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
                <h3 className="font-semibold">Upload new contacts</h3>
                <button
                  className="btn btn--primary btn--sm text-13 w-3/12 gap-1"
                  onClick={() => setOpenUpload(true)}
                >
                  <img className="" src={iconUpload} alt="" />
                  Upload
                </button>
              </div>
              <div className="border border-grey-400 border-radius-14 bg-white mt-10 py-5">
                <h3 className="text-lg font-semibold ml-6">Upload history</h3>
                <div className="px-4 w-full">
                  <table className="tabel-upload-history">
                    <thead>
                      <tr className="bg-grey-200 rounded-md">
                        <th>File</th>
                        <th>Contacts</th>
                        <th>Date</th>
                        <th style={{ textAlign: "right" }}>Rollback</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          border: 0,
                          borderRadius: 0,
                          backgroundColor: "transparent",
                        }}
                      >
                        <td
                          style={{
                            paddingTop: 4,
                            paddingBottom: 4,
                            border: 0,
                            borderRadius: 0,
                          }}
                        ></td>
                      </tr>
                      <tr>
                        <td>General box.csv</td>
                        <td>256</td>
                        <td>Jun 29, 2022</td>
                        <td>
                          <div
                            className="flex items-center justify-center bg-white ml-auto"
                            style={{ width: 30, height: 30, borderRadius: 6 }}
                          >
                            <img src={iconRollBack} />
                          </div>
                        </td>
                      </tr>
                      <tr
                        style={{
                          border: 0,
                          borderRadius: 0,
                          backgroundColor: "transparent",
                        }}
                      >
                        <td
                          style={{
                            paddingTop: 2,
                            paddingBottom: 2,
                            border: 0,
                            borderRadius: 0,
                          }}
                        ></td>
                      </tr>
                      <tr>
                        <td>Contact list.csv</td>
                        <td>500</td>
                        <td>Apr 08, 2022</td>
                        <td>
                          <div
                            className="flex items-center justify-center bg-white ml-auto"
                            style={{ width: 30, height: 30, borderRadius: 6 }}
                          >
                            <img src={iconRollBack} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <BoxSettingsUploadContact
              openUpload={openUpload}
              setOpenUpload={setOpenUpload}
							setOpenUploadingModal={setOpenUploadingModal}
            />
						<ModalBoxSettingsUploading 
						openUploadingModal={openUploadingModal}
						setOpenUploadingModal={setOpenUploadingModal}
						setMatchingFieldsModal={setMatchingFieldsModal}
						/>
						<MatchingFieldsModal 
						matchingFieldsModal={matchingFieldsModal}
						setShowUploadedModal={setShowUploadedModal}
						setMatchingFieldsModal={setMatchingFieldsModal}
						/>
						<BoxSettingsUploadedModal 
						showUploadedModal={showUploadedModal}
						setShowUploadedModal={setShowUploadedModal}
						/>
          </div>
        </section>
      </section>
    </main>
  );
};

export default GeneralSettingsBoxContacts;
