import React from 'react'
import { Link } from 'react-router-dom';
import avatarIcon from '../../assets/icons/icon-enquiry-assistant.svg';
import notificationIcon from '../../assets/icons/icon-notification.svg';
import nudgesIcon from '../../assets/icons/icon-nudges.svg';

const EnquiryAssistantNavLinks = ({ activeScreen }) => {
	return (
		<ul>
			<Link to='/enquiry-assistant-settings' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm block ${activeScreen === 1 ? 'active' : ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={avatarIcon} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h2 className="general-settings__card-list__title">
							Avatar
						</h2>
						<p className="general-settings__card-list__desc">
							Change your enquiry assistants avatar
						</p>
					</div>
				</div>
			</Link>
			<Link to='/enquiry-assistant-settings-notification' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 2 ? 'active' : ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={notificationIcon} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Notifications
						</h3>
						<p className="general-settings__card-list__desc">
							Change what, when and how you get notified
						</p>
					</div>
				</div>
			</Link>
			<Link to='/enquiry-assistant-settings-nudges' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 3 ? 'active' : ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={nudgesIcon} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Nudges
						</h3>
						<p className="general-settings__card-list__desc">
							Change what and when your get nudged
						</p>
					</div>
				</div>
			</Link>
		</ul>
	)
}

export default EnquiryAssistantNavLinks