import React from 'react';
import plusIcon from '../assets/icons/icon-plus-icon.svg';

const AutomateStuff = ({ data }) => {
	return (
		<div>
			<h2 className="text-2xl font-semibold text-center">
				Team access
			</h2>
			<div className='mt-3 text-grey-800 text-15 text-center'>Automatically send email, Assign enquiries and more...</div>
			<div className='flex justify-between items-center bg-grey-300 border-radius-14 py-3 pr-5 pl-6 mt-8'>
				<div className='font-semibold'>Automate repetitive things</div>
				<div>
					<button
						className="btn btn--primary text-13 text-white"
						style={{ padding: "5px 14px", borderRadius: 6 }}
					>
						<img src={plusIcon} alt='' style={{ width: 8, height: 8, marginRight: 6 }} />
						Automation
					</button>
				</div>
			</div>
			{data.map((ele, index) => {
				return (
					<div className='flex justify-between card-box pt-5 pr-5 pb-4 pl-6 mt-6' key={index}>
						<div className='flex items-start'>
							<img src={ele.icon} alt='icon' style={{marginRight: 14, marginTop: 3}} />
							<div>
								<div className='font-semibold'>{ele.title}</div>
								<div className='text-grey-800 text-sm mt-1'>{ele.desc}</div>
							</div>
						</div>
						<div className="toggle-slider">
							<input
								type="checkbox"
								id={ele.id}
								className="toggle-slider__checkbox"
								name="checkedA"
							/>
							<label
								htmlFor={ele.id}
								className="toggle-slider__label"
							>
								<code className="toggle-slider__ball toggle-slider__ball__small"></code>
							</label>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default AutomateStuff