import React, { useState } from 'react'
import AccountsSideBarExpanded from './Common/AccountsSideBarExpanded'
import AccountsSideLinks from './Common/AccountsSideLinks'
import AddNewAccountLinks from './Common/AddNewAccountLinks'

const SelectPayment = () => {
	const [planType, setPlanType] = useState(1)
	const [billingSetUp, setBillingSetUp] = useState(1)
	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<AccountsSideLinks />
				<AccountsSideBarExpanded />
				<section className="dashboard__main">
					<div className="dashboard__main__header">
						<h3 className="font-semibold text-2xl">Add a new account</h3>
					</div>
					<div className="dashboard__main__body" style={{ height: 'calc(100% - 83px)' }}>
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<AddNewAccountLinks activeScreen={3} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay p-10">
							<div className="text-center">
								<h2 className="text-2xl font-semibold">Choose your plan & billing setup</h2>
								<p className="text-15 text-grey-800 mt-3">
									You can choose to clone an existing account including all it’s settings, boxes, bots and automations or create a fresh new account.
								</p>
							</div>
							<div className='card-box pt-5 pb-6 px-6 mt-6'>
								<h3 className="font-semibold text-lg">Choose your plan</h3>
								<ul className='mt-5'>
									<li className={`account-plan-list justify-between ${planType === 1 ? 'bg-white' : 'bg-grey-200'}`} onClick={() => setPlanType(1)}>
										<div className='flex'>
											<div className="mt-1">
												<input
													checked={planType === 1}
													type="radio"
													className="custom-radio-button"
													name="radio-btn"
												/>
											</div>
											<div className="ml-3">
												<h4 className="font-semibold mb-2">
													Free account
												</h4>
												<p className="text-sm text-grey-800">
													Lorem ipsum dolor sit amet, consectetur elit
												</p>
											</div>
										</div>
									</li>
									<li className={`account-plan-list justify-between ${planType === 2 ? 'bg-white' : 'bg-grey-200'}`} onClick={() => setPlanType(2)}>
										<div className='flex'>
											<div className="mt-1">
												<input
													checked={planType === 2}
													type="radio"
													className="custom-radio-button"
													name="radio-btn"
												/>
											</div>
											<div className="ml-3">
												<h4 className="font-semibold mb-2">
													Starter
												</h4>
												<p className="text-sm text-grey-800">
													Lorem ipsum dolor sit amet, consectetur elit
												</p>
											</div>
										</div>
										<div className='flex items-center'>
											<span className='mr-1 text-2xl font-semibold text-primary'>£49</span>
											<span className='text-xs'>/month</span>
										</div>
									</li>
									<li className={`account-plan-list justify-between ${planType === 3 ? 'bg-white' : 'bg-grey-200'}`} onClick={() => setPlanType(3)}>
										<div className='flex'>
											<div className="mt-1">
												<input
													checked={planType === 3}
													type="radio"
													className="custom-radio-button"
													name="radio-btn"
												/>
											</div>
											<div className="ml-3">
												<h4 className="font-semibold mb-2">
													Grow
												</h4>
												<p className="text-sm text-grey-800">
													Lorem ipsum dolor sit amet, consectetur elit
												</p>
											</div>
										</div>
										<div className='flex items-center'>
											<span className='mr-1 text-2xl font-semibold text-primary'>£99</span>
											<span className='text-xs'>/month</span>
										</div>
									</li>
									<li className={`account-plan-list justify-between ${planType === 4 ? 'bg-white' : 'bg-grey-200'}`} onClick={() => setPlanType(4)}>
										<div className='flex'>
											<div className="mt-1">
												<input
													checked={planType === 4}
													type="radio"
													className="custom-radio-button"
													name="radio-btn"
												/>
											</div>
											<div className="ml-3">
												<h4 className="font-semibold mb-2">
													Pro
												</h4>
												<p className="text-sm text-grey-800">
													Lorem ipsum dolor sit amet, consectetur
												</p>
											</div>
										</div>
										<div className='flex items-center'>
											<span className='mr-1 text-2xl font-semibold text-primary'>£199</span>
											<span className='text-xs'>/month</span>
										</div>
									</li>
								</ul>
							</div>
							<div className='card-box pt-5 pb-6 px-6 mt-6'>
								<h3 className="font-semibold text-lg">Choose your billing setup</h3>
								<ul className='mt-5'>
									<li className={`email-provider-list justify-between ${billingSetUp === 1 ? 'bg-white' : 'bg-grey-200'}`} onClick={() => setBillingSetUp(1)}>
										<div className='flex'>
											<div className="mt-1">
												<input
													checked={billingSetUp === 1}
													type="radio"
													className="custom-radio-button"
													name="radio-btn-1"
												/>
											</div>
											<div className="ml-3">
												<h4 className="font-semibold mb-2">
													Bill to Smiles Dental Group (Organisation)
												</h4>
												<p className="text-sm text-grey-800">
													Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												</p>
											</div>
										</div>
									</li>
									<li className={`email-provider-list justify-between ${billingSetUp === 2 ? 'bg-white' : 'bg-grey-200'}`} onClick={() => setBillingSetUp(2)}>
										<div className='flex'>
											<div className="mt-1">
												<input
													checked={billingSetUp === 2}
													type="radio"
													className="custom-radio-button"
													name="radio-btn-1"
												/>
											</div>
											<div className="ml-3">
												<h4 className="font-semibold mb-2">
													Bill to Smiles Dental - London
												</h4>
												<p className="text-sm text-grey-800">
													Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												</p>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div className="flex items-center justify-end gap-3 mt-6">
								<button className="btn btn--grey btn--md min-w-140">
									<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
										<path d="M4.99805 1L1.00271 5L4.99805 9" stroke="#4C5254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M1 5H11" stroke="#4C5254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>Go back</button>
								<button className="btn btn--primary btn--md min-w-140">Continue</button>
							</div>
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default SelectPayment