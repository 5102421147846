import React, { useState, useRef } from "react";
import ChannelHeaderNew from "./Common/ChannelHeaderNew";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";
import ToggleEmptyState from "./Common/ToggleEmptyState";
import ToggleReportLink from "./Common/ToggleReportLink";
import BoxInfo from "./modal/BoxInfo";
import InviteModal from "./modal/InviteModal";
import ToggleContacts from "./ToggleContacts";
import ToggleEbox from "./ToggleEbox";

const EnquiryBoxChannels = () => {
  const [isActive, setIsActive] = useState(1);
	const [openBoxInfo, setOpenBoxInfo] = useState(false);
  const [openBoxTemplate, setOpenBoxTemplate] = useState(false);
	const [openInviteModal,setOpenInviteModal] = useState(false);

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew
				setOpenBoxInfo={setOpenBoxInfo}
				setOpenInviteModal={setOpenInviteModal} />
        <section className="dashboard__main">
          <ChannelHeaderNew
            title="Enquirybox"
            showToggle={true}
            setIsActive={setIsActive}
            isActive={isActive}
            HeaderIcon={() => (
              <figure
                className="avatar-container avatar-container--contain"
                style={{ width: 50, height: 50 }}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.76571 0.692307C3.85919 0.692307 0.692324 3.85917 0.692324 7.76569L0.692322 11.6389L0.692322 14.5562V18.4492C0.692322 22.3152 3.82633 25.4492 7.69232 25.4492H18.3033C22.1556 25.4492 25.2811 22.3373 25.3032 18.4901C25.3062 18.4052 25.3077 18.3199 25.3077 18.2343V7.76569C25.3077 3.85917 22.1408 0.692307 18.2343 0.692307H7.76571ZM22.4784 13.4598V7.76569C22.4784 5.42178 20.5782 3.52166 18.2343 3.52166H7.76571C5.4218 3.52166 3.52168 5.42178 3.52168 7.76569V13.5026C3.5939 14.0963 4.09966 14.5562 4.71283 14.5562H7.41512C8.39292 14.5562 9.22737 15.2632 9.38796 16.2277L9.67822 17.9709C9.79866 18.6943 10.4245 19.2246 11.1578 19.2246H14.8416C15.5749 19.2246 16.2007 18.6945 16.3212 17.9712L16.6118 16.2274C16.7726 15.263 17.607 14.5562 18.5846 14.5562H21.2828C21.9106 14.5562 22.4258 14.074 22.4784 13.4598Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          {isActive === 1 && <ToggleEbox />}
          {isActive === 2 && <ToggleContacts />}
          {isActive === 3 && (
            <>
              <div className="dashboard__main__body__section-left overflow-overlay gs-body">
                <ToggleReportLink />
              </div>
              <ToggleEmptyState
                title="Enquirybox contacts"
                desc="One central place to view and find all the contacts in
									enquirybox account. 😎"
              />
            </>
          )}
        </section>
      </section>
			<InviteModal
        addTeamMember={openInviteModal}
        setAddTeamMember={setOpenInviteModal}
				hideTabs={true}
      />
      <BoxInfo
        setOpenBoxInfo={setOpenBoxInfo}
        openBoxInfo={openBoxInfo}
        setOpenBoxTemplate={setOpenBoxTemplate}
      />
    </main>
  );
};

export default EnquiryBoxChannels;
