import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import logo from "./assets/icons/eb-logo.svg";
import BusinessInfo from "./components/authentication/BusinessInfo";
import CreatePassword from "./components/authentication/CreatePassword";
import EmailConfirmation from "./components/authentication/EmailConfirmation";
import ForgotPassword from "./components/authentication/ForgotPassword";
import InviteUserCreateAccount from "./components/authentication/InviteUserCreateAccount";
import Login from "./components/authentication/Login";
import PersonalInfo from "./components/authentication/Personal-Info";
import ResendEmail from "./components/authentication/ResendEmail";
import ResetLinkConfirmationMsg from "./components/authentication/ResetLinkConfirmationMsg";
import SetPassword from "./components/authentication/SetPassword";
import Signup from "./components/authentication/Signup";
import Dashboard from "./components/Dashboard";
import DashboardContactForm from "./components/DashboardContactForm";
import ContactFormConnected from "./components/ContactFormConnected";
import DashboardEmailAddress from "./components/DashboardEmailAddress";
import DashboardFacebook from "./components/DashboardFacebook";
import DashboardGmailConnect from "./components/DashboardGmailConnect";
import DashboardInstagram from "./components/DashboardInstagram";
import DashboardMicrosoftConnect from "./components/DashboardMicrosoftConnect";
import DashboardNote from "./components/DashboardNote";
import DashboardWebsiteChatbot from "./components/DashboardWebsiteChatbot";
import FacebookPageExistingTeamMember from "./components/FacebookPageExistingTeamMember";
import EnquiryAssistant from "./components/modal/EnquiryAssistant";
import FileLibrary from "./components/modal/FileLibrary";
import AttachFiles from "./components/modal/AttachFiles";
import UploadFile from "./components/modal/UploadFile";
import ConnectChannels from "./components/modal/ModalConnectChannel";
import FacebookPageNewTeamMember from "./components/FacebookPageNewTeamMember";
import FacebookPageNewTeamMemberConfirmationMessage from "./components/FacebookPageNewTeamMemberConfirmationMessage";
import ConnectingOtherEmailProvider from "./components/ConnectingOtherEmailProvider";
import OtherEmailConnected from "./components/OtherEmailConnected";
import GmailOrMicrosoftConnected from "./components/GmailOrMicrosoftConnected";
import SendReplies from "./components/SendReplies";
import SendRepliesConfirmationLink from "./components/SendRepliesConfirmationLink";
import Details from "./components/Details";
// import Modals from "./components/Modals";
// import DashboardNoteV2 from "./components/DashboardNoteV2";
import ChannelInstagramConversation from "./components/ChannelInstgramConversation";
import ChannelConnectedPrivate from "./components/ChannelConnectedprivate";
import DashboardHistory from "./components/DashboardHistory";
import History from "./components/History";
import AddCustomBox from "./components/modal/AddCustomBox";
import BoxInfo from "./components/modal/BoxInfo";
import ChannelInviteTeam from "./components/ChannelInviteTeam";
import InviteDropdown from "./components/InviteDropdown";
import ChannelConnectedOpen from "./components/ChannelConnectedOpen";
import ChannelNewTeamMember from "./components/ChannelNewTeamMember";
import ChannelHideOrRequest from "./components/modal/ChannelHideOrRequest";
import RequestToJoin from "./components/modal/RequestToJoin";
import SalesBoxFirstView from "./components/SalesBoxFirstView";
import ChannelEmailConversationStart from "./components/ChannelEmailConversationStart";
import ChannelWebformConversationStart from "./components/ChannelWebformConversationStart";
import ClickedEnquiryView from "./components/ClickedEnquiryView";
import RequestSent from "./components/modal/RequestSent";
import DashboardHistoryModal from "./components/DashboardHistoryModal";
import BoxSettingsNoChannel from "./components/BoxSettingsNoChannel";
import SalesBoxMessageScreens from "./components/SalesBoxMessageScreens";
import ManageChannelConnectionMessage from "./components/ManageChannelConnectionMessage";
import GeneralSettingsBoxContacts from "./components/GeneralSettingsBoxContacts";
import BoxSettingsTeamAccess from "./components/BoxSettingsTeamAccess";
import BoxSettingsNoBoxMember from "./components/BoxSettingsNoBoxMembers";
import BoxSettingsCreateCard from "./components/BoxSettingsCreateCard";
// import ChannelInstagramConversation from "./components/ChannelInstgramConversation";
import AddEnquiryModal from "./components/modal/AddEnquiryModal";
import BoxSettingsUploadContact from "./components/BoxSettingsUploadContact";
import ChannelsSettingsTeamAccess from "./components/ChannelsSettingsTeamAccess";
import ModalBoxSettingsUploaded from "./components/ModalBoxSettingsUploaded";
import BoxSettingsAutomateStuff from "./components/BoxSettingsAutomateStuff";
import AllContacts from "../src/components/AllContacts"
import ModalUploadMultiContactSetup1 from "./components/ModalUploadMultiContactSetup1";
import BoxSettingsBoxForm from "./components/BoxSettingsBoxForm";
import BoxSettingsChannelConnections from "./components/BoxSettingsChannelConnections";
import BoxSettingsUploadFieldsModal from "./components/modal/BoxSettingsUploadFieldsModal";
import BoxSettingsUploadedModal from "./components/modal/BoxSettingsUploaded";
import PostLogin from "./components/modal/PostLogin";
import ToggleNew from "./components/Common/ToggleSwitchOnOff";
import BoxSettingsInegrations from "./components/BoxSettingsIntegrations";
import BoxSettingsBoringStuff from "./components/BoxSettingsBoringStuff";
import GlobalSettingsMyProfile from "./components/GlobalSettingsMyProfile";
import AddSingleContactWarningMessage from "./components/AddSingleContactWarningMessage";
import ToggleSwitchOnOff from "./components/Common/ToggleSwitchOnOff";
import BoxSettingsUploadingModal from './components/BoxSettingUploadIng';
import InviteOnlyChannel from "./components/InviteOnlyChannel";
import EnquiryAssistantSummary from "./components/EnquiryAssistantSummary";
import EnquirySummaryAllNotification from "./components/EnquirySummaryAllNotification";
import GlobalBusinessInfo from "./components/GlobalBusinessInfo";
import QuestionEdit from "./components/QuestionsEdit";
import QuestionContactTab from "./components/QuestionsContactTab";
import EnquiryAssistantSettings from "./components/EnquiryAssistantSettings";
import EnquiryAssistantNotificationSettings from "./components/EnquiryAssistantNotificationSettings";
import EnquiryAssistantSettingsNudges from "./components/EnquiryAssistantSettingsNudges";
import AddQuestions from "./components/AddQuestions";
import AddQuestionSelectQuestion from "./components/AddQuestionSelectQuestion";
import ChannelReplyTemplate from "./components/ChannelReplyTemplate";
import GlobalSettingsTeamMembers from "./components/GlobalSettingsTeamMembers";
import EnquiryBin from "./components/EnquiryBin";
import DeleteEnquiryBinModal from "./components/modal/DeleteEnquiryBinModal";
import AddNewAccount from "./components/AddNewAccount";
import SelectAccountType from "./components/SelectAccountType";
import SelectPayment from "./components/SelectPayment";
import GlobalSettingsReplyTemplates from "./components/GlobalSettingsReplyTemplates";
import GlobalSettingsSingleQuestions from "./components/GlobalSettingsSingleQuestions";
import InviteAccountOwner from "./components/InviteAccountOwner";
import CompletePayment from "./components/CompletePayment";
import ScanQRCode from "./components/Common/ScanQRCode";
import TwoFactorAuthentication from "./components/TwoFactorAuthentication";
import FreeSignUp from "./components/authentication/FreeSignUp";
import CreateUserInvite from "./components/authentication/CreateUserInvite";
import LandingPageNew from "./components/LandingPageNew";
import EnquiryBoxChannels from "./components/EnquiryBoxChannels";
import BoxTemplatePreview from "./components/BoxTemplatePreview";
import BoxTemplate from "./components/BoxTemplate";
import GlobalSettingsLabels from "./components/GlobalSettingsLabels";
import ChannelWhatapp from "./components/ChannelWhatapp";
import ConnectWebsiteForm from "./components/ConnectWebsiteForm";
import ChannelFacebookLeads from "./components/ChannelFacebookLeads";
import ChannelConneted from "./components/ChannelsConneted";
import SelectChannel from "./components/SelectChannel";
import CreateChannel from "./components/modal/CreateChannelModal";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/dashboard-website-chatbot">
            <DashboardWebsiteChatbot />
          </Route>
          <Route path="/dashboard-contact-form">
            <DashboardContactForm />
          </Route>
          <Route path="/contact-form-connected">
            <ContactFormConnected />
          </Route>
          <Route path="/box-settings-team-access">
            <BoxSettingsTeamAccess />
          </Route>
          <Route path="/box-settings-no-box-members">
            <BoxSettingsNoBoxMember />
          </Route>
          <Route path="/box-settings-no-channel">
            <BoxSettingsNoChannel />
          </Route>
          <Route path="/dashboard-email-address">
            <DashboardEmailAddress />
          </Route>
          <Route path="/channel-invite-team">
            <ChannelInviteTeam />
          </Route>
          <Route path="/channel-connected-open">
            <ChannelConnectedOpen />
          </Route>
          <Route path="/channel-invite-new-team-member">
            <ChannelNewTeamMember />
          </Route>
          <Route path="/dashboard-facebook">
            <DashboardFacebook />
          </Route>
          <Route path="/dashboard-connected-private">
            <ChannelConnectedPrivate />
          </Route>
          <Route path="/EnqAssistant">
            <EnquiryAssistant />
          </Route>
          <Route path="/select-channel">
            <SelectChannel />
          </Route>
          <Route path="/connect-channels">
            <ConnectChannels />
          </Route>
          <Route path="/dashboard-instagram">
            <DashboardInstagram />
          </Route>
          <Route path="/channel-whatapp">
            <ChannelWhatapp />
          </Route>
          <Route path="/chat-conversation">
            <DashboardNote />
          </Route>
          <Route path="/enquiry-bin">
            <EnquiryBin />
          </Route>
          <Route path="/dashboard-instgram-conversation">
            <ChannelInstagramConversation />
          </Route>
          <Route path="/file-library">
            <FileLibrary />
          </Route>
          <Route path="/connect-website-form">
            <ConnectWebsiteForm />
          </Route>
          <Route path="/channel-facebook-leads">
            <ChannelFacebookLeads />
          </Route>
          <Route path="/attach-files">
            <AttachFiles />
          </Route>
          <Route path="/upload-files">
            <UploadFile />
          </Route>
          <Route path="/create-channel">
            <CreateChannel />
          </Route>
          <Route path="/dashboard-gmail-connect">
            <DashboardGmailConnect />
          </Route>
          <Route path="/dashboard-microsoft-connect">
            <DashboardMicrosoftConnect />
          </Route>
          <Route path="/facebook-page-existing-team-member">
            <FacebookPageExistingTeamMember />
          </Route>
          <Route path="/facebook-page-new-team-member">
            <FacebookPageNewTeamMember />
          </Route>
          <Route path="/facebook-page-new-team-member-confirmation-message">
            <FacebookPageNewTeamMemberConfirmationMessage />
          </Route>
          <Route path="/connecting-other-email-provider">
            <ConnectingOtherEmailProvider />
          </Route>
          <Route path="/other-email-connected">
            <OtherEmailConnected />
          </Route>
          <Route path="/gmail-or-microsoft-connected">
            <GmailOrMicrosoftConnected />
          </Route>
          <Route path="/send-replies">
            <SendReplies />
          </Route>
          <Route path="/send-replies-confirmation-link">
            <SendRepliesConfirmationLink />
          </Route>
          <Route path="/details">
            <Details />
          </Route>
          <Route path="/dashboard-history">
            <DashboardHistory />
          </Route>
          <Route path="/dashboard-history-Modal">
            <DashboardHistoryModal />
          </Route>
          <Route path="/history">
            <History />
          </Route>
          <Route path="/add-custom-box">
            <AddCustomBox />
          </Route>
          <Route path="/box-info">
            <BoxInfo />
          </Route>
          <Route path="/invite-dropdown">
            <InviteDropdown />
          </Route>
          <Route path="/channel-hide-or-request">
            <ChannelHideOrRequest />
          </Route>
          <Route path="/request-sent">
            <RequestSent />
          </Route>
          <Route path="/request-to-join">
            <RequestToJoin />
          </Route>
          <Route path="/sales-box-first-view">
            <SalesBoxFirstView />
          </Route>
          <Route path="/channel-email-conversation-start">
            <ChannelEmailConversationStart />
          </Route>
          <Route path="/channel-Webform-Conversation-Start">
            <ChannelWebformConversationStart />
          </Route>
          <Route path="/channel-email-conversation-start">
            <ChannelEmailConversationStart />
          </Route>
          <Route path="/channel-Webform-Conversation-Start">
            <ChannelWebformConversationStart />
          </Route>
          <Route path="/clicked-enquiry-view">
            <ClickedEnquiryView />
          </Route>
          <Route path="/welcome-to-your-sales-box-message">
            <SalesBoxMessageScreens />
          </Route>
          <Route path="/manage-channel-connections-message">
            <ManageChannelConnectionMessage />
          </Route>
          <Route path="/box-settings-create-card">
            <BoxSettingsCreateCard />
          </Route>
          <Route path="/add-enquirymodal">
            <AddEnquiryModal />
          </Route>
          <Route path="/global-settings-my-profile">
            <GlobalSettingsMyProfile />
          </Route>
          <Route path="/box-settings-upload-fields">
            <BoxSettingsUploadFieldsModal />
          </Route>
          <Route path="/general-settings-box-contacts">
            <GeneralSettingsBoxContacts />
          </Route>
          <Route path="/box-settings-upload-contact">
            <BoxSettingsUploadContact />
          </Route>
          <Route path="/channel-settings-team-access">
            <ChannelsSettingsTeamAccess />
          </Route>
          <Route path="/global-settings-team-members">
            <GlobalSettingsTeamMembers />
          </Route>
          <Route path="/box-settings-uploaded">
            <ModalBoxSettingsUploaded />
          </Route>
          <Route path='/box-settings-automate-stuff'>
            <BoxSettingsAutomateStuff />
          </Route>
          <Route path='/channel-connected-page'>
            <ChannelConneted />
          </Route>
          <Route path="/global-settings-business-info">
            <GlobalBusinessInfo />
          </Route>
          <Route path="/global-settings-reply-template">
            <GlobalSettingsReplyTemplates />
          </Route>
          <Route path="/all-contacts">
            <AllContacts />
          </Route>
          <Route path="/modal-upload-multi-contact-setup1">
            <ModalUploadMultiContactSetup1 />
          </Route>
          <Route path="/modal-upload-multi-contact-setup1">
            <ModalUploadMultiContactSetup1 />
          </Route>
          <Route path="/modal-add-single-contact-warning-message">
            <AddSingleContactWarningMessage />
          </Route>
          <Route path="/box-settings-channel-connection">
            <BoxSettingsChannelConnections />
          </Route>
          <Route path="/box-settings-box-form">
            <BoxSettingsBoxForm />
          </Route>
          <Route path="/post-login">
            <PostLogin />
          </Route>
          <Route path="/toggle-switch">
            <ToggleSwitchOnOff />
          </Route>
          <Route path='/toggle-switch'>
            <ToggleSwitchOnOff />
          </Route>
          <Route path="/box-settings-integrations">
            <BoxSettingsInegrations />
          </Route>
          <Route path="/box-settings-boring-stuff">
            <BoxSettingsBoringStuff />
          </Route>
          <Route path="/enquiry-bin-delete">
            <DeleteEnquiryBinModal />
          </Route>
          <Route path="/BoxSettingsUploadingModal">
            <BoxSettingsUploadingModal />
          </Route>
          <Route path='/invite-only-channels'>
            <InviteOnlyChannel />
          </Route>
          <Route path='/enquiry-assistant-summary'>
            <EnquiryAssistantSummary />
          </Route>
          <Route path='/enquiry-assistant-summary-all-notification'>
            <EnquirySummaryAllNotification />
          </Route>
          <Route path="/BoxSettingsUploadingModal">
            <BoxSettingsUploadingModal />
          </Route>
          <Route path='/invite-only-channels'>
            <InviteOnlyChannel />
          </Route>
          <Route path='/enquiry-assistant-notifications'>
            <EnquiryAssistantSummary />
          </Route>
          <Route path='/enquiry-assistant-summary-all-notification'>
            <EnquirySummaryAllNotification />
          </Route>
          <Route path='/enquiry-assistant-settings-notification'>
            <EnquiryAssistantNotificationSettings />
          </Route>
          <Route path='/enquiry-assistant-settings-nudges'>
            <EnquiryAssistantSettingsNudges />
          </Route>
          <Route path='/channel-reply-template'>
            <ChannelReplyTemplate />
          </Route>
          <Route path='/question-edit'>
            <QuestionEdit />
          </Route>
          <Route path='/question-contact-tab'>
            <QuestionContactTab />
          </Route>
          <Route path='/enquiry-assistant-settings'>
            <EnquiryAssistantSettings />
          </Route>
          <Route path='/enquiry-assistant-settings-notification'>
            <EnquiryAssistantNotificationSettings />
          </Route>
          <Route path='/enquiry-assistant-settings-nudges'>
            <EnquiryAssistantSettingsNudges />
          </Route>
          <Route path='/add-question-select-question'>
            <AddQuestionSelectQuestion />
          </Route>
          <Route path='/global-settings-single-questions'>
            <GlobalSettingsSingleQuestions />
          </Route>
          <Route path='/add-new-account-account-details'>
            <AddNewAccount />
          </Route>
          <Route path='/add-new-account-select-account-type'>
            <SelectAccountType />
          </Route>
          <Route path='/add-new-account-select-payment'>
            <SelectPayment />
          </Route>
          <Route path='/invite-account-owner'>
            <InviteAccountOwner />
          </Route>
          <Route path='/complete-payment'>
            <CompletePayment />
          </Route>
          <Route path='/scan-qr-code'>
            <ScanQRCode />
          </Route>
          <Route exact path="/sign-up">
            <Signup />
          </Route>
          <Route exact path="/two-factor-authentication">
            <TwoFactorAuthentication />
          </Route>
          <Route path="/landing-page-new">
            <LandingPageNew />
          </Route>
          <Route path="/enquiry-box-channels">
            <EnquiryBoxChannels />
          </Route>
          <Route path='/box-template-preview'>
            <BoxTemplatePreview />
          </Route>
          <Route path="/box-template">
            <BoxTemplate />
          </Route>
          <Route path='/global-settings-labels'>
            <GlobalSettingsLabels />
          </Route>
        </Switch>
      </BrowserRouter>
      {/* <main className="authentication-module">
				<div className="logo flex items-center justify-center mb-8">
					<img src={logo} alt="logo" />
				</div>
				<div className="text-center mb-7">
					<h2 className="font-bold text-2xl">Sign up for free</h2>
					<p className="font-medium text-lg">No credit card required</p>
				</div>				
				<BrowserRouter>
					<Switch>
						<Route exact path='/free-sign-up'>
							<FreeSignUp />
						</Route>
					</Switch>
				</BrowserRouter>
			</main> */}
      {/* <main className="authentication-module">
        <div className="logo flex items-center justify-center mb-16 xxl:mb-16">
          <img src={logo} alt="logo" />
        </div> */}
      {/* <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Signup />
            </Route>
            <Route path="/business-info">
              <BusinessInfo />
            </Route>
            <Route path="/email-confirmation">
              <EmailConfirmation />
            </Route>
            <Route path="/resend-email">
              <ResendEmail />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/create-password">
              <CreatePassword />
            </Route>
            <Route path="/personal-info">
              <PersonalInfo />
            </Route>
            <Route path="/invite-user-account">
              <InviteUserCreateAccount />
            </Route>
            <Route path="/set-password">
              <SetPassword />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-link-confirmation">
              <ResetLinkConfirmationMsg />
            </Route>
						<Route path='/create-your-account-from-email-invite'>
							<CreateUserInvite />
						</Route>
          </Switch>
        </BrowserRouter> */}
      {/* </main> */}
    </>
  );
}

export default App;
