import { useState } from "react";
import AttachmentItem from "./Common/AttachmentItem";
import EmailConversationUserName from "./Common/EmailConversationUserName";

const EmailConversationBody = ({ sender = false }) => {
  const [expandEmail, setExpandEmail] = useState(false);
  const dummyAttachments = [
    {
      name: "Purchase.pdf",
    },
    {
      name: "Expense receipts.docx",
    },
    {
      name: "Site plans for new pl...docx",
    },
  ];

  const Receiver = () => {
    return (
      <div className="email-body">
        <p className="item text-13 font-bold">Welcome to Enquirybox!</p>
        <div className="item">
          <div className="flex items-center">
            <p className=" text-13 text-grey-600">To:</p>
            <p className="text-13 text-grey-900 ml-2">Abhinav Utkarsh</p>
          </div>
          {expandEmail && (
            <div className="">
              <div className="item flex items-center my-3">
                <p className="text-13 text-grey-600">Cc:</p>
                <div className="flex items-center flex-wrap">
                  <div className="flex items-center">
                    <EmailConversationUserName
                      editMode={false}
                      name={"John Leon"}
                    />
                    ,
                    <EmailConversationUserName
                      editMode={false}
                      name={"Madison Inouye"}
                    />
                    ,
                    <EmailConversationUserName
                      editMode={false}
                      name={"Alex Foster"}
                    />
                    ,
                    <EmailConversationUserName
                      editMode={false}
                      name={"Olivia Rhye"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <p className=" text-13 text-grey-600">Bcc:</p>
                <div className="flex items-center flex-wrap">
                  <div className="flex items-center">
                    <EmailConversationUserName
                      editMode={false}
                      name={"Merva Sahin"}
                    />
                    ,
                    <EmailConversationUserName
                      editMode={false}
                      name={"Lucas Mattia"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="item">
          <p className="text-13 text-grey-900">
            Hi there! 👋 Glad you're trying out Enquirybox! This is an
            automated.
            <br />
            <br />I hope curabitur magna massa, tristique bibendum finibus nec,
            consectetur ut urna. Sed nec libero feugiat, tincidunt arcu.
          </p>
          {expandEmail && (
            <div>
              <h3 className="text-13 text-grey-900 font-bold mt-5 mb-2">
                Attchments
              </h3>
              <div className="item__attachment">
                {dummyAttachments.map((attachment) => {
                  return <AttachmentItem attachment={attachment} />;
                })}
              </div>
            </div>
          )}
        </div>
        <div className="item">
          <p className="text-13 text-grey-600" style={{ marginBottom: 4 }}>
            Best regards
          </p>
          <p className="text-13 text-grey-900 font-bold">Enquirybox Team</p>
        </div>
      </div>
    );
  };

  const Sender = () => {
    return (
      <div className="email-body">
        <div className="item">
          <p className="text-13 font-bold">Welcome to Enquirybox!</p>
          <div className="flex items-center">
            <p className=" text-13 text-grey-600">To:</p>
            <p className="text-13 text-grey-900 ml-2">Abhinav Utkarsh</p>
          </div>
        </div>
        <div className="item">
          <p className="text-13 text-grey-900">
            Thank you...Cras at purus quis magna cursus dictum et ut arcu.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      className="inline-block border border-grey-400 text-base-1 text-grey-900"
      style={{
        borderRadius: 10,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: sender ? "#f4f9f9" : "#FEF2F7",
        width: sender ? "100%" : "90%",
        marginRight: sender ? 6 : 0,
      }}
    >
      <div
        className="flex items-center"
        style={{ justifyContent: "space-between" }}
      >
        <div className="flex items-center">
          <p className="text-13 font-medium">Merva Sahin</p>
          <p className="text-12 ml-2">
            {"<"}charleswundeng@gmail.com{">"}
          </p>
        </div>
        <p
          className="text-12 ml-5 cursor-pointer"
          onClick={() => setExpandEmail((curr) => !curr)}
        >
          {expandEmail ? "Collapse" : "Expand"}
        </p>
      </div>
      {sender ? <Sender /> : <Receiver />}
    </div>
  );
};

export default EmailConversationBody;
