import React from "react";
import { Link } from "react-router-dom";
import iconSearch from "../assets/icons/icon-search.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import ChannelHeader from "./Common/ChannelHeader";


import iconCheckCircle from "../assets/icons/icon-green-check-circle.svg";
import InviteNewTeamMember from "./InviteNewTeamMember";
import HelperVideo from "./Common/HelperVideo";


const ChannelConnectedPrivate = () => {
	const data =[{id:1,title :"Open for all the team", desc :"The Channel will be open to join for all current and future team members."} , {id:2,title :"Invite only", desc :"The Channel will be accessible to team members who have been invited."}]
	const headerText = "Choose access permissions";
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />
        <section className="dashboard__main">
          <ChannelHeader
            text={"Stead"}
            linkText="Manage notifications"
            HeaderIcon={() => (
              <figure className="avatar-container">
                <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left">
              <h2 className="text-lg font-semibold text-grey-900">Messages</h2>
              <div className="mt-15">
                <label className="text-xs font-medium text-grey-700 uppercase">
                  New
                </label>
                <ul className="">
                  <li className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer active">
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{
                        width: 48,
                        height: 48,
                        border: "1px solid rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <img src={iconAvatar}  alt = "" className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900 truncate">
                        Enquiry Assistant
                      </h3>
                      <p className="text-base-1 text-grey-800 truncate">
                        Connect your Contact Form
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
                <div className="mb-3">
                  <div className="flex items-center gap-2">
                    <img src={iconCheckCircle} alt="icon-check-cirlce" />
                    <h3 className="text-grey-900 font-semibold">
                      Facebook page is connected
                    </h3>
                  </div>
									<div className="mt-4">
										<HelperVideo />
									</div>							
                  {/* <div className="video-container mt-4">
                    <img
                      src={contactFormPlaceholder}
                      alt=""
                      className="w-full h-full"
                    />
                  </div> */}
									<InviteNewTeamMember
										data={data}
										headerText={headerText} />
                  {/* <div className="mt-25">
                    <label
                      className="input-field-label"
                      style={{ fontSize: 14 }}
                    >
                      Choose access permissions
                    </label>
                    <ul className="mt-15 mr-5">
                      <li className="email-provider-list active selected">
                        <div className="flex items-center mb-40">
                          <input
                          checked
                            type="radio"
                            className="custom-radio-button"
                            name="radio-btn"
                          />
                        </div>
                        <div className="ml-15">
                          <h4 className="text-base-1 font-semibold text-grey-900 mb-2">
                            Open for all the team
                          </h4>
                          <p className="text-sm text-grey-800">
                            The Channel will be open to join for all current and future team members.
                          </p>
                        </div>
                      </li>
                      <li className="email-provider-list">
                        <div className="flex items-center ml-auto mb-40">
                          <input

                            type="radio"
                            className="custom-radio-button custom-radio-button--sm"
                            name="radio-btn"
                          />
                        </div>
                        <div className="ml-15">
                          <h4 className="text-base-1 font-semibold text-grey-900 mb-2">
                            Invite only
                          </h4>
                          <p className="text-sm text-grey-800">
                            The Channel will be accessible to team members who have been invited.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <button className="btn btn--primary btn--sm w-1/4 ml-auto mt-10">
                  Next
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default ChannelConnectedPrivate;
