import React, {useState} from "react";

const InviteNewTeamMember = ({ data, headerText, className }) => {
	const [active, setActive] = useState(1);

  return (
    <div className={`${className ? className : ''}`}>
      <p className="text-grey-900 font-semibold">
        {headerText}
      </p>
      <ul>
				{data.map((item,index) => {
					return (
						<li className="mt-15">
							<input
								type="radio"
								checked={item.id === active}
								className="action-box-list__radio-btn"
								id={`user-type1_${item.id}`}
								name="invite-team-member"
								onChange={() => setActive(item.id)}
							/>
							<label className="action-box-list" for={`user-type1_${item.id}`}>
								<code className="action-box-list__circle mt-1"></code>
								<div className="flex flex-col ml-3">
									<h4 className="text-16 font-semibold text-grey-900 mb-2">
										{item.title}
									</h4>
									<p className="text-sm text-grey-800">{item.desc}</p>
								</div>
							</label>
						</li>
					)
				})}
      </ul>
    </div>
  );
};
export default InviteNewTeamMember;
