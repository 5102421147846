import React from 'react'
import iconGroupChannel from "../../assets/icons/Group132462.svg";
import iconGroupCardDesign from "../../assets/icons/Group13220.svg";
import iconTeamAccess from "../../assets/icons/Group13230.svg";
import iconAutomate from "../../assets/icons/Group13224.svg";
import iconBoxForms from "../../assets/icons/Group13237.svg";
import iconImportContacts from "../../assets/icons/Group13225.svg";
import iconIntegrations from "../../assets/icons/Group13229.svg";
import iconBoringStuff from "../../assets/icons/Group13245.svg";
import { Link } from 'react-router-dom';

const BoxSettingsNavLinks = ({activeScreen}) => {
	return (
		<ul>
			<Link to='/box-settings-channel-connection' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm block ${activeScreen === 1 ? 'active': ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconGroupChannel} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h2 className="general-settings__card-list__title">
							Channel Connections
						</h2>
						<p className="general-settings__card-list__desc">
							Channel connect to box
						</p>
					</div>
				</div>
			</Link>
			<Link to='/box-settings-create-card' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 2 ? 'active': ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconGroupCardDesign} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Card design
						</h3>
						<p className="general-settings__card-list__desc">
							design the perfect enquiry card
						</p>
					</div>
				</div>
			</Link>
			<Link to='/box-settings-team-access' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 3 ? 'active': ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconTeamAccess} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Team access
						</h3>
						<p className="general-settings__card-list__desc">
							Manage who has access
						</p>
					</div>
				</div>
			</Link>
			<Link to='/box-settings-automate-stuff' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 4 ? 'active': ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconAutomate} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Automate stuff
						</h3>
						<p className="general-settings__card-list__desc">
							Replies, Moving stuff {"&"} more
						</p>
					</div>
				</div>
			</Link>
			<Link to='/box-settings-box-form' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 5 ? 'active': ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconBoxForms} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Box forms
						</h3>
						<p className="general-settings__card-list__desc">
							Disconnect, Delete, Rename etc
						</p>
					</div>
				</div>
			</Link>
			<Link to='/general-settings-box-contacts' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 6 ? 'active': ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconImportContacts} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Import contacts
						</h3>
						<p className="general-settings__card-list__desc">
							Import existing contacts to the box
						</p>
					</div>
				</div>
			</Link>
			<Link to='/box-settings-integrations' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 7 ? 'active': ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconIntegrations} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Integrations
						</h3>
						<p className="general-settings__card-list__desc">
							Manage third party Integrations
						</p>
					</div>
				</div>
			</Link>
			<Link to='/box-settings-boring-stuff' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 8 ? 'active': ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconBoringStuff} alt=''/>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Boring stuff
						</h3>
						<p className="general-settings__card-list__desc">
							Disconnect, Delete, Rename etc
						</p>
					</div>
				</div>
			</Link>
		</ul>
	)
}

export default BoxSettingsNavLinks