import React, { useState } from "react";
import searchCloseIcon from "../../assets/icons/icon-modal-close.svg";
import iconSearch from "../../assets/icons/icon-search.svg";
import iconSettings from "../../assets/icons/icon-settings.svg";
import userIcon from "../../assets/icons/usericon1.svg";
import iconPlusGreen from "../../assets/icons/icon-plus-green.svg";
import ToggleHeader from "./ToggleHeader";
import iconPlusWhite from "../../assets/icons/icon-plus-white.svg";
import { useLocation } from "react-router-dom";
import AddReminder from "../modal/AddReminder";
import ReminderDetails from "../modal/ReminderDetails";
import DeleteEnquiryBinModal from "../modal/DeleteEnquiryBinModal";

const allContactsUrl = "all-contacts";
const modalContact = "modal-upload-multi-contact-setup1";

const ChannelHeader = ({
	seatchResults = [],
	text = "",
	HeaderIcon = () => <></>,
	linkText = "",
	className = false,
	onLinkTextClick = () => { },
	showProfileImages = true,
	showToggle = false,
	emptyBin = false,
	enquiryVisible = false,
	addReminderBtn = false,
	setShowUploadContactModal,
}) => {
	const [isInputFocused, setIsInputFocused] = useState(false);
	const [searchInputValue, setSearchInputValue] = useState("");
	const [showSearchInput, setShowSearchInput] = useState(false);
	const [openReminder, setOpenReminder] = useState(false);
	const [openViewEnquiry, setOpenViewEnquiry] = useState(false);
	const location = useLocation();
	const checkUrl = location && location.pathname;
	const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

	const dummyData = [
		{
			name: "alexfoster_dp@gmail.com",
		},
		{
			name: "alyonapastu@gmail",
		},
		{
			name: "Abraham Perez",
		},
		{
			name: "Anna Moreva",
		},
		{
			name: "Aldair Lina",
		},
	];

	return (
		<>
			<div className="dashboard__main__header">
				<div className="flex items-center dashboard__main__header__section-left">
					<HeaderIcon />
					{/* // className="avatar-container" */}
					<div className="ml-5">
						<h3 className={` ${!className ? 'text-xl font-bold' : ' text-xl font-semibold'}`}>{text}</h3>
						<p
							onClick={onLinkTextClick}
							className={`${!className ? 'text-primary text-base-1 font-medium cursor-pointer' : 'text-base-1 font-medium text-grey-800 cursor-pointer'}`}
						>
							{linkText}
						</p>
					</div>
				</div>
				{showToggle && <ToggleHeader />}
				<div className="flex items-center dashboard__main__header__section-right mr-4">
					<div
						className={`search-container ${showSearchInput ? "is-open" : ""} `}
					>
						<div className="relative hidden">
							<div className="flex overflow-visible relative float-left bg-grey-90 rounded-xl">
								{/* <div className="dashboard__main__body__user border-2 border-solid border-white text-center p-4"> */}
								<figure
									className="avatar-container avatar-container--full bg-white"
									style={{
										width: 40,
										height: 40,
										border: "1px solid rgba(0, 0, 0, 0.05)",
									}}
								>
									<img src={userIcon} alt="" className="" />
								</figure>
								<figure
									className="avatar-container avatar-container--full bg-white"
									style={{
										width: 40,
										height: 40,
										border: "1px solid rgba(0, 0, 0, 0.05)",
									}}
								>
									<img src={userIcon} alt="" className="" />
								</figure>
								{/* </div> */}
								<div className="dashboard__main__body__user-add">+</div>
							</div>
						</div>
						<div className="relative search-input-wrapper">
							<input
								onFocus={() => setIsInputFocused(true)}
								onBlur={() => setIsInputFocused(false)}
								placeholder="Search...."
								value={searchInputValue}
								onChange={(e) => setSearchInputValue(e.target.value)}
								className="search-input input-field input-field--md input-field--icon-search"
							/>
							<div
								className={`dropdown-menu dropdown-menu--with-border py-4 ${searchInputValue.length >= 1 && "open"
									}`}
								style={{
									top: "calc(100% + 4px)",
									maxHeight: 250,
									overflowY: "auto",
								}}
							>
								<p className="text-13 text-grey-600 px-3">Searching...</p>
								<div className="dropdown-menu__list">
									<img src={iconSearch} alt="file" className="mr-3" />
									alexfoster_dp@gmail.com
								</div>
								<div className="dropdown-menu__list">
									<img src={iconSearch} alt="file" className="mr-3" />
									alexfoster_dp@gmail.com
								</div>
								<div className="dropdown-menu__list">
									<img src={iconSearch} alt="file" className="mr-3" />
									alexfoster_dp@gmail.com
								</div>
								<div className="dropdown-menu__list">
									<img src={iconSearch} alt="file" className="mr-3" />
									alexfoster_dp@gmail.com
								</div>
							</div>
						</div>
						{!addReminderBtn && <img
							onClick={() => setShowSearchInput(true)}
							src={iconSearch}
							alt="icon-search"
							className="search-icon ml-5"
						/>}
						<img
							src={searchCloseIcon}
							alt=""
							onClick={() => setShowSearchInput(false)}
							className="search-close-icon ml-5"
						/>
					</div>

					{enquiryVisible && (
						<>
							<code
								className="bg-grey-400 mx-4"
								style={{ width: 1, height: 26 }}
							></code>
							<button className="btn btn--default">
								<img src={iconPlusGreen} alt="" style={{ marginRight: 6 }} />
								Enquiry
							</button>
						</>
					)}
					{addReminderBtn && (
						<button className="btn btn--primary btn--sm  ml-4 decoration-white text-sm" onClick={() => setOpenReminder(true)}>
							<img
								src={iconPlusWhite}
								alt=""
								style={{ marginRight: 6, width: "8px", height: "8px" }}
							/>
							Add reminder
						</button>
					)}
					<img
						src={iconSettings}
						alt="icon-settings"
						className="cursor-pointer hidden"
						style={{ height: 18, width: 17 }}
					/>
					{(checkUrl.includes(allContactsUrl) || checkUrl.includes(modalContact)) && (
						<>
							<div className="w-px h-7 bg-grey-400 rounded-xl m-3"></div>
							<button className="btn btn--primary btn--sm  ml-4 decoration-white text-sm" onClick={() => setShowUploadContactModal(true)}>
								<img
									src={iconPlusWhite}
									alt=""
									style={{ marginRight: 6, width: "8px", height: "8px" }}
								/>
								Contact
							</button>
						</>
					)}
					<div
						className="flex items-center dashboard__main__header__section-right"
						style={{ display: "none" }}
					>
						<div className="search-container">
							{!showSearchInput ? (
								<img
									onClick={() => setShowSearchInput(true)}
									src={iconSearch}
									alt="icon-search"
									className="cursor-pointer"
									style={{ height: 18, width: 18 }}
								/>
							) : (
								<>
									<div className="flex items-center">
										<input
											onFocus={() => setIsInputFocused(true)}
											onBlur={() => setIsInputFocused(false)}
											placeholder="Search...."
											value={searchInputValue}
											onChange={(e) => setSearchInputValue(e.target.value)}
											className="input-field input--search input-field--md input-field--icon-search"
										/>

										<div className="" onClick={() => setShowSearchInput(false)}>
											<img
												className="ml-3 cursor-pointer"
												src={searchCloseIcon}
												alt=""
											/>
										</div>
									</div>
									{searchInputValue.length >= 1 && (
										<>
											<div className="input-field__search-results">
												<span className="font-normal text-grey-600 ml-4">
													Searching...
												</span>
												{dummyData.map((item) => {
													return (
														<div className="flex">
															<img
																src={iconSearch}
																alt="file"
																className="mr-3 ml-4"
															/>
															<p className="font-normal text-grey-800">{item.name}</p>
														</div>
													);
												})}
											</div>

										</>
									)}
								</>
							)}
						</div>
						<code
							className="bg-grey-400 mx-4"
							style={{ width: 1, height: 26 }}
						></code>

						<img
							src={iconSettings}
							alt="icon-settings"
							className="cursor-pointer"
							style={{ height: 18, width: 17 }}
						/>
					</div>
					{emptyBin && <>
						<code
							className="bg-grey-400 mx-4"
							style={{ width: 1, height: 26 }}
						></code>
						<button className='btn btn--default' name="checkedA" onClick={() => setOpenDeleteConfirmation(true)} style={{ padding: '5px 20px' }}>
							Empty Bin
						</button>
						<DeleteEnquiryBinModal
							openDeleteConfirmation={openDeleteConfirmation}
							setOpenDeleteConfirmation={setOpenDeleteConfirmation}
							isdelete={false}
						/>
					</>}
				</div>
			</div>
			<AddReminder openReminder={openReminder} setOpenReminder={setOpenReminder} setOpenViewEnquiry={setOpenViewEnquiry} />
			<ReminderDetails openViewEnquiry={openViewEnquiry} setOpenViewEnquiry={setOpenViewEnquiry} />
		</>
	);
};

export default ChannelHeader;
