import React, { useEffect, useState } from "react";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconMoreRound from "../assets/icons/icon-more-round.svg";
import iconCardProfile from "../assets/icons/icon-card-image.svg";
import iconEdit from "../assets/icons/icon-edit.svg";
import deleteIcon from "../assets/icons/icon-Delete.svg";
import { Link } from "react-router-dom";
import SidebarLinksNew from "./Common/SideBarLinksNew";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import ChannelHeaderNew from "./Common/ChannelHeaderNew";
import iconHeader from "../assets/icons/icon-sales-box-header.svg";
import InviteModal from "./modal/InviteModal";
import ModalUploadMultiContactSetup1 from "./ModalUploadMultiContactSetup1";
import BoxInfo from "./modal/BoxInfo";
import avatarPic from "../assets/images/avatar-pic.png";
import iconMessanger from "../assets/icons/icon-channels-messanger.svg";
import iconInsta from "../assets/icons/icon-channels-instagram.svg";
import iconFbLeads from "../assets/icons/icon-channels-fbleads.svg";
import iconWhatsApp from "../assets/icons/icon-channels-whatsApp.svg";
import iconWebforms from "../assets/icons/icon-channels-web-Forms.svg";

const users = [
  {
    id: 1,
    name: "Charles",
    checked: false,
  },
  {
    id: 2,
    name: "Madison Inouye",
    checked: false,
  },
  {
    id: 3,
    name: "Olivia Rhye",
    checked: false,
  },
  {
    id: 4,
    name: "Charles Wundeng",
    checked: false,
  },
  {
    id: 5,
    name: "Lisa Fotios",
    checked: false,
  },
  {
    id: 6,
    name: "Peter Parker",
    checked: false,
  },
];

const channels = [
  {
    id: 1,
    name: "Smiles Dental",
    icon: iconMessanger,
    checked: false,
  },
  {
    id: 2,
    name: "Smiles Dental Practice",
    icon: iconInsta,
    checked: false,
  },
  {
    id: 3,
    name: "+4419876352",
    icon: iconWhatsApp,
    checked: false,
  },
  {
    id: 4,
    name: "Smiles Dental Practice",
    icon: iconFbLeads,
    checked: false,
  },
  {
    id: 5,
    name: "Wedding",
    icon: iconInsta,
    checked: false,
  },
  {
    id: 6,
    name: "Website forms",
    icon: iconWebforms,
    checked: false,
  },
];

const labelData = [
  {
    id: 1,
    name: "Whitening",
    checked: false,
  },
  {
    id: 2,
    name: "Invisalign",
    checked: false,
  },
  {
    id: 3,
    name: "Dental Implants",
    checked: false,
  },
  {
    id: 4,
    name: "NHS Patient",
    checked: false,
  },
  {
    id: 5,
    name: "New Patient",
    checked: false,
  },
  {
    id: 6,
    name: "Emergency",
    checked: false,
  },
];

const SalesBoxFirstView = () => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isGeneral, setIsGeneral] = useState(false);
  const [showDelete, setShowDelete] = useState(0);
  const [addStage, setAddStage] = useState(false);
  const [isActive, setIsActive] = useState(1);
  const [showUploadContactModal, setShowUploadContactModal] = useState(false);
  const [openBoxInfo, setOpenBoxInfo] = useState(false);
  const [openBoxTemplate, setOpenBoxTemplate] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedUser, setSelectedUser] = useState(users);
  const [openChannels, setOpenChannels] = useState(false);
  const [openLabels, setOpenLabels] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState(labelData);
  const [selectedChannels, setSelectedChannels] = useState(channels);
  let isTrue = selectedUser.filter((item) => item.checked === true);
  let isTrueChannels = selectedChannels.filter((item) => item.checked === true);
  let isTrueLabels = selectedLabels.filter((item) => item.checked === true);

  const handleChange = (ind) => {
    const currentStatus = selectedUser[ind].checked;
    selectedUser[ind].checked = !currentStatus;
    setSelectedUser([...selectedUser]);
  };
  const handleRemove = (ind) => {
    const currentStatus = selectedUser[ind].checked;
    selectedUser[ind].checked = !currentStatus;
    setSelectedUser([...selectedUser]);
  };

  const handleChangeChannels = (ind) => {
    const currentStatus = selectedChannels[ind].checked;
    selectedChannels[ind].checked = !currentStatus;
    setSelectedChannels([...selectedChannels]);
  };

  const handleRemoveChannels = (ind) => {
    const currentStatus = selectedChannels[ind].checked;
    selectedChannels[ind].checked = !currentStatus;
    setSelectedChannels([...selectedChannels]);
  };

  const handleChangeLabels = (ind) => {
    const currentStatus = selectedLabels[ind].checked;
    selectedLabels[ind].checked = !currentStatus;
    setSelectedLabels([...selectedLabels]);
  };

  const handleRemoveLabels = (ind) => {
    const currentStatus = selectedLabels[ind].checked;
    selectedLabels[ind].checked = !currentStatus;
    setSelectedLabels([...selectedLabels]);
  };

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew
				setOpenBoxInfo={setOpenBoxInfo}
				setOpenInviteModal={setOpenInviteModal}
				 />
        <section className="dashboard__main">
          <ChannelHeaderNew
            title="Sales"
            showToggle={true}
            isActive={isActive}
            isBoxScreen={true}
            setIsActive={setIsActive}
            hideText={true}
            setIsFilterClicked={setIsFilterClicked}
            isFilterClicked={isFilterClicked}
            HeaderIcon={() => (
              <figure
                className="avatar-container avatar-container--contain"
                style={{ width: 50, height: 50 }}
              >
                <img
                  src={iconHeader}
                  alt=""
                  style={{ width: 28, height: 28 }}
                />
              </figure>
            )}
          />
          <div style={{ height: "calc(100% - 103px)" }}>
            {isActive === 1 && (
              <>
                {isFilterClicked && (
                  <div className="dashboard__main__body__filter-section flex justify-between py-3 px-6">
                    <div className="flex gap-2">
                      <span className="text-13 font-medium text-grey-700 mt-1">
                        Filter by
                      </span>
                      <div className="relative">
                        <button
                          className={`btn ${
                            openAssign
                              ? "bg-grey-90 border border-primary-border btn--xs-28"
                              : "bg-white border border-grey-400 btn--xs-28"
                          } ${
                            isTrue.length > 0 && !openAssign
                              ? "bg-grey-90 border border-primary-border btn--xs-28-p-1"
                              : ""
                          }`}
                          style={{ borderRadius: 20 }}
                        >
                          <figure
                            className="mr-2"
                            onClick={() => {
															setOpenAssign(!openAssign);
															setOpenChannels(false);
															setOpenLabels(false);
														}}
                          >
                            {!openAssign ? (
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.00013 7.69161C8.14467 7.69161 9.0725 6.76378 9.0725 5.61924C9.0725 4.4747 8.14467 3.54688 7.00013 3.54688C5.85559 3.54688 4.92776 4.4747 4.92776 5.61924C4.92776 6.76378 5.85559 7.69161 7.00013 7.69161ZM4.88118 8.38239C3.60362 8.38239 2.74905 9.69737 3.26792 10.8648C3.44693 11.2676 3.77014 11.5888 4.174 11.7654L5.17049 12.2011C6.33698 12.711 7.66337 12.711 8.82986 12.201L9.98962 11.694C10.2929 11.5614 10.5517 11.3446 10.7353 11.0692C11.5009 9.92071 10.6776 8.38239 9.29736 8.38239H4.88118Z"
                                  fill="#4C5254"
                                />
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6"
                                  stroke="#4C5254"
                                  stroke-width="1.3"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.00013 7.69161C8.14467 7.69161 9.0725 6.76378 9.0725 5.61924C9.0725 4.4747 8.14467 3.54688 7.00013 3.54688C5.85559 3.54688 4.92776 4.4747 4.92776 5.61924C4.92776 6.76378 5.85559 7.69161 7.00013 7.69161ZM4.88118 8.38239C3.60362 8.38239 2.74905 9.69737 3.26792 10.8648C3.44693 11.2676 3.77014 11.5888 4.174 11.7654L5.17049 12.2011C6.33698 12.711 7.66337 12.711 8.82986 12.201L9.98962 11.694C10.2929 11.5614 10.5517 11.3446 10.7353 11.0692C11.5009 9.92071 10.6776 8.38239 9.29736 8.38239H4.88118Z"
                                  fill="#005C6F"
                                />
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6"
                                  stroke="#005C6F"
                                  stroke-width="1.3"
                                />
                              </svg>
                            )}
                          </figure>
                          {isTrue.length > 0 && !openAssign ? (
                            <></>
                          ) : (
                            <span
                              className={`text-13 font-medium mr-2 ${
                                openAssign ? "text-primary" : ""
                              }`}
                              onClick={() => {
																setOpenAssign(!openAssign);
																setOpenChannels(false);
																setOpenLabels(false);
															}}
                            >
                              Assigned user
                            </span>
                          )}
                          <figure>
                            {!openAssign ? (
                              <svg
                                width="8"
                                height="5"
                                viewBox="0 0 8 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${isTrue.length > 0 ? "mr-2" : ""}`}
                              >
                                <path
                                  d="M7 1L4 3.9965L1 1"
                                  stroke="#4C5254"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="8"
                                height="5"
                                viewBox="0 0 8 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 3.99609L4 0.999593L7 3.99609"
                                  stroke="#005C6F"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            )}
                          </figure>
                          {selectedUser.length > 0 &&
                            !openAssign &&
                            selectedUser.map((item, index) => {
                              return (
                                <>
                                  {item.checked && (
                                    <div className="box-filter-assignTo">
                                      <img
                                        className="mr-1"
                                        src={avatarPic}
                                        style={{ width: 16, height: 16 }}
                                        alt=""
                                      />
                                      <p className="text-xs font-medium mr-2 truncate">
                                        {item.name}
                                      </p>
                                      <figure
                                        onClick={() => handleRemove(index)}
                                      >
                                        <svg
                                          width="8"
                                          height="8"
                                          viewBox="0 0 8 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1 7L7 1"
                                            stroke="#005C6F"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M1 1L7 7"
                                            stroke="#005C6F"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </figure>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </button>
                        <div
                          className={`dropdown-menu dropdown-menu--with-border mt-3 ${
                            openAssign ? "open" : ""
                          } p-2`}
                          style={{
                            right: "0px",
                            width: "316px",
                            top: "20px",
                            transition: "all 300ms ease-in",
                          }}
                        >
                          <div style={{ margin: "10px 10px 0px" }}>
                            <div
                              className={`input-field input-field--sm input-field--prepend no-hover ${
                                isInputFocused
                                  ? "input-field--prepend--focus"
                                  : ""
                              }`}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-2"
                              >
                                <g opacity="0.8">
                                  <circle
                                    cx="6.33333"
                                    cy="6.33333"
                                    r="5.33333"
                                    stroke="#636566"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    d="M10.3333 10.334L12.9999 13.0007"
                                    stroke="#636566"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </g>
                              </svg>

                              <input
                                onFocus={() => setIsInputFocused(true)}
                                onBlur={() => setIsInputFocused(false)}
                                placeholder="Search"
                                value={inputValue}
                                onChange={(e) => {
                                  setInputValue(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <p
                            className="text-grey-700 text-xs font-medium text-left mt-4"
                            style={{ marginLeft: 10 }}
                          >
                            Assign to
                          </p>
                          <div
                            className={`no-hover dropdown-menu__overflow-y-scroll mt-1`}
                          >
                            {users.map((user, index) => {
                              return (
                                <div
                                  key={index}
                                  className="dropdown-menu__list font-medium"
                                  style={{ color: "#2B3639" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox custom-checkbox--xs mr-3"
                                    name={`${user.id}`}
                                    checked={user.checked}
                                    onChange={() => handleChange(index)}
                                  />
                                  <img
                                    className="mr-3"
                                    src={avatarPic}
                                    style={{ width: 26, height: 26 }}
                                    alt=""
                                  />
                                  {user.name}
                                  {index === 0 && (
                                    <span className="ml-1 text-grey-600 text-13 font-medium">
                                      You
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="relative">
                        <button
                          className={`btn ${
                            openChannels
                              ? "bg-grey-90 border border-primary-border btn--xs-28"
                              : "bg-white border border-grey-400 btn--xs-28"
                          } ${
                            isTrueChannels.length > 0 && !openChannels
                              ? "bg-grey-90 border border-primary-border btn--xs-28-p-1"
                              : ""
                          }`}
                          style={{ borderRadius: 20 }}
                        >
                          <figure
                            className="mr-2"
                            onClick={() => {
															setOpenChannels(!openChannels);
															setOpenAssign(false);
															setOpenLabels(false);
														}}
                          >
                            {!openChannels ? (
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="1"
                                  y="1"
                                  width="4.8"
                                  height="4.8"
                                  rx="2.4"
                                  fill="#4C5254"
                                  stroke="#4C5254"
                                  stroke-width="1.3"
                                />
                                <rect
                                  x="1"
                                  y="8.19922"
                                  width="4.8"
                                  height="4.8"
                                  rx="2.4"
                                  fill="#4C5254"
                                  stroke="#4C5254"
                                  stroke-width="1.3"
                                />
                                <rect
                                  x="8.2002"
                                  y="1"
                                  width="4.8"
                                  height="4.8"
                                  rx="2.4"
                                  fill="#4C5254"
                                  stroke="#4C5254"
                                  stroke-width="1.3"
                                />
                                <rect
                                  x="8.2002"
                                  y="8.19922"
                                  width="4.8"
                                  height="4.8"
                                  rx="2.4"
                                  stroke="#4C5254"
                                  stroke-width="1.3"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="1"
                                  y="1"
                                  width="4.8"
                                  height="4.8"
                                  rx="2.4"
                                  fill="#005C6F"
                                  stroke="#005C6F"
                                  stroke-width="1.3"
                                />
                                <rect
                                  x="1"
                                  y="8.19922"
                                  width="4.8"
                                  height="4.8"
                                  rx="2.4"
                                  fill="#005C6F"
                                  stroke="#005C6F"
                                  stroke-width="1.3"
                                />
                                <rect
                                  x="8.2002"
                                  y="1"
                                  width="4.8"
                                  height="4.8"
                                  rx="2.4"
                                  fill="#005C6F"
                                  stroke="#005C6F"
                                  stroke-width="1.3"
                                />
                                <rect
                                  x="8.2002"
                                  y="8.19922"
                                  width="4.8"
                                  height="4.8"
                                  rx="2.4"
                                  stroke="#005C6F"
                                  stroke-width="1.3"
                                />
                              </svg>
                            )}
                          </figure>
                          {isTrueChannels.length > 0 && !openChannels ? (
                            <></>
                          ) : (
                            <span
                              className={`text-13 font-medium mr-2 ${
                                openChannels ? "text-primary" : ""
                              }`}
                              onClick={() => {
																setOpenChannels(!openChannels);
																setOpenAssign(false);
																setOpenLabels(false);
															}}
                            >
                              Channel
                            </span>
                          )}
                          <figure>
                            {!openChannels ? (
                              <svg
                                width="8"
                                height="5"
                                viewBox="0 0 8 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  isTrueChannels.length > 0 ? "mr-2" : ""
                                }`}
                              >
                                <path
                                  d="M7 1L4 3.9965L1 1"
                                  stroke="#4C5254"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="8"
                                height="5"
                                viewBox="0 0 8 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 3.99609L4 0.999593L7 3.99609"
                                  stroke="#005C6F"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            )}
                          </figure>
                          {selectedChannels.length > 0 &&
                            !openChannels &&
                            selectedChannels.map((item, index) => {
                              return (
                                <>
                                  {item.checked && (
                                    <div className="box-filter-assignTo">
                                      <img
                                        className="mr-1"
                                        src={item.icon}
                                        style={{ width: 16, height: 16 }}
                                        alt=""
                                      />
                                      <p className="text-xs font-medium mr-2 truncate">
                                        {item.name}
                                      </p>
                                      <figure
                                        onClick={() =>
                                          handleRemoveChannels(index)
                                        }
                                      >
                                        <svg
                                          width="8"
                                          height="8"
                                          viewBox="0 0 8 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1 7L7 1"
                                            stroke="#005C6F"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M1 1L7 7"
                                            stroke="#005C6F"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </figure>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </button>
                        <div
                          className={`dropdown-menu dropdown-menu--with-border mt-3 ${
                            openChannels ? "open" : ""
                          } p-2`}
                          style={{
                            right: "0px",
                            width: "316px",
                            top: "20px",
                            transition: "all 300ms ease-in",
                          }}
                        >
                          <div style={{ margin: "10px 10px 0px" }}>
                            <div
                              className={`input-field input-field--sm input-field--prepend no-hover ${
                                isInputFocused
                                  ? "input-field--prepend--focus"
                                  : ""
                              }`}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-2"
                              >
                                <g opacity="0.8">
                                  <circle
                                    cx="6.33333"
                                    cy="6.33333"
                                    r="5.33333"
                                    stroke="#636566"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    d="M10.3333 10.334L12.9999 13.0007"
                                    stroke="#636566"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </g>
                              </svg>

                              <input
                                onFocus={() => setIsInputFocused(true)}
                                onBlur={() => setIsInputFocused(false)}
                                placeholder="Search"
                                value={inputValue}
                                onChange={(e) => {
                                  setInputValue(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <p
                            className="text-grey-700 text-xs font-medium text-left mt-4"
                            style={{ marginLeft: 10 }}
                          >
                            Channels
                          </p>
                          <div
                            className={`no-hover dropdown-menu__overflow-y-scroll mt-1`}
                          >
                            {channels.map((channel, index) => {
                              return (
                                <div
                                  key={index}
                                  className="dropdown-menu__list font-medium"
                                  style={{ color: "#2B3639" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox custom-checkbox--xs mr-3"
                                    name={`${channel.id}`}
                                    checked={channel.checked}
                                    onChange={() => handleChangeChannels(index)}
                                  />
                                  <img
                                    className="mr-3"
                                    src={channel.icon}
                                    style={{ width: 26, height: 26 }}
                                    alt=""
                                  />
                                  {channel.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="relative">
                        <button
                          className={`btn ${
                            openLabels
                              ? "bg-grey-90 border border-primary-border btn--xs-28"
                              : "bg-white border border-grey-400 btn--xs-28"
                          } ${
                            isTrueLabels.length > 0 && !openLabels
                              ? "bg-grey-90 border border-primary-border btn--xs-28-p-1"
                              : ""
                          }`}
                          style={{ borderRadius: 20 }}
                        >
                          <figure
                            className="mr-2"
                            onClick={() => {
															setOpenLabels(!openLabels)
															setOpenAssign(false);
															setOpenChannels(false);
														}}
                          >
                            {!openLabels ? (
                              <svg
                                width="13"
                                height="15"
                                viewBox="0 0 13 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.846841 6.94475L5.90734 1.9488C6.19903 1.66101 6.59105 1.5 7.00018 1.5H10.1807"
                                  stroke="#4C5254"
                                  stroke-width="1.3"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M1.45231 9.09147L7.0604 3.48338C7.2043 3.33871 7.39953 3.25781 7.60332 3.25781H11.8992C12.3239 3.25781 12.6685 3.60239 12.6685 4.02708V8.3222C12.6685 8.52599 12.5868 8.722 12.4429 8.8659L6.83483 14.474C6.23435 15.0745 5.26052 15.0745 4.66005 14.474L1.45231 11.2663C0.851834 10.6658 0.851834 9.69195 1.45231 9.09147ZM9.36275 7.5357C9.89972 7.5357 10.335 7.1004 10.335 6.56342C10.335 6.02645 9.89972 5.59115 9.36275 5.59115C8.82578 5.59115 8.39047 6.02645 8.39047 6.56342C8.39047 7.1004 8.82578 7.5357 9.36275 7.5357Z"
                                  fill="#4C5254"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="13"
                                height="15"
                                viewBox="0 0 13 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.846841 6.94475L5.90734 1.9488C6.19903 1.66101 6.59105 1.5 7.00018 1.5H10.1807"
                                  stroke="#005C6F"
                                  stroke-width="1.3"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M1.45231 9.09147L7.0604 3.48338C7.2043 3.33871 7.39953 3.25781 7.60332 3.25781H11.8992C12.3239 3.25781 12.6685 3.60239 12.6685 4.02708V8.3222C12.6685 8.52599 12.5868 8.722 12.4429 8.8659L6.83483 14.474C6.23435 15.0745 5.26052 15.0745 4.66005 14.474L1.45231 11.2663C0.851834 10.6658 0.851834 9.69195 1.45231 9.09147ZM9.36275 7.5357C9.89972 7.5357 10.335 7.1004 10.335 6.56342C10.335 6.02645 9.89972 5.59115 9.36275 5.59115C8.82578 5.59115 8.39047 6.02645 8.39047 6.56342C8.39047 7.1004 8.82578 7.5357 9.36275 7.5357Z"
                                  fill="#005C6F"
                                />
                              </svg>
                            )}
                          </figure>
                          {isTrueLabels.length > 0 && !openLabels ? (
                            <></>
                          ) : (
                            <span
                              className={`text-13 font-medium mr-2 ${
                                openLabels ? "text-primary" : ""
                              }`}
                              onClick={() => {
																setOpenLabels(!openLabels);
																setOpenAssign(false);
																setOpenChannels(false);
															}}
                            >
                              Label
                            </span>
                          )}
                          <figure>
                            {!openLabels ? (
                              <svg
                                width="8"
                                height="5"
                                viewBox="0 0 8 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  isTrueLabels.length > 0 ? "mr-2" : ""
                                }`}
                              >
                                <path
                                  d="M7 1L4 3.9965L1 1"
                                  stroke="#4C5254"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="8"
                                height="5"
                                viewBox="0 0 8 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 3.99609L4 0.999593L7 3.99609"
                                  stroke="#005C6F"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            )}
                          </figure>
                          {selectedLabels.length > 0 &&
                            !openLabels &&
                            selectedLabels.map((item, index) => {
                              return (
                                <>
                                  {item.checked && (
                                    <div className="box-filter-assignTo">
                                      <p className="text-xs font-medium mr-2 truncate">
                                        {item.name}
                                      </p>
                                      <figure
                                        onClick={() =>
                                          handleRemoveLabels(index)
                                        }
                                      >
                                        <svg
                                          width="8"
                                          height="8"
                                          viewBox="0 0 8 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1 7L7 1"
                                            stroke="#005C6F"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M1 1L7 7"
                                            stroke="#005C6F"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </figure>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </button>
                        <div
                          className={`dropdown-menu dropdown-menu--with-border mt-3 ${
                            openLabels ? "open" : ""
                          } p-2`}
                          style={{
                            right: "0px",
                            width: "316px",
                            top: "20px",
                            transition: "all 300ms ease-in",
                          }}
                        >
                          <div style={{ margin: "10px 10px 0px" }}>
                            <div
                              className={`input-field input-field--sm input-field--prepend no-hover ${
                                isInputFocused
                                  ? "input-field--prepend--focus"
                                  : ""
                              }`}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-2"
                              >
                                <g opacity="0.8">
                                  <circle
                                    cx="6.33333"
                                    cy="6.33333"
                                    r="5.33333"
                                    stroke="#636566"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    d="M10.3333 10.334L12.9999 13.0007"
                                    stroke="#636566"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </g>
                              </svg>

                              <input
                                onFocus={() => setIsInputFocused(true)}
                                onBlur={() => setIsInputFocused(false)}
                                placeholder="Search"
                                value={inputValue}
                                onChange={(e) => {
                                  setInputValue(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <p
                            className="text-grey-700 text-xs font-medium text-left mt-4"
                            style={{ marginLeft: 10 }}
                          >
                            Labels
                          </p>
                          <div
                            className={`no-hover dropdown-menu__overflow-y-scroll mt-1`}
                          >
                            {labelData.map((label, index) => {
                              return (
                                <div
                                  key={index}
                                  className="dropdown-menu__list font-medium"
                                  style={{ color: "#2B3639" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox custom-checkbox--xs mr-3"
                                    name={`${label.id}`}
                                    checked={label.checked}
                                    onChange={() => handleChangeLabels(index)}
                                  />
                                  {label.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center cursor-pointer">
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 9L9 1"
                          stroke="#005C6F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M1 1L9 9"
                          stroke="#005C6F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                      <span className="text-13 font-medium text-primary ml-2">
                        Clear filter
                      </span>
                    </div>
                  </div>
                )}
                <div className="h-full width-fill-available mt-5 mx-6">
                  <div className="box-card-wrapper">
                    <div
                      className={`box-card-container mr-5 ${
                        !isGeneral ? "clip-path" : ""
                      }`}
                    >
                      {!isGeneral && (
                        <div
                          className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white"
                          style={{
                            width: 40,
                            height: 24,
                            border: "1px solid rgba(4, 145, 171, 0.3)",
                            borderRadius: "20px",
                          }}
                        >
                          £0
                        </div>
                      )}
                      <div
                        className={`flex justify-between ml-3 ${
                          isGeneral ? "mt-3" : "mt-2"
                        }`}
                      >
                        <div className="font-semibold text-lg">New</div>
                        <div className="flex items-center">
                          <div
                            className="cursor-pointer relative"
                            onClick={() => setShowDelete(1)}
                          >
                            <img
                              src={iconMoreRound}
                              alt=""
                              style={{ width: 16, height: 4 }}
                            />
                            <div
                              className={`dropdown-menu dropdown-menu--more ${
                                showDelete === 1 ? "open" : ""
                              }`}
                              style={{ top: 10 }}
                            >
                              <div className="dropdown-menu__list red-text">
                                <img
                                  src={deleteIcon}
                                  alt=""
                                  className="mr-2"
                                  style={{ width: 16, height: 16 }}
                                />
                                Delete
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className="text-sm flex justify-center text-white ml-3 font-semibold bg-secondary"
                              style={{
                                width: 32,
                                height: 22,
                                borderRadius: "20px",
                              }}
                            >
                              2
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <Link
                          className="box-card"
                          to="/welcome-to-your-sales-box-message"
                        >
                          <div className="flex mt-1">
                            <figure
                              className="avatar-container mr-4"
                              style={{ width: 48, height: 48 }}
                            >
                              <img src={iconCardProfile} alt="" />
                            </figure>
                            <div>
                              <div className="font-semibold">
                                Enquiry Assistant
                              </div>
                              <div className="mt-1 text-15 truncate w-64">
                                Welcome to your sales box
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className="text-white text-10 font-semibold flex justify-center bg-green-700"
                              style={{
                                width: 36,
                                height: 16,
                                borderRadius: "20px",
                              }}
                            >
                              New
                            </div>
                            <div className="flex justify-end mt-4 text-sm">
                              1m
                            </div>
                          </div>
                        </Link>
                        <Link
                          className="box-card mt-3"
                          to="/clicked-enquiry-view"
                        >
                          <div className="flex mt-1">
                            <figure
                              className="avatar-container mr-4"
                              style={{ width: 48, height: 48 }}
                            >
                              <img src={iconCardProfile} alt="" />
                            </figure>
                            <div>
                              <div className="font-semibold">
                                Enquiry Assistant
                              </div>
                              <div className="mt-1 text-15 truncate w-64">
                                Welcome to your sales box
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className="text-white text-10 font-semibold flex justify-center bg-green-700"
                              style={{
                                width: 36,
                                height: 16,
                                borderRadius: "20px",
                              }}
                            >
                              New
                            </div>
                            <div className="flex justify-end mt-4 text-sm">
                              2m
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    {!isGeneral ? (
                      <>
                        <div
                          className={`box-card-container mr-5 ${
                            !isGeneral ? "clip-path" : ""
                          }`}
                        >
                          <div
                            className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white"
                            style={{
                              width: 40,
                              height: 24,
                              border: "1px solid rgba(4, 145, 171, 0.3)",
                              borderRadius: "20px",
                            }}
                          >
                            £0
                          </div>
                          <div className="flex justify-between mt-2 ml-3">
                            <div className="font-semibold text-lg">
                              Contacting
                            </div>
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer relative"
                                onClick={() => setShowDelete(2)}
                              >
                                <img
                                  src={iconMoreRound}
                                  alt=""
                                  style={{ width: 16, height: 4 }}
                                />
                                <div
                                  className={`dropdown-menu dropdown-menu--more ${
                                    showDelete === 2 ? "open" : ""
                                  }`}
                                  style={{ top: 10 }}
                                >
                                  <div className="dropdown-menu__list red-text">
                                    <img
                                      src={deleteIcon}
                                      alt=""
                                      className="mr-2"
                                      style={{ width: 16, height: 16 }}
                                    />
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5">
                            <Link
                              className="box-card"
                              to="/clicked-enquiry-view"
                            >
                              <div className="flex mt-1">
                                <figure
                                  className="avatar-container mr-4"
                                  style={{ width: 48, height: 48 }}
                                >
                                  <img src={iconCardProfile} alt="" />
                                </figure>
                                <div>
                                  <div className="font-semibold">
                                    Enquiry Assistant
                                  </div>
                                  <div className="mt-1 text-15 truncate w-64">
                                    Welcome to your sales box
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div
                                  className="text-white text-10 font-semibold flex justify-center bg-green-700"
                                  style={{
                                    width: 36,
                                    height: 16,
                                    borderRadius: "20px",
                                  }}
                                >
                                  New
                                </div>
                                <div className="flex justify-end mt-4 text-sm">
                                  1m
                                </div>
                              </div>
                            </Link>
                            <Link
                              className="box-card mt-3"
                              to="/clicked-enquiry-view"
                            >
                              <div className="flex mt-1">
                                <figure
                                  className="avatar-container mr-4"
                                  style={{ width: 48, height: 48 }}
                                >
                                  <img src={iconCardProfile} alt="" />
                                </figure>
                                <div>
                                  <div className="font-semibold">
                                    Enquiry Assistant
                                  </div>
                                  <div className="mt-1 text-15 truncate w-64">
                                    Welcome to your sales box
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div
                                  className="text-white text-10 font-semibold flex justify-center bg-green-700"
                                  style={{
                                    width: 36,
                                    height: 16,
                                    borderRadius: "20px",
                                  }}
                                >
                                  New
                                </div>
                                <div className="flex justify-end mt-4 text-sm">
                                  2m
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div
                          className={`box-card-container mr-5 ${
                            !isGeneral ? "clip-path" : ""
                          }`}
                        >
                          <div
                            className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white"
                            style={{
                              width: 40,
                              height: 24,
                              border: "1px solid rgba(4, 145, 171, 0.3)",
                              borderRadius: "20px",
                            }}
                          >
                            £0
                          </div>
                          <div className="flex justify-between mt-2 ml-3">
                            <div className="font-semibold text-lg">Won</div>
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer relative"
                                onClick={() => setShowDelete(3)}
                              >
                                <img
                                  src={iconMoreRound}
                                  alt=""
                                  style={{ width: 16, height: 4 }}
                                />
                                <div
                                  className={`dropdown-menu dropdown-menu--more ${
                                    showDelete === 3 ? "open" : ""
                                  }`}
                                  style={{ top: 10 }}
                                >
                                  <div className="dropdown-menu__list red-text">
                                    <img
                                      src={deleteIcon}
                                      alt=""
                                      className="mr-2"
                                      style={{ width: 16, height: 16 }}
                                    />
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5">
                            <div
                              className="box-card box-card box-card--empty"
                              style={{ height: 78 }}
                            >
                              <div className="text-sm text-center flex items-center px-12">
                                Drag enquiries to this stage once you have made
                                contact
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`box-card-container mr-5 ${
                            !isGeneral ? "clip-path" : ""
                          }`}
                        >
                          <div
                            className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white"
                            style={{
                              width: 40,
                              height: 24,
                              border: "1px solid rgba(4, 145, 171, 0.3)",
                              borderRadius: "20px",
                            }}
                          >
                            £0
                          </div>
                          <div className="flex justify-between mt-2 ml-3">
                            <div className="font-semibold text-lg">Lost</div>
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer relative"
                                onClick={() => setShowDelete(4)}
                              >
                                <img
                                  src={iconMoreRound}
                                  alt=""
                                  style={{ width: 16, height: 4 }}
                                />
                                <div
                                  className={`dropdown-menu dropdown-menu--more ${
                                    showDelete === 4 ? "open" : ""
                                  }`}
                                  style={{ top: 10 }}
                                >
                                  <div className="dropdown-menu__list red-text">
                                    <img
                                      src={deleteIcon}
                                      alt=""
                                      className="mr-2"
                                      style={{ width: 16, height: 16 }}
                                    />
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5">
                            <div
                              className="box-card box-card box-card--empty"
                              style={{ height: 78 }}
                            >
                              <div className="text-sm text-center flex items-center px-12">
                                Drag enquiries to this stage once you have made
                                contact
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="box-card-container__add mr-5">
                          {!addStage ? (
                            <>
                              <div className="flex justify-between bg-grey-90 rounded-lg py-1 px-3 items-center">
                                <div className="flex items-center">
                                  <img
                                    src={iconPlus}
                                    alt=""
                                    className="cursor-pointer mr-3"
                                    style={{ width: 12, height: 12 }}
                                  />
                                  <div className="font-semibold text-lg">
                                    Add Stage
                                  </div>
                                </div>
                                <div onClick={() => setAddStage(true)}>
                                  <img
                                    src={iconEdit}
                                    alt=""
                                    className="cursor-pointer"
                                    style={{ width: 16, height: 16 }}
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="box-card box-card--empty ml-2 mr-2">
                                  <p className="text-sm text-center px-10">
                                    Add another stage to keep track of your
                                    process step by step
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div>
                              <div
                                className={`flex items-center ${
                                  addStage ? "is-open" : ""
                                }`}
                                style={{ width: 394 }}
                              >
                                <input
                                  type="text"
                                  onFocus={() => setIsInputFocused(true)}
                                  onBlur={() => setIsInputFocused(false)}
                                  placeholder=""
                                  value={searchInputValue}
                                  onChange={(e) =>
                                    setSearchInputValue(e.target.value)
                                  }
                                  className="input-field input-field--sm"
                                />
                              </div>
                              <div className="flex justify-end mt-15">
                                <button
                                  className="btn btn--sm-60 btn--grey mr-2"
                                  onClick={() => setAddStage(false)}
                                >
                                  Cancel
                                </button>
                                <button className="btn btn--primary btn--sm-60">
                                  Save
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </section>
      <InviteModal
        addTeamMember={openInviteModal}
        setAddTeamMember={setOpenInviteModal}
        hideTabs={true}
      />
      <ModalUploadMultiContactSetup1
        showUploadContactModal={showUploadContactModal}
        setShowUploadContactModal={setShowUploadContactModal}
      />
      <BoxInfo
        setOpenBoxInfo={setOpenBoxInfo}
        openBoxInfo={openBoxInfo}
        setOpenBoxTemplate={setOpenBoxTemplate}
      />
    </main>
  );
};

export default SalesBoxFirstView;
