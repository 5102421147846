import React, { useState } from 'react';
import plusIcon from '../assets/icons/icon-plus-icon.svg';
import avatarPic from "../assets/images/avatar-pic.png";

const users = [
	{
		id: 1,
		name: "John Leon",
	},
	{
		id: 2,
		name: "Madison Inouye",
	},
	{
		id: 3,
		name: "Olivia Rhye",
	},
	{
		id: 4,
		name: "Charles Wundeng",
	},
	{
		id: 5,
		name: "Lisa Fotios",
	},
	{
		id: 6,
		name: "Elene Mako",
	},
	{
		id: 7,
		name: "Alex Foster"
	}
];

const InviteDropdown = () => {
	const [isAdminUser, setIsAdminUser] = useState(1);

	return (
		<div className='flex justify-center'>
			<div className='dropdown-menu--invite relative'>
				<div className='flex justify-between pl-3 pr-3'>
					<div className='font-semibold'>8 Channel members</div>
					<div>
						<button
							className="btn btn--primary text-13 text-white"
							style={{ padding: "5px 14px", borderRadius: 6 }}
						>
							<img src={plusIcon} alt='' style={{ width: 8, height: 8, marginRight: 6 }} />
							Invite
						</button>
					</div>
				</div>
				<div className='pt-5'>
					<div className='dropdown-menu__overflow-y-scroll' style={{ height: 282 }}>
						{users.map((user, index) => {
							return (
								<div
									key={index}
									className="dropdown-menu__list flex justify-between items-center"
								>
									<div className='flex items-center font-semibold'>
										<img
											className="mr-3"
											src={avatarPic}
											style={{ width: 26, height: 26 }}
											alt=''
										/>
										{user.name}
										{user.id === isAdminUser && <div>(Me)</div>}
									</div>
									<div className='dropdown-menu__list--right-side text-13'>{user.id === isAdminUser ? 'Leave Channel' : 'Remove'}</div>
								</div>
							);
						})}			
					</div>	
				</div>
			</div>
		</div>
	)
}

export default InviteDropdown;