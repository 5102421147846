import React, { useState } from 'react'
import AccountsSideBarExpanded from './Common/AccountsSideBarExpanded'
import AccountsSideLinks from './Common/AccountsSideLinks'
import AddNewAccountLinks from './Common/AddNewAccountLinks'

const SelectAccountType = () => {
	const [accountType, setAccountType] = useState(1);
	const [openCloneAccountDropdown, setOpenCloneAccountDropdown] = useState(false)
	const [selectedAcccount, setSelectedAccount] = useState('Select the account to clone')
	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<AccountsSideLinks />
				<AccountsSideBarExpanded />
				<section className="dashboard__main">
					<div className="dashboard__main__header">
						<h3 className="font-semibold text-2xl">Add a new account</h3>
					</div>
					<div className="dashboard__main__body" style={{ height: 'calc(100% - 83px)' }}>
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<AddNewAccountLinks activeScreen={2} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay p-10">
							<div className="text-center">
								<h2 className="text-2xl font-semibold">New account or clone of existing?</h2>
								<p className="text-15 text-grey-800 mt-3">
									You can choose to clone an existing account including all it’s settings, boxes, bots and automations or create a fresh new account.
								</p>
							</div>
							<div className='card-box p-6 mt-6'>
								<ul>
									<li className="email-provider-list" style={{ alignItems: 'flex-start' }} onClick={() => setAccountType(1)}>
										<div className="flex mt-1">
											<input
												checked={accountType === 1}
												type="radio"
												className="custom-radio-button"
												name="radio-btn"
											/>
										</div>
										<div className="ml-3">
											<h4 className="font-semibold mb-2">
												New account
											</h4>
											<p className="text-sm text-grey-800">
												Fresh account, default enquirybox settings
											</p>
										</div>
									</li>
									<li className="email-provider-list" style={{ alignItems: 'flex-start', padding: '16px 20px 16px 16px' }} onClick={() => setAccountType(2)}>
										<div className="flex mt-1">
											<input
												checked={accountType === 2}
												type="radio"
												className="custom-radio-button custom-radio-button--sm"
												name="radio-btn"
											/>
										</div>
										<div className="ml-3">
											<h4 className="font-semibold mb-2">
												Cloned account
											</h4>
											<p className="text-sm text-grey-800">
												Cloned from an existing account including its setup
											</p>
											{accountType === 2 &&
												<>
													<div className="relative mt-5" onClick={() => setOpenCloneAccountDropdown(!openCloneAccountDropdown)}>
														<label className='text-15 font-semibold'>Select the account to clone</label>
														<input
															className="input-field input-field--select input-field--icon-pos-left input-field--icon-industry text-15 mt-1"
															// placeholder="Select Industry"
															value={selectedAcccount}
														/>
														<div className={`dropdown-menu p-2 ${openCloneAccountDropdown ? 'open' : ''}`}>
															<div className="dropdown-menu__list">
																<figure className="avatar-container avatar-container--full bg-grey-400 mr-3"
																	style={{
																		width: 26,
																		height: 26,
																		border: "1px solid rgba(0, 0, 0, 0.05)",
																	}}>
																	<span className='text-13 font-semibold'>S</span>
																</figure>
																<span className="text-sm font-medium text-grey-900">
																	Smiles Dental London branch
																</span>
															</div>
															<div className="dropdown-menu__list">
																<figure className="avatar-container avatar-container--full bg-grey-400 mr-3"
																	style={{
																		width: 26,
																		height: 26,
																		border: "1px solid rgba(0, 0, 0, 0.05)",
																	}}>
																	<span className='text-13 font-semibold'>S</span>
																</figure>
																<span className="text-sm font-medium text-grey-900">
																	Smiles Dental group- Leeds branch
																</span>
															</div>
															<div className="dropdown-menu__list">
																<figure className="avatar-container avatar-container--full bg-grey-400 mr-3"
																	style={{
																		width: 26,
																		height: 26,
																		border: "1px solid rgba(0, 0, 0, 0.05)",
																	}}>
																	<span className='text-13 font-semibold'>S</span>
																</figure>
																<span className="text-sm font-medium text-grey-900">
																	Smiles Dental group- Guildford branch
																</span>
															</div>
															<div className="dropdown-menu__list">
																<figure className="avatar-container avatar-container--full bg-grey-400 mr-3"
																	style={{
																		width: 26,
																		height: 26,
																		border: "1px solid rgba(0, 0, 0, 0.05)",
																	}}>
																	<span className='text-13 font-semibold'>S</span>
																</figure>
																<span className="text-sm font-medium text-grey-900">
																	Smiles Dental group- Samson branch
																</span>
															</div>
														</div>
													</div>
													<div className='mt-6'>
														<h3 className='font-semibold text-15 mb-1'>Select the features you would like to clone</h3>
														<p className='text-sm text-grey-800'>Please note, any automations or bots will be turned off by default</p>
														<div className='flex justify-between py-3 pl-1 mt-3'>
															<div className='flex'>
																<figure style={{ marginTop: 6 }}>
																	<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
																		<rect x="1" y="1" width="16" height="4.8" rx="2" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M3.40039 3.39844H7.40039" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M14.4004 3.39844H14.9004" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<rect x="1" y="9" width="16" height="4.8" rx="2" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M3.40039 11.3984H7.40039" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M14.4004 11.3984H14.9004" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																	</svg>
																</figure>
																<div className='ml-3'>
																	<h3 className='text-15 font-semibold'>Box names & stages</h3>
																	<p className='text-sm text-grey-800'>All box names and enquiry stages</p>
																</div>
															</div>
															<div className='mt-1'>
																<input
																	type="checkbox"
																	className="custom-checkbox custom-checkbox--sm"
																	name=""
																/>
															</div>
														</div>
														<div className='flex justify-between py-3 pl-1'>
															<div className='flex'>
																<figure style={{ marginTop: 6 }}>
																	<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M15.4 5.7V4C15.4 2.34315 14.0569 1 12.4 1H4C2.34315 1 1 2.34314 1 4V10.6C1 12.2569 2.34315 13.6 4 13.6H8.2" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M4.15039 4.60156L8.20039 7.30156L12.2504 4.60156" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M10.55 10.405L16.568 6.84891C16.6622 6.79327 16.7645 6.90751 16.6989 6.99501L14.3686 10.102C14.3322 10.1505 14.347 10.22 14.4 10.2494L16.8516 11.6114C16.9182 11.6484 16.9206 11.7433 16.856 11.7836L10.2848 15.8907C10.1897 15.95 10.0839 15.8322 10.1531 15.7441L13.0273 12.086C13.0654 12.0376 13.0511 11.9667 12.9973 11.9368L10.5523 10.5785C10.4848 10.541 10.4835 10.4443 10.55 10.405Z" fill="#636566" />
																	</svg>
																</figure>
																<div className='ml-3'>
																	<h3 className='text-15 font-semibold'>Automations</h3>
																	<p className='text-sm text-grey-800'>Any automations or autoresponders</p>
																</div>
															</div>
															<div className='mt-1'>
																<input
																	type="checkbox"
																	className="custom-checkbox custom-checkbox--sm"
																	name=""
																/>
															</div>
														</div>
														<div className='flex justify-between py-3 pl-1'>
															<div className='flex'>
																<figure style={{ marginTop: 6 }}>
																	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M9.20028 10.0731V9.85972C9.20028 9.16017 9.63228 8.7815 10.0661 8.49083C10.4892 8.20639 10.9132 7.83483 10.9132 7.1495C10.9132 6.20372 10.1469 5.4375 9.20117 5.4375C8.25539 5.4375 7.48828 6.20194 7.48828 7.14772" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M9.19968 12.4844C9.11435 12.4844 9.04502 12.5537 9.04591 12.639C9.04591 12.7244 9.11524 12.7937 9.20057 12.7937C9.28591 12.7937 9.35524 12.7244 9.35524 12.639C9.35524 12.5528 9.28591 12.4844 9.19968 12.4844" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<circle cx="9" cy="9" r="8" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																	</svg>
																</figure>
																<div className='ml-3'>
																	<h3 className='font-semibold text-15'>Questions</h3>
																	<p className='text-sm text-grey-800'>All questions and answers</p>
																</div>
															</div>
															<div className='mt-1'>
																<input
																	type="checkbox"
																	className="custom-checkbox custom-checkbox--sm"
																	name=""
																/>
															</div>
														</div>
														<div className='flex justify-between py-3 pl-1'>
															<div className='flex'>
																<figure style={{ marginTop: 6 }}>
																	<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M8.45161 15.4516H4C2.34315 15.4516 1 14.1085 1 12.4516V4C1 2.34315 2.34315 1 4 1H12.4516C14.1085 1 15.4516 2.34315 15.4516 4V5.48214" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M14.1436 9V14.7143" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M11.2861 11.8594H17.0004" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																	</svg>
																</figure>
																<div className='ml-3'>
																	<h3 className='text-15 font-semibold'>Channels</h3>
																	<p className='text-sm text-grey-800'>Create the same channels. The will need to be connected again</p>
																</div>
															</div>
															<div className='mt-1'>
																<input
																	type="checkbox"
																	className="custom-checkbox custom-checkbox--sm"
																	name=""
																/>
															</div>
														</div>
														<div className='flex justify-between py-3 pl-1'>
															<div className='flex'>
																<figure style={{ marginTop: 6 }}>
																	<svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M17.1597 9.10938V8.35938C17.0306 8.35938 16.9036 8.3927 16.7912 8.45614L17.1597 9.10938ZM20.9834 12.4075L20.2446 12.5365L20.2446 12.5369L20.9834 12.4075ZM21.002 12.6859L20.2522 12.7016L20.2522 12.702L21.002 12.6859ZM19.961 13.7725L19.9686 14.5225L19.9771 14.5223L19.961 13.7725ZM19.9383 13.7727V14.5228L19.9459 14.5227L19.9383 13.7727ZM15.3037 8.88283C14.8895 8.88283 14.5537 9.21862 14.5537 9.63283C14.5537 10.047 14.8895 10.3828 15.3037 10.3828V8.88283ZM17.2261 13.0227C16.8119 13.0227 16.4761 13.3585 16.4761 13.7727C16.4761 14.1869 16.8119 14.5227 17.2261 14.5227V13.0227ZM17.1597 9.85938C18.6853 9.85938 19.976 10.9995 20.2446 12.5365L21.7222 12.2784C21.337 10.0741 19.4644 8.35938 17.1597 8.35938V9.85938ZM20.2446 12.5369C20.2428 12.5268 20.2446 12.5339 20.2469 12.5685C20.2491 12.6 20.2509 12.6437 20.2522 12.7016L21.7518 12.6702C21.7491 12.5379 21.7425 12.3947 21.7221 12.278L20.2446 12.5369ZM20.2522 12.702C20.2559 12.8753 20.1184 13.0189 19.9449 13.0227L19.9771 14.5223C20.9787 14.5008 21.7733 13.6715 21.7518 12.6698L20.2522 12.702ZM19.9533 13.0225L19.9306 13.0228L19.9459 14.5227L19.9686 14.5225L19.9533 13.0225ZM15.3037 10.3828C16.0695 10.3828 16.8139 10.1655 17.5282 9.76261L16.7912 8.45614C16.2681 8.75119 15.7752 8.88283 15.3037 8.88283V10.3828ZM19.9383 13.0227H17.2261V14.5227H19.9383V13.0227Z" fill="#636566" />
																		<path d="M17.3526 4.84426C17.3526 5.90596 16.4919 6.76664 15.4302 6.76664C14.3685 6.76664 13.5078 5.90596 13.5078 4.84426C13.5078 3.78255 14.3685 2.92188 15.4302 2.92188C16.4919 2.92188 17.3526 3.78255 17.3526 4.84426Z" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M10.0654 9.97656C12.3636 9.97656 14.2616 11.6894 14.6538 13.9343C14.6654 14.0004 14.6729 14.1117 14.6762 14.2684C14.6913 14.9734 14.132 15.5572 13.427 15.5723C13.4179 15.5725 13.4088 15.5726 13.3997 15.5726H2.27684C1.57166 15.5726 1 15.0009 1 14.2958L1.00033 14.2668C1.00369 14.1187 1.01079 14.0132 1.0216 13.9501C1.39708 11.7592 3.20624 10.0711 5.42461 9.9804L5.61278 9.97656C6.35404 10.3953 7.09585 10.6047 7.83824 10.6047C8.58062 10.6047 9.323 10.3953 10.0654 9.97656Z" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																		<path d="M11.1033 4.36417C11.1033 6.22215 9.59715 7.72834 7.73917 7.72834C5.88119 7.72834 4.375 6.22215 4.375 4.36417C4.375 2.50619 5.88119 1 7.73917 1C9.59715 1 11.1033 2.50619 11.1033 4.36417Z" stroke="#636566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
																	</svg>
																</figure>
																<div className='ml-3'>
																	<h3 className='text-15 font-semibold'>Team members</h3>
																	<p className='text-sm text-grey-800'>Grant access to the same set of team members</p>
																</div>
															</div>
															<div className='mt-1'>
																<input
																	type="checkbox"
																	className="custom-checkbox custom-checkbox--sm"
																	name=""
																/>
															</div>
														</div>
													</div>
												</>
											}
										</div>
									</li>
								</ul>
							</div>
							<div
								className="flex items-center justify-end gap-3 mt-6"
							>
								<button className="btn btn--grey btn--md min-w-140">
									<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
										<path d="M4.99805 1L1.00271 5L4.99805 9" stroke="#4C5254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M1 5H11" stroke="#4C5254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>Go back</button>
								<button className="btn btn--primary btn--md min-w-140">Continue</button>
							</div>
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default SelectAccountType