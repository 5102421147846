import React, { useState } from "react";
import avatarPic from "../../assets/images/avatar-pic.png";
import arrowRight from "../../assets/icons/icon-arrow-right.svg";
import arrowRightGreen from "../../assets/icons/icon-arrow-right-green.svg";
import closeGreen from "../../assets/icons/icon-close-green.svg";

const ChatUserName = ({ name, onClick = () => {}, onClose = () => {} }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div
      onClick={() => {
        setIsClicked((curr) => !curr);
        // setIsClicked(true);
      }}
      onMouseEnter={() => {
        if (!isClicked) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!isClicked) {
          setIsHovered(false);
        }
      }}
      className={`inline-flex items-center border border-grey-400 chat-message-header-user-heading cursor-pointer ${
        isHovered ? "hovered" : isClicked ? "clicked" : ""
      } pl-1 pr-5 py-1`}
    >
      {isClicked ? (
        <div className="inline-flex items-center" onClick={onClose}>
          <p className="text-13 font-medium" style={{ color: "#005C6F" }}>
            Close Contact
          </p>
          <img src={closeGreen} alt="arrow-right" className="ml-2" />
        </div>
      ) : isHovered ? (
        <div className="inline-flex items-center" onClick={onClick}>
          <p className="text-13 font-medium" style={{ color: "#005C6F" }}>
            View Contact
          </p>
          <img src={arrowRightGreen} alt="arrow-right" className="ml-2" />
        </div>
      ) : (
        <div className="inline-flex items-center">
          <figure
            className="avatar-container avatar-container--full"
            style={{ width: 28, height: 28 }}
          >
            <img src={avatarPic} alt="avatar-pic" />
          </figure>
          <p className="text-13 font-medium ml-2" style={{ color: "#292929" }}>
            {name}
          </p>
          <img src={arrowRight} alt="arrow-right" className="ml-2" />
        </div>
      )}
    </div>
  );
};

export default ChatUserName;
