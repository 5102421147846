import React, { useState } from 'react'
import AccountsSideBarExpanded from './Common/AccountsSideBarExpanded'
import AccountsSideLinks from './Common/AccountsSideLinks'
import AddNewAccountLinks from './Common/AddNewAccountLinks'

const CompletePayment = () => {
	const [isCompleted, setIsCompleted] = useState(false)
	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<AccountsSideLinks />
				<AccountsSideBarExpanded />
				<section className="dashboard__main">
					<div className="dashboard__main__header">
						<h3 className="font-semibold text-2xl">Add a new account</h3>
					</div>
					<div className="dashboard__main__body" style={{ height: 'calc(100% - 83px)' }}>
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<AddNewAccountLinks activeScreen={5} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay p-10">
							<div className="text-center">
								<h2 className="text-2xl font-semibold">Complete payment</h2>
								<p className="text-15 text-grey-800 mt-3">
									Confirm this account and complete payment
								</p>
							</div>
							{!isCompleted ?
								<>
									<div className='card-box pt-5 pb-6 px-6 mt-6'>
										<h3 className='font-semibold text-lg'>Account details</h3>
										<div className='border border-grey-400 rounded-xl px-5 pt-5 pb-4 flex justify-between items-center mt-5'>
											<div className='flex'>
												<figure className="avatar-container avatar-container--full bg-grey-400 mr-3"
													style={{
														width: 36,
														height: 36,
													}}>
													<span className='text-13 font-semibold'>S</span>
												</figure>
												<div>
													<h3 className='text-15 font-semibold'>Smiles Dental Group Guildford branch</h3>
													<span className='px-3 py-1 bg-red-200 border-radius-20 text-xs font-medium mt-2'>Starter plan</span>
												</div>
											</div>
											<div className='flex items-center'>
												<span className='mr-1 text-2xl font-semibold text-primary'>£49</span>
												<span className='text-xs'>/month</span>
											</div>
										</div>
										<div className='border border-grey-400 rounded-xl pt-4 px-5 pb-5 mt-6'>
											<h3 className='text-15 font-semibold'>Credit card details</h3>
											<div className='mt-3 mb-5'>
												<div className='flex'>
													<span className='text-sm text-grey-800 w-1/2'>Card number</span>
													<div className='flex items-center'>
														<code className='flex gap-1'>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
															<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
																<circle cx="2.5" cy="2.5" r="2.5" fill="#4C5254" />
															</svg>
														</code>
														<span className='text-sm font-semibold ml-1'>3429</span>
													</div>
												</div>
												<div className='flex mt-3'>
													<span className='text-sm text-grey-800 w-1/2'>Card holder name</span>
													<span className='text-sm font-semibold ml-1'>John King</span>
												</div>
												<div className='flex mt-3'>
													<span className='text-sm text-grey-800 w-1/2'>Expiry </span>
													<span className='text-sm font-semibold ml-1'>25/28</span>
												</div>
											</div>
											<span className='text-13'>This card will be charged.</span>
										</div>
										<div className='mt-4 bg-grey-200 rounded-xl py-3 px-4 text-13 text-grey-800'>
											Once you complete the payment you will be redirected to the account where you can invite in team members.
										</div>
									</div>
									<div className="flex items-center justify-end gap-3 mt-6">
										<button className="btn btn--grey btn--md min-w-140">
											<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
												<path d="M4.99805 1L1.00271 5L4.99805 9" stroke="#4C5254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
												<path d="M1 5H11" stroke="#4C5254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
											</svg>Go back</button>
										<button className="btn btn--primary btn--md min-w-140" onClick={() => setIsCompleted(true)}>Complete payment</button>
									</div>
								</> :
								<div className='border border-grey-400 rounded-xl mt-6 text-center pt-12 pb-12 px-5'>
									<figure className='flex justify-center'>
										<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="21" cy="21" r="20" stroke="#14AE5C" stroke-width="2" />
											<path d="M28.9792 15.9531L19.1617 25.7706L14.6992 21.3081" stroke="#14AE5C" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</figure>															
									<h2 className='mt-6 font-semibold text-lg'>Thank you for your payment.</h2>
									<p className='mt-3 text-13'>Your subscription is now active. Click the button below to invite Team Members to the account.</p>
									<div className="flex items-center justify-center gap-3 mt-6">
										<button className="btn btn--grey btn--md min-w-220">Maybe Later</button>
										<button className="btn btn--primary btn--md min-w-220">Invite Team Members</button>
									</div>
								</div>
							}
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default CompletePayment