import React, { useState } from "react";
import search from "../../assets/icons/icon-search.svg";

const InputField = ({
  value = "",
  onChange = (e) => {},
  prependIcon = search,
  placeholder = "placeholder",
  isMargin,
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  return (
    <div
      className={`input-field input-field--md input-field--prepend no-hover ${
        isInputFocused ? "input-field--prepend--focus" : ""
      }`}
    >
      <img src={prependIcon} alt="" className={"mr-2"} />
      <input
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
