import React from 'react';
import downloadIcon from '../../assets/icons/icon-btn-download.svg';
import closeModalIcon from '../../assets/icons/icon-close-full-image.svg';
import nextIcon from '../../assets/icons/icon-next-image.svg';
import prevIcon from '../../assets/icons/icon-prev-image.svg';

const ImageModal = ({ image, showModal, setShowImageModal, showImageModal }) => {
	

	return (
		<div className={`modal modal--image-full flex ${showModal ? 'show' : ''}`}>
			<div className='modal__prev-icon' onClick={() => setShowImageModal(showImageModal - 1)}>
				<img src={prevIcon} alt='' />
			</div>
			<div className="modal__container">
				<div className="modal__header">
					<h2 className="modal__heading pr-5">Business cover image.png</h2>
					<div className='flex'>
						<div className='flex items-center text-grey-800'>32.MB</div>
						<button className='btn btn--primary btn--xs ml-14'>
							<img src={downloadIcon} alt='' className='mr-6'/>
							Download
						</button>
					</div>
				</div>
				<div className="modal__body">
					<div className='flex justify-center my-3'>
						<img src={image} alt='' />
					</div>
				</div>
			</div>
			<div className='modal__close-full-image z-50 cursor-pointer' onClick={() => setShowImageModal(0)}>
				<img src={closeModalIcon} alt='' />
			</div>
			<div className='modal__next-icon' onClick={() => setShowImageModal(showImageModal + 1)}>
				<img src={nextIcon} alt='' />
			</div>
		</div>
	)
}

export default ImageModal