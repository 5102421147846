import React from "react";

const Signup = () => {
  return (
    <section className="authentication-module__container">
      <h1 className="text-2xl font-semibold text-center authentication-module__heading mb-8 xxl:mb-12">Sign Up</h1>
      <div className="w-full">
        <button className="w-full btn btn--google flex items-center justify-center gap-3">
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path
              d="M18.0019 9.20161C18.0019 8.46161 17.9406 7.9216 17.808 7.3616H9.18463V10.7016H14.2463C14.1443 11.5316 13.5933 12.7816 12.3686 13.6216L12.3514 13.7334L15.078 15.8032L15.2669 15.8217C17.0017 14.2517 18.0019 11.9416 18.0019 9.20161Z"
              fill="#4285F4"
            />
            <path
              d="M9.18457 18.001C11.6644 18.001 13.7462 17.2009 15.2668 15.8209L12.3686 13.6209C11.593 14.1509 10.552 14.5209 9.18457 14.5209C6.75576 14.5209 4.69434 12.9509 3.9595 10.7809L3.85179 10.7898L1.01669 12.9399L0.979614 13.0409C2.48996 15.9809 5.59233 18.001 9.18457 18.001Z"
              fill="#34A853"
            />
            <path
              d="M3.95956 10.7819C3.76566 10.2219 3.65345 9.62183 3.65345 9.00186C3.65345 8.38181 3.76566 7.78183 3.94936 7.22183L3.94422 7.10256L1.07359 4.918L0.97967 4.96178C0.357184 6.1818 0 7.55184 0 9.00186C0 10.4519 0.357184 11.8218 0.97967 13.0419L3.95956 10.7819Z"
              fill="#FBBC05"
            />
            <path
              d="M9.18458 3.48001C10.9092 3.48001 12.0726 4.21002 12.7359 4.82006L15.328 2.34002C13.7361 0.890013 11.6644 0 9.18458 0C5.59233 0 2.48996 2.02 0.979614 4.96001L3.9493 7.22007C4.69434 5.05005 6.75577 3.48001 9.18458 3.48001Z"
              fill="#EB4335"
            />
          </svg>

          <span>Sign up with Google</span>
        </button>
        <button className="w-full btn btn--google flex items-center justify-center gap-3 mt-4">
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
            <path d="M8.07792 8.57792H0V0.5H8.07792V8.57792Z" fill="#F1511B" />
            <path
              d="M16.9968 8.57792H8.91907V0.5H16.9968V8.57792Z"
              fill="#80CC28"
            />
            <path
              d="M8.07769 17.4997H0V9.42188H8.07769V17.4997Z"
              fill="#00ADEF"
            />
            <path
              d="M16.9968 17.4997H8.91907V9.42188H16.9968V17.4997Z"
              fill="#FBBC09"
            />
          </svg>

          <span>Sign up with Microsoft</span>
        </button>
      </div>
      <div className="or-text mt-3 mb-8">
        <span className="text-sm bg-white">OR</span>
      </div>
      <div className="">
        <div className="relative">
          <input
            className="input-field input-field--icon-pos-left input-field--icon-user"
            placeholder="Name"
          />
        </div>
        <div className="relative mt-3">
          <input
            className="input-field input-field--icon-pos-left input-field--icon-email"
            placeholder="Email"
          />
        </div>
      </div>
      <div className="flex items-center mt-5">
        <input
          type="checkbox"
          className="custom-checkbox custom-checkbox--sm"
          name=""
        />
        <span className="text-sm text-grey-800 ml-4">
          I agree to the <span className="text-primary font-medium cursor-pointer">Terms of service </span> and <span className="text-primary font-medium cursor-pointer">Privacy policy</span>
        </span>
      </div>
      <button className="btn btn--primary w-full mt-6 xxl:mt-8">Continue</button>
    </section>
  );
};

export default Signup;
