import React, { useEffect, useState } from "react";
import avatarPic from "../assets/images/avatar-pic.png";
import ChannelHeader from "./Common/ChannelHeader";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import ChatMessageHeader from "./Common/ChatMessageHeader";
import iconCheck from "../assets/icons/Group5569.svg";
import iconCross from "../assets/icons/Group12629.svg";

import ImageModal from "./modal/ImageModal";
import UploadFile from "./modal/UploadFile";
import fullImage1 from "../assets/icons/icon-full-image1.svg";
import fullImage2 from "../assets/icons/icon-full-image2.svg";
import ChatPreviewList from "./ChatPreviewList";

const EnquiryBin = () => {
    const [openTabs, setOpenTabs] = useState(false);
    const [showImageModal, setShowImageModal] = useState(0);
    const [openUpload, setOpenUpload] = useState(false);
    const [type, setType] = useState(false);

    const onRestoreChannel = () => {
        setType(!type)
    }
    return (
        <main className="dashboard">
            <section className="dashboard__container flex items-start">
                <SidebarLinks />
                <SidebarExpanded />

                <section className="dashboard__main">
                    <ChannelHeader
                        text={"Enquiry Bin"}
                        className={true}
                        linkText="Binned enquiries are permanently deleted after 30 days"

                        HeaderIcon={() => (
                            <figure className="avatar-container avatar-container--contain">
                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96925 1.14013C7.30091 0.443651 8.00356 0 8.77497 0H11.5079C12.2793 0 12.982 0.443651 13.3136 1.14013L13.7417 2.03904H18.9415C19.6823 2.03904 20.2829 2.63963 20.2829 3.3805C20.2829 4.12137 19.6823 4.72196 18.9415 4.72196H1.34147C0.600596 4.72196 0 4.12137 0 3.3805C0 2.63963 0.600594 2.03904 1.34146 2.03904H6.5412L6.96925 1.14013ZM18.1903 6.22439H2.09271L3.42742 19.3045C3.58364 20.8355 4.87299 22 6.41192 22H13.8711C15.41 22 16.6993 20.8355 16.8556 19.3045L18.1903 6.22439Z" fill="#2B3639" />
                                </svg>
                            </figure>
                        )}
                        emptyBin={true}
                    />
                    <div className="dashboard__main__body">
                        <div className="dashboard__main__body__section-left overflow-overlay">
                            <ChatPreviewList />
                        </div>
                        <div className="dashboard__main__body__section-right chat-message-container relative">
                            <ChatMessageHeader
                                setOpenTabs={setOpenTabs}
                                openTabs={openTabs}
                                showrestroed={true}
                                onRestoreChannel={onRestoreChannel}
                            />

                            <div className="chat-message-body">
                                <div className="chat-message-list flex">
                                    <figure
                                        className="avatar-container avatar-container--full"
                                        style={{ width: 32, height: 32 }}
                                    >
                                        <img src={avatarPic} alt="avatar-pic" />
                                    </figure>
                                    <div className="" style={{ marginLeft: 6 }}>
                                        <div>
                                            <div
                                                className="inline-block border border-grey-400 text-base-1 text-grey-900"
                                                style={{
                                                    borderRadius: 10,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                }}
                                            >
                                                Hi dude...
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="inline-block border border-grey-400 text-base-1 text-grey-900"
                                                style={{
                                                    borderRadius: 10,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    marginTop: 6,
                                                }}
                                            >
                                                How is your job?
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="inline-block border border-grey-400 text-base-1 text-grey-900"
                                                style={{
                                                    borderRadius: 10,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    marginTop: 6,
                                                }}
                                            >
                                                Are you doing anything fun this weekend at home?
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="text-xs text-grey-700"
                                                style={{ marginTop: 6 }}
                                            >
                                                2d
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-message-list chat-message-list--sender flex">
                                    <figure
                                        className="avatar-container avatar-container--full"
                                        style={{ width: 32, height: 32 }}
                                    >
                                        <img src={avatarPic} alt="avatar-pic" />
                                    </figure>
                                    <div className="" style={{ marginRight: 6 }}>
                                        <div>
                                            <div
                                                className="inline-block  text-base-1 text-grey-900"
                                                style={{
                                                    borderRadius: 10,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    backgroundColor: "#F4F9F9",
                                                    border: "1px solid rgba(0, 0, 0, 0.05)",
                                                }}
                                            >
                                                Hi dude...
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="inline-block  text-base-1 text-grey-900"
                                                style={{
                                                    borderRadius: 10,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    marginTop: 6,
                                                    backgroundColor: "#F4F9F9",
                                                    border: "1px solid rgba(0, 0, 0, 0.05)",
                                                }}
                                            >
                                                How is your job?
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="inline-block text-base-1 text-grey-900"
                                                style={{
                                                    borderRadius: 10,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    marginTop: 6,
                                                    backgroundColor: "#F4F9F9",
                                                    border: "1px solid rgba(0, 0, 0, 0.05)",
                                                }}
                                            >
                                                Are you doing anything fun this weekend at home?
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="text-xs text-grey-700"
                                                style={{ marginTop: 6 }}
                                            >
                                                2d
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-message-list flex">
                                    <figure
                                        className="avatar-container avatar-container--full"
                                        style={{ width: 32, height: 32 }}
                                    >
                                        <img src={avatarPic} alt="avatar-pic" />
                                    </figure>
                                    <div className="" style={{ marginLeft: 6 }}>
                                        <div>
                                            <div
                                                className="inline-block border border-grey-400 text-base-1 text-grey-900"
                                                style={{
                                                    borderRadius: 10,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                }}
                                            >
                                                Tristique bibendum finibus nec, consectetu.
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="text-xs text-grey-700"
                                                style={{ marginTop: 6 }}
                                            >
                                                Now
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="snackbar items-center"
                            id={type ? "show" : "hide"}
                            style={{
                                backgroundColor: "black",
                                color: "white",
                            }}
                        >
                            <div className="symbol">
                                {type ? <img src={iconCheck} /> : <img src={iconCross} />}
                            </div>
                            <div className="message">{type ? "Restored to Hunley Weddings" : "Disconnected"}</div>
                        </div>
                    </div>
                </section>
                <ImageModal
                    image={showImageModal === 1 ? fullImage1 : fullImage2}
                    showModal={showImageModal === 0 ? false : true}
                    setShowImageModal={setShowImageModal}
                    showImageModal={showImageModal}
                />
                <UploadFile setOpenUpload={setOpenUpload} openUpload={openUpload} />
            </section>
        </main>
    );
};

export default EnquiryBin;
