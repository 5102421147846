import React from 'react';
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import iconCloudUpload from "../../assets/icons/icon-upload-cloud.svg";
import ProgressBar from "../Common/ProgressBar";

const ModalBoxSettingsUploading = ({openUploadingModal, setOpenUploadingModal, setMatchingFieldsModal}) => {
	return (
		<div className={`modal ${openUploadingModal ? 'show' : ''}`}>
			<div className="modal__container">
				<div className="modal__header">
					<h2 className="modal__heading pr-5">Upload contacts</h2>
					<div
						className="modal__close-icon"
						onClick={() => setOpenUploadingModal(false)}
					>
						<img src={modalCloseIcon} alt="" />
					</div>
				</div>
				<div className="modal__body px-8 pb-10">
					<div className="mt-8 px-8 py-12 border border-dashed border-grey-400 border-radius-14">
						<div className="text-center">
							<div
								className="inline-flex items-center justify-center bg-grey-90 rounded-full"
								style={{ width: 70, height: 70 }}
							>
								<img src={iconCloudUpload} alt="icon-cloud-upload" />
							</div>
							<h3 className="text-lg font-semibold text-center mt-5">
								Uploading...
							</h3>
						</div>
						<div className="mt-8">
							<div className="flex justify-between">
								<span className="text-sm font-semibold">
									Contact list.csv
								</span>
								<div className="flex items-center gap-3">
									<span className="text-sm font-medium text-grey-800">
										8.5 MB
									</span>
									<span className="text-sm font-sans text-primary">
										Cancel
									</span>
								</div>
							</div>
							<div className="mt-3">
								<ProgressBar progress="20%" />
							</div>
						</div>
					</div>
					<div className="mt-8 px-8 py-12 border border-dashed border-grey-400 border-radius-14">
						<div className="text-center">
							<div
								className="inline-flex items-center justify-center bg-grey-90 rounded-full"
								style={{ width: 70, height: 70 }}
							>
								<svg width="20" height="20" viewBox="0 0 12 10" fill="none">
									<path
										d="M1 5.37637L4.13194 9L11 1"
										stroke="#005C6F"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>

							<h3 className="text-center text-lg font-semibold mt-5">
								500 Contacts uploaded
							</h3>
						</div>
						<div className="mt-8">
							<div className="flex justify-between">
								<span className="text-sm font-semibold">
									Contact list.csv
								</span>
								<div className="flex items-center gap-3">
									<span className="text-sm font-medium text-grey-800">
										8.5 MB
									</span>
								</div>
							</div>
							<div className="mt-3">
								<ProgressBar progress="100%" />
							</div>
						</div>
					</div>
					<div className="flex items-center justify-end gap-3 mt-12">
						<button className="btn btn--grey btn--sm min-w-120">Cancel</button>
						<button className="btn btn--primary btn--sm min-w-120" onClick={() => {setOpenUploadingModal(false); setMatchingFieldsModal(true)}}>Next</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ModalBoxSettingsUploading