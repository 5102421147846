import React, { useState } from "react";
import logo from "../../assets/icons/eb-logo.svg";
import avatarPic from "../../assets/images/avatar-pic.png";
import iconMyProfile from "../../assets/icons/icon-my-profile.svg";
import iconTeamMembers from "../../assets/icons/icon-team-members.svg";
import iconBilling from "../../assets/icons/icon-subscription-billing.svg";
import iconEnquiryAssistant from "../../assets/icons/icon-enquiry-assistant.svg";
import iconSettings from "../../assets/icons/icon-settings.svg";
import iconHelp from "../../assets/icons/icon-get-help.svg";
import IconApp from "../../assets/icons/icon-get-app.svg";
import iconLogout from "../../assets/icons/icon-logout.svg";

const SidebarLinksNew = ({}) => {
  const [openDropDown, SetOpenDropDown] = useState(false);
  return (
    <div className="dashboard__sidebar__links">
      <div className="dashboard__sidebar__logo">
        <img
          src={logo}
          alt="eb-logo"
          className="mx-auto"
          style={{ height: 31 }}
        />
      </div>

      <ul className="flex items-center justify-center flex-col mt-8">
        <li className="dashboard__sidebar__links__list active">
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.25 0.75C2.79822 0.75 0 3.54822 0 7V10.4223V13V15.625V16.25C0 19.4183 2.35752 22.036 5.41405 22.4446C5.92363 22.5626 6.45453 22.625 7 22.625H14.7461C15.2897 22.625 15.8188 22.563 16.3268 22.4458C19.3878 22.0413 21.75 19.4215 21.75 16.25V7C21.75 3.54822 18.9518 0.75 15.5 0.75H6.25ZM19.25 11.8975V7C19.25 4.92893 17.5711 3.25 15.5 3.25H6.25C4.17893 3.25 2.5 4.92893 2.5 7V11.9377C2.5683 12.5356 3.07601 13 3.69219 13H5.74297C6.72077 13 7.55522 13.707 7.71581 14.6715L7.91558 15.8714C8.03603 16.5948 8.66186 17.125 9.39521 17.125H12.3543C13.0876 17.125 13.7134 16.5949 13.8339 15.8716L14.034 14.6712C14.1947 13.7068 15.0291 13 16.0068 13H18.0539C18.6838 13 19.2004 12.5147 19.25 11.8975Z"
              fill="#2B3639"
            />
          </svg>
        </li>
        {/* <li className="dashboard__sidebar__links__list">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 0H16C19.3137 0 22 2.68629 22 6V16C22 19.3137 19.3137 22 16 22H6C2.68629 22 0 19.3137 0 16V6C0 2.68629 2.68629 0 6 0ZM12.25 6.1875C12.25 5.49714 11.6904 4.9375 11 4.9375C10.3096 4.9375 9.75 5.49714 9.75 6.1875V15.8125C9.75 16.5029 10.3096 17.0625 11 17.0625C11.6904 17.0625 12.25 16.5029 12.25 15.8125V6.1875ZM5.95837 11.8125C6.64873 11.8125 7.20837 12.3721 7.20837 13.0625V15.8125C7.20837 16.5029 6.64873 17.0625 5.95837 17.0625C5.26802 17.0625 4.70837 16.5029 4.70837 15.8125V13.0625C4.70837 12.3721 5.26802 11.8125 5.95837 11.8125ZM17.2916 10.3125C17.2916 9.62214 16.732 9.0625 16.0416 9.0625C15.3513 9.0625 14.7916 9.62214 14.7916 10.3125V15.8125C14.7916 16.5029 15.3513 17.0625 16.0416 17.0625C16.732 17.0625 17.2916 16.5029 17.2916 15.8125V10.3125Z"
              fill="#2B3639"
            />
          </svg>
        </li>
        <li className="dashboard__sidebar__links__list">
          <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
            <path
              d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
              fill="#2B3639"
            />
          </svg>
        </li>
        <li className="dashboard__sidebar__links__list dashboard__sidebar__links__list--icon-leaf">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
            <path
              d="M7.45459 12C7.45459 14.5104 9.48966 16.5455 12 16.5455C14.5104 16.5455 16.5455 14.5104 16.5455 12"
              stroke="#2B3639"
              stroke-width="2.5"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C10.13 2 6.79667 2 2 2C2 6.85477 2 10.1881 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#2B3639"
              stroke-width="2.5"
            />
            <path
              d="M7.92334 2C12.914 2.82392 15.788 5.64803 16.5454 10.4723C16.5904 10.9471 16.613 11.2373 16.613 11.3428"
              stroke="#2B3639"
              stroke-width="2.5"
            />
          </svg>
        </li> */}
      </ul>

      <ul className="flex items-center justify-center flex-col mt-auto">
        <li className="dashboard__sidebar__links__list">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="11" cy="11" r="11" fill="#2B3639" />
            <path
              d="M11 12.5587V12.2837C11 11.385 11.5555 10.8977 12.1121 10.5237C12.6555 10.1574 13.2 9.67998 13.2 8.79998C13.2 7.58448 12.2155 6.59998 11 6.59998C9.7845 6.59998 8.8 7.58448 8.8 8.79998"
              stroke="#FCFBF8"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.9956 14.4837C10.3884 14.4837 9.89563 14.9765 9.90003 15.5837C9.90003 16.1909 10.3928 16.6837 11 16.6837C11.6072 16.6837 12.1 16.1909 12.1 15.5837C12.1 14.9765 11.6072 14.4837 10.9956 14.4837Z"
              fill="#FCFBF8"
            />
          </svg>
        </li>
        <li className="dashboard__sidebar__links__list">
          <svg width="19" height="20" viewBox="0 0 21 22" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.96925 1.14013C7.30091 0.443651 8.00356 0 8.77497 0H11.5079C12.2793 0 12.982 0.443651 13.3136 1.14013L13.7417 2.03904H18.9415C19.6823 2.03904 20.2829 2.63963 20.2829 3.3805C20.2829 4.12137 19.6823 4.72196 18.9415 4.72196H1.34147C0.600596 4.72196 0 4.12137 0 3.3805C0 2.63963 0.600594 2.03904 1.34146 2.03904H6.5412L6.96925 1.14013ZM18.1903 6.22439H2.09271L3.42742 19.3045C3.58364 20.8355 4.87299 22 6.41192 22H13.8711C15.41 22 16.6993 20.8355 16.8556 19.3045L18.1903 6.22439Z"
              fill="#2B3639"
            />
          </svg>
        </li>
        <li
          className="relative dashboard__sidebar__links__list--profile-avatar cursor-pointer"
          onClick={() => SetOpenDropDown(!openDropDown)}
        >
          <figure
            className="avatar-container avatar-container--full"
            style={{ width: 36, height: 36 }}
          >
            <img src={avatarPic} alt="" className="avatar-pic" />
          </figure>
          <div
            className={`dropdown-menu no-translate p-6 ${
              openDropDown ? "show" : ""
            }`}
            style={{
              width: 390,
              "max-height": "90vh",
              overflow: "auto",
              left: 30,
              bottom: 35,
            }}
          >
            <div className="flex">
              <figure
                className="avatar-container avatar-container--cover"
                style={{
                  width: 42,
                  height: 42,
                  "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={avatarPic} className="" alt="avatar-pic" />
              </figure>
              <div className="flex-1 ml-4 pb-5 border-b border-solid border-grey-400">
                <h4 className="font-semibold">John Leon</h4>
                <p className="text-sm text-grey-700">johnleon_12@gmail.com</p>
              </div>
            </div>
            <ul className="mt-5">
              <li className="flex py-2 bg-color-on-hover">
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#FAF9F8",
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={iconMyProfile} alt="" className="avatar-pic" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">My Profile</h4>
                  <p className="text-sm text-grey-800">johnleon_12@gmail.com</p>
                </div>
              </li>
              <li className="flex py-2 bg-color-on-hover mt-1">
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#FAF9F8",
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
                  }}
                >
                  <img
                    src={iconEnquiryAssistant}
                    alt=""
                    className="avatar-pic"
                  />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">
                    Enquiry assistant
                  </h4>
                  <p className="text-sm text-grey-800">
                    Manage how & when you get notified
                  </p>
                </div>
              </li>
              <li
                className="relative pb-5 mb-5 border-b border-grey-400"
                style={{ width: "80%", left: "17%" }}
              ></li>
              <li className="flex py-2 bg-color-on-hover">
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#FAF9F8",
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
                  }}
                >
                  <img src={iconSettings} alt="" className="avatar-pic" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">Settings</h4>
                  <p className="text-sm text-grey-800">
                    View all account settings
                  </p>
                </div>
              </li>
              <li className="flex py-2 bg-color-on-hover mt-1">
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#FAF9F8",
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
                  }}
                >
                  <img
                    src={iconBilling}
                    alt=""
                    style={{ width: 19, height: 21 }}
                  />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">
                    Subscription & billing
                  </h4>
                  <p className="text-sm text-grey-800">
                    Invoices, payment method, upgrade
                  </p>
                </div>
              </li>
              <li className="flex py-2 bg-color-on-hover mt-1">
                <figure
                  className="avatar-container avatar-container--contain bg-grey-300"
                  style={{
                    width: 42,
                    height: 42,
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={iconTeamMembers} alt="" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">Team members</h4>
                  <p className="text-sm text-grey-800">
                    Invite new, manage access
                  </p>
                </div>
              </li>
              <li
                className="relative pb-5 mb-5 border-b border-grey-400"
                style={{ width: "80%", left: "17%" }}
              ></li>
              <li className="flex py-2 bg-color-on-hover">
                <figure
                  className="avatar-container avatar-container--contain bg-grey-300"
                  style={{
                    width: 42,
                    height: 42,
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={iconHelp} alt="" className="avatar-pic" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">Get some help</h4>
                  <p className="text-sm text-grey-800">
                    Learn about features, get support
                  </p>
                </div>
              </li>
              <li className="flex py-2 bg-color-on-hover mt-1">
                <figure
                  className="avatar-container avatar-container--contain bg-grey-300"
                  style={{
                    width: 42,
                    height: 42,
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={IconApp} alt="" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">Get the app</h4>
                  <p className="text-sm text-grey-800">Download the app</p>
                </div>
              </li>
              <li
                className="relative pb-5 mb-5 border-b border-grey-400"
                style={{ width: "80%", left: "17%" }}
              ></li>
            </ul>
            <div className="flex items-center cursor-pointer">
              <figure
                className="flex items-center justify-center"
                style={{ width: 40, height: 40 }}
              >
                <img src={iconLogout} className="" alt="icon-logout" />
              </figure>
              <h4 className="text-base-1 font-semibold ml-4">Log Out</h4>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SidebarLinksNew;
