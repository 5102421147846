import React, { useState } from "react";
// import iconHelpVideo from '../../assets/icons/icon-help-video.svg';
import iconVideoHelp from '../../assets/icons/videoIcon.svg';
import iconarrowWhite from '../../assets/icons/arrowWhite.svg';
import VideoIcon from "../../assets/icons/bigVideoIcon.svg";
import closeGreen from "../../assets/icons/icon-close-green.svg";


const HelperVideo = () => {
	const [modalShow, hideModalShow] = useState(false);

	return (
		<div className="flex justify-between bg-blue-700 rounded-lg pl-4">
			<div className="mt-4">
				<p className="text-sm text-white font-semibold">Need help? Watch this 2 minute setup video</p>
				<div className='flex'>
					<p className="text-xs text-white  mt-1  font-medium cursor-pointer">Or, read our trouble shooting guide</p>
					<img src={iconarrowWhite} alt='' className='mt-1 ml-1' />
				</div>
			</div>
			<div onClick={() => hideModalShow(true)}>
				<img className="cursor-pointer" src={iconVideoHelp} alt='' />
			</div>

			{modalShow && (
				<div className="modal modal--contact show" style={{ top: '0px', height: '100%' }}>
					<div className="modal__container mt-4" style={{ background: 'transparent' }}>
						<div className="modal__header modal__header--plain" style={{ justifyContent: "flex-end", paddingRight: '0px', paddingBottom: '0.5rem', marginTop: '48px' }}>
							<div className="flex border border-grey-400 cursor-pointer border-radius-30" style={{ background: 'white' }}>
								<div className="flex items-center py-1 pr-4 pl-3" onClick={() => hideModalShow(false)}>
									<img src={closeGreen} alt="arrow-right" className="cursor-pointer" />
									<p className="text-13 font-medium ml-2 mr-2" style={{ color: "#005C6F" }}>
										Close Video
									</p>
								</div>
							</div>
						</div>
						<div className="" style={{ padding: '0px' }}>
							<img className="cursor-pointer" src={VideoIcon} alt='' />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default HelperVideo