import React, { useState } from "react";
import { Link } from "react-router-dom";
import iconSearch from "../assets/icons/icon-search.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import ChannelHeader from "./Common/ChannelHeader";
import { useHistory } from "react-router-dom";
import ChatPreviewListSample from "./ChatPreviewListSample";
import HelperVideo from "./Common/HelperVideo";


const DashboardWebsiteChatbot = () => {
  const [modalShow, hideModalShow] = useState(true);
  let history = useHistory();
  const nextScreen = () => {
    history.push("/dashboard-contact-form");
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded navType2={true} />
        <section className="dashboard__main">
          <ChannelHeader
            text={"Website Chatbot"}
            linkText="Settings & Notifications"
            HeaderIcon={() => (
              <figure className="avatar-container" style={{backgroundColor: '#FAF9F8', boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'}}>
                <svg width="24" height="22" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M18 8.13559V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V8.13559H18ZM15.5 3C16.8807 3 18 4.11929 18 5.5V6.91525H2V5.5C2 4.11929 3.11929 3 4.5 3H15.5ZM4.44444 4.27119C4.07625 4.27119 3.77778 4.57852 3.77778 4.95763C3.77778 5.33674 4.07625 5.64407 4.44444 5.64407C4.81263 5.64407 5.11111 5.33674 5.11111 4.95763C5.11111 4.57852 4.81263 4.27119 4.44444 4.27119ZM6.66667 4.27119C6.29848 4.27119 6 4.57852 6 4.95763C6 5.33674 6.29848 5.64407 6.66667 5.64407C7.03486 5.64407 7.33333 5.33674 7.33333 4.95763C7.33333 4.57852 7.03486 4.27119 6.66667 4.27119Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left">
              <ChatPreviewListSample />
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
								<div>
									<h3 className="text-grey-900 font-semibold">
										Connect a Website chatbot
									</h3>
								</div>
								<div className="mt-4">
									<HelperVideo />
								</div>								
                {/* <div className="video-container mt-4">
                  <img
                    src={contactFormPlaceholder}
                    alt=""
                    className="w-full h-full"
                  />
                </div> */}
                <div className="flex items-center justify-between gap-4 mt-8">
                  <button className="btn btn--grey btn--sm w-6/12">
                    Change questions
                  </button>
                  <button className="btn btn--primary btn--sm w-6/12">
                    See it on your website
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* <ConfirmDetails /> */}
      {modalShow && (
        <div className="modal show">
          <div className="modal__container">
            <div className="modal__header">
              <h2 className="modal__heading pr-5">Confirm your details</h2>
              <div
                className="modal__close-icon"
                onClick={() => hideModalShow(false)}
              >
                <img src={modalCloseIcon} alt="" />
              </div>
            </div>
            <div className="modal__body px-8 py-6">
              <p className="text-sm text-grey-900">
                Are the below details correct for your business? We'll use them
                to show you a preview on your site.
              </p>
              <div className="mt-25">
                <div className="relative">
                  <label className="input-field-label">Business name</label>
                  <input
                    className="input-field input-field--md"
                    placeholder="dribbble.com"
                  />
                </div>
                <div className="relative mt-25">
                  <label className="input-field-label">Website URL</label>
                  <input
                    className="input-field  input-field--md"
                    placeholder="https://dribbble.com"
                  />
                </div>
              </div>
              <div className="flex justify-end mt-16">
                <button className="btn btn--primary btn--md w-5/12" onClick={nextScreen}>
                  See your Enquirybot
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default DashboardWebsiteChatbot;
