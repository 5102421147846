import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/icons/eb-logo.svg";
import iconCheck from "../assets/icons/icon-check.svg";
import arrowRight from "../assets/icons/icon-arrow-right.svg";
import avatarPic1 from "../assets/icons/icon-avatar.svg";
import iconNoteActive from "../assets/icons/icon-note-active.svg";
import iconExpand from "../assets/icons/icon-expand.svg";
import iconForward from "../assets/icons/icon-forward.svg";
import iconNoteInActive from "../assets/icons/icon-note.svg";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconReminderActive from "../assets/icons/icon-reminder-active.svg";
import iconReminderInActive from "../assets/icons/icon-reminder.svg";
import iconReplyInActive from "../assets/icons/icon-reply-inactive.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconSettings from "../assets/icons/icon-settings.svg";
import avatarPic from "../assets/images/avatar-pic.png";
import iconClock from "../assets/icons/icon-clock.svg";
import iconAdd from "../assets/icons/icon-add.svg";
import InputField from "./Common/InputField";
import iconReplyActive from "../assets/icons/icon-reply.svg";
import AttachFiles from "./modal/AttachFiles";
import FileLibrary from "./modal/FileLibrary";
import UploadFile from "./modal/UploadFile";
import iconAddItem from "../assets/icons/icon-add-item.svg";
import iconSmiley from "../assets/icons/icon-smiley.svg";
import iconUploadPic from "../assets/icons/icon-upload-pic.svg";
import iconAttachment from "../assets/icons/icon-attachment.svg";
import iconCardProfile from '../assets/icons/icon-card-image.svg';
import iconHelperClose from '../assets/icons/icon-helper-close.svg';
import iconEmptyState from '../assets/icons/icon-reply-template-empty-state.svg';

const replyBy = [
	{
		title: "Email",
	},
	{
		title: "Facebook",
	},
];

const users = [
	{
		name: "None",
	},
	{
		name: "Madison Inouye",
	},
	{
		name: "Olivia Rhye",
	},
	{
		name: "Charles Wundeng",
	},
	{
		name: "Lisa Fotios",
	},
	{
		name: "Peter Parker",
	},
];

const reminderOn = [
	{
		title: "Tomorrow morning ",
		id: "1",
	},
	{
		title: "Monday morning ",
		id: "2",
	},
	{
		title: "Custom date",
		id: "3",
	},
];

const reminderTimes = [
	{
		title: "12:00am",
	},
	{
		title: "1:00am",
	},
	{
		title: "2:00am",
	},
	{
		title: "3:00am",
	},
	{
		title: "4:00am",
	},
	{
		title: "5:00am",
	},
	{
		title: "6:00am",
	},
];
const replyTemplateData = [
	{
		id:1,
		title: 'Change credit card info'	
	},
	{
		id: 2,
		title: 'Changing name of default stages in'
	},
	{
		id: 3,
		title: 'Hard refresh'
	},
	{
		id: 4,
		title: 'Inbox reply'
	},
	{
		id: 5,
		title: 'Reset password'
	},
	{
		id: 6,
		title: 'Taking a screenshot'
	},
	{
		id: 7,
		title: 'Taking a screenshot'
	},
	{
		id: 8,
		title: 'Taking a screenshot'
	},
	{
		id: 9,
		title: 'Taking a screenshot'
	}
];
const tabs = {
	reply: { id: "reply", placeholder: "Message..." },
	note: { id: "note", placeholder: "Message..." },
	reminder: {
		id: "reminder",
		placeholder: "Type what you want to be reminded about...",
	},
};

const allTabs = [
	{
		id: "reply",
		text: "Reply",
		iconActive: iconReplyActive,
		iconInactive: iconReplyInActive,
	},
	{
		id: "note",
		text: "Note",
		iconActive: iconNoteActive,
		iconInactive: iconNoteInActive,
	},
	{
		id: "reminder",
		text: "Reminder",
		iconActive: iconReminderActive,
		iconInactive: iconReminderInActive,
	},
];

const ChatFooterInputBox = ({ activetab = tabs.reply.id, showMoveHelper = false, setShowMoveHelper }) => {
	const [activeTab, setActiveTab] = useState(tabs.reply.id);
	const [showReplyOptions, setShowReplyOptions] = useState(false);
	const [notes, setNotes] = useState("");
	const [inputValue, setInputValue] = useState("");
	const [showUsers, setShowUsers] = useState(false);
	const [showReminderOptions, setShowReminderOptions] = useState(false);
	const [showReminderTime, setShowReminderTime] = useState(false);
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedReminderDay, setSelectedReminderDay] = useState({});
	const [showAttachFiles, setShowAttachFiles] = useState(false);
	const [showFileLibrary, setShowFileLibrary] = useState(false);
	const [showFileUpload, setShowFileUpload] = useState(false);
	const [openDropDown, setOpenDropDown] = useState(false);
	const [openReplyTemplate,setOpenReplyTemplate] = useState(false);
	const [selectedReplyTemplate, setSelectedReplyTemplate] = useState(1);

	const HeaderTabItem = ({ active = false, onClick = () => { }, item = {} }) => {
		const { id, text, iconActive, iconInactive } = item;
		return (
			<div className="flex items-center">
				{text === 'Reply' ?
					<div className="relative">
						<div
							className="relative flex items-center pointer"
							onClick={onClick}>
							<img src={active ? iconActive : iconInactive} alt="icon-reply" />
							<p className={`${active ? "text-primary font-semibold" : "text-grey font-medium"} text-13 ml-2`}>
								{text}
							</p>
						</div>
						<div
							ref={node}
							className={`dropdown-menu dropdown-menu--sm ${showReplyOptions ? 'open' : ''}`}
							style={{
								width: "max-content",
								top: "calc(100% + 2px)",
								left: "initial",
								right: 0,
							}}
						>
							{replyBy.map((item, index) => {
								return (
									<div className="dropdown-menu__list" key={index}>
										<p className="text-sm">Reply by {item.title}</p>
									</div>
								);
							})}
						</div>
					</div> :
					<div className="flex items-center ml-8 pointer" onClick={onClick}>
						<img src={active ? iconActive : iconInactive} alt="icon-reply" />
						<p
							className={`${active ? "text-primary font-semibold" : "text-grey font-medium"
								} text-13 ml-2`}
						>
							{text}
						</p>
					</div>}
			</div>
		);
	};

	const node = useRef();

	const PopupItem = ({ isSelected = false, onClick = () => { }, text = "" }) => {
		return (
			<div
				className={`dropdown-menu__list ${isSelected ? "selected" : ""}`}
				onClick={onClick}
			>
				{text}
			</div>
		);
	};
	const ButtonItem = ({
		icon = iconReminderInActive,
		text = "placeholder",
		onClick = () => { },
		active = false,
	}) => {
		return (
			<div
				onClick={onClick}
				className={`flex items-center gap-2 button px-3 py-1 ${active ? "button__active" : ""
					}`}
			>
				<img src={icon} alt="icon-reminder" />
				<code
					className="inline-block"
					style={{
						width: 1,
						height: 22,
						backgroundColor: "#E5E5E5",
					}}
				></code>
				<p className="text-grey-900 text-13 font-medium">{text}</p>
			</div>
		);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	const handleClick = (e) => {
		if (node.current?.contains(e.target)) {
			return;
		}
		setShowReplyOptions(false);
		setShowUsers(false);
		setShowReminderTime(false);
		setShowReminderOptions(false);
		// setShowAttachFiles(false);
		// setShowFileLibrary(false);
		// setShowFileUpload(false);
	};

	return (
		<>
			{showMoveHelper ?
				<div className="chat-message-footer chat-message-footer">
					<div className="px-4 pt-4">
						<div className="flex justify-between">
							<div className="flex items-center">
								<figure className="avatar-container mr-4" style={{ width: 32, height: 32, border: '2px solid #0491AB' }}>
									<img src={iconCardProfile} alt='' />
								</figure>
								<div className="font-medium">What stage would you like to move the Enquiry to now?</div>
							</div>
							<div className="cursor-pointer" onClick={() => setShowMoveHelper(false)}>
								<img src={iconHelperClose} alt='' style={{ width: 9, height: 9 }} />
							</div>
						</div>
						<div className="flex mt-3" style={{ marginLeft: 42 }}>
							<button className="btn btn--stage">Contacting</button>
							<button className="btn btn--stage">Won</button>
							<button className="btn btn--stage">Lost</button>
							<button className="btn btn--stage-red">Don’t move</button>
						</div>
					</div>
					<div className="chat-message-footer__footer flex justify-end">
						Turn off this helper
					</div>
				</div> :
				<div
					className={`chat-message-footer chat-message-footer${activeTab === tabs.reply.id
							? ""
							: activeTab === tabs.note.id
								? "__note"
								: "__reminder"
						}`}
				>
					<div className="chat-message-footer__header chat-message-footer__header">
						<div className="flex items-center">
							{/* <div
              className="flex items-center pointer"
              onClick={() => {
                setShowReplyOptions((curr) => !curr);
              }}
            >
              <img src={iconReplyInActive} alt="icon-reply" />
              <p className="text-13 font-medium text-grey-700 ml-6">Reply</p>
            </div> */}
							{allTabs.map((item, index) => {
								let isActive = activeTab === item.id;
								return (
									<HeaderTabItem
										key={index}
										item={item}
										text={item.text}
										active={isActive}
										onClick={() => {
											if (item.id === tabs.reply.id && isActive) {
												setShowReplyOptions(true);
											} else {
												setActiveTab(item.id);
											}
										}}
									/>
								);
							})}
						</div>
						<img src={iconExpand} alt="icon-expand" />
					</div>
					<div className="chat-message-footer__body">
						<div
							ref={node}
							className={`dropdown-menu dropdown-menu--sm ${showUsers && "open"}`}
							style={{
								left: "auto",
								bottom: "145px",
								width: "300px",
							}}
						>
							<div className="dropdown-menu__overflow-y-scroll mt-2">
								{users.map((user, index) => {
									return (
										<div
											key={index}
											className="dropdown-menu__list"
											onClick={() => {
												let newNotes = notes.slice(0, -1);
												setNotes(newNotes + " " + user.name);
												setShowUsers(false);
											}}
										>
											<img
												src={avatarPic}
												className="mr-3"
												style={{ width: 30, height: 30 }}
											/>
											{user.name}
										</div>
									);
								})}
							</div>
						</div>
						<textarea
							onChange={(e) => {
								setNotes(e.target.value);
								if (e.target.value.substr(-1) === "@") {
									setShowUsers(true);
								} else {
									setShowUsers(false);
								}
							}}
							value={notes}
							name=""
							rows="2"
							cols="5"
							placeholder={tabs[activeTab].placeholder}
							className="text-base-1 text-grey-900 resize-none w-full px-4 py-2 border-none outline-none"
						></textarea>
					</div>

					{activeTab === tabs.reply.id ? (
						<div className="chat-message-footer__footer flex items-center justify-between px-4 py-3">
							<div className="flex items-center gap-4 relative">
							<figure className={`avatar-container cursor-pointer ${openDropDown || openReplyTemplate ? 'bg-grey-90' : ''}`} style={{ width: 22, height: 22 }} onClick={() => {openReplyTemplate ? setOpenReplyTemplate(false) : setOpenDropDown(!openDropDown)}}>
									{openDropDown || openReplyTemplate ? <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.99939 1V9" stroke="#005C6F" stroke-width="1.5" stroke-linecap="round" />
										<path d="M9 5L1 5" stroke="#005C6F" stroke-width="1.5" stroke-linecap="round" />
									</svg> :
									<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.99939 1V9" stroke="#2B3639" stroke-width="1.5" stroke-linecap="round" />
										<path d="M9 5L1 5" stroke="#2B3639" stroke-width="1.5" stroke-linecap="round" />
									</svg>}
								</figure>
								<div className={`dropdown-menu dropdown-menu--sm ${openDropDown && "open"}`} style={{ bottom: 25 }}>
									<div className="dropdown-menu__list" onClick={() => {setOpenReplyTemplate(true); setOpenDropDown(!openDropDown)}}>
										<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
											<path d="M1.5 4C1.5 2.34315 2.84315 1 4.5 1H9.5C11.1569 1 12.5 2.34315 12.5 4V12.0887C12.5 12.4833 12.0642 12.7224 11.7314 12.5104L7.26864 9.66748C7.10476 9.56308 6.89524 9.56308 6.73136 9.66748L2.26864 12.5104C1.93578 12.7224 1.5 12.4833 1.5 12.0887V4Z" fill="#2B3639" stroke="#2B3639" stroke-width="1.2" stroke-linejoin="round" />
										</svg>
										Reply template
									</div>
								</div>
								<div className={`dropdown-menu dropdown-menu--reply-template ${openReplyTemplate && "open"}`} style={{ bottom: 25, minWidth: 600 }}>
									<div className="pb-3 border-b border-grey-400 px-2 pt-2">
										<input type="text" className="input-field input-field--icon-search--sm text-13" placeholder="Search reply templates" style={{ padding: '6px 32px', fontWeight: 400 }} />
									</div>
									<div className="w-full flex">
										<div className="py-2 px-2 w-1/2 border-r border-grey-400 overflow-y-auto" style={{ maxHeight: 300 }}>
											{replyTemplateData.map((item, index) => {
												return (
													<div className={`flex px-3 py-2 items-center truncate cursor-pointer bg-color-on-hover rounded-lg mt-1 ${selectedReplyTemplate === item.id ? 'bg-grey-90' : ''}`} key={index} onClick={() => setSelectedReplyTemplate(item.id)}>
														<div className="mr-3">
															{selectedReplyTemplate === item.id ? <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M1.5 4C1.5 2.34315 2.84315 1 4.5 1H9.5C11.1569 1 12.5 2.34315 12.5 4V12.0887C12.5 12.4833 12.0642 12.7224 11.7314 12.5104L7.26864 9.66748C7.10476 9.56308 6.89524 9.56308 6.73136 9.66748L2.26864 12.5104C1.93578 12.7224 1.5 12.4833 1.5 12.0887V4Z" fill="#2B3639" stroke="#2B3639" stroke-width="1.2" stroke-linejoin="round" />
															</svg> :
																<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M1.5 4C1.5 2.34315 2.84315 1 4.5 1H9.5C11.1569 1 12.5 2.34315 12.5 4V12.0887C12.5 12.4833 12.0642 12.7224 11.7314 12.5104L7.26864 9.66748C7.10476 9.56308 6.89524 9.56308 6.73136 9.66748L2.26864 12.5104C1.93578 12.7224 1.5 12.4833 1.5 12.0887V4Z" stroke="#636566" stroke-width="1.2" stroke-linejoin="round" />
																</svg>
															}
														</div>
														<span className={`text-sm truncate ${selectedReplyTemplate === item.id ? 'text-grey-900 font-semibold' : 'text-grey-800 font-medium'}`}>{item.title}</span>
													</div>
												)
											})}
										</div>
										<div className="p-4 w-1/2 text-sm font-normal">
											Thanks for your email. Could you kindly email <span className="font-medium">accounts@enquirybot.com</span> They can assist you with the card update.
											Please send the name of your account name.
										</div>
									</div>
									<h2 className="font-semibold pt-4 px-4 pb-3 border-b border-grey-400 text-base text-grey-900">Reply templates</h2>
									<div className="w-full flex">
										<div className="p-5 w-1/2 border-r border-grey-400">
											<div className="pb-4 border-b border-grey-400 text-sm font-medium text-grey-900">Save time by saving replies that you frequently send.</div>
											<ul>
												<li className="py-4 border-b border-grey-400 text-sm font-medium flex items-baseline text-grey-900">
													<div className="mr-3">
														<svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
															<circle cx="3.5" cy="3.5" r="3.5" fill="#4C5254" />
														</svg>
													</div>
													You can create a reply template by saving an existing reply 👉
												</li>
												<li className="pt-4 text-sm font-medium flex items-baseline text-grey-900">
													<div className="mr-3">
														<svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
															<circle cx="3.5" cy="3.5" r="3.5" fill="#4C5254" />
														</svg>
													</div>
													<div>
														Or you can create a template in your settings section. <span className="text-primary">Go to settings</span>
													</div>
												</li>
											</ul>
										</div>
										<div className="px-5 w-1/2">
											<img src={iconEmptyState} alt='' style={{ width: 280, height: 195 }} />
										</div>
									</div>
								</div>
								<code
									className="inline-block"
									style={{
										width: 1,
										height: 22,
										backgroundColor: "#E5E5E5",
									}}
								></code>
								<img src={iconSmiley} alt="icon-smiley" />
								<img src={iconUploadPic} alt="icon-upload-pic" />
								<img
									src={iconAttachment}
									alt="icon-attachment"
									className="cursor-pointer"
									onClick={() => setShowAttachFiles(true)}
								/>
								<div ref={node}>
									<AttachFiles
										setShowFileLibrary={setShowFileLibrary}
										setShowFileUpload={setShowFileUpload}
										setShowAttachFiles={setShowAttachFiles}
										showAttachFiles={showAttachFiles}
									/>
								</div>
								<div ref={node}>
									<FileLibrary
										setShowFileLibrary={setShowFileLibrary}
										showFileLibrary={showFileLibrary}
									/>
								</div>
								<div ref={node}>
									<UploadFile
										setShowFileUpload={setShowFileUpload}
										showFileUpload={showFileUpload}
									/>
								</div>
							</div>
							<button
								className="btn btn--primary text-sm text-white"
								style={{ padding: "4px 10px", borderRadius: 6 }}
							>
								Send
							</button>
						</div>
					) : activeTab === tabs.note.id ? (
						<div className="chat-message-footer__footer flex items-center justify-between px-4 py-3">
							<div className="flex items-center gap-4">
								<div className="flex items-center ps px-2 py-1">
									<strong>PS:</strong>
									<p className="ml-1">The customer wont see this note</p>
								</div>
							</div>
							<button
								className="btn btn--primary text-sm text-white"
								style={{ padding: "4px 10px", borderRadius: 6 }}
							>
								Update
							</button>
						</div>
					) : (
						<div className="chat-message-footer__footer flex items-center justify-between px-4 py-3">
							<div className="flex items-center gap-4">
								<div>
									<div
										ref={node}
										className={`dropdown-menu dropdown-menu--sm ${showReminderOptions && "open"
											}`}
										style={{ width: "240px", bottom: "80px", left: "auto" }}
									>
										{reminderOn.map((item, index) => {
											let isSelected = item.id === selectedReminderDay.id;
											return (
												<PopupItem
													isSelected={isSelected}
													key={index}
													text={item.title}
													onClick={() => {
														if (item.id !== "3") {
															setSelectedReminderDay(item);
														} else {
															//open Date Picker here
															setShowCalendar(true);
														}
													}}
												/>
											);
										})}
									</div>

									<ButtonItem
										active={showReminderOptions}
										icon={iconReminderInActive}
										text="Monday morning"
										onClick={() => {
											setShowReminderOptions(true);
										}}
									/>
								</div>
								<div>
									<div
										ref={node}
										className={`dropdown-menu dropdown-menu--sm ${showReminderTime && "open"
											}`}
										style={{ width: "120px", left: "auto", bottom: "80px" }}
									>
										<div className="dropdown-menu__overflow-y-scroll">
											{reminderTimes.map((item, index) => {
												let isSelected = index === 1;
												return (
													<PopupItem
														key={index}
														isSelected={isSelected}
														text={item.title}
														onClick={() => { }}
													/>
												);
											})}
										</div>
									</div>

									<ButtonItem
										active={showReminderTime}
										icon={iconClock}
										text="09:00am"
										onClick={() => {
											setShowReminderTime(true);
										}}
									/>
								</div>
							</div>
							<button
								className="btn btn--primary text-sm text-white"
								style={{ padding: "4px 10px", borderRadius: 6 }}
							>
								<img src={iconAdd} alt="icon-add-item" className="mr-2" />
								Add
							</button>
						</div>
					)}
					{/* <div className="flex items-center gap-4">
          <div className="flex items-center ps px-2 py-1">
            <strong>PS:</strong>
            <p className="ml-1">The customer wont see this note</p>
          </div>
        </div>
        <button
          className="btn btn--primary text-sm text-white"
          style={{ padding: "4px 10px", borderRadius: 6 }}
        >
          Update
        </button> */}
				</div>}
		</>
	);
};

export default ChatFooterInputBox;
