import React from 'react';
import closeIcon from '../../assets/icons/icon-tooltip-close.svg';
import handIcon from '../../assets/icons/icon-hand-tooltip.png';

const EnquiryAssistant = () => {
	return (
		<div className='tooltip relative'>
				<img src={closeIcon} alt="" className='inline tooltip__close-icon'/>
			<div className='pt-5 pl-5 font-medium text-white'>
				No problem Merva, I’ll remind you to watch the<br /> video later.
				Check out the website Chatbot<br /> that we built for you.
			</div>
			<div className='pl-5'>
				<button className="btn btn--white btn--enquiry mt-5"><span className='font-bold'>Ella</span>, Enquiry Assistant</button>
				<img src={handIcon} alt="" className='tooltip__hand-icon inline'/>
			</div>
		</div>
	)
}

export default EnquiryAssistant;
