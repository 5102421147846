import React, { useState } from 'react'
import AccountsSideBarExpanded from './Common/AccountsSideBarExpanded'
import AccountsSideLinks from './Common/AccountsSideLinks'
import AddNewAccountLinks from './Common/AddNewAccountLinks'
import iconCheck from "../assets/icons/Group5569.svg";

const InviteAccountOwner = () => {
	const [email, setEmail] = useState('')
	const [isInviteLink, setIsInviteLink] = useState(false);
	const [isInviteEmail, setIsInviteEmail] = useState(false);

	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<AccountsSideLinks />
				<AccountsSideBarExpanded />
				<section className="dashboard__main">
					<div className="dashboard__main__header">
						<h3 className="font-semibold text-2xl">Add a new account</h3>
					</div>
					<div className="dashboard__main__body" style={{ height: 'calc(100% - 83px)' }}>
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<AddNewAccountLinks activeScreen={4} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay p-10">
							<div className="text-center">
								<h2 className="text-2xl font-semibold">Complete payment</h2>
								<p className="text-15 text-grey-800 mt-3">
									Confirm this account and complete payment
								</p>
							</div>
							<div className='card-box pt-5 pb-6 px-6 mt-6'>
								<h3 className='font-semibold text-lg'>Account details</h3>
								<div className='border border-grey-400 rounded-xl px-5 pt-5 pb-4 flex justify-between items-center mt-5'>
									<div className='flex'>
										<figure className="avatar-container avatar-container--full bg-grey-400 mr-3"
											style={{
												width: 36,
												height: 36,
											}}>
											<span className='text-13 font-semibold'>S</span>
										</figure>
										<div>
											<h3 className='text-15 font-semibold'>Smiles Dental Group Guildford branch</h3>
											<span className='px-3 py-1 bg-red-200 border-radius-20 text-xs font-medium mt-2'>Starter plan</span>
										</div>
									</div>
									<div className='flex items-center'>
										<span className='mr-1 text-2xl font-semibold text-primary'>£49</span>
										<span className='text-xs'>/month</span>
									</div>
								</div>
								<div className='mt-6'>
									<label className='text-15 font-semibold'>Email address of the account owner</label>
									<input className='input-field input-field--sm-38 mt-2' value={email} onChange={(e) => setEmail(e.target.value)}></input>
								</div>
								<div className='mt-6'>
									<label className='text-15 font-semibold'>Note</label>
									<textarea className='input-field input-field--lg-12-15 resize-none mt-2' cols="4" rows="3" placeholder='Give the invite some context, write a little note for the account owner'></textarea>
								</div>
								<div className='mt-4 bg-grey-200 rounded-xl px-4 py-3 text-13 text-grey-800'>
									The account owner will be asked to complete the payment to activate the account. Once the payment is completed, they will have full access to the account and can invite in team members themselves.
								</div>
							</div>
							<div className="flex items-center justify-end gap-3 mt-6">
								<button className="btn btn--grey btn--md min-w-140">
									<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
										<path d="M4.99805 1L1.00271 5L4.99805 9" stroke="#4C5254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M1 5H11" stroke="#4C5254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									Go back
								</button>
								<button className={`btn btn--primary btn--md ${email !== '' ? 'opacity-100' : 'opacity-50'}`} style={{padding: '10px 24px'}} onClick={() => {setIsInviteEmail(false); setIsInviteLink(!isInviteLink)}}>
									<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-2'>
										<path d="M8.29201 6.71094C9.54201 7.96094 9.54201 9.89844 8.29201 11.1484L6.542 12.8985C5.292 14.1485 3.3545 14.1485 2.10449 12.8985C0.854492 11.6484 0.854492 9.71094 2.10449 8.46094L3.667 6.96094" stroke="white" stroke-width="1.5" stroke-linecap="round" />
										<path d="M6.54102 8.45314C5.29101 7.20314 5.29101 5.26563 6.54102 4.01563L8.29102 2.26563C9.54102 1.01562 11.4785 1.01562 12.7285 2.26563C13.9785 3.51563 13.9785 5.45313 12.7285 6.70314L11.166 8.20314" stroke="white" stroke-width="1.5" stroke-linecap="round" />
									</svg>
									Copy invite link
								</button>
								<button className={`btn btn--primary btn--md ${email !== '' ? 'opacity-100' : 'opacity-50'}`} style={{padding: '10px 24px'}} onClick={() => {setIsInviteLink(false);setIsInviteEmail(!isInviteEmail)}}>
									<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-2'>
										<path d="M14.9236 0.791627L1.22282 6.01505C0.395071 6.33063 0.356082 7.48712 1.16069 7.85773L5.1875 9.7125L12.125 4.9875L7.2875 11.85L9.23231 15.8135C9.61808 16.5997 10.7565 16.5454 11.0657 15.726L16.2154 2.07908C16.5198 1.27249 15.7292 0.484511 14.9236 0.791627Z" fill="white" />
									</svg>
									Send invite email
								</button>
							</div>
							<div className='general-settings'>
								<div
									className="snackbar items-center"
									id={email !== '' && (isInviteLink || isInviteEmail) ? "show" : "hide"}
									style={{
										backgroundColor: "black",
										color: "white",
										padding:"8px"
									}}
								>
									<div className="symbol">
										<img src={iconCheck} alt='' />
									</div>
									<div className="message">{isInviteLink ? 'Invite link copied to clipboard' : isInviteEmail ? 'Invitation email sent' : ''}</div>
								</div>
							</div>					
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default InviteAccountOwner