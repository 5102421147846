import React from "react";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";
import ToggleSwitchOnOff from "./Common/ToggleSwitchOnOff";

const BoxSettingsInegrations = () => {
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"General Box Settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <BoxSettingsNavLinks activeScreen={7} />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay  p-10">
              <div className="text-center">
                <h2 className="text-2xl font-semibold">Integrations</h2>
                <p className="text-base-1 text-grey-800 mt-2">
                  Connect any third party systems to this box to assist you in
                  managing your enquiries.
                </p>
              </div>
              <div className="card-box mt-10 px-6 py-5">
                <div className="flex items-center justify-between">
                  <h3 className="font-medium">Dentally</h3>
                  <ToggleSwitchOnOff />
                </div>
              </div>
              <div className="card-box mt-10 px-5 py-10 text-center">
                <div className="flex flex-col items-center">
                  <h3 className="text-xl font-medium">
                    You currently don't have any integrations.
                  </h3>
                  <button
                    className="btn btn--primary btn--md mt-6"
                    style={{ paddingLeft: 35, paddingRight: 35 }}
                  >
                    Set one up here
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default BoxSettingsInegrations;
