import React from 'react'
import { Link } from 'react-router-dom'

const AddNewAccountLinks = ({ activeScreen }) => {
	return (
		<ul>
			<Link to='/add-new-account-account-details' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm block ${activeScreen === 1 ? 'active' : ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<span className='font-semibold text-xl'>1</span>
					</figure>
					<div className="truncate pr-4">
						<h2 className="general-settings__card-list__title">
							Add account details
						</h2>
						<p className="general-settings__card-list__desc">
							Create a new account or copy from existing
						</p>
					</div>
				</div>
			</Link>
			<Link to='/add-new-account-select-account-type' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 2 ? 'active' : ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<span className='font-semibold text-xl'>2</span>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Select account type
						</h3>
						<p className="general-settings__card-list__desc">
							New account or clone of existing
						</p>
					</div>
				</div>
			</Link>
			<Link to='/add-new-account-select-payment' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 3 ? 'active' : ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<span className='font-semibold text-xl'>3</span>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Choose your plan & billing setup
						</h3>
						<p className="general-settings__card-list__desc">
							Pay yourself or bill to new account
						</p>
					</div>
				</div>
			</Link>
			<Link to='/invite-account-owner' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 4 ? 'active' : ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<span className='font-semibold text-xl'>4</span>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Invite the account owner
						</h3>
						<p className="general-settings__card-list__desc">
							Confirm this account and complete payment
						</p>
					</div>
				</div>
			</Link>
			<Link to='/complete-payment' className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 block ${activeScreen === 5 ? 'active' : ''}`}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<span className='font-semibold text-xl'>5</span>
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Complete payment
						</h3>
						<p className="general-settings__card-list__desc">
							Confirm this account and complete payment
						</p>
					</div>
				</div>
			</Link>
		</ul>
	)
}

export default AddNewAccountLinks