import React, { useState } from "react";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import ChannelHeader from "./Common/ChannelHeader";
import salesIcon from "../assets/icons/icon-sales-box.svg";
import deleteIcon from "../assets/icons/icon-Delete.svg";
import iconMoreRound from "../assets/icons/icon-more-round.svg";
import user1Icon from "../assets/icons/icon-avatar-image-1.svg";
import user2Icon from "../assets/icons/icon-avatar-image-2.svg";
import user3Icon from "../assets/icons/icon-avatar-image-3.svg";
import iconFb from "../assets/icons/Icon-fb-20.svg";
import iconInsta from "../assets/icons/Icon-Instagram-20.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import InviteNewTeamMember from "./InviteNewTeamMember";
import { Link } from "react-router-dom";
import HelperVideo from "./Common/HelperVideo";
import ChannelHeaderNew from "./Common/ChannelHeaderNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";

const SalesBoxMessageScreens = () => {
  const [showDelete, setShowDelete] = useState(0);
  const [isActive, setIsActive] = useState(1);
  const userData = [
    {
      id: 1,
      title: "Open for all the team",
      desc: "The Channel will be open to join for all current and future team members.",
    },
    {
      id: 2,
      title: "Invite only",
      desc: "The Channel will be accessible to team members who have been invited.",
    },
  ];
  const headerText = "Choose access permissions";

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew />
        <section className="dashboard__main">
          <ChannelHeaderNew
            showToggle={true}
            setIsActive={setIsActive}
            isActive={isActive}
            HeaderIcon={() => {
              return (
                <Link to="/sales-box-first-view">
                  <button className="btn btn--back rounded btn--xs border border-grey-400">
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M4.91895 1L1.00195 5L4.91895 9"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 4.99634L11 4.99634"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="ml-2 text-13 font-medium text-primary">
                      Back to Stages
                    </span>
                  </button>
                </Link>
              );
            }}
          />
          <div className="dashboard__main__body">
            <div
              className="dashboard__main__body__section-left"
              style={{
                padding: "17px 20px 20px 26px",
              }}
            >
              <div
                className="box-card-container mr-5 clip-path mt-15"
                style={{ minWidth: "100%" }}
              >
                <div
                  className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white"
                  style={{
                    width: 61,
                    height: 24,
                    border: "1px solid rgba(4, 145, 171, 0.3)",
                    borderRadius: "20px",
                  }}
                >
                  $153
                </div>
                <div className="flex justify-between ml-3 mt-2">
                  <div className="font-semibold text-lg">Won</div>
                  <div className="flex items-center">
                    <div
                      className="cursor-pointer relative"
                      onClick={() => setShowDelete(1)}
                    >
                      <img
                        src={iconMoreRound}
                        alt=""
                        style={{ width: 16, height: 4 }}
                      />
                      <div
                        className={`dropdown-menu dropdown-menu--more ${
                          showDelete === 1 ? "open" : ""
                        }`}
                        style={{ top: 10 }}
                      >
                        <div className="dropdown-menu__list red-text">
                          <img
                            src={deleteIcon}
                            alt=""
                            className="mr-2"
                            style={{ width: 16, height: 16 }}
                          />
                          Delete
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-sm flex justify-center text-white ml-3 font-semibold bg-secondary"
                        style={{
                          width: 32,
                          height: 22,
                          borderRadius: "20px",
                        }}
                      >
                        3
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="box-card" style={{ width: "100%" }}>
                    <div className="flex mt-1">
                      <figure
                        className="avatar-container avatar-container--contain mr-4"
                        style={{ width: 48, height: 48 }}
                      >
                        <img src={user2Icon} alt="" />
                        <figure
                          className="avatar-container bg-white"
                          style={{
                            width: 20,
                            height: 20,
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <img
                            src={iconFb}
                            alt=""
                            className=""
                            style={{ width: 12, height: 12 }}
                          />
                        </figure>
                      </figure>
                      <div>
                        <div className="font-semibold">Alex Foster</div>
                        <div className="text-15 truncate">How are you !</div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="flex justify-end text-sm"
                        style={{ marginTop: 30 }}
                      >
                        May 29
                      </div>
                    </div>
                  </div>
                  <div className="box-card mt-3" style={{ width: "100%" }}>
                    <div className="flex mt-1">
                      <figure
                        className="avatar-container avatar-container--contain mr-4"
                        style={{ width: 48, height: 48 }}
                      >
                        <img src={user1Icon} alt="" />
                        <figure
                          className="avatar-container bg-white"
                          style={{
                            width: 20,
                            height: 20,
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <img
                            src={iconInsta}
                            alt=""
                            className=""
                            style={{ width: 12, height: 12 }}
                          />
                        </figure>
                      </figure>
                      <div>
                        <div className="font-semibold">Lisa Fotios</div>
                        <div className="text-15 truncate">Hey</div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="flex justify-end text-sm"
                        style={{ marginTop: 30 }}
                      >
                        30m
                      </div>
                    </div>
                  </div>
                  <div className="box-card mt-3" style={{ width: "100%" }}>
                    <div className="flex mt-1">
                      <figure
                        className="avatar-container avatar-container--contain mr-4"
                        style={{ width: 48, height: 48 }}
                      >
                        <img src={user3Icon} alt="" />
                        <figure
                          className="avatar-container bg-white"
                          style={{
                            width: 20,
                            height: 20,
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <img
                            src={iconFb}
                            alt=""
                            className=""
                            style={{ width: 12, height: 12 }}
                          />
                        </figure>
                      </figure>
                      <div>
                        <div className="font-semibold">Merva Sahin</div>
                        <div className="text-15 truncate">Good night</div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-white text-10 font-semibold flex justify-center bg-green-700"
                        style={{
                          width: 36,
                          height: 16,
                          borderRadius: "20px",
                          marginLeft: "calc(100% - 35px)",
                        }}
                      >
                        New
                      </div>
                      <div
                        className="flex justify-end text-sm"
                        style={{ marginTop: 14 }}
                      >
                        May 31
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
                <div className="mb-3">
                  <div className="flex items-center gap-2">
                    <h3 className="text-grey-900 font-semibold">
                      Welcome to your sales box
                    </h3>
                  </div>
                  <div className="mt-4">
                    <HelperVideo />
                  </div>
                  {/* <div className="video-container mt-4">
                    <img
                      src={contactFormPlaceholder}
                      alt=""
                      className="w-full h-full"
                    />
                  </div> */}
                  <div className="mt-5">
                    <InviteNewTeamMember
                      data={userData}
                      headerText={headerText}
                    />
                  </div>
                </div>
                <Link
                  to="/manage-channel-connections-message"
                  className="btn btn--primary btn--sm w-1/4 ml-auto mt-10"
                >
                  Next
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default SalesBoxMessageScreens;
