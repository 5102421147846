import React, { useRef, useState } from 'react'
import iconInsta from "../assets/icons/Icon-Instagram-20.svg";
import iconemail from "../assets/icons/Icon-Email-22.svg";
import iconfb from "../assets/icons/Icon-fb-GS-20.svg";
import Snackbar from "./Common/SnackBar";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";

const BoxSettingsChannelConnections = () => {
	const [isEmptyChannel, setIsEmptyChannel] = useState(false);
	const [state, setState] = React.useState({
		checkedA: false,
		checkedB: false,
		checkedC: false,
		checkedD: false,
	});
	const [type, setType] = useState(false);
	const snackbarRef = useRef(null);
	const handleChange = (event) => {
		snackbarRef.current.show();
		event.target.checked ? setType("success") : setType("fail");
		setState({ ...state, [event.target.name]: event.target.checked });
	};
	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />

				<section className="dashboard__main general-settings">
					<GeneralSettingsHedder text={"General Box Settings"} />
					<div className="dashboard__main__body">
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<BoxSettingsNavLinks activeScreen={1} />
						</div>
						<div className='dashboard__main__body__section-right p-10'>
							<h2 className="text-2xl font-semibold text-center">
								Connect channels to this box
							</h2>
							<p className="text-grey-800 text-sm text-center mt-3 px-16">
								Connect channels to this box to automatically send enquiries
								from a channel in here. When you connect a channel, it will no
								longer show on the channel list but don’t worry, the connection
								is still there.
							</p>
							{!isEmptyChannel ?
								<div className="border border-grey-400 bg-white mt-10 border-radius-14 px-6 py-4">
									<h2 className="text-lg font-semibold">Channels</h2>
									<ul className="mt-4">
										<li className="connect-channels-list bg-color-on-hover">
											<div className="flex items-center gap-3">
												<img src={iconfb} />
												<span className="connect-channels-list__title">Stead</span>
											</div>
											<div className="ml-auto">
												<div className="toggle-slider">
													<input
														type="checkbox"
														id={`externalEmail-2345`}
														// defaultChecked={false}
														className="toggle-slider__checkbox"
														name="checkedA"
														value={state.checkedA}
														onChange={handleChange}
													/>
													<label
														htmlFor={`externalEmail-2345`}
														className="toggle-slider__label"
													>
														<code className="toggle-slider__ball"></code>
													</label>
												</div>
											</div>
										</li>
										<li className="connect-channels-list bg-color-on-hover">
											<div className="flex items-center gap-3">
												<img src={iconInsta} />
												<span className="connect-channels-list__title">Hunley Weddings</span>
											</div>
											<div className="ml-auto">
												<div className="toggle-slider">
													<input
														type="checkbox"
														id={`externalEmail-2345`}
														// defaultChecked={false}
														className="toggle-slider__checkbox"
														name="checkedA"
														value={state.checkedA}
														onChange={handleChange}
													/>
													<label
														htmlFor={`externalEmail-2345`}
														className="toggle-slider__label"
													>
														<code className="toggle-slider__ball"></code>
													</label>
												</div>
											</div>
										</li>
										<li className="connect-channels-list bg-color-on-hover">
											<div className="flex items-center gap-3">
												<img src={iconfb} />
												<span className="connect-channels-list__title">Facebook pages</span>
											</div>
											<div className="ml-auto">
												<div className="toggle-slider">
													<input
														type="checkbox"
														id={`externalEmail-2345`}
														// defaultChecked={false}
														className="toggle-slider__checkbox"
														name="checkedA"
														value={state.checkedA}
														onChange={handleChange}
													/>
													<label
														htmlFor={`externalEmail-2345`}
														className="toggle-slider__label"
													>
														<code className="toggle-slider__ball"></code>
													</label>
												</div>
											</div>
										</li>
										<li className="connect-channels-list bg-color-on-hover">
											<div className="flex items-center gap-3">
												<img src={iconemail} />
												<span className="connect-channels-list__title">Wedding email</span>
											</div>
											<div className="ml-auto">
												<div className="toggle-slider">
													<input
														type="checkbox"
														id={`externalEmail-2345`}
														// defaultChecked={false}
														className="toggle-slider__checkbox"
														name="checkedA"
														value={state.checkedA}
														onChange={handleChange}
													/>
													<label
														htmlFor={`externalEmail-2345`}
														className="toggle-slider__label"
													>
														<code className="toggle-slider__ball"></code>
													</label>
												</div>
											</div>
										</li>
									</ul>
									<Snackbar
										ref={snackbarRef}
										message={type === "success" ? "Connected" : "Disconnected"}
										type={type}
									/>
								</div> :
								<div className="flex flex-col items-center justify-center text-center border border-grey-400 bg-white p-10 mt-10 border-radius-14">
									<h4 className="text-xl font-semibold">
										You currently have no channels
									</h4>
									<p className="text-base-1 text-grey-800 mt-2">
										Connected to your account
									</p>
									<button className="btn btn--primary btn--sm mt-8">Connect a channel</button>
								</div>}
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default BoxSettingsChannelConnections