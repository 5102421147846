import React, { useState } from 'react'
import AccountsSideBarExpanded from './Common/AccountsSideBarExpanded'
import AccountsSideLinks from './Common/AccountsSideLinks'
import AddNewAccountLinks from './Common/AddNewAccountLinks'

const AddNewAccount = () => {
	const [openIndustryDropDown,setOpenIndustryDropDown] = useState(false)
	const [selectedIndustry, setSelectedIndustry] = useState('Select Industry');
	const [openTeamSizeDropdown, setOpenTeamSizeDropdown] = useState(false);
	const [selectedTeamSize,setSelectedTeamSize] = useState('Team Size')
	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<AccountsSideLinks />
				<AccountsSideBarExpanded />
				<section className="dashboard__main">
					<div className="dashboard__main__header">
						<h3 className="font-semibold text-2xl">Add a new account</h3>
					</div>
					<div className="dashboard__main__body" style={{ height: 'calc(100% - 83px)' }}>
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<AddNewAccountLinks activeScreen={1} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay p-10">
							<div className="text-center">
								<h2 className="text-2xl font-semibold">Please add the account details</h2>
								<p className="text-15 text-grey-800 mt-3">
									Create a new account or copy from existing
								</p>
							</div>
							<div className="mt-6 xxl:mt-8">
								<div className="relative">
									<input
										className="input-field input-field--icon-pos-left input-field--icon-business"
										placeholder="Business Name"
									/>
									{/* <p className="error-msg error-msg--sm">Please add your business name</p> */}
								</div>
								<div className="relative mt-3">
									<input
										className="input-field input-field--icon-pos-left input-field--icon-web"
										placeholder="Website URL"
									/>
								</div>
								<div className="relative mt-3" onClick={() => {setOpenIndustryDropDown(!openIndustryDropDown); setOpenTeamSizeDropdown(false)}}>
									<input
										className="input-field input-field--select input-field--icon-pos-left input-field--icon-industry"
										// placeholder="Select Industry"
										value={selectedIndustry}
										style={{fontWeight: 400}}
									/>
									<div className={`dropdown-menu py-2 ${openIndustryDropDown ? 'open' : ''}`}>
										<div className="dropdown-menu__list">Dental</div>
										<div className="dropdown-menu__list">Golf</div>
										<div className="dropdown-menu__list">Leisure</div>
										<div className="dropdown-menu__list">Hotel</div>
										<div className="dropdown-menu__list">Wedding</div>
										<div className="dropdown-menu__list">Cosmetic & Aesthetic</div>
										<div className="dropdown-menu__list">IT Services</div>
										<div className="dropdown-menu__list selected">Other</div>
									</div>
								</div>
								<div className="relative mt-3" onClick={() => {setOpenTeamSizeDropdown(!openTeamSizeDropdown); setOpenIndustryDropDown(false)}}>
									<input
										className="input-field input-field--select input-field--icon-pos-left input-field--icon-user-group"
										value={selectedTeamSize}
										style={{fontWeight: 400}}
									/>
									<div className={`dropdown-menu ${openTeamSizeDropdown ? 'open' : ''}`}>
										<div className="dropdown-menu__list">1-5 Employees</div>
										<div className="dropdown-menu__list">6-10 Employees</div>
										<div className="dropdown-menu__list">11-20 Employees</div>
										<div className="dropdown-menu__list selected">
											Greater than 20 Employees
										</div>
									</div>
								</div>
							</div>
							<button className="btn btn--primary w-full mt-6 xxl:mt-8">Continue</button>
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default AddNewAccount