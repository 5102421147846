import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import avatarPic1 from "../../assets/icons/icon-avatar.svg";
import iconPlus from "../../assets/icons/icon-plus.svg";
import iconSales from "../../assets/icons/icon-suitcase.svg";
import iconGeneral from "../../assets/icons/icon-table-tennis.svg";
import iconEmojiFire from "../../assets/icons/icon-emoji-fire.svg";
import iconRocket from "../../assets/icons/icon-emoji-rocket.svg";
import iconBall from "../../assets/icons/icon-emoji-ball.svg";
import EmojiModal from "../modal/EmojiModal";
import PopUp from "../PopUp";
import handIcon from '../../assets/icons/icon-hand-tooltip.png';
import SetUpModal from "../modal/SetUpModal";


const SidebarExpanded = ({ navType2 = false, activeTab = "facebook" }) => {

  const [showPotentialLeadsEmojiModal, setShowPotentialLeadsEmojiModal] =
    useState(false);
  const [showMarketingLeadsEmojiModal, setShowMarketingLeadsEmojiModal] =
    useState(false);
  const [showProductEnquiriesModal, setShowProductEnquiriesEmojiModal] =
    useState(false);
	const [openBoxSetup, setOpenBoxSetup] = useState(false);

  return (
    <div className="dashboard__sidebar__expanded-section">
      <h2 className="text-2xl font-semibold text-grey-900">Enquirybox</h2>
      <div className="flex items-center mt-5 pb-18 border-b border-grey-400">
        <PopUp text={"No problem Merva, I’ll remind you to watch the video later. Check out the website Chatbot that we built for you"}
          subTextOne={"Ella"}
          subTextTwo={"Enquiry Assistant"}
          img={handIcon}
          width={350}>
          <Link className="flex items-center" to="/enquiry-assistant-summary">
            <figure
              className="avatar-container avatar-container--contain"
              style={{
                width: 32,
                height: 32,
                border: "1px solid rgba(0, 0, 0, 0.05)",
              }}
            >
              <img src={avatarPic1} alt="" style={{width: 22, height: 28}}/>
            </figure>
            <p className="ml-3 text-base-1 font-semibold text-grey-900">
              Enquiry Assistant
            </p>
          </Link>
        </PopUp>
        <div
          className="flex items-center justify-center text-xs text-white font-semibold bg-red-900 px-2 rounded-full ml-auto"
          style={{ height: 20 }}
        >
          3
        </div>
      </div>
      <div className="">
        <div className="flex items-center justify-between pt-4 pb-2">
          <h3 className="text-lg font-semibold text-grey-900">Boxes</h3>
          <img src={iconPlus} alt="" className="cursor-pointer" onClick={() => setOpenBoxSetup(true)}/>
        </div>
        <ul className="flex flex-col">
          <li className="dashboard__sidebar__nav-list">
            <img
              src={iconEmojiFire}
              alt="icon-emoji-fire"
              onClick={() => {
                setShowProductEnquiriesEmojiModal(false);
                setShowMarketingLeadsEmojiModal(false);
                setShowPotentialLeadsEmojiModal(!showPotentialLeadsEmojiModal);
              }}
            />
            <span className="text-base-1 font-medium text-grey-900">
              Potential Leads
            </span>
          </li>
          {showPotentialLeadsEmojiModal && <EmojiModal className="mt-8" />}
          <li className="dashboard__sidebar__nav-list">
            <img
              src={iconRocket}
              alt="icon-rocket"
              onClick={() => {
                setShowProductEnquiriesEmojiModal(false);
                setShowPotentialLeadsEmojiModal(false);
                setShowMarketingLeadsEmojiModal(!showMarketingLeadsEmojiModal);
              }}
            />
            <span className="text-base-1 font-medium text-grey-900">
              Marketing Leads
            </span>
          </li>
          {showMarketingLeadsEmojiModal && <EmojiModal className="mt-75" />}
          <li className="dashboard__sidebar__nav-list">
            <img
              src={iconBall}
              alt="icon-ball"
              onClick={() => {
                setShowPotentialLeadsEmojiModal(false);
                setShowMarketingLeadsEmojiModal(false);
                setShowProductEnquiriesEmojiModal(!showProductEnquiriesModal);
              }}
            />
            <span className="text-base-1 font-medium text-grey-900">
              Product Enquiries
            </span>
          </li>
          {showProductEnquiriesModal && <EmojiModal className="mt-115" />}
        </ul>
      </div>
      <div className="bg-grey-400 my-6" style={{ height: 1 }}></div>
      <div>
        <div className="flex items-center justify-between pb-2">
          <h3 className="text-lg font-semibold text-grey-900">Channels</h3>
          <img src={iconPlus} alt="" />
        </div>
        <ul className={`${navType2 && "dashboard__sidebar__nav-list--type2"}`}>
          <Link
            to="/dashboard-website-chatbot"
            className="dashboard__sidebar__nav-list active"
          >
            <figure className="avatar-container">
              <svg width="18" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M18 8.13559V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V8.13559H18ZM15.5 3C16.8807 3 18 4.11929 18 5.5V6.91525H2V5.5C2 4.11929 3.11929 3 4.5 3H15.5ZM4.44444 4.27119C4.07625 4.27119 3.77778 4.57852 3.77778 4.95763C3.77778 5.33674 4.07625 5.64407 4.44444 5.64407C4.81263 5.64407 5.11111 5.33674 5.11111 4.95763C5.11111 4.57852 4.81263 4.27119 4.44444 4.27119ZM6.66667 4.27119C6.29848 4.27119 6 4.57852 6 4.95763C6 5.33674 6.29848 5.64407 6.66667 5.64407C7.03486 5.64407 7.33333 5.33674 7.33333 4.95763C7.33333 4.57852 7.03486 4.27119 6.66667 4.27119Z"
                  fill="#4C5254"
                />
              </svg>
            </figure>
            <p className="dashboard__sidebar__nav-list__title">
              Website Chatbot
            </p>
          </Link>
          <Link
            to="/dashboard-contact-form"
            className="dashboard__sidebar__nav-list"
          >
            <figure className="avatar-container">
              <svg width="16" height="14" viewBox="0 0 16 16" fill="none">
                <path
                  d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                  fill="#4C5254"
                />
              </svg>
            </figure>
            <p className="dashboard__sidebar__nav-list__title">Contact Form</p>
          </Link>
          <Link
            to="/dashboard-email-address"
            className="dashboard__sidebar__nav-list"
          >
            <figure className="avatar-container">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 3.5V10.5C0 12.433 1.59188 14 3.55556 14H12.4444C14.4081 14 16 12.433 16 10.5V3.5C16 1.567 14.4081 0 12.4444 0H3.55556C1.59188 0 0 1.567 0 3.5ZM3.3009 2.82049C2.93328 2.57031 2.43658 2.67171 2.1915 3.04699C1.94642 3.42228 2.04576 3.92932 2.41338 4.17951L6.9223 7.24808C7.5749 7.69221 8.4251 7.69221 9.0777 7.24808L13.5866 4.17951C13.9543 3.92932 14.0536 3.42228 13.8085 3.04699C13.5634 2.67171 13.0667 2.57031 12.6991 2.82049L8.19018 5.88905C8.07502 5.96743 7.92498 5.96743 7.80982 5.88905L3.3009 2.82049Z"
                  fill="#4C5254"
                />
              </svg>
            </figure>
            <p className="dashboard__sidebar__nav-list__title">
              Enquiries Email
            </p>
            <div className="ml-auto flex items-center gap-2">
              <span className="text-base-1 font-medium text-grey-600">8</span>
            </div>
          </Link>
          <Link
            to="/dashboard-facebook"
            className={"dashboard__sidebar__nav-list"}
          >
            <figure className="avatar-container">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                  fill="#4C5254"
                />
              </svg>
            </figure>
            <p className="dashboard__sidebar__nav-list__title">Facebook Page</p>
          </Link>
          <Link
            to="/dashboard-instagram"
            className="dashboard__sidebar__nav-list"
          >
            <figure className="avatar-container">
              <svg width="15" height="15" viewBox="0 0 16 16" fill="none">
                <path
                  d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
                  fill="#4C5254"
                />
              </svg>
            </figure>
            <p className="dashboard__sidebar__nav-list__title">
              Instagram Account
            </p>
          </Link>
        </ul>
      </div>
			<SetUpModal openBoxSetup={openBoxSetup} setOpenBoxSetup={setOpenBoxSetup}/>
    </div>
  );
};

export default SidebarExpanded;
