import React, { useState } from "react";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import iconFlash from "../assets/icons/icon-flash.svg";

const ModalAddSingleContactWarningMessage = ({ hideModalShow, modalShow }) => {
  return (
    <div className="modal show modal--upload-contact">
      <div className="modal__container">
        <div
          className="modal__header modal__header--plain"
          style={{ justifyContent: "flex-end" }}
        >
          <div
            className="modal__close-icon"
            onClick={() =>
              hideModalShow({
                addSingelContact: !modalShow.addSingelContact,
                contactSetup: modalShow.contactSetup,
              })
            }
          >
            <img src={modalCloseIcon} alt="" />
          </div>
        </div>

        <div className="modal__body px-8 pb-10 ">
          <h3 className="text-xl font-semibold px-5 text-center">
            The following automation will run when contacts gets added to the
            Won stage
          </h3>
          <div className="card-box mt-6 p-4">
            <div className="flex items-start">
              <img src={iconFlash} className="mt-1" />
              <div className="ml-4">
                <p className="font-semibold">
                  Send a confirmation email to the customer
                </p>
                <spam className="text-primary text-13 font-medium cursor-pointer">
                  Preview automation{" "}
                </spam>
              </div>
            </div>
          </div>
          <div className="card-box mt-4 py-5 px-8">
            <p className="text-sm font-medium text-center px-5">Would you like to run this automation or skip it when adding the contacts?</p>
            <div className="flex items-center gap-2 mt-4">
              <button className="btn btn--error btn--sm text-base-1 w-6/12">Skip automation</button>
              <button className="btn btn--primary btn--sm text-base-1 w-6/12">Run automation</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddSingleContactWarningMessage;
