import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/eb-logo.svg";
import arrowRight from "../assets/icons/icon-arrow-right.svg";
import {
	default as avatarPic1,
	default as iconAvatar,
} from "../assets/icons/icon-avatar.svg";
import iconEmailProvider from "../assets/icons/Icon-email-email-provider.svg";
import iconExpand from "../assets/icons/icon-expand.svg";
import icongSuite from "../assets/icons/icon-g-suite.svg";
import iconCheckCirlce from "../assets/icons/icon-green-check-circle.svg";
import iconMicrosoft from "../assets/icons/icon-microsoft-email-provider.svg";
import iconNoteActive from "../assets/icons/icon-note-active.svg";
import iconReminderActive from "../assets/icons/icon-reminder-active.svg";
import iconReplyActive from "../assets/icons/icon-reply.svg";

import iconPlus from "../assets/icons/icon-plus.svg";
import iconReminder from "../assets/icons/icon-reminder.svg";
import iconDownload from "../assets/icons/icon-download.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import avatarPic from "../assets/images/avatar-pic.png";
import picAvatar2 from "../assets/images/avatar-pic2.png";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import EmailConversationReply from "./EmailConversationReply";
import { fileIcons } from "./constants/constants";
import InputField from "./Common/InputField";
import EmailConversationBody from "./EmailConversationBody";
import CustomToolTip from "./Common/CustomToolTip";
import SidebarLinks from "./Common/SidebarLinks";
import ChannelHeader from "./Common/ChannelHeader";
import SidebarExpanded from "./Common/SidebarExpanded";
import { useHistory } from "react-router-dom";
import ChatPreviewListSample from "./ChatPreviewListSample";
import iconHelpVideo from '../assets/icons/icon-help-video.svg';
import HelperVideo from "./Common/HelperVideo";

const footerIcons = [
	{
		text: "Reply",
		icon: iconReplyActive,
	},
	{
		text: "Note",
		icon: iconNoteActive,
	},
	{
		text: "Reminder",
		icon: iconReminderActive,
	},
];

const users = [
	{
		name: "None",
	},
	{
		name: "Madison Inouye",
	},
	{
		name: "Olivia Rhye",
	},
	{
		name: "Charles Wundeng",
	},
	{
		name: "Lisa Fotios",
	},
	{
		name: "Peter Parker",
	},
];

const DashboardEmailAddress = () => {
	const [openChat, setOpenChat] = useState(false);
	const [openTab, setOpenTab] = useState("reply");
	const [showAssignToUsersList, setShowAssignToUsersList] = useState(false);
	const [notes, setNotes] = useState("");
	const [inputValue, setInputValue] = useState("");
	const [showUsers, setShowUsers] = useState(false);
	const [showFooter, setShowFooter] = useState(false);

	const node = useRef();
	let history = useHistory();

	const nextScreen = () => {
		history.push("/connect-channels");
	};
	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	const handleClick = (e) => {
		if (node.current?.contains(e.target)) {
			return;
		}
		setShowAssignToUsersList(false);
		setShowUsers(false);
	};

	const ChatItem = ({ }) => {
		return (
			<>
				<figure
					className="avatar-container avatar-container--full bg-white"
					style={{
						width: 48,
						height: 48,
						border: "1px solid rgba(0, 0, 0, 0.05)",
					}}
				>
					<img src={picAvatar2} className="" />
				</figure>
				<div className="ml-4 truncate">
					<h3 className="font-bold text-grey-900 truncate">Charles Wundeng</h3>
					<div className="flex items-center whitespace-nowrap">
						<p className="text-base-1 text-grey-800 truncate">
							You are now connected wedding lorem ipsum text
						</p>
						<code
							className="flex-shrink-0 bg-grey-500 rounded-full"
							style={{
								width: 4,
								height: 4,
								marginLeft: 10,
								marginRight: 5,
							}}
						></code>
						<span className="text-sm text-grey-700">Apr 26</span>
					</div>
				</div>
			</>
		);
	};

	const FooterIconItem = ({ icon = null, text = "" }) => {
		const [showLabel, setShowLabel] = useState(false);

		return (
			<div
				className="flex flex-col items-center item cursor-pointer"
				onMouseEnter={() => setShowLabel(true)}
				onMouseLeave={() => setShowLabel(false)}
				onClick={() => {
					setOpenTab(text.toLowerCase());
					setShowFooter(true);
				}}
			>
				<div className={`item__label ${showLabel && "item__label__visible"}`}>
					<p className="font-normal font-semibold text-white">Draft</p>
				</div>

				<img src={icon} />
				<p className="text-xs text-primary font-bold">{text}</p>
			</div>
		);
	};

	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />
				<section className="dashboard__main">
					<ChannelHeader
						text={"Email address"}
						linkText="Settings & Notifications"
						HeaderIcon={() => (
							<figure className="avatar-container">
								<svg width="22" height="19" viewBox="0 0 16 14" fill="none">
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M0 3.5V10.5C0 12.433 1.59188 14 3.55556 14H12.4444C14.4081 14 16 12.433 16 10.5V3.5C16 1.567 14.4081 0 12.4444 0H3.55556C1.59188 0 0 1.567 0 3.5ZM3.3009 2.82049C2.93328 2.57031 2.43658 2.67171 2.1915 3.04699C1.94642 3.42228 2.04576 3.92932 2.41338 4.17951L6.9223 7.24808C7.5749 7.69221 8.4251 7.69221 9.0777 7.24808L13.5866 4.17951C13.9543 3.92932 14.0536 3.42228 13.8085 3.04699C13.5634 2.67171 13.0667 2.57031 12.6991 2.82049L8.19018 5.88905C8.07502 5.96743 7.92498 5.96743 7.80982 5.88905L3.3009 2.82049Z"
										fill="#2B3639"
									/>
								</svg>
							</figure>
						)}
					/>
					<div className="dashboard__main__body">
						<div className="dashboard__main__body__section-left">
							<ChatPreviewListSample />
						</div>
						{!openChat ? (
							<div className="dashboard__main__body__section-right layout-1">
								<div className="card-container--new">
									<div>
										<h2 className="font-semibold text-lg">Connect an email address</h2>
										<p className="text-grey-800 text-sm mt-3">By connecting your email address you will be able to receive and reply to email enquiries right here in this channel or send them to a particular box.</p>
									</div>								
									<div className="mt-9">
										<h3 className="text-grey-900 font-semibold" onClick={nextScreen}>
											Select your email provider
										</h3>
										<ul className="mt-15">
											<li className="email-provider-list active selected">
												<figure className="email-provider-list__avatar email-provider-list__avatar--g-suite">
													<img src={icongSuite} alt="" />
												</figure>
												<div className="ml-15 truncate" >
													<h4 className="text-base-1 font-semibold text-grey-900 truncate">
														Gmail or G suite{" "}
													</h4>
													<p className="text-sm text-grey-800 truncate">
														Connect a single Gmail or Gsuite email address
													</p>
												</div>
												<img
													src={iconCheckCirlce}
													alt="check-circle"
													className="flex-shrink-0 ml-auto"
												/>
											</li>
											<li className="email-provider-list">
												<figure className="email-provider-list__avatar email-provider-list__avatar--microsoft">
													<img src={iconMicrosoft} alt="" />
												</figure>
												<div className="ml-15">
													<h4 className="text-base-1 font-semibold text-grey-900">
														Microsoft
													</h4>
													<p className="text-sm text-grey-800">
														Connect a single Outlook or office 365 email address
													</p>
												</div>
											</li>
											<li className="email-provider-list email-provider-list--">
												<figure className="email-provider-list__avatar email-provider-list__avatar--email">
													<img src={iconEmailProvider} alt="" />
												</figure>
												<div className="ml-15">
													<h4 className="text-base-1 font-semibold text-grey-900">
														Any other email provider
													</h4>
													<p className="text-sm text-grey-800">
														Connect any other email services such as Microsoft
														Exchange server, yahoo or any other service.
													</p>
												</div>
											</li>
										</ul>
									</div>
									<div className="mt-8">
										<HelperVideo />
									</div>								
								</div>
								{/* <div className="card-container">
                  <h3 className="text-grey-900 font-semibold">
                    Connect an email address
                  </h3>
                  <div className="video-container mt-4">
                    <img
                      src={contactFormPlaceholder}
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                  <div className="mt-6">
                    <h3 className="text-grey-900 font-bold" onClick={nextScreen}>
                      Select your email provider
                    </h3>
                    <ul className="mt-15 mr-5">
                      <li className="email-provider-list active selected">
                        <figure className="email-provider-list__avatar email-provider-list__avatar--g-suite">
                          <img src={icongSuite} alt="" />
                        </figure>
                        <div className="ml-15 truncate" >
                          <h4 className="text-base-1 font-semibold text-grey-900 truncate">
                            Gmail or G suite{" "}
                          </h4>
                          <p className="text-sm text-grey-800 truncate">
                            Connect a single Gmail or Gsuite email address
                          </p>
                        </div>
                        <img
                          src={iconCheckCirlce}
                          alt="check-circle"
                          className="flex-shrink-0 ml-auto"
                        />
                      </li>
                      <li className="email-provider-list">
                        <figure className="email-provider-list__avatar email-provider-list__avatar--microsoft">
                          <img src={iconMicrosoft} alt="" />
                        </figure>
                        <div className="ml-15">
                          <h4 className="text-base-1 font-semibold text-grey-900">
                            Microsoft
                          </h4>
                          <p className="text-sm text-grey-800">
                            Connect a single Outlook or office 365 email address
                          </p>
                        </div>
                      </li>
                      <li className="email-provider-list email-provider-list--">
                        <figure className="email-provider-list__avatar email-provider-list__avatar--email">
                          <img src={iconEmailProvider} alt="" />
                        </figure>
                        <div className="ml-15">
                          <h4 className="text-base-1 font-semibold text-grey-900">
                            Any other email provider
                          </h4>
                          <p className="text-sm text-grey-800">
                            Connect any other email services such as Microsoft
                            Exchange server, yahoo or any other service.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> */}
							</div>
						) : (
							<div className="dashboard__main__body__section-right chat-message-container">
								<div className="chat-message-header flex items-center">
									<div
										className="inline-flex items-center border border-grey-400"
										style={{
											borderRadius: 30,
											paddingTop: 4,
											paddingBottom: 4,
											paddingLeft: 4,
											paddingRight: 15,
										}}
									>
										{/* <figure
                      className="avatar-container avatar-container--full"
                      style={{ width: 28, height: 28 }}
                    >
                      <img src={avatarPic} alt="avatar-pic" /> 
                    </figure> */}
										<div
											className="avatar-container avatar-container--initials"
											style={{ backgroundColor: "#4B7FE6" }}
										>
											<p className="text-11 font-medium">LF</p>
										</div>
										<p
											className="text-13 font-medium ml-2"
											style={{ color: "#292929" }}
										>
											Lisa Fotios
										</p>
										<img src={arrowRight} alt="arrow-right" className="ml-2" />
									</div>
									<div className="flex items-center gap-2 ml-auto">
										<button
											className="btn btn--primary btn--sm gap-2"
											style={{
												borderRadius: 6,
												padding: "2px 11px",
												fontSize: 13,
												fontWeight: "500",
											}}
										>
											<svg
												width="14"
												height="13"
												viewBox="0 0 14 13"
												fill="none"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M7.99743 2.50257H3.5C3.34825 2.50257 3.19913 2.51384 3.05343 2.53558C3.23782 1.80097 3.90257 1.25701 4.69437 1.25701H9.84937C11.2755 1.25701 12.4315 2.41309 12.4315 3.83919V8.99418C12.4315 9.86735 11.7701 10.586 10.9209 10.6764C10.971 10.459 10.9974 10.2326 10.9974 10V5.50257C10.9974 3.84572 9.65429 2.50257 7.99743 2.50257ZM0.5 5.50257C0.5 4.41956 1.07388 3.47059 1.93413 2.94316C1.93716 1.42129 3.1718 0.188526 4.69437 0.188526H9.84937C11.8656 0.188526 13.5 1.82298 13.5 3.83919V8.99418C13.5 10.5186 12.2642 11.7544 10.7398 11.7544H10.4312C9.88634 12.509 8.99923 13 7.99743 13H3.5C1.84314 13 0.5 11.6569 0.5 10L0.5 5.50257ZM5.01124 8.30863C4.80384 8.09877 4.80583 7.76051 5.01569 7.5531L7.07225 5.52063H6.07702C5.78197 5.52063 5.54278 5.28144 5.54278 4.98639C5.54278 4.69133 5.78197 4.45214 6.07702 4.45214H8.35425C8.37216 4.45151 8.39009 4.45178 8.40797 4.45294C8.53923 4.46012 8.66365 4.51552 8.75697 4.60907C8.8572 4.70953 8.91334 4.84574 8.913 4.98765L8.90757 7.28396C8.90688 7.57902 8.66712 7.81764 8.37207 7.81694C8.07702 7.81624 7.83839 7.57649 7.83909 7.28144L7.8415 6.26264L5.76677 8.31308C5.5569 8.52048 5.21865 8.51849 5.01124 8.30863Z"
													fill="white"
												/>
											</svg>
											Move to
										</button>
										<div>
											<button
												ref={node}
												onClick={() => {
													setShowAssignToUsersList((curr) => !curr);
												}}
												className="btn btn--primary gap-2"
												style={{
													borderRadius: 6,
													padding: "2px 11px",
													fontSize: 13,
													fontWeight: "500",
												}}
											>
												<svg
													width="14"
													height="14"
													viewBox="0 0 14 14"
													fill="none"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M1.24016 7.00016C1.24016 3.819 3.819 1.24016 7.00016 1.24016C10.1813 1.24016 12.7602 3.819 12.7602 7.00016C12.7602 8.27684 12.3448 9.45652 11.6418 10.4114C11.6008 9.4488 10.8232 8.575 9.72928 8.575H4.47417C3.30989 8.575 2.46516 9.56838 2.53515 10.6393C1.72564 9.64727 1.24016 8.38041 1.24016 7.00016ZM7.00016 0.160156C3.22253 0.160156 0.160156 3.22253 0.160156 7.00016C0.160156 10.7778 3.22253 13.8402 7.00016 13.8402C10.7778 13.8402 13.8402 10.7778 13.8402 7.00016C13.8402 3.22253 10.7778 0.160156 7.00016 0.160156ZM9.36262 5.425C9.36262 6.72977 8.30489 7.7875 7.00012 7.7875C5.69535 7.7875 4.63762 6.72977 4.63762 5.425C4.63762 4.12023 5.69535 3.0625 7.00012 3.0625C8.30489 3.0625 9.36262 4.12023 9.36262 5.425Z"
														fill="white"
													/>
												</svg>
												Unassigned
											</button>
											<div
												ref={node}
												className={`dropdown-menu mt-3 ${showAssignToUsersList ? "open" : ""
													}`}
												style={{
													right: "25px",
													left: "auto",
													width: "300px",
													padding: "10px 10px 6px 10px",
												}}
											>
												<InputField
													placeholder="Search"
													value={inputValue}
													onChange={(e) => {
														setInputValue(e.target.value);
													}}
												/>
												<div className="dropdown-menu__overflow-y-scroll mt-2">
													<p className="text-13 text-grey-700 px-3">
														Assign To
													</p>
													{users.map((user, index) => {
														return (
															<div key={index} className="dropdown-menu__list">
																<img
																	className="mr-3"
																	src={avatarPic}
																	style={{ width: 30, height: 30 }}
																	alt=''
																/>
																<p>{user.name}</p>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="chat-message-body">
									<div className="chat-message-list flex">
										<div
											className="avatar-container avatar-container--initials"
											style={{
												backgroundColor: "#4B7FE6",
												width: "32px",
												height: "32px",
											}}
										>
											<p
												className="text-11 font-medium"
												style={{
													marginBottom: 0,
												}}
											>
												LF
											</p>
										</div>
										<div className="" style={{ marginLeft: 6 }}>
											<div
												className="inline-block border border-grey-400 text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
													backgroundColor: "#FEF2F7",
												}}
											>
												<p className="text-13 font-medium">Charles Wundeng</p>
												Hi there! 👋 Glad you're trying out EnquiryBox! This is
												an automated...
											</div>
											<div>
												<div
													className="text-xs text-grey-700"
													style={{ marginTop: 6 }}
												>
													2m
												</div>
											</div>
										</div>
									</div>
									<div className="chat-message-list chat-message-list--sender flex">
										<div
											className="avatar-container avatar-container--initials"
											style={{
												backgroundColor: "#4B7FE6",
												width: "32px",
												height: "32px",
											}}
										>
											<p
												className="text-11 font-medium"
												style={{
													marginBottom: 0,
												}}
											>
												AF
											</p>
										</div>
										<div className="" style={{ marginRight: 10 }}>
											<div
												className="inline-block border border-grey-400 text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
													backgroundColor: "#f4f9f9",
												}}
											>
												<p className="text-13 font-medium">Alex Foster</p>
												Thank you...Cras at purus quis magna cursus dictum et ut
												arcu.
											</div>
											<div className="chat-message-list--sender flex items-center">
												<div
													className="text-xs text-grey-700"
													style={{ marginTop: 6 }}
												>
													3d
												</div>
												<code
													className="flex-shrink-0 bg-grey-500 rounded-full"
													style={{
														width: 4,
														height: 4,
														marginLeft: 5,
														marginRight: 5,
														marginTop: 6,
													}}
												></code>
												<div
													className="text-xs text-grey-700 error-msg"
													style={{ marginTop: 6 }}
												>
													Unread
												</div>
											</div>
										</div>
									</div>
									<div className="chat-message-list chat-message-list--sender flex">
										<div
											className="avatar-container avatar-container--initials"
											style={{
												backgroundColor: "#4B7FE6",
												width: "32px",
												height: "32px",
											}}
										>
											<p className="text-11 font-medium">LF</p>
										</div>
										<div className="" style={{ marginRight: 10 }}>
											<div
												className="inline-block border border-grey-400 text-base-1 text-grey-900"
												style={{
													borderRadius: 10,
													paddingTop: 6,
													paddingBottom: 6,
													paddingLeft: 15,
													paddingRight: 15,
													backgroundColor: "#f4f9f9",
												}}
											>
												<p className="text-13 font-medium">Lisa Fotios</p>I hope
												curabitur magna massa, tristique bibendum finibus nec,
												conse...
											</div>
											<div className="chat-message-list--sender flex items-center">
												<div
													className="text-xs text-grey-700"
													style={{ marginTop: 6 }}
												>
													3d
												</div>
												<code
													className="flex-shrink-0 bg-grey-500 rounded-full"
													style={{
														width: 4,
														height: 4,
														marginLeft: 5,
														marginRight: 5,
														marginTop: 6,
													}}
												></code>
												<div
													className="text-xs text-primary"
													style={{ marginTop: 6 }}
												>
													Read
												</div>
											</div>
										</div>
									</div>
									<div className="chat-message-list flex">
										<div
											className="avatar-container avatar-container--initials"
											style={{
												backgroundColor: "#4B7FE6",
												width: "32px",
												height: "32px",
											}}
										>
											<p
												className="text-11 font-medium"
												style={{
													marginBottom: 0,
												}}
											>
												MS
											</p>
										</div>
										<div className="" style={{ marginLeft: 6 }}>
											<EmailConversationBody sender={false} />
											<div>
												<div
													className="text-xs text-grey-700"
													style={{ marginTop: 6 }}
												>
													3d
												</div>
											</div>
										</div>
									</div>
									<div className="chat-message-list chat-message-list--sender flex">
										<div
											className="avatar-container avatar-container--initials"
											style={{
												backgroundColor: "#4B7FE6",
												width: "32px",
												height: "32px",
											}}
										>
											<p
												className="text-11 font-medium"
												style={{
													marginBottom: 0,
												}}
											>
												MS
											</p>
										</div>
										<div className="" style={{ marginRight: 6, width: "" }}>
											<EmailConversationBody sender={true} />
											<div>
												<div
													className="text-xs text-grey-700"
													style={{ marginTop: 6 }}
												>
													3d
												</div>
											</div>
										</div>
									</div>
								</div>
								{showFooter ? (
									<EmailConversationReply openTab={openTab} replyTo="Charles" />
								) : (
									<div className="flex footer-icons items-center p-3 mb-5 bg-white">
										{footerIcons.map((item, index) => {
											return <FooterIconItem {...{ ...item }} key={index} />;
										})}
									</div>
								)}
							</div>
						)}
					</div>
				</section>
			</section>
		</main>
	);
};

export default DashboardEmailAddress;
