import React, { useState } from "react";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import GlobelNavLinks from "./Common/GlobelNavlinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import iconDots from "../assets/icons/icon-more-round.svg";
import iconText from "../assets/icons/icon-text.svg";
import iconDecider from "../assets/icons/icon-decider.svg";
import iconValue from "../assets/icons/icon-dollar-rounded.svg";
import iconMultiChoices from "../assets/icons/icon-multi-choices.svg";
import iconDropDown from "../assets/icons/icon-dropdown.svg";
import iconEdit from "../assets/icons/icon-edit.svg";
import iconPlus from "../assets/icons/icon-plus-white.svg";

const GlobalSettingsSingleQuestions = () => {
  const [activeScreen, setActiveScreen] = useState(1);
  const [questionGroupType, setQuestionGroupType] = useState(true);
  const [selectedOption, setSelectedOption] = useState([]);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [groupCount, setGroupCount] = useState(0);
  const [questionsType, setquestionsType] = useState([
    {
      name: "How long is a piece of string",
      imgUrl: iconText,
    },
    {
      name: "What is the date of your party?",
      imgUrl: iconMultiChoices,
    },
    {
      name: "Are you vegetarian?",
      imgUrl: iconDecider,
    },
    {
      name: "Which food type do you want?",
      imgUrl: iconMultiChoices,
    },
    {
      name: "Price",
      imgUrl: iconValue,
    },
    {
      name: "Select your nonveg items",
      imgUrl: iconDropDown,
    },
  ]);
  const handleSelectedOption = (name) => {
    if (selectedOption.includes(name)) {
      setSelectedOption(
        selectedOption.filter((item) => {
          return item !== name;
        })
      );
    } else {
      setSelectedOption([...selectedOption, name]);
    }
  };
  const handleAddQuestionCheck = (evt) => {
    evt.target.checked
      ? setGroupCount(groupCount + 1)
      : setGroupCount(groupCount - 1);
  };

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main">
          <GeneralSettingsHedder text={"Account settings"} />
          <div
            className="dashboard__main__body"
            style={{ height: "calc(100% - 79px)" }}
          >
            <div className="dashboard__main__body__section-left overflow-overlay">
              <GlobelNavLinks
                setActiveScreen={setActiveScreen}
                activeScreen={4}
              />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay p-10">
              <h2 className="text-2xl text-center font-semibold">Questions</h2>
              <div className="flex mt-6 border-radius-14">
                <div className="p-2 w-full">
                  <div className="p-3">
                    <div className=" flex items-center justify-between">
                      <h3 className="text-lg font-semibold">Single questions</h3>
                      <div className="relative cursor-pointer">
                        <button className="btn btn--primary btn--xs text-13">
                        <img src={iconPlus} alt="icon-plus" class="mr-2"/>
                          Create questions</button>
                      </div>
                    </div>
                    <div className="tabs-wrapper mt-4">
                      <div
                        className={`tabs-header ${
                          questionGroupType ? "tabs-header__active" : ""
                        }`}
                        onClick={() => setQuestionGroupType(true)}
                      >
                        <div className="">Single questions</div>
                      </div>
                      <div
                        className={`tabs-header ${
                          !questionGroupType ? "tabs-header__active" : ""
                        }`}
                        onClick={() => setQuestionGroupType(false)}
                      >
                        <div className="">Question groups</div>
                      </div>
                    </div>
                  </div>
                  {questionGroupType && (
                    <div className="mt-4 px-3">
                      {questionsType.map((questionType, index) => {
                        return (
                          <div
                            className={`type-list gap-2`}
                            style={{ marginTop: "2px" }}
                            onClick={() =>
                              handleSelectedOption(questionType.name)
                            }
                          >
                            <img className="image" src={questionType.imgUrl} />
                            <span className="font-medium text-15">
                              {questionType.name}
                            </span>
                            <div className="relative cursor-pointer ml-auto">
                                    <img
                                      src={iconDots}
                                      alt="icon-dots"
                                      onClick={() =>index === 0 &&
                                        setDropDownOpen(!dropdownOpen)
                                      }
                                    />
                                    <div
                                      className={`dropdown-menu ${
                                        dropdownOpen && index === 0 && "open"
                                      }`}
                                      style={{
                                        width: 180,
                                        left: -154,
                                        top: 8,
                                        padding: 8,
                                      }}
                                    >
                                      <div className="dropdown-menu__list items-center">
                                        <img
                                          src={iconEdit}
                                          alt="icon-edit"
                                          className="mr-2"
                                        />
                                        Edit
                                      </div>
                                      <div
                                        className="dropdown-menu__list items-center"
                                        style={{ color: "#F04438" }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="mr-2"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                            fill="#F04438"
                                          />
                                        </svg>
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!questionGroupType && (
                    <div className="px-3">
                      <div className="items-center mt-3 py-2">
                        <div className="flex">
                          <h2 className="font-semibold">Pre sale questions</h2>
                          <div className="ml-auto text-xs flex items-center">
                            <span className="font-semibold text-grey-900">
                              3
                            </span>
                            <span className=" ml-1 text-grey-600">
                              Questions
                            </span>
                            <input
                              type="checkbox"
                              className="custom-checkbox custom-checkbox--sm ml-4"
                              name=""
                              onChange={handleAddQuestionCheck}
                            />
                          </div>
                        </div>
                        <div className="groups-list">
                          <img className="image" src={iconText} />
                          <span className="font-medium text-15">
                            How long is a piece of string
                          </span>
                        </div>
                        <div className="groups-list">
                          <div className="image">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 18 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 5.07032C1 3.41346 2.34315 2.07031 4 2.07031H14C15.6569 2.07031 17 3.41346 17 5.07031V11.8703C17 13.5272 15.6569 14.8703 14 14.8703H4C2.34315 14.8703 1 13.5272 1 11.8703V5.07032Z"
                                stroke="#2B3639"
                                stroke-width="1.5"
                              />
                              <path
                                d="M1 6.26562H17"
                                stroke="#2B3639"
                                stroke-width="1.5"
                              />
                              <path
                                d="M5 1.53333V1"
                                stroke="#2B3639"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                              <path
                                d="M12.6001 1.53333V1"
                                stroke="#2B3639"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>

                          <span className="font-medium text-15">
                            What is the date of your party?
                          </span>
                        </div>
                        <div className="groups-list">
                          <img className="image" src={iconDecider} />
                          <span className="font-medium text-15">
                            Are you vegetarian?
                          </span>
                        </div>
                      </div>
                      <div className="items-center mt-5 py-2">
                        <div className="flex">
                          <h2 className="font-semibold">Post sale questions</h2>
                          <div className="ml-auto text-xs flex items-center">
                            <span className="font-semibold text-grey-900">
                              4
                            </span>
                            <span className="ml-1 text-grey-600">
                              Questions
                            </span>
                            <input
                              type="checkbox"
                              className="custom-checkbox custom-checkbox--sm ml-4"
                              name=""
                              onChange={handleAddQuestionCheck}
                            />
                          </div>
                        </div>
                        <div className="groups-list">
                          <img className="image" src={iconDropDown} />
                          <span className="font-medium text-15">
                            Which food type do you want?
                          </span>
                        </div>
                        <div className="groups-list">
                          <img className="image" src={iconValue} />
                          <span className="font-medium text-15">Price</span>
                        </div>
                        <div className="groups-list">
                          <img className="image" src={iconDecider} />
                          <span className="font-medium text-15">
                            Select your nonveg items
                          </span>
                        </div>
                        <div className="groups-list">
                          <img className="image" src={iconText} />
                          <span className="font-medium text-15">
                            How long is a piece of string
                          </span>
                        </div>
                        {groupCount !== 0 && (
                          <div className="flex mt-4 justify-center cursor-pointer items-center bg-primary py-3 rounded-lg">
                            <div className="text-sm font-semibold text-white">
                              Add {groupCount} groups
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default GlobalSettingsSingleQuestions;
