import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/icons/eb-logo.svg";
import iconCheck from "../assets/icons/icon-check.svg";
import arrowRight from "../assets/icons/icon-arrow-right.svg";
import avatarPic1 from "../assets/icons/icon-avatar.svg";
import iconNoteActive from "../assets/icons/icon-note-active.svg";
import iconExpand from "../assets/icons/icon-expand.svg";
import iconClose from "../assets/icons/icon-close-white.svg";
import iconNoteInActive from "../assets/icons/icon-note.svg";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconReminderActive from "../assets/icons/icon-reminder-active.svg";
import iconReminderInActive from "../assets/icons/icon-reminder.svg";
import iconReplyInActive from "../assets/icons/icon-reply-inactive.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconChevron from "../assets/icons/icon-chevron-arrow.svg";
import avatarPic from "../assets/images/avatar-pic.png";
import iconClock from "../assets/icons/icon-clock.svg";
import iconAdd from "../assets/icons/icon-add.svg";
import InputField from "./Common/InputField";
import iconReplyActive from "../assets/icons/icon-reply.svg";
import AttachFiles from "./modal/AttachFiles";
import FileLibrary from "./modal/FileLibrary";
import UploadFile from "./modal/UploadFile";
import iconAddItem from "../assets/icons/icon-add-item.svg";
import iconSmiley from "../assets/icons/icon-smiley.svg";
import iconUploadPic from "../assets/icons/icon-upload-pic.svg";
import iconAttachment from "../assets/icons/icon-attachment.svg";
import EmailConversationUserName from "./Common/EmailConversationUserName";
import AttachmentItem from "./Common/AttachmentItem";

const replyBy = [
  {
    title: "Email",
  },
  {
    title: "Facebook",
  },
];

const users = [
  {
    name: "Madison Inouye",
  },
  {
    name: "Olivia Rhye",
  },
  {
    name: "Charles Wundeng",
  },
  {
    name: "Lisa Fotios",
  },
  {
    name: "Peter Parker",
  },
];

const reminderOn = [
  {
    title: "Tomorrow morning ",
    id: "1",
  },
  {
    title: "Monday morning ",
    id: "2",
  },
  {
    title: "Custom date",
    id: "3",
  },
];

const reminderTimes = [
  {
    title: "12:00am",
  },
  {
    title: "1:00am",
  },
  {
    title: "2:00am",
  },
  {
    title: "3:00am",
  },
  {
    title: "4:00am",
  },
  {
    title: "5:00am",
  },
  {
    title: "6:00am",
  },
];

const dummyEmails = [
  {
    email: "ninouye_@gmail.com",
  },
  {
    email: "madisoninouye@gmail.com",
  },
  {
    email: "madison_inouye06@gmail.com",
  },
];

const tabs = {
  reply: { id: "reply", placeholder: "Write a reply" },
  note: { id: "note", placeholder: "Message..." },
  reminder: {
    id: "reminder",
    placeholder: "Type what you want to be reminded about...",
  },
};

const allTabs = [
  {
    id: "reply",
    text: "Reply",
    iconActive: iconReplyActive,
    iconInactive: iconReplyInActive,
  },
  {
    id: "note",
    text: "Note",
    iconActive: iconNoteActive,
    iconInactive: iconNoteInActive,
  },
  {
    id: "reminder",
    text: "Reminder",
    iconActive: iconReminderActive,
    iconInactive: iconReminderInActive,
  },
];

const dummyAttachments = [
  {
    name: "Purchase.pdf",
  },
  {
    name: "Expense receipts.docx",
  },
];

const EmailConversationReply = ({ openTab = "reply", replyTo = "" }) => {
  const [activeTab, setActiveTab] = useState(openTab);
  const [showReplyOptions, setShowReplyOptions] = useState(false);
  const [notes, setNotes] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showUsers, setShowUsers] = useState(false);
  const [showReminderOptions, setShowReminderOptions] = useState(false);
  const [showReminderTime, setShowReminderTime] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedReminderDay, setSelectedReminderDay] = useState({});
  const [showAttachFiles, setShowAttachFiles] = useState(false);
  const [showFileLibrary, setShowFileLibrary] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showEmailsDropdown, setShowEmailDropdown] = useState(false);
  const [replyFrom, setReplyFrom] = useState("");

  const HeaderTabItem = ({ active = false, onClick = () => {}, item = {} }) => {
    const { id, text, iconActive, iconInactive } = item;
    return (
      <div className="flex items-center mr-8 pointer" onClick={onClick}>
        <img src={active ? iconActive : iconInactive} alt="icon-reply" />
        <p
          className={`${
            active ? "text-primary font-semibold" : "text-grey font-medium"
          } text-13  ml-6`}
        >
          {text} {id === tabs.reply.id ? "to " + replyTo : ""}
        </p>
      </div>
    );
  };

  const node = useRef();

  const PopupItem = ({ isSelected = false, onClick = () => {}, text = "" }) => {
    return (
      <div
        className={`dropdown-menu__list ${isSelected ? "selected" : ""}`}
        onClick={onClick}
      >
        {text}
      </div>
    );
  };
  const ButtonItem = ({
    icon = iconReminderInActive,
    text = "placeholder",
    onClick = () => {},
    active = false,
  }) => {
    return (
      <div
        onClick={onClick}
        className={`flex items-center gap-2 button px-3 py-1 ${
          active ? "button__active" : ""
        }`}
      >
        <img src={icon} alt="icon-reminder" />
        <code
          className="inline-block"
          style={{
            width: 1,
            height: 22,
            backgroundColor: "#E5E5E5",
          }}
        ></code>
        <p className="text-grey-900 text-13 font-medium">{text}</p>
      </div>
    );
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowReplyOptions(false);
    setShowUsers(false);
    setShowAttachFiles(false);
    setShowReminderOptions(false);
    setShowReminderTime(false);
    setShowEmailDropdown(false);
  };

  return (
    <div
      className={`chat-message-footer chat-message-footer${
        activeTab === tabs.reply.id
          ? ""
          : activeTab === tabs.note.id
          ? "__note"
          : "__reminder"
      }`}
    >
      <div className="chat-message-footer__header chat-message-footer__header">
        <div className="flex items-center">
          {allTabs.map((item, index) => {
            let isActive = activeTab === item.id;
            return (
              <HeaderTabItem
                item={item}
                text={item.text}
                active={isActive}
                onClick={() => {
                  if (item.id === tabs.reply.id && isActive) {
                    setShowReplyOptions(true);
                  } else {
                    setActiveTab(item.id);
                  }
                }}
              />
            );
          })}
          <div
            ref={node}
            className={`dropdown-menu dropdown-menu--sm ${
              showReplyOptions && "open"
            }`}
            style={{
              width: "max-content",
              bottom: "calc(38% + 10px)",
              left: "auto",
            }}
          >
            {replyBy.map((item, index) => {
              return (
                <div className="dropdown-menu__list" key={index}>
                  <p className="text-sm">Reply by {item.title}</p>
                </div>
              );
            })}
          </div>
        </div>
        <img src={iconExpand} alt="icon-expand" />
      </div>
      {activeTab === tabs.reply.id ? (
        <div className="email-input">
          <div className="item flex items-center">
            <p className=" text-13 text-grey-600">From:</p>
            <div className="flex items-center" style={{ width: "50%" }}>
              <input
                className="text-13 text-grey-900 ml-2"
                placeholder="example@email.com"
                style={
                  replyFrom.length === 0
                    ? { width: "max-content" }
                    : { flex: 1 }
                }
                value={replyFrom}
              />
              <img
                className={`mx-1 cursor-pointer ${
                  !showEmailsDropdown && "icon-chevron-down"
                }`}
                src={iconChevron}
                style={{ marginTop: "4px" }}
                onClick={() => {
                  setShowEmailDropdown(true);
                }}
                alt="expand"
              />
            </div>
          </div>
          <div
            ref={node}
            className={`dropdown-menu dropdown-menu--sm ${
              showEmailsDropdown && "open"
            }`}
            style={{
              width: "max-content",
              left: "auto",
            }}
          >
            {dummyEmails.map((item, index) => {
              return (
                <div
                  className="dropdown-menu__list"
                  key={index}
                  onClick={() => {
                    setReplyFrom(item.email);
                    setShowEmailDropdown(false);
                  }}
                >
                  <p className="text-sm">{item.email}</p>
                </div>
              );
            })}
          </div>
          <div className="item flex items-center my-3">
            <p className="text-13 text-grey-600">Cc:</p>
            <div className="flex items-center flex-wrap">
              <div className="flex items-center">
                <EmailConversationUserName editMode={true} name={"John Leon"} />
                ,
                <EmailConversationUserName
                  editMode={true}
                  name={"Madison Inouye"}
                />
                ,
                <EmailConversationUserName
                  editMode={true}
                  name={"Alex Foster"}
                />
                ,
                <EmailConversationUserName
                  editMode={true}
                  name={"Olivia Rhye"}
                />
              </div>
              <input
                className="text-13 text-grey-900 ml-2"
                placeholder={"Add more"} //only show when there are no names in CC
              />
            </div>
          </div>

          <div className="item flex items-center">
            <p className=" text-13 text-grey-600">Bcc:</p>
            <div className=" flex items-center flex-wrap">
              <div className="flex items-center">
                <EmailConversationUserName
                  editMode={true}
                  name={"Merva Sahin"}
                />
                ,
                <EmailConversationUserName
                  editMode={true}
                  name={"Lucas Mattia"}
                />
              </div>
              <input
                className="text-13 text-grey-900 ml-2"
                placeholder="Add more"
              />
            </div>
          </div>

          <div className="item flex items-center my-3">
            <p className=" text-13 text-grey-600">Subject:</p>
            <input
              className="text-13 text-grey-900 ml-2"
              placeholder="Subject"
            />
          </div>
          <div
            className="item"
            style={{ maxHeight: "150px", overflowY: "scroll" }}
          >
            <textarea
              name=""
              rows="3"
              cols="5"
              placeholder={tabs[activeTab].placeholder}
              className="text-base-1 text-grey-900 resize-none w-full border-none outline-none"
            ></textarea>
            <div className="item__attachment-wrapper bg-grey-300 py-3 px-4">
              <h3 className="text-13 text-grey-900 font-bold mb-2">
                Attchments
              </h3>
              <div className="flex flex-wrap item__attachment items-center w-full">
                {dummyAttachments.map((attachment) => {
                  return (
                    <AttachmentItem attachment={attachment} message={false} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="chat-message-footer__body">
          <div
            ref={node}
            className={`dropdown-menu dropdown-menu--sm ${showUsers && "open"}`}
            style={{
              left: "auto",
              bottom: "145px",
              width: "300px",
            }}
          >
            <div className="dropdown-menu__overflow-y-scroll">
              {users.map((user, index) => {
                return (
                  <div
                    key={index}
                    className="dropdown-menu__list"
                    onClick={() => {
                      let newNotes = notes.slice(0, -1);
                      setNotes(newNotes + " " + user.name);
                      setShowUsers(false);
                    }}
                  >
                    <img
                      src={avatarPic}
                      className="mr-3"
                      style={{ width: 30, height: 30 }}
                    />
                    {user.name}
                  </div>
                );
              })}
            </div>
          </div>
          <textarea
            onChange={(e) => {
              setNotes(e.target.value);
              if (
                activeTab === tabs.note.id &&
                e.target.value.substr(-1) === "@"
              ) {
                setShowUsers(true);
              } else {
                setShowUsers(false);
              }
            }}
            value={notes}
            name=""
            rows="2"
            cols="5"
            placeholder={tabs[activeTab].placeholder}
            className="text-base-1 text-grey-900 resize-none w-full px-4 py-2 border-none outline-none"
          ></textarea>
        </div>
      )}

      {activeTab === tabs.reply.id ? (
        <div className="chat-message-footer__footer flex items-center justify-between px-4 py-3">
          <div className="flex items-center gap-4">
            <img src={iconAddItem} alt="icon-add-item" />
            <code
              className="inline-block"
              style={{
                width: 1,
                height: 22,
                backgroundColor: "#E5E5E5",
              }}
            ></code>
            <img src={iconSmiley} alt="icon-smiley" />
            <img src={iconUploadPic} alt="icon-upload-pic" />
            <img
              src={iconAttachment}
              alt="icon-attachment"
              className="cursor-pointer"
              onClick={() => setShowAttachFiles(true)}
            />
            <AttachFiles
              setShowFileLibrary={setShowFileLibrary}
              setShowFileUpload={setShowFileUpload}
              setShowAttachFiles={setShowAttachFiles}
              showAttachFiles={showAttachFiles}
            />
            <FileLibrary
              setShowFileLibrary={setShowFileLibrary}
              showFileLibrary={showFileLibrary}
            />
            <UploadFile
              setShowFileUpload={setShowFileUpload}
              showFileUpload={showFileUpload}
            />
          </div>
          <button
            className="btn btn--primary text-sm text-white"
            style={{ padding: "4px 10px", borderRadius: 6 }}
          >
            Send
          </button>
        </div>
      ) : activeTab === tabs.note.id ? (
        <div className="chat-message-footer__footer flex items-center justify-between px-4 py-3">
          <div className="flex items-center gap-4">
            <div className="flex items-center ps px-2 py-1">
              <strong>PS:</strong>
              <p className="ml-1">The customer wont see this note</p>
            </div>
          </div>
          <button
            className="btn btn--primary text-sm text-white"
            style={{ padding: "4px 10px", borderRadius: 6 }}
          >
            Update
          </button>
        </div>
      ) : (
        <div className="chat-message-footer__footer flex items-center justify-between px-4 py-3">
          <div className="flex items-center gap-4">
            <div>
              <div
                ref={node}
                className={`dropdown-menu dropdown-menu--sm ${
                  showReminderOptions && "open"
                }`}
                style={{ width: "240px", bottom: "80px", left: "auto" }}
              >
                {reminderOn.map((item, index) => {
                  let isSelected = item.id === selectedReminderDay.id;
                  return (
                    <PopupItem
                      isSelected={isSelected}
                      key={index}
                      text={item.title}
                      onClick={() => {
                        if (item.id !== "3") {
                          setSelectedReminderDay(item);
                        } else {
                          //open Date Picker here
                          setShowCalendar(true);
                        }
                      }}
                    />
                  );
                })}
              </div>

              <ButtonItem
                active={showReminderOptions}
                icon={iconReminderInActive}
                text="Monday morning"
                onClick={() => {
                  setShowReminderOptions(true);
                }}
              />
            </div>
            <div>
              <div
                ref={node}
                className={`dropdown-menu dropdown-menu--sm ${
                  showReminderTime && "open"
                }`}
                style={{ width: "120px", left: "auto", bottom: "80px" }}
              >
                <div className="dropdown-menu__overflow-y-scroll">
                  {reminderTimes.map((item, index) => {
                    let isSelected = index === 1;
                    return (
                      <PopupItem
                        key={index}
                        isSelected={isSelected}
                        text={item.title}
                        onClick={() => {}}
                      />
                    );
                  })}
                </div>
              </div>

              <ButtonItem
                active={showReminderTime}
                icon={iconClock}
                text="09:00am"
                onClick={() => {
                  setShowReminderTime(true);
                }}
              />
            </div>
          </div>
          <button
            className="btn btn--primary text-sm text-white"
            style={{ padding: "4px 10px", borderRadius: 6 }}
          >
            <img src={iconAdd} alt="icon-add-item" className="mr-2" />
            Add
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailConversationReply;
