import React, { useState, useRef } from "react";
import iconGroupChannel from "../assets/icons/Group132462.svg";
import iconGroupCardDesign from "../assets/icons/Group13220.svg";
import iconTeamAccess from "../assets/icons/Group13230.svg";
import iconAutomate from "../assets/icons/Group13224.svg";
import iconBoxForms from "../assets/icons/Group13237.svg";
import iconImportContacts from "../assets/icons/Group13225.svg";
import iconIntegrations from "../assets/icons/Group13229.svg";
import iconBoringStuff from "../assets/icons/Group13245.svg";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import avatarPic from "../assets/images/avatar-pic.png";
import iconPlus from "../assets/icons/icon-plus-white.svg";
import iconLock from "../assets/icons/icon-lock.svg";


const BoxSettingsNoBoxMembers = () => {
    
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"General Box Settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <ul>
                <li className=" general-settings__card-list bg-color-on-hover bg-color-on-hover--sm active">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconGroupChannel} />
                    </figure>
                    <div className="truncate pr-4">
                      <h2 className="general-settings__card-list__title">
                        Channel Connections
                      </h2>
                      <p className="general-settings__card-list__desc">
                        Channel connect to box
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconGroupCardDesign} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Card design
                      </h3>
                      <p className="general-settings__card-list__desc">
                        design the perfect enquiry card
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconTeamAccess} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Team access
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Manage who has access
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconAutomate} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Automate stuff
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Replies, Moving stuff {"&"} more
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconBoxForms} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Box forms
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Disconnect, Delete, Rename etc
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconImportContacts} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Import contacts
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Import existing contacts to the box
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconIntegrations} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Integrations
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Manage third party Integrations
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconBoringStuff} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Boring stuff
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Disconnect, Delete, Rename etc
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="dashboard__main__body__section-right p-10">
            <h2 className="text-2xl font-semibold text-center">Team access</h2>
              <div className="flex items-center justify-between py-5 px-6 border border-grey-400 border-radius-14 mt-8">
                <div className="flex items-center font-semibold">
                  <img src={iconLock} alt="icon-lock" className="mr-2" />
                  This box is invite only</div>
                <span className="text-sm text-primary font-semibold cursor-pointer">
                Change to open for all
                </span>
              </div>
              <div className="py-5 px-6 border border-grey-400 border-radius-14 mt-5">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold">Box members</h3>
                  <button className="btn btn--primary btn--xs">
                    <img src={iconPlus} alt="icon-plus" className="mr-2" />
                    New Member
                  </button>
                </div>
                <ul className="mt-5">
                  <li className="teams-profile-list bg-color-on-hover">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{ width: 32, height: 32 }}
                    >
                      <img src={avatarPic} alt="avatar-pic" />
                    </figure>
                    <div className="teams-profile-list__title">
                      Charles Wundeng
                    </div>
                    <div className="ml-auto hover-visible">
                      <span className="text-sm font-semibold text-primary hidden">
                        Invite
                      </span>
                      <span className="text-sm font-semibold text-red-900">
                        Remove
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default BoxSettingsNoBoxMembers;
