import React from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import trashIcon from "../../assets/icons/icon-trash-red.svg";

const DeleteEnquiryBinModal = ({
  openDeleteConfirmation,
  setOpenDeleteConfirmation,
}) => {
  return (
    <div className={`modal ${openDeleteConfirmation ? "show" : ""}`}>
      <div className="modal__container">
        <div
          className=" modal__header modal__header--plain flex"
          style={{ justifyContent: "flex-end" }}
        >
          <div
            className="modal__close-icon"
            onClick={() => setOpenDeleteConfirmation(false)}
          >
            <img src={modalCloseIcon} alt="" />
          </div>
        </div>
        <div className="modal__body px-8 pb-10">
          <figure
            className="flex items-center justify-center bg-red-200 rounded-full mx-auto cursor-pointer"
            style={{
              width: 54,
              height: 54,
              "box-shadow": "inset 0px 0px 0px 1px rgba(240, 68, 56, 0.05)",
            }}
          >
            <img src={trashIcon} alt="" />
          </figure>
          <div className="text-center w-8/12 mx-auto">
            <h3 className="text-xl font-semibold mt-5">
              Are you sure you want to delete these eight enquiries?
            </h3>
            <p className="text-13 mt-4">
              Once deleted you will not be able to get it back.
            </p>
          </div>

          <div className="flex items-center gap-2 justify-center mt-6">
            <button
              className="btn btn--cancel btn--md text-base-1 min-w-120"
              onClick={() => setOpenDeleteConfirmation(false)}
            >
              Cancel
            </button>
            <button className="btn btn--red btn--md text-base-1 min-w-120">Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteEnquiryBinModal;
