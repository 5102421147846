import React, { useState } from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import lockicon from "../../assets/icons/icon-lock.svg";

const ChannelHideOrRequest = ({ isrequest = false, openModal, setOpenModal, setGoToChannels }) => {
	
  return (
    <div className={`modal modal--type2 ${openModal ? 'show' : ''}`}>
      <div className="modal__container">
        <div className="modal__header modal__header--plain" style={{justifyContent: 'flex-end'}}>
          <div className="modal__close-icon" onClick={() => setOpenModal(false)}>
            <img src={modalCloseIcon} alt="" />
          </div>
        </div>
        <div className="modal__body pb-10">
          <div className="flex items-center justify-center gap-2">
            <h2 className="text-xl font-semibold text-center">
              {isrequest
                ? "This channel is invite only"
                : "This channel is open to all"}
            </h2>
            {isrequest && <img className="" alt="lock" src={lockicon} />}
          </div>
          <div className="text-13 text-center mt-5 px-10">
            {isrequest
              ? "If you do not want to see it on the sidebar, click hide this channel. If you want to join, click request to join"
              : "If you do not want to see it on the sidebar, click hide this channel. If you do want to see it, click Go to channel."}
          </div>
          <div className="flex items-center gap-2 justify-center px-16 mt-6">
            <button className="btn btn--sm text-base-1 btn--error w-6/12">
              Hide this channel
            </button>
						<button className="btn btn--sm text-base-1 btn--primary w-6/12" onClick={() => {setOpenModal(false); setGoToChannels(true)}}>
							{isrequest ? "Request to join" : "Go to channel"}
						</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelHideOrRequest;
