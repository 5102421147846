import React, {useState} from 'react';
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import selectIcon from '../../assets/icons/icon-green-select.svg';
import BoxInfo from './BoxInfo';

const AddCustomBox = ({openCustomBox, setOpenCustomBox}) => {
	const [openBoxInfo, setOpenBoxInfo] = useState(false);

	return (
		<>
			<div className={`modal modal--CustomBox ${openCustomBox ? 'show' : ''}`}>
				<div className="modal__container">
					<div className="modal__header">
						<h2 className="modal__heading pr-5">What type of Box would you like?</h2>
						<div className="modal__close-icon" onClick={() => setOpenCustomBox(false)}>
							<img src={modalCloseIcon} alt="" style={{ width: 10, height: 10 }} />
						</div>
					</div>
					<div className="modal__body" style={{ padding: '25px 30px 30px' }}>
						<ul>
							<li
								className="add-custom-list"
								onClick={() => setOpenBoxInfo(true)}
							>
								<div className='flex justify-between items-center'>
									<h2 className="font-semibold text-lg">For Sales</h2>
									<img src={selectIcon} alt='' style={{ width: 18, height: 18 }} className='invisible' />
								</div>
								<div style={{ marginTop: 10 }} className="text-sm font-normal">A sales Box is where you manage sales enquiries for specific products or services.
									You can add a value to enquiries and track sales through reports.
								</div>
							</li>
							<li
								className="add-custom-list mt-15"
								onClick={() => setOpenBoxInfo(true)}
							>
								<div className='flex justify-between items-center'>
									<h2 className="font-semibold text-lg">Not For Sales</h2>
									<img src={selectIcon} alt='' style={{ width: 18, height: 18 }} className='invisible' />
								</div>
								<div style={{ marginTop: 10 }} className="text-sm font-normal">
									This Box can be used to manage any types of enquiries or processes.
									For example, job applications, customer requests, the Sky is the limit really!
								</div>
							</li>
						</ul>
						<div className="flex items-center justify-end gap-3" style={{ marginTop: 60 }}>
							<button
								className="btn btn--grey btn--sm w-1/4"
							>
								Cancel
							</button>
							<button className="btn btn--primary btn--sm w-1/4">
								Next
							</button>
						</div>
					</div>
				</div>
			</div> 
			{/* <BoxInfo setOpenBoxInfo={setOpenBoxInfo} openBoxInfo={openBoxInfo}/>  */}
		</>
	)
}

export default AddCustomBox