import React, { useState } from "react";
import iconDots from "../assets/icons/icon-more-round.svg";
import iconText from "../assets/icons/icon-text.svg";
import iconDecider from "../assets/icons/icon-decider.svg";
import iconValue from "../assets/icons/icon-dollar-rounded.svg";
import iconMultiChoices from "../assets/icons/icon-multi-choices.svg";
import iconDropDown from "../assets/icons/icon-dropdown.svg";
import iconDate from "../assets/icons/icon-date.svg";

const AddQuestions = ({selectedOption,setSelectedOption,setShowModal,showModal}) => {
  const [questionsType, setquestionsType] = useState([
    {
      name: "Text",
      imgUrl: iconText,
    },
    {
      name: "Multiple choice",
      imgUrl: iconMultiChoices,
    },
    {
      name: "Decider",
      imgUrl: iconDecider,
    },
    {
      name: "Multi select",
      imgUrl: iconMultiChoices,
    },
    {
      name: "Value",
      imgUrl: iconValue,
    },
    {
      name: "Dropdown",
      imgUrl: iconDropDown,
    },
    {
      name: "Date",
      imgUrl: iconDate,
    },
  ]);
  const handleAddQuestion = ()=>{
   selectedOption && setShowModal(!showModal)
  }
  
  return (
    <div className="contact-questions" style={{marginBottom:"400px"}}>
      <div className="mt-6">
        <h3 className="text-lg font-semibold">Questions</h3>
        <div className="question-ans-list">
          <div className="question-ans-list__title-wrapper">
            <h5 className="question-ans-list__title">New question</h5>
          </div>
          <div className="question-ans-list__body">
            <div className="relative mt-3">
              <input
                className="input-field input-field--sm input-field--select"
                placeholder="Select your question type"
              />
              <div
                className={`dropdown-menu dropdown-menu--with-border open
                                py-5 px-5`}
                style={{
                  top: "calc(100% + 4px)",
                  height: 425,
                }}
              >
                <div
                  style={{
                    overflowY: "auto",
                    height: "327px",
                  }}
                >
                  {questionsType.map((questionType, index) => {
                    return (
                      <div
                        className={`dropdown-menu__type-list gap-2 mt-1 ${
                          questionType.name === selectedOption ? "active" : ""
                        }`}
                        onClick={() => setSelectedOption(questionType.name)}
                      >
                        <img
                          className="dropdown-menu__image"
                          src={questionType.imgUrl}
                        />
                        <span className="font-semibold">
                          {questionType.name}
                        </span>
                        {questionType.name === selectedOption && (
                          <figure className="ml-auto">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="20"
                                height="20"
                                rx="10"
                                fill="#005C6F"
                              />
                              <path
                                d="M13.7038 7.77783L8.6112 12.8704L6.29639 10.5556"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </figure>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex justify-end mt-5">
                  <button className="btn btn--primary btn--md min-w-120" onClick={() =>handleAddQuestion()}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuestions;
