import React, { useState } from "react";
import iconAvatar from "../assets/images/avatar-pic2.png";
import iconForward from "../assets/icons/icon-forward.svg";
import ChatFooterInputBox from "./ChatFooterInputBox";
import avatarPic from "../assets/images/avatar-pic.png";
import iconReplyArrow from "../assets/icons/icon-reply-white.svg";
import InputField from "./Common/InputField";
import Details from "./Details";
import ImageModal from "./modal/ImageModal";
import UploadFile from "./modal/UploadFile";
import ChatHeader from "./Common/ChatHeader";
import fullImage1 from "../assets/icons/icon-full-image1.svg";
import fullImage2 from "../assets/icons/icon-full-image2.svg";
import iconMessanger from "../assets/icons/icon-channels-messanger.svg";
import iconInsta from "../assets/icons/icon-channels-instagram.svg";
import iconFbLeads from "../assets/icons/icon-channels-fbleads.svg";
import iconWhatsApp from "../assets/icons/icon-channels-whatsApp.svg";
import iconWebforms from "../assets/icons/icon-channels-web-Forms.svg";

const users = [
	{
		name: "Charles",
	},
	{
		name: "Madison Inouye",
	},
	{
		name: "Olivia Rhye",
	},
	{
		name: "Charles Wundeng",
	},
	{
		name: "Lisa Fotios",
	},
	{
		name: "Peter Parker",
	},
];

const channels = [
	{
		id: 1,
		name: "Smiles Dental",
		icon: iconMessanger,
	},
	{
		id: 2,
		name: "Smiles Dental Practice",
		icon: iconInsta,
	},
	{
		id: 3,
		name: "+4419876352",
		icon: iconWhatsApp,
	},
	{
		id: 4,
		name: "Smiles Dental Practice",
		icon: iconFbLeads,
	},
	{
		id: 5,
		name: "Wedding",
		icon: iconInsta,
	},
	{
		id: 6,
		name: "Website forms",
		icon: iconWebforms,
	},
];

const ToggleEbox = () => {
	const [selectedUser, setSelectedUser] = useState({ name: null });
	const [openTabs, setOpenTabs] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [openFilterDropDown, setOpenFilterDropDown] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState("");
	const [showImageModal, setShowImageModal] = useState(0);
	const [openUpload, setOpenUpload] = useState(false);
	const [filter, setFilter] = useState({});
	const [activeScreen, setActiveScreen] = useState("all");
	const [openAssign, setOpenAssign] = useState(false);

	return (
		<>
			<div
				className="dashboard__main__body"
			// style={{ height: "calc(100% - 145px)" }}
			>
				<div
					className="dashboard__main__body__section-left ebox"
					style={{ width: "40%" }}
				>
					<div className="flex dashboard__main__body__section-left__header">
						<div className="tabs-wrapper">
							<div
								className={`tabs-header ${activeScreen === "all" ? "tabs-header__active" : ""
									}`}
								style={{ paddingBottom: 19, fontSize: 14 }}
								onClick={() => {
									setActiveScreen("all");
									setOpenAssign(false);
									setOpenFilterDropDown(false);
								}}
							>
								<p
									className={`${activeScreen === "all" ? "text-primary" : "text-grey-900"
										}`}
								>
									All
								</p>
								<figure
									className="border-radius-20 bg-grey-100 flex justify-center items-center ml-2"
									style={{ width: 22, height: 16 }}
								>
									<span className="text-xs font-semibold text-primary">4</span>
								</figure>
							</div>
							<div
								className={`tabs-header relative ${activeScreen === "assignedTo" ? "tabs-header__active" : ""
									}`}
								style={{ paddingBottom: 19, fontSize: 14 }}
								onClick={() => {
									setActiveScreen("assignedTo");
									setOpenAssign(!openAssign);
									setOpenFilterDropDown(false);
								}}
							>
								<div className="dashboard__main__header__full-left--assigned-to">
									{selectedUser.name === null ? (
										<span
											className={`mr-2 ${activeScreen === "assignedTo"
												? "text-primary"
												: "text-grey-900"
												}`}
										>
											Assigned to
										</span>
									) : (
										<>
											<img
												className="mr-1"
												src={avatarPic}
												style={{ width: 20, height: 20 }}
												alt=""
											/>
											<span
												className={`selected-user ${activeScreen === "assignedTo"
													? "text-primary"
													: "text-grey-900"
													}`}
											>
												{selectedUser.name}
											</span>
											<figure
												className="border-radius-20 bg-grey-100 flex justify-center items-center mr-1"
												style={{ width: 22, height: 16 }}
											>
												<span className="text-xs font-semibold text-primary">
													4
												</span>
											</figure>
										</>
									)}
									<figure>
										{openAssign ? (
											<svg
												width="8"
												height="5"
												viewBox="0 0 8 5"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1 3.99651L4 1.00001L7 3.99651"
													stroke="#005C6F"
													stroke-width="1.8"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										) : (
											<svg
												width="8"
												height="5"
												viewBox="0 0 8 5"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M7 1L4 3.9965L1 1"
													stroke="#4C5254"
													stroke-width="1.8"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										)}
									</figure>
								</div>
								<div
									className={`dropdown-menu dropdown-menu--with-border mt-3 ${openAssign ? "open" : ""
										} p-2`}
									style={{
										right: "0px",
										minWidth: "316px",
										top: "15px",
										transition: "all 300ms ease-in",
									}}
								>
									<div style={{ marginTop: 10 }}>
										<InputField
											placeholder="Search"
											value={inputValue}
											onChange={(e) => {
												setInputValue(e.target.value);
											}}
										/>
									</div>
									<div className="mt-4" style={{ marginLeft: 10 }}>
										<p className="text-grey-700 text-xs font-medium">Assign to</p>
									</div>
									<div
										className={`no-hover dropdown-menu__overflow-y-scroll mt-1`}
									>
										{users.map((user, index) => {
											return (
												<div
													key={index}
													className="dropdown-menu__list font-medium"
													style={{ color: "#2B3639" }}
													onClick={() => {
														setOpenAssign(false);
														setSelectedUser(user);
													}}
												>
													<img
														className="mr-3"
														src={avatarPic}
														style={{ width: 26, height: 26 }}
														alt=""
													/>
													{user.name}
													{index === 0 && (
														<span className="ml-1 text-grey-600 text-13 font-medium">
															You
														</span>
													)}
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div
							className={`${openFilterDropDown || selectedFilter !== ""
								? "bg-grey-90"
								: "bg-grey-300"
								} ${selectedFilter === "" ? "px-3" : "px-2"
								} dashboard__main__body__section-left__header--channels-btn`}
						>
							{selectedFilter === "" ? (
								<figure>
									<svg
										width="12"
										height="9"
										viewBox="0 0 12 9"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6.875 7.01299C7.28921 7.01299 7.625 7.36186 7.625 7.79221C7.625 8.19182 7.33547 8.52117 6.96247 8.56619L6.875 8.57143H5.125C4.71079 8.57143 4.375 8.22256 4.375 7.79221C4.375 7.39259 4.66453 7.06324 5.03753 7.01823L5.125 7.01299H6.875ZM9.5 3.50649C9.91421 3.50649 10.25 3.85536 10.25 4.28571C10.25 4.68533 9.96047 5.01468 9.58747 5.05969L9.5 5.06493H2.5C2.08579 5.06493 1.75 4.71607 1.75 4.28571C1.75 3.8861 2.03953 3.55675 2.41253 3.51174L2.5 3.50649H9.5ZM11.25 0C11.6642 0 12 0.348869 12 0.779221C12 1.17883 11.7105 1.50819 11.3375 1.5532L11.25 1.55844H0.75C0.335786 1.55844 0 1.20957 0 0.779221C0 0.379609 0.28953 0.050254 0.662534 0.00524221L0.75 0H11.25Z"
											fill="#4C5254"
										/>
									</svg>
								</figure>
							) : (
								<figure style={{ width: 14, height: 14 }}>
									<img
										src={filter.icon}
										alt="filtered-icon"
										style={{ width: 14, height: 14 }}
									/>
								</figure>
							)}
							{selectedFilter === "" ? (
								<p
									className="text-13 font-medium ml-1"
									onClick={() => {
										setOpenFilterDropDown(!openFilterDropDown);
										setOpenAssign(false);
									}}
								>
									Channels
								</p>
							) : (
								<>
									<p
										className="selected__text"
										onClick={() => {
											setOpenFilterDropDown(!openFilterDropDown);
											setOpenAssign(false);
										}}
									>
										{filter.name}
									</p>
									<figure
										onClick={() => {
											setSelectedFilter("");
											setOpenFilterDropDown(false);
										}}
									>
										<svg
											width="8"
											height="8"
											viewBox="0 0 8 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1 7L7 1"
												stroke="#2B3639"
												stroke-width="1.5"
												stroke-linecap="round"
											/>
											<path
												d="M1 1L7 7"
												stroke="#2B3639"
												stroke-width="1.5"
												stroke-linecap="round"
											/>
										</svg>
									</figure>
								</>
							)}
							<div
								className={`dropdown-menu dropdown-menu--with-border mt-3 ${openFilterDropDown ? "open" : ""
									} p-2`}
								style={{
									right: "0px",
									minWidth: "316px",
									top: "20px",
									transition: "all 300ms ease-in",
								}}
							>
								<div style={{ marginTop: 10 }}>
									<InputField
										placeholder="Search"
										value={inputValue}
										onChange={(e) => {
											setInputValue(e.target.value);
										}}
									/>
								</div>
								<div className="mt-4" style={{ marginLeft: 10 }}>
									<p className="text-grey-700 text-xs font-medium">Channels</p>
								</div>
								<div className={`no-hover dropdown-menu__overflow-y-scroll mt-1`}>
									{channels.map((user, index) => {
										return (
											<div
												key={index}
												className="dropdown-menu__list font-medium"
												style={{ color: "#2B3639" }}
												onClick={() => {
													setFilter(user);
													setSelectedFilter(user.name);
													setOpenFilterDropDown(!openFilterDropDown);
												}}
											>
												<img
													className="mr-3"
													src={user.icon}
													style={{ width: 26, height: 26 }}
													alt=""
												/>
												{user.name}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>

					<div className="dashboard__main__body__section-left__body flex flex-col overflow-y-auto">
						<ul>
							<li className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm">
								<div className="flex items-center gap-4 flex-1 truncate">
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 48,
											height: 48,
										}}
									>
										<img
											src={iconAvatar}
											alt=""
											style={{ width: 48, height: 48 }}
										/>
										<div
											className="avatar-container__sup-icon--bottom-0 bg-white absolute flex justify-center items-center"
											style={{ width: 20, height: 20 }}
										>
											<svg
												width="12"
												height="12"
												viewBox="0 0 12 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M0 5.8143C0 2.47356 2.61972 0 6 0C9.38028 0 12 2.47356 12 5.81427C12 9.15498 9.38028 11.6285 6 11.6285C5.39277 11.6285 4.80966 11.549 4.26279 11.3982C4.15655 11.3692 4.04357 11.3778 3.94287 11.4223L2.75253 11.9469C2.44104 12.0832 2.08974 11.8625 2.07888 11.5224L2.04627 10.4563C2.04144 10.3248 1.98231 10.203 1.88451 10.115C0.71709 9.07176 0 7.5618 0 5.8143ZM4.16016 4.72161L2.3976 7.51476C2.22735 7.78251 2.55816 8.08524 2.81046 7.89348L4.70463 6.45708C4.76698 6.40994 4.84297 6.38433 4.92113 6.38412C4.9993 6.3839 5.07543 6.40908 5.13804 6.45588L6.53964 7.50633C6.95976 7.82112 7.56096 7.71015 7.84104 7.26633L9.6024 4.47438C9.77265 4.20666 9.44184 3.90393 9.18954 4.09566L7.29657 5.53086C7.23422 5.578 7.15823 5.60361 7.08006 5.60382C7.0019 5.60404 6.92577 5.57886 6.86316 5.53206L5.46156 4.48161C5.04144 4.16682 4.44024 4.27779 4.16016 4.72161Z"
													fill="url(#paint0_linear_12699_461)"
												/>
												<defs>
													<linearGradient
														id="paint0_linear_12699_461"
														x1="6"
														y1="0"
														x2="6"
														y2="11.9879"
														gradientUnits="userSpaceOnUse"
													>
														<stop stop-color="#00B2FF" />
														<stop offset="1" stop-color="#006AFF" />
													</linearGradient>
												</defs>
											</svg>
										</div>
									</figure>
									<div className="truncate pr-4">
										<h3
											className="chats-preview__title"
											style={{ fontWeight: 600 }}
										>
											John Leon
										</h3>
										<p
											className="chats-preview__msg"
											style={{ color: "#2B3639", fontWeight: 400 }}
										>
											You are now connected wedding cha...
										</p>
									</div>
								</div>
								<div className="chats-preview__add-info">
									<label
										className="notification-label notification-label--new-msg"
										style={{
											fontSize: 10,
											paddingTop: 0,
											paddingBottom: 0,
										}}
									>
										New
									</label>
									<p className="chats-preview__time">1m</p>
								</div>
							</li>
							<li className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm">
								<div className="flex items-center gap-4 flex-1 truncate">
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 48,
											height: 48,
										}}
									>
										<img
											src={iconAvatar}
											alt=""
											style={{ width: 48, height: 48 }}
										/>
										<div
											className="avatar-container__sup-icon--bottom-0 bg-white absolute flex justify-center items-center"
											style={{ width: 20, height: 20 }}
										>
											<svg
												width="12"
												height="12"
												viewBox="0 0 12 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M0 5.8143C0 2.47356 2.61972 0 6 0C9.38028 0 12 2.47356 12 5.81427C12 9.15498 9.38028 11.6285 6 11.6285C5.39277 11.6285 4.80966 11.549 4.26279 11.3982C4.15655 11.3692 4.04357 11.3778 3.94287 11.4223L2.75253 11.9469C2.44104 12.0832 2.08974 11.8625 2.07888 11.5224L2.04627 10.4563C2.04144 10.3248 1.98231 10.203 1.88451 10.115C0.71709 9.07176 0 7.5618 0 5.8143ZM4.16016 4.72161L2.3976 7.51476C2.22735 7.78251 2.55816 8.08524 2.81046 7.89348L4.70463 6.45708C4.76698 6.40994 4.84297 6.38433 4.92113 6.38412C4.9993 6.3839 5.07543 6.40908 5.13804 6.45588L6.53964 7.50633C6.95976 7.82112 7.56096 7.71015 7.84104 7.26633L9.6024 4.47438C9.77265 4.20666 9.44184 3.90393 9.18954 4.09566L7.29657 5.53086C7.23422 5.578 7.15823 5.60361 7.08006 5.60382C7.0019 5.60404 6.92577 5.57886 6.86316 5.53206L5.46156 4.48161C5.04144 4.16682 4.44024 4.27779 4.16016 4.72161Z"
													fill="url(#paint0_linear_12699_461)"
												/>
												<defs>
													<linearGradient
														id="paint0_linear_12699_461"
														x1="6"
														y1="0"
														x2="6"
														y2="11.9879"
														gradientUnits="userSpaceOnUse"
													>
														<stop stop-color="#00B2FF" />
														<stop offset="1" stop-color="#006AFF" />
													</linearGradient>
												</defs>
											</svg>
										</div>
									</figure>
									<div className="truncate pr-4">
										<h3
											className="chats-preview__title"
											style={{ fontWeight: 600 }}
										>
											John Leon
										</h3>
										<p
											className="chats-preview__msg"
											style={{ color: "#2B3639", fontWeight: 400 }}
										>
											You are now connected wedding cha...
										</p>
									</div>
								</div>
								<div className="chats-preview__add-info">
									<label
										className="notification-label notification-label--new-msg"
										style={{
											fontSize: 10,
											paddingTop: 0,
											paddingBottom: 0,
										}}
									>
										New
									</label>
									<p className="chats-preview__time">1m</p>
								</div>
							</li>
							<li className="chats-preview chats-preview--reply-received bg-color-on-hover bg-color-on-hover--sm">
								<div className="flex items-center gap-4 flex-1 truncate">
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 48,
											height: 48,
										}}
									>
										<img
											src={iconAvatar}
											alt=""
											style={{ width: 48, height: 48 }}
										/>
										<div
											className="avatar-container__sup-icon--bottom-0 bg-white absolute flex justify-center items-center"
											style={{ width: 20, height: 20 }}
										>
											<svg
												width="12"
												height="12"
												viewBox="0 0 12 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M0 5.8143C0 2.47356 2.61972 0 6 0C9.38028 0 12 2.47356 12 5.81427C12 9.15498 9.38028 11.6285 6 11.6285C5.39277 11.6285 4.80966 11.549 4.26279 11.3982C4.15655 11.3692 4.04357 11.3778 3.94287 11.4223L2.75253 11.9469C2.44104 12.0832 2.08974 11.8625 2.07888 11.5224L2.04627 10.4563C2.04144 10.3248 1.98231 10.203 1.88451 10.115C0.71709 9.07176 0 7.5618 0 5.8143ZM4.16016 4.72161L2.3976 7.51476C2.22735 7.78251 2.55816 8.08524 2.81046 7.89348L4.70463 6.45708C4.76698 6.40994 4.84297 6.38433 4.92113 6.38412C4.9993 6.3839 5.07543 6.40908 5.13804 6.45588L6.53964 7.50633C6.95976 7.82112 7.56096 7.71015 7.84104 7.26633L9.6024 4.47438C9.77265 4.20666 9.44184 3.90393 9.18954 4.09566L7.29657 5.53086C7.23422 5.578 7.15823 5.60361 7.08006 5.60382C7.0019 5.60404 6.92577 5.57886 6.86316 5.53206L5.46156 4.48161C5.04144 4.16682 4.44024 4.27779 4.16016 4.72161Z"
													fill="url(#paint0_linear_12699_461)"
												/>
												<defs>
													<linearGradient
														id="paint0_linear_12699_461"
														x1="6"
														y1="0"
														x2="6"
														y2="11.9879"
														gradientUnits="userSpaceOnUse"
													>
														<stop stop-color="#00B2FF" />
														<stop offset="1" stop-color="#006AFF" />
													</linearGradient>
												</defs>
											</svg>
										</div>
									</figure>
									<div className="truncate pr-4">
										<h3
											className="chats-preview__title"
											style={{ fontWeight: 600 }}
										>
											John Leon
										</h3>
										<p
											className="chats-preview__msg"
											style={{ color: "#2B3639", fontWeight: 400 }}
										>
											You are now connected wedding cha...
										</p>
									</div>
								</div>
								<div className="chats-preview__add-info">
									<label
										className="notification-label notification-label--new-msg"
										style={{
											fontSize: 12,
											paddingTop: 1,
											paddingBottom: 1,
										}}
									>
										2
										<img src={iconReplyArrow} alt="arrow-reply" />
									</label>
									<p className="chats-preview__time">26m</p>
								</div>
							</li>
							<li className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm">
								<div className="flex items-center gap-4 flex-1 truncate">
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 48,
											height: 48,
										}}
									>
										<img
											src={iconAvatar}
											alt=""
											style={{ width: 48, height: 48 }}
										/>
										<div
											className="avatar-container__sup-icon--bottom-0 bg-white absolute flex justify-center items-center"
											style={{ width: 20, height: 20 }}
										>
											<svg
												width="12"
												height="12"
												viewBox="0 0 12 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M0 5.8143C0 2.47356 2.61972 0 6 0C9.38028 0 12 2.47356 12 5.81427C12 9.15498 9.38028 11.6285 6 11.6285C5.39277 11.6285 4.80966 11.549 4.26279 11.3982C4.15655 11.3692 4.04357 11.3778 3.94287 11.4223L2.75253 11.9469C2.44104 12.0832 2.08974 11.8625 2.07888 11.5224L2.04627 10.4563C2.04144 10.3248 1.98231 10.203 1.88451 10.115C0.71709 9.07176 0 7.5618 0 5.8143ZM4.16016 4.72161L2.3976 7.51476C2.22735 7.78251 2.55816 8.08524 2.81046 7.89348L4.70463 6.45708C4.76698 6.40994 4.84297 6.38433 4.92113 6.38412C4.9993 6.3839 5.07543 6.40908 5.13804 6.45588L6.53964 7.50633C6.95976 7.82112 7.56096 7.71015 7.84104 7.26633L9.6024 4.47438C9.77265 4.20666 9.44184 3.90393 9.18954 4.09566L7.29657 5.53086C7.23422 5.578 7.15823 5.60361 7.08006 5.60382C7.0019 5.60404 6.92577 5.57886 6.86316 5.53206L5.46156 4.48161C5.04144 4.16682 4.44024 4.27779 4.16016 4.72161Z"
													fill="url(#paint0_linear_12699_461)"
												/>
												<defs>
													<linearGradient
														id="paint0_linear_12699_461"
														x1="6"
														y1="0"
														x2="6"
														y2="11.9879"
														gradientUnits="userSpaceOnUse"
													>
														<stop stop-color="#00B2FF" />
														<stop offset="1" stop-color="#006AFF" />
													</linearGradient>
												</defs>
											</svg>
										</div>
									</figure>
									<div className="truncate pr-4">
										<h3
											className="chats-preview__title"
											style={{ fontWeight: 600 }}
										>
											John Leon
										</h3>
										<p
											className="chats-preview__msg"
											style={{ color: "#2B3639", fontWeight: 400 }}
										>
											You are now connected wedding cha...
										</p>
									</div>
								</div>
								<div className="chats-preview__add-info">
									<label
										className="notification-label notification-label--new-msg"
										style={{
											fontSize: 10,
											paddingTop: 0,
											paddingBottom: 0,
										}}
									>
										New
									</label>
									<p className="chats-preview__time">1m</p>
								</div>
							</li>
							<div className="mt-5 mb-1">
								<label className="text-xs font-medium text-grey-700 uppercase">
									seen or replied
								</label>
							</div>
							<li
								className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm"
								style={{ borderRadius: 10 }}
							>
								<figure
									className="avatar-container avatar-container--contain bg-white"
									style={{
										width: 48,
										height: 48,
									}}
								>
									<img
										src={iconAvatar}
										alt=""
										style={{ width: 48, height: 48 }}
									/>
									<div
										className="avatar-container__sup-icon--bottom-0 bg-white absolute flex justify-center items-center"
										style={{ width: 20, height: 20 }}
									>
										<svg
											width="12"
											height="12"
											viewBox="0 0 12 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M0 5.8143C0 2.47356 2.61972 0 6 0C9.38028 0 12 2.47356 12 5.81427C12 9.15498 9.38028 11.6285 6 11.6285C5.39277 11.6285 4.80966 11.549 4.26279 11.3982C4.15655 11.3692 4.04357 11.3778 3.94287 11.4223L2.75253 11.9469C2.44104 12.0832 2.08974 11.8625 2.07888 11.5224L2.04627 10.4563C2.04144 10.3248 1.98231 10.203 1.88451 10.115C0.71709 9.07176 0 7.5618 0 5.8143ZM4.16016 4.72161L2.3976 7.51476C2.22735 7.78251 2.55816 8.08524 2.81046 7.89348L4.70463 6.45708C4.76698 6.40994 4.84297 6.38433 4.92113 6.38412C4.9993 6.3839 5.07543 6.40908 5.13804 6.45588L6.53964 7.50633C6.95976 7.82112 7.56096 7.71015 7.84104 7.26633L9.6024 4.47438C9.77265 4.20666 9.44184 3.90393 9.18954 4.09566L7.29657 5.53086C7.23422 5.578 7.15823 5.60361 7.08006 5.60382C7.0019 5.60404 6.92577 5.57886 6.86316 5.53206L5.46156 4.48161C5.04144 4.16682 4.44024 4.27779 4.16016 4.72161Z"
												fill="url(#paint0_linear_12699_461)"
											/>
											<defs>
												<linearGradient
													id="paint0_linear_12699_461"
													x1="6"
													y1="0"
													x2="6"
													y2="11.9879"
													gradientUnits="userSpaceOnUse"
												>
													<stop stop-color="#00B2FF" />
													<stop offset="1" stop-color="#006AFF" />
												</linearGradient>
											</defs>
										</svg>
									</div>
								</figure>
								<div className="ml-4 truncate">
									<h3 className="font-medium text-grey-800">John Leon</h3>
									<div className="flex items-center whitespace-nowrap">
										<p className="text-base-1 text-grey-800 truncate">
											You are now connected wedding lorem ipsum text
										</p>
										<code
											className="flex-shrink-0 bg-grey-500 rounded-full"
											style={{
												width: 4,
												height: 4,
												marginLeft: 10,
												marginRight: 5,
											}}
										></code>
										<span className="text-sm text-grey-700">2d</span>
									</div>
								</div>
							</li>
							<li
								className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm"
								style={{ borderRadius: 10 }}
							>
								<figure
									className="avatar-container avatar-container--contain bg-white"
									style={{
										width: 48,
										height: 48,
									}}
								>
									<img
										src={iconAvatar}
										alt=""
										style={{ width: 48, height: 48 }}
									/>
									<div
										className="avatar-container__sup-icon--bottom-0 bg-white absolute flex justify-center items-center"
										style={{ width: 20, height: 20 }}
									>
										<svg
											width="12"
											height="12"
											viewBox="0 0 12 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M0 5.8143C0 2.47356 2.61972 0 6 0C9.38028 0 12 2.47356 12 5.81427C12 9.15498 9.38028 11.6285 6 11.6285C5.39277 11.6285 4.80966 11.549 4.26279 11.3982C4.15655 11.3692 4.04357 11.3778 3.94287 11.4223L2.75253 11.9469C2.44104 12.0832 2.08974 11.8625 2.07888 11.5224L2.04627 10.4563C2.04144 10.3248 1.98231 10.203 1.88451 10.115C0.71709 9.07176 0 7.5618 0 5.8143ZM4.16016 4.72161L2.3976 7.51476C2.22735 7.78251 2.55816 8.08524 2.81046 7.89348L4.70463 6.45708C4.76698 6.40994 4.84297 6.38433 4.92113 6.38412C4.9993 6.3839 5.07543 6.40908 5.13804 6.45588L6.53964 7.50633C6.95976 7.82112 7.56096 7.71015 7.84104 7.26633L9.6024 4.47438C9.77265 4.20666 9.44184 3.90393 9.18954 4.09566L7.29657 5.53086C7.23422 5.578 7.15823 5.60361 7.08006 5.60382C7.0019 5.60404 6.92577 5.57886 6.86316 5.53206L5.46156 4.48161C5.04144 4.16682 4.44024 4.27779 4.16016 4.72161Z"
												fill="url(#paint0_linear_12699_461)"
											/>
											<defs>
												<linearGradient
													id="paint0_linear_12699_461"
													x1="6"
													y1="0"
													x2="6"
													y2="11.9879"
													gradientUnits="userSpaceOnUse"
												>
													<stop stop-color="#00B2FF" />
													<stop offset="1" stop-color="#006AFF" />
												</linearGradient>
											</defs>
										</svg>
									</div>
								</figure>
								<div className="ml-4 truncate">
									<h3 className="font-medium text-grey-800">Madison Inouye</h3>
									<div className="flex items-center whitespace-nowrap">
										<img src={iconForward} alt="icon-forward" className="mr-2" />
										<p className="text-base-1 text-grey-800 truncate">Hey</p>
										<code
											className="flex-shrink-0 bg-grey-500 rounded-full"
											style={{
												width: 4,
												height: 4,
												marginLeft: 10,
												marginRight: 5,
											}}
										></code>
										<span className="text-sm text-grey-700">2d</span>
									</div>
								</div>
							</li>
							<li
								className="chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm"
								style={{ borderRadius: 10 }}
							>
								<figure
									className="avatar-container avatar-container--contain bg-white"
									style={{
										width: 48,
										height: 48,
									}}
								>
									<img
										src={iconAvatar}
										alt=""
										style={{ width: 48, height: 48 }}
									/>
									<div
										className="avatar-container__sup-icon--bottom-0 bg-white absolute flex justify-center items-center"
										style={{ width: 20, height: 20 }}
									>
										<svg
											width="12"
											height="12"
											viewBox="0 0 12 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M0 5.8143C0 2.47356 2.61972 0 6 0C9.38028 0 12 2.47356 12 5.81427C12 9.15498 9.38028 11.6285 6 11.6285C5.39277 11.6285 4.80966 11.549 4.26279 11.3982C4.15655 11.3692 4.04357 11.3778 3.94287 11.4223L2.75253 11.9469C2.44104 12.0832 2.08974 11.8625 2.07888 11.5224L2.04627 10.4563C2.04144 10.3248 1.98231 10.203 1.88451 10.115C0.71709 9.07176 0 7.5618 0 5.8143ZM4.16016 4.72161L2.3976 7.51476C2.22735 7.78251 2.55816 8.08524 2.81046 7.89348L4.70463 6.45708C4.76698 6.40994 4.84297 6.38433 4.92113 6.38412C4.9993 6.3839 5.07543 6.40908 5.13804 6.45588L6.53964 7.50633C6.95976 7.82112 7.56096 7.71015 7.84104 7.26633L9.6024 4.47438C9.77265 4.20666 9.44184 3.90393 9.18954 4.09566L7.29657 5.53086C7.23422 5.578 7.15823 5.60361 7.08006 5.60382C7.0019 5.60404 6.92577 5.57886 6.86316 5.53206L5.46156 4.48161C5.04144 4.16682 4.44024 4.27779 4.16016 4.72161Z"
												fill="url(#paint0_linear_12699_461)"
											/>
											<defs>
												<linearGradient
													id="paint0_linear_12699_461"
													x1="6"
													y1="0"
													x2="6"
													y2="11.9879"
													gradientUnits="userSpaceOnUse"
												>
													<stop stop-color="#00B2FF" />
													<stop offset="1" stop-color="#006AFF" />
												</linearGradient>
											</defs>
										</svg>
									</div>
								</figure>
								<div className="ml-4 truncate">
									<h3 className="font-medium text-grey-800">John Leon</h3>
									<div className="flex items-center whitespace-nowrap">
										<p className="text-base-1 text-grey-800 truncate">
											You are now connected lorem ipsum
										</p>
										<code
											className="flex-shrink-0 bg-grey-500 rounded-full"
											style={{
												width: 4,
												height: 4,
												marginLeft: 10,
												marginRight: 5,
											}}
										></code>
										<span className="text-sm text-grey-700">2d</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div
					className={`dashboard__main__body__section-right chat-message-container relative`}
				>
					<div className="chat-message-header flex items-center" style={{ maxHeight: "53px" }}>
						<ChatHeader setOpenTabs={setOpenTabs} openTabs={openTabs} />
					</div>
					<div className="chat-message-body">
						<div className="chat-message-list flex">
							<figure
								className="avatar-container avatar-container--full"
								style={{ width: 32, height: 32 }}
							>
								<img src={avatarPic} alt="avatar-pic" />
							</figure>
							<div className="" style={{ marginLeft: 6 }}>
								<div>
									<div
										className="inline-block border border-grey-400 text-base-1 text-grey-900"
										style={{
											borderRadius: 10,
											paddingTop: 6,
											paddingBottom: 6,
											paddingLeft: 15,
											paddingRight: 15,
										}}
									>
										Hi dude...
									</div>
								</div>
								<div>
									<div
										className="inline-block border border-grey-400 text-base-1 text-grey-900"
										style={{
											borderRadius: 10,
											paddingTop: 6,
											paddingBottom: 6,
											paddingLeft: 15,
											paddingRight: 15,
											marginTop: 6,
										}}
									>
										How is your job?
									</div>
								</div>
								<div>
									<div
										className="inline-block border border-grey-400 text-base-1 text-grey-900"
										style={{
											borderRadius: 10,
											paddingTop: 6,
											paddingBottom: 6,
											paddingLeft: 15,
											paddingRight: 15,
											marginTop: 6,
										}}
									>
										Are you doing anything fun this weekend at home?
									</div>
								</div>
								<div>
									<div
										className="text-xs text-grey-700"
										style={{ marginTop: 6 }}
									>
										2d
									</div>
								</div>
							</div>
						</div>
						<div className="chat-message-list chat-message-list--sender flex">
							<figure
								className="avatar-container avatar-container--full"
								style={{ width: 32, height: 32 }}
							>
								<img src={avatarPic} alt="avatar-pic" />
							</figure>
							<div className="" style={{ marginRight: 6 }}>
								<div>
									<div
										className="inline-block  text-base-1 text-grey-900"
										style={{
											borderRadius: 10,
											paddingTop: 6,
											paddingBottom: 6,
											paddingLeft: 15,
											paddingRight: 15,
											backgroundColor: "#F4F9F9",
											border: "1px solid rgba(0, 0, 0, 0.05)",
										}}
									>
										Hi dude...
									</div>
								</div>
								<div>
									<div
										className="inline-block  text-base-1 text-grey-900"
										style={{
											borderRadius: 10,
											paddingTop: 6,
											paddingBottom: 6,
											paddingLeft: 15,
											paddingRight: 15,
											marginTop: 6,
											backgroundColor: "#F4F9F9",
											border: "1px solid rgba(0, 0, 0, 0.05)",
										}}
									>
										How is your job?
									</div>
								</div>
								<div>
									<div
										className="inline-block text-base-1 text-grey-900"
										style={{
											borderRadius: 10,
											paddingTop: 6,
											paddingBottom: 6,
											paddingLeft: 15,
											paddingRight: 15,
											marginTop: 6,
											backgroundColor: "#F4F9F9",
											border: "1px solid rgba(0, 0, 0, 0.05)",
										}}
									>
										Are you doing anything fun this weekend at home?
									</div>
								</div>
								<div>
									<div
										className="text-xs text-grey-700"
										style={{ marginTop: 6 }}
									>
										2d
									</div>
								</div>
							</div>
						</div>
						<div className="chat-message-list flex">
							<figure
								className="avatar-container avatar-container--full"
								style={{ width: 32, height: 32 }}
							>
								<img src={avatarPic} alt="avatar-pic" />
							</figure>
							<div className="" style={{ marginLeft: 6 }}>
								<div>
									<div
										className="inline-block border border-grey-400 text-base-1 text-grey-900"
										style={{
											borderRadius: 10,
											paddingTop: 6,
											paddingBottom: 6,
											paddingLeft: 15,
											paddingRight: 15,
										}}
									>
										Tristique bibendum finibus nec, consectetu.
									</div>
								</div>
								<div>
									<div
										className="text-xs text-grey-700"
										style={{ marginTop: 6 }}
									>
										Now
									</div>
								</div>
							</div>
						</div>
					</div>
					<ChatFooterInputBox />
					<section
						className={`modal modal--contact ${openTabs ? "show" : ""}`}
						style={{ top: 0, height: "100%" }}
					>
						<div className="modal__container">
							<Details
								setShowImageModal={setShowImageModal}
								setOpenUpload={setOpenUpload}
							/>
						</div>
					</section>
					<ImageModal
						image={showImageModal === 1 ? fullImage1 : fullImage2}
						showModal={showImageModal === 0 ? false : true}
						setShowImageModal={setShowImageModal}
						showImageModal={showImageModal}
					/>
					<UploadFile setOpenUpload={setOpenUpload} openUpload={openUpload} />
				</div>
			</div>
		</>
	);
};

export default ToggleEbox;
