import React from "react";
import userIcon2 from "../../assets/icons/usericon2.svg";
import logo from "../../assets/icons/eb-logo.svg";

const PostLogin = () => {
  return (
    <main className="authentication-module post-login">
      <div className="logo flex items-center justify-center mb-8">
        <img src={logo} alt="logo" />
      </div>
      <div className="authentication-module__container">
        <h1 className="text-2xl text-center authentication-module__heading mb-8 xxl:mb-12">
          Select your profile to login
        </h1>
        <ul className="post-login__card-wrapper">
          <li className="post-login__card">
            <div className="post-login__card__container">
              <figure
                className="avatar-container m-auto"
                style={{
                  width: 70,
                  height: 70,
                  "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={userIcon2} alt="" className="" />
              </figure>
              <p className="text-13 font-medium text-center mt-15">
                Madison Inouye
              </p>
            </div>
          </li>
          <li className="post-login__card">
            <div className="post-login__card__container">
              <figure
                className="avatar-container m-auto"
                style={{
                  width: 70,
                  height: 70,
                  "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={userIcon2} alt="" className="" />
              </figure>
              <p className="text-13 font-medium text-center mt-15">
                Madison Inouye
              </p>
            </div>
          </li>
          <li className="post-login__card">
            <div className="post-login__card__container">
              <figure
                className="avatar-container m-auto"
                style={{
                  width: 70,
                  height: 70,
                  "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={userIcon2} alt="" className="" />
              </figure>
              <p className="text-13 font-medium text-center mt-15">
                Madison Inouye
              </p>
            </div>
          </li>
          <li className="post-login__card">
            <div className="post-login__card__container">
              <figure
                className="avatar-container m-auto"
                style={{
                  width: 70,
                  height: 70,
                  "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={userIcon2} alt="" className="" />
              </figure>
              <p className="text-13 font-medium text-center mt-15">
                Madison Inouye
              </p>
            </div>
          </li>
          <li className="post-login__card">
            <div className="post-login__card__container">
              <figure
                className="avatar-container m-auto"
                style={{
                  width: 70,
                  height: 70,
                  "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={userIcon2} alt="" className="" />
              </figure>
              <p className="text-13 font-medium text-center mt-15">
                Madison Inouye
              </p>
            </div>
          </li>
          <li className="post-login__card">
            <div className="post-login__card__container">
              <figure
                className="avatar-container m-auto"
                style={{
                  width: 70,
                  height: 70,
                  "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={userIcon2} alt="" className="" />
              </figure>
              <p className="text-13 font-medium text-center mt-15">
                Madison Inouye
              </p>
            </div>
          </li>
        </ul>
      </div>
    </main>
  );
};
export default PostLogin;
