import React, { useState, useRef } from "react";
import iconGroupChannel from "../assets/icons/Group132462.svg";
import iconGroupCardDesign from "../assets/icons/Group13220.svg";
import iconTeamAccess from "../assets/icons/Group13230.svg";
import iconAutomate from "../assets/icons/Group13224.svg";
import iconBoxForms from "../assets/icons/Group13237.svg";
import iconImportContacts from "../assets/icons/Group13225.svg";
import iconIntegrations from "../assets/icons/Group13229.svg";
import iconBoringStuff from "../assets/icons/Group13245.svg";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import tourVideoPlaceholderPic from "../assets/images/product-tour-placeholder.png";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import iconCloudUpload from "../assets/icons/icon-upload-cloud.svg";
import iconDownload from "../assets/icons/icon-new-download.svg";
import iconUpload from "../assets/icons/icon-upload.svg";
import iconRollBack from "../assets/icons/icon-rollback.svg";
import { Link } from "react-router-dom";
import ProgressBar from "./Common/ProgressBar";
import iconStatusCheck from "../assets/icons/icon-check.svg";

const BoxSettingsUploadingModal = () => {
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });
  const [modalShow, hideModalShow] = useState(true);

  const [type, setType] = useState(false);
  const snackbarRef = useRef(null);

  const handleChange = (event) => {
    snackbarRef.current.show();
    event.target.checked ? setType("success") : setType("fail");
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main">
          <GeneralSettingsHedder text={"General Box Settings"} />
          <div className="dashboard__main__body general-settings">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <ul>
                <li className=" general-settings__card-list bg-color-on-hover bg-color-on-hover--sm active">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconGroupChannel} />
                    </figure>
                    <div className="truncate pr-4">
                      <h2 className="general-settings__card-list__title">
                        Channel Connections
                      </h2>
                      <p className="general-settings__card-list__desc">
                        Channel connect to box
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconGroupCardDesign} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Card design
                      </h3>
                      <p className="general-settings__card-list__desc">
                        design the perfect enquiry card
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconTeamAccess} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Team access
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Manage who has access
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconAutomate} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Automate stuff
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Replies, Moving stuff {"&"} more
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconBoxForms} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Box forms
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Disconnect, Delete, Rename etc
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconImportContacts} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Import contacts
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Import existing contacts to the box
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconIntegrations} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Integrations
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Manage third party Integrations
                      </p>
                    </div>
                  </div>
                </li>
                <li className="general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2">
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <figure className="general-settings__card-list__avatar-container">
                      <img src={iconBoringStuff} />
                    </figure>
                    <div className="truncate pr-4">
                      <h3 className="general-settings__card-list__title">
                        Boring stuff
                      </h3>
                      <p className="general-settings__card-list__desc">
                        Disconnect, Delete, Rename etc
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay  p-10">
              <h2 className="text-2xl font-semibold text-center">
                Box contacts
              </h2>
              <p className="text-grey-800 text-sm text-center mt-3 px-16">
                Download or upload contacts
              </p>
              <div className="mt-8 mx-auto">
                <img src={tourVideoPlaceholderPic} alt="" />
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
                <h3 className="font-semibold">
                  Download all box contacts (456)
                </h3>
                <button className="btn btn--primary btn--sm text-13 w-3/12 gap-1">
                  <img className="" src={iconDownload} alt="" />
                  Download
                </button>
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
                <h3 className="font-semibold">Upload new contacts</h3>
                <button className="btn btn--primary btn--sm text-13 w-3/12 gap-1">
                  <img className="" src={iconUpload} alt="" />
                  Upload
                </button>
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 bg-white mt-10 px-6 py-4">
                <div className="w-full">
                  <h3 className="text-grey-900 font-semibold self-center text-base">
                    Upload history
                  </h3>
                  <table className="tabel-upload-history">
                    <thead></thead>
                    <tbody>
                      <tr className="bg-grey-200">
                        <td>General box.csv</td>
                        <td>256</td>
                        <td>Jun 29, 2022</td>
                        <td>hello</td>
                      </tr>
                    </tbody>
                  </table>
                  <button className="btn btn--sm border border-secondary ml-auto mt-2">
                    <img className="" src={iconRollBack} alt="" />
                    Rollback
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {modalShow && (
        <div className="modal show ">
          <div className="modal__container">
            <div className="modal__header">
              <h2 className="modal__heading pr-5">Upload contacts</h2>
              <div
                className="modal__close-icon"
                onClick={() => hideModalShow(false)}
              >
                <img src={modalCloseIcon} alt="" />
              </div>
            </div>
            <div className="modal__body px-8 pb-10">
              <div className="mt-8 px-8 py-12 border border-dashed border-grey-400 border-radius-14">
                <div className="text-center">
                  <div
                    className="inline-flex items-center justify-center bg-grey-90 rounded-full"
                    style={{ width: 70, height: 70 }}
                  >
                    <img src={iconCloudUpload} alt="icon-cloud-upload" />
                  </div>
                  <h3 className="text-lg font-semibold text-center mt-5">
                    Uploading...
                  </h3>
                </div>
                <div className="text-center">
                  <div
                    className="inline-flex items-center justify-center bg-grey-90 rounded-full"
                    style={{ width: 70, height: 70 }}
                  >
                    <svg width="20" height="20" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M1 5.37637L4.13194 9L11 1"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <h3 className="text-center text-lg font-semibold mt-5">
                    500 Contacts uploaded
                  </h3>
                </div>
                <div className="mt-8">
                  <div className="flex justify-between">
                    <span className="text-sm font-semibold">
                      Contact list.csv
                    </span>
                    <div className="flex items-center gap-3">
                      <span className="text-sm font-medium text-grey-800">
                        8.5 MB
                      </span>
                      <span className="text-sm font-sans text-primary">
                        Cancel
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <ProgressBar progress="20%" />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-3 mt-12">
                <button className="btn btn--grey btn--sm min-w-120">Cancel</button>
                <button className="btn btn--primary btn--sm min-w-120">Next</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default BoxSettingsUploadingModal;
