import React, { useState } from "react";
import contactIcon from "../assets/icons/icon-contact-tab.svg";
import fileIcon from "../assets/icons/icon-file-tab.svg";
import historyIcon from "../assets/icons/icon-history-tab.svg";
import Contacts from "./Contacts";
import Files from "./Files";
import History from "./History";

const Details = ({ setShowImageModal, setOpenUpload }) => {
  const [activeTab, setActiveTab] = useState(2);

  return (
    <>
      <div className="tabs-wrapper tabs-wrapper--contact">
        <div
          className={`tabs-header ${activeTab === 1 ? "tabs-header__active" : ""}`}
          onClick={() => setActiveTab(1)}
        >
          <img src={contactIcon} alt="" />
          <div className="ml-1">Contact</div>
        </div>
        <div
          className={`tabs-header ${activeTab === 2 ? "tabs-header__active" : ""}`}
          onClick={() => setActiveTab(2)}
        >
          <img src={historyIcon} alt="" />
          <div className="ml-1">History</div>
        </div>
        <div
          className={`tabs-header ${activeTab === 3 ? "tabs-header__active" : ""}`}
          onClick={() => setActiveTab(3)}
        >
          <img src={fileIcon} alt="" />
          <div className="ml-1">Files</div>
        </div>
      </div>
      {activeTab === 1 && <Contacts />}
      {activeTab === 2 && <History />}
      {activeTab === 3 && (
        <Files
          setShowImageModal={setShowImageModal}
          setOpenUpload={setOpenUpload}
        />
      )}
    </>
  );
};

export default Details;
