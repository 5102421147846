import React from "react";

//choose positions between "top", "left", "bottom", "right"

const CustomToolTip = ({
  children,
  position = "top",
  tooltipText = "tooltipText",
  tooltipStyle = {},
  img = false
}) => {

  return (
    <div class="custom-tooltip">
      {children}
      {img && <div className={`flex justify-between h-10 tooltiptext w-f-max tooltiptext__${position} `} style={tooltipStyle} >
        <img src={img} alt="user" className="" style={{ width: 30 }} />
        <span className=" flex justify-center text-center text-13 p-1 self-center text-white">{tooltipText}</span>
      </div>}
      {!img && <span
        class={`tooltiptext tooltiptext__${position} text-13 text-grey-600`}
        style={tooltipStyle}
      >
        {tooltipText}
      </span>}
    </div>
  );
};

export default CustomToolTip;
