import React, { useState } from "react";
import { Link } from "react-router-dom";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import Header from "./Header";
import ModalConnectChannel from "./modal/ModalConnectChannel";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from './Common/GeneralSettingsHedder';
import DMInbox from "./Common/DMInbox";
import { useHistory } from "react-router-dom";
import ChatPreviewList from "./ChatPreviewList";
import ChatPreviewListSample from "./ChatPreviewListSample";
import HelperVideo from "./Common/HelperVideo";
import ChannelConnectLinks from "./Common/ChannelConnectLinks";
import mobileViewIcon from "../assets/icons/mobileView.svg";
import iconUserRound from "../assets/icons/icon-user-round.svg";
import qrcodeIcon from "../assets/icons/qrcode.svg";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";

const ChannelWhatapp = () => {
  // const [modalShow, hideModalShow] = useState(true);
  let history = useHistory();
  const [isActive, setIsActive] = useState(1);
  const [activeScreen, setActiveScreen] = useState(1);

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew />
        <section className="dashboard__main">
          <GeneralSettingsHedder text="Channel Settings" />
          <div className="dashboard__main__body" style={{ height: 'calc(100% - 79px)' }} >
            <div className="dashboard__main__body__section-left overflow-overlay">
              <ChannelConnectLinks
                show={true}
                activeScreen={3}
                setActiveScreen={setActiveScreen} />
            </div>
            <div
              className="dashboard__main__body__section-right layout-1"
              id="rightSide"
              style={{ padding: '35px 2.3%' }}
            >
              <div className="card-container--new w-full flex m-auto" style={{ "max-width": '935px' }}>
                <div style={{ width: '60%', paddingRight: '20px' }}>
                  <div>
                    <h2 className="font-semibold text-lg">
                      Connect a WhatsApp account
                    </h2>
                    <p className="text-grey-800 text-sm mt-2">
                      By connecting a whats app account your will beable to
                      receive and respond to messages right within your
                      enquirybox.
                    </p>
                    <p className="text-base-1 font-semibold mt-5">
                      To connect, watch the video on the right for the device
                      you use WhatsApp on. Then scan the QR Code to connect your
                      account.
                    </p>
                  </div>
                  <figure className="card-container--mobile flex items-center justify-center bg-grey-90 border-radius-14 p-8 mt-5">
                    <img src={qrcodeIcon} alt="" className="cursor-pointer" />
                  </figure>
                  <div className="mt-8">
                    <h5 className="text-sm font-semibold text-red-900">Important to note</h5>
                    <p className="text-grey-800 text-13 mt-2">To maintain the connection with your enquirybox, do not remove the linked device in WhatsApp and make sure to use the connected phone at least once every 14 days.</p>
                  </div>
                </div>
                <div className="card-container--new--design" style={{ width: '40%' }}>
                  <div className="flex justify-center">
                    <div className="header-toggle" style={{ width: "200px" }}>
                      <div
                        className={`header-toggle__switch ${isActive === 1 ? "header-toggle__switch--active" : ""
                          }`}
                        onClick={() => setIsActive(1)}
                        style={{ paddingLeft: 29 }}
                      >
                        {isActive === 1 ? (
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.56297 4.13885C7.12712 4.13885 6.44809 3.63125 5.74249 3.63125C4.80435 3.63125 3.94594 4.20463 3.46225 5.04577C2.48956 6.73469 3.21177 9.2302 4.16054 10.6029C4.62629 11.2713 5.17509 12.0234 5.90328 11.9995C6.60157 11.9696 6.86401 11.5457 7.71179 11.5457C8.5536 11.5457 8.79212 11.9994 9.53227 11.9815C10.2844 11.9696 10.7621 11.3012 11.2212 10.6268C11.7527 9.85076 11.9733 9.09865 11.9853 9.05679C11.9673 9.05081 10.5229 8.49603 10.505 6.82439C10.493 5.4278 11.6451 4.75941 11.6989 4.72951C11.0425 3.76878 10.0339 3.66115 9.68176 3.63723C8.76222 3.56547 7.99284 4.13885 7.56297 4.13885Z"
                              stroke="white"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.9483 0.454568C9.52565 0.239618 9.90332 0.55654 9.79184 1.16244C9.68037 1.76833 9.12197 2.43376 8.54462 2.64871C7.96727 2.86366 7.58961 2.54674 7.70108 1.94084C7.81255 1.33494 8.37096 0.669517 8.9483 0.454568Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.56294 4.13893C5.12709 4.13893 4.44807 3.63132 3.74247 3.63132C2.80432 3.63132 1.94591 4.2047 1.46222 5.04584C0.48953 6.73476 1.21174 9.23028 2.16051 10.6029C2.62626 11.2713 3.17506 12.0234 3.90325 11.9995C4.60154 11.9696 4.86398 11.5457 5.71177 11.5457C6.55357 11.5457 6.79209 11.9995 7.53224 11.9816C8.28435 11.9696 8.76206 11.3012 9.22116 10.6269C9.75269 9.85083 9.97327 9.09872 9.98523 9.05687C9.96729 9.05089 8.52287 8.49611 8.50493 6.82446C8.49297 5.42788 9.64505 4.75948 9.69887 4.72959C9.04244 3.76885 8.03387 3.66122 7.68173 3.6373C6.76219 3.56554 5.99281 4.13893 5.56294 4.13893Z" stroke="#828282" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.9483 0.45443C7.52564 0.23948 7.90331 0.556402 7.79184 1.1623C7.68036 1.76819 7.12196 2.43362 6.54461 2.64857C5.96726 2.86352 5.5896 2.5466 5.70107 1.9407C5.81255 1.33481 6.37095 0.669379 6.9483 0.45443" fill="#4C5254" />
                          </svg>
                        )}
                        <span
                          className={`text-sm ml-1 font-medium ${isActive === 1 ? "text-white" : "text-grey-800"
                            }`}
                        >
                          iOS
                        </span>
                      </div>
                      <div
                        className={`header-toggle__switch ${isActive === 2 ? "header-toggle__switch--active" : ""
                          }`}
                        onClick={() => setIsActive(2)}
                      >
                        {isActive === 2 ? (
                          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.43335 1L5.33335 1.9" stroke="#ffffff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.23325 1L8.33325 1.9" stroke="#ffffff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.76673 11.1997H3.90007C3.53207 11.1997 3.2334 10.901 3.2334 10.533V5.19971H10.4334V10.533C10.4334 10.901 10.1347 11.1997 9.76673 11.1997Z" stroke="#ffffff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.4334 5.2001C10.4334 3.2121 8.8214 1.6001 6.8334 1.6001C4.8454 1.6001 3.2334 3.2121 3.2334 5.2001" fill="#ffffff" />
                            <path d="M10.4334 5.2001C10.4334 3.2121 8.8214 1.6001 6.8334 1.6001C4.8454 1.6001 3.2334 3.2121 3.2334 5.2001" stroke="#ffffff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.1667 5.1665V8.83317" stroke="#ffffff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1.5 5.1665V8.83317" stroke="#ffffff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.03345 11.1997V12.9997" stroke="#ffffff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.6333 11.1997V12.9997" stroke="#ffffff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        ) : (
                          <svg
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.43335 1L5.33335 1.9"
                              stroke="#4C5254"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.23325 1L8.33325 1.9"
                              stroke="#4C5254"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.76673 11.1997H3.90007C3.53207 11.1997 3.2334 10.901 3.2334 10.533V5.19971H10.4334V10.533C10.4334 10.901 10.1347 11.1997 9.76673 11.1997Z"
                              stroke="#4C5254"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.4334 5.2001C10.4334 3.2121 8.8214 1.6001 6.8334 1.6001C4.8454 1.6001 3.2334 3.2121 3.2334 5.2001"
                              fill="#4C5254"
                            />
                            <path
                              d="M10.4334 5.2001C10.4334 3.2121 8.8214 1.6001 6.8334 1.6001C4.8454 1.6001 3.2334 3.2121 3.2334 5.2001"
                              stroke="#4C5254"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.1667 5.1665V8.83317"
                              stroke="#4C5254"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1.5 5.1665V8.83317"
                              stroke="#4C5254"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.03345 11.1997V12.9997"
                              stroke="#4C5254"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.6333 11.1997V12.9997"
                              stroke="#4C5254"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                        <span
                          className={`text-sm ml-1 font-medium ${isActive === 2 ? "text-white" : "text-grey-800"
                            }`}
                        >
                          Android
                        </span>
                      </div>
                    </div>
                  </div>
                  <img src={mobileViewIcon} alt="mobile-view" className="p-5" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* <ModalConnectChannel /> */}
    </main>
  );
};

export default ChannelWhatapp;
