import React from 'react'

const NotificationWidget = ({icon, color, text, count, bgColor, border}) => {
	return (
		<div className='notification-widget flex items-center cursor-pointer' style={{background: bgColor, border: border}}>
			<img src={icon} alt='' style={{width: 16, height: 16}}/>
			<span className='text-xs font-semibold ml-1' style={{color: color}}>{text}</span>
			<div className="text-xs flex justify-center text-white ml-2 font-semibold" style={{
				width: 26,
				height: 18,
				borderRadius: "20px",
				backgroundColor: color
			}}>{count}</div>
		</div>
	)
}

export default NotificationWidget