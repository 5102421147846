import React, { useState } from "react";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import iconEmojiFire from "../assets/icons/icon-emoji-fire.svg";
import iconRocket from "../assets/icons/icon-emoji-rocket.svg";
import suitCase from "../assets/icons/suitcase.svg";
import iconTTBat from "../assets/icons/icon-tt-bat.svg";

const AddSingleContact = ({ hideModalShow, modalShow, setShowUploadContactModal }) => {
  const [showDropdown, setShowdropdown] = useState({
    box: false,
    stage: false,
  });
  return (
    <div className="modal show modal--upload-contact">
      <div className="modal__container">
        <div className="modal__header">
          <h2 className="modal__heading pr-5">Add single contact</h2>
          <div
            className="modal__close-icon"
						onClick={() => {
							setShowUploadContactModal(false)
							hideModalShow({
								addSingelContact: !modalShow.addSingelContact,
								contactSetup: modalShow.contactSetup,
							})
							}
						}
          >
            <img src={modalCloseIcon} alt="" />
          </div>
        </div>
        <div className="modal__body" style={{ padding: "25px 30px 30px" }}>
          <div className="">
            <div className="flex gap-3">
              <div className="w-full">
                <label className="input-field-label" style={{ fontSize: 14 }}>
                  First Name
                </label>
                <input
                  className="input-field input-field--md"
                  placeholder="First Name"
                />
              </div>
              <div className="w-full">
                <label className="input-field-label" style={{ fontSize: 14 }}>
                  Last Name
                </label>
                <input
                  className="input-field input-field--md"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="mt-6">
              <label className="input-field-label" style={{ fontSize: 14 }}>
                Email
              </label>
              <input
                className="input-field input-field--md"
                placeholder="Type email address"
              />
            </div>
            <div className="mt-6">
              <label className="input-field-label" style={{ fontSize: 14 }}>
                Phone
              </label>
              <input
                className="input-field input-field--md"
                placeholder="Phone"
              />
            </div>
            <div className="relative mt-3">
              <label className="input-field-label">Box</label>
              <input
                className="input-field input-field--md input-field--select"
                placeholder="Select box to add contact to"
                onClick={() =>
                  setShowdropdown({
                    box: !showDropdown.box,
                    stage: showDropdown.stage ? false : showDropdown.stage,
                  })
                }
              />
              <div
                className={`dropdown-menu dropdown-menu--with-border ${
                  showDropdown.box ? "open" : ""
                } py-2 px-2`}
                style={{
                  top: "calc(100% + 4px)",
                  maxHeight: 250,
                  overflowY: "auto",
                }}
              >
                <div className="dropdown-menu__list gap-2">
                  <img src={suitCase} alt="icon-emoji-fire" />
                  <span className="text-base-1 font-medium text-grey-900">
                    Sales
                  </span>
                </div>
                <div className="dropdown-menu__list gap-2 selected">
                  <img src={iconTTBat} alt="icon-emoji-fire" />
                  <span className="text-base-1 font-medium text-grey-900">
                    General
                  </span>
                </div>
                <div className="dropdown-menu__list gap-2">
                  <img src={iconEmojiFire} alt="icon-emoji-fire" />
                  <span className="text-base-1 font-medium text-grey-900">
                    Potential Leads
                  </span>
                  <span className="ml-auto invisible">icon-tick</span>
                </div>
                <div className="dropdown-menu__list gap-2">
                  <img src={iconRocket} alt="icon-rocket" />
                  <span className="text-base-1 font-medium text-grey-900">
                    Marketing Leads
                  </span>
                  <span className="ml-auto invisible">icon-tick</span>
                </div>
                <div className="dropdown-menu__list gap-2">
                  <img src={iconRocket} alt="icon-rocket" />
                  <span className="text-base-1 font-medium text-grey-900">
                    Product Enquiries
                  </span>
                  <span className="ml-auto invisible">icon-tick</span>
                </div>
              </div>
            </div>
            <div className="relative mt-3">
              <label className="input-field-label">Stage</label>
              <input
                className="input-field input-field--md input-field--select"
                placeholder="Select the stage to add the contact to"
                onClick={() =>
                  setShowdropdown({
                    box: showDropdown.box,
                    stage: !showDropdown.stage,
                  })
                }
              />
              <div
                className={`dropdown-menu dropdown-menu--with-border ${
                  showDropdown.stage ? "open" : ""
                } py-2 px-2`}
                style={{
                  top: "calc(100% + 4px)",
                  maxHeight: 250,
                  overflowY: "auto",
                }}
              >
                <div className="dropdown-menu__list gap-2">
                  <span className="text-base-1 font-medium text-grey-900">
                    New
                  </span>
                </div>
                <div className="dropdown-menu__list gap-2 selected">
                  <span className="text-base-1 font-medium text-grey-900">
                    Contacting
                  </span>
                </div>
                <div className="dropdown-menu__list gap-2">
                  <span className="text-base-1 font-medium text-grey-900">
                    Won
                  </span>
                </div>
                <div className="dropdown-menu__list gap-2">
                  <span className="text-base-1 font-medium text-grey-900">
                    Lost
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <label className="input-field-label" style={{ fontSize: 14 }}>
                Note
              </label>
              <textarea
                className="input-field input-field--md resize-none"
                rows="4"
                cols="10"
                placeholder="Add note"
              ></textarea>
            </div>
            <div
              className="flex items-center justify-end gap-3"
              style={{ marginTop: 60 }}
            >
              <button className="btn btn--grey btn--sm min-w-120">Cancel</button>
              <button className="btn btn--primary btn--sm min-w-120">Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSingleContact;
