import React, { useState } from 'react'
import EnquiryAssistantNavLinks from './Common/EnquiryAssistantNavLinks'
import SidebarExpanded from './Common/SidebarExpanded'
import SidebarLinks from './Common/SidebarLinks'

const EnquiryAssistantSettingsNudges = () => {
	const [openDropDown,setOpenDropDown] = useState(false);
	const [openDropdown,setOpenDropdown] = useState(false)
	const [selectedValue,setSelectedValue] = useState('Hours')
	const [selectedValue2,setSelectedValue2] = useState('Hours')
	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />
				<section className="dashboard__main">
					<div className="dashboard__main__header">
						<h3 className="font-semibold text-2xl">Enquiry assistant settings</h3>
					</div>
					<div className="dashboard__main__body" style={{height: 'calc(100% - 83px)'}}>
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<EnquiryAssistantNavLinks activeScreen={3} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay  p-10">
							<div>
								<h2 className="text-2xl font-semibold text-center">
									Nudges
								</h2>
								<p className="text-grey-800 text-15 text-center mt-3">
									Change what and when your get nudged
								</p>
							</div>
							<div className='mt-6 card-box py-5 px-6'>
								<h3 className='font-semibold text-lg'>Nudges</h3>
								<p className='text-sm text-grey-800 mt-1'>A nudge is a little reminder from your enquiry assistant to do something.</p>
								<div className='border border-grey-400 border-radius-10 p-4 mt-8'>
									<h3 className='font-semibold'>If an enquiry in a channel or box</h3>
									<div className='border-radius-10 bg-grey-300 pt-3 px-4 pb-4 mt-3'>
										<div className='flex justify-between'>
											<h3 className='text-sm'>Hasn’t been assigned to anyone in... </h3>
											<div className="toggle-slider">
												<input
													type="checkbox"
													id="check3"
													className="toggle-slider__checkbox"
													name="check3"
												/>
												<label
													htmlFor="check3"
													className="toggle-slider__label"
												>
													<code className="toggle-slider__ball toggle-slider__ball__small"></code>
												</label>
											</div>
										</div>
										<div className='flex mt-3'>
											<div>
												<input type='number' className='input-field input-field--xs-32 text-15 mr-2' style={{ width: 74, fontWeight: 400 }} />
											</div>
											<div className="relative" onClick={() => setOpenDropDown(!openDropDown)}>
												<input
													className="input-field input-field--select--sm input-field--xs-32 text-15 font-normal"		
													value={selectedValue}				
													style={{ width: 124, fontWeight: 400 }}					
												/>
												<div className={`dropdown-menu ${openDropDown ? 'open' : ''}`}>
													<div className="dropdown-menu__list mx-1 mt-1" onClick={() => setSelectedValue('Hours')}>Hours</div>
													<div className="dropdown-menu__list mx-1" onClick={() => setSelectedValue('Minutes')}>Minutes</div>
													<div className="dropdown-menu__list mx-1 mb-1" onClick={() => setSelectedValue('Days')}>Days</div>								
												</div>
											</div>											
										</div>
									</div>
									<div className='border-radius-10 bg-grey-300 pt-3 px-4 pb-4 mt-2'>
										<div className='flex justify-between'>
											<h3 className='text-sm'>Hasn’t been replied to in...</h3>
											<div className="toggle-slider">
												<input
													type="checkbox"
													id="check4"
													className="toggle-slider__checkbox"
													name="check4"
												/>
												<label
													htmlFor="check4"
													className="toggle-slider__label"
												>
													<code className="toggle-slider__ball toggle-slider__ball__small"></code>
												</label>
											</div>
										</div>
										<div className='flex mt-3'>
											<div>
												<input type='number' className='input-field input-field--xs-32 text-15 mr-2' style={{ width: 74, fontWeight: 400 }} />
											</div>
											<div className="relative" onClick={() => setOpenDropdown(!openDropdown)}>
												<input
													className="input-field input-field--select--sm input-field--xs-32 text-15 font-normal"		
													value={selectedValue2}				
													style={{ width: 124, fontWeight: 400 }}					
												/>
												<div className={`dropdown-menu ${openDropdown ? 'open' : ''}`}>
													<div className="dropdown-menu__list mx-1 mt-1" onClick={() => setSelectedValue2('Hours')}>Hours</div>
													<div className="dropdown-menu__list mx-1" onClick={() => setSelectedValue2('Minutes')}>Minutes</div>
													<div className="dropdown-menu__list mx-1 mb-1" onClick={() => setSelectedValue2('Days')}>Days</div>								
												</div>
											</div>											
										</div>
									</div>
									<div className='border border-grey-400 rounded-xl p-4 mt-6'>
										<div className='flex justify-between'>
											<h3 className='font-semibold'>Nudge me to move enquiry stages in boxes</h3>
											<div className="toggle-slider">
												<input
													type="checkbox"
													id="check4"
													className="toggle-slider__checkbox"
													name="check4"
												/>
												<label
													htmlFor="check4"
													className="toggle-slider__label"
												>
													<code className="toggle-slider__ball toggle-slider__ball__small"></code>
												</label>
											</div>
										</div>								
										<p className='text-sm text-grey-800 mt-2'>This is a handy little helper to help you keep track of your enquiries</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default EnquiryAssistantSettingsNudges