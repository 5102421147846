import React, { useState } from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import fileLibraryIcon from "../../assets/icons/icon-file-library.svg";
import fileUploadIcon from "../../assets/icons/icon-file-upload.svg";
import arrowIcon from "../../assets/icons/icon-right-arrow.svg";
import arrowIconColor from "../../assets/icons/icon-right-arrow-color.svg";

const AttachFiles = ({
  setShowFileUpload,
  setShowFileLibrary,
  setShowAttachFiles,
  showAttachFiles,
}) => {
  return (
    <div
      className={`modal modal--attach-files ${showAttachFiles ? "show" : ""}`}
    >
      <div className="modal__container">
        <div className="modal__header">
          <h2 className="modal__heading pr-5">Attach files</h2>
          <div className="modal__close-icon">
            <img
              src={modalCloseIcon}
              alt=""
              onClick={() => setShowAttachFiles(false)}
            />
          </div>
        </div>
        <div className="modal__body p-8">
          <ul>
            <li
              className="attach-type-list"
              onClick={() => setShowFileLibrary(true)}
            >
              <img src={fileLibraryIcon} alt="" />
              <p className="font-semibold ml-5">From file library</p>
              <div className="icon-arrow-container">
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                  <path
                    d="M6.77686 1.21582L10.498 4.94131L6.77686 8.6668"
                    stroke="#908F8D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 4.93945L1 4.93945"
                    stroke="#908F8D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </li>
            <li
              className="attach-type-list"
              onClick={() => setShowFileUpload(true)}
            >
              <img src={fileLibraryIcon} alt="" />
              <p className="font-semibold ml-5">Upload new file</p>
              <div className="icon-arrow-container">
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                  <path
                    d="M6.77686 1.21582L10.498 4.94131L6.77686 8.6668"
                    stroke="#908F8D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 4.93945L1 4.93945"
                    stroke="#908F8D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AttachFiles;
