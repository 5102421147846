import React, { useState } from "react";
import GlobelNavLinks from "./Common/GlobelNavlinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import DefaultProfile from "../assets/icons/icon-default-profile.png";
import iconGoogle from "../assets/icons/icon-gmail.svg";
import iconEmail from "../assets/icons/icon-email.svg";
import iconEyeClose from "../assets/icons/icon-eye-close.svg";
import iconMicrosoft from "../assets/icons/icon-microsoft.svg";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import iconGotoAccount from "../assets/icons/icon-goto-account.svg";
import { useHistory } from "react-router-dom";

const TwoFactorAuthentication = (isrequest = false) => {
  const [activeScreen, setActiveScreen] = useState(1);
  const [modalVerifyAuthSetup, hideModalVerifyAuthSetup] = useState(false);
  let history = useHistory();

  const nextScreen = () => {
    history.push("/scan-qr-code");
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main">
          <div className="dashboard__main__header">
            <div className="flex items-center gap-5">
              <figure
                className="avatar-container avatar-container--no-box-shadow avatar-container--bg-grey-400"
                style={{ width: 52, height: 52 }}
              >
                <span className="text-2xl font-semibold">S</span>
              </figure>
              <h1 className="text-2xl font-semibold">
                Smiles Dental Group - Settings
              </h1>
            </div>
            <div className="ml-auto">
              <button
                className="btn btn--primary text-13"
                style={{ padding: "5px 14px" }}
              >
                <img
                  src={iconGotoAccount}
                  alt="icon-goto-account"
                  className="mr-2"
                />
                Go to account
              </button>
            </div>
          </div>
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay">
              <GlobelNavLinks
                setActiveScreen={setActiveScreen}
                activeScreen={0}
              />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay p-10">
              <div className="text-center">
                <h2 className="text-2xl font-semibold">My Profile</h2>
                <p className="text-base-1 text-grey-800 mt-2">
                  Login details, email signature
                </p>
              </div>
              <div className="card-box py-5 px-6 mt-6">
                <h3 className="text-lg font-semibold">
                  Users name & Profile picture
                </h3>
                <div className="flex mt-6">
                  <div className="file-upload-profile">
                    <input
                      type="file"
                      id="fileUpload"
                      className="file-upload__input"
                    />
                    <label
                      for="fileUpload"
                      className="file-upload-profile__container"
                    >
                      <img src={DefaultProfile} alt="icon-default-profile" />
                      <div className="file-upload-profile__icon-wrapper hidden">
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 3.08093C0 1.97636 0.895431 1.08093 2 1.08093H3L3.40673 0.568005C3.69126 0.209188 4.12412 0 4.58206 0H7.41794C7.87588 0 8.30874 0.209188 8.59327 0.568005L9 1.08093H10C11.1046 1.08093 12 1.97636 12 3.08093V7.85714C12 8.96171 11.1046 9.85714 10 9.85714H2C0.895431 9.85714 0 8.96171 0 7.85714V3.08093ZM6 3.85714C5.05323 3.85714 4.28571 4.62465 4.28571 5.57143C4.28571 6.5182 5.05323 7.28571 6 7.28571C6.94677 7.28571 7.71429 6.5182 7.71429 5.57143C7.71429 4.62465 6.94677 3.85714 6 3.85714ZM3.42857 5.57143C3.42857 4.15127 4.57984 3 6 3C7.42016 3 8.57143 4.15127 8.57143 5.57143C8.57143 6.99159 7.42016 8.14286 6 8.14286C4.57984 8.14286 3.42857 6.99159 3.42857 5.57143ZM9.42857 3C9.19188 3 9 3.19188 9 3.42857C9 3.66526 9.19188 3.85714 9.42857 3.85714H10.2857C10.5224 3.85714 10.7143 3.66526 10.7143 3.42857C10.7143 3.19188 10.5224 3 10.2857 3H9.42857Z"
                            fill="#005C6F"
                          />
                        </svg>
                      </div>
                      <div className="file-upload-profile__icon-wrapper">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M9.23891 4.35127L11.6488 6.76154L5.97401 12.4363C5.92042 12.4899 5.85543 12.5308 5.78389 12.5558L3.17395 13.4688C2.91098 13.5608 2.62322 13.4222 2.53122 13.1592C2.49349 13.0514 2.4935 12.9339 2.53124 12.826L3.44431 10.2165C3.46934 10.145 3.51016 10.08 3.56375 10.0264L9.23891 4.35127ZM12.547 2.74018L13.26 3.45327C13.6974 3.89066 13.7265 4.58169 13.3474 5.05287L13.2599 5.15029L12.1868 6.22319L9.77652 3.81325L10.85 2.74003C11.3187 2.27149 12.0784 2.27156 12.547 2.74018Z"
                            fill="#005C6F"
                          />
                        </svg>
                      </div>
                    </label>
                  </div>
                  <div className="ml-8">
                    <h4 className="text-base-1 font-semibold">User Name</h4>
                    <p className="text-sm text-grey-700 mt-1">
                      This is the name your team members will see
                    </p>
                    <input
                      type="text"
                      className="input-field input-field--md-38 mt-3"
                      placeholder="Type user name"
                    />
                  </div>
                </div>
              </div>
              <div className="card-box py-5 px-6 mt-6">
                <h3 className="text-lg font-semibold">How you login</h3>
                <ul className="mt-10">
                  <li className="list-hover">
                    <div className="list-hover__container">
                      <figure className="list-hover__avatar">
                        <img
                          src={iconGoogle}
                          alt=""
                          style={{ width: 24, height: 24 }}
                        />
                      </figure>
                      <div className="ml-4">
                        <h4 className="text-base-1 font-semibold">
                          Login with Google
                        </h4>
                        <p className="text-sm text-grey-700 mt-1">
                          You are using your Google account{" "}
                          <span className="text-primary font-medium">
                            (jonkinger86@gmail.com)
                          </span>{" "}
                          to log in
                        </p>
                      </div>
                    </div>
                    <div
                      className="absolute left-0 bg-secondary text-white text-11 font-medium rounded-t-lg"
                      style={{ padding: "2px 10px", top: -20 }}
                    >
                      {" "}
                      You're using this
                    </div>
                  </li>
                  <li className="list-hover">
                    <div className="list-hover__container">
                      <figure className="list-hover__avatar">
                        <img
                          src={iconMicrosoft}
                          alt=""
                          style={{ width: 24, height: 24 }}
                        />
                      </figure>
                      <div className="ml-4">
                        <h4 className="text-base-1 font-semibold">
                          Login with Microsoft
                        </h4>
                        <p className="text-sm text-grey-700 mt-1">
                          You are using your Microsoft account{" "}
                          <span className="text-primary font-medium">
                            (jonkinger86@outlook.com)
                          </span>{" "}
                          to log in
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="list-hover">
                    <div className="list-hover__container">
                      <figure className="list-hover__avatar">
                        <img
                          src={iconEmail}
                          alt=""
                          style={{ width: 24, height: 21 }}
                        />
                      </figure>
                      <div className="ml-4">
                        <h4 className="text-base-1 font-semibold">
                          Your Email & Password
                        </h4>
                        <p className="text-sm text-grey-700 mt-1">
                          You'll be prompted to set up a password
                        </p>
                        <button
                          className="btn btn--primary text-13 mt-4"
                          style={{ padding: "6px 20px" }}
                        >
                          Switch to email
                        </button>
                      </div>
                    </div>
                    <div className="list-hover__container items-center mt-4">
                      <figure className="list-hover__avatar">
                        <img
                          src={iconEmail}
                          alt=""
                          style={{ width: 24, height: 21 }}
                        />
                      </figure>
                      <div className="ml-4">
                        <h4 className="text-base-1 font-semibold text-grey-900-secondary">
                          You will login with{" "}
                          <span className="font-normal">
                            jonkinger86@hotmail.com
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="mt-3 w-9/12" style={{ marginLeft: 72 }}>
                      <div className="relative">
                        <input
                          type="password"
                          className="input-field input-field--icon-lock input-field--icon-pos-left input-field--md"
                          placeholder="Create a new password"
                          style={{ paddingRight: 50 }}
                        />
                        {/* <img src={iconEye} alt="" className="icon-eye-toggle" /> */}
                        <img
                          src={iconEyeClose}
                          alt=""
                          className="icon-eye-toggle"
                        />
                      </div>
                      <div className="relative mt-3">
                        <input
                          type="password"
                          className="input-field input-field--icon-lock input-field--icon-pos-left input-field--md"
                          placeholder="Confirm your password"
                          style={{ paddingRight: 50 }}
                        />
                        {/* <img src={iconEye} alt="" className="icon-eye-toggle" /> */}
                        <img
                          src={iconEyeClose}
                          alt=""
                          className="icon-eye-toggle"
                        />
                      </div>
                      <div className="flex items-center gap-3 mt-5">
                        <button className="btn btn--primary text-base-1 btn--md min-w-170">
                          Save Password
                        </button>
                        <button className="btn btn--grey text-base-1 btn--md min-w-120">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="card-box py-5 px-6 mt-6">
                <div className="">
                  <h3 className="text-lg font-semibold">
                    2 step security authentication
                  </h3>
                  <p className="text-base-1 text-grey-800 mt-1">
                    Add an extra layer of security to your enquirybox account by
                    adding a second step during login to verify it is you.
                  </p>
                </div>
                <div className="mt-6">
                  <h3 className="font-semibold">
                    Setup using an authentication app
                  </h3>
                  <p className="text-base-1 text-grey-800 mt-1">
                    Please{" "}
                    <span className="text-primary font-semibold">
                      download the Google Authenticator app
                    </span>{" "}
                    before procedding. You can use the app to generate an
                    authentication code each time you login.
                  </p>
                  <button
                    className="btn btn--primary text-13 mt-4"
                    style={{ padding: "4px 20px" }}
                    onClick={() => hideModalVerifyAuthSetup(true)}
                  >
                    Setup
                  </button>
                </div>
                <div className="mt-6">
                  <div className="flex items-center gap-2">
                    <h3 className="text-lg font-semibold">
                      2 step security authentication
                    </h3>
                    <div
                      className="flex items-center justify-center gap-1 text-white text-11 font-semibold bg-green-900 border-radius-20"
                      style={{ padding: "3px 10px" }}
                    >
                      <svg width="8" height="7" viewBox="0 0 8 7" fill="none">
                        <path
                          d="M7.23156 1.05469L2.78925 5.49699L0.77002 3.47776"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Active
                    </div>
                  </div>
                  <p className="text-base-1 text-grey-800 mt-1">
                    Add an extra layer of security to your enquirybox account by
                    adding a second step during login to verify it is you.
                  </p>
                  <button
                    className="btn btn--error text-13 mt-5"
                    style={{ padding: "5px 20px" }}
                  >
                    Remove authentication step
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <>
        {modalVerifyAuthSetup && (
          <div className={`modal show`}>
            <div className="modal__container">
              <div
                className="modal__header modal__header--plain"
                style={{ justifyContent: "flex-end" }}
              >
                <div
                  className="modal__close-icon"
                  onClick={() => hideModalVerifyAuthSetup(false)}
                >
                  <img src={modalCloseIcon} alt="" />
                </div>
              </div>
              <div className="modal__body p-10 pt-4">
                <div className="flex items-center justify-center gap-2">
                  <h2 className="text-xl font-semibold text-center">
                    Verify identity to continue authetication setup
                  </h2>
                </div>
                <div className="text-grey-800 text-base-1 text-center mt-3 px-5">
                  In order to enable 2-Step authentication, we need to verify
                  your identity with your username and password. For added
                  security, this step will log you out and have you log back in.
                </div>
                <div className="flex items-center gap-2 justify-center mt-10">
                  <button className="btn btn--md text-base-1 btn--grey w-6/12">
                    Cancel
                  </button>
                  <button className="btn btn--md text-base-1 btn--primary w-6/12" onClick={nextScreen}>
                    Continue to verification
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </main>
  );
};

export default TwoFactorAuthentication;
