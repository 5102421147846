import React from 'react'
import { Link } from 'react-router-dom';
import avatarPic1 from "../../assets/icons/icon-avatar.svg";
import handIcon from '../../assets/icons/icon-hand-tooltip.png';
import PopUp from '../PopUp';
import iconPlus from "../../assets/icons/icon-plus.svg";
import CustomToolTip from './CustomToolTip';

const AccountsSideBarExpanded = () => {
	return (
		<div className="dashboard__sidebar__expanded-section">
			<h2 className="text-2xl font-semibold text-grey-900">Account central</h2>
			<div className="flex items-center mt-5 pb-18 border-b border-grey-400">
				<PopUp text={"No problem Merva, I’ll remind you to watch the video later. Check out the website Chatbot that we built for you"}
					subTextOne={"Ella"}
					subTextTwo={"Enquiry Assistant"}
					img={handIcon}
					width={350}>
					<Link className="flex items-center" to="/enquiry-assistant-summary">
						<figure
							className="avatar-container avatar-container--contain"
							style={{
								width: 32,
								height: 32,
								border: "1px solid rgba(0, 0, 0, 0.05)",
							}}
						>
							<img src={avatarPic1} alt="" style={{ width: 22, height: 28 }} />
						</figure>
						<p className="ml-3 text-base-1 font-semibold text-grey-900">
							Enquiry Assistant
						</p>
					</Link>
				</PopUp>
				<div
					className="flex items-center justify-center text-xs text-white font-semibold bg-red-900 px-2 rounded-full ml-auto"
					style={{ height: 20 }}
				>
					3
				</div>
			</div>
			<div>
				<div className="pt-4 pb-2">
					<h3 className="text-lg font-semibold text-grey-900">Organization</h3>
				</div>
				<ul>
					<li className="dashboard__sidebar__nav-list">
						<figure className="avatar-container avatar-container--full bg-grey-400"
							style={{
								width: 24,
								height: 24,
								// border: "1px solid rgba(0, 0, 0, 0.05)",
							}}>
							<span className='text-13 font-semibold'>S</span>
						</figure>
						<span className="text-base-1 font-medium text-grey-900">
							Smiles Dental group
						</span>
					</li>
				</ul>
				<div className="bg-grey-400 my-5" style={{ height: 1 }}></div>
			</div>
			<div>
				<div className="pb-2 flex justify-between">
					<h3 className="text-lg font-semibold text-grey-900">Live accounts</h3>			
						<CustomToolTip
							position="account"
							tooltipText="Add new accounts"
							tooltipStyle={{color: "#FFFFFF"}}
						>
							<figure style={{ width: 28, height: 26 }} className='bg-grey-90 flex items-center justify-center'>
								<img src={iconPlus} alt="" className="cursor-pointer" style={{ width: 12, height: 12 }} />
							</figure>
						</CustomToolTip>				
				</div>
				<ul>
					<li className="dashboard__sidebar__nav-list">
						<figure className="avatar-container avatar-container--full bg-grey-400"
							style={{
								width: 24,
								height: 24,
								border: "1px solid rgba(0, 0, 0, 0.05)",
							}}>
							<span className='text-13 font-semibold'>S</span>
						</figure>
						<span className="text-base-1 font-medium text-grey-900">
							Smiles Dental London branch
						</span>
					</li>
					<li className="dashboard__sidebar__nav-list">
						<figure className="avatar-container avatar-container--full bg-grey-400"
							style={{
								width: 24,
								height: 24,
								border: "1px solid rgba(0, 0, 0, 0.05)",
							}}>
							<span className='text-13 font-semibold'>S</span>
						</figure>
						<span className="text-base-1 font-medium text-grey-900">
							Smiles Dental Leeds branch
						</span>
					</li>
					<li className="dashboard__sidebar__nav-list">
						<figure className="avatar-container avatar-container--full bg-grey-400"
							style={{
								width: 24,
								height: 24,
								border: "1px solid rgba(0, 0, 0, 0.05)",
							}}>
							<span className='text-13 font-semibold'>S</span>
						</figure>
						<span className="text-base-1 font-medium text-grey-900">
							Smiles Dental Guildford branch
						</span>
					</li>
					<li className="dashboard__sidebar__nav-list">
						<figure className="avatar-container avatar-container--full bg-grey-400"
							style={{
								width: 24,
								height: 24,
								border: "1px solid rgba(0, 0, 0, 0.05)",
							}}>
							<span className='text-13 font-semibold'>S</span>
						</figure>
						<span className="text-base-1 font-medium text-grey-900">
							Smiles Dental Samson branch
						</span>
					</li>
				</ul>
				<div className="bg-grey-400 my-5" style={{ height: 1 }}></div>
			</div>
		</div>
	)
}

export default AccountsSideBarExpanded