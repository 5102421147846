import React, { useState } from "react";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import iconEdit from "../assets/icons/icon-edit.svg";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic2 from "../assets/images/avatar-pic2.png";
import ChannelHeader from "./Common/ChannelHeader";
import fileIcon from "../assets/icons/icon-file-tab.svg";
import historyIcon from "../assets/icons/icon-history-grey.svg";
import iconContactGreen from "../assets/icons/icon-contact-green.svg";
import iconDownload from "../assets/icons/icon-new-download.svg";
import iconClose from "../assets/icons/icon-close-white.svg";
import iconDelete from "../assets/icons/icon-delete-white.svg";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import ModalUploadMultiContactSetup1 from "./ModalUploadMultiContactSetup1";
import History from "./History";
import Files from "./Files";
import ImageModal from "./modal/ImageModal";
import fullImage1 from "../assets/icons/icon-full-image1.svg";
import fullImage2 from "../assets/icons/icon-full-image2.svg";
import UploadFile from "./modal/UploadFile";
import iconDots from "../assets/icons/icon-more-round.svg";
import iconUnion from "../assets/icons/Union.svg";

const users = [
  {
    id: 1,
    name: "John Leon",
    image: avatarPic,
    active: false,
  },
  {
    id: 2,
    name: "Madison Inouye",
    image: avatarPic,
    active: false,
  },
  {
    id: 7,
    name: "John Leon",
    image: avatarPic,
    active: false,
  },
  {
    id: 3,
    name: "Olivia Rhye",
    image: avatarPic,
    active: false,
  },
  {
    id: 6,
    name: "Alex Foster",
    image: avatarPic,
    active: true,
  },
  {
    id: 4,
    name: "Charles Wundeng",
    image: avatarPic,
    active: false,
  },
  {
    id: 5,
    name: "Lisa Fotios",
    image: avatarPic,
    active: false,
  },
];

const QuestionEdit = () => {
  const [hoverIndex, setHoverIndex] = useState();
  const [hoverBackground, setHoverBackground] = useState();
  const [listIndex, setlistIndex] = useState([]);
  const [showDeletAlert, setShowDeleteAlert] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [activeScreen, setActiveScreen] = useState("contact");
  const [showUploadContactModal, setShowUploadContactModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(0);
  const [openUpload, setOpenUpload] = useState(false);
  const [showEditInput, setShowEditInput] = useState({
    name: false,
    email: false,
    phoneNumber: false,
  });
  const [showAddQuestion, setShowAddQuestion] = useState(true);

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [showModals, setShowModals] = useState(false);

  const MouseOver = (event, index) => {
    setHoverIndex(index);
  };
  const MouseOut = (event, index) => {
    setHoverIndex();
  };

  const handleSelect = (index) => {
    if (listIndex.includes(index)) {
      const newIndexList = listIndex.filter((item) => {
        return item !== index;
      });
      setlistIndex(newIndexList);
    } else {
      setlistIndex([...listIndex, index]);
    }
  };
  const handleSelectAll = () => {
    if (listIndex.length !== users.length) {
      const allIndex = users.map((user, index) => {
        return index;
      });
      setlistIndex(allIndex);
    } else {
      setlistIndex("");
    }
  };

  return (
    <main className="dashboard all-contacts">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />
        <section className="dashboard__main">
          <ChannelHeader
            setShowUploadContactModal={setShowUploadContactModal}
            text={"All Contacts"}
            linkText="1,532 Contacts"
            HeaderIcon={() => (
              <figure className="avatar-container">
                <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          <div
            className={`flex  bg-white rounded-xl bg-primary-800 absolute transition-opacity ease-in duration-700 ${
              listIndex.length !== 0 ? "opacity-100" : "opacity-0"
            } `}
            style={{
              left: "35%",
              top: "7%",
              background: "#0491AB",
              boxShadow: "0px 10px 12px rgba(85, 83, 80, 0.12)",
              zIndex: 1,
            }}
          >
            <div className="p-5 flex gap-2 pr-10">
              <button
                className="btn btn--sm text-13 justify-start px-3 py-3 gap-2 min-w-140 "
                style={{
                  background:
                    listIndex.length === users.length
                      ? "white"
                      : hoverBackground === "selected"
                      ? "#037b92"
                      : "#29a1b8",
                  color:
                    listIndex.length === users.length ? "#2B3639" : "white",
                }}
                onMouseOver={(e) => setHoverBackground("selected")}
                onMouseOut={(e) => setHoverBackground()}
                onClick={handleSelectAll}
              >
                {listIndex.length !== users.length ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="8"
                      stroke="white"
                      stroke-width="1.5"
                    />
                  </svg>
                ) : (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="10" fill="#005C6F" />
                    <path
                      d="M13.7038 7.77783L8.6112 12.8704L6.29639 10.5556"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
                {listIndex.length == users.length
                  ? "All Selected"
                  : "Selected "}
                <span className="ml-auto">{listIndex.length}</span>
              </button>
              <button
                className="btn btn--sm text-13 text-left gap-2 px-3 py-3 min-w-140"
                style={{
                  background:
                    hoverBackground === "download" ? "#037b92" : "#29a1b8",
                  color: "white",
                }}
                onMouseOver={(e) => setHoverBackground("download")}
                onMouseOut={(e) => setHoverBackground()}
              >
                <img
                  className=""
                  src={iconDownload}
                  alt="download-icon-white"
                />
                Download
                <span className="ml-auto">{listIndex.length}</span>
              </button>
              <button
                className="btn  btn--sm text-13 text-left gap-2 px-3 py-3 min-w-140"
                style={{
                  background: showDeletAlert
                    ? "white"
                    : hoverBackground === "delete"
                    ? "#037b92"
                    : "#29a1b8",
                  color: showDeletAlert ? "#2B3639" : "white",
                }}
                onMouseOver={(e) => setHoverBackground("delete")}
                onMouseOut={(e) => setHoverBackground()}
                onClick={() => setShowDeleteAlert(true)}
              >
                {!showDeletAlert ? (
                  <img className="" src={iconDelete} alt="delete-icon" />
                ) : (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                      fill="#F04438"
                    />
                  </svg>
                )}
                Delete
                <span className="ml-auto">{listIndex.length}</span>
              </button>
            </div>
            <img
              className="ml-auto justify-start absolute"
              style={{ top: 15, right: 15, width: 9, height: 9 }}
              src={iconClose}
              alt="close"
              onClick={() => setlistIndex([])}
            />
          </div>
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay">
              <ul>
                {users.map((ele, index) => {
                  return (
                    <li
                      key={index}
                      className={`teams-profile-list bg-color-on-hover ${
                        ele.active ? "active" : ""
                      }`}
                      onMouseOver={(e) => MouseOver(e, index)}
                      onMouseOut={(e) => MouseOut(e, index)}
                      onClick={() => handleSelect(index)}
                    >
                      <div className="flex justify-start items-center">
                        <figure
                          className="avatar-container avatar-container--full"
                          style={{
                            width: 42,
                            height: 42,
                            text: "center",
                            background: "#4B7FE6",
                            color: "#FFFFFF",
                            fontWeight: "600",
                          }}
                        >
                          <div
                            className={`select-user transition-opacity ease-in duration-300 ${
                              hoverIndex == index || listIndex.includes(index)
                                ? "opacity-100"
                                : "opacity-0"
                            }`}
                            style={{
                              width: " 32px",
                              height: "32px",
                              background: "white",
                              position: "absolute",
                              borderRadius: "50%",
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {listIndex.includes(index) && (
                              <div
                                className={`transition-opacity ease-in duration-300 ${
                                  hoverIndex == index ||
                                  listIndex.includes(index)
                                    ? "opacity-100"
                                    : "opacity-0"
                                }`}
                              >
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16 2L6.375 11.625L2 7.25"
                                    stroke="#005C6F"
                                    stroke-width="3.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                          {index === 0 ? (
                            <span>
                              {ele.name.split(" ")[0][0].toUpperCase()}
                              {ele.name.split(" ")[1][0].toUpperCase()}
                            </span>
                          ) : index === 3 ? (
                            <span>
                              {ele.name.split(" ")[0][0].toUpperCase()}
                              {ele.name.split(" ")[1][0].toUpperCase()}
                            </span>
                          ) : (
                            <img src={ele.image} alt="avatar-pic" />
                          )}
                        </figure>
                        <div className="font-semibold text-base ml-4">
                          {ele.name}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="dashboard__main__body__section-right">
              <div className="tabs-wrapper tabs-wrapper--contact justify-center">
                <div
                  className={`tabs-header ${
                    activeScreen === "contact" ? "tabs-header__active" : ""
                  }`}
                  onClick={() => setActiveScreen("contact")}
                >
                  <img src={iconContactGreen} alt="" />
                  <div className="ml-1">Contact</div>
                </div>
                <div
                  className={`tabs-header ${
                    activeScreen === "history" ? "tabs-header__active" : ""
                  }`}
                  onClick={() => setActiveScreen("history")}
                >
                  <img src={historyIcon} alt="" />
                  <div className="ml-1">History</div>
                </div>
                <div
                  className={`tabs-header ${
                    activeScreen === "files" ? "tabs-header__active" : ""
                  }`}
                  onClick={() => setActiveScreen("files")}
                >
                  <img src={fileIcon} alt="" />
                  <div className="ml-1">Files</div>
                </div>
              </div>
              {activeScreen === "contact" && (
                <div className="h-full pb-10 overflow-y-auto p-10">
                  <div className="card-box flex p-5 border-radius-14 ">
                    <figure
                      className="avatar-container avatar-container--cover bg-grey-100 p-1"
                      style={{ width: "76px", height: "76px" }}
                    >
                      <img src={avatarPic2} />
                    </figure>
                    <div className="pl-6 w-full">
                      {!showEditInput.name && (
                        <div className="edit-signle-line bg-color-on-hover bg-color-on-hover--br-6 ml-4 mt-1 py-2">
                          <h3 className="text-base-1 font-bold">Alex Foster</h3>
                          <img
                            src={iconEdit}
                            alt="icon-edit"
                            className="hover-visible-icon"
                            onClick={() =>
                              setShowEditInput({
                                name: !showEditInput.name,
                                email: showEditInput.email,
                                phoneNumber: showEditInput.phoneNumber,
                              })
                            }
                          />
                        </div>
                      )}
                      {showEditInput.name && (
                        <div className="flex gap-5">
                          <input className="input-field input-field--sm w-full" />
                          <div className="flex items-center gap-2">
                            <button
                              className="btn btn--primary btn--sm text-13"
                              style={{ "min-width": 70 }}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn--grey btn--sm text-13"
                              style={{ "min-width": 70 }}
                              onClick={() =>
                                setShowEditInput({
                                  name: !showEditInput.name,
                                  email: showEditInput.email,
                                  phoneNumber: showEditInput.phoneNumber,
                                })
                              }
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                      {!showEditInput.email && (
                        <div className="edit-signle-line bg-color-on-hover bg-color-on-hover--br-6 ml-4 py-2">
                          <span className="text-sm">
                            lisafotios_458@gmail.com
                          </span>
                          <img
                            src={iconEdit}
                            alt="icon-edit"
                            className="hover-visible-icon"
                            onClick={() =>
                              setShowEditInput({
                                name: showEditInput.name,
                                email: !showEditInput.email,
                                phoneNumber: showEditInput.phoneNumber,
                              })
                            }
                          />
                        </div>
                      )}
                      {showEditInput.email && (
                        <div className="flex gap-5 mt-1">
                          <input className="input-field input-field--sm" />
                          <div className="flex items-center gap-2">
                            <button
                              className="btn btn--primary btn--sm text-13"
                              style={{ "min-width": 70 }}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn--grey btn--sm text-13"
                              style={{ "min-width": 70 }}
                              onClick={() =>
                                setShowEditInput({
                                  name: showEditInput.name,
                                  email: !showEditInput.email,
                                  phoneNumber: showEditInput.phoneNumber,
                                })
                              }
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                      {!showEditInput.phoneNumber && (
                        <div className="edit-signle-line bg-color-on-hover bg-color-on-hover--br-6 ml-4 py-2">
                          <p className="text-sm text-grey-800 mt-1">
                            (514) 531-8471
                          </p>
                          <img
                            src={iconEdit}
                            alt="icon-edit"
                            className="hover-visible-icon"
                            onClick={() =>
                              setShowEditInput({
                                name: showEditInput.name,
                                email: showEditInput.email,
                                phoneNumber: !showEditInput.phoneNumber,
                              })
                            }
                          />
                        </div>
                      )}
                      {showEditInput.phoneNumber && (
                        <div className="flex gap-5 mt-1">
                          <input className="input-field input-field--sm" />
                          <div className="flex items-center gap-2">
                            <button
                              className="btn btn--primary btn--sm text-13"
                              style={{
                                "min-width": 70,
                              }}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn--grey btn--sm text-13"
                              style={{
                                "min-width": 70,
                              }}
                              onClick={() =>
                                setShowEditInput({
                                  name: showEditInput.name,
                                  email: showEditInput.email,
                                  phoneNumber: !showEditInput.phoneNumber,
                                })
                              }
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="contacts-tab py-6" style={{overflowY:"unset",marginBottom:"30px",height:"100%"}}>
                    <div className="mt-6">
                      <h3 className="text-lg font-semibold ">Questions</h3>
                      <ul className="relative ">
                        <li className="question-ans-list">
                          <div className="question-ans-list__title-wrapper">
                            <h5 className="question-ans-list__title">
                              Are you vegetarian?
                            </h5>
                            <div className="relative cursor-pointer">
                              <img
                                src={iconDots}
                                alt="icon-dots"
                                onClick={() => setDropDownOpen(!dropdownOpen)}
                              />
                              <div
                                className={`dropdown-menu ${
                                  dropdownOpen && "open"
                                }`}
                                style={{
                                  width: 180,
                                  left: -154,
                                  top: 8,
                                  padding: 8,
                                }}
                              >
                                <div className="dropdown-menu__list items-center">
                                  <img
                                    src={iconEdit}
                                    alt="icon-edit"
                                    className="mr-2"
                                  />
                                  Edit
                                </div>
                                <div
                                  className="dropdown-menu__list items-center"
                                  style={{ color: "#F04438" }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mr-2"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                      fill="#F04438"
                                    />
                                  </svg>
                                  Delete
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="question-ans-list__body">
                            <div className="flex items-center gap-5">
                              <div className="flex item-center cursor-pointer">
                                <input
                                  checked
                                  type="radio"
                                  id="Yes"
                                  className="custom-radio-button"
                                  name="vegetarian"
                                />
                                <label
                                  className="custom-radio-button__label"
                                  for="Yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="flex item-center cursor-pointer">
                                <input
                                  checked
                                  type="radio"
                                  className="custom-radio-button"
                                  name="vegetarian"
                                  id="No"
                                />
                                <label
                                  className="custom-radio-button__label"
                                  for="No"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="question-ans-list">
                          <div className="question-ans-list__title-wrapper">
                            <h5 className="question-ans-list__title">
                              Which food type do you want?
                            </h5>
                            <div className="relative cursor-pointer">
                              <img
                                src={iconDots}
                                alt="icon-dots"
                                onClick={() => setDropDownOpen(!dropdownOpen)}
                              />
                              <div
                                className={`dropdown-menu ${
                                  dropdownOpen && "open"
                                }`}
                                style={{
                                  width: 180,
                                  left: -154,
                                  top: 8,
                                  padding: 8,
                                }}
                              >
                                <div className="dropdown-menu__list items-center">
                                  <img
                                    src={iconEdit}
                                    alt="icon-edit"
                                    className="mr-2"
                                  />
                                  Edit
                                </div>
                                <div
                                  className="dropdown-menu__list items-center"
                                  style={{ color: "#F04438" }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mr-2"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                      fill="#F04438"
                                    />
                                  </svg>
                                  Delete
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="question-ans-list__body">
                            <div className="relative mt-3">
                              <input
                                className="input-field input-field--sm input-field--select"
                                style={{ width: "60%" }}
                                placeholder="Please Select"
                              />
                              <div
                                className="dropdown-menu hidden"
                                style={{
                                  width: "60%",
                                  maxHeight: 300,
                                  overflow: "overlay",
                                }}
                              >
                                <div className="dropdown-menu__list">
                                  Food Type1
                                </div>
                                <div className="dropdown-menu__list">
                                  Food Type2
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="question-ans-list">
                          <div className="question-ans-list__title-wrapper">
                            <div className="flex items-center ">
                              
                              <div
                                style={{
                                  background: "#EAF3F6",
                                  borderRadius: "5px",
                                  padding: "9px",
                                  marginRight: "8px",
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="7.99956"
                                    cy="8.00639"
                                    r="6.54545"
                                    stroke="#2B3639"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <circle
                                    cx="7.99954"
                                    cy="8.0137"
                                    r="3.74026"
                                    fill="#2B3639"
                                  />
                                </svg>
                              </div>
                              <h5 className="question-ans-list__title">
                                Decider
                              </h5>
                            </div>
                          </div>
                          <div className="question-ans-list__body">
                            <div className="">
                              <label
                                className="input-field-label"
                                style={{ fontSize: 14 }}
                              >
                                Question
                              </label>
                              <input
                                className="input-field input-field--sm "
                                placeholder="Type your question here"
                                value={"Are you vegetarian?"}
                              />
                            </div>
                            <div className="w-1/2 mt-5">
                              <label
                                className="input-field-label"
                                style={{ fontSize: 14 }}
                              >
                                Option 1
                              </label>
                              <input
                                className="input-field input-field--sm "
                                placeholder="Type option"
                                value={"Yes"}
                              />
                            </div>
                            <div className="w-1/2 mt-5 relative">
                              <label
                                className="input-field-label"
                                style={{ fontSize: 14 }}
                              >
                                Option 2
                              </label>
                              <input
                                className="input-field input-field--sm "
                                placeholder="Type option"
                                value={"No"}
                              />
                              <img
                                style={{
                                  position: "absolute",
                                  top: "36px",
                                  left: "-12px",
                                }}
                                src={iconUnion}
                              />
                            </div>
                            <div className="flex justify-end gap-2 mt-8">
                              <button className="btn btn--grey btn--md min-w-120">
                                Cancel
                              </button>
                              <button className="btn btn--primary btn--md min-w-120">
                                Save
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {activeScreen === "history" && <History isContactPage={true} />}
              {activeScreen === "files" && (
                <Files
                  setShowImageModal={setShowImageModal}
                  setOpenUpload={setOpenUpload}
                />
              )}
            </div>
          </div>
        </section>
      </section>
      <div className={`modal ${showDeletAlert ? "show" : ""}`}>
        <div className="modal__container">
          <div
            className="modal__header modal__header--plain"
            style={{ justifyContent: "flex-end" }}
          >
            <div
              className="modal__close-icon"
              onClick={() => setShowDeleteAlert(false)}
            >
              <img src={modalCloseIcon} alt="" />
            </div>
          </div>

          <div className="modal__body px-8 pb-6">
            <p className="text-center text-xl text-grey-900 font-semibold px-12">
              Are you sure you want to delete these “{listIndex.length}”
              contacts.
            </p>
            <div className="flex justify-center mt-12 gap-2">
              <button className="btn btn--grey btn--md min-w-120">
                Cancel
              </button>
              <button className="btn btn--red btn--md min-w-120 text-white">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalUploadMultiContactSetup1
        showUploadContactModal={showUploadContactModal}
        setShowUploadContactModal={setShowUploadContactModal}
      />
      <ImageModal
        image={showImageModal === 1 ? fullImage1 : fullImage2}
        showModal={showImageModal === 0 ? false : true}
        setShowImageModal={setShowImageModal}
        showImageModal={showImageModal}
      />
      <UploadFile
        setShowFileUpload={setOpenUpload}
        showFileUpload={openUpload}
      />
    </main>
  );
};

export default QuestionEdit;
