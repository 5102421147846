import React, { useState } from "react";
import iconEdit from "../assets/icons/icon-edit-primary-color.svg";
import iconEmail from "../assets/icons/icon-email-grey-700.svg";
import iconPhone from "../assets/icons/icon-phone.svg";
import iconSmiles from "../assets/icons/icon-fresh-smiles.svg";
import iconGlobe from "../assets/icons/icon-globe.svg";
import iconMap from "../assets/icons/icon-map.svg";
import sampleLogo from "../assets/images/sample-logo.png";
import iconUpload from "../assets/icons/icon-upload-image.svg";
import iconTrash from "../assets/icons/icon-trash-grey-600.svg";
import emailSignature from "../assets/images/email-signature.png";

const GlobalSettingsEmailSignature = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="card-box p-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold">Email signature</h3>
        <button
          className="btn btn--outline btn--sm text-13"
          style={{ padding: "5px 10px" }}
        >
          <img src={iconEdit} alt="icon-edit" className="mr-1" />
          Edit
        </button>
      </div>
      <div className="tab-wrapper mt-5">
        <div className="tab__header">
          <div
            className={`tab__header__list ${activeTab === 1 ? "active" : ""}`}
            onClick={() => setActiveTab(1)}
          >
            Default
          </div>
          <div
            className={`tab__header__list ${activeTab === 2 ? "active" : ""}`}
            onClick={() => setActiveTab(2)}
          >
            Custom
          </div>
        </div>
        <div className="tab__body mt-8">
          {activeTab === 1 && (
            <div className="tab__body__content">
              <div className="relative card-box p-6">
                <span className="text-13 font-medium text-grey-800">
                  Kind regards,
                </span>
                <div className="mt-4">
                  <h4 className="font-semibold">Lisa Fotios</h4>
                  <span className="text-13 text-grey-800 mt-1">Manager</span>
                </div>
                <ul>
                  <li className="flex items-center mt-4">
                    <div style={{ width: 25 }}>
                      <img
                        src={iconEmail}
                        alt="icon-email"
                        style={{ width: 13, height: 11 }}
                      />
                    </div>
                    <span className="text-sm">lisafotios@gmail.com</span>
                  </li>
                  <li className="flex items-center mt-4">
                    <div style={{ width: 25 }}>
                      <img src={iconPhone} alt="icon-email" />
                    </div>
                    <span className="text-sm">+325 458 487</span>
                  </li>
                  <li className="flex items-center mt-4">
                    <div style={{ width: 25 }}>
                      <img src={iconSmiles} alt="icon-email" />
                    </div>
                    <span className="text-sm">Fresh Smiles</span>
                  </li>
                  <li className="flex items-center mt-4">
                    <div style={{ width: 25 }}>
                      <img src={iconGlobe} alt="icon-email" />
                    </div>
                    <span className="text-sm">www.freshsmiles.com</span>
                  </li>
                  <li className="flex items-center mt-4">
                    <div style={{ width: 25 }}>
                      <img src={iconMap} alt="icon-email" />
                    </div>
                    <span className="text-sm">
                      Berkeley ave, 20 Patrick Street
                    </span>
                  </li>
                </ul>
                <div
                  className="flex items-center justify-center bg-white border-radius-10 p-3 mt-4"
                  style={{
                    width: 100,
                    height: 80,
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img
                    src={sampleLogo}
                    alt="pic-signature"
                    className="object-contain"
                  />
                </div>
                <div className="flex items-center justify-center absolute -top-4 left-0 right-0 mx-auto text-grey-800 text-11 font-semibold uppercase bg-grey-400" style={{width: 84, padding: '5px 8px', borderRadius: 20, 'box-shadow': ' inset 0px 0px 0px 1px #FFFFFF'}}>Preview</div>
              </div>
              <div className="mt-6">
                <div className="flex items-center justify-between flex-wrap -mx-3">
                  <div className="w-6/12 px-3 mb-4">
                    <label className="input-field-label">
                      Sign off message
                    </label>
                    <input
                      type="text"
                      className="input-field input-field--select input-field--md-38"
                    />
                  </div>
                  <div className="w-6/12 px-3 mb-4">
                    <label className="input-field-label">Font</label>
                    <input
                      type="text"
                      className="input-field input-field--select input-field--md-38"
                    />
                  </div>
                  <div className="w-6/12 px-3 mb-4">
                    <label className="input-field-label">Full name</label>
                    <input
                      type="text"
                      className="input-field input-field--md-38"
                    />
                  </div>
                  <div className="w-6/12 px-3 mb-4">
                    <label className="input-field-label">Job title</label>
                    <input
                      type="text"
                      className="input-field input-field--md-38"
                    />
                  </div>
                  <div className="w-6/12 px-3 mb-4">
                    <label className="input-field-label">Email</label>
                    <input
                      type="text"
                      className="input-field input-field--md-38"
                    />
                  </div>
                  <div className="w-6/12 px-3 mb-4">
                    <label className="input-field-label">Phone</label>
                    <input
                      type="text"
                      className="input-field input-field--md-38"
                    />
                  </div>
                  <div className="w-6/12 px-3 mb-4">
                    <label className="input-field-label">Company</label>
                    <input
                      type="text"
                      className="input-field input-field--md-38"
                    />
                  </div>
                  <div className="w-6/12 px-3 mb-4">
                    <label className="input-field-label">Website</label>
                    <input
                      type="text"
                      className="input-field input-field--md-38"
                    />
                  </div>
                  <div className="w-full px-3 mb-4">
                    <label className="input-field-label">Address</label>
                    <input
                      type="text"
                      className="input-field input-field--md-38"
                      placeholder="Address"
                    />
                  </div>
                </div>
                <div className="">
                  <label className="text-lg font-semibold">Logo</label>
                  <div className="flex items-center gap-4 mt-3">
                    <div className="file-upload-wrapper">
                      <input
                        type="file"
                        className="file-upload__input"
                        id="uploadSignature"
                      />
                      <label
                        for="uploadSignature"
                        className="file-upload__signature py-1 px-3"
                      >
                        <img
                          src={iconUpload}
                          alt="file-upload"
                          className="mr-2"
                        />
                        <span className="text-primary text-13 font-medium">
                          Upload image
                        </span>
                      </label>
                    </div>
                    <span className="text-grey-600 text-13 font-medium">
                      Max - 100kb
                    </span>
                  </div>
                  <div
                    className="border border-grey-400 border-radius-10 p-1 mt-3"
                    style={{ width: 278, height: 62 }}
                  >
                    <div className="flex items-center h-full">
                      <figure
                        className="h-full flex items-center justify-center flex-shrink-0 p-1 bg-grey-200 rounded-md"
                        style={{ width: 54 }}
                      >
                        <img src={sampleLogo} alt="signature pic" />
                      </figure>
                      <div className="flex flex-col ml-3 pr-3 truncate">
                        <h5 className="text-sm font-medium truncate">
                          Sensu logo1.png
                        </h5>
                        <p className="text-xs text-grey-700 truncate">60kb</p>
                      </div>
                      <img
                        src={iconTrash}
                        alt="icon-delete"
                        className="ml-auto mt-5 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end gap-3 mt-8">
                  <button className="btn btn--grey btn--md min-w-120">
                    Cancel
                  </button>
                  <button className="btn btn--primary btn--md min-w-120">
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className="tab__body__content">
              <div className="relative card-box p-5" style={{ borderRadius: 8 }}>
                <div className="flex flex-col items-center justify-center text-center p-16">
                  <p className="text-grey-900-secondary text-sm font-medium">
                    Copy your signature from an email or your email provider and
                    paste it here
                  </p>
                  <button className="btn btn--grey btn--sm min-w-120 text-sm mt-5">
                    Paste here
                  </button>
                </div>
                <figure
                  className="flex items-center justify-center"
                  style={{ width: 190, height: 190 }}
                >
                  <img
                    src={emailSignature}
                    alt="email-signature"
                    className="object-contain"
                  />
                </figure>
                <div className="flex items-center justify-center absolute -top-4 left-0 right-0 mx-auto text-grey-800 text-11 font-semibold uppercase bg-grey-400" style={{width: 84, padding: '5px 8px', borderRadius: 20, 'box-shadow': ' inset 0px 0px 0px 1px #FFFFFF'}}>Preview</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalSettingsEmailSignature;
