import React, { useState } from "react";
import avtarPic from "../assets/images/avatar-pic.png";
import iconDots from "../assets/icons/icon-more-round.svg";

const Contacts = () => {
  return (
    <div className="contacts-tab px-8 py-6">
      <div className="flex items-center bg-white border border-grey-400 border-radius-14 p-6">
        <figure
          className="avatar-container avatar-container--full"
          style={{ width: 70, height: 70 }}
        >
          <img src={avtarPic} alt="avatar-pic" />
        </figure>
        <div className="ml-6">
          <h5 className="text-base-1 font-bold">Alex Foster</h5>
          <p className="text-sm mt-2">lisafotios_458@gmail.com</p>
          <p className="text-sm text-grey-800 mt-2">(514) 531-8471</p>
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-lg font-semibold">Questions</h3>
        <ul>
          <li className="question-ans-list">
            <div className="question-ans-list__title-wrapper">
              <h5 className="question-ans-list__title">Are you vegetarian?</h5>
              <div className="relative cursor-pointer">
                <img src={iconDots} alt="icon-dots" />
                <div
                  className="dropdown-menu open"
                  style={{ width: 120, left: -100, top: 8 }}
                >
                  <div className="dropdown-menu__list">Delete</div>
                </div>
              </div>
            </div>
            <div className="question-ans-list__body">
              <div className="flex items-center gap-5">
                <div className="flex item-center cursor-pointer">
                  <input
                    checked
                    type="radio"
                    id="Yes"
                    className="custom-radio-button"
                    name="vegetarian"
                  />
                  <label className="custom-radio-button__label" for="Yes">
                    Yes
                  </label>
                </div>
                <div className="flex item-center cursor-pointer">
                  <input
                    checked
                    type="radio"
                    className="custom-radio-button"
                    name="vegetarian"
                    id="No"
                  />
                  <label className="custom-radio-button__label" for="No">
                    No
                  </label>
                </div>
              </div>
            </div>
          </li>
          <li className="question-ans-list">
            <div className="question-ans-list__title-wrapper">
              <h5 className="question-ans-list__title">
                Which food type do you want?
              </h5>
              <div className="relative cursor-pointer">
                <img src={iconDots} alt="icon-dots" />
                <div
                  className="dropdown-menu open"
                  style={{ width: 120, left: -100, top: 8 }}
                >
                  <div className="dropdown-menu__list">Delete</div>
                </div>
              </div>
            </div>
            <div className="question-ans-list__body">
              <div className="relative mt-3">
                <input
                  className="input-field input-field--sm input-field--select" style={{width: '60%'}}
                  placeholder="Please Select"
                />
                <div className="dropdown-menu hidden" style={{width: '60%', maxHeight: 300, overflow:'overlay'}}> 
                  <div className="dropdown-menu__list">Food Type1</div>
                  <div className="dropdown-menu__list">Food Type2</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contacts;
