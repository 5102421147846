import React, { useState } from "react";
import iconEdit from "../assets/icons/icon-edit.svg";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic2 from "../assets/images/avatar-pic2.png";
import fileIcon from "../assets/icons/icon-file-tab.svg";
import historyIcon from "../assets/icons/icon-history-grey.svg";
import iconContactGreen from "../assets/icons/icon-contact-green.svg";
import iconDownload from "../assets/icons/icon-new-download.svg";
import iconClose from "../assets/icons/icon-close-white.svg";
import iconDelete from "../assets/icons/icon-delete-white.svg";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import ModalUploadMultiContactSetup1 from "./ModalUploadMultiContactSetup1";
import History from "./History";
import Files from "./Files";
import ImageModal from "./modal/ImageModal";
import fullImage1 from "../assets/icons/icon-full-image1.svg";
import fullImage2 from "../assets/icons/icon-full-image2.svg";
import UploadFile from "./modal/UploadFile";
import NoQuestions from "./NoQuestions";
import iconDots from "../assets/icons/icon-more-round.svg";
import AddQuestions from "./AddQuestions";
import iconText from "../assets/icons/icon-text.svg";
import iconDecider from "../assets/icons/icon-decider.svg";
import iconDropDown from "../assets/icons/icon-dropdown.svg";
import iconUnion from "../assets/icons/Union.svg";
import iconValue from "../assets/icons/icon-value.svg";
import iconUsflag from "../assets/icons/icon-usflag.png";
import iconMultiChoices from "../assets/icons/icon-multi-choices.svg";
import iconPlusGreen from "../assets/icons/icon-plus-green.svg";

const users = [
  {
    id: 1,
    name: "John Leon",
    image: avatarPic,
    active: false,
  },
  {
    id: 2,
    name: "Madison Inouye",
    image: avatarPic,
    active: false,
  },
  {
    id: 7,
    name: "John Leon",
    image: avatarPic,
    active: false,
  },
  {
    id: 3,
    name: "Olivia Rhye",
    image: avatarPic,
    active: false,
  },
  {
    id: 6,
    name: "Alex Foster",
    image: avatarPic,
    active: true,
  },
  {
    id: 4,
    name: "Charles Wundeng",
    image: avatarPic,
    active: false,
  },
  {
    id: 5,
    name: "Lisa Fotios",
    image: avatarPic,
    active: false,
  },
];

const ToggleContacts = () => {
  const [hoverIndex, setHoverIndex] = useState();
  const [hoverBackground, setHoverBackground] = useState();
  const [listIndex, setlistIndex] = useState([]);
  const [showDeletAlert, setShowDeleteAlert] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [activeScreen, setActiveScreen] = useState("contact");
  const [showUploadContactModal, setShowUploadContactModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(0);
  const [openUpload, setOpenUpload] = useState(false);
  const [showEditInput, setShowEditInput] = useState({
    name: false,
    email: false,
    phoneNumber: false,
  });
  const [showAddQuestion, setShowAddQuestion] = useState(true);

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [showModals, setShowModals] = useState(false);

  const [selectItem, setSelectItem] = useState({
    lunch: false,
    breakfast: true,
    snaks: false,
    dinner: false,
  });

  const [selectNonVegItem, setSelectNonVegItem] = useState({
    chicken: false,
    mutton: false,
    lamb: false,
    fish: true,
    pork: false,
  });

  const [valueDropDown, setValuedropDown] = useState(false);

  const MouseOver = (event, index) => {
    setHoverIndex(index);
  };
  const MouseOut = (event, index) => {
    setHoverIndex();
  };

  const handleSelect = (index) => {
    if (listIndex.includes(index)) {
      const newIndexList = listIndex.filter((item) => {
        return item !== index;
      });
      setlistIndex(newIndexList);
    } else {
      setlistIndex([...listIndex, index]);
    }
  };
  const handleSelectAll = () => {
    if (listIndex.length !== users.length) {
      const allIndex = users.map((user, index) => {
        return index;
      });
      setlistIndex(allIndex);
    } else {
      setlistIndex("");
    }
  };
  const handleAddQuestion = () => {
    setSelectedOption("");
    setShowAddQuestion(false);
    setShowModals(false);
  };
  const handleSelectFoodChange = (evt) => {
    setSelectItem({ ...selectItem, [evt.target.name]: evt.target.checked });
  };

  const handleSelectNonVegItemChange = (evt) => {
    setSelectNonVegItem({
      ...selectNonVegItem,
      [evt.target.name]: evt.target.checked,
    });
  };
  return (
    <>
      <div
        className={`flex  bg-white rounded-xl bg-primary-800 absolute transition-opacity ease-in duration-700 ${
          listIndex.length !== 0 ? "opacity-100" : "opacity-0"
        } `}
        style={{
          left: "35%",
          top: "7%",
          background: "#0491AB",
          boxShadow: "0px 10px 12px rgba(85, 83, 80, 0.12)",
          zIndex: 1,
        }}
      >
        <div className={`p-5 gap-2 pr-10 ${listIndex.length === 0 ? 'd-none' : 'flex'}`}>
          <button
            className="btn btn--sm text-13 justify-start px-3 py-3 gap-2 min-w-140"
            style={{
              background:
                listIndex.length === users.length
                  ? "white"
                  : hoverBackground === "selected"
                  ? "#037b92"
                  : "#29a1b8",
              color: listIndex.length === users.length ? "#2B3639" : "white",
            }}
            onMouseOver={(e) => setHoverBackground("selected")}
            onMouseOut={(e) => setHoverBackground()}
            onClick={handleSelectAll}
          >
            {listIndex.length !== users.length ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="8"
                  stroke="white"
                  stroke-width="1.5"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#005C6F" />
                <path
                  d="M13.7038 7.77783L8.6112 12.8704L6.29639 10.5556"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            {listIndex.length == users.length ? "All Selected" : "Selected "}
            <span className="ml-auto">{listIndex.length}</span>
          </button>
          <button
            className="btn btn--sm text-13 text-left gap-2 px-3 py-3 min-w-140"
            style={{
              background:
                hoverBackground === "download" ? "#037b92" : "#29a1b8",
              color: "white",
            }}
            onMouseOver={(e) => setHoverBackground("download")}
            onMouseOut={(e) => setHoverBackground()}
          >
            <img className="" src={iconDownload} alt="download-icon-white" />
            Download
            <span className="ml-auto">{listIndex.length}</span>
          </button>
          <button
            className="btn  btn--sm text-13 text-left gap-2 px-3 py-3 min-w-140"
            style={{
              background: showDeletAlert
                ? "white"
                : hoverBackground === "delete"
                ? "#037b92"
                : "#29a1b8",
              color: showDeletAlert ? "#2B3639" : "white",
            }}
            onMouseOver={(e) => setHoverBackground("delete")}
            onMouseOut={(e) => setHoverBackground()}
            onClick={() => setShowDeleteAlert(true)}
          >
            {!showDeletAlert ? (
              <img className="" src={iconDelete} alt="delete-icon" />
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                  fill="#F04438"
                />
              </svg>
            )}
            Delete
            <span className="ml-auto">{listIndex.length}</span>
          </button>
        </div>
        <img
          className="ml-auto justify-start absolute"
          style={{ top: 15, right: 15, width: 9, height: 9 }}
          src={iconClose}
          alt="close"
          onClick={() => setlistIndex([])}
        />
      </div>
      <div className="dashboard__main__body">
        <div className="dashboard__main__body__section-left overflow-overlay">
          <ul>
            <div className="flex items-center cursor-pointer py-3">
              <figure
                className="bg-grey-90 flex justify-center items-center"
                style={{ width: 42, height: 42, borderRadius: "50%" }}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 1V11"
                    stroke="#005C6F"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M11 6L1 6"
                    stroke="#005C6F"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </figure>
              <p className="ml-4 text-15 font-semibold text-primary" onClick={() => setShowUploadContactModal(true)}>
                Add a contact
              </p>
            </div>
            {users.map((ele, index) => {
              return (
                <li
                  key={index}
                  className={`teams-profile-list bg-color-on-hover ${
                    ele.active ? "active" : ""
                  }`}
                  onMouseOver={(e) => MouseOver(e, index)}
                  onMouseOut={(e) => MouseOut(e, index)}
                  onClick={() => handleSelect(index)}
                >
                  <div className="flex justify-start items-center">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{
                        width: 42,
                        height: 42,
                        text: "center",
                        background: "#4B7FE6",
                        color: "#FFFFFF",
                        fontWeight: "600",
                      }}
                    >
                      <div
                        className={`select-user transition-opacity ease-in duration-300 ${
                          hoverIndex == index || listIndex.includes(index)
                            ? "opacity-100"
                            : "opacity-0"
                        }`}
                        style={{
                          width: " 32px",
                          height: "32px",
                          background: "white",
                          position: "absolute",
                          borderRadius: "50%",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {listIndex.includes(index) && (
                          <div
                            className={`transition-opacity ease-in duration-300 ${
                              hoverIndex == index || listIndex.includes(index)
                                ? "opacity-100"
                                : "opacity-0"
                            }`}
                          >
                            <svg
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 2L6.375 11.625L2 7.25"
                                stroke="#005C6F"
                                stroke-width="3.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                      {index === 0 ? (
                        <span>
                          {ele.name.split(" ")[0][0].toUpperCase()}
                          {ele.name.split(" ")[1][0].toUpperCase()}
                        </span>
                      ) : index === 3 ? (
                        <span>
                          {ele.name.split(" ")[0][0].toUpperCase()}
                          {ele.name.split(" ")[1][0].toUpperCase()}
                        </span>
                      ) : (
                        <img src={ele.image} alt="avatar-pic" />
                      )}
                    </figure>
                    <div className="font-semibold text-base ml-4">
                      {ele.name}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="dashboard__main__body__section-right">
          <div className="tabs-wrapper tabs-wrapper--contact justify-center">
            <div
              className={`tabs-header ${
                activeScreen === "contact" ? "tabs-header__active" : ""
              }`}
              onClick={() => setActiveScreen("contact")}
            >
              <img src={iconContactGreen} alt="" />
              <div className="ml-1">Contact</div>
            </div>
            <div
              className={`tabs-header ${
                activeScreen === "conversation" ? "tabs-header__active" : ""
              }`}
              onClick={() => setActiveScreen("conversation")}
            >
              {activeScreen === "conversation" ? (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.04689 12.805C5.67169 12.805 6.98929 11.4874 6.98929 9.8626C6.98929 8.2378 5.67229 6.9202 4.04689 6.9202C2.42149 6.9202 1.10449 8.2378 1.10449 9.8626"
                    stroke="#005C6F"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.0498 12.808C3.607 12.808 3.1876 12.7102 2.8108 12.5356L1 12.9202L1.3786 11.1058C1.2028 10.7278 1.1044 10.3066 1.1044 9.86259"
                    stroke="#005C6F"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.73459 6.937C3.51979 6.4444 3.40039 5.9074 3.40039 5.344C3.40039 2.9338 5.55919 1 8.20039 1C10.8416 1 13.0004 2.9338 13.0004 5.344C13.0004 6.7684 12.2432 8.0224 11.081 8.8132C11.0816 9.2668 11.0804 9.8782 11.0804 10.5202L9.19699 9.5926C8.87479 9.6544 8.54179 9.688 8.20039 9.688C7.77619 9.688 7.36459 9.6376 6.97219 9.5434"
                    stroke="#005C6F"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.04689 12.805C5.67169 12.805 6.98929 11.4874 6.98929 9.8626C6.98929 8.2378 5.67229 6.9202 4.04689 6.9202C2.42149 6.9202 1.10449 8.2378 1.10449 9.8626"
                    stroke="#636566"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.0498 12.808C3.607 12.808 3.1876 12.7102 2.8108 12.5356L1 12.9202L1.3786 11.1058C1.2028 10.7278 1.1044 10.3066 1.1044 9.86259"
                    stroke="#636566"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.73459 6.937C3.51979 6.4444 3.40039 5.9074 3.40039 5.344C3.40039 2.9338 5.55919 1 8.20039 1C10.8416 1 13.0004 2.9338 13.0004 5.344C13.0004 6.7684 12.2432 8.0224 11.081 8.8132C11.0816 9.2668 11.0804 9.8782 11.0804 10.5202L9.19699 9.5926C8.87479 9.6544 8.54179 9.688 8.20039 9.688C7.77619 9.688 7.36459 9.6376 6.97219 9.5434"
                    stroke="#636566"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              <div className="ml-1">Conversation</div>
            </div>
            <div
              className={`tabs-header ${
                activeScreen === "history" ? "tabs-header__active" : ""
              }`}
              onClick={() => setActiveScreen("history")}
            >
              <img src={historyIcon} alt="" />
              <div className="ml-1">History</div>
            </div>
            <div
              className={`tabs-header ${
                activeScreen === "files" ? "tabs-header__active" : ""
              }`}
              onClick={() => setActiveScreen("files")}
            >
              <img src={fileIcon} alt="" />
              <div className="ml-1">Files</div>
            </div>
          </div>
          {activeScreen === "contact" && (
            <div className="h-full pb-10 overflow-y-auto p-10">
              <div className="card-box flex p-5 border-radius-14">
                <figure
                  className="avatar-container avatar-container--cover bg-grey-100 p-1"
                  style={{ width: "76px", height: "76px" }}
                >
                  <img src={avatarPic2} />
                </figure>
                <div className="pl-6 w-full">
                  {!showEditInput.name && (
                    <div className="edit-signle-line bg-color-on-hover bg-color-on-hover--br-6 ml-4 mt-1 py-2">
                      <h3 className="text-base-1 font-bold">Alex Foster</h3>
                      <img
                        src={iconEdit}
                        alt="icon-edit"
                        className="hover-visible-icon"
                        onClick={() =>
                          setShowEditInput({
                            name: !showEditInput.name,
                            email: showEditInput.email,
                            phoneNumber: showEditInput.phoneNumber,
                          })
                        }
                      />
                    </div>
                  )}
                  {showEditInput.name && (
                    <div className="flex gap-5">
                      <input className="input-field input-field--sm w-full" />
                      <div className="flex items-center gap-2">
                        <button
                          className="btn btn--primary btn--sm text-13"
                          style={{ "min-width": 70 }}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn--grey btn--sm text-13"
                          style={{ "min-width": 70 }}
                          onClick={() =>
                            setShowEditInput({
                              name: !showEditInput.name,
                              email: showEditInput.email,
                              phoneNumber: showEditInput.phoneNumber,
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {!showEditInput.email && (
                    <div className="edit-signle-line bg-color-on-hover bg-color-on-hover--br-6 ml-4 py-2">
                      <span className="text-sm">lisafotios_458@gmail.com</span>
                      <img
                        src={iconEdit}
                        alt="icon-edit"
                        className="hover-visible-icon"
                        onClick={() =>
                          setShowEditInput({
                            name: showEditInput.name,
                            email: !showEditInput.email,
                            phoneNumber: showEditInput.phoneNumber,
                          })
                        }
                      />
                    </div>
                  )}
                  {showEditInput.email && (
                    <div className="flex gap-5 mt-1">
                      <input className="input-field input-field--sm" />
                      <div className="flex items-center gap-2">
                        <button
                          className="btn btn--primary btn--sm text-13"
                          style={{ "min-width": 70 }}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn--grey btn--sm text-13"
                          style={{ "min-width": 70 }}
                          onClick={() =>
                            setShowEditInput({
                              name: showEditInput.name,
                              email: !showEditInput.email,
                              phoneNumber: showEditInput.phoneNumber,
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {!showEditInput.phoneNumber && (
                    <div className="edit-signle-line bg-color-on-hover bg-color-on-hover--br-6 ml-4 py-2">
                      <p className="text-sm text-grey-800 mt-1">
                        (514) 531-8471
                      </p>
                      <img
                        src={iconEdit}
                        alt="icon-edit"
                        className="hover-visible-icon"
                        onClick={() =>
                          setShowEditInput({
                            name: showEditInput.name,
                            email: showEditInput.email,
                            phoneNumber: !showEditInput.phoneNumber,
                          })
                        }
                      />
                    </div>
                  )}
                  {showEditInput.phoneNumber && (
                    <div className="flex gap-5 mt-1">
                      <input className="input-field input-field--sm" />
                      <div className="flex items-center gap-2">
                        <button
                          className="btn btn--primary btn--sm text-13"
                          style={{
                            "min-width": 70,
                          }}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn--grey btn--sm text-13"
                          style={{
                            "min-width": 70,
                          }}
                          onClick={() =>
                            setShowEditInput({
                              name: showEditInput.name,
                              email: showEditInput.email,
                              phoneNumber: !showEditInput.phoneNumber,
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {showModals && (
                <div className="contacts-tab" style={{ overflowY: "unset" }}>
                  <div className="mt-6">
                    <ul>
                      {selectedOption === "Decider" && (
                        <div className="contact-questions">
                          <div className="mt-6">
                            <h3 className="text-lg font-semibold">Decider</h3>
                          </div>
                          <li className="question-ans-list">
                            <div className="question-ans-list__title-wrapper">
                              <h5 className="question-ans-list__title">
                                Are you vegetarian?
                              </h5>
                              <div className="relative cursor-pointer">
                                <img
                                  src={iconDots}
                                  alt="icon-dots"
                                  onClick={() => setDropDownOpen(!dropdownOpen)}
                                />
                                <div
                                  className={`dropdown-menu ${
                                    dropdownOpen && "open"
                                  }`}
                                  style={{
                                    width: 180,
                                    left: -154,
                                    top: 8,
                                    padding: 8,
                                  }}
                                >
                                  <div className="dropdown-menu__list items-center">
                                    <img
                                      src={iconEdit}
                                      alt="icon-edit"
                                      className="mr-2"
                                    />
                                    Edit
                                  </div>
                                  <div
                                    className="dropdown-menu__list items-center"
                                    style={{ color: "#F04438" }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="mr-2"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                        fill="#F04438"
                                      />
                                    </svg>
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="question-ans-list__body">
                              <div className="flex items-center gap-5">
                                <div className="flex item-center cursor-pointer">
                                  <input
                                    checked
                                    type="radio"
                                    id="Yes"
                                    className="custom-radio-button"
                                    name="vegetarian"
                                  />
                                  <label
                                    className="custom-radio-button__label"
                                    for="Yes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="flex item-center cursor-pointer">
                                  <input
                                    checked
                                    type="radio"
                                    className="custom-radio-button"
                                    name="vegetarian"
                                    id="No"
                                  />
                                  <label
                                    className="custom-radio-button__label"
                                    for="No"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="question-ans-list">
                            <div className="question-ans-list__title-wrapper">
                              <div className="flex items-center ">
                                <img
                                  className="question-ans-list__image"
                                  src={iconDecider}
                                  style={{
                                    background: "#EAF3F6",
                                    borderRadius: "5px",
                                    padding: "9px",
                                    marginRight: "8px",
                                  }}
                                />
                                <h5 className="question-ans-list__title">
                                  Decider
                                </h5>
                              </div>
                            </div>
                            <div className="question-ans-list__body">
                              <div className="">
                                <label
                                  className="input-field-label"
                                  style={{ fontSize: 14 }}
                                >
                                  Question
                                </label>
                                <input
                                  className="input-field input-field--sm "
                                  placeholder="Type your question here"
                                />
                              </div>
                              <div className="w-1/2 mt-5">
                                <label
                                  className="input-field-label"
                                  style={{ fontSize: 14 }}
                                >
                                  Option 1
                                </label>
                                <input
                                  className="input-field input-field--sm "
                                  placeholder="Type option"
                                />
                              </div>
                              <div className="w-1/2 mt-5 relative">
                                <label
                                  className="input-field-label"
                                  style={{ fontSize: 14 }}
                                >
                                  Option 2
                                </label>
                                <input
                                  className="input-field input-field--sm "
                                  placeholder="Type option"
                                />
                                <img
                                  style={{
                                    position: "absolute",
                                    top: "36px",
                                    left: "-12px",
                                  }}
                                  src={iconUnion}
                                />
                              </div>
                              <div className="flex justify-end gap-2 mt-8">
                                <button className="btn btn--grey btn--md min-w-120">
                                  Cancel
                                </button>
                                <button className="btn btn--primary btn--md min-w-120">
                                  Save
                                </button>
                              </div>
                            </div>
                          </li>
                          <div
                            className="flex mt-4 justify-center cursor-pointer items-center bg-grey-90 py-3 rounded-lg mb-10"
                            onClick={() => handleAddQuestion()}
                          >
                            <img
                              src={iconPlusGreen}
                              alt=""
                              style={{
                                marginRight: 8,
                                width: 10,
                                height: 10,
                              }}
                            />
                            <div className="text-sm font-semibold text-primary ">
                              Add Question
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedOption === "Multiple choice" && (
                        <>
                          <div className="contact-questions">
                            <div className="mt-6">
                              <h3 className="text-lg font-semibold">
                                Multi choice
                              </h3>
                              <div className="question-ans-list">
                                <div className="question-ans-list__title-wrapper">
                                  <h5 className="question-ans-list__title">
                                    Select your food time
                                  </h5>
                                  <div className="relative cursor-pointer">
                                    <img
                                      src={iconDots}
                                      alt="icon-dots"
                                      onClick={() =>
                                        setDropDownOpen(!dropdownOpen)
                                      }
                                    />
                                    <div
                                      className={`dropdown-menu ${
                                        dropdownOpen && "open"
                                      }`}
                                      style={{
                                        width: 180,
                                        left: -154,
                                        top: 8,
                                        padding: 8,
                                      }}
                                    >
                                      <div className="dropdown-menu__list items-center">
                                        <img
                                          src={iconEdit}
                                          alt="icon-edit"
                                          className="mr-2"
                                        />
                                        Edit
                                      </div>
                                      <div
                                        className="dropdown-menu__list items-center"
                                        style={{ color: "#F04438" }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="mr-2"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                            fill="#F04438"
                                          />
                                        </svg>
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="question-ans-list__body">
                                  <div className="relative mt-3 flex gap-4">
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox custom-checkbox--sm custom-checkbox--circle"
                                        name="breakfast"
                                        checked={selectItem.breakfast}
                                        onChange={handleSelectFoodChange}
                                      />
                                      <span
                                        className={`text-md ${
                                          selectItem.breakfast
                                            ? "font-semibold"
                                            : ""
                                        } ml-2`}
                                      >
                                        Breakfast
                                      </span>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox custom-checkbox--sm custom-checkbox--circle"
                                        name="lunch"
                                        checked={selectItem.lunch}
                                        onChange={handleSelectFoodChange}
                                      />
                                      <span
                                        className={`text-md ${
                                          selectItem.lunch
                                            ? "font-semibold"
                                            : ""
                                        } ml-2`}
                                      >
                                        Lunch
                                      </span>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox custom-checkbox--sm custom-checkbox--circle"
                                        name="snaks"
                                        checked={selectItem.snaks}
                                        onChange={handleSelectFoodChange}
                                      />
                                      <span
                                        className={`text-md ${
                                          selectItem.snaks
                                            ? "font-semibold"
                                            : ""
                                        } ml-2`}
                                      >
                                        Snaks
                                      </span>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox custom-checkbox--sm custom-checkbox--circle"
                                        name="dinner"
                                        checked={selectItem.dinner}
                                        onChange={handleSelectFoodChange}
                                      />
                                      <span
                                        className={`text-md ${
                                          selectItem.dinner
                                            ? "font-semibold"
                                            : ""
                                        } ml-2`}
                                      >
                                        Dinner
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <li className="question-ans-list">
                                <div className="question-ans-list__title-wrapper">
                                  <div className="flex items-center ">
                                    <div
                                      style={{
                                        background: "#EAF3F6",
                                        borderRadius: "5px",
                                        padding: "9px",
                                        marginRight: "8px",
                                      }}
                                    >
                                      <img
                                        className="question-ans-list__image"
                                        src={iconMultiChoices}
                                      />
                                    </div>
                                    <h5 className="question-ans-list__title">
                                      Multiple choice
                                    </h5>
                                  </div>
                                </div>
                                <div className="question-ans-list__body">
                                  <div className="">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      Question
                                    </label>
                                    <input
                                      className="input-field input-field--sm "
                                      placeholder="Type your question here"
                                    />
                                  </div>
                                  <div className="w-1/2 mt-5 relative">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      Option 1
                                    </label>
                                    <input
                                      className="input-field input-field--sm "
                                      placeholder="Type option"
                                    />
                                    <img
                                      src={modalCloseIcon}
                                      style={{
                                        position: "absolute",
                                        top: "38px",
                                        right: "-20px",
                                        cursor: "pointer",
                                      }}
                                      alt="icon-close"
                                    />
                                  </div>
                                  <div className="w-1/2 mt-5 relative">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      Option 2
                                    </label>
                                    <input
                                      className="input-field input-field--sm "
                                      placeholder="Type option"
                                    />
                                    <img
                                      src={modalCloseIcon}
                                      style={{
                                        position: "absolute",
                                        top: "38px",
                                        right: "-20px",
                                        cursor: "pointer",
                                      }}
                                      alt="icon-close"
                                    />
                                    <img
                                      style={{
                                        position: "absolute",
                                        top: "36px",
                                        left: "-12px",
                                      }}
                                      src={iconUnion}
                                    />
                                  </div>
                                  <div className="w-1/2 mt-5 relative">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      Add option
                                    </label>
                                    <input
                                      className="input-field input-field--sm "
                                      placeholder="Add option"
                                    />
                                  </div>
                                  <div className="flex justify-end gap-2 mt-8">
                                    <button className="btn btn--grey btn--md min-w-120">
                                      Cancel
                                    </button>
                                    <button className="btn btn--primary btn--md min-w-120">
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <div
                                className="flex mt-4 justify-center cursor-pointer items-center bg-grey-90 py-3 rounded-lg mb-10"
                                onClick={() => handleAddQuestion()}
                              >
                                <img
                                  src={iconPlusGreen}
                                  alt=""
                                  style={{
                                    marginRight: 8,
                                    width: 10,
                                    height: 10,
                                  }}
                                />
                                <div className="text-sm font-semibold text-primary ">
                                  Add Question
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {selectedOption === "Text" && (
                        <>
                          <div className="contact-questions">
                            <div className="mt-6">
                              <h3 className="text-lg font-semibold">Texts</h3>
                              <div className="question-ans-list">
                                <div className="question-ans-list__title-wrapper">
                                  <h5 className="question-ans-list__title">
                                    Add notes for your party
                                  </h5>
                                  <div className="relative cursor-pointer">
                                    <div>
                                      <img
                                        src={iconDots}
                                        alt="icon-dots"
                                        onClick={() =>
                                          setDropDownOpen(!dropdownOpen)
                                        }
                                      />
                                    </div>
                                    <div
                                      className={`dropdown-menu ${
                                        dropdownOpen && "open"
                                      }`}
                                      style={{
                                        width: 180,
                                        left: -154,
                                        top: 8,
                                        padding: 8,
                                      }}
                                    >
                                      <div className="dropdown-menu__list items-center">
                                        <img
                                          src={iconEdit}
                                          alt="icon-edit"
                                          className="mr-2"
                                        />
                                        Edit
                                      </div>
                                      <div
                                        className="dropdown-menu__list items-center"
                                        style={{ color: "#F04438" }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="mr-2"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                            fill="#F04438"
                                          />
                                        </svg>
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="question-ans-list__body">
                                  <div className="relative mt-3">
                                    <input
                                      className="input-field input-field--sm "
                                      placeholder="Add answer"
                                    />
                                  </div>
                                </div>
                              </div>
                              <li className="question-ans-list">
                                <div className="question-ans-list__title-wrapper">
                                  <div className="flex items-center">
                                    <div
                                      style={{
                                        background: "#EAF3F6",
                                        borderRadius: "5px",
                                        padding: "9px",
                                        marginRight: "8px",
                                      }}
                                    >
                                      <img
                                        className="question-ans-list__image"
                                        src={iconText}
                                      />
                                    </div>

                                    <h5 className="question-ans-list__title">
                                      Text
                                    </h5>
                                  </div>
                                </div>
                                <div className="question-ans-list__body">
                                  <div className="">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      Question
                                    </label>
                                    <input
                                      className="input-field input-field--sm "
                                      placeholder="Type your question here"
                                    />
                                  </div>
                                  <div className="flex justify-end gap-2 mt-8">
                                    <button className="btn btn--grey btn--md min-w-120">
                                      Cancel
                                    </button>
                                    <button className="btn btn--primary btn--md min-w-120">
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <div
                                className="flex mt-4 mb-10 justify-center cursor-pointer items-center bg-grey-90 py-3 rounded-lg"
                                onClick={() => handleAddQuestion()}
                              >
                                <img
                                  src={iconPlusGreen}
                                  alt=""
                                  style={{
                                    marginRight: 8,
                                    width: 10,
                                    height: 10,
                                  }}
                                />
                                <div className="text-sm font-semibold text-primary">
                                  Add Question
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {selectedOption === "Multi select" && (
                        <div className="contact-questions">
                          <div className="mt-6">
                            <h3 className="text-lg font-semibold">
                              Multi Select
                            </h3>
                            <div className="question-ans-list">
                              <div className="question-ans-list__title-wrapper">
                                <h5 className="question-ans-list__title">
                                  Select your nonveg items
                                </h5>
                                <div className="relative cursor-pointer">
                                  <img
                                    src={iconDots}
                                    alt="icon-dots"
                                    onClick={() =>
                                      setDropDownOpen(!dropdownOpen)
                                    }
                                  />
                                  <div
                                    className={`dropdown-menu ${
                                      dropdownOpen && "open"
                                    }`}
                                    style={{
                                      width: 180,
                                      left: -154,
                                      top: 8,
                                      padding: 8,
                                    }}
                                  >
                                    <div className="dropdown-menu__list items-center">
                                      <img
                                        src={iconEdit}
                                        alt="icon-edit"
                                        className="mr-2"
                                      />
                                      Edit
                                    </div>
                                    <div
                                      className="dropdown-menu__list items-center"
                                      style={{ color: "#F04438" }}
                                    >
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mr-2"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                          fill="#F04438"
                                        />
                                      </svg>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="question-ans-list__body">
                                <div className="relative mt-3 gap-4">
                                  <div className="flex items-center mt-4">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox custom-checkbox--sm"
                                      name="chicken"
                                      onChange={handleSelectNonVegItemChange}
                                      checked={selectNonVegItem.chicken}
                                    />
                                    <span
                                      className={`text-md text-grey-800 ${
                                        selectNonVegItem.chicken
                                          ? "font-semibold"
                                          : ""
                                      } ml-2`}
                                    >
                                      Chicken
                                    </span>
                                  </div>
                                  <div className="flex items-center mt-4">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox custom-checkbox--sm"
                                      name="mutton"
                                      onChange={handleSelectNonVegItemChange}
                                      checked={selectNonVegItem.mutton}
                                    />
                                    <span
                                      className={`text-md text-grey-800 ${
                                        selectNonVegItem.mutton
                                          ? "font-semibold"
                                          : ""
                                      } ml-2`}
                                    >
                                      Mutton
                                    </span>
                                  </div>
                                  <div className="flex items-center mt-4">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox custom-checkbox--sm"
                                      name="lamb"
                                      onChange={handleSelectNonVegItemChange}
                                      checked={selectNonVegItem.lamb}
                                    />
                                    <span
                                      className={`text-md text-grey-800 ${
                                        selectNonVegItem.lamb
                                          ? "font-semibold"
                                          : ""
                                      } ml-2`}
                                    >
                                      Lamb
                                    </span>
                                  </div>
                                  <div className="flex items-center mt-4">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox custom-checkbox--sm"
                                      name="fish"
                                      onChange={handleSelectNonVegItemChange}
                                      checked={selectNonVegItem.fish}
                                    />
                                    <span
                                      className={`text-md text-grey-800 ${
                                        selectNonVegItem.fish
                                          ? "font-semibold"
                                          : ""
                                      } ml-2`}
                                    >
                                      Fish
                                    </span>
                                  </div>
                                  <div className="flex items-center mt-4">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox custom-checkbox--sm"
                                      name="pork"
                                      onChange={handleSelectNonVegItemChange}
                                      checked={selectNonVegItem.pork}
                                    />
                                    <span
                                      className={`text-md text-grey-800 ${
                                        selectNonVegItem.pork
                                          ? "font-semibold"
                                          : ""
                                      } ml-2`}
                                    >
                                      Pork
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <li className="question-ans-list">
                              <div className="question-ans-list__title-wrapper">
                                <div className="flex items-center ">
                                  <div
                                    style={{
                                      background: "#EAF3F6",
                                      borderRadius: "5px",
                                      padding: "9px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="1.45508"
                                        y="1.46094"
                                        width="13.0909"
                                        height="13.0909"
                                        rx="3"
                                        stroke="#2B3639"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M10.6182 6.03906L7.0182 9.63906L5.38184 8.0027"
                                        stroke="#2B3639"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div>
                                  <h5 className="question-ans-list__title">
                                    Multiple Select
                                  </h5>
                                </div>
                              </div>
                              <div className="question-ans-list__body">
                                <div className="">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Question
                                  </label>
                                  <input
                                    className="input-field input-field--sm "
                                    placeholder="Type your question here"
                                  />
                                </div>
                                <div className="w-1/2 mt-5 relative">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Option 1
                                  </label>
                                  <input
                                    className="input-field input-field--sm "
                                    placeholder="Type option"
                                  />
                                  <img
                                    src={modalCloseIcon}
                                    style={{
                                      position: "absolute",
                                      top: "38px",
                                      right: "-20px",
                                      cursor: "pointer",
                                    }}
                                    alt="icon-close"
                                  />
                                </div>
                                <div className="w-1/2 mt-5 relative">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Option 2
                                  </label>
                                  <input
                                    className="input-field input-field--sm "
                                    placeholder="Type option"
                                  />
                                  <img
                                    src={modalCloseIcon}
                                    style={{
                                      position: "absolute",
                                      top: "38px",
                                      right: "-20px",
                                      cursor: "pointer",
                                    }}
                                    alt="icon-close"
                                  />
                                  <img
                                    style={{
                                      position: "absolute",
                                      top: "36px",
                                      left: "-12px",
                                    }}
                                    src={iconUnion}
                                  />
                                </div>
                                <div className="w-1/2 mt-5 relative">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Add option
                                  </label>
                                  <input
                                    className="input-field input-field--sm "
                                    placeholder="Add option"
                                  />
                                </div>
                                <div className="flex justify-end gap-2 mt-8">
                                  <button className="btn btn--grey btn--md min-w-120">
                                    Cancel
                                  </button>
                                  <button className="btn btn--primary btn--md min-w-120">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </li>
                            <div
                              className="flex mt-4 mb-10 justify-center cursor-pointer items-center bg-grey-90 py-3 rounded-lg"
                              onClick={() => handleAddQuestion()}
                            >
                              <img
                                src={iconPlusGreen}
                                alt=""
                                style={{
                                  marginRight: 8,
                                  width: 10,
                                  height: 10,
                                }}
                              />
                              <div className="text-sm font-semibold text-primary">
                                Add Question
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedOption === "Value" && (
                        <>
                          <div className="contact-questions">
                            <div className="mt-6">
                              <h3 className="text-lg font-semibold">Value</h3>
                              <div className="question-ans-list">
                                <div className="question-ans-list__title-wrapper">
                                  <h5 className="question-ans-list__title">
                                    Price
                                  </h5>
                                  <div className="relative cursor-pointer">
                                    <img
                                      src={iconDots}
                                      alt="icon-dots"
                                      onClick={() =>
                                        setDropDownOpen(!dropdownOpen)
                                      }
                                    />
                                    <div
                                      className={`dropdown-menu ${
                                        dropdownOpen && "open"
                                      }`}
                                      style={{
                                        width: 180,
                                        left: -154,
                                        top: 8,
                                        padding: 8,
                                      }}
                                    >
                                      <div className="dropdown-menu__list items-center">
                                        <img
                                          src={iconEdit}
                                          alt="icon-edit"
                                          className="mr-2"
                                        />
                                        Edit
                                      </div>
                                      <div
                                        className="dropdown-menu__list items-center"
                                        style={{ color: "#F04438" }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="mr-2"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                            fill="#F04438"
                                          />
                                        </svg>
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="question-ans-list__body">
                                  <div className="relative mt-3">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      Currency options
                                    </label>
                                    <div
                                      className="relative date-container"
                                      style={{ width: "60%" }}
                                      onClick={() =>
                                        setValuedropDown(!valueDropDown)
                                      }
                                    >
                                      <input
                                        className="input-field input-field--sm input-field--select w-full"
                                        placeholder="Please Select"
                                      />
                                      <span
                                        class="open-button"
                                        style={{
                                          position: "absolute",
                                          right: 38,
                                          top: 7,
                                        }}
                                      >
                                        USD
                                      </span>
                                    </div>

                                    <div
                                      className={`dropdown-menu ${
                                        valueDropDown && "open"
                                      }`}
                                      style={{
                                        width: "60%",
                                        maxHeight: 300,
                                        overflow: "overlay",
                                        padding: 8,
                                      }}
                                    >
                                      <div className="dropdown-menu__list rounded-md">
                                        <img
                                          className="mr-2"
                                          src={iconUsflag}
                                        />
                                        GBP - Pound Sterling
                                      </div>
                                      <div className="dropdown-menu__list rounded-md">
                                        <img
                                          className="mr-2"
                                          src={iconUsflag}
                                        />
                                        EUR - Euro
                                      </div>
                                      <div className="dropdown-menu__list rounded-md selected ">
                                        <img
                                          className="mr-2"
                                          src={iconUsflag}
                                        />
                                        USD - US Dollar
                                      </div>
                                      <div className="dropdown-menu__list rounded-md">
                                        <img
                                          className="mr-2"
                                          src={iconUsflag}
                                        />
                                        AUD - Australian Dollar
                                      </div>
                                      <div className="dropdown-menu__list rounded-md">
                                        <img
                                          className="mr-2"
                                          src={iconUsflag}
                                        />
                                        AED - Emirati Dirhams
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <li className="question-ans-list">
                            <div className="question-ans-list__title-wrapper">
                              <div className="flex items-center ">
                                <img
                                  className="question-ans-list__image"
                                  src={iconValue}
                                  style={{
                                    background: "#EAF3F6",
                                    borderRadius: "5px",
                                    padding: "9px",
                                    marginRight: "8px",
                                  }}
                                />
                                <h5 className="question-ans-list__title">
                                  Value
                                </h5>
                              </div>
                            </div>
                            <div className="question-ans-list__body">
                              <div className="">
                                <label
                                  className="input-field-label"
                                  style={{ fontSize: 14 }}
                                >
                                  Question
                                </label>
                                <input
                                  className="input-field input-field--sm "
                                  placeholder="Type your question here"
                                />
                              </div>

                              <div className="relative mt-3">
                                <label
                                  className="input-field-label"
                                  style={{ fontSize: 14 }}
                                >
                                  Currency options
                                </label>
                                <input
                                  className="input-field input-field--sm input-field--select"
                                  style={{ width: "60%" }}
                                  placeholder="Please Select"
                                />
                                <div
                                  className={`dropdown-menu ${
                                    dropdownOpen && "open"
                                  }`}
                                  style={{
                                    width: "60%",
                                    maxHeight: 300,
                                    overflow: "overlay",
                                    padding: 8,
                                  }}
                                >
                                  <div className="dropdown-menu__list">
                                    <img className="mr-2" src={iconUsflag} />
                                    GBP - Pound Sterling
                                  </div>
                                  <div className="dropdown-menu__list">
                                    <img className="mr-2" src={iconUsflag} />
                                    EUR - Euro
                                  </div>
                                  <div className="dropdown-menu__list selected">
                                    <img className="mr-2" src={iconUsflag} />
                                    USD - US Dollar
                                  </div>
                                  <div className="dropdown-menu__list">
                                    <img className="mr-2" src={iconUsflag} />
                                    AUD - Australian Dollar
                                  </div>
                                  <div className="dropdown-menu__list">
                                    <img className="mr-2" src={iconUsflag} />
                                    AED - Emirati Dirhams
                                  </div>
                                </div>
                                <div className="mt-5">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Option
                                  </label>
                                  <div className="flex items-center mt-4">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox custom-checkbox--sm"
                                      name=""
                                    />
                                    <span className="text-sm text-grey-800 ml-2">
                                      This question will represent the stage
                                      total count on each stage.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-end gap-2 mt-8">
                                <button className="btn btn--grey btn--md min-w-120">
                                  Cancel
                                </button>
                                <button className="btn btn--primary btn--md min-w-120">
                                  Save
                                </button>
                              </div>
                            </div>
                          </li>
                          <div
                            className="flex mt-4 justify-center cursor-pointer items-center bg-grey-90 py-3 rounded-lg"
                            onClick={() => handleAddQuestion()}
                          >
                            <img
                              src={iconPlusGreen}
                              alt=""
                              style={{
                                marginRight: 8,
                                width: 10,
                                height: 10,
                              }}
                            />
                            <div className="text-sm font-semibold text-primary">
                              Add Question
                            </div>
                          </div>
                        </>
                      )}
                      {selectedOption === "Dropdown" && (
                        <>
                          <div className="contact-questions">
                            <div className="mt-6">
                              <h3 className="text-lg font-semibold">
                                Drop Down
                              </h3>
                            </div>
                            <div className="question-ans-list">
                              <div className="question-ans-list__title-wrapper">
                                <h5 className="question-ans-list__title">
                                  Which food type do you want?
                                </h5>
                                <div className="relative cursor-pointer">
                                  <img
                                    src={iconDots}
                                    alt="icon-dots"
                                    onClick={() =>
                                      setDropDownOpen(!dropdownOpen)
                                    }
                                  />
                                  <div
                                    className={`dropdown-menu ${
                                      dropdownOpen && "open"
                                    }`}
                                    style={{
                                      width: 180,
                                      left: -154,
                                      top: 8,
                                      padding: 8,
                                    }}
                                  >
                                    <div className="dropdown-menu__list items-center">
                                      <img
                                        src={iconEdit}
                                        alt="icon-edit"
                                        className="mr-2"
                                      />
                                      Edit
                                    </div>
                                    <div
                                      className="dropdown-menu__list items-center"
                                      style={{ color: "#F04438" }}
                                    >
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mr-2"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                          fill="#F04438"
                                        />
                                      </svg>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="question-ans-list__body">
                                <div className="relative mt-3">
                                  <input
                                    className="input-field input-field--sm input-field--select"
                                    style={{ width: "60%" }}
                                    placeholder="Please Select"
                                  />
                                  <div
                                    className="dropdown-menu"
                                    style={{
                                      width: "60%",
                                      maxHeight: 300,
                                      overflow: "overlay",
                                    }}
                                  >
                                    <div className="dropdown-menu__list">
                                      Food Type1
                                    </div>
                                    <div className="dropdown-menu__list">
                                      Food Type2
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="question-ans-list">
                              <div className="question-ans-list__title-wrapper">
                                <div className="flex items-center ">
                                  <img
                                    className="question-ans-list__image"
                                    src={iconDropDown}
                                    style={{
                                      background: "#EAF3F6",
                                      borderRadius: "5px",
                                      padding: "9px",
                                      marginRight: "8px",
                                    }}
                                  />
                                  <h5 className="question-ans-list__title">
                                    Dropdown
                                  </h5>
                                </div>
                              </div>
                              <div className="question-ans-list__body">
                                <div className="">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Question
                                  </label>
                                  <input
                                    className="input-field input-field--sm "
                                    placeholder="Type your question here"
                                  />
                                </div>
                                <div className="w-1/2 mt-5 relative">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Option 1
                                  </label>
                                  <input
                                    className="input-field input-field--sm "
                                    placeholder="Type option"
                                  />
                                  <img
                                    src={modalCloseIcon}
                                    style={{
                                      position: "absolute",
                                      top: "38px",
                                      right: "-20px",
                                      cursor: "pointer",
                                    }}
                                    alt="icon-close "
                                  />
                                </div>
                                <div className="w-1/2 mt-5 relative">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Option 2
                                  </label>
                                  <input
                                    className="input-field input-field--sm "
                                    placeholder="Type option"
                                  />
                                  <img
                                    src={modalCloseIcon}
                                    style={{
                                      position: "absolute",
                                      top: "38px",
                                      right: "-20px",
                                      cursor: "pointer",
                                    }}
                                    alt="icon-close"
                                  />
                                  <img
                                    style={{
                                      position: "absolute",
                                      top: "36px",
                                      left: "-12px",
                                    }}
                                    src={iconUnion}
                                  />
                                </div>
                                <div className="w-1/2 mt-5 relative">
                                  <label
                                    className="input-field-label"
                                    style={{ fontSize: 14 }}
                                  >
                                    Add option
                                  </label>
                                  <input
                                    className="input-field input-field--sm "
                                    placeholder="Add option"
                                  />
                                </div>
                                <div className="flex justify-end gap-2 mt-8">
                                  <button className="btn btn--grey btn--md min-w-120">
                                    Cancel
                                  </button>
                                  <button className="btn btn--primary btn--md min-w-120">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className="flex mt-4 mb-10 justify-center cursor-pointer items-center bg-grey-90 py-3 rounded-lg"
                              onClick={() => handleAddQuestion()}
                            >
                              <img
                                src={iconPlusGreen}
                                alt=""
                                style={{
                                  marginRight: 8,
                                  width: 10,
                                  height: 10,
                                }}
                              />
                              <div className="text-sm font-semibold text-primary">
                                Add Question
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {selectedOption === "Date" && (
                        <>
                          <div className="contact-questions">
                            <div className="mt-6">
                              <h3 className="text-lg font-semibold">Date</h3>
                              <div className="question-ans-list">
                                <div className="question-ans-list__title-wrapper">
                                  <h5 className="question-ans-list__title">
                                    Which date is your party?
                                  </h5>
                                  <div className="relative cursor-pointer">
                                    <img
                                      src={iconDots}
                                      alt="icon-dots"
                                      onClick={() =>
                                        setDropDownOpen(!dropdownOpen)
                                      }
                                    />
                                    <div
                                      className={`dropdown-menu ${
                                        dropdownOpen && "open"
                                      }`}
                                      style={{
                                        width: 180,
                                        left: -154,
                                        top: 8,
                                        padding: 8,
                                      }}
                                    >
                                      <div className="dropdown-menu__list items-center">
                                        <img
                                          src={iconEdit}
                                          alt="icon-edit"
                                          className="mr-2"
                                        />
                                        Edit
                                      </div>
                                      <div
                                        className="dropdown-menu__list items-center"
                                        style={{ color: "#F04438" }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="mr-2"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                            fill="#F04438"
                                          />
                                        </svg>
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="question-ans-list__body">
                                  <div className="date-container relative mt-3 w-1/2">
                                    <input
                                      className="input-field input-field--sm input-field--icon-date"
                                      type="date"
                                      name="selected_date"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="question-ans-list">
                                <div className="question-ans-list__title-wrapper">
                                  <h5 className="question-ans-list__title">
                                    Which date is your party?
                                  </h5>
                                  <div className="relative cursor-pointer">
                                    <img
                                      src={iconDots}
                                      alt="icon-dots"
                                      onClick={() =>
                                        setDropDownOpen(!dropdownOpen)
                                      }
                                    />
                                    <div
                                      className={`dropdown-menu ${
                                        dropdownOpen && "open"
                                      }`}
                                      style={{
                                        width: 180,
                                        left: -154,
                                        top: 8,
                                        padding: 8,
                                        visibility: "hidden",
                                      }}
                                    >
                                      <div className="dropdown-menu__list items-center">
                                        <img
                                          src={iconEdit}
                                          alt="icon-edit"
                                          className="mr-2"
                                        />
                                        Edit
                                      </div>
                                      <div
                                        className="dropdown-menu__list items-center"
                                        style={{ color: "#F04438" }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="mr-2"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                            fill="#F04438"
                                          />
                                        </svg>
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="question-ans-list__body flex">
                                  <div className="relative mt-3 w-1/2 mr-2">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      From
                                    </label>
                                    <div class="date-container">
                                      <input
                                        className="input-field input-field--sm input-field--icon-date"
                                        type="date"
                                        name="selected_date"
                                      />
                                    </div>
                                  </div>
                                  <div className="relative mt-3 w-1/2">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      To
                                    </label>
                                    <div class="date-container">
                                      <input
                                        className="input-field input-field--sm input-field--icon-date"
                                        type="date"
                                        name="selected_date"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <li className="question-ans-list">
                                <div className="question-ans-list__title-wrapper">
                                  <div className="flex items-center">
                                    <div
                                      style={{
                                        background: "#EAF3F6",
                                        borderRadius: "5px",
                                        padding: "9px",
                                        marginRight: "8px",
                                      }}
                                    >
                                      <svg
                                        width="14"
                                        height="12"
                                        viewBox="0 0 14 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 3.66406C1 2.55949 1.89543 1.66406 3 1.66406H11C12.1046 1.66406 13 2.55949 13 3.66406V9.26406C13 10.3686 12.1046 11.2641 11 11.2641H3C1.89543 11.2641 1 10.3686 1 9.26406V3.66406Z"
                                          stroke="#2B3639"
                                          stroke-width="1.2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M1 4.14844H13"
                                          stroke="#2B3639"
                                          stroke-width="1.2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M4 1.26719V0.867188"
                                          stroke="#2B3639"
                                          stroke-width="1.2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M9.70117 1.26719V0.867188"
                                          stroke="#2B3639"
                                          stroke-width="1.2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <h5 className="question-ans-list__title">
                                      Date
                                    </h5>
                                  </div>
                                </div>
                                <div className="question-ans-list__body">
                                  <div className="">
                                    <label
                                      className="input-field-label"
                                      style={{ fontSize: 14 }}
                                    >
                                      Question
                                    </label>
                                    <input
                                      className="input-field input-field--sm "
                                      placeholder="Type your question here"
                                    />
                                  </div>

                                  <div className="relative mt-3">
                                    <div className="mt-5">
                                      <label
                                        className="input-field-label"
                                        style={{ fontSize: 14 }}
                                      >
                                        Date range
                                      </label>
                                      <div className="flex items-center mt-4">
                                        <input
                                          type="checkbox"
                                          className="custom-checkbox custom-checkbox--sm"
                                          name=""
                                        />
                                        <span className="text-sm text-grey-800 ml-2">
                                          Make this a date range instead of a
                                          single date
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex justify-end gap-2 mt-8">
                                    <button className="btn btn--grey btn--md min-w-120">
                                      Cancel
                                    </button>
                                    <button className="btn btn--primary btn--md min-w-120">
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <div
                                className="flex mt-4 mb-10 justify-center cursor-pointer items-center bg-grey-90 py-3 rounded-lg"
                                onClick={() => handleAddQuestion()}
                              >
                                <img
                                  src={iconPlusGreen}
                                  alt=""
                                  style={{
                                    marginRight: 8,
                                    width: 10,
                                    height: 10,
                                  }}
                                />
                                <div className="text-sm font-semibold text-primary">
                                  Add Question
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {!showAddQuestion && !showModals && (
                <div className="pb-10">
                  <AddQuestions
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    setShowModal={setShowModals}
                    showModal={showModals}
                  />
                </div>
              )}
            </div>
          )}
          {activeScreen === "history" && <History isContactPage={true} />}
          {activeScreen === "files" && (
            <Files
              setShowImageModal={setShowImageModal}
              setOpenUpload={setOpenUpload}
            />
          )}
        </div>
      </div>
      <div className={`modal ${showDeletAlert ? "show" : ""}`}>
        <div className="modal__container">
          <div
            className="modal__header modal__header--plain"
            style={{ justifyContent: "flex-end" }}
          >
            <div
              className="modal__close-icon"
              onClick={() => setShowDeleteAlert(false)}
            >
              <img src={modalCloseIcon} alt="" />
            </div>
          </div>

          <div className="modal__body px-8 pb-6">
            <p className="text-center text-xl text-grey-900 font-semibold px-12">
              Are you sure you want to delete these “{listIndex.length}”
              contacts.
            </p>
            <div className="flex justify-center mt-12 gap-2">
              <button className="btn btn--grey btn--md min-w-120">
                Cancel
              </button>
              <button className="btn btn--red btn--md min-w-120 text-white">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalUploadMultiContactSetup1
        showUploadContactModal={showUploadContactModal}
        setShowUploadContactModal={setShowUploadContactModal}
      />
      <ImageModal
        image={showImageModal === 1 ? fullImage1 : fullImage2}
        showModal={showImageModal === 0 ? false : true}
        setShowImageModal={setShowImageModal}
        showImageModal={showImageModal}
      />
      <UploadFile
        setShowFileUpload={setOpenUpload}
        showFileUpload={openUpload}
      />
    </>
  );
};

export default ToggleContacts;
