import React, { useState } from "react";
import { fileIcons } from "../constants/constants";
import iconDownload from "../../assets/icons/icon-download.svg";
import iconTrash from "../../assets/icons/icon-trash.svg";

const AttachmentItem = ({
  attachment = { name: "" },
  message = true, //if this component is used in conversation message keep this as true. If you're in editor and sending a message keep this as false
}) => {
  const [showIcon, setShowIcon] = useState(false);
  let splittedName = attachment?.name?.split(".") || [];
  return (
    <div
      className="attachment-item cursor-pointer flex items-center px-4 py-2 bg-white"
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      <img
        className="file-icon"
        src={fileIcons[splittedName?.slice(-1)[0]]}
        alt="file"
      />
      <p className="truncate text-13 text-grey-900 ml-1" style={{ flex: 1 }}>
        {attachment?.name}
      </p>
      {message ? (
        <img
          className={`secondary-icon ${showIcon && "secondary-icon__visible"}`}
          src={iconDownload}
          alt="download"
        />
      ) : (
        <img
          className={`secondary-icon ${showIcon && "secondary-icon__visible"}`}
          src={iconTrash}
          alt="remove"
        />
      )}
    </div>
  );
};

export default AttachmentItem;
