import React, { useState } from "react";
import { Link } from "react-router-dom";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import Header from "./Header";
import ModalConnectChannel from "./modal/ModalConnectChannel";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from './Common/GeneralSettingsHedder';
import DMInbox from "./Common/DMInbox";
import { useHistory } from "react-router-dom";
import ChatPreviewList from "./ChatPreviewList";
import ChatPreviewListSample from "./ChatPreviewListSample";
import HelperVideo from "./Common/HelperVideo";
import ChannelConnectLinks from "./Common/ChannelConnectLinks";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import iconCheck from "../assets/icons/Group5569.svg";
import iconCross from "../assets/icons/Group12629.svg";
import SidebarLinksNew from "./Common/SideBarLinksNew";
const DashboardFacebook = () => {
  // const [modalShow, hideModalShow] = useState(true);
  let history = useHistory();
  const [facebookFailed, setFacebookFailed] = useState(false)
  const [activeScreen, setActiveScreen] = useState(1);
  const nextScreen = () => {
    history.push("/channel-connected-page");
    setFacebookFailed(true);
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew />
        <section className="dashboard__main">
          <GeneralSettingsHedder text="Channel Settings" />
          <div className="dashboard__main__body " style={{ height: 'calc(100% - 79px)' }}>
            <div className="dashboard__main__body__section-left overflow-overlay">
              <ChannelConnectLinks facebookFailed={facebookFailed}
                show={true}
                facebookReconnect={true}
                activeScreen={1}
                setActiveScreen={setActiveScreen}
              />
            </div>
            {<div
              className="dashboard__main__body__section-right layout-1"
              id="rightSide"
            >
              <div className="card-container--new">
                <div>
                  <h2 className="font-semibold text-lg">
                    Connect a Facebook page
                  </h2>
                  <p className="text-grey-800 text-sm mt-3 pr-16">
                    By connecting your Facebook page you will be able to receive
                    and reply to messages, right here on this channel.
                  </p>
                </div>
                <div className="mt-8">
                  <h4 className="font-bold">
                    To connect your account, you need these 2 things
                  </h4>
                  <ul className="mt-3 ml-1">
                    <li className="text-sm font-medium">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      A Facebook Business page
                    </li>
                    <li className="text-sm font-medium mt-2">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      Admin level access to that page
                    </li>
                  </ul>
                </div>
                <div className="bg-grey-200 rounded-xl py-3 px-4 mt-8 pr-16">
                  <p className="text-grey-800 text-13">When you connect your page, we only access your page messages. Your personal messages will never be accessed by EnquiryBox.</p>
                </div>
                <div className="mt-5">
                  <button
                    className="btn btn--sm btn--facebook gap-2"
                    onClick={nextScreen}
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                        fill="#ffffff"
                      />
                    </svg>
                    Connect my Facebook Page
                  </button>
                </div>
                <div className="mt-8">
                  <HelperVideo />
                </div>
              </div>
              {/* <div className="card-container">
                <Header
                  name="Connect a Facebook Page"
                  id="fb-header"
                  mainId="rightSide"
                />
                <div className="video-container mt-4">
                  <img
                    src={contactFormPlaceholder}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
                <div className="mt-4">
                  <h3 className="text-grey-900 font-bold">
                    You only need 2 things to connect a page
                  </h3>
                  <ul className="mt-4">
                    <li className="text-sm text-grey-900 font-medium">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      The Facebook Business Page you want to connect
                    </li>
                    <li className="text-sm text-grey-900 font-medium mt-3">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      Admin level access to that page
                    </li>
                  </ul>
                  <p className="text-13 font-medium text-grey-800 mt-8">
                    When you login & connect your Facebook page, we only access
                    your page messages. Your private messages can never be
                    accessed by EnquiryBot. Need help or have a question? Read
                    our setup guide
                  </p>
                  <button
                    className="btn btn--sm btn--facebook gap-2 mt-8"
                    onClick={nextScreen}
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                        fill="#ffffff"
                      />
                    </svg>
                    Connect my Facebook Page
                  </button>
                </div>
              </div> */}
            </div>}
          </div>
        </section>
      </section>
      {/* <ModalConnectChannel /> */}
    </main>
  );
};

export default DashboardFacebook;
