import React, { useState } from 'react';
import plusIcon from '../assets/icons/icon-plus-icon.svg';
import avatarPic from "../assets/images/avatar-pic.png";
import lockIcon from '../assets/icons/icon-lock.svg';

import InviteModal from './modal/InviteModal';

const TeamAccess = ({ data }) => {
	const [isInviteOnly, setIsInviteOnly] = useState(false);
	const [addTeamMember, setAddTeamMember] = useState(false);

	return (
		<div>
			<h2 className="text-2xl font-semibold text-center">
				Team access
			</h2>
			<div className='card-box flex justify-between items-center py-5 px-6 mt-8'>
				<div className='flex items-center'>
					{isInviteOnly && <img src={lockIcon} alt='lock-icon' className='mr-2' style={{ width: 14, height: 16,}} />}
					<div className='font-semibold'>{isInviteOnly ? 'This channel is invite only' : 'This channel is open for all'}</div>
				</div>
				<div className='font-semibold text-sm text-primary cursor-pointer' onClick={() => setIsInviteOnly(!isInviteOnly)}>{isInviteOnly ? 'Change to open for all' : 'Change to invite only'}</div>
			</div>
			<div className='card-box mt-5 py-5 px-6'>
				<div className='flex justify-between'>
					<div className='font-semibold text-lg'>Channel members</div>
					<div>
						<button
							className="btn btn--primary text-13 text-white"
							style={{ padding: "5px 14px", borderRadius: 6 }}
							onClick={() => setAddTeamMember(true)}
						>
							<img src={plusIcon} alt='' style={{ width: 8, height: 8, marginRight: 6 }} />
							Add member
						</button>
					</div>
				</div>
				<ul className='mt-5'>
					{data.map((ele, index) => {
						return (
							<li
								key={index}
								className="teams-profile-list justify-between bg-color-on-hover"
							>
								<figure
									className="avatar-container avatar-container--full"
									style={{ width: 32, height: 32 }}
								>
									<img src={avatarPic} alt="avatar-pic" />
								</figure>
								<div className="teams-profile-list__title">
									{ele.name}
								</div>
								<div className="ml-auto hover-visible">
									<span className="text-sm font-semibold text-red-900">
										Remove
									</span>
								</div>
							</li>
						)
					})}
				</ul>
			</div>
			<InviteModal addTeamMember={addTeamMember} setAddTeamMember={setAddTeamMember}/>
		</div>
	)
}

export default TeamAccess;