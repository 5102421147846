import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/eb-logo.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from './Common/GeneralSettingsHedder';
import { useHistory } from "react-router-dom";
import ChatPreviewListSample from "./ChatPreviewListSample";
import iconHelpVideo from '../assets/icons/icon-help-video.svg';
import HelperVideo from "./Common/HelperVideo";
import ChannelHeaderNew from "./Common/ChannelHeaderNew";
import ChannelConnectLinks from "./Common/ChannelConnectLinks";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";

const DashboardInstagram = () => {
  let history = useHistory();
  const [instgramFailed, setInstagramFailed] = useState(false)
  const [activeScreen, setActiveScreen] = useState(1);

  const nextScreen = () => {
    history.push("/channel-connected-page");
    setInstagramFailed(true);
  };
  // const [modalShow, hideModalShow] = useState(true);
  // window.onload = function () {
  //   document.getElementById("rightSide").addEventListener("scroll", myFunction);
  //   function myFunction() {
  //     var header = document.getElementById("myHeader");
  //     var sticky = 80;
  //     var scrollY = document.getElementById("rightSide").scrollTop;
  //     if (scrollY >= sticky) {
  //       header.classList.add("sticky");
  //     } else {
  //       header.classList.remove("sticky");
  //     }
  //   }
  // };

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew />
        <section className="dashboard__main">
          <GeneralSettingsHedder text="Channel Settings" />

          <div className="dashboard__main__body " style={{ height: 'calc(100% - 79px)' }}>
            <div className="dashboard__main__body__section-left overflow-overlay">
              <ChannelConnectLinks
                instgramFailed={instgramFailed}
                show={true}
                activeScreen={2}
                setActiveScreen={setActiveScreen} />
            </div>
            <div
              className="dashboard__main__body__section-right layout-1"
              id="rightSide"
            >
              <div className="card-container--new">
                <div>
                  <h2 className="font-semibold text-lg">Connect an Instagram account </h2>
                  <p className="text-grey-800 text-sm mt-3">By connecting your Instagram account you will be able to receive and reply to messages, right here on this channel.</p>
                </div>
                <div className="mt-8">
                  <h4 className="font-bold">To connect your account, you need these 3 things</h4>
                  <ul className="mt-3 ml-1">
                    <li className="text-sm font-medium">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      An Instagram Business account
                    </li>
                    <li className="text-sm font-medium mt-2">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      Your Instagram account, linked to your Facebook page
                    </li>
                    <li className="text-sm font-medium mt-2">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      Admin level access to that page
                    </li>
                  </ul>
                </div>
                <div className="bg-grey-200 rounded-xl py-3 px-4 mt-8">
                  <p className="text-grey-800 text-13">When you connect your account, we only access account messages. Your personal messages will never be accessed by EnquiryBox.</p>
                </div>
                <div className="mt-5">
                  <button
                    className="btn btn--sm btn--instagram text-base-1 gap-2"
                    onClick={nextScreen}
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
                        fill="#ffffff"
                      />
                    </svg>
                    Connect your account
                  </button>
                </div>
                <div className="mt-8">
                  <HelperVideo />
                </div>
              </div>
              {/* <div className="card-container">
                <h3 className="card-heading font-semibold" id="myHeader">
                  Connect a Instagram account
                </h3>
                <div className="video-container mt-4">
                  <img
                    src={contactFormPlaceholder}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
                <div className="mt-4">
                  <h3 className="font-bold">
                    You only need 2 things to connect a page
                  </h3>
                  <ul className="mt-4">
                    <li className="text-sm font-medium">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      The Instagram Business you want to connect
                    </li>
                    <li className="text-sm font-medium mt-3">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      Admin level access to that page
                    </li>
                    <li className="text-sm font-medium mt-3">
                      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                      Admin level access to that page
                    </li>
                  </ul>
                  <div className="text-13 font-medium text-grey-800 mt-8">
                    When you login & connect your Facebook page, we only access
                    your page messages. Your private messages can never be
                    accessed by EnquiryBot. Need help or have a question?{" "}
                    <p className="text-primary font-medium cursor-pointer">
                      Read our setup guide
                    </p>
                  </div>
                  <button
                    className="btn btn--sm btn--instagram gap-2 mt-8"
                    onClick={nextScreen}
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
                        fill="#ffffff"
                      />
                    </svg>
                    Connect your account
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default DashboardInstagram;
