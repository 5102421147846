import React, { useState } from "react";
import InputField from "../Common/InputField";

const EmojiModal = ({ className,hideSearch, setOpenEmoji }) => {
  const [inputVal, setInputVal] = useState("");

  return (
    <div className={`${!hideSearch ? 'dashboard__emoji-container' : 'dashboard__emoji-dropdown' } ${className}`}>
      {!hideSearch && <div className="mt-5 ml-5 mr-5">
        <InputField
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
          placeholder="Search"
          isMargin={true}
        />
      </div>}
      <div className={`mt-15 pl-5 ${hideSearch ? 'dashboard__overflow-y-scroll pr-5' : 'pr-1'}`}>
        <div className="text-grey-600 font-normal text-sm">Recent</div>
        <div className="mt-1">&#127795;</div>
        <div>
					<div className="flex justify-between items-center">
						<p className="text-grey-600 font-normal text-sm">People</p>
						<button className={`btn btn--emoji-filter btn--xs ${hideSearch ? '' : 'mr-83'}`}>
							Smiling face with smiling eyes
						</button>
					</div>       
          <div className='dashboard__emoji-list'>
            <div className="flex mt-2">
              <p className="mr-2 dashboard__emoji-icon" onClick={() => setOpenEmoji(false)}>&#128512;</p>
              <p className="mr-2 dashboard__emoji-icon" onClick={() => setOpenEmoji(false)}>&#128513;</p>
              <p className="mr-2 dashboard__emoji-icon" onClick={() => setOpenEmoji(false)}>&#128514;</p>
              <p className="mr-2 dashboard__emoji-icon" onClick={() => setOpenEmoji(false)}>&#128515;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128516;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128517;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128518;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128519;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128520;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128521;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128522;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128523;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128524;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128525;</p>
              <p className={`dashboard__emoji-icon ${hideSearch ? '' : 'mr-2'}`}>&#128526;</p>
              {!hideSearch && <p className="dashboard__emoji-icon">&#128527;</p>}
            </div>
            <div className="flex mt-2">
              <p className="mr-2 dashboard__emoji-icon" onClick={() => setOpenEmoji(false)}>&#128527;</p>
              <p className="mr-2 dashboard__emoji-icon" onClick={() => setOpenEmoji(false)}>&#128528;</p>
              <p className="mr-2 dashboard__emoji-icon" onClick={() => setOpenEmoji(false)}>&#128529;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128530;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128531;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128532;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128533;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128534;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128535;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128536;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128537;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128538;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128539;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128540;</p>
              <p className={`dashboard__emoji-icon ${hideSearch ? '' : 'mr-2'}`}>&#128541;</p>
              {!hideSearch && <p className="dashboard__emoji-icon">&#128542;</p>}
            </div>
            <div className="flex mt-2">
              <p className="mr-2 dashboard__emoji-icon">&#128543;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128544;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128545;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128546;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128547;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128548;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128549;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128550;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128551;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128552;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128553;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128554;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128555;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128556;</p>
              <p className={`dashboard__emoji-icon ${hideSearch ? '' : 'mr-2'}`}>&#128557;</p>
              {!hideSearch && <p className="dashboard__emoji-icon">&#128558;</p>}
            </div>
            <div className="flex mt-2">
              <p className="mr-2 dashboard__emoji-icon">&#128559;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128560;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128561;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128562;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128563;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128564;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128565;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128566;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128567;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128568;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128569;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128570;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128571;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128572;</p>
              <p className={`dashboard__emoji-icon ${hideSearch ? '' : 'mr-2'}`}>&#128573;</p>
              {!hideSearch && <p className="dashboard__emoji-icon">&#128574;</p>}
            </div>
            <div className="flex mt-2">
              <p className="mr-2 dashboard__emoji-icon">&#128512;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128513;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128514;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128515;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128516;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128517;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128518;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128519;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128520;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128521;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128522;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128523;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128524;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128525;</p>						
              <p className={`dashboard__emoji-icon ${hideSearch ? '' : 'mr-2'}`}>&#128526;</p>
							{!hideSearch && <p className="dashboard__emoji-icon">&#128527;</p>}
            </div>
            <div className='flex mt-2 mb-5'>
              <p className="mr-2 dashboard__emoji-icon">&#128512;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128513;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128514;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128515;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128516;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128517;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128518;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128519;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128520;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128521;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128522;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128523;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128524;</p>
              <p className="mr-2 dashboard__emoji-icon">&#128525;</p>						
              <p className={`dashboard__emoji-icon ${hideSearch ? '' : 'mr-2'}`}>&#128526;</p>
							{!hideSearch && <p className="dashboard__emoji-icon">&#128527;</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmojiModal;
