import React from "react";
const ChannelProfileList = ({ lists }) => {
  return (
    <ul className="flex flex-col">
      {lists.map((item, index) => (
        <li className="dashboard__sidebar__nav-list">
          <figure
            className="avatar-container avatar-container--contain"
            style={{
              width: 32,
              height: 32,
              padding: 7,
              backgroundColor: "#FAE050",
            }}
          >
            <img src={item.profilePic} alt="" className="cursor-pointer" />
            <div className="avatar-container__sup-icon avatar-container__sup-icon--sm">
              {/* <img
                src={item.subIcon}
                alt=""
                className="cursor-pointer"
              /> */}
            </div>
          </figure>
          <p className="text-base-1 font-medium text-grey-900">{item.name}</p>
        </li>
      ))}
    </ul>
  );
};
export default ChannelProfileList;
