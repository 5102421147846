import pdfIcon from "../../assets/icons/icon-file-upload-pdf.svg";
import pngIcon from "../../assets/icons/icon-file-upload-png.svg";
import docxIcon from "../../assets/icons/icon-file-upload-docx.svg";

export const fileIcons = {
  pdf: pdfIcon,
  png: pngIcon,
  docs: docxIcon,
  docx: docxIcon,
};
