import React from "react";
import iconPlusGreen from "../assets/icons/icon-plus-green.svg";
import iconNoQuestions from "../assets/icons/icon-no-questions.svg";

const NoQuestions = ({setShowAddQuestion}) => {
  return (
    <div className="card-box p-5 border-radius-14 mt-6 mb-10">
      <h3 className="card-heading font-semibold" id="myHeader">
        Questions
      </h3>
      <div className="flex justify-center item-center ">
        <figure className="bg-grey-300 rounded-full p-6 ">
          <img src={iconNoQuestions} />
        </figure>
      </div>
      <p className="font-medium text-center px-20 pt-5">
        Create a question to add additional information you collected to your
        enquiries.
      </p>
      <div className="flex mt-4 justify-center cursor-pointer items-center bg-grey-90 py-3 rounded-lg" onClick={()=>setShowAddQuestion(false)}>
        <img
          src={iconPlusGreen}
          alt=""
          style={{ marginRight: 8, width: 10, height: 10 }}
        />
        <div className="text-sm font-semibold text-primary" >Add Question</div>
      </div>
    </div>
  );
};

export default NoQuestions;
