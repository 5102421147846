import { useEffect, useRef, useState } from "react";
import avatarPic from "../assets/images/avatar-pic.png";
import ChatFooterInputBox from "./ChatFooterInputBox";
import ChannelHeader from "./Common/ChannelHeader";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import ChatMessageHeader from "./Common/ChatMessageHeader";
import Details from "./Details";
import ImageModal from "./modal/ImageModal";
import UploadFile from "./modal/UploadFile";
import fullImage1 from "../assets/icons/icon-full-image1.svg";
import fullImage2 from "../assets/icons/icon-full-image2.svg";
import ChatPreviewList from "./ChatPreviewList";

const DashboardNote = () => {
  const [openTabs, setOpenTabs] = useState(false);
  const [showImageModal, setShowImageModal] = useState(0);
  const [openUpload, setOpenUpload] = useState(false);
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main">
          <ChannelHeader
            text={"Hunley Weddings"}
            linkText="Settings & Notifications"
            showToggle={true}
            HeaderIcon={() => (
              <figure className="avatar-container avatar-container--contain">
                <svg width="24" height="22" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M18 8.13559V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V8.13559H18ZM15.5 3C16.8807 3 18 4.11929 18 5.5V6.91525H2V5.5C2 4.11929 3.11929 3 4.5 3H15.5ZM4.44444 4.27119C4.07625 4.27119 3.77778 4.57852 3.77778 4.95763C3.77778 5.33674 4.07625 5.64407 4.44444 5.64407C4.81263 5.64407 5.11111 5.33674 5.11111 4.95763C5.11111 4.57852 4.81263 4.27119 4.44444 4.27119ZM6.66667 4.27119C6.29848 4.27119 6 4.57852 6 4.95763C6 5.33674 6.29848 5.64407 6.66667 5.64407C7.03486 5.64407 7.33333 5.33674 7.33333 4.95763C7.33333 4.57852 7.03486 4.27119 6.66667 4.27119Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay">
              <ChatPreviewList />
            </div>
            <div className="dashboard__main__body__section-right chat-message-container relative">
              <ChatMessageHeader
                setOpenTabs={setOpenTabs}
                openTabs={openTabs}
              />

              <div className="chat-message-body">
                <div className="chat-message-list flex">
                  <figure
                    className="avatar-container avatar-container--full"
                    style={{ width: 32, height: 32 }}
                  >
                    <img src={avatarPic} alt="avatar-pic" />
                  </figure>
                  <div className="" style={{ marginLeft: 6 }}>
                    <div>
                      <div
                        className="inline-block border border-grey-400 text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        Hi dude...
                      </div>
                    </div>
                    <div>
                      <div
                        className="inline-block border border-grey-400 text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 6,
                        }}
                      >
                        How is your job?
                      </div>
                    </div>
                    <div>
                      <div
                        className="inline-block border border-grey-400 text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 6,
                        }}
                      >
                        Are you doing anything fun this weekend at home?
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-xs text-grey-700"
                        style={{ marginTop: 6 }}
                      >
                        2d
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-message-list chat-message-list--sender flex">
                  <figure
                    className="avatar-container avatar-container--full"
                    style={{ width: 32, height: 32 }}
                  >
                    <img src={avatarPic} alt="avatar-pic" />
                  </figure>
                  <div className="" style={{ marginRight: 6 }}>
                    <div>
                      <div
                        className="inline-block  text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          backgroundColor: "#F4F9F9",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        Hi dude...
                      </div>
                    </div>
                    <div>
                      <div
                        className="inline-block  text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 6,
                          backgroundColor: "#F4F9F9",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        How is your job?
                      </div>
                    </div>
                    <div>
                      <div
                        className="inline-block text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 6,
                          backgroundColor: "#F4F9F9",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        Are you doing anything fun this weekend at home?
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-xs text-grey-700"
                        style={{ marginTop: 6 }}
                      >
                        2d
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-message-list flex">
                  <figure
                    className="avatar-container avatar-container--full"
                    style={{ width: 32, height: 32 }}
                  >
                    <img src={avatarPic} alt="avatar-pic" />
                  </figure>
                  <div className="" style={{ marginLeft: 6 }}>
                    <div>
                      <div
                        className="inline-block border border-grey-400 text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        Tristique bibendum finibus nec, consectetu.
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-xs text-grey-700"
                        style={{ marginTop: 6 }}
                      >
                        Now
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ChatFooterInputBox />
              <section
                className={`modal modal--contact ${openTabs ? "show" : ""}`}
              >
                <div className="modal__container">
                  <Details
                    setShowImageModal={setShowImageModal}
                    setOpenUpload={setOpenUpload}
                  />
                </div>
              </section>
            </div>
          </div>
        </section>
        <ImageModal
          image={showImageModal === 1 ? fullImage1 : fullImage2}
          showModal={showImageModal === 0 ? false : true}
          setShowImageModal={setShowImageModal}
          showImageModal={showImageModal}
        />
        <UploadFile setOpenUpload={setOpenUpload} openUpload={openUpload} />
      </section>
    </main>
  );
};

export default DashboardNote;
