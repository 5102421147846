import React, { useState } from 'react'
import ChannelHeader from './Common/ChannelHeader'
import SidebarExpanded from './Common/SidebarExpanded'
import SidebarLinks from './Common/SidebarLinks'
import avatarPic1 from "../assets/icons/icon-avatar.svg";
import NotificationWidget from './Common/NotificationWidget';
import iconWidget1 from '../assets/icons/icon-notification-widget-1.svg';
import iconWidget2 from '../assets/icons/icon-notification-widget-2.svg';
import iconWidget3 from '../assets/icons/icon-notification-widget-3.svg';

const EnquiryAssistantSummary = () => {
	const [activeTab, setActiveTab] = useState(1);
	
	const [openSnoozeOptions,setOpenSnoozeOptions] = useState(false);

	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />
				<section className="dashboard__main">
					<ChannelHeader
						text={"Enquiry Assistant"}
						linkText="Settings & Notifications"
						HeaderIcon={() => (
							<figure className="avatar-container avatar-container--contain">
								<img src={avatarPic1} alt='' style={{ width: 30, height: 30 }} />
							</figure>
						)}
						showToggle={false}
						addReminderBtn={true}
					/>
					<div className="enquiry-assistant-summary__body overflow-auto">
						<div className='enquiry-assistant-summary__header'>
							<div className="enquiry-assistant-summary__tabs-wrapper justify-center h-full">
								<div
									className={`enquiry-assistant-summary__tabs-header ${activeTab === 1 ? "enquiry-assistant-summary__tabs-header__active" : ""}`}
									onClick={() => setActiveTab(1)}
								>
									<div className='mr-1'>
										{activeTab === 1 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.0806 17H8.44531" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M9.76471 4.64844C7.49094 4.64844 5.64706 6.49232 5.64706 8.76608V8.79244V10.8282C5.64706 11.095 5.49635 11.338 5.25835 11.4574L4.84412 11.6641C4.32694 11.9235 4 12.4522 4 13.0303C4 13.8736 4.68353 14.5571 5.52682 14.5571H14.0026C14.8459 14.5571 15.5294 13.8736 15.5294 13.0303C15.5294 12.4522 15.2025 11.9235 14.6853 11.6649L14.2711 11.4582C14.0331 11.338 13.8824 11.095 13.8824 10.8282V8.79244V8.76608" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M14.8035 3.72362C15.7683 4.68844 15.7683 6.25273 14.8035 7.21756C13.8387 8.18238 12.2744 8.18238 11.3096 7.21756C10.3447 6.25273 10.3447 4.68844 11.3096 3.72362C12.2744 2.75879 13.8387 2.75879 14.8035 3.72362Z" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
										</svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.0806 17H8.44531" stroke="#636566" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M9.76471 4.64844C7.49094 4.64844 5.64706 6.49232 5.64706 8.76608V8.79244V10.8282C5.64706 11.095 5.49635 11.338 5.25835 11.4574L4.84412 11.6641C4.32694 11.9235 4 12.4522 4 13.0303C4 13.8736 4.68353 14.5571 5.52682 14.5571H14.0026C14.8459 14.5571 15.5294 13.8736 15.5294 13.0303C15.5294 12.4522 15.2025 11.9235 14.6853 11.6649L14.2711 11.4582C14.0331 11.338 13.8824 11.095 13.8824 10.8282V8.79244V8.76608" stroke="#636566" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M14.8035 3.72362C15.7683 4.68844 15.7683 6.25273 14.8035 7.21756C13.8387 8.18238 12.2744 8.18238 11.3096 7.21756C10.3447 6.25273 10.3447 4.68844 11.3096 3.72362C12.2744 2.75879 13.8387 2.75879 14.8035 3.72362Z" stroke="#636566" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
										</svg>}
									</div>
									All notifications
								</div>
								<div
									className={`font-sm enquiry-assistant-summary__tabs-header ${activeTab === 2 ? "enquiry-assistant-summary__tabs-header__active" : ""}`}
									onClick={() => setActiveTab(2)}
								>
									<div className='mr-1'>
										{activeTab === 2 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M3.33594 7.48437C3.33594 6.10366 4.45523 4.98438 5.83594 4.98438H14.1693C15.55 4.98438 16.6693 6.10366 16.6693 7.48437V13.3177C16.6693 14.6984 15.55 15.8177 14.1693 15.8177H5.83594C4.45522 15.8177 3.33594 14.6984 3.33594 13.3177V7.48437Z" stroke="#005C6F" stroke-width="1.3" />
											<path d="M6.66797 4.15625V4.57292" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" />
											<path d="M13.3359 4.15625V4.57292" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" />
											<path d="M3.33594 7.90625H16.6693" stroke="#005C6F" stroke-width="1.3" />
										</svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M3.33594 7.48437C3.33594 6.10366 4.45523 4.98438 5.83594 4.98438H14.1693C15.55 4.98438 16.6693 6.10366 16.6693 7.48437V13.3177C16.6693 14.6984 15.55 15.8177 14.1693 15.8177H5.83594C4.45522 15.8177 3.33594 14.6984 3.33594 13.3177V7.48437Z" stroke="#636566" stroke-width="1.3" />
											<path d="M6.66797 4.15625V4.57292" stroke="#636566" stroke-width="1.3" stroke-linecap="round" />
											<path d="M13.3359 4.15625V4.57292" stroke="#636566" stroke-width="1.3" stroke-linecap="round" />
											<path d="M3.33594 7.90625H16.6693" stroke="#636566" stroke-width="1.3" />
										</svg>}
									</div>
									Reminders
								</div>
								<div
									className={`font-sm enquiry-assistant-summary__tabs-header ${activeTab === 3 ? "enquiry-assistant-summary__tabs-header__active" : ""}`}
									onClick={() => setActiveTab(3)}
								>
									<div className='mr-1'>
										{activeTab === 3 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.8102 16.1891C12.0273 16.5125 11.2164 16.6641 10.418 16.6641C6.96641 16.6641 4.16797 13.8656 4.16797 10.4141C4.16797 6.9625 6.96641 4.16406 10.418 4.16406C13.8695 4.16406 16.668 6.9625 16.668 10.4141V11.4562C16.668 12.5344 15.793 13.4094 14.7148 13.4094C13.6367 13.4094 12.7617 12.5344 12.7617 11.4562V10.4141C12.7617 9.12031 11.7117 8.07031 10.418 8.07031C9.12422 8.07031 8.07422 9.12031 8.07422 10.4141C8.07422 11.7078 9.12422 12.7578 10.418 12.7578C10.811 12.795 11.7185 12.7355 12.2037 12.1998" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
										</svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.8102 16.1891C12.0273 16.5125 11.2164 16.6641 10.418 16.6641C6.96641 16.6641 4.16797 13.8656 4.16797 10.4141C4.16797 6.9625 6.96641 4.16406 10.418 4.16406C13.8695 4.16406 16.668 6.9625 16.668 10.4141V11.4562C16.668 12.5344 15.793 13.4094 14.7148 13.4094C13.6367 13.4094 12.7617 12.5344 12.7617 11.4562V10.4141C12.7617 9.12031 11.7117 8.07031 10.418 8.07031C9.12422 8.07031 8.07422 9.12031 8.07422 10.4141C8.07422 11.7078 9.12422 12.7578 10.418 12.7578C10.811 12.795 11.7185 12.7355 12.2037 12.1998" stroke="#636566" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
										</svg>}
									</div>
									Mentions
								</div>
								<div
									className={`font-sm enquiry-assistant-summary__tabs-header ${activeTab === 4 ? "enquiry-assistant-summary__tabs-header__active" : ""}`}
									onClick={() => setActiveTab(4)}
								>
									<div className='mr-1'>
										{activeTab === 4 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M13.5 4H6.5C5.11929 4 4 5.11929 4 6.5V13.5C4 14.8807 5.11929 16 6.5 16H13.5C14.8807 16 16 14.8807 16 13.5V6.5C16 5.11929 14.8807 4 13.5 4Z" stroke="#005C6F" stroke-width="1.3" />
											<path d="M10 12.625V7.375" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" />
											<path d="M7.25 12.625V11.125" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" />
											<path d="M12.75 12.625V9.625" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" />
										</svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M13.5 4H6.5C5.11929 4 4 5.11929 4 6.5V13.5C4 14.8807 5.11929 16 6.5 16H13.5C14.8807 16 16 14.8807 16 13.5V6.5C16 5.11929 14.8807 4 13.5 4Z" stroke="#636566" stroke-width="1.3" />
											<path d="M10 12.625V7.375" stroke="#636566" stroke-width="1.3" stroke-linecap="round" />
											<path d="M7.25 12.625V11.125" stroke="#636566" stroke-width="1.3" stroke-linecap="round" />
											<path d="M12.75 12.625V9.625" stroke="#636566" stroke-width="1.3" stroke-linecap="round" />
										</svg>}
									</div>
									Reports
								</div>
								<div
									className={`font-sm enquiry-assistant-summary__tabs-header ${activeTab === 5 ? "enquiry-assistant-summary__tabs-header__active" : ""}`}
									onClick={() => setActiveTab(5)}
								>
									<div className='mr-1'>
										{activeTab === 5 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M7.33203 12.6719H12.6654" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" />
											<path d="M12.3322 7.46875L9.3989 10.4021L8.15234 9.07692" stroke="#005C6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M13.3333 16H6.66667C5.2 16 4 14.8 4 13.3333V6.66667C4 5.2 5.2 4 6.66667 4H13.3333C14.8 4 16 5.2 16 6.66667V13.3333C16 14.8 14.8 16 13.3333 16Z" stroke="#005C6F" stroke-width="1.3" />
										</svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M7.33203 12.6719H12.6654" stroke="#636566" stroke-width="1.3" stroke-linecap="round" />
											<path d="M12.3322 7.46875L9.3989 10.4021L8.15234 9.07692" stroke="#636566" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M13.3333 16H6.66667C5.2 16 4 14.8 4 13.3333V6.66667C4 5.2 5.2 4 6.66667 4H13.3333C14.8 4 16 5.2 16 6.66667V13.3333C16 14.8 14.8 16 13.3333 16Z" stroke="#636566" stroke-width="1.3" />
										</svg>}
									</div>
									Updates
								</div>
							</div>
						</div>
						<div className='enquiry-assistant-summary__section'>
							{activeTab === 1 && <>
								<div className='flex justify-center'>
									<span className='enquiry-assistant-summary__section--header'>
										Today
									</span>
								</div>
								<div className='enquiry-assistant-summary__section--card mt-5 mb-5 relative'>
									<div className='enquiry-assistant-summary__section--card-header' style={{ background: '#E1F5F0' }}></div>
									<figure
										className="avatar-container bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
									</figure>
									<h3 className='font-semibold mt-5 ml-6'>Here’s what you missed since 5:00 pm yesterday, Sam...</h3>
									<div>
										<h4 className='ml-6 mt-5 text-13'>Your channels</h4>
										<ul className="mt-2 pb-5 mx-4 border-b border-grey-400">
											<li className="flex justify-between p-3">
												<div className="flex items-center gap-3">
													<figure className="avatar-container">
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path
																d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
																fill="#4C5254"
															/>
														</svg>
													</figure>
													<span className="text-15 font-medium">Stead</span>
												</div>
												<div className='flex'>
													<NotificationWidget
														icon={iconWidget1}
														color="#219653"
														text="New"
														count="3"
														bgColor="#F4FAF6"
														border='1px solid rgba(33, 150, 83, 0.2)'
													/>
													<NotificationWidget
														icon={iconWidget2}
														color="#FB7227"
														text="Replies"
														count="6"
														bgColor="#FFF8F4"
														border='1px solid rgba(251, 114, 39, 0.2)'
													/>
													<NotificationWidget
														icon={iconWidget3}
														color="#31B0D9"
														text="Mentions"
														count="6"
														bgColor="#F5FBFD"
														border='1px solid rgba(49, 176, 217, 0.2)'
													/>
												</div>
											</li>
											<li className="flex justify-between p-3">
												<div className="flex items-center gap-3">
													<figure className="avatar-container">
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path
																d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
																fill="#4C5254"
															/>
														</svg>
													</figure>
													<span className="text-15 font-medium">Facebook Pages</span>
												</div>
												<div className='flex'>
													<NotificationWidget
														icon={iconWidget1}
														color="#219653"
														text="New"
														count="1"
														bgColor="#F4FAF6"
														border='1px solid rgba(33, 150, 83, 0.2)'
													/>
													<NotificationWidget
														icon={iconWidget2}
														color="#FB7227"
														text="Replies"
														count="1"
														bgColor="#FFF8F4"
														border='1px solid rgba(251, 114, 39, 0.2)'
													/>
												</div>
											</li>
											<li className="flex justify-between p-3">
												<div className="flex items-center gap-3">
													<figure className="avatar-container">
														<svg width="15" height="15" viewBox="0 0 16 16" fill="none">
															<path
																d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
																fill="#4C5254"
															/>
														</svg>
													</figure>
													<span className="text-15 font-medium">Hunley Weddings</span>
												</div>
												<div className='flex'>
													<NotificationWidget
														icon={iconWidget3}
														color="#31B0D9"
														text="Mentions"
														count="6"
														bgColor="#F5FBFD"
														border='1px solid rgba(49, 176, 217, 0.2)'
													/>
												</div>
											</li>
										</ul>
									</div>
									<div className='px-4 py-5'>
										<h4 className='ml-2 text-13'>Your boxes</h4>
										<ul className="mt-2">
											<li className="flex justify-between p-3">
												<div className="flex items-center gap-3">
													<figure className="avatar-container">
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path
																d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
																fill="#4C5254"
															/>
														</svg>
													</figure>
													<span className="text-15 font-medium">Sales</span>
												</div>
												<div className='flex'>
													<NotificationWidget
														icon={iconWidget1}
														color="#219653"
														text="New"
														count="3"
														bgColor="#F4FAF6"
														border='1px solid rgba(33, 150, 83, 0.2)'
													/>
													<NotificationWidget
														icon={iconWidget2}
														color="#FB7227"
														text="Replies"
														count="6"
														bgColor="#FFF8F4"
														border='1px solid rgba(251, 114, 39, 0.2)'
													/>
												</div>
											</li>
											<li className="flex justify-between p-3">
												<div className="flex items-center gap-3">
													<figure className="avatar-container">
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
															<path
																d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
																fill="#4C5254"
															/>
														</svg>
													</figure>
													<span className="text-15 font-medium">General</span>
												</div>
												<div className='flex'>
													<NotificationWidget
														icon={iconWidget1}
														color="#219653"
														text="New"
														count="1"
														bgColor="#F4FAF6"
														border='1px solid rgba(33, 150, 83, 0.2)'
													/>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className='enquiry-assistant-summary__section--card mt-5 relative'>
									<div className='enquiry-assistant-summary__section--card-header' style={{ background: '#E1F5F0' }}></div>
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
									</figure>
									<div className='flex justify-end text-xs text-grey-700 mt-2 mr-4'>05.30 PM</div>
									<div className='mt-3 px-6 pb-5'>
										<h3 className='font-semibold'>Welcome, James 👋</h3>
										<h4 className='text-13 font-semibold mt-5'>I’m Ella and I’ll be your enquiry assistant</h4>
										<p className='text-13 mt-1'>My job is to make your life easier and help you stay organised and on top of things.
											I’ll nudge you now and then with a little reminder so you don’t miss anything. Looking forward to working together! 😀 </p>
									</div>
								</div>
								<div className='enquiry-assistant-summary__section--card mt-4 relative'>
									<div className='enquiry-assistant-summary__section--card-header flex justify-end items-center p-2' style={{ background: 'rgba(53, 122, 245, 0.1)' }}>
										<div className='flex mr-2'>
											<button className='btn btn--basic mr-1 bg-white'>
												<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
													<path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 6C11.25 8.8995 8.8995 11.25 6 11.25C3.10051 11.25 0.75 8.8995 0.75 6C0.75 3.10051 3.10051 0.75 6 0.75C8.8995 0.75 11.25 3.10051 11.25 6ZM8.12084 3.88033C8.32587 4.08536 8.32587 4.41777 8.12084 4.62279L6.74207 6.00156L8.12084 7.38033C8.32587 7.58536 8.32587 7.91777 8.12084 8.12279C7.91582 8.32782 7.5834 8.32782 7.37838 8.12279L5.99961 6.74402L4.62084 8.12279C4.41582 8.32782 4.0834 8.32782 3.87838 8.12279C3.67335 7.91777 3.67335 7.58536 3.87838 7.38033L5.25715 6.00156L3.87838 4.62279C3.67335 4.41777 3.67335 4.08536 3.87838 3.88033C4.0834 3.67531 4.41582 3.67531 4.62084 3.88033L5.99961 5.2591L7.37838 3.88033C7.5834 3.67531 7.91582 3.67531 8.12084 3.88033Z" fill="#908F8D" />
												</svg>
												Dismiss
											</button>
											<button className={`btn btn--basic mr-1 relative ${openSnoozeOptions ? 'bg-primary text-white' : 'bg-white'}`} onClick={() => setOpenSnoozeOptions(!openSnoozeOptions)}>
												{openSnoozeOptions ?
													<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
														<path fill-rule="evenodd" clip-rule="evenodd" d="M9.29469 8.71636C9.98103 8.71636 10.6382 8.59135 11.2446 8.36285C10.3424 10.1922 8.45869 11.4507 6.28102 11.4507C3.22632 11.4507 0.75 8.97441 0.75 5.91971C0.75 3.55134 2.23856 1.53065 4.33113 0.742188C3.9678 1.47895 3.76367 2.3083 3.76367 3.18533C3.76367 6.24003 6.24 8.71636 9.29469 8.71636ZM8.47713 4.69742L10.4352 2.56601C10.6255 2.36884 10.7207 2.14108 10.7207 1.88273V1.85214C10.7207 1.66177 10.6527 1.5122 10.5168 1.40342C10.3876 1.29464 10.2346 1.24025 10.0578 1.24025H7.11058C6.94741 1.24025 6.80463 1.30484 6.68226 1.43401C6.55988 1.56319 6.49869 1.71616 6.49869 1.89293C6.49869 2.0629 6.55988 2.21587 6.68226 2.35184C6.81143 2.48102 6.95421 2.54561 7.11058 2.54561H8.68109L6.72305 4.67702C6.53268 4.87418 6.4375 5.10194 6.4375 5.36029V5.39089C6.4375 5.58125 6.50209 5.73083 6.63126 5.83961C6.76724 5.94839 6.92361 6.00278 7.10038 6.00278H10.2006C10.3638 6.00278 10.5066 5.93819 10.6289 5.80901C10.7513 5.67984 10.8125 5.52686 10.8125 5.3501C10.8125 5.18013 10.7479 5.03056 10.6187 4.90138C10.4964 4.7654 10.357 4.69742 10.2006 4.69742H8.47713Z" fill="white" />
													</svg>
													: <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
														<path fill-rule="evenodd" clip-rule="evenodd" d="M9.29469 8.71636C9.98103 8.71636 10.6382 8.59135 11.2446 8.36285C10.3424 10.1922 8.45869 11.4507 6.28102 11.4507C3.22632 11.4507 0.75 8.97441 0.75 5.91971C0.75 3.55134 2.23856 1.53065 4.33113 0.742188C3.9678 1.47895 3.76367 2.3083 3.76367 3.18533C3.76367 6.24003 6.24 8.71636 9.29469 8.71636ZM8.47713 4.69742L10.4352 2.56601C10.6255 2.36884 10.7207 2.14108 10.7207 1.88273V1.85214C10.7207 1.66177 10.6527 1.5122 10.5168 1.40342C10.3876 1.29464 10.2346 1.24025 10.0578 1.24025H7.11058C6.94741 1.24025 6.80463 1.30484 6.68226 1.43401C6.55988 1.56319 6.49869 1.71616 6.49869 1.89293C6.49869 2.0629 6.55988 2.21587 6.68226 2.35184C6.81143 2.48102 6.95421 2.54561 7.11058 2.54561H8.68109L6.72305 4.67702C6.53268 4.87418 6.4375 5.10194 6.4375 5.36029V5.39089C6.4375 5.58125 6.50209 5.73083 6.63126 5.83961C6.76724 5.94839 6.92361 6.00278 7.10038 6.00278H10.2006C10.3638 6.00278 10.5066 5.93819 10.6289 5.80901C10.7513 5.67984 10.8125 5.52686 10.8125 5.3501C10.8125 5.18013 10.7479 5.03056 10.6187 4.90138C10.4964 4.7654 10.357 4.69742 10.2006 4.69742H8.47713Z" fill="#908F8D" />
													</svg>}
												Snooze
												<div className={`dropdown-menu dropdown-menu--snooze ${openSnoozeOptions ? 'open' : ''}`}>
													<div className="dropdown-menu__list">20 minutes</div>
													<div className="dropdown-menu__list">1 hour</div>
													<div className="dropdown-menu__list">3 hours</div>
													<div className="dropdown-menu__list">Tomorrow morning </div>
													<div className="dropdown-menu__list">Next week</div>
												</div>
											</button>
											<button className='btn btn--basic bg-white'>
												<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
													<path fill-rule="evenodd" clip-rule="evenodd" d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z" fill="#908F8D" />
												</svg>
												View enquiry
											</button>

										</div>
									</div>
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
										<div className='avatar-container__sup-icon--xs absolute bg-white' style={{ height: 16, width: 16 }}>
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', left: 1, top: 1 }}>
												<circle cx="7" cy="7" r="7" fill="#357AF5" />
												<path d="M3 5.5C3 4.67157 3.67157 4 4.5 4H9.5C10.3284 4 11 4.67157 11 5.5V9C11 9.82843 10.3284 10.5 9.5 10.5H4.5C3.67157 10.5 3 9.82843 3 9V5.5Z" stroke="white" stroke-width="0.8" />
												<path d="M5 3.5V3.75" stroke="white" stroke-width="0.8" stroke-linecap="round" />
												<path d="M9 3.5V3.75" stroke="white" stroke-width="0.8" stroke-linecap="round" />
												<path d="M3 5.75H11" stroke="white" stroke-width="0.8" />
											</svg>
										</div>
									</figure>
									<div className='flex justify-end text-xs text-grey-700 mt-2 mr-4'>03.25 PM</div>
									<div className='mt-1 px-6 pb-5'>
										<h3 className='font-semibold'>Just a quick reminder, Sam...</h3>
										<p className='text-13 mt-3'>Call Freddy about the pricing propsoal that was sent</p>
									</div>
								</div>
								<div className='flex justify-center relative mt-5'>
									<span className='enquiry-assistant-summary__section--header-bottom'>
										Yesterday
									</span>
								</div>
								<div className='enquiry-assistant-summary__section--card mt-5 relative'>
									<div className='enquiry-assistant-summary__section--card-header flex justify-end items-center p-2' style={{ background: 'rgba(240, 165, 0, 0.1)' }}>
										<div className='flex mr-2'>
											<button className='btn btn--basic mr-1 bg-white'>
												<div className='mr-1'>
													<svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M0.651611 3.13283L3.74264 0.486929C4.00216 0.26478 4.40275 0.449187 4.40275 0.790804V2.19372C9.6793 2.50641 10.2581 5.81728 10.2499 7.76341C8.47152 5.06651 5.85218 5.28799 4.40275 5.41828V6.912C4.40275 7.2507 4.0081 7.4361 3.7474 7.21989L0.663521 4.66224C0.186227 4.26639 0.18054 3.53606 0.651611 3.13283Z" fill="#908F8D" />
													</svg>
												</div>
												Reply
											</button>
											<button className='btn btn--basic bg-white'>
												<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
													<path fill-rule="evenodd" clip-rule="evenodd" d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z" fill="#908F8D" />
												</svg>
												View enquiry
											</button>
										</div>
									</div>
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
										<div className='avatar-container__sup-icon--xs absolute bg-white' style={{ height: 16, width: 16 }}>
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="7" cy="7" r="7" fill="#F0A500" />
												<path d="M3.5 5C3.5 4.17157 4.17157 3.5 5 3.5H8.99994C9.82836 3.5 10.4999 4.17157 10.4999 5V8.63629C10.4999 10.017 9.38065 11.1363 7.99994 11.1363H5C4.17157 11.1363 3.5 10.4647 3.5 9.6363V5Z" stroke="white" stroke-width="0.8" />
												<path d="M7.31885 11.1349V11.1349C8.02175 11.1349 8.59156 10.5651 8.59156 9.8622V9.43796C8.59156 9.32081 8.68653 9.22584 8.80368 9.22584H9.22792C9.93082 9.22584 10.5006 8.65603 10.5006 7.95312V7.95312" stroke="white" stroke-width="0.8" />
												<path d="M5.40967 5.72656H7.31874" stroke="white" stroke-width="0.8" stroke-linecap="round" />
												<path d="M5.40967 7.32031H8.59146" stroke="white" stroke-width="0.8" stroke-linecap="round" />
											</svg>
										</div>
									</figure>
									<div className='flex justify-end text-xs text-grey-700 mt-2 mr-4'>08.00 AM</div>
									<div className='mt-1 px-6 pb-5'>
										<h3 className='font-semibold'>Sam, tagged you in a note</h3>
										<p className='text-13 mt-3'>Need to follow up with customer <span className='font-medium text-primary'>@Lisa Fotios</span> Can you take this?</p>
									</div>
								</div>
								<div className='flex justify-center relative mt-5'>
									<span className='enquiry-assistant-summary__section--header-bottom'>
										Tuesday, Aug 16
									</span>
								</div>
								<div className='enquiry-assistant-summary__section--card mt-5 relative mb-10'>
									<div className='enquiry-assistant-summary__section--card-header flex justify-end items-center p-2' style={{ background: 'rgba(106, 94, 245, 0.05)' }}>
										<div className='flex mr-2'>
											<button className='btn btn--basic mr-1 bg-white'>
												<div className='mr-1'>
													<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M6.70011 5.30469C7.70013 6.30471 7.70013 7.85474 6.70011 8.85476L5.30008 10.2548C4.30006 11.2548 2.75003 11.2548 1.75001 10.2548C0.749995 9.25476 0.749995 7.70473 1.75001 6.70471L3.00004 5.50469" stroke="#908F8D" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
														<path d="M5.30031 6.70011C4.30029 5.70009 4.30029 4.15006 5.30031 3.15004L6.70033 1.75001C7.70035 0.749995 9.25038 0.749995 10.2504 1.75001C11.2504 2.75003 11.2504 4.30006 10.2504 5.30008L9.00038 6.50011" stroke="#908F8D" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
													</svg>
												</div>
												Copy link
											</button>
											<button className='btn btn--basic bg-white'>
												<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
													<path fill-rule="evenodd" clip-rule="evenodd" d="M0.955137 5.0705C0.848288 5.26854 0.848288 5.50879 0.955137 5.70683C2.24278 8.08883 4.62138 10.153 6.99998 10.153C9.37858 10.153 11.7572 8.08884 13.0448 5.70751C13.1517 5.50947 13.1517 5.26922 13.0448 5.07118C11.7572 2.68918 9.37858 0.625 6.99998 0.625C4.62138 0.625 2.24278 2.68917 0.955137 5.0705ZM8.44241 6.82918C9.23975 6.03184 9.23975 4.73909 8.44241 3.94175C7.64507 3.14442 6.35233 3.14442 5.55499 3.94175C4.75765 4.73909 4.75765 6.03184 5.55499 6.82918C6.35233 7.62652 7.64507 7.62652 8.44241 6.82918Z" fill="#908F8D" />
												</svg>
												View enquiry
											</button>
										</div>
									</div>
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
										<div className='avatar-container__sup-icon--xs absolute bg-white' style={{ height: 16, width: 16 }}>
											<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="8.22842" cy="8.22842" r="7.72842" fill="#6A5EF5" stroke="white" />
												<path d="M10.5 4H5.5C4.67157 4 4 4.67157 4 5.5V10.5C4 11.3284 4.67157 12 5.5 12H10.5C11.3284 12 12 11.3284 12 10.5V5.5C12 4.67157 11.3284 4 10.5 4Z" stroke="white" stroke-width="0.8" />
												<path d="M8 9.75V6.25" stroke="white" stroke-width="0.8" stroke-linecap="round" />
												<path d="M6.1665 9.75V8.75" stroke="white" stroke-width="0.8" stroke-linecap="round" />
												<path d="M9.8335 9.75V7.75" stroke="white" stroke-width="0.8" stroke-linecap="round" />
											</svg>
										</div>
									</figure>
									<div className='flex justify-end text-xs text-grey-700 mt-2 mr-4'>12.38 PM</div>
									<div className='mt-1 px-6 pb-5'>
										<h3 className='font-semibold'>Here’s your monthly sales report, Sam</h3>
										<p className='text-13 mt-3'>I’ll keep an eye on things in the meantime until your next report</p>
									</div>
								</div>
							</>}
							{activeTab === 2 &&
								<div className='enquiry-assistant-summary__section--card mt-4 relative'>
									<div className='enquiry-assistant-summary__section--card-header' style={{ background: '#E1F5F0' }}></div>
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
									</figure>
									<div className='mt-8 px-6 pb-5'>
										<h3 className='font-semibold'>“Wow, James is so on it! They never forget anything...”</h3>
										<h4 className='text-13 font-semibold mt-5'>That’s what they are going to say about you  😎 </h4>
										<p className='text-13 mt-1'>You can ask me to remind you about anything! On top of that I’m going remind you when an enquiry has not been assigned or replied to by anyone on your team in 24 hours.
											If you’re already a whizz at this you can set me up to do something else.</p>
									</div>
								</div>
							}
							{activeTab === 3 &&
								<div className='enquiry-assistant-summary__section--card mt-4 relative'>
									<div className='enquiry-assistant-summary__section--card-header' style={{ background: '#E1F5F0' }}></div>
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
									</figure>
									<div className='mt-8 px-6 pb-5'>
										<h3 className='font-semibold'>I’ll let you know when someone mentions you anywhere 🤟 </h3>
										<p className='text-13 mt-1'>Members of your team can mention you in a note or when moving an 
										enquiry into a box that you are a member of. </p>
									</div>
								</div>
							}
							{activeTab === 4 &&
								<div className='enquiry-assistant-summary__section--card mt-4 relative'>
									<div className='enquiry-assistant-summary__section--card-header' style={{ background: '#E1F5F0' }}></div>
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
									</figure>
									<div className='mt-8 px-6 pb-5'>
										<h3 className='font-semibold'>“Can you send me that monthly report on...”</h3>
										<h4 className='text-13 font-semibold mt-5'>I can help you with this painful task, automatically! 😎</h4>
										<p className='text-13 mt-1'>This is nuts, I know, but I’ve been trained to do this. I can send you daily, 
										weekly or monthly reports on the information that’s important to you.</p>
										<p className='text-13 font-semibold text-primary mt-2'>Click here to setup these reports</p>
									</div>
								</div>}
							{activeTab === 5 && 
								<div className='enquiry-assistant-summary__section--card mt-4 relative'>
									<div className='enquiry-assistant-summary__section--card-header' style={{ background: '#E1F5F0' }}></div>
									<figure
										className="avatar-container avatar-container--contain bg-white"
										style={{
											width: 42,
											height: 42,
											border: "1px solid rgba(0, 0, 0, 0.05)",
											position: 'absolute',
											top: 7,
											left: 24
										}}
									>
										<img src={avatarPic1} alt="" style={{ width: 30, height: 37 }} />
									</figure>
									<div className='mt-8 px-6 pb-5'>
										<h3 className='font-semibold'>I’ll keep you posted on channel or box invites and new features💥</h3>
										<p className='text-13 mt-1'>If you’ve been invited to a channel or box you’ll hear about it here. I’ll also let you know about any new features we release.</p>
									</div>
								</div>
							}
						</div>
					</div>
				</section>
			</section>
		</main>
	)
}

export default EnquiryAssistantSummary