import React from 'react'
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";

const DeleteLabel = ({openDeleteModal, setOpenDeleteModal}) => {
	return (
		<div className={`modal ${openDeleteModal ? "show" : ""}`}>
        <div className="modal__container">
          <div
            className="modal__header modal__header--plain-sm"
            style={{ justifyContent: "flex-end" }}
          >
            <div
              className="modal__close-icon"
              onClick={() => setOpenDeleteModal(false)}
            >
              <img src={modalCloseIcon} alt="" />
            </div>
          </div>

          <div className="modal__body px-8 pb-6">
            <p className="text-center text-xl font-semibold">
							Are you sure you want to delete this label?
            </p>
						<p className='text-13 mt-3 text-center'>It's linked to 153 contacts and 1 automation.</p>
            <div className="flex justify-center mt-12 gap-2">
              <button className="btn btn--grey btn--md min-w-120" 
              onClick={() => setOpenDeleteModal(false)}
							>
                Cancel
              </button>
              <button className="btn btn--red btn--md min-w-120 text-white">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
	)
}

export default DeleteLabel