import React, { useState, useRef } from "react";
import closeGreen from "../../assets/icons/icon-close-green.svg";
import avatarPic from "../../assets/images/avatar-pic.png";
import InputField from "./InputField";
import iconEmojiFire from "../../assets/icons/icon-emoji-fire.svg";
import arrowRightGreen from "../../assets/icons/icon-arrow-right-green.svg";
import { Link } from "react-router-dom";

const popups = {
  showUsers: "showUsers",
  note: "note",
};
const assignToUsers = [
  {
    name: "Madison Inouye",
  },
  {
    name: "Olivia Rhye",
  },
  {
    name: "Charles Wundeng",
  },
  {
    name: "Lisa Fotios",
  },
  {
    name: "Peter Parker",
  },
];
const moveToOptions = [
  {
    name: "Sales",
    icon: iconEmojiFire,
    bgColor: "#4B7FE6",
  },
  {
    name: "General",
    icon: iconEmojiFire,
    bgColor: "#57D1F8",
  },
  {
    name: "Potential Leads",
    icon: iconEmojiFire,
    bgColor: "#F55E5E",
  },
  {
    name: "Marketing Leads",
    icon: iconEmojiFire,
    bgColor: "#EC9E54",
  },
  {
    name: "Product Enquiries",
    icon: iconEmojiFire,
    bgColor: "#35CC71",
  },
];

const labelData = [
  {
    id: 1,
    name: "Whitening",
    checked: false,
  },
  {
    id: 2,
    name: "Invisalign",
    checked: false,
  },
  {
    id: 3,
    name: "Dental Implants",
    checked: false,
  },
  {
    id: 4,
    name: "NHS Patient",
    checked: false,
  },
  {
    id: 5,
    name: "New Patient",
    checked: false,
  },
  {
    id: 6,
    name: "Emergency",
    checked: false,
  },
];

const ChatHeader = ({
  setOpenTabs,
  openTabs,
  user = { name: "Lisa Fotios" },
}) => {
  const node = useRef();
  const lableNode = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isDropdown, setIsdropdown] = useState(0);
  const [activeScreen, setActiveScreen] = useState(null);
  const [selectedUser, setSelectedUser] = useState({ name: null });
  const [inputValue, setInputValue] = useState("");
  const [openLabel, setOpenLabel] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(labelData);

  const data = isDropdown === 1 ? moveToOptions : assignToUsers;

  const onClose = () => {
    setOpenTabs(false);
  };

  const onClick = () => {
    setOpenTabs(!openTabs);
  };
  const ShowUsersList = ({}) => {
    return (
      <div>
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <InputField
            placeholder="Search"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
        </div>
        <div
          className={`no-hover ${
            isDropdown === 1 ? "" : "dropdown-menu__overflow-y-scroll"
          } mt-4`}
        >
          <p className="text-grey-700 px-3 text-xs" style={{ marginBottom: 6 }}>
            {isDropdown === 1 ? "Move to" : "Assign To"}
          </p>
          {data.map((user, index) => {
            return (
              <div
                key={index}
                className="dropdown-menu__list font-medium"
                style={{ color: "#2B3639" }}
                onClick={() => {
                  setSelectedUser(user);
                  setActiveScreen(popups.note);
                }}
              >
                {isDropdown === 1 ? (
                  <img
                    className="mr-3"
                    src={user.icon}
                    style={{ width: 20, height: 20 }}
                    alt=""
                  />
                ) : (
                  <img
                    className="mr-3"
                    src={avatarPic}
                    style={{ width: 30, height: 30 }}
                    alt=""
                  />
                )}
                {user.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const SendNote = ({}) => {
    return (
      <>
        <div
          className="flex items-center pb-2 px-6 text-13 text-primary cursor-pointer font-medium"
          onClick={() => {
            setSelectedUser({ name: null });
            setActiveScreen(popups.showUsers);
          }}
        >
          <img
            className="mr-1"
            src={arrowRightGreen}
            style={{ width: 10, transform: "rotate(180deg)" }}
            alt=""
          />
          Back
        </div>
        <div className="flex items-center border-b border-grey-400 border-t pl-5 py-2">
          {isDropdown === 1 ? (
            <img
              src={selectedUser.icon}
              className="mr-3"
              style={{ width: 20, height: 20 }}
              alt=""
            />
          ) : (
            <img
              className="mr-3"
              src={avatarPic}
              style={{ width: 30, height: 30 }}
              alt=""
            />
          )}
          <p
            className="font-semibold text-grey-900"
            style={{ fontSize: "14px" }}
          >
            {selectedUser?.name}
          </p>
        </div>
        <div className="no-hover px-4 pt-5">
          {isDropdown === 1 ? (
            <textarea
              name=""
              rows="3"
              cols="5"
              placeholder="Write a note, tag a team member by typing @"
              className="input-field input-field__textarea border text-base-1 text-grey-900"
            ></textarea>
          ) : (
            <textarea
              name=""
              rows="3"
              cols="5"
              placeholder={`Write a note for ${
                selectedUser?.name?.split(" ")[0]
              } (Optional)`}
              className="input-field input-field__textarea border text-base-1 text-grey-900"
            ></textarea>
          )}
          <div className="flex items-center justify-end gap-3 mt-10">
            <button
              className="btn btn--cancel btn--md"
              style={{ width: "27%" }}
              onClick={() => {
                setSelectedUser({ name: null });
                setActiveScreen(popups.showUsers);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn--primary btn--md"
              style={{ width: "27%" }}
            >
              {isDropdown === 1 ? "Move" : "Attach"}
            </button>
          </div>
        </div>
      </>
    );
  };

  const handleChange = (ind) => {
    const currentStatus = selectedLabel[ind].checked;
    selectedLabel[ind].checked = !currentStatus;
    setSelectedLabel([...selectedLabel]);
  };
  const handleRemove = (ind) => {
    const currentStatus = selectedLabel[ind].checked;
    selectedLabel[ind].checked = !currentStatus;
    setSelectedLabel([...selectedLabel]);
  };
  return (
    <>
      <div className="flex items-center">
        <div
          onClick={() => {
            setIsClicked((curr) => !curr);
          }}
          onMouseEnter={() => {
            if (!isClicked) {
              setIsHovered(true);
            }
          }}
          onMouseLeave={() => {
            if (!isClicked) {
              setIsHovered(false);
            }
          }}
          className={`dashboard__main__header__full-right--profile-container ${
            isHovered ? "hovered" : isClicked ? "clicked" : ""
          }`}
          style={{ height: 32 }}
        >
          {isClicked ? (
            <div className="flex items-center" onClick={onClose}>
              <p className="text-13 font-medium" style={{ color: "#005C6F" }}>
                Close Contact
              </p>
              <img src={closeGreen} alt="arrow-right" className="ml-2" />
            </div>
          ) : isHovered ? (
            <div className="flex items-center" onClick={onClick}>
              <p
                className="text-13 font-medium mr-1"
                style={{ color: "#005C6F" }}
              >
                View Contact
              </p>
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.0022 1L10.4981 4.5L7.0022 8"
                  stroke="#005C6F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 4.49796L1 4.49796"
                  stroke="#005C6F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className="flex items-center">
              <figure
                className="avatar-container avatar-container--full"
                style={{ width: 26, height: 26 }}
              >
                <img src={avatarPic} alt="avatar-pic" />
              </figure>
              <p
                className="text-13 font-medium ml-2 mr-1"
                style={{ color: "#292929" }}
              >
                {user.name}
              </p>
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.0022 1L10.4981 4.5L7.0022 8"
                  stroke="#005C6F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 4.49796L1 4.49796"
                  stroke="#005C6F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2 ml-auto relative">
        <div className="hide-scroll dashboard__main__header__full-right--label-container">
          {selectedLabel.length > 0 &&
            selectedLabel.map((item, index) => {
              return (
                <>
                  {item.checked && (
                    <div
                      className="bg-grey-90 rounded-md flex items-center py-1 px-2 cursor-pointer mr-1"
                      style={{ border: "1px solid rgba(0, 92, 111, 0.2)" }}
                      key={index}
                    >
                      <p className="text-13 font-medium mr-2 truncate">
                        {item.name}
                      </p>
                      <figure
                        onClick={() => handleRemove(index)}
                      >
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 7L7 1"
                            stroke="#005C6F"
                            stroke-width="1.2"
                            stroke-linecap="round"
                          />
                          <path
                            d="M1 1L7 7"
                            stroke="#005C6F"
                            stroke-width="1.2"
                            stroke-linecap="round"
                          />
                        </svg>
                      </figure>
                    </div>
                  )}
                </>
              );
            })}
        </div>
        <button
          className={`btn border border-grey-400 ${
            openLabel ? "bg-grey-90" : "bg-white"
          }`}
          style={{ padding: "4px 10px" }}
          onClick={() => {
            setOpenLabel(!openLabel);
            setIsdropdown(0);
            setActiveScreen(null);
          }}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 1V9"
              stroke="#4C5254"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 5L1 5"
              stroke="#4C5254"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="text-13 font-medium ml-2">Label</p>
        </button>
        <button
          className={`btn gap-2 ${
            isDropdown === 1 ? "bg-grey-90" : "btn--primary"
          }`}
          style={{ padding: "4px 10px" }}
          onClick={() => {
            setActiveScreen(popups.showUsers);
            setIsdropdown(1);
            setOpenLabel(false);
          }}
        >
          {isDropdown === 1 ? (
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99743 2.50257H3.5C3.34825 2.50257 3.19913 2.51384 3.05343 2.53558C3.23782 1.80097 3.90257 1.25701 4.69437 1.25701H9.84937C11.2755 1.25701 12.4315 2.41309 12.4315 3.83919V8.99418C12.4315 9.86735 11.7701 10.586 10.9209 10.6764C10.971 10.459 10.9974 10.2326 10.9974 10V5.50257C10.9974 3.84572 9.65429 2.50257 7.99743 2.50257ZM0.5 5.50257C0.5 4.41956 1.07388 3.47059 1.93413 2.94316C1.93716 1.42129 3.1718 0.188526 4.69437 0.188526H9.84937C11.8656 0.188526 13.5 1.82298 13.5 3.83919V8.99418C13.5 10.5186 12.2642 11.7544 10.7398 11.7544H10.4312C9.88634 12.509 8.99923 13 7.99743 13H3.5C1.84314 13 0.5 11.6569 0.5 10L0.5 5.50257ZM5.01124 8.30863C4.80384 8.09877 4.80583 7.76051 5.01569 7.5531L7.07225 5.52063H6.07702C5.78197 5.52063 5.54278 5.28144 5.54278 4.98639C5.54278 4.69133 5.78197 4.45214 6.07702 4.45214H8.35425C8.37216 4.45151 8.39009 4.45178 8.40797 4.45294C8.53923 4.46012 8.66365 4.51552 8.75697 4.60907C8.8572 4.70953 8.91334 4.84574 8.913 4.98765L8.90757 7.28396C8.90688 7.57902 8.66712 7.81764 8.37207 7.81694C8.07702 7.81624 7.83839 7.57649 7.83909 7.28144L7.8415 6.26264L5.76677 8.31308C5.5569 8.52048 5.21865 8.51849 5.01124 8.30863Z"
                fill="#005C6F"
              />
            </svg>
          ) : (
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99743 2.50257H3.5C3.34825 2.50257 3.19913 2.51384 3.05343 2.53558C3.23782 1.80097 3.90257 1.25701 4.69437 1.25701H9.84937C11.2755 1.25701 12.4315 2.41309 12.4315 3.83919V8.99418C12.4315 9.86735 11.7701 10.586 10.9209 10.6764C10.971 10.459 10.9974 10.2326 10.9974 10V5.50257C10.9974 3.84572 9.65429 2.50257 7.99743 2.50257ZM0.5 5.50257C0.5 4.41956 1.07388 3.47059 1.93413 2.94316C1.93716 1.42129 3.1718 0.188526 4.69437 0.188526H9.84937C11.8656 0.188526 13.5 1.82298 13.5 3.83919V8.99418C13.5 10.5186 12.2642 11.7544 10.7398 11.7544H10.4312C9.88634 12.509 8.99923 13 7.99743 13H3.5C1.84314 13 0.5 11.6569 0.5 10L0.5 5.50257ZM5.01124 8.30863C4.80384 8.09877 4.80583 7.76051 5.01569 7.5531L7.07225 5.52063H6.07702C5.78197 5.52063 5.54278 5.28144 5.54278 4.98639C5.54278 4.69133 5.78197 4.45214 6.07702 4.45214H8.35425C8.37216 4.45151 8.39009 4.45178 8.40797 4.45294C8.53923 4.46012 8.66365 4.51552 8.75697 4.60907C8.8572 4.70953 8.91334 4.84574 8.913 4.98765L8.90757 7.28396C8.90688 7.57902 8.66712 7.81764 8.37207 7.81694C8.07702 7.81624 7.83839 7.57649 7.83909 7.28144L7.8415 6.26264L5.76677 8.31308C5.5569 8.52048 5.21865 8.51849 5.01124 8.30863Z"
                fill="white"
              />
            </svg>
          )}
          <p className="text-13 font-medium">Move To</p>
        </button>
        <button
          className={`btn gap-2 ${
            isDropdown === 2 ? "bg-grey-90" : "btn--primary"
          }`}
          onClick={() => {
            setActiveScreen(popups.showUsers);
            setIsdropdown(2);
            setOpenLabel(false);
          }}
          style={{ padding: "4px 10px" }}
        >
          <figure>
            {isDropdown === 2 ? (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.24016 7.00016C1.24016 3.819 3.819 1.24016 7.00016 1.24016C10.1813 1.24016 12.7602 3.819 12.7602 7.00016C12.7602 8.27684 12.3448 9.45652 11.6418 10.4114C11.6008 9.4488 10.8232 8.575 9.72928 8.575H4.47417C3.30989 8.575 2.46516 9.56838 2.53515 10.6393C1.72564 9.64727 1.24016 8.38041 1.24016 7.00016ZM7.00016 0.160156C3.22253 0.160156 0.160156 3.22253 0.160156 7.00016C0.160156 10.7778 3.22253 13.8402 7.00016 13.8402C10.7778 13.8402 13.8402 10.7778 13.8402 7.00016C13.8402 3.22253 10.7778 0.160156 7.00016 0.160156ZM9.36262 5.425C9.36262 6.72977 8.30489 7.7875 7.00012 7.7875C5.69535 7.7875 4.63762 6.72977 4.63762 5.425C4.63762 4.12023 5.69535 3.0625 7.00012 3.0625C8.30489 3.0625 9.36262 4.12023 9.36262 5.425Z"
                  fill="#005C6F"
                />
              </svg>
            ) : (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.24016 7.00016C1.24016 3.819 3.819 1.24016 7.00016 1.24016C10.1813 1.24016 12.7602 3.819 12.7602 7.00016C12.7602 8.27684 12.3448 9.45652 11.6418 10.4114C11.6008 9.4488 10.8232 8.575 9.72928 8.575H4.47417C3.30989 8.575 2.46516 9.56838 2.53515 10.6393C1.72564 9.64727 1.24016 8.38041 1.24016 7.00016ZM7.00016 0.160156C3.22253 0.160156 0.160156 3.22253 0.160156 7.00016C0.160156 10.7778 3.22253 13.8402 7.00016 13.8402C10.7778 13.8402 13.8402 10.7778 13.8402 7.00016C13.8402 3.22253 10.7778 0.160156 7.00016 0.160156ZM9.36262 5.425C9.36262 6.72977 8.30489 7.7875 7.00012 7.7875C5.69535 7.7875 4.63762 6.72977 4.63762 5.425C4.63762 4.12023 5.69535 3.0625 7.00012 3.0625C8.30489 3.0625 9.36262 4.12023 9.36262 5.425Z"
                  fill="white"
                />
              </svg>
            )}
          </figure>
          <span className="text-13 font-medium">Unassigned</span>
        </button>
      </div>
      {!openLabel ? (
        <div
          ref={node}
          className={`dropdown-menu dropdown-menu--with-border mt-3 ${
            activeScreen !== null && "open"
          }`}
          style={{
            right: "25px",
            left: "auto",
            width: "500px",
            top: "35px",
            padding:
              activeScreen === popups.showUsers
                ? "18px 8px 8px 8px"
                : "11px 0px 18px",
            transition: "all 300ms ease-in",
          }}
        >
          {activeScreen === popups.showUsers ? (
            <ShowUsersList />
          ) : activeScreen === popups.note ? (
            <SendNote />
          ) : null}
        </div>
      ) : (
        <>
          {selectedLabel.length > 0 ? (
            <div
              ref={lableNode}
              className={`dropdown-menu dropdown-menu--with-border mt-3 ${
                openLabel ? "open" : ""
              } p-2`}
              style={{
                right: "240px",
                left: "auto",
                width: "316px",
                top: "33px",
              }}
            >
              <div style={{ margin: "10px 10px 0px" }}>
                <div
                  className={`input-field input-field--xs input-field--prepend no-hover text-sm ${
                    isInputFocused ? "input-field--prepend--focus" : ""
                  }`}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <g opacity="0.8">
                      <circle
                        cx="6.33333"
                        cy="6.33333"
                        r="5.33333"
                        stroke="#636566"
                        stroke-width="1.5"
                      />
                      <path
                        d="M10.3333 10.334L12.9999 13.0007"
                        stroke="#636566"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </g>
                  </svg>
                  <input
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="mt-4 cursor-pointer" style={{ marginLeft: 10 }}>
                <Link
                  className="font-medium text-primary text-13"
                  to="/global-settings-labels"
                >
                  Manage Labels
                </Link>
              </div>
              <div className="no-hover dropdown-menu__overflow-y-scroll mt-2">
                {selectedLabel.map((item, index) => {
                  return (
                    <div className="dropdown-menu__list gap-4" key={index}>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          className="custom-checkbox custom-checkbox--xs"
                          name={`${item.id}`}
                          checked={item.checked}
                          onChange={() => handleChange(index)}
                        />
                      </div>
                      <span className="text-sm">{item.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div
              ref={lableNode}
              className={`dropdown-menu dropdown-menu--with-border mt-3 pt-9 pb-12 ${
                openLabel ? "open" : ""
              }`}
              style={{
                right: "240px",
                left: "auto",
                width: "316px",
                top: "33px",
              }}
            >
              <div className="flex justify-center">
                <figure
                  className="bg-grey-300 flex justify-center items-center"
                  style={{ width: 71, height: 71, borderRadius: "50%" }}
                >
                  <svg
                    width="26"
                    height="30"
                    viewBox="0 0 26 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.01011 12.7738L11.6934 2.22677C12.3092 1.6192 13.1368 1.2793 14.0005 1.2793H20.7149"
                      stroke="#636566"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.28865 17.3057L14.1279 5.46643C14.4317 5.16101 14.8439 4.99023 15.2741 4.99023H24.3432C25.2398 4.99023 25.9672 5.71767 25.9672 6.61424V15.6817C25.9672 16.1119 25.7948 16.5258 25.491 16.8295L13.6517 28.6688C12.3841 29.9365 10.3282 29.9365 9.06053 28.6688L2.28865 21.8969C1.02097 20.6293 1.02097 18.5734 2.28865 17.3057ZM18.9889 14.0243C20.1225 14.0243 21.0415 13.1053 21.0415 11.9717C21.0415 10.8381 20.1225 9.91912 18.9889 9.91912C17.8553 9.91912 16.9363 10.8381 16.9363 11.9717C16.9363 13.1053 17.8553 14.0243 18.9889 14.0243Z"
                      fill="#636566"
                    />
                  </svg>
                </figure>
              </div>
              <p className="font-medium mt-5 text-center">
                You currently have no labels !
              </p>
              <div className="flex justify-center mt-6">
                <Link
                  className="btn btn--primary btn--sm-34 gap-2"
                  to="/global-settings-labels"
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 1V9"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M9 5L1 5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                  <span className="text-13 font-semibold">Create one</span>
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ChatHeader;
