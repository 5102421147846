import React, { useState } from "react";
import logo from "../../assets/icons/eb-logo.svg";
import iconPlus from "../../assets/icons/icon-plus.svg";
import iconDownload from "../../assets/icons/icon-new-download.svg";
import iconUpload from "../../assets/icons/icon-upload.svg";
import iconRollBack from "../../assets/icons/icon-rollback.svg";
import avatarPic from "../../assets/images/avatar-pic.png";
import avatarPic1 from "../../assets/icons/icon-avatar.svg";
import iconEmail from "../../assets/icons/Icon-Email-22.svg";
import iconFb from "../../assets/icons/Icon-fb-20.svg";
import iconInsta from "../../assets/icons/Icon-Instagram-20.svg";
import iconSalesBox from "../../assets/icons/icon-sales-box.svg";
import iconGeneralBox from "../../assets/icons/icon-general-box.svg";
import HelperVideo from "../Common/HelperVideo";
import GeneralSettingsHedder from "../Common/GeneralSettingsHedder";
import BoxSettingsNavLinks from "../Common/BoxSettingsNavLinks";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import closeIcon from "../../assets/icons/icon-close-red.svg";

const BoxSettingsUploadFieldsModal = () => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isGeneral, setIsGeneral] = useState(false);
  const [showDelete, setShowDelete] = useState(0);
  const [addStage, setAddStage] = useState(false);
  const [openCustomBox, setOpenCustomBox] = useState(false);
  const [modalShow, hideModalShow] = useState(true);
  const [popUpShow, setPopUpShow] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const data = [
    {
      name: "Stead",
      profilePic: iconFb,
      count: 5,
    },
    {
      name: "Facebook Pages",
      profilePic: iconFb,
      count: 0,
    },
    {
      name: "Hunley Weddings",
      profilePic: iconInsta,
      count: 3,
    },
    {
      name: "Instagram",
      profilePic: iconInsta,
      count: 0,
    },
    {
      name: "Wedding email",
      profilePic: iconEmail,
      count: 0,
    },
  ];
  const dummyData = [
    {
      name: "alexfoster_dp@gmail.com",
    },
    {
      name: "alyonapastu@gmail",
    },
    {
      name: "Abraham Perez",
    },
    {
      name: "Anna Moreva",
    },
    {
      name: "Aldair Lina",
    },
  ];
  const fieldsName = [
    {
      name: "First Name",
    },
    {
      name: "Last Name",
    },
    {
      name: "Contact Type",
    },
    {
      name: "Organization",
    },
    {
      name: "Phone",
    },
    {
      name: "Phone type",
    },
    {
      name: "Email",
    },
    {
      name: "Billing address",
    },
  ];
  const dropdownItems = [
    {
      key: "name",
      value: "Anniversary",
    },
    {
      key: "name",
      value: "Other",
    },
    {
      key: "name",
      value: "Do not import",
    },
    {
      key: "name",
      value: "Create a question",
    },
    {
      key: "name",
      value: "Questions",
    },
    {
      key: "name",
      value: "NHS or private",
    },
    {
      key: "name",
      value: "Business name",
    },
    {
      key: "name",
      value: "Showround date",
    },
    {
      key: "name",
      value: "Allergies",
    },
  ];

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <div className="dashboard__sidebar__links">
          <div className="dashboard__sidebar__logo">
            <img
              src={logo}
              alt="eb-logo"
              className="mx-auto"
              style={{ height: 31 }}
            />
          </div>

          <ul className="flex items-center justify-center flex-col mt-8">
            <li className="dashboard__sidebar__links__list active">
              <svg width="22" height="23" viewBox="0 0 22 23" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.25 0.75C2.79822 0.75 0 3.54822 0 7V10.4223V13V15.625V16.25C0 19.4183 2.35752 22.036 5.41405 22.4446C5.92363 22.5626 6.45453 22.625 7 22.625H14.7461C15.2897 22.625 15.8188 22.563 16.3268 22.4458C19.3878 22.0413 21.75 19.4215 21.75 16.25V7C21.75 3.54822 18.9518 0.75 15.5 0.75H6.25ZM19.25 11.8975V7C19.25 4.92893 17.5711 3.25 15.5 3.25H6.25C4.17893 3.25 2.5 4.92893 2.5 7V11.9377C2.5683 12.5356 3.07601 13 3.69219 13H5.74297C6.72077 13 7.55522 13.707 7.71581 14.6715L7.91558 15.8714C8.03603 16.5948 8.66186 17.125 9.39521 17.125H12.3543C13.0876 17.125 13.7134 16.5949 13.8339 15.8716L14.034 14.6712C14.1947 13.7068 15.0291 13 16.0068 13H18.0539C18.6838 13 19.2004 12.5147 19.25 11.8975Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 0H16C19.3137 0 22 2.68629 22 6V16C22 19.3137 19.3137 22 16 22H6C2.68629 22 0 19.3137 0 16V6C0 2.68629 2.68629 0 6 0ZM12.25 6.1875C12.25 5.49714 11.6904 4.9375 11 4.9375C10.3096 4.9375 9.75 5.49714 9.75 6.1875V15.8125C9.75 16.5029 10.3096 17.0625 11 17.0625C11.6904 17.0625 12.25 16.5029 12.25 15.8125V6.1875ZM5.95837 11.8125C6.64873 11.8125 7.20837 12.3721 7.20837 13.0625V15.8125C7.20837 16.5029 6.64873 17.0625 5.95837 17.0625C5.26802 17.0625 4.70837 16.5029 4.70837 15.8125V13.0625C4.70837 12.3721 5.26802 11.8125 5.95837 11.8125ZM17.2916 10.3125C17.2916 9.62214 16.732 9.0625 16.0416 9.0625C15.3513 9.0625 14.7916 9.62214 14.7916 10.3125V15.8125C14.7916 16.5029 15.3513 17.0625 16.0416 17.0625C16.732 17.0625 17.2916 16.5029 17.2916 15.8125V10.3125Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
                <path
                  d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list dashboard__sidebar__links__list--icon-leaf">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7.45459 12C7.45459 14.5104 9.48966 16.5455 12 16.5455C14.5104 16.5455 16.5455 14.5104 16.5455 12"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C10.13 2 6.79667 2 2 2C2 6.85477 2 10.1881 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
                <path
                  d="M7.92334 2C12.914 2.82392 15.788 5.64803 16.5454 10.4723C16.5904 10.9471 16.613 11.2373 16.613 11.3428"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
              </svg>
            </li>
          </ul>

          <ul className="flex items-center justify-center flex-col mt-auto">
            <li className="dashboard__sidebar__links__list">
              <svg width="24" height="24" viewBox="0 0 30 30" fill="none">
                <rect
                  x="4"
                  y="4"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="4"
                  y="16.2222"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="16.2222"
                  y="4"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="16.2222"
                  y="16.2222"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="19" height="20" viewBox="0 0 21 22" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.96925 1.14013C7.30091 0.443651 8.00356 0 8.77497 0H11.5079C12.2793 0 12.982 0.443651 13.3136 1.14013L13.7417 2.03904H18.9415C19.6823 2.03904 20.2829 2.63963 20.2829 3.3805C20.2829 4.12137 19.6823 4.72196 18.9415 4.72196H1.34147C0.600596 4.72196 0 4.12137 0 3.3805C0 2.63963 0.600594 2.03904 1.34146 2.03904H6.5412L6.96925 1.14013ZM18.1903 6.22439H2.09271L3.42742 19.3045C3.58364 20.8355 4.87299 22 6.41192 22H13.8711C15.41 22 16.6993 20.8355 16.8556 19.3045L18.1903 6.22439Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list--profile-avatar">
              <figure
                className="avatar-container avatar-container--full"
                style={{ width: 36, height: 36 }}
              >
                <img src={avatarPic} alt="" className="avatar-pic" />
              </figure>
            </li>
          </ul>
        </div>
        <div className="dashboard__sidebar__expanded-section">
          <h2 className="text-2xl font-semibold text-grey-900">Enquirybox</h2>
          <div className="flex items-center mt-5 pb-18 border-b border-grey-400">
            <div className="flex items-center">
              <figure
                className="avatar-container avatar-container--contain"
                style={{
                  width: 32,
                  height: 32,
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={avatarPic1} alt="" />
              </figure>
              <p className="ml-3 text-base-1 font-semibold text-grey-900">
                Enquiry Assistant
              </p>
            </div>
            <div
              className="flex items-center justify-center text-xs text-white font-semibold bg-red-900 px-2 rounded-full ml-auto"
              style={{ height: 20 }}
            >
              3
            </div>
          </div>
          <div className="">
            <div className="flex items-center justify-between pt-4 pb-2">
              <h3 className="text-lg font-semibold text-grey-900">Boxes</h3>
              <img
                src={iconPlus}
                alt=""
                className="cursor-pointer"
                onClick={() => setOpenCustomBox(true)}
              />
            </div>
            <ul className="flex flex-col">
              <li
                className="dashboard__sidebar__nav-list"
                style={{ gap: 8 }}
                onClick={() => setIsGeneral(false)}
              >
                <img
                  src={iconSalesBox}
                  alt="icon-emoji-fire"
                  style={{ width: 24, height: 24 }}
                />
                <span className="text-base-1 font-medium text-grey-900">
                  Sales
                </span>
              </li>
              <li
                className="dashboard__sidebar__nav-list"
                style={{ gap: 8 }}
                onClick={() => setIsGeneral(true)}
              >
                <img
                  src={iconGeneralBox}
                  alt="icon-rocket"
                  style={{ width: 24, height: 24 }}
                />
                <span className="text-base-1 font-medium text-grey-900">
                  General
                </span>
              </li>
            </ul>
          </div>
          <div className="bg-grey-400 my-6" style={{ height: 1 }}></div>
          <div>
            <div className="flex items-center justify-between pb-2">
              <h3 className="text-lg font-semibold text-grey-900">Channels</h3>
              <img src={iconPlus} alt="" />
            </div>
            <ul className="flex flex-col">
              {data.map((item, index) => (
                <li className="dashboard__sidebar__nav-list justify-between">
                  <div className="flex" style={{ gap: 8 }}>
                    <figure className="avatar-container avatar-container--contain">
                      <img
                        src={item.profilePic}
                        alt=""
                        className="cursor-pointer"
                        style={{ width: 20, height: 20 }}
                      />
                    </figure>
                    <p className="text-base-1 font-medium text-grey-900">
                      {item.name}
                    </p>
                  </div>
                  <p className="text-15 text-grey-600 font-medium">
                    {item.count > 0 ? item.count : ""}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"General Box Settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <BoxSettingsNavLinks activeScreen={6} />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay  p-10">
              <div>
                <h2 className="text-2xl font-semibold text-center">
                  Box contacts
                </h2>
                <p className="text-grey-800 text-sm text-center mt-3">
                  Download or upload contacts
                </p>
              </div>
              <div className="mt-8">
                <HelperVideo />
                {/* <img src={tourVideoPlaceholderPic} alt="" className="mx-auto" /> */}
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
                <h3 className="font-semibold">
                  Download all box contacts (456)
                </h3>
                <button className="btn btn--primary btn--sm text-13 w-3/12 gap-1">
                  <img className="" src={iconDownload} alt="" />
                  Download
                </button>
              </div>
              <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
                <h3 className="font-semibold">Upload new contacts</h3>
                <button
                  className="btn btn--primary btn--sm text-13 w-3/12 gap-1"
                >
                  <img className="" src={iconUpload} alt="" />
                  Upload
                </button>
              </div>
              <div className="border border-grey-400 border-radius-14 bg-white mt-10 py-5">
                <h3 className="text-lg font-semibold ml-6">Upload history</h3>
                <div className="px-4 w-full">
                  <table className="tabel-upload-history">
                    <thead>
                      <tr className="bg-grey-200 rounded-md">
                        <th>File</th>
                        <th>Contacts</th>
                        <th>Date</th>
                        <th style={{ textAlign: "right" }}>Rollback</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          border: 0,
                          borderRadius: 0,
                          backgroundColor: "transparent",
                        }}
                      >
                        <td
                          style={{
                            paddingTop: 4,
                            paddingBottom: 4,
                            border: 0,
                            borderRadius: 0,
                          }}
                        ></td>
                      </tr>
                      <tr>
                        <td>General box.csv</td>
                        <td>256</td>
                        <td>Jun 29, 2022</td>
                        <td>
                          <div
                            className="flex items-center justify-center bg-white ml-auto"
                            style={{ width: 30, height: 30, borderRadius: 6 }}
                          >
                            <img src={iconRollBack} />
                          </div>
                        </td>
                      </tr>
                      <tr
                        style={{
                          border: 0,
                          borderRadius: 0,
                          backgroundColor: "transparent",
                        }}
                      >
                        <td
                          style={{
                            paddingTop: 2,
                            paddingBottom: 2,
                            border: 0,
                            borderRadius: 0,
                          }}
                        ></td>
                      </tr>
                      <tr>
                        <td>Contact list.csv</td>
                        <td>500</td>
                        <td>Apr 08, 2022</td>
                        <td>
                          <div
                            className="flex items-center justify-center bg-white ml-auto"
                            style={{ width: 30, height: 30, borderRadius: 6 }}
                          >
                            <img src={iconRollBack} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>      
          </div>
        </section>
      </section>
      {/* <AddCustomBox openCustomBox={openCustomBox} setOpenCustomBox={setOpenCustomBox}/> */}
      {modalShow && (
        <div className="modal modal--match-fields show">
          <div className="modal__container">
            <div className={`modal__header`}>
              <h2 className="modal__heading pr-5">Match your fields</h2>
              <div className="modal__close-icon">
                <img src={modalCloseIcon} alt="" />
              </div>
            </div>
            <div
              className={`relative modal__body px-12 py-6 ${
                popUpShow ? "blur-bg" : ""
              }`}
            >
              <div className="flex -mx-5">
                <div className="w-3/6 px-4">
                  <label className="font-semibold">CSV column names</label>
                </div>
                <div className="w-3/6 px-4">
                  <label className="font-semibold">Enquirybox questions</label>
                </div>
              </div>
              {fieldsName.map((item, index) => {
                return (
                  <div className="flex -mx-5 mt-5">
                    <div className="w-3/6 px-5">
                      <div
                        className="w-full flex items-center px-5 py-1 bg-grey-300 rounded-lg truncate"
                        style={{
                          boxShadow:
                            "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                          height: 42,
                        }}
                      >
                        <label className="text-base-1 truncate">
                          {item.name}
                        </label>
                      </div>
                    </div>
                    <div className="relative w-3/6 px-5">
                      <input
                        className={`input-field input-field--select input-field--md ${
                          popUpShow && "error"
                        }`}
                        placeholder={`Select ${item.name}`}
                        onClick={() => {
                          setActiveIndex(index);
                          setOpenDropdown(true);
                        }}
                      />
                      {index === activeIndex && openDropdown && (
                        // <div
                        //   className={`dropdown-menu dropdown-menu__upload shadow-none open`}>
                        //   {dropdownItems.map((item) => (
                        //     <div
                        //       className="dropdown-menu__list"
                        //       onClick={() => {
                        //         setOpenDropdown(false);
                        //       }}>
                        //       {item.value}
                        //     </div>
                        //   ))}
                        // </div>
                        <div className="dropdown-menu dropdown-menu--sm p-2 open">
                          <div className="pb-2 border-b border-grey-400">
                            <h6 className="text-xs font-medium text-grey-700 mb-1 mt-2 ml-3">
                              Other
                            </h6>
                            <div className="dropdown-menu__list">
                              Do not import
                            </div>
                            <div className="dropdown-menu__list">
                              Create a question
                            </div>
                          </div>
                          <div className="pb-2 border-b border-grey-400">
                            <h6 className="text-xs font-medium text-grey-700 mb-1 mt-2 ml-3">
                              Group heading
                            </h6>
                            <div className="dropdown-menu__list">
                              Anniversary
                            </div>
                            <div className="dropdown-menu__list">Birthday</div>
                          </div>
                          <div className="">
                            <h6 className="text-xs font-medium text-grey-700 mb-1 mt-2 ml-3">
                              Group heading
                            </h6>
                            <div className="dropdown-menu__list">Phone 1</div>
                            <div className="dropdown-menu__list">
                              Phone 1 ext
                            </div>
                            <div className="dropdown-menu__list">
                              Phone 1 ext
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="flex items-center justify-end gap-3 mt-12">
                <button className="btn btn--secondary btn--md min-w-120">
                  Cancel
                </button>
                <button
                  className="btn btn--error btn--md btn--wide-20 min-w-120"
                  onClick={() => {
                    setPopUpShow(true);
                  }}
                >
                  Finish upload
                </button>
              </div>
            </div>
            <div
              className={`match-field-error-popup ${popUpShow ? "show" : ""}`}
            >
              <p className="text-sm text-red-900 text-center">
                Please match all imported fields to enquirybox questions.
                Unmatched fields are marked in red
              </p>
              <img
                src={closeIcon}
                alt="icon-close"
                className="absolute top-3 right-3 cursor-pointer"
                onClick={() => hideModalShow(false)}
              />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default BoxSettingsUploadFieldsModal;
