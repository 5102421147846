import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/eb-logo.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic1 from "../assets/icons/icon-avatar.svg";
import Tab from "./Tabs";
import { useHistory } from "react-router-dom";
import HelperVideo from "./Common/HelperVideo";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import ChannelHeader from "./Common/ChannelHeader";

const ConnectingOtherEmailProvider = () => {
  const [tabsView, setTabsView] = useState(true);
  let history = useHistory();

  const nextScreen = () => {
    history.push("/send-replies");
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />
        <section className="dashboard__main">
				<ChannelHeader
            text={"Contact Form"}
            linkText="Settings & Notifications"
            HeaderIcon={() => (
							<figure className="avatar-container avatar-container--bg-color">
								<p className="text-xl font-bold text-white">FK</p>
								<div className="avatar-container__sup-icon">
									<svg width="11" height="11" viewBox="0 0 16 16" fill="none">
										<path
											d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
											fill="#fff"
										/>
									</svg>
								</div>
							</figure>
            )}
          />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left">
              <h2 className="text-lg font-semibold text-grey-900">Messages</h2>
              <div className="mt-15">
                <label className="text-xs font-medium text-grey-700 uppercase">
                  New
                </label>
                <ul className="">
                  <li className="bg-color-on-hover flex items-center py-2 cursor-pointer active">
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{
                        width: 48,
                        height: 48,
                        border: "1px solid rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <img src={iconAvatar} className="" alt=""/>
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900 truncate">
                        Enquiry Assistant
                      </h3>
                      <p className="text-base-1 text-grey-800 truncate">
                        Connect your contact form
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
                <div className="mb-3">
                  <button className="btn btn--back btn--text">
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M4.91895 1L1.00195 5L4.91895 9"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 4.99634L11 4.99634"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <span className="ml-2 text-sm font-medium text-primary">
                      Back
                    </span>
                  </button>
									<div className="mt-4">
										<HelperVideo />
									</div>								
                  {/* <div className="video-container mt-4">
                    <img
                      src={contactFormPlaceholder}
                      alt=""
                      className="w-full h-full"
                    />
                  </div> */}
                  <div className="mt-6">
                    <label className="input-field-label">
                      Add your email address
                    </label>
                    <input
                      type="text"
                      className="input-field input-field--md"
                      placeholder="Email address"
                    />
                  </div>
                </div>
                <button className="btn btn--primary btn--md mt-auto ml-auto" style={{padding: '8px 30px'}} onClick ={nextScreen}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default ConnectingOtherEmailProvider;
