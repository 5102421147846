import React, { useState, useRef, useEffect } from "react";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import AutomateOptionIcon from "../assets/icons/icon-flash.svg";
import ChannelConnectLinks from "./Common/ChannelConnectLinks";
import Snackbar from "./Common/SnackBar";
import iconDelete from "../assets/icons/icon-delete-white.svg";
import iconAlert from "../assets/icons/alert.svg";

import iconCheck from "../assets/icons/Group5569.svg";
import iconCross from "../assets/icons/Group12629.svg";
import ToggleContactLink from "./Common/ToggleContactLink";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";

const data = [
  {
    id: 1,
    icon: AutomateOptionIcon,
    title: "Send a message when enquiry comes into this channel",
    desc: "The automation is live and has run 47 times",
  },
  {
    id: 2,
    icon: AutomateOptionIcon,
    title: "Assign enquiry to team member for this channel",
    desc: "This automation is not live - flick the switch to turn it on",
  },
  {
    id: 3,
    icon: AutomateOptionIcon,
    title: "Move enquiries from this channel directly to a box",
    desc: "This automation is not live - flick the switch to turn it on",
  },
];

const ChannelConneted = ({
  isError = true,
  facebookReconnect = true,
  webformConnect = true,
  instaReconnect = false,
  isConnted = false,
}) => {
  const [openBoxInfo, setOpenBoxInfo] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });
  const [type, setType] = useState(false);
  const snackbarRef = useRef(null);
  const handleChange = (event) => {
    snackbarRef.current.show();
    event.target.checked ? setType("success") : setType("fail");
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setType(!type);
  }, []);

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew
          setOpenBoxInfo={setOpenBoxInfo}
          setOpenInviteModal={setOpenInviteModal}
        />

        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"General Box Settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay">
              {!isConnted && (
                <ToggleContactLink
                  title="Connect a channel to your enquirybox"
                  plus={true}
                  Subtitle="PENDING CONNECTIONS"
                />
              )}
              <ChannelConnectLinks facebookFailed={true} />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay layout-1" style={{backgroundColor: "white"}}>
              <div>
                <h2 className="text-2xl font-semibold text-center">
                  Channel Settings
                </h2>
              </div>
              {webformConnect && (
                <>
                  <div className="card-box pt-5 px-6 pb-6 mt-8">
                    <h3 className="text-lg font-semibold">Channel name</h3>
                    <div className="mt-3">
                      <input
                        className="input-field input-field--md"
                        placeholder="Website form 1"
                      />
                    </div>
                  </div>
                  <div className="card-box px-6 py-4 mt-10">
                    <div className="">
                      <h4 className="font-semibold text-lg">
                        To connect, copy this code and add it to your website
                      </h4>
                      <textarea
                        name=""
                        rows="2"
                        cols="5"
                        placeholder=""
                        value={`<script type="text/javascript" src="https://launcher.enquirybot.com/index.js" data-bot-id="499f969c-1d63-4bc5-9a05-5c8246ba71c8"></script>`}
                        style={{ padding: "15px 20px", fontWeight: "normal" }}
                        className="input-field input-field__textarea border text-13 mt-4 resize-none"
                      ></textarea>
                      <div className="flex justify-between">
                        <button className="btn btn--primary btn--xs mt-1">
                          Copy code
                        </button>
                        <p className="flex justify-center items-center text-13 font-medium text-primary align-center">
                          Read more on adding the code
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <>
                <div className="card-box px-6 py-4 mt-8">
                  <div>
                    <h3 className="text-lg font-semibold">Automate stuff</h3>
                    <p className="text-grey-800 text-15">
                      Automatically send email, Assign enquiries and more...
                    </p>
                  </div>
                  {data.map((ele, index) => {
                    return (
                      <div
                        className="flex justify-between pt-5 pr-5 pb-4 pl-6 mt-6 bg-grey-300 border  border-grey-400 border-radius-10"
                        key={index}
                      >
                        <div className="flex items-start">
                          <img
                            src={ele.icon}
                            alt="icon"
                            style={{
                              marginRight: 15,
                              marginTop: 3,
                              height: 21,
                              width: 16,
                            }}
                          />
                          <div>
                            <div className="font-semibold">{ele.title}</div>
                            <div className="text-grey-800 text-sm mt-1">
                              {ele.desc}
                            </div>
                          </div>
                        </div>
                        <div className="toggle-slider">
                          <input
                            type="checkbox"
                            id={ele.id}
                            className="toggle-slider__checkbox"
                            name="checkedA"
                            value={state.checkedA}
                            onChange={handleChange}
                          />
                          <label
                            htmlFor={ele.id}
                            className="toggle-slider__label"
                          >
                            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="card-box pt-4 px-6 pb-8 mt-7">
                  <h3 className="font-semibold">
                    Forms connected to this page
                  </h3>
                  <p className="mt-1 text-15 text-grey-800">
                    Lead ad submissions from these pages will come into your
                    enquirybox
                  </p>
                  <div className="mt-6">
                    <div
                      className="card-box flex justify-between"
                      style={{ padding: "10px 28px 10px 20px", backgroundColor: "#FAF9F8" }}
                    >
                      <p className="text-15 font-medium text-grey-800">Invisalign New Year Offer form</p>
                      <div className="toggle-slider">
                        <input
                          type="checkbox"
                          id="fb-leads-1"
                          className="toggle-slider__checkbox"
                          name="checkedA"
                        />
                        <label
                          htmlFor="fb-leads-1"
                          className="toggle-slider__label"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                        </label>
                      </div>
                    </div>
										<div
                      className="card-box flex justify-between mt-3"
                      style={{ padding: "10px 28px 10px 20px", backgroundColor: "#FAF9F8" }}
                    >
                      <p className="text-15 font-medium text-grey-800">Teeth Whitening special discount form</p>
                      <div className="toggle-slider">
                        <input
                          type="checkbox"
                          id="fb-leads-2"
                          className="toggle-slider__checkbox"
                          name="checkedA"
                        />
                        <label
                          htmlFor="fb-leads-2"
                          className="toggle-slider__label"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                        </label>
                      </div>
                    </div>
										<div
                      className="card-box flex justify-between mt-3"
                      style={{ padding: "10px 28px 10px 20px", backgroundColor: "#FAF9F8" }}
                    >
                      <p className="text-15 font-medium text-grey-800">Marketing offer form</p>
                      <div className="toggle-slider">
                        <input
                          type="checkbox"
                          id="fb-leads-3"
                          className="toggle-slider__checkbox"
                          name="checkedA"
                        />
                        <label
                          htmlFor="fb-leads-3"
                          className="toggle-slider__label"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-box mt-5 px-6 py-5">
                  <div>
                    <div className="flex justify-between items-center">
                      <h3 className="font-semibold">Channel connection</h3>
                      <div className="flex gap-2">
                        {facebookReconnect ? (
                          <button
                            className="btn btn--sm btn--facebook gap-2"
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 6,
                              paddingBottom: 6,
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                                fill="#ffffff"
                              />
                            </svg>
                            Reconnect page
                          </button>
                        ) : (
                          instaReconnect && (
                            <button
                              className="btn btn--sm btn--instagram text-base-1 gap-2"
                              style={{
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingTop: 6,
                                paddingBottom: 6,
                              }}
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
                                  fill="#ffffff"
                                />
                              </svg>
                              Reconnect account
                            </button>
                          )
                        )}
                        <button
                          className="btn btn--error btn--sm text-13"
                          style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 6,
                            paddingBottom: 6,
                          }}
                        >
                          <img
                            src={iconDelete}
                            alt="edit-icon"
                            className="mr-2"
                          />
                          Delete
                        </button>
                      </div>
                    </div>
                    {isError && (
                      <div className="flex error-view mt-4">
                        <div className="self-center">
                          <img src={iconAlert} alt=""/>
                        </div>
                        <span className="text-13 text-red-900 text-center ml-3">
                          The page connection has expired, please click the
                          reconnect page button{" "}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
              <Snackbar
                ref={snackbarRef}
                message={
                  type === "success" ? "Channel connected" : "Disconnected"
                }
                type={type}
              />
              <div
                className="snackbar items-center"
                id={type ? "show" : "hide"}
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <div className="symbol">
                  {type ? <img src={iconCheck} /> : <img src={iconCross} />}
                </div>
                <div className="message">
                  {type ? "Channel connected" : "Disconnected"}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default ChannelConneted;
