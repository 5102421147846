import React from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";

const CreateChannel = ({ setShowFileUpload, showFileUpload }) => {
    return (
        <div className={`modal ${showFileUpload ? "show" : ""}`}>
            <div className="modal__container" >
                <div className="modal__header">
                    <h2 className="modal__heading pr-5">Create a channel</h2>
                    <div
                        className="modal__close-icon"
                        onClick={() => setShowFileUpload(false)}
                    >
                        <img src={modalCloseIcon} alt="" />
                    </div>
                </div>
                <div className="modal__body px-8 pb-10">
                    <div className="mt-10">
                        <label className="input-field-label">Channel name</label>
                        <input
                            className="input-field input-field--md"
                            placeholder="Give it a name that reflects the software it’s coming from"
                        />
                    </div>

                    <div className="flex items-center justify-end gap-3 mt-12">
                        <button className="btn btn--grey btn--sm min-w-120" onClick={() => setShowFileUpload(false)}>Cancel</button>
                        <button className="btn btn--primary btn--sm min-w-120">Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateChannel;
