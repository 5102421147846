import React, { useState } from "react";

const Tab = ({ tabs , active = 0}) => {
  const [activeTab, setActiveTab] = useState(active);

  return (
    <div className="tabs-wrapper">
      {tabs.map((item, index) => {
        return (
          <div
            className={`tabs-header ${activeTab === index ? "tabs-header__active" : ""}`}
            onClick={() => {
              setActiveTab(index);
              item.onClick && item.onClick();
            }}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};
export default Tab;
