import React from "react";
import iconMessanger from '../../assets/icons/icon-messanger-channel.svg';
import iconWhatsApp from '../../assets/icons/icon-whatsapp-channel.svg';
import iconInsta from '../../assets/icons/icon-insta-channel.svg';
import iconFbLeads from '../../assets/icons/icon-fb-leads-channel.svg';
import iconWebform from '../../assets/icons/icon-webform-channel.svg';
import { Link } from "react-router-dom";
import ToggleContactLink from "./ToggleContactLink";
import iconAlert from "../../assets/icons/alert.svg";
import greydelete from "../../assets/icons/icon-trash-grey.svg";

const ChannelConnectLinks = ({ isSettings = false, setActiveScreen, activeScreen, facebookFailed, instgramFailed, isConnted = false, facebookConnect = false, whatappFail = false, show = false, }) => {

  return (
    <ul className="">
      {show && <li>
        {!isConnted && <ToggleContactLink title="Connect a channel to your enquirybox"
          Subtitle="PENDING CONNECTIONS"
        />}
        {isConnted &&
          <ToggleContactLink
            plus={true}
            title="Connect a channel to your enquirybox"
            SubTitle2="CONNECTED CHANNEL"
          />}
      </li>}
      <Link
        to="/dashboard-facebook"
        className={`chats-preview chats-preview--new mt-2 ${facebookFailed ? 'error-view bg-color-on-hover--err' : 'bg-color-on-hover bg-color-on-hover'} ${activeScreen === 1 ? 'active' : ''} `}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="chats-preview__avatar-container bg-grey-300 flex justify-center items-center">
            <img src={iconMessanger} className="" alt="iconMessanger" style={{ width: 28, height: 28 }} />
          </figure>
          <div className="truncate pr-4">
            <h3 className="chats-preview__title" style={{ fontWeight: 600 }}>Connect Facebook Pages</h3>
            <p className="chats-preview__msg" style={{ color: "#4C5254" }}>
              One minute setup
            </p>
          </div>
        </div>
        {isSettings ? <div className="chats-preview__add-info">
          <label className="notification-label notification-label--new-msg">
            New
          </label>
          <p className="chats-preview__time">1m</p>
        </div> :
          !facebookFailed ?
            <div className="self-center chats-preview__icon">
              <img src={greydelete}></img>
            </div>
            :
            <div className="self-center">
              <img src={iconAlert}></img>
            </div>}
      </Link>
      {facebookConnect && <ToggleContactLink
        SubTitle2="CONNECTED CHANNEL"
        plus={false}
      />}
      <Link
        to="/dashboard-instagram"
        className={`chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm mt-2 ${instgramFailed ? 'error-view bg-color-on-hover--err' : ''} ${activeScreen === 2 ? 'active' : ''}`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="chats-preview__avatar-container bg-grey-300 flex justify-center items-center">
            <img src={iconInsta} className="" alt="iconInsta" style={{ width: 28, height: 28 }} />
          </figure>
          <div className="truncate pr-4">
            <h3 className="chats-preview__title" style={{ fontWeight: 600 }}>Connect Instagram Accounts</h3>
            <p className="chats-preview__msg" style={{ color: "#4C5254" }}>One minute setup</p>
          </div>
        </div>
        {isSettings ? <div className="chats-preview__add-info">
          <label className="notification-label notification-label--new-msg">
            New
          </label>
          <p className="chats-preview__time">1m</p>
        </div> :
          !instgramFailed ?
            <div className="self-center chats-preview__icon">
              <img src={greydelete}></img>
            </div> :
            <div className="self-center">
              <img src={iconAlert}></img>
            </div>}
      </Link>
      <Link
        to="/channel-whatapp"
        className={`chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm mt-2  ${whatappFail ? 'error-view bg-color-on-hover--err' : ''} ${activeScreen === 3 ? 'active' : ''}`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="chats-preview__avatar-container bg-grey-300 flex justify-center items-center">
            <img src={iconWhatsApp} className="" alt="iconWhatsApp" style={{ width: 28, height: 28 }} />
          </figure>
          <div className="truncate pr-4">
            <h3 className="chats-preview__title" style={{ fontWeight: 600 }}>Connect Whats App Account</h3>
            <p className="chats-preview__msg" style={{ color: "#4C5254" }}>One minute setup</p>
          </div>
        </div>
        {isSettings ? <div className="chats-preview__add-info">
          <label className="notification-label notification-label--new-msg">
            New
          </label>
          <p className="chats-preview__time">1m</p>
        </div> :
          !whatappFail ?
            <div className="self-center chats-preview__icon">
              <img src={greydelete}></img>
            </div> :
            <div className="self-center">
              <img src={iconAlert}></img>
            </div>}
      </Link>
      <Link
        to="/channel-facebook-leads"
        className={`chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm mt-2 ${activeScreen === 4 ? 'active' : ''}`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="chats-preview__avatar-container bg-grey-300 flex justify-center items-center">
            <img src={iconFbLeads} className="" alt="iconFbLeads" style={{ width: 28, height: 28 }} />
          </figure>
          <div className="truncate pr-4">
            <h3 className="chats-preview__title" style={{ fontWeight: 600 }}>Connect Facebook Lead Ads</h3>
            <p className="chats-preview__msg" style={{ color: "#4C5254" }}>
              One minute setup
            </p>
          </div>
        </div>
        {isSettings ? <div className="chats-preview__add-info">
          <label className="notification-label notification-label--new-msg">
            New
          </label>
          <p className="chats-preview__time">1m</p>
        </div> :
          <div className="self-center chats-preview__icon">
            <img src={greydelete}></img>
          </div>}
      </Link>
      <Link
        to="/connect-website-form"
        className={`chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm mt-2 ${activeScreen === 5 ? 'active' : ''}`}
      >
        <div className="flex items-center gap-4 flex-1 truncate">
          <figure className="chats-preview__avatar-container bg-grey-300 flex justify-center items-center">
            <img src={iconWebform} className="" alt="iconWebform" style={{ width: 28, height: 28 }} />
          </figure>
          <div className="truncate pr-4">
            <h3 className="chats-preview__title" style={{ fontWeight: 600 }}>Connect Wesbite Forms</h3>
            <div className="flex items-center gap-1">
              <p className="chats-preview__msg" style={{ color: "#4C5254" }}>
                One minute setup
              </p>
            </div>
          </div>
        </div>
        {isSettings ? <div className="chats-preview__add-info">
          <label className="notification-label notification-label--new-msg">
            New
          </label>
          <p className="chats-preview__time">1m</p>
        </div> :
          <div className="self-center chats-preview__icon">
            <img src={greydelete}></img>
          </div>}
      </Link>
    </ul >
  )
}

export default ChannelConnectLinks