import React from "react";
import closeIcon from '../assets/icons/icon-tooltip-close.svg';

const PopUp = ({ width = '', text = '', img = '', subTextOne = '', subTextTwo = '', children }) => {
  return (
    <div className="popup relative">{children}
      <span className="tooltiptext text-base-1 p-4 pl-5 text-left hidden" style={{ width: width }}>{text}
				<img src={closeIcon} alt="" className='inline tooltip__close-icon'/>
        {((subTextOne || subTextTwo)&& img) && <div>
          <button className="btn btn--white btn--enquiry mt-5"><span className='font-bold'>{subTextOne}</span>,{subTextTwo}</button>
          <img src={img} alt="" className='tooltip__hand-icon inline' />
        </div>}
      </span>
    </div>
  )
}
export default PopUp;