import React, { useState, useEffect } from 'react'
import GeneralSettingsHedder from './Common/GeneralSettingsHedder'
import SidebarExpanded from './Common/SidebarExpanded'
import SidebarLinks from './Common/SidebarLinks'
import plusIcon from '../assets/icons/icon-plus-icon.svg';
import avatarPic from "../assets/images/avatar-pic.png";
import AutomateOptionIcon from '../assets/icons/icon-flash.svg';
import iconAddNewAutomation from '../assets/icons/icon-new-automation-icon.svg';
import iconThenAutomation from '../assets/icons/icon-automation-then-icon.svg';
import iconCaretUp from '../assets/icons/icon-caret-up.svg';
import footerPlusIcon from '../assets/icons/icon-footer-plus.svg';
import footerSmileyIcon from '../assets/icons/icon-footer-smiley.svg';
import footerGalleryIcon from '../assets/icons/icon-footer-gallery.svg';
import footerAttachmentsIcon from '../assets/icons/icon-footer-attachments.svg';
import CustomToolTip from './Common/CustomToolTip';
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";
import iconEmojiFire from "../assets/icons/icon-emoji-fire.svg";
import iconEdit from '../assets/icons/icon-edit.svg';
import iconDots from "../assets/icons/icon-more-round.svg";
import deleteIcon from '../assets/icons/icon-Delete.svg';
import iconAddAutomationRound from '../assets/icons/icon-add-automation-round.svg';
import iconClose from '../assets/icons/icon-modal-close.svg';

const data = [
	{
		id: 1,
		icon: AutomateOptionIcon,
		title: 'Move to sales box when enquiry is moved to Won stage',
		desc: 'The automation is live and has run 47 times'
	},
	{
		id: 2,
		icon: AutomateOptionIcon,
		title: 'Move to sales box when enquiry is moved to Won stage',
		desc: 'This automation is not live - flick the switch to turn it on'
	}
];

const usersData = [
	{
		id: 1,
		name: "John Leon",
	},
	{
		id: 2,
		name: "Madison Inouye",
	},
	{
		id: 3,
		name: "Olivia Rhye",
	},
	{
		id: 4,
		name: "Charles Wundeng",
	},
	{
		id: 5,
		name: "Lisa Fotios",
	},
	{
		id: 6,
		name: "Elene Mako",
	},
	{
		id: 7,
		name: "Alex Foster"
	}
];

const moveToOptions = [
	{
		name: "Sales",
		icon: iconEmojiFire,
		bgColor: "#4B7FE6"
	},
	{
		name: "General",
		icon: iconEmojiFire,
		bgColor: "#57D1F8"
	},
	{
		name: "Potential Leads",
		icon: iconEmojiFire,
		bgColor: "#F55E5E"
	},
	{
		name: "Marketing Leads",
		icon: iconEmojiFire,
		bgColor: "#EC9E54"
	},
	{
		name: "Product Enquiries",
		icon: iconEmojiFire,
		bgColor: "#35CC71"
	},
];

const BoxSettingsAutomateStuff = () => {
	const [addAutomation, setAddAutomation] = useState(false)
	const [openWhen, setOpenWhen] = useState(false);
	const [openThen, setOpenThen] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	const [openDropdown,setOpenDropdown]= useState(false);
	const [openOptions,setOpenOptions] = useState(false);
	const [openFirstThen,setOpenFirstThen] = useState(false);
	const [selectedStageOption,setSelectedStageOption] = useState('Select the stage');
	const [selectedValue, setSelectedValue] = useState('Select what you would like to do now...')
	const [selectedStage, setSelectedStage] = useState('An enquiry move to a particular stage')
	const [showMoreDropdown,setShowMoreDropdown] = useState(0);
	const [channelsOptions,setChannelsOptions] = useState(false)
	const [editName,setEditName] = useState(false);
	const [openFromDropdown,setOpenFromDropdown] = useState(false);
	const [selectedChannelsOption,setSelectedChannelsOption]= useState('Any Channel')
	const bracketIcon = "{ }";

	useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

	const handleClick = () => {
		setShowMoreDropdown(0);
	};


	const SendEmailSection = () => {
		return (
			<div className='border-radius-10 bg-white border border-grey-400 p-4'>
				<div className='flex items-center pb-3 border-b border-popup-border-color'>
					<label className='text-sm font-medium text-grey-600 mr-2'>From</label>
					<div className='flex items-center relative' onClick={() => setOpenFromDropdown(!openFromDropdown)}>
						<div className='text-sm mr-1 cursor-pointer'>madisoninouye@gmail.com</div>
						<img src={iconCaretUp} alt='' style={{ width: 10, height: 6 }} />
						<div className={`dropdown-menu dropdown-menu--filter ${openFromDropdown ? 'open' : ''}`}>
							<div className="dropdown-menu__list">madisoninouye@gmail.com</div>
							<div className="dropdown-menu__list">madisoninouye@gmail.com</div>
							<div className="dropdown-menu__list">madisoninouye@gmail.com</div>
						</div>
					</div>				
				</div>
				<div className='flex items-center pb-3 pt-2 border-b border-popup-border-color'>
					<label className='text-sm font-medium text-grey-600 mr-2'>Cc</label>
					<input className='input-field input-field__noborder' type='text' style={{fontSize: 13, fontWeight: 400}}/>
				</div>
				<div className='flex items-center pb-3 pt-2 border-b border-popup-border-color'>
					<label className='text-sm font-medium text-grey-600 mr-2'>Bcc</label>
					<input className='input-field input-field__noborder' type='text' style={{fontSize: 13, fontWeight: 400}}/>
				</div>
				<div className='flex items-center pb-3 pt-2 border-b border-popup-border-color'>
					<label className='text-sm font-medium text-grey-600 mr-2'>Subject</label>
					<input className='input-field input-field__noborder' type='text' style={{fontSize: 13, fontWeight: 400}}/>
				</div>
				<div>
					<textarea
						name="tedx"
						rows="6"
						cols="5"
						placeholder="Write your email here..."
						className="text-sm text-grey-900 resize-none w-full py-4 border-none outline-none"
					></textarea>
					<div className='flex items-center justify-between'>
						<div className='flex items-center gap-3'>
							<img src={footerPlusIcon} alt='footerPlusIcon' style={{ width: 22, height: 22 }} className='cursor-pointer' />
							<code
								className="inline-block"
								style={{
									width: 1,
									height: 22,
									backgroundColor: "#E5E5E5",
								}}
							></code>
							<img src={footerSmileyIcon} alt='footerSmileyIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
							<img src={footerGalleryIcon} alt='footerGalleryIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
							<img src={footerAttachmentsIcon} alt='footerAttachmentsIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
							<span className='cursor-pointer bg-color-on-hover bg-color-on-hover--xs'>Aa</span>
							<CustomToolTip position={`${openDropdown ? 'invisible': 'chat-footer'}`} tooltipText="Insert contact details or question">
								<span className='cursor-pointer bg-color-on-hover bg-color-on-hover--xs' onClick={() => setOpenDropdown(!openDropdown)}>{bracketIcon}</span>
								<div className={`dropdown-menu dropdown-menu--email-automation ${openDropdown ? 'open' : ''}`}>
									<div className='border-b-2 border-grey-400 pb-2'>
										<h4 className='text-xs font-medium text-grey-700 mb-1' style={{marginLeft: 10}}>Contact details</h4>
										<div className="dropdown-menu__list">First name</div>
										<div className="dropdown-menu__list">Last name</div>
										<div className="dropdown-menu__list">Email</div>
										<div className="dropdown-menu__list">Phone number</div>
									</div>
									<div className='border-b-2 border-grey-400 pb-2 pt-2'>
										<h4 className='text-xs font-medium text-grey-700 mb-1' style={{marginLeft: 10}}>Group heading</h4>
										<div className="dropdown-menu__list">Anniversary</div>
										<div className="dropdown-menu__list">Birthday</div>
									</div>
									<div className='pt-2'>
										<h4 className='text-xs font-medium text-grey-700' style={{marginLeft: 10}}>Group heading</h4>
										<div className="dropdown-menu__list">Phone 1</div>
									</div>
								</div>								
							</CustomToolTip>		
						</div>
						<button
							className="btn btn--primary text-sm"
							style={{ padding: "5px 14px", borderRadius: 6 }}
							onClick={() => {setOpenThen(!openThen); setSelectedOption('')}}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		)
	}
	const SendSmsSection = () => {
		return (
			<div className='border-radius-10 bg-white border border-grey-400 p-4'>
				<div className='flex items-center pb-3 border-b border-popup-border-color'>
					<label className='text-sm font-medium text-grey-600 mr-2'>From</label>
					<div className='text-sm'>+44344465735</div>
				</div>
				<div>
					<textarea
						name="tedx"
						rows="4"
						cols="5"
						placeholder=""
						className="text-sm text-grey-900 resize-none w-full py-4 border-none outline-none"
					></textarea>
					<span className='text-grey-500 text-13'>120/120</span>
					<div className='flex items-center justify-between mt-3'>
						<div className='flex items-center gap-3'>
							<img src={footerSmileyIcon} alt='footerSmileyIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
							<span className='cursor-pointer bg-color-on-hover bg-color-on-hover--xs'>Aa</span>
							<CustomToolTip position={`${openDropdown ? 'invisible' : 'sms-variable'}`} tooltipText="Insert contact details or question">
								<span className='cursor-pointer bg-color-on-hover bg-color-on-hover--xs' onClick={() => setOpenDropdown(!openDropdown)}>{bracketIcon}</span>
								<div className={`dropdown-menu dropdown-menu--variables ${openDropdown ? 'open' : ''}`}>
									<div className='border-b-2 border-grey-400 pb-2'>
										<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Contact details</h4>
										<div className="dropdown-menu__list">First name</div>
										<div className="dropdown-menu__list">Last name</div>
										<div className="dropdown-menu__list">Email</div>
										<div className="dropdown-menu__list">Phone number</div>
									</div>
									<div className='border-b-2 border-grey-400 pb-2 pt-2'>
										<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Group heading</h4>
										<div className="dropdown-menu__list">Anniversary</div>
										<div className="dropdown-menu__list">Birthday</div>
									</div>
									<div className='pt-2'>
										<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Group heading</h4>
										<div className="dropdown-menu__list">Phone 1</div>
									</div>
								</div>
							</CustomToolTip>
						</div>
						<button
							className="btn btn--primary text-sm"
							style={{ padding: "5px 14px", borderRadius: 6 }}
							onClick={() => setAddAutomation(!addAutomation)}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		)
	}
	const AssignTeamMemberSection = () => {
		return (
			<div className='border-radius-10 bg-white border border-grey-400 p-2'>
				<div className='dropdown-menu__team-access' style={{ height: 282 }}>
					{usersData.map((user, index) => {
						return (
							<div
								key={index}
								className="dropdown-menu__list flex justify-between items-center"
							>
								<div className='flex items-center'>
									<img
										className="mr-3"
										src={avatarPic}
										style={{ width: 32, height: 32 }}
										alt=''
									/>
									{user.name}
									
								</div>
								<div>
									<svg
										width="12"
										height="10"
										viewBox="0 0 12 10"
										fill="none"
									>
										<path
											d="M1 5.37637L4.13194 9L11 1"
											stroke="#005C6F"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</div>
							</div>
						);
					})}
				</div>	
			</div>
		)
	}
	const MoveToBox = () => {
		return (
			<div className='border-radius-10 bg-white border border-grey-400 px-2 py-4'>
				<div className='dropdown-menu__overflow-y-scroll'>
				<p className="text-grey-700 px-3 text-xs" style={{ marginBottom: 6 }}>Move to</p>
					{moveToOptions.map((user, index) => {
						return (
							<div
								key={index}
								className="dropdown-menu__list flex justify-between items-center"
								style={{ color: "#2B3639" }}
							>
								<div className='flex items-center'>
									<img
										className="mr-3"
										src={user.icon}
										style={{ width: 20, height: 20 }}
										alt=''
									/>
									{user.name}
								</div>
								<div>
									<svg
										width="12"
										height="10"
										viewBox="0 0 12 10"
										fill="none"
									>
										<path
											d="M1 5.37637L4.13194 9L11 1"
											stroke="#005C6F"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</div>
							</div>
						);
					})}
					<div className='flex justify-end mt-3'>
						<button
							className="btn btn--primary text-sm mr-2"
							style={{ padding: "5px 14px", borderRadius: 6 }}
						>
							Move
						</button>
					</div>				
				</div>
			</div>
		)
	}
	const DelayTimer = () => {
		return (
			<div className='border-radius-10 bg-white border border-grey-400 p-4'>
				<div className='font-medium'>Wait for 2 hours and then run on weekdays</div>
				<div className="mt-7 h-48">
					<div className="items-baseline flex w-full">
						<div className="w-1/4">
							<label className="text-15 font-semibold">Wait for</label>
						</div>
						<div className="flex gap-2" style={{width: "35%"}}>
							<input
								type="number"
								className="input-field input-field__timer-input"
								min="1"
								placeholder=""
							/>
							<div className="flex">
								<select defaultValue="MINUTES" className="custom-select__automation text-15">
									<option value='HOURS'>Hours</option>
									<option value='MINUTES'>Minutes</option>
									<option value='DAYS'>Days</option>
								</select>
							</div>
						</div>
						
					</div>
					<div className="items-baseline flex w-full mt-3">
						<div className="w-1/4">
							<label className="text-15 font-semibold">And run on</label>
						</div>
						<div className="flex">
							<select defaultValue="WEEKDAYS" className="custom-select__automation text-15" style={{width: 222}}>
								<option value='ANYTIME'>Anytime</option>
								<option value='WEEKDAYS'>Weekdays</option>
								<option value='WEEKENDS'>Weekends</option>
							</select>
						</div>
					</div>
				</div>
				<div className='flex justify-end mt-3'>
					<button
						className="btn btn--primary text-sm mr-2"
						style={{ padding: "5px 14px", borderRadius: 6 }}
					>
						Save
					</button>
				</div>
			</div>
		)
	}
	const InstagramSection = () => {
		return (
			<div className='border-radius-10 bg-white border border-grey-400 p-4'>
				<textarea
					name="tedx"
					rows="6"
					cols="5"
					placeholder="Message..."
					className="text-sm text-grey-900 resize-none w-full border-none outline-none"
				></textarea>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-3'>
						<img src={footerPlusIcon} alt='footerPlusIcon' style={{ width: 22, height: 22 }} className='cursor-pointer' />
						<code
							className="inline-block"
							style={{
								width: 1,
								height: 22,
								backgroundColor: "#E5E5E5",
							}}
						></code>
						<img src={footerSmileyIcon} alt='footerSmileyIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
						<img src={footerGalleryIcon} alt='footerGalleryIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
						<img src={footerAttachmentsIcon} alt='footerAttachmentsIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
						<CustomToolTip position={`${openDropdown ? 'invisible' : 'chat-footer'}`} tooltipText="Insert contact details or question">
							<span className='cursor-pointer bg-color-on-hover bg-color-on-hover--xs' onClick={() => setOpenDropdown(!openDropdown)}>{bracketIcon}</span>
							<div className={`dropdown-menu dropdown-menu--variables ${openDropdown ? 'open' : ''}`}>
								<div className='border-b-2 border-grey-400 pb-2'>
									<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Contact details</h4>
									<div className="dropdown-menu__list">First name</div>
									<div className="dropdown-menu__list">Last name</div>
									<div className="dropdown-menu__list">Email</div>
									<div className="dropdown-menu__list">Phone number</div>
								</div>
								<div className='border-b-2 border-grey-400 pb-2 pt-2'>
									<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Group heading</h4>
									<div className="dropdown-menu__list">Anniversary</div>
									<div className="dropdown-menu__list">Birthday</div>
								</div>
								<div className='pt-2'>
									<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Group heading</h4>
									<div className="dropdown-menu__list">Phone 1</div>
								</div>
							</div>
						</CustomToolTip>
					</div>
					<button
						className="btn btn--primary text-sm"
						style={{ padding: "5px 14px", borderRadius: 6 }}
					>
						Save
					</button>
				</div>
			</div>
		)
	}
	const FacebookSection = () => {
		return (
			<div className='border-radius-10 bg-white border border-grey-400 p-4'>
				<textarea
					name="tedx"
					rows="6"
					cols="5"
					placeholder="Message..."
					className="text-sm text-grey-900 resize-none w-full border-none outline-none"
				></textarea>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-3'>
						<img src={footerPlusIcon} alt='footerPlusIcon' style={{ width: 22, height: 22 }} className='cursor-pointer' />
						<code
							className="inline-block"
							style={{
								width: 1,
								height: 22,
								backgroundColor: "#E5E5E5",
							}}
						></code>
						<img src={footerSmileyIcon} alt='footerSmileyIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
						<img src={footerGalleryIcon} alt='footerGalleryIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
						<img src={footerAttachmentsIcon} alt='footerAttachmentsIcon' style={{ width: 24, height: 24 }} className='cursor-pointer' />
						<CustomToolTip position={`${openDropdown ? 'invisible' : 'chat-footer'}`} tooltipText="Insert contact details or question">
							<span className='cursor-pointer bg-color-on-hover bg-color-on-hover--xs' onClick={() => setOpenDropdown(!openDropdown)}>{bracketIcon}</span>
							<div className={`dropdown-menu dropdown-menu--variables ${openDropdown ? 'open' : ''}`}>
								<div className='border-b-2 border-grey-400 pb-2'>
									<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Contact details</h4>
									<div className="dropdown-menu__list">First name</div>
									<div className="dropdown-menu__list">Last name</div>
									<div className="dropdown-menu__list">Email</div>
									<div className="dropdown-menu__list">Phone number</div>
								</div>
								<div className='border-b-2 border-grey-400 pb-2 pt-2'>
									<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Group heading</h4>
									<div className="dropdown-menu__list">Anniversary</div>
									<div className="dropdown-menu__list">Birthday</div>
								</div>
								<div className='pt-2'>
									<h4 className='text-xs font-medium text-grey-700 mb-1' style={{ marginLeft: 10 }}>Group heading</h4>
									<div className="dropdown-menu__list">Phone 1</div>
								</div>
							</div>
						</CustomToolTip>
					</div>
					<button
						className="btn btn--primary text-sm"
						style={{ padding: "5px 14px", borderRadius: 6 }}
					>
						Save
					</button>
				</div>
			</div>
		)
	}
	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />

				<section className="dashboard__main general-settings">
					<GeneralSettingsHedder text={"General Box Settings"} />
					<div className="dashboard__main__body">
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<BoxSettingsNavLinks activeScreen={4} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay p-10">
							<div>
								<h2 className="text-2xl font-semibold text-center">
									Automate stuff
								</h2>
								<p className="text-grey-800 text-15 text-center mt-3 px-16">
									Automatically send email, Assign enquiries and more...
								</p>
							</div>
							<div className='flex justify-between items-center bg-grey-300 border-radius-14 py-4 pr-5 pl-6 mt-10'>
								<div className='font-semibold'>Automate repetitive things</div>
								<div>
									<button
										className="btn btn--primary text-13 text-white"
										style={{ padding: "5px 14px", borderRadius: 6 }}
										onClick={() => setAddAutomation(!addAutomation)}
									>
										<img src={plusIcon} alt='' style={{ width: 8, height: 8, marginRight: 6 }} />
										Automation
									</button>
								</div>
							</div>
							{addAutomation ?
								<div className='card-box mt-6 pt-5 px-6 pb-6'>
									<div className='flex justify-between'>
										<div className='flex items-center'>
											<img src={AutomateOptionIcon} alt='' style={{ width: 16, height: 21, marginRight: 15 }} />
											{!editName ? <h4 className='font-semibold'>Name your automation</h4> :
												<input className='input-field input-field--sm' type='text' />
											}
											{!editName ? <img src={iconEdit} alt='' style={{width: 14, height: 14}} className='ml-1 cursor-pointer' onClick={() => setEditName(true)}/> :
											<img src={iconClose} alt='' style={{width: 14, height: 14}} className='ml-2 cursor-pointer' onClick={() => setEditName(false)} />}
										</div>
										<button
											className="btn btn--primary text-13 text-white"
											style={{ padding: "5px 14px", borderRadius: 6 }}
											onClick={() => setAddAutomation(!addAutomation)}
										>
											Save
										</button>
									</div>
									<div className={`box-settings__automation-card mt-4 px-5 pt-5 pb-6`}>
										<div className='flex'>
											<img src={iconAddNewAutomation} alt='' style={{ width: 52, height: 50 }} />
											<div className='ml-4'>
												<h4 className='font-semibold'>When...</h4>
												<p className='text-sm text-grey-800'>This automation is started when the following occurs</p>
											</div>
										</div>
										<div className="relative mt-4">
											<input
												className="input-field input-field--md"
												value={selectedStage}
												style={{fontWeight: 400}}
											/>
										</div>
										<div className="relative mt-4" onClick={() => setOpenOptions(!openOptions)}>
											<input
												className="input-field input-field--md input-field--select"
												value={selectedStageOption}
												style={{fontWeight: 400}}
											/>
											<div className={`dropdown-menu px-2 py-4 ${openOptions ? 'open' : ''}`}>
												<div className="">
													<p className="text-xs text-grey-700 font-medium ml-4">
														Select stage
													</p>
												</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => setSelectedStageOption('New enquiry')}>New enquiry</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => setSelectedStageOption('Contacting')}>Contacting</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => setSelectedStageOption('Won')}>Won</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => setSelectedStageOption('Lost')}>Lost</div>
											</div>
										</div>
										{selectedStageOption !== 'Select the stage' && <div className="relative mt-4" onClick={() => setChannelsOptions(!channelsOptions)}>
											<input
												className="input-field input-field--md input-field--select"
												value={selectedChannelsOption}
												style={{ fontWeight: 400 }}
											/>
											<div className={`dropdown-menu px-2 py-4 mt-2 ${channelsOptions ? 'open' : ''}`}>
												<div className={`dropdown-menu__list font-medium justify-between ${selectedChannelsOption === 'Stead' ? 'selected' : ''}`} style={{ padding: "9px 15px" }} onClick={() => setSelectedChannelsOption('Stead')}>
													<div className='flex'>
														<figure className="avatar-container">
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
																<path
																	d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
																	fill="#4C5254"
																/>
															</svg>
														</figure>
														<p className='ml-3'>Stead</p>
													</div>														
												</div>
												<div className={`dropdown-menu__list font-medium justify-between ${selectedChannelsOption === 'Facebook Page' ? 'selected' : ''}`} style={{ padding: "9px 15px" }} onClick={() => setSelectedChannelsOption('Facebook Page')}>
													<div className='flex'>
														<figure className="avatar-container">
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
																<path
																	d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
																	fill="#4C5254"
																/>
															</svg>
														</figure>
														<p className='ml-3'>Facebook Page</p>
													</div>	
												</div>
												<div className={`dropdown-menu__list font-medium justify-between ${selectedChannelsOption === 'Hunley Weddings' ? 'selected' : ''}`} style={{ padding: "9px 15px" }} onClick={() => setSelectedChannelsOption('Hunley Weddings')}>
													<div className='flex'>
														<figure className="avatar-container">
															<svg width="15" height="15" viewBox="0 0 16 16" fill="none">
																<path
																	d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
																	fill="#4C5254"
																/>
															</svg>
														</figure>
														<p className='ml-3'>Hunley Weddings</p>
													</div>												
												</div>
												<div className={`dropdown-menu__list font-medium justify-between ${selectedChannelsOption === 'Instagram' ? 'selected' : ''}`} style={{ padding: "9px 15px" }} onClick={() => setSelectedChannelsOption('Instagram')}>
													<div className='flex'>
														<figure className="avatar-container">
															<svg width="15" height="15" viewBox="0 0 16 16" fill="none">
																<path
																	d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
																	fill="#4C5254"
																/>
															</svg>
														</figure>
														<p className='ml-3'>Instagram</p>
													</div>
												</div>
												<div className={`dropdown-menu__list font-medium justify-between ${selectedChannelsOption === 'Wedding email' ? 'selected' : ''}`} style={{ padding: "9px 15px" }} onClick={() => setSelectedChannelsOption('Wedding email')}>
													<div className='flex'>
														<figure className="avatar-container">
															<svg width="16" height="14" viewBox="0 0 16 14" fill="none">
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M0 3.5V10.5C0 12.433 1.59188 14 3.55556 14H12.4444C14.4081 14 16 12.433 16 10.5V3.5C16 1.567 14.4081 0 12.4444 0H3.55556C1.59188 0 0 1.567 0 3.5ZM3.3009 2.82049C2.93328 2.57031 2.43658 2.67171 2.1915 3.04699C1.94642 3.42228 2.04576 3.92932 2.41338 4.17951L6.9223 7.24808C7.5749 7.69221 8.4251 7.69221 9.0777 7.24808L13.5866 4.17951C13.9543 3.92932 14.0536 3.42228 13.8085 3.04699C13.5634 2.67171 13.0667 2.57031 12.6991 2.82049L8.19018 5.88905C8.07502 5.96743 7.92498 5.96743 7.80982 5.88905L3.3009 2.82049Z"
																	fill="#4C5254"
																/>
															</svg>
														</figure>
														<p className='ml-3'>Wedding email</p>
													</div>													
												</div>
											</div>
										</div>}
										{selectedChannelsOption !== 'Any Channel' &&
											<div className='bg-white border-radius-10 border border-grey-400 py-3 px-4 mt-4'>
												<div className='text-13 font-semibold'>Enquiry is from</div>
												<div className='mt-3 bg-grey-100 rounded-lg p-3'>
													<div className='flex'>
														<figure className="avatar-container">
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
																<path
																	d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
																	fill="#4C5254"
																/>
															</svg>
														</figure>
														<p className='ml-3 font-semibold text-sm'>Facebook Pages</p>
													</div>
												</div>
											</div>}
									</div>
									<div className="box-settings__automation-card mt-6 px-5 pt-5 pb-6 mb-3">
										<div className='flex justify-between'>
											<div className='flex'>
												<img src={iconThenAutomation} alt='' style={{ width: 52, height: 50 }} />
												<div className='ml-4'>
													<h4 className='font-semibold'>Then...</h4>
													<p className='text-sm text-grey-800'>This action will happen automatically</p>
												</div>
											</div>
											<div className="relative mt-3">
												<img src={iconDots} alt='' className='cursor-pointer' style={{ width: 16, height: 4 }} onClick={() => setShowMoreDropdown(1)}/>
												<div className={`dropdown-menu dropdown-menu--more ${showMoreDropdown === 1 ? 'open' : ''}`} style={{top: 10}}>
													<div className="dropdown-menu__list red-text">
														<img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Delete</div>
												</div>
											</div>
										</div>
										<div className="relative mt-4">
											<input
												className="input-field input-field--md"
												value="Send an email"
												style={{ fontWeight: 400 }}
											/>
											<p className='text-primary text-13 font-semibold absolute cursor-pointer' style={{right: 15, bottom: 10}} onClick={() => setOpenFirstThen(!openFirstThen)}>Edit</p>
											<div className={`dropdown-menu px-2 pt-4 pb-2 mt-2 ${openFirstThen ? 'open' : ''}`}>
												<div className="">
													<p className="text-xs text-grey-700 font-medium ml-4 mb-1">
														Select actions
													</p>
												</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} >Send an email</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} >Send an sms</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} >Select the box to move to</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} >Assign to team member</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} >Add a delay timer</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} >Send an instagram DM</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} >Send a Facebook Message</div>
											</div>
										</div>
									</div>
									<div className="box-settings__automation-card mt-6 px-5 pt-5 pb-6 mb-3">
										<div className='flex justify-between'>
											<div className='flex'>
												<img src={iconThenAutomation} alt='' style={{ width: 52, height: 50 }} />
												<div className='ml-4'>
													<h4 className='font-semibold'>Then...</h4>
													<p className='text-sm text-grey-800'>This action will happen automatically</p>
												</div>
											</div>
											<div className="relative mt-3">
												<img src={iconDots} alt='' className='cursor-pointer' style={{ width: 16, height: 4 }} onClick={() => setShowMoreDropdown(2)}/>
												<div className={`dropdown-menu dropdown-menu--more ${showMoreDropdown === 2 ? 'open' : ''}`} style={{top: 10}}>
													<div className="dropdown-menu__list red-text">
														<img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Delete</div>
												</div>
											</div>
										</div>
										{selectedOption === '' ? <div className="relative mt-4">
											<div onClick={() => setOpenThen(!openThen)}>
												<input
													className="input-field input-field--md input-field--select"
													value={selectedValue}
													style={{fontWeight: 400}}
												/>
											</div>
											<div className={`dropdown-menu px-2 pt-4 pb-2 mt-2 ${openThen ? 'open' : ''}`}>
												<div className="">
													<p className="text-xs text-grey-700 font-medium ml-4 mb-1">
														Select actions
													</p>
												</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => { setSelectedOption('sendEmail'); setSelectedValue('Send an email') }}>Send an email</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => { setSelectedOption('sendSms'); setSelectedValue('Send an sms') }}>Send an sms</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => { setSelectedOption('moveTo'); setSelectedValue('Select the box to move to') }}>Select the box to move to</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => { setSelectedOption('assignTo'); setSelectedValue('Assign to team member') }}>Assign to team member</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => { setSelectedOption('delaytimer'); setSelectedValue('Add a delay timer') }}>Add a delay timer</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => { setSelectedOption('InstaDm'); setSelectedValue('Send an instagram DM') }}>Send an instagram DM</div>
												<div className="dropdown-menu__list" style={{ padding: "9px 15px" }} onClick={() => { setSelectedOption('FbDm'); setSelectedValue('Send a Facebook Message') }}>Send a Facebook Message</div>
											</div>
										</div> :
											<div className="relative mt-4">
												<div onClick={() => { setSelectedOption(''); setSelectedValue('Select what you would like to do now...') }}>
													<input
														className="input-field input-field--md input-field--select"
														value={selectedValue}
														style={{fontWeight: 400}}
													/>
												</div>
												<div className="dropdown-menu open mt-2">
													{selectedOption === 'sendEmail' && <SendEmailSection />}
													{selectedOption === 'sendSms' && <SendSmsSection />}
													{selectedOption === 'assignTo' && <AssignTeamMemberSection />}
													{selectedOption === 'moveTo' && <MoveToBox />}
													{selectedOption === 'delaytimer' && <DelayTimer />}
													{selectedOption === 'InstaDm' && <InstagramSection />}
													{selectedOption === 'FbDm' && <FacebookSection />}
												</div>
											</div>}
									</div>
									<div className='flex justify-center'>
										<img src={iconAddAutomationRound} alt='' style={{width: 22, height: 22, marginLeft: 1}} className="cursor-pointer"/>
									</div>
								</div> : <>
									{data.map((ele, index) => {
										return (
											<div className='flex justify-between card-box pt-5 pr-5 pb-4 pl-6 mt-6' key={index}>
												<div className='flex items-start'>
													<img src={ele.icon} alt='icon' style={{ marginRight: 15, marginTop: 3, height: 21, width: 16 }} />
													<div>
														<div className='font-semibold'>{ele.title}</div>
														<div className='text-grey-800 text-sm mt-1'>{ele.desc}</div>
													</div>
												</div>
												<div className="toggle-slider">
													<input
														type="checkbox"
														id={ele.id}
														className="toggle-slider__checkbox"
														name="checkedA"
													/>
													<label
														htmlFor={ele.id}
														className="toggle-slider__label"
													>
														<code className="toggle-slider__ball toggle-slider__ball__small"></code>
													</label>
												</div>
											</div>
										)
									})}
								</>
							}
						</div>
					</div>
				</section>
			</section>
		</main>

	)
}

export default BoxSettingsAutomateStuff