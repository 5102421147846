import React from "react";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";
import iconEdit from "../assets/icons/icon-edit-white.svg";
import iconDelete from "../assets/icons/icon-delete-white.svg";

const BoxSettingsBoringStuff = () => {
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"General Box Settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <BoxSettingsNavLinks activeScreen={8} />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay  p-10">
              <h2 className="text-2xl font-semibold text-center">
                Boring stuff
              </h2>

              <div className="card-box mt-10 px-6 py-5">
                <div className="flex items-center justify-between">
                  <h3 className="font-semibold">Rename Box</h3>
                  <div className="flex items-center gap-2">
                    <button
                      className="btn btn--primary btn--sm text-13"
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 6,
                        paddingBottom: 6,
                      }}
                    >
                      <img src={iconEdit} alt="edit-icon" className="mr-2" />
                      Rename
                    </button>
                    <button
                      className="btn btn--primary btn--sm text-13"
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 6,
                        paddingBottom: 6,
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder=""
                  className="input-field input-field--md mt-5"
                />
              </div>
              <div className="card-box mt-5 px-6 py-5">
                <div className="flex items-center justify-between">
                  <h3 className="font-semibold">Delete Box</h3>
                  <button
                    className="btn btn--error btn--sm text-13"
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 6,
                      paddingBottom: 6,
                    }}
                  >
                    <img src={iconDelete} alt="edit-icon" className="mr-2" />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default BoxSettingsBoringStuff;
