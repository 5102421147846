import React, { useState } from "react";
import iconDelete from "../assets/icons/bin.svg";
import iconPlusGreen from "../assets/icons/icon-plus-green.svg";
import CustomToolTip from "./Common/CustomToolTip";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";

const BoxSettingsBoxForm = () => {
	const [showRenameInput, setShowRenameInput] = useState(false);

	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />

				<section className="dashboard__main general-settings">
					<GeneralSettingsHedder text={"General Box Settings"} />
					<div className="dashboard__main__body">
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<BoxSettingsNavLinks activeScreen={5} />
						</div>
						<div className="dashboard__main__body__section-right overflow-overlay p-10">
							<div>
								<h2 className="text-2xl font-semibold text-center">Box form</h2>
								<p className="text-grey-800 text-15 text-center mt-3 px-16">
									Create the perfect form to add enquiries to your box
								</p>
							</div>
							<div
								className="border-radius-14 bg-grey-300 mt-10"
								style={{ padding: "35px 60px" }}
							>
								<div className="flex">
									<div className="w-full mr-5">
										<label className="input-field-label">First name</label>
										<input
											className="input-field input-field--type2"
											placeholder="First name"
										/>
									</div>
									<div className="w-full">
										<label className="input-field-label">Last name</label>
										<input
											className="input-field input-field--type2"
											placeholder="Last name"
										/>
									</div>
								</div>
								<div className="rounded-xl mt-4 bg-color-on-hover active pt-2 pb-4">
									<div className="w-full">
										<label className="input-field-label">Email</label>
										<div className="flex items-center">
											<input
												className="input-field input-field--type2"
												placeholder="Email"
												style={{ marginRight: 6 }}
											/>
											<CustomToolTip
												position="left--delete-icon"
												tooltipText="You can't delete the field. The system needs to have at least one contact."
											>
												<img
													src={iconDelete}
													alt=""
													className="cursor-pointer"
												/>
											</CustomToolTip>
										</div>
									</div>
								</div>
								<div className="mt-2">
									<div className="w-full">
										<label className="input-field-label">Phone</label>
										<input
											className="input-field input-field--type2"
											placeholder="Phone"
										/>
									</div>
								</div>
								<div className="flex mt-4 items-center">
									<img
										src={iconPlusGreen}
										alt=""
										style={{ marginRight: 8, width: 10, height: 10 }}
									/>
									<div className="text-sm font-semibold text-primary">
										Add Question
									</div>
								</div>
								<div className="flex mt-6 items-center justify-between">
									<div className="text-15 font-semibold">Note</div>
									<div className="toggle-slider">
										<input
											type="checkbox"
											id="notes"
											className="toggle-slider__checkbox"
											name="notes"
										/>
										<label htmlFor="notes" className="toggle-slider__label">
											<code className="toggle-slider__ball toggle-slider__ball__small"></code>
										</label>
									</div>
								</div>
							</div>
							<div className="card-box mt-10 px-6 pt-5 pb-6">
								<h2 className="font-semibold text-lg">Enquiry form page</h2>
								<p className="text-15 text-grey-800 mt-1">
									Add enquiries to this box without having to login.
								</p>
								<div className="mt-5 relative">
									<input
										className="input-field input-field--sm-38"
										placeholder="folders02/1axsRtXwQ_H6JOxbYzQPqPeXDrEshsjjlqiu54_jiql68TdEJYlhj..."
										style={{
											fontWeight: 400,
											color: "#4C5254",
											paddingRight: 80,
										}}
									/>
									<button className="btn btn--primary btn--sm btn--copy btn--copy--sm">
										Copy
									</button>
								</div>
							</div>
							<div className="card-box mt-6 px-6 pt-5 pb-6">
								<h2 className="font-semibold text-lg">
									Change the button text
								</h2>
								<p className="text-15 text-grey-800 mt-1">
									Customize the word used on your add enquiry button
								</p>
								<div className="flex mt-5 items-center">
									<button className="btn btn--default text-primary text-13 mr-2">
										<img
											src={iconPlusGreen}
											alt=""
											style={{ marginRight: 6 }}
										/>
										Enquiry
									</button>
									<div
										className="text-primary text-xs font-semibold cursor-pointer"
										onClick={() => setShowRenameInput(!showRenameInput)}
									>
										Change
									</div>
								</div>
								{showRenameInput && (
									<div className="flex mt-5 items-center">
										<input
											className="input-field input-field--md mr-3"
											style={{ width: 240 }}
											placeholder=""
										/>
										<p className="text-grey-800 text-xs">
											Maximum 10 characters
										</p>
									</div>
								)}
							</div>
							<div className="flex justify-end" style={{ marginTop: 60 }}>
								<button className="btn btn--primary btn--md min-w-120">
									Save
								</button>
							</div>
						</div>
					</div>
				</section>
			</section>
		</main>

	);
};

export default BoxSettingsBoxForm;
