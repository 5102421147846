import React from "react";
import { Link } from "react-router-dom";

const BoxTemplateHeader = ({
  title = "",
  HeaderIcon = () => <></>,
  isPreview = false,
	setModalShow 
}) => {
  return (
    <div
      className="dashboard__main__header"
      style={{ padding: "20px 25px 20px 40px" }}
    >
      <div className="flex items-center dashboard__main__header__section-left">
        <HeaderIcon />
        <div className="ml-5">
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
      </div>
      <div className="flex items-center dashboard__main__header__section-right mr-8">
        {!isPreview ? (
          <div
            className="bg-blue-700 border-radius-20 flex items-center cursor-pointer"
            style={{ padding: "6px 14px 6px 6px" }}
						onClick={() => setModalShow(true)}
          >
            <figure
              className="bg-white flex justify-center items-center"
              style={{ width: 24, height: 24, borderRadius: "50%" }}
            >
              <svg
                width="7"
                height="8"
                viewBox="0 0 7 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.599121 6.90995V1.02003C0.599121 0.635632 1.01482 0.395008 1.34815 0.58646L6.1337 3.33509C6.45695 3.52075 6.47068 3.9821 6.15905 4.18665L1.3735 7.32794C1.041 7.5462 0.599121 7.30768 0.599121 6.90995Z"
                  fill="#526BF0"
                />
              </svg>
            </figure>
            <span className="text-white text-13 font-semibold ml-2">
              What are boxes?
            </span>
          </div>
        ) : (
          <div className="flex gap-3">
            <button
              className="btn btn--primary text-13"
              style={{ padding: "5px 14px", fontWeight: 500 }}
            >
              Use this template
            </button>
            <Link className="btn border border-grey-400 rounded-md text-primary gap-2 text-13"
              style={{ padding: "5px 14px", fontWeight: 500 }}
							to="/box-template"
						>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9L9 1"
                  stroke="#005C6F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M1 1L9 9"
                  stroke="#005C6F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
              Close preview
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoxTemplateHeader;
