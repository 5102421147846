import React, { useState } from "react";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import iconEdit from "../assets/icons/icon-edit.svg";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic2 from "../assets/images/avatar-pic2.png";
import ChannelHeader from "./Common/ChannelHeader";
import fileIcon from "../assets/icons/icon-file-tab.svg";
import historyIcon from "../assets/icons/icon-history-grey.svg";
import iconContactGreen from "../assets/icons/icon-contact-green.svg";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import iconUnion from "../assets/icons/Union-model-user.svg";
import AddSingleContact from "./AddSingleContact";
import AddSingleContactWarningMessage from "./ModalAddSingleContactWarningMessage";
import { useLocation } from "react-router-dom";
import BoxSettingsUploadContact from './BoxSettingsUploadContact'
import {
	NavLink,
	useRouteMatch,
	Route,
	Redirect,
	Switch,
} from "react-router-dom";
import ModalBoxSettingsUploading from "./modal/ModalBoxSettingsUploading";
import MatchingFieldsModal from "./modal/MatchingFieldsModal";
import BoxSettingsUploadedModal from "./modal/BoxSettingsUploaded";

const users = [
	{
		id: 1,
		name: "John Leon",
		image: avatarPic,
		active: false,
	},
	{
		id: 2,
		name: "Madison Inouye",
		image: avatarPic,
		active: false,
	},
	{
		id: 7,
		name: "John Leon",
		image: avatarPic,
		active: false,
	},
	{
		id: 3,
		name: "Olivia Rhye",
		image: avatarPic,
		active: false,
	},
	{
		id: 6,
		name: "Alex Foster",
		image: avatarPic,
		active: true,
	},
	{
		id: 4,
		name: "Charles Wundeng",
		image: avatarPic,
		active: false,
	},
	{
		id: 5,
		name: "Lisa Fotios",
		image: avatarPic,
		active: false,
	},
];

const modalUpload = "modal-upload-multi-contact-setup1";
const modalWarning = "modal-warning";


const ModalUploadMultiContactSetup1 = ({showUploadContactModal, setShowUploadContactModal}) => {
	const { path } = useRouteMatch();
	const [openUpload, setOpenUpload] = useState(false);
	const [openUploadingModal,setOpenUploadingModal] = useState(false);
	const [matchingFieldsModal, setMatchingFieldsModal] = useState(false);
	const [showUploadedModal, setShowUploadedModal] = useState(false);
	const [modalShow, hideModalShow] = useState({
		contactSetup: true,
		addSingelContact: false,
		addMultiContact: false
	});
	const [state, setState] = React.useState({
		checkedA: true,
		checkedB: false,
	});
	const location = useLocation();
	const checkUrl = location && location.pathname;

	const handleChange = (event) => {
		event === "checkedA" && !state.checkedA
			? setState({ checkedA: true, checkedB: !state.checkedB })
			: event === "checkedB" &&
			!state.checkedB &&
			setState({ checkedB: true, checkedA: !state.checkedA });
	};
	const handleSubmit = () => {
		if(state.checkedA){
			setOpenUpload(true)
			hideModalShow({...modalShow, addMultiContact: !modalShow.addMultiContact})
		}
		if(state.checkedB){
			hideModalShow({...modalShow, addSingelContact: !modalShow.addSingelContact})
		}
		setShowUploadContactModal(false)
	}

	return (
		<>
			{modalShow.contactSetup && !checkUrl.includes(modalWarning) && (
				<div className={`modal modal--upload-contact ${showUploadContactModal ? 'show':''}`}>
					<div className="modal__container">
						<div className="modal__header">
							<h2 className="modal__heading pr-5">Upload contact</h2>
							<div
								className="modal__close-icon"
								onClick={() =>{
									setShowUploadContactModal(false);
									// hideModalShow({
									// 	addSingelContact: modalShow.addSingelContact,
									// 	contactSetup: !modalShow.contactSetup,
									// })
								}				
								}
							>
								<img src={modalCloseIcon} alt="" />
							</div>
						</div>
						<div className="modal__body px-8 py-12 pb-10">
							<ul className="add-contacts-list-wrapper">
								<li
									className="add-contacts-list"
									onClick={() => handleChange("checkedA")}
									style={
										state.checkedA
											? {
												boxShadow:
													"0px 0px 13px -2px rgba(85, 83, 80, 0.1), 0px 10px 12px -2px rgba(85, 83, 80, 0.12)",
												border: "1px solid #0491AB",
											}
											: {}
									}
								>
									<div className="text-center">
										<svg
											className="check-icon"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											style={{
												visibility: state.checkedA ? "visible" : "hidden",
											}}
										>
											<rect width="20" height="20" rx="10" fill="#005C6F" />
											<path
												d="M13.7038 7.77783L8.6112 12.8704L6.29639 10.5556"
												stroke="white"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
										<div
											className="inline-flex items-center justify-center bg-grey-90 rounded-full"
											style={{ width: 70, height: 70 }}
										>
											<img alt="" src={iconUnion}></img>
										</div>
										<h3 className="text-base font-semibold text-center mt-4 text-grey-900">
											Add Multiple contacts
										</h3>
									</div>
								</li>
								<li
									className="add-contacts-list"
									onClick={() => handleChange("checkedB")}
									style={
										state.checkedB
											? {
												boxShadow:
													"0px 0px 13px -2px rgba(85, 83, 80, 0.1), 0px 10px 12px -2px rgba(85, 83, 80, 0.12)",
												border: "1px solid #0491AB",
											}
											: {}
									}
								>
									<div className="text-center">
										<svg
											className="check-icon"
											width="20"
											height="20"
											right="18"
											top="12"
											viewBox="0 0 20 20"
											fill="none"
											style={{
												visibility: state.checkedB ? "visible" : "hidden",
											}}
										>
											<rect width="20" height="20" rx="10" fill="#005C6F" />
											<path
												d="M13.7038 7.77783L8.6112 12.8704L6.29639 10.5556"
												stroke="white"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
										<div
											className="inline-flex items-center justify-center bg-grey-90 rounded-full"
											style={{ width: 70, height: 70 }}
										>
											<svg
												width="20"
												height="20"
												viewBox="0 0 16 16"
												fill="none"
											>
												<path
													d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
													fill="#005C6F"
												/>
											</svg>
										</div>
										<h3 className="text-base font-semibold text-center mt-4 text-grey-900">
											Add Single contact
										</h3>
									</div>
								</li>
							</ul>

							<div className="flex justify-end mt-16">
								<button
									className="btn btn--primary btn--sm min-w-120"
									onClick={handleSubmit
										// () =>
										// hideModalShow({
										// 	addSingelContact: !modalShow.addSingelContact,
										// 	contactSetup: !modalShow.contactSetup,
										// 	addMultiContact: !modalShow.addMultiContact
										// })
									}
								>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			{modalShow.addSingelContact && <AddSingleContact setShowUploadContactModal={setShowUploadContactModal} hideModalShow={hideModalShow} modalShow={modalShow} />}
			<BoxSettingsUploadContact
				openUpload={openUpload}
				setOpenUpload={setOpenUpload}
				setOpenUploadingModal={setOpenUploadingModal} />
			<ModalBoxSettingsUploading
				openUploadingModal={openUploadingModal}
				setOpenUploadingModal={setOpenUploadingModal}
				setMatchingFieldsModal={setMatchingFieldsModal}
			/>
			<MatchingFieldsModal
				matchingFieldsModal={matchingFieldsModal}
				setShowUploadedModal={setShowUploadedModal}
				setMatchingFieldsModal={setMatchingFieldsModal}
			/>
			<BoxSettingsUploadedModal
				showUploadedModal={showUploadedModal}
				setShowUploadedModal={setShowUploadedModal}
			/>
			{/* <Switch>
				<Route
					path={`/${modalUpload}/${modalWarning}`}
					render={() => (
						<AddSingleContactWarningMessage
							hideModalShow={hideModalShow}
							modalShow={modalShow}
						/>
					)}
					exact
				/>
				<Route
					path={path}
					render={() => {
						return <Redirect to={`/${modalUpload}`} />;
					}}
				/>
			</Switch> */}
		</>
	);
};

export default ModalUploadMultiContactSetup1;
