import React, { useState } from "react";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import GlobelNavLinks from "./Common/GlobelNavlinks";
import avatarPic from "../assets/images/avatar-pic.png";
import iconPlusWhite from "../assets/icons/icon-plus-white.svg";
import iconDots from "../assets/icons/icon-more-round.svg";
import iconEdit from "../assets/icons/icon-edit.svg";
import createUnion from "../assets/icons/createUnionIcon.svg";
import deleteIcon from "../assets/icons/icon-Delete.svg";
import InviteNewTeamMember from "./InviteNewTeamMember";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import CustomToolTip from "./Common/CustomToolTip";
import Tab from "./Tabs";
import iconEmail from "../assets/icons/Icon-Email-22.svg";
import iconFb from "../assets/icons/Icon-fb-20.svg";
import iconInsta from "../assets/icons/Icon-Instagram-20.svg";
import lockIcon from "../assets/icons/icon-lock.svg";
import iconEmojiFire from "../assets/icons/icon-emoji-fire.svg";
import iconRocket from "../assets/icons/icon-emoji-rocket.svg";
import iconBall from "../assets/icons/icon-emoji-ball.svg";
import iconTTBat from "../assets/icons/icon-tt-bat.svg";
import suitCase from "../assets/icons/suitcase.svg";
import iconPlayButton from "../assets/icons/icon-play-btn.svg";
import iconPlus from "../assets/icons/icon-plus-primary-color.svg";
import iconCheckCircle from "../assets/icons/icon-border-check-circle.svg";

const mydata = [
  {
    title: "Merva Sahin",
    desc: "Team Admin",
    icon: avatarPic,
  },
  {
    title: "John Leon",
    desc: "Team Admin",
    icon: avatarPic,
  },
  {
    title: "Madison Inouye",
    desc: "Team Admin",
    icon: avatarPic,
  },
  {
    title: "Alex Foster",
    desc: "Team Admin",
    icon: avatarPic,
  },
  {
    title: "Brad Dani",
    desc: "Team Admin",
    icon: avatarPic,
  },
  {
    title: "Lisa Fotios",
    desc: "Team Admin",
    icon: avatarPic,
  },
];

const channeldata = [
  {
    name: "Stead",
    profilePic: iconFb,
    icon: lockIcon,
  },
  {
    name: "Facebook Pages",
    profilePic: iconFb,
  },
  {
    name: "Hunley Weddings",
    profilePic: iconInsta,
  },
  {
    name: "Instagram",
    profilePic: iconInsta,
    icon: lockIcon,
  },
  {
    name: "Wedding email",
    profilePic: iconEmail,
  },
];
const boxesdata = [
  {
    img: suitCase,
    title: "Sales",
    icon: lockIcon,
  },
  {
    img: iconTTBat,
    title: "General",
  },
  {
    img: iconEmojiFire,
    title: "Potential Leads",
    icon: lockIcon,
  },
  {
    img: iconRocket,
    title: "Marketing Leads",
  },
  {
    img: iconBall,
    title: "Product Enquiries",
  },
];

const reassignEnquiriesData = [
  {
    img: avatarPic,
    title: "Merva Sahin",
    subTitle: "Team Admin",
  },
  {
    img: avatarPic,
    title: "John Leon",
    subTitle: "Team Member",
  },
  {
    img: avatarPic,
    title: "Madison Inouye",
    subTitle: "Team Member",
  },
  {
    img: avatarPic,
    title: "Alex Foster",
    subTitle: "Team Member",
  },
  {
    img: avatarPic,
    title: "Lisa Fotios",
    subTitle: "Team Member",
  },
  {
    img: avatarPic,
    title: "Charles Wundeng",
    subTitle: "Team Member",
  },
  {
    img: avatarPic,
    title: "Brad Dani",
    subTitle: "Team Member",
  },
];

const GlobalSettingsTeamMembers = () => {
  const [activeScreen, setActiveScreen] = useState(0);
  const [dropdownIndex, setDropDownIndex] = useState();
  const [modalShow, hideModalShow] = useState(false);
  const [doneShow, hideDoneShow] = useState(false);
  const [editAccessShow, hideEditAccessShow] = useState(false);
  const [createSharedLogin, hideCreateSharedLogin] = useState(false);
  const [reassignEnquiries, hideReassignEnquiries] = useState(false);
  const [tabsView, setTabsView] = useState(false);
  const data = [
    {
      id: 1,
      title: "Team member",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: 2,
      title: "Account admin",
      desc: "The Box will be accessible to team members who have been.",
    },
  ];
  const headerText = "Select the user type";
  const openDropdown = (index) => {
    setDropDownIndex(index);
  };
  const [dropdownOpen, setDropDownOpen] = useState(false);

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />
        <section className="dashboard__main">
          <GeneralSettingsHedder text={"Account settings"} />
          <div
            className="dashboard__main__body"
            style={{ height: "calc(100% - 79px)" }}
          >
            <div className="dashboard__main__body__section-left overflow-overlay">
              <GlobelNavLinks
                setActiveScreen={setActiveScreen}
                activeScreen={1}
              />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay p-10">
              {/* {activeScreen === 1 && <TeamAccess data={users} />} */}
              <div className="text-center">
                <h2 className="text-2xl font-semibold">Team members</h2>
                <p className="text-grey-800 text-base-1 mt-3">
                  Invite new, manage access
                </p>
              </div>

              <div className="flex items-center justify-between mt-6">
                <h3 className="text-lg font-semibold">Team members</h3>
                <button
                  className="btn btn--primary btn--xs text-13"
                  onClick={() => hideModalShow(true)}
                >
                  <img src={iconPlusWhite} alt="icon-plus" className="mr-2" />
                  Add a team member
                </button>
              </div>
              <ul className="mt-4">
                {mydata.map((item, index) => (
                  <li
                    className="flex items-center py-2 px-3 hover-bg-grey"
                    style={{ marginTop: 2 }}
                  >
                    <div className="flex items-center">
                      <figure
                        className="avatar-container avatar-container--full bg-white"
                        style={{
                          width: 42,
                          height: 42,
                          "box-shadow":
                            "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <img src={item.icon} className="" />
                      </figure>
                      <div className="ml-4 truncate pr-5">
                        <h3 className="font-semibold truncate">{item.title}</h3>
                        <p className="text-sm text-grey-800 truncate">
                          {item.desc}
                        </p>
                      </div>
                    </div>
                    <div className="relative ml-auto">
                      <img
                        src={iconDots}
                        alt="icon-dots"
                        className="p-1 cursor-pointer"
                        onClick={() => openDropdown(index)}
                      />
                      <div
                        className={`dropdown-menu dropdown-menu--sm p-2 ${
                          dropdownIndex === index && "open"
                        }`}
                        style={{
                          width: 190,
                          left: "initial",
                          right: 0,
                          top: 15,
                        }}
                      >
                        <CustomToolTip
                          position="top"
                          tooltipStyle={{
                            display: "flex",
                            bottom: "45px",
                            marginLeft: "-132px",
                            width: "260px",
                            padding: "8px",
                          }}
                          tooltipText="To delete a team member, you must first reassign all enquiries that are assigned 
                                                        to them to another team member"
                        >
                          <div
                            className="dropdown-menu__list items-center"
                            onClick={() => hideReassignEnquiries(true)}
                            style={{ color: "#F04438", width: "170px" }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-2"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.8 5.29167L12.5884 11.787C12.5396 13.2853 11.2862 14.5 9.7997 14.5H6.2003C4.70896 14.5 3.46078 13.2962 3.41161 11.787L3.2 5.29167H12.8ZM9.5 7.35C9.19875 7.35 8.94936 7.59051 8.90651 7.90395L8.9 8V11.25L8.90785 11.3554C8.95435 11.6643 9.20177 11.9 9.5 11.9C9.80125 11.9 10.0506 11.6595 10.0935 11.3461L10.1 11.25V8L10.0921 7.89457C10.0456 7.58572 9.79823 7.35 9.5 7.35ZM6.5 7.35C6.19875 7.35 5.94936 7.59051 5.90651 7.90395L5.9 8V11.25L5.90785 11.3554C5.95435 11.6643 6.20177 11.9 6.5 11.9C6.80125 11.9 7.05064 11.6595 7.09349 11.3461L7.1 11.25V8L7.09215 7.89457C7.04565 7.58572 6.79823 7.35 6.5 7.35ZM8.68285 1.5C9.17071 1.5 9.6037 1.81253 9.75733 2.27557C9.91097 2.7386 10.344 3.05114 10.8318 3.05114H13.1875C13.6362 3.05114 14 3.4149 14 3.86364C14 4.31237 13.6362 4.67614 13.1875 4.67614H2.8125C2.36377 4.67614 2 4.31237 2 3.86364C2 3.4149 2.36377 3.05114 2.8125 3.05114H5.20202C5.64523 3.05114 6.04133 2.78718 6.21547 2.38776L6.25733 2.27557C6.40171 1.81413 6.82915 1.5 7.31265 1.5H8.68285Z"
                                fill="#F04438"
                              />
                            </svg>
                            Delete User
                          </div>
                        </CustomToolTip>
                        <div
                          className="dropdown-menu__list items-center"
                          onClick={() => hideEditAccessShow(true)}
                        >
                          <img
                            src={iconEdit}
                            alt="icon-edit"
                            className="mr-2"
                          />
                          Edit access
                        </div>
                        <div
                          className="dropdown-menu__list items-center"
                          onClick={() => hideCreateSharedLogin(true)}
                        >
                          <img
                            src={createUnion}
                            alt="icon-edit"
                            className="mr-2"
                          />
                          Create shared login
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </section>
      <>
        {modalShow && (
          <div className="modal show">
            <div className="modal__container">
              <div className="modal__header">
                <h2 className="modal__heading pr-5">Add a team member</h2>
                <div
                  className="modal__close-icon"
                  onClick={() => hideModalShow(false)}
                >
                  <img src={modalCloseIcon} alt="" />
                </div>
              </div>

              <div className="modal__body px-8 ">
                <div className="mt-6 ">
                  <div className="">
                    <label
                      className="input-field-label"
                      style={{ fontSize: 14 }}
                    >
                      Email
                    </label>
                    <input
                      className="input-field input-field--md"
                      placeholder="Type email address"
                    />
                    <div className="text-13 font-medium text-grey-800 mt-8 bg-grey-200 rounded-xl p-f-10">
                      New team members will have access to this channel and all
                      open channels and boxes when they accept the invitation
                      and join.
                    </div>
                  </div>
                  <InviteNewTeamMember data={data} headerText={headerText} />
                  <button
                    className="btn btn--primary btn--md mb-5 mt-10 ml-auto min-w-120"
                    onClick={() => hideDoneShow(true) || hideModalShow(false)}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      <>
        {doneShow && (
          <div className="modal show">
            <div className="modal__container">
              <div className="modal__header">
                <h2 className="modal__heading pr-5">Add team member</h2>
                <div
                  className="modal__close-icon"
                  onClick={() => hideModalShow(false)}
                >
                  <img src={modalCloseIcon} alt="" />
                </div>
              </div>
              <div className="modal__body px-8 py-12 pb-10">
                <div className="text-center">
                  <div
                    className="inline-flex items-center justify-center border-2 border-green-900 rounded-full"
                    style={{
                      width: 60,
                      height: 60,
                      backgroundColor: "rgba(20, 174, 92, 0.05)",
                    }}
                  >
                    <svg width="20" height="20" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M1 5.37637L4.13194 9L11 1"
                        stroke="#14AE5C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <h4 className="text-lg font-semibold mt-6">
                    Invitation sent
                  </h4>
                  <div
                    className="text-sm font-normal text-grey-800"
                    style={{ padding: "10px 30px" }}
                  >
                    Once they accept and create their account you'll get a
                    notification from your enquiry assistant
                  </div>
                </div>
                <div className="flex justify-end mt-8">
                  <button
                    className="btn btn--primary btn--sm min-w-120"
                    onClick={() => hideDoneShow(false)}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      <>
        {editAccessShow && (
          <div className="modal show">
            <div className="modal__container">
              <div
                className="modal__header"
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  paddingBottom: 0,
                }}
              >
                <div className="flex items-center justify-between w-full">
                  <h2 className="modal__heading pr-5">Edit access</h2>
                  <div
                    className="modal__close-icon"
                    onClick={() => hideEditAccessShow(false)}
                  >
                    <img src={modalCloseIcon} alt="" />
                  </div>
                </div>
                <div className="mt-4">
                  <Tab
                    tabs={[
                      {
                        name: "Boxes",
                        onClick: () => setTabsView(true),
                      },
                      {
                        name: "Channels",
                        onClick: () => setTabsView(false),
                      },
                    ]}
                    active={tabsView ? 0 : 1}
                  />
                </div>
              </div>

              <div className="modal__body px-5">
                {!tabsView ? (
                  <div className="mt-4">
                    <ul>
                      {channeldata.map((item, index) => (
                        <li
                          className="hover-bg-grey hover-bg-grey--br-8 flex items-center justify-between"
                          style={{ marginTop: 2, padding: "10px 12px" }}
                        >
                          <div className="flex gap-2">
                            <figure className="avatar-container avatar-container--contain">
                              <img
                                src={item.profilePic}
                                alt=""
                                className="cursor-pointer"
                                style={{ width: 20, height: 20 }}
                              />
                            </figure>
                            <p className="text-base-1 font-medium text-grey-900">
                              {item.name}
                            </p>
                          </div>
                          <div className="flex">
                            {item.icon && (
                              <div>
                                <img
                                  src={item.icon}
                                  alt="lock-icon"
                                  className="mr-2"
                                  style={{ width: 14, height: 16 }}
                                />
                              </div>
                            )}
                            <div className="flex items-center ml-auto custom-checkbox-wrapper">
                              <input
                                type="checkbox"
                                className="custom-checkbox custom-checkbox--sm mr-2"
                                name=""
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <button
                      className="btn btn--primary text-base-1 btn--md ml-auto min-w-120 my-10"
                      onClick={() => hideEditAccessShow(false)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="mt-4">
                    <ul>
                      {boxesdata.map((item, index) => (
                        <li
                          className="hover-bg-grey hover-bg-grey--br-8 flex items-center justify-between"
                          style={{ padding: "10px 12px", marginTop: 2 }}
                        >
                          <div className="flex gap-2">
                            <figure className="avatar-container avatar-container--contain">
                              <img
                                src={item.img}
                                alt=""
                                className="cursor-pointer"
                                style={{ width: 20, height: 20 }}
                              />
                            </figure>
                            <p className="text-base-1 font-medium text-grey-900">
                              {item.title}
                            </p>
                          </div>
                          <div className="flex">
                            {item.icon && (
                              <div>
                                <img
                                  src={item.icon}
                                  alt="lock-icon"
                                  className="mr-2"
                                  style={{ width: 14, height: 16 }}
                                />
                              </div>
                            )}
                            <div className="flex items-center ml-auto custom-checkbox-wrapper">
                              <input
                                type="checkbox"
                                className="custom-checkbox custom-checkbox--sm mr-2"
                                name=""
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <button
                      className="btn btn--primary btn--md text-base-1 ml-auto min-w-120 my-10"
                      onClick={() => hideEditAccessShow(false)}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
      <>
        {createSharedLogin && (
          <div className="modal show">
            <div className="modal__container">
              <div className="modal__header">
                <h2 className="modal__heading pr-5">Shared login</h2>
                <div
                  className="modal__close-icon"
                  onClick={() => hideCreateSharedLogin(false)}
                >
                  <img src={modalCloseIcon} alt="" />
                </div>
              </div>
              <div className="modal__body py-5 px-8">
                <div
                  className="flex items-center justify-between bg-grey-90"
                  style={{ padding: "10px 16px" }}
                >
                  <p className="text-sm font-semibold pr-4 truncate">
                    Understand shared login in 1 min
                  </p>
                  <div className="flex items-center gap-1 flex-shrink-0">
                    <img src={iconPlayButton} alt="iconPlay" />
                    <span className="text-primary text-13 font-medium">
                      Watch video
                    </span>
                  </div>
                </div>
                <p className="text-13 mt-4">
                  Like your Netflix or Amazon Prime account you can have
                  multiple profiles using one account. We call this a shared
                  login. For example, in your business there might be 3 members
                  of the team using on reception@company.com email address to
                  handle enquiries. In this scenario, you can create 3 profiles
                  who can all login using the same email and password.{" "}
                </p>
                <div className="flex items-center gap-4 mt-6">
                  <figure
                    className="avatar-container avatar-container--full"
                    style={{
                      width: 40,
                      height: 40,
                      "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <img src={avatarPic} alt="avatar-pic" />
                  </figure>
                  <div className="">
                    <h4 className="text-base-1 font-semibold">
                      Reception team
                    </h4>
                    <p
                      className="text-13 text-grey-800"
                      style={{ marginTop: 2 }}
                    >
                      reception@brightsmilesdental.com
                    </p>
                  </div>
                </div>
                <div className="py-3 px-4 bg-grey-300 border-radius-10 mt-4">
                  <h3 className="font-semibold">Shared login profiles</h3>
                  <p className="text-grey-800 text-13 mt-1">
                    <span className="text-primary font-semibold cursor-pointer">
                      Click here to copy
                    </span>{" "}
                    the login link to send to new shared login profiles
                  </p>
                </div>
                <div className="flex items-center justify-between mt-4 hover-bg-grey hover-bg-grey--br-6 p-2">
                  <div className="flex items-center gap-3">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{
                        width: 32,
                        height: 32,
                        "box-shadow":
                          "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <img src={avatarPic} alt="avatar-pic" />
                    </figure>
                    <p className="text-sm font-medium" style={{ marginTop: 2 }}>
                      Reception team
                    </p>
                  </div>
                  <div className="relative p-1 cursor-pointer">
                    <img src={iconDots} alt="icon-dots" />
                  </div>
                </div>
                <div
                  className="flex items-center gap-3 hover-bg-grey hover-bg-grey--br-6 p-2"
                  style={{ marginTop: 2 }}
                >
                  <div
                    className="avatar-container avatar-container--contain bg-white"
                    style={{
                      width: 32,
                      height: 32,
                      "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <img
                      src={iconPlus}
                      alt="icon-plus"
                      style={{ width: 10, height: 10 }}
                    />
                  </div>
                  <p className="text-primary text-sm font-medium">
                    Add another profile
                  </p>
                </div>
                <div className="mt-3">
                  <input
                    type="text"
                    className="input-field input-field--md"
                    placeholder="Type profile name"
                  />
                  <div className="flex items-center justify-end gap-3 mt-4">
                    <button
                      className="btn btn--grey btn--md min-w-100"
                      onClick={() => hideCreateSharedLogin(false)}
                    >
                      Cancel
                    </button>
                    <button className="btn btn--primary btn--md min-w-100">
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center justify-center gap-2 absolute top-5 left-0 right-0 mx-auto px-6 py-4 bg-white border-radius-10"
                style={{
                  width: "max-content",
                  "box-shadow":
                    "0px -2px 4px rgba(85, 83, 80, 0.04), 0px 12px 30px rgba(85, 83, 80, 0.25)",
                }}
              >
                <img
                  src={iconCheckCircle}
                  alt="icon-check-circle"
                  style={{ width: 24, height: 24 }}
                />
                <span className="font-semibold text-grey-900-secondary">
                  Copied
                </span>
              </div>
            </div>
          </div>
        )}
      </>
      <>
        {reassignEnquiries && (
          <div className="modal show">
            <div className="modal__container">
              <div className="modal__header">
                <h2 className="modal__heading pr-5">Reassign enquiries</h2>
                <div
                  className="modal__close-icon"
                  onClick={() => hideReassignEnquiries(false)}
                >
                  <img src={modalCloseIcon} alt="" />
                </div>
              </div>
              <div className="modal__body py-5 px-8">
                <div className="flex items-center gap-2 py-2 px-3 bg-grey-90 rounded-lg">
                  <span
                    className="text-white text-sm font-semibold bg-primary rounded-2xl"
                    style={{ padding: "1px 11px" }}
                  >
                    256
                  </span>
                  <p className="text-13 font-medium">
                    Enquiries will be resassigned. Select the team member below
                  </p>
                </div>
                <ul className="mt-3">
                  {reassignEnquiriesData.map((item, index) => (
                    <li
                      className="flex items-center justify-between hover-bg-grey py-2 px-3"
                      style={{ marginTop: 2 }}
                    >
                      <div className="flex items-center gap-4">
                        <figure className="avatar-container avatar-container--full">
                          <img src={item.img} alt="avatar-pic" />
                        </figure>
                        <div className="">
                          <h4 className="text-base-1 font-semibold">
                            {item.title}
                          </h4>
                          <p className="text-grey-800 text-13">
                            {item.subTitle}
                          </p>
                        </div>
                      </div>
                      <input
                        type="radio"
                        id=""
                        name="team-members"
                        className="custom-radio-button"
                      />
                    </li>
                  ))}
                </ul>
                <div className="flex items-center justify-end gap-3 mt-8">
                  <button className="btn btn--grey btn--md min-w-120" onClick={() => hideReassignEnquiries(false)}>Cancel</button>
                  <button className="btn btn--primary btn--md min-w-120">Done</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </main>
  );
};

export default GlobalSettingsTeamMembers;
