import React, { useState } from "react";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import GlobelNavLinks from "./Common/GlobelNavlinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import footerSmileyIcon from "../assets/icons/icon-footer-smiley.svg";
import footerAttachmentsIcon from "../assets/icons/icon-footer-attachments.svg";
import footerLinkIcon from "../assets/icons/icon-footer-link.svg";
import iconDots from "../assets/icons/icon-more-round.svg";
import iconPlus from "../assets/icons/icon-plus-primary-color.svg";

const GlobalSettingsReplyTemplates = () => {
  const [activeScreen, setActiveScreen] = useState(0);
  const [openDropDrown, setOpenDropDown] = useState(false);
  const [addNewTemplate, setAddNewTemplate] = useState(false);
  const [editHeader, setEditHeader] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [intialValue, setIntialValue] = useState(`Hi [first_name],

	Thanks so much for reaching out! This auto-reply is just to let you know…
	
	We received your email and will get back to you with a (human) response as soon as possible. During [business_hours] that’s usually within a couple of hours. Evenings and weekends may take us a little bit longer.
	
	If you have general questions about [product], check out our [knowledge_base] for walkthroughs and answers to FAQs.
	
	If you have any additional information that you think will help us to assist you, please feel free to reply to this email.
	
	We look forward to chatting soon!
	
	Cheers,
	
	John`);
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />

        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"Account settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <GlobelNavLinks
                setActiveScreen={setActiveScreen}
                activeScreen={7}
              />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay p-10">
              <div className="flex flex-col items-center">
                <h2 className="text-2xl font-semibold">Reply templates</h2>
                <p className="text-grey-800 text-base-1 mt-3">
                  Frequently sent templates for replies
                </p>
              </div>
              {!editTemplate ? (
                <>
                  {!addNewTemplate ? (
                    <>
                      <div className="card-box card-box--br-10 flex items-center justify-between py-5 pl-6 pr-5 mt-6">
                        <h3 className="font-semibold">
                          Thank you for your enquiry
                        </h3>
                        <figure
                          className={`flex items-center cursor-pointer justify-center ${
                            openDropDrown
                              ? "bg-grey-90 rounded-md relative"
                              : ""
                          }`}
                          style={{ width: 26, height: 26 }}
                          onClick={() => setOpenDropDown(!openDropDrown)}
                        >
                          <img src={iconDots} alt="icon-dots" />

                          <div
                            className={`dropdown-menu dropdown-menu--more dropdown-menu--more--lg ${
                              openDropDrown ? "open" : ""
                            }`}
                          >
                            <div
                              className="dropdown-menu__list"
                              onClick={() => setEditTemplate(!editTemplate)}
                            >
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-3"
                              >
                                <path
                                  d="M7.27654 2.1201L9.87987 4.72378L3.74963 10.854C3.69174 10.9119 3.62154 10.956 3.54425 10.983L0.724873 11.9693C0.440795 12.0687 0.129943 11.919 0.0305655 11.6349C-0.0101924 11.5184 -0.0101883 11.3915 0.0305768 11.275L1.01692 8.45599C1.04396 8.37872 1.08806 8.30853 1.14595 8.25064L7.27654 2.1201ZM10.8501 0.379714L11.6204 1.15003C12.0928 1.62252 12.1243 2.369 11.7148 2.87799L11.6202 2.98324L10.461 4.14224L7.85729 1.5389L9.01686 0.379557C9.52317 -0.12658 10.3439 -0.12651 10.8501 0.379714Z"
                                  fill="#2B3639"
                                />
                              </svg>
                              Edit template
                            </div>
                            <div className="dropdown-menu__list red-text">
                              <svg
                                width="12"
                                height="14"
                                viewBox="0 0 12 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-3"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10.8 4.29167L10.5884 10.787C10.5396 12.2853 9.28622 13.5 7.7997 13.5H4.2003C2.70896 13.5 1.46078 12.2962 1.41161 10.787L1.2 4.29167H10.8ZM7.5 6.35C7.19875 6.35 6.94936 6.59051 6.90651 6.90395L6.9 7V10.25L6.90785 10.3554C6.95435 10.6643 7.20177 10.9 7.5 10.9C7.80125 10.9 8.05064 10.6595 8.09349 10.3461L8.1 10.25V7L8.09215 6.89457C8.04565 6.58572 7.79823 6.35 7.5 6.35ZM4.5 6.35C4.19875 6.35 3.94936 6.59051 3.90651 6.90395L3.9 7V10.25L3.90785 10.3554C3.95435 10.6643 4.20177 10.9 4.5 10.9C4.80125 10.9 5.05064 10.6595 5.09349 10.3461L5.1 10.25V7L5.09215 6.89457C5.04565 6.58572 4.79823 6.35 4.5 6.35ZM6.68285 0.5C7.17071 0.5 7.6037 0.812534 7.75733 1.27557C7.91097 1.7386 8.34396 2.05114 8.83181 2.05114H11.1875C11.6362 2.05114 12 2.4149 12 2.86364C12 3.31237 11.6362 3.67614 11.1875 3.67614H0.8125C0.363769 3.67614 0 3.31237 0 2.86364C0 2.4149 0.363769 2.05114 0.8125 2.05114H3.20202C3.64523 2.05114 4.04133 1.78718 4.21547 1.38776L4.25733 1.27557C4.40171 0.814129 4.82915 0.5 5.31265 0.5H6.68285Z"
                                  fill="#F04438"
                                />
                              </svg>
                              Delete template
                            </div>
                          </div>
                        </figure>
                      </div>
                      <div
                        className="flex justify-center items-center py-3 px-2 bg-grey-90 rounded-md mt-8 cursor-pointer"
                        onClick={() => setAddNewTemplate(!addNewTemplate)}
                      >
                        <img
                          src={iconPlus}
                          alt="icon-plus"
                          style={{ width: 10, height: 10 }}
                        />
                        <span className="text-primary ml-2 text-base-1 font-semibold">
                          Create a reply template
                        </span>
                      </div>
                    </>
                  ) : (
                    <div
                      className="border border-secondary border-radius-10 mt-6"
                      style={{
                        "box-shadow":
                          "0px 6px 14px -2px rgba(85, 83, 80, 0.15)",
                      }}
                    >
                      {!editHeader ? (
                        <div
                          className="p-5 cursor-pointer"
                          onClick={() => setEditHeader(!editHeader)}
                        >
                          <h3 className="font-semibold">Reply template 2</h3>
                        </div>
                      ) : (
                        <div className="p-2">
                          <input
                            className="input-field input-field--md"
                            placeholder="Give your reply template a name"
                          />
                        </div>
                      )}
                      <div className="bg-grey-300 p-5 border-b-r-10 border-b-l-10">
                        <div className="bg-white border-radius-10 border border-grey-500">
                          <textarea
                            className="input-field input-field__no-focus resize-none"
                            rows="8"
                            cols="10"
                            style={{
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                          ></textarea>
                          <div className="py-2 px-6 flex">
                            <img
                              src={footerSmileyIcon}
                              alt="footerSmileyIcon"
                              style={{ width: 24, height: 24 }}
                              className="cursor-pointer mr-3"
                            />
                            <img
                              src={footerAttachmentsIcon}
                              alt="footerAttachmentsIcon"
                              style={{ width: 24, height: 24 }}
                              className="cursor-pointer mr-3"
                            />
                            <img
                              src={footerLinkIcon}
                              alt="footerLinkIcon"
                              style={{ width: 24, height: 24 }}
                              className="cursor-pointer mr-3"
                            />
                            <span className="cursor-pointer">Aa</span>
                          </div>
                        </div>
                        <div className="flex justify-end mt-8">
                          <button
                            className="btn btn--grey btn--md min-w-120 mr-3 text-15"
                            onClick={() => setAddNewTemplate(!addNewTemplate)}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn--primary btn--md min-w-120 text-15"
                            onClick={() => setAddNewTemplate(!addNewTemplate)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="border border-secondary border-radius-10 mt-6">
                  <div className="p-5">
                    <h3 className="font-semibold">
                      Thank you for your enquiry
                    </h3>
                  </div>
                  <div className="bg-grey-300 p-5 border-b-r-10 border-b-l-10">
                    <div className="bg-white border-radius-10 border border-grey-500">
                      <textarea
                        className="input-field input-field__no-focus resize-none text-15"
                        rows="8"
                        cols="10"
                        value={intialValue}
                        onChange={(e) => setIntialValue(e.target.value)}
                        style={{
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                          fontWeight: 400,
                        }}
                      ></textarea>
                      <div className="py-2 px-6 flex">
                        <img
                          src={footerSmileyIcon}
                          alt="footerSmileyIcon"
                          style={{ width: 24, height: 24 }}
                          className="cursor-pointer mr-3"
                        />
                        <img
                          src={footerAttachmentsIcon}
                          alt="footerAttachmentsIcon"
                          style={{ width: 24, height: 24 }}
                          className="cursor-pointer mr-3"
                        />
                        <img
                          src={footerLinkIcon}
                          alt="footerLinkIcon"
                          style={{ width: 24, height: 24 }}
                          className="cursor-pointer mr-3"
                        />
                        <span className="cursor-pointer">Aa</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-3 justify-end mt-8">
                      <button
                        className="btn btn--grey btn--md text-base-1 min-w-120"
                        onClick={() => setEditTemplate(!editTemplate)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn--primary btn--md text-base-1 min-w-120"
                        onClick={() => setEditTemplate(!editTemplate)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default GlobalSettingsReplyTemplates;
