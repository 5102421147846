import React from 'react'
import iconVideo from '../../assets/icons/icon-landing-video.svg';

const ToggleEmptyState = ({title, desc}) => {
	return (
    <div className="dashboard__main__body__section-right overflow-overlay p-10">
      <h2 className="text-2xl font-semibold text-center">
        {title}
      </h2>
      <p className="text-center text-sm text-grey-800 mt-4">
        {desc}
      </p>
      <figure className="mt-9">
        <img
          src={iconVideo}
          alt="landing-video"
          style={{ width: 620, height: 420 }}
        />
      </figure>
    </div>
  );
}

export default ToggleEmptyState