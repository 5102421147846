import React, {useState} from 'react'

const SetUpModal = ({ openBoxSetup, setOpenBoxSetup }) => {
	const [openIndustryDropDown,setOpenIndustryDropDown] = useState(false)
	const [selectedIndustry, setSelectedIndustry] = useState('Select Industry');
	const [openTeamSizeDropdown, setOpenTeamSizeDropdown] = useState(false);
	const [selectedTeamSize,setSelectedTeamSize] = useState('Team Size')
	const [websiteUrl, setWebsiteUrl] = useState('Website URL')

	return (
		<div className={`modal modal--CustomBox ${openBoxSetup ? 'show' : ''}`}>
			<div className="modal__container">
				<div className="modal__body text-center" style={{ padding: '45px 50px' }}>
					<h2 className='font-bold text-2xl'>Let’s setup your enquirybox</h2>
					<p className='mt-3'>We’ll use this info to suggest the perfect setup</p>
					<div className="relative mt-6" onClick={() => { setOpenIndustryDropDown(!openIndustryDropDown); setOpenTeamSizeDropdown(false) }}>
						<input
							className="input-field input-field--select input-field--icon-pos-left input-field--icon-industry"
							// placeholder="Select Industry"
							value={selectedIndustry}
							style={{ fontWeight: 400 }}
						/>
						<div className={`dropdown-menu ${openIndustryDropDown ? 'open' : ''}`}>
							<div className="dropdown-menu__list">Dental</div>
							<div className="dropdown-menu__list">Golf</div>
							<div className="dropdown-menu__list">Leisure</div>
							<div className="dropdown-menu__list">Hotel</div>
							<div className="dropdown-menu__list">Wedding</div>
							<div className="dropdown-menu__list">Cosmetic & Aesthetic</div>
							<div className="dropdown-menu__list">IT Services</div>
							<div className="dropdown-menu__list selected">Other</div>
						</div>
					</div>
					<div className="relative mt-3" onClick={() => { setOpenTeamSizeDropdown(!openTeamSizeDropdown); setOpenIndustryDropDown(false) }}>
						<input
							className="input-field input-field--select input-field--icon-pos-left input-field--icon-user-group"
							value={selectedTeamSize}
							style={{ fontWeight: 400 }}
						/>
						<div className={`dropdown-menu ${openTeamSizeDropdown ? 'open' : ''}`}>
							<div className="dropdown-menu__list">1-5 Employees</div>
							<div className="dropdown-menu__list">6-10 Employees</div>
							<div className="dropdown-menu__list">11-20 Employees</div>
							<div className="dropdown-menu__list selected">
								Greater than 20 Employees
							</div>
						</div>
					</div>
					<div className="relative mt-3">
						<input
							className="input-field input-field--icon-pos-left input-field--icon-web"
							placeholder="Website URL"
							value={websiteUrl}
							onChange={(e) => setWebsiteUrl(e.target.value)}
						/>
					</div>
					<button className="btn btn--primary w-full mt-12 xxl:mt-12" onClick={() => setOpenBoxSetup(false)}>Take me to my enquirybox</button>
				</div>
			</div>
		</div>
	)
}

export default SetUpModal