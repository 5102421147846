import React, { useState } from "react";
import iconFacebookLead from "../assets/icons/icon-facebook-leads.svg";
import { useHistory } from "react-router-dom";
import ChannelConnectLinks from "./Common/ChannelConnectLinks";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from './Common/GeneralSettingsHedder';
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import SidebarLinksNew from "./Common/SideBarLinksNew";

const SelectChannel = () => {
    let history = useHistory();
    const [showFileUpload, setShowFileUpload] = useState(false);

    const nextScreen = () => {
        history.push("/dashboard-facebook");
    };
    return (
        <main className="dashboard">
            <section className="dashboard__container flex items-start">
                <SidebarLinksNew />
                <SideBarExpandedNew />
                <section className="dashboard__main">
                    <GeneralSettingsHedder text="Channel Settings" />
                    <div className="dashboard__main__body">
                        <div className="dashboard__main__body__section-left">
                            <ChannelConnectLinks
                                show={true} />
                        </div>
                        <div
                            className="dashboard__main__body__section-right layout-1"
                            id="rightSide"
                        >
                            <h2 className="text-2xl font-semibold text-center">
                                Select a Channel
                            </h2>
                            <ul className="mt-5">
                                <li className="modal__channel-list">
                                    <figure className="modal__channel-list__avatar-container modal__channel-list__avatar-container--facebook-page">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0 13.5662C0 5.77115 6.11268 -0.000488281 14 -0.000488281C21.8873 -0.000488281 28 5.77115 28 13.5661C28 21.3611 21.8873 27.1328 14 27.1328C12.5831 27.1328 11.2225 26.9471 9.94651 26.5953C9.69861 26.5277 9.435 26.5476 9.20003 26.6516L6.42257 27.8757C5.69576 28.1937 4.87606 27.6787 4.85072 26.8851L4.77463 24.3975C4.76336 24.0908 4.62539 23.8066 4.39719 23.6011C1.67321 21.167 0 17.6437 0 13.5662ZM9.70643 11.0169L5.59379 17.5342C5.19654 18.159 5.96843 18.8654 6.55713 18.4179L10.9769 15.0663C11.1224 14.9563 11.2996 14.8966 11.482 14.8961C11.6644 14.8956 11.8421 14.9543 11.9882 15.0635L15.2586 17.5146C16.2388 18.2491 17.6416 17.9902 18.2952 16.9546L22.405 10.44C22.8022 9.81534 22.0304 9.10897 21.4417 9.55634L17.0247 12.9051C16.8792 13.0151 16.7019 13.0749 16.5195 13.0754C16.3372 13.0759 16.1595 13.0171 16.0134 12.9079L12.743 10.4569C11.7628 9.72238 10.36 9.98131 9.70643 11.0169Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </figure>
                                    <div className="modal__channel-list__content-wrapper">
                                        <h4 className="font-semibold">Facebook Page</h4>
                                        <p className="text-sm text-grey-800">
                                            Connect a Facebook Page to receive and reply to messages
                                        </p>
                                    </div>
                                    <button className="btn btn--primary absolute right-5 btn--connect" style={{ padding: '4px 18px' }} onClick={() => setShowFileUpload(true)}>Create</button>
                                </li>
                                <li className="modal__channel-list">
                                    <figure className="modal__channel-list__avatar-container modal__channel-list__avatar-container--instagram-account">
                                        <svg width="28" height="28" viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
                                                fill="#ffffff"
                                            />
                                        </svg>
                                    </figure>
                                    <div className="modal__channel-list__content-wrapper">
                                        <h4 className="font-semibold">Instagram</h4>
                                        <p className="text-sm text-grey-800">
                                            Recive and respond to instagram messages.
                                        </p>
                                    </div>
                                    <button className="btn btn--primary absolute right-5 btn--connect" style={{ padding: '4px 18px' }} onClick={() => setShowFileUpload(true)}>Create</button>
                                </li>
                                <li className="modal__channel-list">
                                    <figure className="modal__channel-list__avatar-container modal__channel-list__avatar-container--email">
                                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="60" height="60" rx="14" fill="#25D366" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0632 15.5996C33.8986 15.6013 37.4988 17.0946 40.206 19.8034C42.9131 22.5138 44.4032 26.1141 44.4016 29.9429C44.3983 37.8456 37.9659 44.2763 30.0632 44.2763H30.0567C27.662 44.2763 25.3068 43.676 23.2033 42.5313L15.6016 44.5246L17.636 37.0972C16.3795 34.918 15.72 32.4461 15.7233 29.9314C15.7249 22.0287 22.1573 15.5996 30.0632 15.5996ZM28.9185 25.3626C29.1679 25.8971 29.1169 26.1721 28.8575 26.554C28.5948 26.9404 28.3091 27.2095 28.0652 27.4393C27.4951 27.9764 27.1529 28.2987 27.8651 29.4C28.6277 30.5792 29.5457 31.49 30.7245 32.2532C31.8253 32.9659 32.1474 32.6236 32.6842 32.0531C32.9139 31.809 33.1829 31.5232 33.569 31.2603C33.9507 31.0009 34.2253 30.9498 34.7597 31.1993C35.5533 31.5697 37.4544 32.8718 37.7516 33.7094C37.8893 34.0962 37.7976 34.4832 37.5278 34.9414C36.7336 36.2909 35.5387 36.7752 34.0117 36.6978C28.8409 36.4364 23.6844 31.2858 23.4229 26.1111C23.3456 24.5833 23.8296 23.3875 25.1783 22.5931C25.6363 22.3232 26.0231 22.2315 26.4097 22.369C27.2469 22.6666 28.5483 24.5687 28.9185 25.3626Z" fill="white" />
                                        </svg>
                                    </figure>
                                    <div className="modal__channel-list__content-wrapper">
                                        <h4 className="font-semibold">WhatsApp</h4>
                                        <p className="text-sm text-grey-800">
                                            Receive and respond to WhatsApp messages.
                                        </p>
                                    </div>
                                    <button className="btn btn--primary absolute right-5 btn--connect" style={{ padding: '4px 18px' }} onClick={() => setShowFileUpload(true)}>Create</button>
                                </li>
                                <li className="modal__channel-list">
                                    <figure className="modal__channel-list__avatar-container modal__channel-list__avatar-container--web-forms">
                                        {/* <import
                                                    src={iconWebForm}
                                                    alt="icon-web-form"
                                                    width="28"
                                                    height="25"
                                                    /> */}
                                        <svg
                                            width="28"
                                            height="26"
                                            viewBox="0 0 28 26"
                                            fill="none"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M-0.000976562 5C-0.000976562 2.23858 2.2376 0 4.99902 0H22.999C25.7604 0 27.999 2.23858 27.999 5V5.89486H-0.000976562V5ZM-0.000976562 8.10538V20.0526C-0.000976562 22.8141 2.2376 25.0526 4.99902 25.0526H22.999C25.7604 25.0526 27.999 22.8141 27.999 20.0526V8.10538H-0.000976562ZM6.78793 3.13168C6.78793 3.84383 6.21061 4.42115 5.49846 4.42115C4.7863 4.42115 4.20898 3.84383 4.20898 3.13168C4.20898 2.41952 4.7863 1.8422 5.49846 1.8422C6.21061 1.8422 6.78793 2.41952 6.78793 3.13168ZM10.4725 3.13168C10.4725 3.84383 9.89519 4.42115 9.18303 4.42115C8.47087 4.42115 7.89355 3.84383 7.89355 3.13168C7.89355 2.41952 8.47087 1.8422 9.18303 1.8422C9.89519 1.8422 10.4725 2.41952 10.4725 3.13168ZM12.8676 4.42115C13.5798 4.42115 14.1571 3.84383 14.1571 3.13168C14.1571 2.41952 13.5798 1.8422 12.8676 1.8422C12.1554 1.8422 11.5781 2.41952 11.5781 3.13168C11.5781 3.84383 12.1554 4.42115 12.8676 4.42115ZM4.39453 13.2635C4.39453 12.4351 5.0661 11.7635 5.89453 11.7635H12.5752C13.4037 11.7635 14.0752 12.4351 14.0752 13.2635C14.0752 14.0919 13.4037 14.7635 12.5752 14.7635H5.89453C5.0661 14.7635 4.39453 14.0919 4.39453 13.2635ZM5.89453 16.7737C5.0661 16.7737 4.39453 17.4453 4.39453 18.2737C4.39453 19.1021 5.0661 19.7737 5.89453 19.7737H17.5858C18.4142 19.7737 19.0858 19.1021 19.0858 18.2737C19.0858 17.4453 18.4142 16.7737 17.5858 16.7737H5.89453Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </figure>
                                    <div className="modal__channel-list__content-wrapper">
                                        <h4 className="font-semibold">Webforms</h4>
                                        <p className="text-sm text-grey-800">
                                            Easily connect your existing web forms and manage
                                            submiss...more
                                        </p>
                                    </div>
                                    <button className="btn btn--primary absolute right-5 btn--connect" style={{ padding: '4px 18px' }} onClick={() => setShowFileUpload(true)}>Create</button>
                                </li>

                                <li className="modal__channel-list">
                                    <figure className="modal__channel-list__avatar-container modal__channel-list__avatar-container--facebook-lead">
                                        <img src={iconFacebookLead} alt="" />
                                    </figure>
                                    <div className="modal__channel-list__content-wrapper">
                                        <h4 className="font-semibold">Facebook Lead Ads</h4>
                                        <p className="text-sm text-grey-800">
                                            Collect and respond directly to Facebook Lead Ads
                                        </p>
                                    </div>
                                    <button className="btn btn--primary absolute right-5 btn--connect" style={{ padding: '4px 18px' }} onClick={() => setShowFileUpload(true)}>Create</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* </div> */}
                </section>
            </section>
            {showFileUpload && <div className="modal show">
                <div className="modal__container" >
                    <div className="modal__header">
                        <h2 className="modal__heading pr-5">Create a channel</h2>
                        <div
                            className="modal__close-icon"
                            onClick={() => setShowFileUpload(false)}
                        >
                            <img src={modalCloseIcon} alt="" />
                        </div>
                    </div>
                    <div className="modal__body px-8 pb-10">
                        <div className="mt-10">
                            <label className="input-field-label">Channel name</label>
                            <input
                                className="input-field input-field--md"
                                placeholder="Give it a name that reflects the software it’s coming from"
                            />
                        </div>

                        <div className="flex items-center justify-end gap-3 mt-12">
                            <button className="btn btn--grey btn--sm min-w-120" onClick={() => setShowFileUpload(false)}>Cancel</button>
                            <button className="btn btn--primary btn--sm min-w-120">Next</button>
                        </div>
                    </div>
                </div>
            </div>}
        </main>
    );
};

export default SelectChannel;
