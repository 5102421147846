import React, { useEffect, useState } from "react";
import iconEmojiFire from "../../assets/icons/icon-emoji-fire.svg";
import iconRocket from "../../assets/icons/icon-emoji-rocket.svg";
import EmojiModal from "../modal/EmojiModal";
import SetUpModal from "../modal/SetUpModal";
import iconTeamMemberProfile from "../../assets/icons/icon-team-member-profile.svg";

const SideBarExpandedNew = ({setOpenBoxInfo, setOpenInviteModal}) => {
  const [showPotentialLeadsEmojiModal, setShowPotentialLeadsEmojiModal] =
    useState(false);
  const [showMarketingLeadsEmojiModal, setShowMarketingLeadsEmojiModal] =
    useState(false);
  const [showProductEnquiriesModal, setShowProductEnquiriesEmojiModal] =
    useState(false);
  const [openBoxSetup, setOpenBoxSetup] = useState(false);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const [showBoxDropdown, setShowBoxDropdown] = useState(false);
  const [showGroupDropDown, setShowGroupDropDown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = () => {
  	setShowMoreDropdown(false);
  	setShowBoxDropdown(false);
  	setShowGroupDropDown(false);
  }

  return (
    <div className="dashboard__sidebar__expanded-section">
      <div className="flex justify-between py-3 bg-color-on-hover bg-color-on-hover--bg-grey-100 active">
        <div className="flex items-center">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.5977 0C2.05846 0 0 2.05846 0 4.5977V7.11528V9.0115V9.09196V11.4023C0 13.9415 2.05846 16 4.5977 16H5.86247C6.23269 16.0605 6.61267 16.092 7 16.092H8.99713C9.38446 16.092 9.76444 16.0605 10.1347 16H11.4023C13.9415 16 16 13.9415 16 11.4023V4.5977C16 2.05846 13.9415 0 11.4023 0H4.5977ZM14.1609 7.89522V4.5977C14.1609 3.07416 12.9258 1.83908 11.4023 1.83908H4.5977C3.07416 1.83908 1.83908 3.07416 1.83908 4.5977V7.92969C1.89845 8.53696 2.41047 9.0115 3.03333 9.0115H3.77673C4.75453 9.0115 5.58898 9.7185 5.74957 10.683L5.76778 10.7923C5.88822 11.5157 6.51406 12.046 7.24741 12.046H8.7523C9.48556 12.046 10.1113 11.5159 10.2319 10.7926L10.2502 10.6827C10.4109 9.71832 11.2453 9.0115 12.223 9.0115H12.9638C13.5984 9.0115 14.1179 8.5189 14.1609 7.89522Z"
              fill="#2B3639"
            />
          </svg>
          <h2 className="text-15 font-semibold text-grey-900 ml-3">
            Enquirybox
          </h2>
        </div>
        <div>
          <p className="text-15 font-medium text-grey-600">5</p>
        </div>
      </div>
      <div className="bg-grey-400 mt-4 mb-2" style={{ height: 1 }}></div>
      <div>
        <div className="flex items-center justify-between pt-4 pb-2">
          <h3 className="text-lg font-semibold text-grey-900">Boxes</h3>
          <figure
            className="relative cursor-pointer"
            onClick={() => setShowBoxDropdown(true)}
          >
            <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                r="1.75"
                transform="matrix(1 0 0 -1 1.75 1.75)"
                fill="#636566"
              />
              <circle
                r="1.75"
                transform="matrix(1 0 0 -1 7.75 1.75)"
                fill="#636566"
              />
              <circle
                r="1.75"
                transform="matrix(1 0 0 -1 13.75 1.75)"
                fill="#636566"
              />
            </svg>
            <div
              className={`dropdown-menu dropdown-menu--more dropdown-menu--more--lg-edit ${
                showBoxDropdown ? "open" : ""
              }`}
            >
              <div className="dropdown-menu__list gap-3">
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.8 0C0.358172 0 0 0.358172 0 0.8C0 1.24183 0.358172 1.6 0.8 1.6H11.2C11.6418 1.6 12 1.24183 12 0.8C12 0.358172 11.6418 0 11.2 0H0.8ZM1.6 3.60312C1.6 4.04495 1.24183 4.40312 0.8 4.40312C0.358172 4.40312 0 4.04495 0 3.60312C0 3.1613 0.358172 2.80312 0.8 2.80312C1.24183 2.80312 1.6 3.1613 1.6 3.60312ZM2.4 3.60312C2.4 3.1613 2.75817 2.80312 3.2 2.80312H11.2C11.6418 2.80312 12 3.1613 12 3.60312C12 4.04495 11.6418 4.40312 11.2 4.40312H3.2C2.75817 4.40312 2.4 4.04495 2.4 3.60312ZM1.6 6.39844C1.6 6.84027 1.24183 7.19844 0.8 7.19844C0.358172 7.19844 0 6.84027 0 6.39844C0 5.95661 0.358172 5.59844 0.8 5.59844C1.24183 5.59844 1.6 5.95661 1.6 6.39844ZM2.4 6.39844C2.4 5.95661 2.75817 5.59844 3.2 5.59844H11.2C11.6418 5.59844 12 5.95661 12 6.39844C12 6.84027 11.6418 7.19844 11.2 7.19844H3.2C2.75817 7.19844 2.4 6.84027 2.4 6.39844ZM0.8 10.0016C1.24183 10.0016 1.6 9.64339 1.6 9.20156C1.6 8.75973 1.24183 8.40156 0.8 8.40156C0.358172 8.40156 0 8.75973 0 9.20156C0 9.64339 0.358172 10.0016 0.8 10.0016ZM3.2 8.40156C2.75817 8.40156 2.4 8.75973 2.4 9.20156C2.4 9.64339 2.75817 10.0016 3.2 10.0016H11.2C11.6418 10.0016 12 9.64339 12 9.20156C12 8.75973 11.6418 8.40156 11.2 8.40156H3.2Z"
                    fill="#2B3639"
                  />
                </svg>
                Group box
              </div>
              <div className="dropdown-menu__list gap-3">
                <svg
                  width="14"
                  height="11"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.75021 0C5.36925 0 4.0469 0.596045 2.92994 1.45933C1.80887 2.32579 0.844994 3.49794 0.168734 4.74895L0.168443 4.74949C-0.0561477 5.16577 -0.0561477 5.66881 0.168443 6.08509L0.168819 6.08579C0.710932 7.08807 1.43749 8.03949 2.28141 8.82388L1.5517 9.55359C1.25881 9.84648 1.25881 10.3214 1.5517 10.6142C1.84459 10.9071 2.31947 10.9071 2.61236 10.6142L3.88352 9.34309C3.9063 9.32295 3.92807 9.30123 3.94865 9.27795L10.6156 2.61104C10.634 2.59436 10.6518 2.57663 10.6688 2.55785L11.9463 1.28033C12.2392 0.987437 12.2392 0.512563 11.9463 0.21967C11.6534 -0.0732233 11.1785 -0.0732233 10.8856 0.21967L10.0307 1.07456C9.03764 0.423825 7.9145 0 6.75021 0ZM8.94191 2.16338C8.2125 1.7352 7.46458 1.5 6.75021 1.5C5.80102 1.5 4.79322 1.91502 3.84723 2.64617C2.91839 3.36405 2.09652 4.35196 1.51276 5.41728C1.98853 6.28501 2.62219 7.10129 3.34291 7.76238L4.13349 6.9718C3.5634 5.92427 3.72137 4.5854 4.60742 3.69935C5.49347 2.8133 6.83235 2.65533 7.87986 3.22542L8.94191 2.16338ZM11.5956 3.46775C11.9327 3.2271 12.4011 3.30531 12.6417 3.64244C12.894 3.99578 13.1254 4.3642 13.3305 4.74353L13.3308 4.74415C13.5362 5.12486 13.5538 5.57815 13.3835 5.97127C13.3699 6.01202 13.3526 6.05216 13.3314 6.09125C12.6552 7.34191 11.6913 8.51388 10.5702 9.38024C9.45329 10.2434 8.13096 10.8395 6.75 10.8395C6.72993 10.8395 6.71003 10.8387 6.69036 10.8371C6.40603 10.8335 6.12416 10.8045 5.8466 10.7533C5.43925 10.6782 5.16989 10.2872 5.24498 9.8798C5.32007 9.47245 5.71116 9.2031 6.11851 9.27818C6.33145 9.31743 6.54236 9.3375 6.75031 9.3375C6.76682 9.3375 6.7832 9.33803 6.79945 9.33908C7.73413 9.32497 8.72341 8.91176 9.65301 8.19335C10.5738 7.4818 11.3894 6.50499 11.9722 5.45062C11.977 5.43834 11.9821 5.42613 11.9876 5.41401C11.8193 5.10706 11.6292 4.80579 11.4209 4.51393C11.1802 4.1768 11.2584 3.70841 11.5956 3.46775Z"
                    fill="#2B3639"
                  />
                </svg>
                Hide box
              </div>
            </div>
          </figure>
        </div>
        <div className="mt-3 flex justify-between items-center relative">
          {!isEdit ? (
            <>
              <h3 className="text-13 font-semibold text-grey-800">Business</h3>
              <figure
                className="relative cursor-pointer"
                onClick={() => setShowGroupDropDown(!showGroupDropDown)}
              >
                <svg
                  width="16"
                  height="4"
                  viewBox="0 0 16 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    r="1.75"
                    transform="matrix(1 0 0 -1 1.75 1.75)"
                    fill="#636566"
                  />
                  <circle
                    r="1.75"
                    transform="matrix(1 0 0 -1 7.75 1.75)"
                    fill="#636566"
                  />
                  <circle
                    r="1.75"
                    transform="matrix(1 0 0 -1 13.75 1.75)"
                    fill="#636566"
                  />
                </svg>
                <div
                  className={`dropdown-menu dropdown-menu--more dropdown-menu--more--lg-edit ${
                    showGroupDropDown ? "open" : ""
                  }`}
                >
                  <div
                    className="dropdown-menu__list gap-3"
                    onClick={() => {
                      setIsEdit(true);
                      setShowGroupDropDown(!showGroupDropDown);
                    }}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.27654 2.1201L9.87987 4.72378L3.74963 10.854C3.69174 10.9119 3.62154 10.956 3.54425 10.983L0.724873 11.9693C0.440795 12.0687 0.129943 11.919 0.0305655 11.6349C-0.0101924 11.5184 -0.0101883 11.3915 0.0305768 11.275L1.01692 8.45599C1.04396 8.37872 1.08806 8.30853 1.14595 8.25064L7.27654 2.1201ZM10.8501 0.379714L11.6204 1.15003C12.0928 1.62252 12.1243 2.369 11.7148 2.87799L11.6202 2.98324L10.461 4.14224L7.85729 1.5389L9.01686 0.379557C9.52317 -0.12658 10.3439 -0.12651 10.8501 0.379714Z"
                        fill="#2B3639"
                      />
                    </svg>
                    Edit group name
                  </div>
                  <div className="dropdown-menu__list red-text gap-3">
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.8 4.29167L10.5884 10.787C10.5396 12.2853 9.28622 13.5 7.7997 13.5H4.2003C2.70896 13.5 1.46078 12.2962 1.41161 10.787L1.2 4.29167H10.8ZM7.5 6.35C7.19875 6.35 6.94936 6.59051 6.90651 6.90395L6.9 7V10.25L6.90785 10.3554C6.95435 10.6643 7.20177 10.9 7.5 10.9C7.80125 10.9 8.05064 10.6595 8.09349 10.3461L8.1 10.25V7L8.09215 6.89457C8.04565 6.58572 7.79823 6.35 7.5 6.35ZM4.5 6.35C4.19875 6.35 3.94936 6.59051 3.90651 6.90395L3.9 7V10.25L3.90785 10.3554C3.95435 10.6643 4.20177 10.9 4.5 10.9C4.80125 10.9 5.05064 10.6595 5.09349 10.3461L5.1 10.25V7L5.09215 6.89457C5.04565 6.58572 4.79823 6.35 4.5 6.35ZM6.68285 0.5C7.17071 0.5 7.6037 0.812534 7.75733 1.27557C7.91097 1.7386 8.34396 2.05114 8.83181 2.05114H11.1875C11.6362 2.05114 12 2.4149 12 2.86364C12 3.31237 11.6362 3.67614 11.1875 3.67614H0.8125C0.363769 3.67614 0 3.31237 0 2.86364C0 2.4149 0.363769 2.05114 0.8125 2.05114H3.20202C3.64523 2.05114 4.04133 1.78718 4.21547 1.38776L4.25733 1.27557C4.40171 0.814129 4.82915 0.5 5.31265 0.5H6.68285Z"
                        fill="#F04438"
                      />
                    </svg>
                    Delete group
                  </div>
                </div>
              </figure>
            </>
          ) : (
            <div className="p-4 rounded-xl bg-white absolute z-10 top-0" style={{boxShadow: "0px 6px 10px -2px rgba(85, 83, 80, 0.2)", marginLeft: "-12px", width: "110%"}}>
              <input
                placeholder="Give your group a name"
                className="input-field input-field--md-34"
              />
              <div className="flex items-center justify-end gap-1 mt-3">
                <button
                  className="btn btn--grey btn--sm-34 text-sm"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </button>
                <button className="btn btn--primary btn--sm-34 text-sm">
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
        <ul className="flex flex-col mt-2">
          <li className="dashboard__sidebar__nav-list justify-between">
            <div className="flex">
              <img
                src={iconEmojiFire}
                alt="icon-emoji-fire"
                onClick={() => {
                  setShowProductEnquiriesEmojiModal(false);
                  setShowMarketingLeadsEmojiModal(false);
                  setShowPotentialLeadsEmojiModal(
                    !showPotentialLeadsEmojiModal
                  );
                }}
              />
              <span className="text-base-1 font-medium text-grey-900 ml-3">
                Sales
              </span>
            </div>
            <div>
              <p className="text-15 font-medium text-grey-600">2</p>
            </div>
          </li>
          {showPotentialLeadsEmojiModal && <EmojiModal className="mt-8" />}
          <li className="dashboard__sidebar__nav-list justify-between">
            <div className="flex">
              <img
                src={iconRocket}
                alt="icon-rocket"
                onClick={() => {
                  setShowProductEnquiriesEmojiModal(false);
                  setShowPotentialLeadsEmojiModal(false);
                  setShowMarketingLeadsEmojiModal(
                    !showMarketingLeadsEmojiModal
                  );
                }}
              />
              <span className="text-base-1 font-medium text-grey-900 ml-3">
                General
              </span>
            </div>
            <div>
              <p className="text-15 font-medium text-grey-600">3</p>
            </div>
          </li>
          {showMarketingLeadsEmojiModal && <EmojiModal className="mt-75" />}
        </ul>
        <div className="flex items-center mt-3 cursor-pointer">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 1V11"
              stroke="#005C6F"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M11 6L1 6"
              stroke="#005C6F"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
          <p className="ml-4 text-sm font-medium text-primary" onClick={() => setOpenBoxInfo(true)}>Add a box</p>
        </div>
      </div>
      <div className="bg-grey-400 mb-6 mt-9" style={{ height: 1 }}></div>
      <div>
        <div className="flex items-center justify-between pb-2">
          <h3 className="text-lg font-semibold text-grey-900">Team chat</h3>
        </div>
        <ul className="flex flex-col">
          <li className="dashboard__sidebar__nav-list justify-between">
            <div className="flex items-center">
              <figure
                className="avatar-container avatar-container--contain bg-white"
                style={{
                  width: 20,
                  height: 20,
                }}
              >
                <img
                  src={iconTeamMemberProfile}
                  alt=""
                  style={{ width: 20, height: 20 }}
                />
                <div className="avatar-container__sup-icon--bottom absolute">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4"
                      fill="#35CC71"
                      stroke="#FCFBF8"
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </figure>
              <span className="text-15 font-medium text-grey-900 ml-3">
                Charles Wundeng
              </span>
              <span
                className="text-13 font-medium text-grey-600 ml-2"
                style={{ marginTop: 3 }}
              >
                You
              </span>
            </div>
            <div>
              <p className="text-15 font-medium text-grey-600">2</p>
            </div>
          </li>
        </ul>
        <div className="flex items-center mt-3 cursor-pointer">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 1V11"
              stroke="#005C6F"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M11 6L1 6"
              stroke="#005C6F"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
          <p className="ml-4 text-sm font-medium text-primary" onClick={() => setOpenInviteModal(true)}>
            Add a team member
          </p>
        </div>
      </div>
      <SetUpModal
        openBoxSetup={openBoxSetup}
        setOpenBoxSetup={setOpenBoxSetup}
      />
    </div>
  );
};

export default SideBarExpandedNew;
