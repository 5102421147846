import React, { useState } from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import Tab from "../Tabs";
import avatarPic from "../../assets/images/avatar-pic.png";
import InviteNewTeamMember from "../InviteNewTeamMember";

const InviteModal = ({ addTeamMember, setAddTeamMember, hideTabs }) => {
  const [tabsView, setTabsView] = useState(true);
  const [showSendInvite, setShowSendInvite] = useState(false);
  const userData = [
    {
      id: 1,
      title: "Team member",
      desc: "The Box will be accessible to team members who have been",
    },
    {
      id: 2,
      title: "Account admin",
      desc: "The Box will be accessible to team members who have been.",
    },
  ];
  const headerText = "Select the user type";
  return (
    <div
      className={`modal modal--channels-request ${addTeamMember ? "show" : ""}`}
    >
      <div className="modal__container">
        <div className="modal__header">
          <h2 className="modal__heading pr-5">
            {!hideTabs ? "Invite Team member" : "Invite the team"}
          </h2>
          <div
            className="modal__close-icon"
            onClick={() => setAddTeamMember(false)}
          >
            <img src={modalCloseIcon} alt="" />
          </div>
        </div>
        <div className="modal__body py-5 px-8">
          {!hideTabs ? (
            <div>
              <div className="">
                <Tab
                  tabs={[
                    {
                      name: "Existing team member",
                      onClick: () => setTabsView(true),
                    },
                    {
                      name: "New team member",
                      onClick: () => setTabsView(false),
                    },
                  ]}
                />
              </div>
              {tabsView ? (
                <ul className="mt-4">
                  <li className="teams-profile-list teams-profile-list--sm bg-color-on-hover">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{ width: 32, height: 32 }}
                    >
                      <img src={avatarPic} alt="avatar-pic" />
                    </figure>
                    <div className="ml-3 text-sm font-medium">
                      Charles Wundeng
                    </div>
                    <div className="ml-auto hover-visible">
                      <span className="text-sm font-semibold text-primary">
                        Invite
                      </span>
                    </div>
                  </li>
                  <li className="teams-profile-list teams-profile-list--sm bg-color-on-hover">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{ width: 32, height: 32 }}
                    >
                      <img src={avatarPic} alt="avatar-pic" />
                    </figure>
                    <div className="ml-3 text-sm font-medium">
                      Charles Wundeng
                    </div>
                    <div className="ml-auto hover-visible">
                      <span className="text-sm font-semibold text-primary">
                        Invite
                      </span>
                    </div>
                  </li>
                  <li className="teams-profile-list teams-profile-list--sm bg-color-on-hover">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{ width: 32, height: 32 }}
                    >
                      <img src={avatarPic} alt="avatar-pic" />
                    </figure>
                    <div className="ml-3 text-sm font-medium">
                      Charles Wundeng
                    </div>
                    <div className="ml-auto hover-visible">
                      <span className="text-sm font-semibold text-primary">
                        Invite
                      </span>
                    </div>
                  </li>
                  <li className="teams-profile-list teams-profile-list--sm bg-color-on-hover">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{ width: 32, height: 32 }}
                    >
                      <img src={avatarPic} alt="avatar-pic" />
                    </figure>
                    <div className="ml-3 text-sm font-medium">
                      Charles Wundeng
                    </div>
                    <div className="ml-auto hover-visible">
                      <span className="text-sm font-semibold text-primary">
                        Invite
                      </span>
                    </div>
                  </li>
                  <li className="teams-profile-list teams-profile-list--sm bg-color-on-hover">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{ width: 32, height: 32 }}
                    >
                      <img src={avatarPic} alt="avatar-pic" />
                    </figure>
                    <div className="ml-3 text-sm font-medium">
                      Charles Wundeng
                    </div>
                    <div className="ml-auto hover-visible">
                      <span className="text-sm font-semibold text-primary">
                        Invite
                      </span>
                    </div>
                  </li>
                </ul>
              ) : (
                <>
                  <div className="mt-15 px-4">
                    <label
                      className="input-field-label"
                      style={{ fontSize: 14 }}
                    >
                      Email
                    </label>
                    <input
                      className="input-field input-field--sm-38"
                      placeholder="Type email address"
                    />
                    <div className="text-13 font-medium text-grey-800 mt-4 bg-grey-200 rounded-xl px-4 py-3">
                      New team members will have access to this channel and all
                      open channels and boxes when they accept the invitation
                      and join.
                    </div>
                  </div>
                  <InviteNewTeamMember
                    data={userData}
                    headerText={headerText}
                    className="px-4"
                  />
                  <div
                    className="flex justify-between flex-row-reverse mr-4"
                    style={{ marginTop: 60 }}
                  >
                    <button className="btn btn--primary btn--sm w-1/4 text-15">
                      Send invite
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <div>
                <label className="input-field-label" style={{ fontSize: 14 }}>
                  Email
                </label>
                <input
                  className="input-field input-field--sm-38"
                  placeholder="Add the email address for the invitation"
                />
              </div>
              <InviteNewTeamMember
                data={userData}
                headerText={headerText}
								className="mt-9"
              />
              <div className="text-13 text-grey-800 mt-5 bg-grey-200 rounded-xl px-4 py-3">
							You will be notified once the team member has joined
              </div>
              <div
                className="flex justify-between flex-row-reverse mr-4"
                style={{ marginTop: 60 }}
              >
                <button className="btn btn--primary btn--sm w-1/4 text-15">
                  Send invite
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default InviteModal;
