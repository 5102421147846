import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/eb-logo.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import GeneralSettingsHedder from './Common/GeneralSettingsHedder';
import { useHistory } from "react-router-dom";
import ChatPreviewListSample from "./ChatPreviewListSample";
import iconHelpVideo from '../assets/icons/icon-help-video.svg';
import HelperVideo from "./Common/HelperVideo";
import ChannelHeaderNew from "./Common/ChannelHeaderNew";
import ChannelConnectLinks from "./Common/ChannelConnectLinks";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";

const ChannelFacebookLeads = () => {
    let history = useHistory();
    const [activeScreen, setActiveScreen] = useState(1);
    const nextScreen = () => {
        history.push("/channel-connected-page");
    };
    // const [modalShow, hideModalShow] = useState(true);
    // window.onload = function () {
    //   document.getElementById("rightSide").addEventListener("scroll", myFunction);
    //   function myFunction() {
    //     var header = document.getElementById("myHeader");
    //     var sticky = 80;
    //     var scrollY = document.getElementById("rightSide").scrollTop;
    //     if (scrollY >= sticky) {
    //       header.classList.add("sticky");
    //     } else {
    //       header.classList.remove("sticky");
    //     }
    //   }
    // };

    return (
        <main className="dashboard">
            <section className="dashboard__container flex items-start">
                <SidebarLinksNew />
                <SideBarExpandedNew />
                <section className="dashboard__main">
                    <GeneralSettingsHedder text="Channel Settings" />
                    <div className="dashboard__main__body" style={{ height: 'calc(100% - 79px)' }}>
                        <div className="dashboard__main__body__section-left overflow-overlay">
                            <ChannelConnectLinks
                                facebookFailed={false}
                                show={true}
                                activeScreen={4}
                                setActiveScreen={setActiveScreen} />
                        </div>
                        <div
                            className="dashboard__main__body__section-right layout-1"
                            id="rightSide"
                        >
                            <div className="card-container--new">
                                <div>
                                    <h2 className="font-semibold text-lg">Connect your Facebook Lead Ads </h2>
                                    <p className="text-grey-800 text-sm mt-3">By connecting your Facebook Lead Adst you will be able to receive and reply to new leads , right from your enquirybox.</p>
                                </div>
                                <div className="mt-8">
                                    <h4 className="font-bold">To connect your account, you need these 3 things</h4>
                                    <ul className="mt-3 ml-1">
                                        <li className="text-sm font-medium">
                                            <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                                            A Facebook Ads account
                                        </li>
                                        <li className="text-sm font-medium mt-2">
                                            <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                                            Admin level access to that account
                                        </li>
                                        <li className="text-sm font-medium mt-2">
                                            <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
                                            A Facebook page running Facebook Leads Ads
                                        </li>
                                    </ul>
                                </div>
                                <div className="bg-grey-200 rounded-xl py-3 px-4 mt-8">
                                    <p className="text-grey-800 text-13">When you connect your account, we only access your lead ads. Your personal messages will never be accessed by enquirybox.</p>
                                </div>
                                <div className="mt-5">
                                    <button
                                        className="btn btn--sm btn--facebook gap-2"
                                        onClick={nextScreen}
                                    >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                                                fill="#ffffff"
                                            />
                                        </svg>
                                        Connect my Facebook Lead Ads
                                    </button>
                                </div>
                                <div className="mt-8">
                                    <HelperVideo />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </section>
        </main>
    );
};

export default ChannelFacebookLeads;
