import React, {useState} from 'react'
import logo from "../../assets/icons/eb-logo.svg";
import iconLogout from "../../assets/icons/icon-logout.svg";
import avatarPic from "../../assets/images/avatar-pic.png";
import iconMyProfile from "../../assets/icons/icon-my-profile.svg";
import iconTeamMembers from "../../assets/icons/icon-team-members.svg";
import iconBilling from "../../assets/icons/icon-subscription-billing.svg";
import iconEnquiryAssistant from "../../assets/icons/icon-enquiry-assistant.svg";
import iconSettings from "../../assets/icons/icon-settings.svg";
import iconHelp from "../../assets/icons/icon-get-help.svg";
import IconApp from "../../assets/icons/icon-get-app.svg";

const AccountsSideLinks = () => {
  const [openDropDown, SetOpenDropDown] = useState(false);

	return (
		<div className="dashboard__sidebar__links">
			<div className="dashboard__sidebar__logo">
				<img
					src={logo}
					alt="eb-logo"
					className="mx-auto"
					style={{ height: 31 }}
				/>
			</div>
			<ul className="flex items-center justify-center flex-col mt-auto">
        <li className="dashboard__sidebar__links__list">
          <svg width="24" height="24" viewBox="0 0 30 30" fill="none">
            <rect
              x="4"
              y="4"
              width="9.77778"
              height="9.77778"
              rx="3"
              fill="#2B3639"
            />
            <rect
              x="4"
              y="16.2222"
              width="9.77778"
              height="9.77778"
              rx="3"
              fill="#2B3639"
            />
            <rect
              x="16.2222"
              y="4"
              width="9.77778"
              height="9.77778"
              rx="3"
              fill="#2B3639"
            />
            <rect
              x="16.2222"
              y="16.2222"
              width="9.77778"
              height="9.77778"
              rx="3"
              fill="#2B3639"
            />
          </svg>
        </li>
        <li className="dashboard__sidebar__links__list">
          <svg width="19" height="20" viewBox="0 0 21 22" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.96925 1.14013C7.30091 0.443651 8.00356 0 8.77497 0H11.5079C12.2793 0 12.982 0.443651 13.3136 1.14013L13.7417 2.03904H18.9415C19.6823 2.03904 20.2829 2.63963 20.2829 3.3805C20.2829 4.12137 19.6823 4.72196 18.9415 4.72196H1.34147C0.600596 4.72196 0 4.12137 0 3.3805C0 2.63963 0.600594 2.03904 1.34146 2.03904H6.5412L6.96925 1.14013ZM18.1903 6.22439H2.09271L3.42742 19.3045C3.58364 20.8355 4.87299 22 6.41192 22H13.8711C15.41 22 16.6993 20.8355 16.8556 19.3045L18.1903 6.22439Z"
              fill="#2B3639"
            />
          </svg>
        </li>
        <li className="relative dashboard__sidebar__links__list--profile-avatar cursor-pointer" onClick={() => SetOpenDropDown(!openDropDown)}>
          <figure
            className="avatar-container avatar-container--full"
            style={{ width: 36, height: 36 }}
          >
            <img src={avatarPic} alt="" className="avatar-pic" />
          </figure>
          <div
            className={`dropdown-menu no-translate p-6 ${openDropDown ? 'show' : ''}`}
            style={{
              width: 390,
              "max-height": "90vh",
              overflow: "auto",
              left: 30,
              bottom: 35,
            }}
          >
            <div className="flex">
              <figure
                className="avatar-container avatar-container--cover"
                style={{
                  width: 42,
                  height: 42,
                  "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={avatarPic} className="" alt="avatar-pic" />
              </figure>
              <div className="flex-1 ml-4 pb-5 border-b border-solid border-grey-400">
                <h4 className="font-semibold">John Leon</h4>
                <p className="text-sm text-grey-700">johnleon_12@gmail.com</p>
              </div>
            </div>
            <ul className="mt-5">
              <li className="flex py-2 bg-color-on-hover">
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#FAF9F8",
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={iconMyProfile} alt="" className="avatar-pic" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">My Profile</h4>
                  <p className="text-sm text-grey-800">johnleon_12@gmail.com</p>
                </div>
              </li>
              <li className="flex py-2 bg-color-on-hover mt-1">
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#FAF9F8",
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
                  }}
                >
                  <img
                    src={iconEnquiryAssistant}
                    alt=""
                    className="avatar-pic"
                  />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">
                    Enquiry assistant
                  </h4>
                  <p className="text-sm text-grey-800">
                    Manage how & when you get notified
                  </p>
                </div>
              </li>
              <li
                className="relative pb-5 mb-5 border-b border-grey-400"
                style={{ width: "80%", left: "17%" }}
              ></li>
              <li className="flex py-2 bg-color-on-hover">
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#FAF9F8",
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
                  }}
                >
                  <img src={iconSettings} alt="" className="avatar-pic" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">Settings</h4>
                  <p className="text-sm text-grey-800">
                    View all account settings
                  </p>
                </div>
              </li>
              <li className="flex py-2 bg-color-on-hover mt-1">
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#FAF9F8",
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
                  }}
                >
                  <img
                    src={iconBilling}
                    alt=""
                    style={{ width: 19, height: 21 }}
                  />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">
                    Subscription & billing
                  </h4>
                  <p className="text-sm text-grey-800">
                    Invoices, payment method, upgrade
                  </p>
                </div>
              </li>
              <li className="flex py-2 bg-color-on-hover mt-1">
                <figure
                  className="avatar-container avatar-container--contain bg-grey-300"
                  style={{
                    width: 42,
                    height: 42,
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={iconTeamMembers} alt="" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">Team members</h4>
                  <p className="text-sm text-grey-800">
                    Invite new, manage access
                  </p>
                </div>
              </li>
              <li
                className="relative pb-5 mb-5 border-b border-grey-400"
                style={{ width: "80%", left: "17%" }}
              ></li>
              <li className="flex py-2 bg-color-on-hover">
                <figure
                  className="avatar-container avatar-container--contain bg-grey-300"
                  style={{
                    width: 42,
                    height: 42,
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={iconHelp} alt="" className="avatar-pic" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">Get some help</h4>
                  <p className="text-sm text-grey-800">
                    Learn about features, get support
                  </p>
                </div>
              </li>
              <li className="flex py-2 bg-color-on-hover mt-1">
                <figure
                  className="avatar-container avatar-container--contain bg-grey-300"
                  style={{
                    width: 42,
                    height: 42,
                    "box-shadow": "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={IconApp} alt="" />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">Get the app</h4>
                  <p className="text-sm text-grey-800">Download the app</p>
                </div>
              </li>
              <li
                className="relative pb-5 mb-5 border-b border-grey-400"
                style={{ width: "80%", left: "17%" }}
              ></li>
            </ul>
            <div className="flex items-center cursor-pointer">
              <figure
                className="flex items-center justify-center"
                style={{ width: 40, height: 40 }}
              >
                <img src={iconLogout} className="" alt="icon-logout" />
              </figure>
              <h4 className="text-base-1 font-semibold ml-4">Log Out</h4>
            </div>
          </div>
        </li>
      </ul>
		</div>
	)
}

export default AccountsSideLinks