import React, { useState } from "react";
import avatarPic from "../assets/images/avatar-pic.png";
import iconPlus from "../assets/icons/icon-plus-white.svg";
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import BoxSettingsNavLinks from "./Common/BoxSettingsNavLinks";
import iconLock from "../assets/icons/icon-lock.svg";

const BoxSettingsTeamAccess = () => {
	const [isOpenForAll, setOpenForAll] = useState(true)
	return (
		<main className="dashboard">
			<section className="dashboard__container flex items-start">
				<SidebarLinks />
				<SidebarExpanded />

				<section className="dashboard__main general-settings">
					<GeneralSettingsHedder text={"General Box Settings"} />
					<div className="dashboard__main__body">
						<div className="dashboard__main__body__section-left overflow-overlay gs-body">
							<BoxSettingsNavLinks activeScreen={3} />
						</div>
						{isOpenForAll ? 
						<div className="dashboard__main__body__section-right p-10">
							<h2 className="text-2xl font-semibold text-center">Team access</h2>
							<div className="flex items-center justify-between py-5 px-6 border border-grey-400 border-radius-14 mt-8">
								<span className="font-semibold">This box is open for all</span>
								<span className="text-sm text-primary font-semibold cursor-pointer" onClick={() => setOpenForAll(false)}>
									Change to invite only
								</span>
							</div>
							<div className="py-5 px-6 border border-grey-400 border-radius-14 mt-5">
								<div className="flex items-center justify-between">
									<h3 className="text-lg font-semibold">Box members</h3>
									<button className="btn btn--primary btn--xs">
										<img src={iconPlus} alt="icon-plus" className="mr-2" />
										New Member
									</button>
								</div>
								<ul className="mt-5">
									<li className="teams-profile-list bg-color-on-hover">
										<figure
											className="avatar-container avatar-container--full"
											style={{ width: 32, height: 32 }}
										>
											<img src={avatarPic} alt="avatar-pic" />
										</figure>
										<div className="teams-profile-list__title">
											Charles Wundeng
										</div>
										<div className="ml-auto hover-visible">
											<span className="text-sm font-semibold text-primary hidden">
												Invite
											</span>
											<span className="text-sm font-semibold text-red-900">
												Remove
											</span>
										</div>
									</li>
									<li className="teams-profile-list bg-color-on-hover">
										<figure
											className="avatar-container avatar-container--full"
											style={{ width: 32, height: 32 }}
										>
											<img src={avatarPic} alt="avatar-pic" />
										</figure>
										<div className="teams-profile-list__title">
											Madison Inouye
										</div>
										<div className="ml-auto hover-visible">
											<span className="text-sm font-semibold text-primary hidden">
												Invite
											</span>
											<span className="text-sm font-semibold text-red-900">
												Remove
											</span>
										</div>
									</li>
									<li className="teams-profile-list bg-color-on-hover">
										<figure
											className="avatar-container avatar-container--full"
											style={{ width: 32, height: 32 }}
										>
											<img src={avatarPic} alt="avatar-pic" />
										</figure>
										<div className="teams-profile-list__title">Merva Sahin</div>
										<div className="ml-auto hover-visible">
											<span className="text-sm font-semibold text-primary hidden">
												Invite
											</span>
											<span className="text-sm font-semibold text-red-900">
												Remove
											</span>
										</div>
									</li>
									<li className="teams-profile-list bg-color-on-hover">
										<figure
											className="avatar-container avatar-container--full"
											style={{ width: 32, height: 32 }}
										>
											<img src={avatarPic} alt="avatar-pic" />
										</figure>
										<div className="teams-profile-list__title">John Leon</div>
										<div className="ml-auto hover-visible">
											<span className="text-sm font-semibold text-primary hidden">
												Invite
											</span>
											<span className="text-sm font-semibold text-red-900">
												Remove
											</span>
										</div>
									</li>
									<li className="teams-profile-list bg-color-on-hover">
										<figure
											className="avatar-container avatar-container--full"
											style={{ width: 32, height: 32 }}
										>
											<img src={avatarPic} alt="avatar-pic" />
										</figure>
										<div className="teams-profile-list__title">Lisa Fotios</div>
										<div className="ml-auto hover-visible">
											<span className="text-sm font-semibold text-primary hidden">
												Invite
											</span>
											<span className="text-sm font-semibold text-red-900">
												Remove
											</span>
										</div>
									</li>
								</ul>
							</div>
						</div> : 
						<div className="dashboard__main__body__section-right p-10">
            <h2 className="text-2xl font-semibold text-center">Team access</h2>
              <div className="flex items-center justify-between py-5 px-6 border border-grey-400 border-radius-14 mt-8">
                <div className="flex items-center font-semibold">
                  <img src={iconLock} alt="icon-lock" className="mr-2" />
                  This box is invite only</div>
                <span className="text-sm text-primary font-semibold cursor-pointer" onClick={() => setOpenForAll(true)}>
                Change to open for all
                </span>
              </div>
              <div className="py-5 px-6 border border-grey-400 border-radius-14 mt-5">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold">Box members</h3>
                  <button className="btn btn--primary btn--xs">
                    <img src={iconPlus} alt="icon-plus" className="mr-2" />
                    New Member
                  </button>
                </div>
                <ul className="mt-5">
                  <li className="teams-profile-list bg-color-on-hover">
                    <figure
                      className="avatar-container avatar-container--full"
                      style={{ width: 32, height: 32 }}
                    >
                      <img src={avatarPic} alt="avatar-pic" />
                    </figure>
                    <div className="teams-profile-list__title">
                      Charles Wundeng
                    </div>
                    <div className="ml-auto hover-visible">
                      <span className="text-sm font-semibold text-primary hidden">
                        Invite
                      </span>
                      <span className="text-sm font-semibold text-red-900">
                        Remove
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div> }
					</div>
				</section>
			</section>
		</main>

	);
};

export default BoxSettingsTeamAccess;
