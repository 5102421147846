import { useRef, useState } from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import progressIcon from '../../assets/icons/icon-progress-bar.svg';
import docsIcon from '../../assets/icons/icon-uploaded-docs.svg';
import moreIcon from '../../assets/icons/icon-more-icon.svg';
import renameIcon from '../../assets/icons/icon-edit.svg';
import deleteIcon from '../../assets/icons/icon-Delete-16.svg';
import fileTooLargeIcon from '../../assets/icons/icon-file-too-large.svg';

const UploadFile = ({ setShowFileUpload, showFileUpload }) => {
	const inputRef = useRef();
	const [inputVal, setInputVal] = useState("");
	const [isInputFocused, setIsInputFocused] = useState(true);
	const [uploadedImages, setUploadedImages] = useState([]);
	const [isUploading, setIsUploading] = useState(true);
	const [isUploaded, setIsUploaded] = useState(false);
	const [showDropDown, setShowdropDown] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isFileLarge, setIsFileLarge] = useState(false);
	const [isUnsupported, setIsUnsupported] = useState(false);
	const isError = isFileLarge ? true : isUnsupported ? true : false;

	const handleDragEnter = (e) => {
		e.preventDefault();
		console.log("drag enter");
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		console.log("drag leave");
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		console.log("drag over");
	};

	const handleDrop = (e) => {
		e.preventDefault();
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			// handleFiles(e.dataTransfer.files);
			console.log("at least one file has been dropped so do something");
			setUploadedImages(e.dataTransfer.files);
		}
		console.log(e.dataTransfer.files, "handleDrop");
	};

	const handleInputChange = () => { };

	return (
		<div className={`modal ${showFileUpload ? 'show' : ''} ${isUploading ? 'modal--image-upload' : ''} ${isUploaded ? 'modal--image-uploaded' : ''} ${isEdit ? 'modal--edit' : ''} ${isFileLarge ? 'modal--Error-screen' : isUnsupported ? 'modal--unsupported-screen' : ''}`}>
			<div className="modal__container" style={{ height: "433px" }}>
				<div className="modal__header">
					<h2 className="modal__heading pr-5">Upload File</h2>
					<div
						className="modal__close-icon"
						onClick={() => setShowFileUpload(false)}
					>
						<img src={modalCloseIcon} alt="" />
					</div>
				</div>
				{!isError ?
					<div className="px-8 pt-6">
						<div
							onClick={() => inputRef.current.click()}
							className="file-upload-wrapper"
							onDrop={(e) => handleDrop(e)}
							onDragOver={(e) => handleDragOver(e)}
							onDragEnter={(e) => handleDragEnter(e)}
							onDragLeave={(e) => handleDragLeave(e)}
							onChange={handleInputChange}
						>
							<input ref={inputRef} type="file" name="file-browser-input" />
							<h3 className="text-sm font-semibold">
								Click to Upload or Drag and Drop
							</h3>
							<p className="text-sm text-grey-800 mt-2">
								Max file size{" "}
								<span className="font-medium text-grey-900">100MB</span>
							</p>
							<button
								className="btn btn--grey btn--xs w-2/4 mx-auto mt-5"
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								Upload
							</button>
						</div>
						{isUploading &&
							<div className="mt-25">
								<div className="font-semibold">{isUploaded ? 'Uploaded files' : 'Uploading'}</div>
								<div className={`${isUploaded ? 'modal__uploaded-section' : 'modal__progress-section'}`}>
									<div className="flex justify-between">
										<div className={`${isUploaded ? 'flex items-center' : ''}`}>
											{isUploaded && !isEdit && <img src={docsIcon} alt='' className="mr-3" style={{ width: 25, height: 32 }} />}
											{!isEdit && <div className={`${isUploaded ? 'text-sm font-medium' : 'text-13 font-semibold'}`}>Fixed assets.docx</div>}
											{isEdit &&
												<div>
													<input
														onFocus={() => setIsInputFocused(true)}
														onBlur={() => setIsInputFocused(false)}
														placeholder={inputVal}
														value={inputVal}
														onChange={(e) => setInputVal(e.target.value)}
														className="input-field input-field--edit"
													/>
													<div className="flex justify-end mt-3">
														<button className="btn bg-grey-300 btn--edit-buttons " onClick={() => setIsEdit(false)}>
															Cancel
														</button>
														<button className="btn btn--primary btn--edit-buttons ">Save</button>
													</div>
												</div>
											}
										</div>
										{isUploaded && !isEdit &&
											<>
												<div className='relative flex items-center files__card--right-side cursor-pointer' onClick={() => setShowdropDown(!showDropDown)}>
													<img src={moreIcon} alt='' />
													<div className={`dropdown-menu dropdown-menu--edit ${showDropDown ? 'open' : ''}`}>
														<div className="dropdown-menu__list text-grey-800" onClick={() => {
															setShowdropDown(!showDropDown);
															setIsEdit(true)
														}}>
															<img src={renameIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />
															Rename</div>
														<div className="dropdown-menu__list">
															<img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />
															Delete</div>
													</div>
												</div>
											</>}
										{!isUploaded && !isEdit && <div className="flex">
											<div className="text-13 text-grey-800 mr-2">8.5 MB</div>
											<div className="txt-blue files__cancel" onClick={() => setIsUploaded(true)} >Cancel</div>
										</div>}
									</div>
									{!isUploaded && !isEdit && <div>
										<img src={progressIcon} alt='' className="mt-2" style={{ width: 430, height: 7 }} />
									</div>}
								</div>
							</div>}
						<div className="flex justify-end">
							<button className="btn bg-grey-300 btn--file-library mr-3" onClick={() => setShowFileUpload(false)}>
								Cancel
							</button>
							<button className="btn btn--sm btn--primary btn--file-library">
								Upload
							</button>
						</div>
					</div> :
					<div className="px-8 pt-6 pb-8">
						<div className={`${isUnsupported ? 'modal__unsupported-section' : 'modal__error-section'} `}>
							<img src={fileTooLargeIcon} alt='' style={{ width: 32, height: 32 }} />
							<div>
								<h3 className="flex justify-center text-sm text-red-600 font-semibold mt-3">{isUnsupported ? '"Unsupported file"' : '"File too large"'}</h3>
								<div className={`mt-1 text-xs ml-4 ${isUnsupported ? 'txt-grey' : 'text-grey-800'}`}>{isUnsupported ? "This type of file is not supported. You can upload PDF, Word Doc, Powerpoint & Image files such as PNG & JPEG" : "This file is larger than the 100MB limit. Please compress the file here to make it smaller."}</div>
								{!isUnsupported && <div className="flex justify-center mt-2 txt-blue text-13 font-medium">Compress the file here</div>}
								<div className={`flex justify-center ${isUnsupported ? 'mt-5' : 'mt-25'}`}>
									<button className="btn btn--primary btn--try-again">Try again</button>
								</div>
							</div>
						</div>
					</div>}
			</div>
		</div>
	);
};

export default UploadFile;
