import React, {useState} from "react";
import { Link } from "react-router-dom";
import iconSearch from "../assets/icons/icon-search.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import ChannelHeader from "./Common/ChannelHeader";
import userIcon from "../assets/icons/usericon1.svg";
import userIcon2 from "../assets/icons/usericon2.svg";
import CustomToolTip from "./Common/CustomToolTip";



import iconCheckCircle from "../assets/icons/icon-green-check-circle.svg";
import InviteNewTeamMember from "./InviteNewTeamMember";


const ChannelConnectedOpen = () => {

const [showSendInvite,setShowSendInvite]=useState(false);
const [openInvite,setOpenInvite] = useState(1);
const userData =[{id:1,title :"Team member", desc :"Lorem ipsum dolor sit amet, consectetur adipiscing elit."} , {id:2,title :"Account admin", desc :"The Box will be accessible to team members who have been."}]
const headerText = "Select the user type";
const data = [
	{
		id:1,
		tooltipText: "John Leon",
		profileIcon:userIcon
	},
	{
		id:2,
		tooltipText: "Charles Wundeng",
		profileIcon:userIcon2
	},
	{
		id:3,
		tooltipText: "Madison Inouye",
		profileIcon:userIcon
	},
	{
		id:4,
		tooltipText: "Merva Sahin",
		profileIcon:userIcon2
	},
	{
		id:5,
		tooltipText: "Lisa Fotios",
		profileIcon:userIcon
	},
	{
		id:6,
		tooltipText: "Charles Wundeng",
		profileIcon:userIcon2
	},
	{
		id:7,
		tooltipText: "Lisa Fotios",
		profileIcon:userIcon
	},
	{
		id:8,
		tooltipText: "John Leon",
		profileIcon:userIcon2
	},

	
]

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />
        <section className="dashboard__main">
          <ChannelHeader
            text={"Stead"}
            linkText="Settings & Notifications"
						showProfileImages={false}
            HeaderIcon={() => (
              <figure className="avatar-container" style={{width:52, height:52}}>
                <svg width="32" height="32" viewBox="0 0 16 16" fill="none">
                <path
                  d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                  fill="#4C5254"
                />
              </svg>
              </figure>
            )}
          />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left">
              <h2 className="text-lg font-semibold text-grey-900">Messages</h2>
              <div className="mt-15">
                <label className="text-xs font-medium text-grey-700 uppercase">
                  New
                </label>
                <ul className="">
                  <li className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer active">
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{
                        width: 48,
                        height: 48,
                        border: "1px solid rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <img src={iconAvatar} alt="" className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900 truncate">
                        Enquiry Assistant
                      </h3>
                      <p className="text-base-1 text-grey-800 truncate">
											Connect your Contact Form
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
                <div>
                  <button className="btn btn--back rounded btn--xs  border border-grey-400">
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M4.91895 1L1.00195 5L4.91895 9"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 4.99634L11 4.99634"
                        stroke="#005C6F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="ml-1 text-13 font-medium text-primary">
                      Back
                    </span>
                  </button>
                </div>
                <div className="mt-5">
                  <h3 className="font-semibold">8 team members have access to this channel</h3>
                  <div className="flex relative mt-4">
                    <div className="flex overflow-visible relative dashboard__main__body__gap">
                      {data.map((item,index) => {
												return (
													<CustomToolTip position="top--new" tooltipText={item.tooltipText} img={item.profileIcon}>
														<figure
															className="avatar-container avatar-container--full bg-white"
															style={{
																width: 40,
																height: 40,
															}}
														>
															<img src={item.profileIcon} alt="" className="" />
														</figure>
													</CustomToolTip>
												)
											})}
                    </div>

                  </div>
                </div>
                <div className="mt-8">
                  <h3 className="font-bold">Invite a new team member</h3>
                  <div className="mt-15">
                    <label
                      className="input-field-label"
                      style={{ fontSize: 14 }}
                    >
                      Email
                    </label>
                    <input
                      className="input-field input-field--md"
                      placeholder="Type email address"
                    />
                    <div className="text-13 font-medium text-grey-800 mt-4 bg-grey-200 rounded-xl p-f-10">
                      New team members will have access to this channel and all open channels and boxes when they accept the invitation and join.
                    </div>
                  </div>
                  {showSendInvite && <InviteNewTeamMember data={userData} headerText={headerText}/>}
									<div className="flex justify-between items-center mt-10">
										<span className="text-primary text-sm font-semibold">Skip for now</span>
										<button className="btn btn--primary btn--sm w-1/4" onClick={() => setShowSendInvite(!showSendInvite)}>
											Send invite
										</button>
								</div> 
                </div>              
              </div>
            </div>
          </div>
        </section>
      </section>
    </main >
  );
};

export default ChannelConnectedOpen;
