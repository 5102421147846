import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/eb-logo.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconSidebarList1 from "../assets/icons/icon-dashboard-sidebar1.svg";
import iconSidebarList2 from "../assets/icons/icon-dashboard-sidebar2.svg";
import iconSidebarList3 from "../assets/icons/icon-dashboard-sidebar3.svg";
import iconSidebarList4 from "../assets/icons/icon-dashboard-sidebar4.svg";
import iconTrash from "../assets/icons/icon-trash.svg";
import iconUser from "../assets/icons/icon-user.svg";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconWebsiteChatbot from "../assets/icons/icon-website-chatbot.svg";
import iconAvatar from "../assets/images/avatar-pic2.png";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic1 from "../assets/icons/icon-avatar.svg";
import iconEmail from "../assets/icons/icon-email.svg";
import iconFacebook from "../assets/icons/icon-facebook.svg";
import iconInstagram from "../assets/icons/icon-instagram.svg";
import iconSettings from "../assets/icons/icon-settings.svg";
import iconForward from "../assets/icons/icon-forward.svg";
import arrowRight from "../assets/icons/icon-arrow-right.svg";
import iconReply from "../assets/icons/icon-reply.svg";
import iconNote from "../assets/icons/icon-note.svg";
import iconReminder from "../assets/icons/icon-reminder.svg";
import iconExpand from "../assets/icons/icon-expand.svg";
import iconAddItem from "../assets/icons/icon-add-item.svg";
import iconSmiley from "../assets/icons/icon-smiley.svg";
import iconUploadPic from "../assets/icons/icon-upload-pic.svg";
import iconAttachment from "../assets/icons/icon-attachment.svg";
import iconEmojiFire from "../assets/icons/icon-emoji-fire.svg";
import iconRocket from "../assets/icons/icon-emoji-rocket.svg";
import iconBall from "../assets/icons/icon-emoji-ball.svg";
import iconThreedots from "../assets/icons/icon-three-dots.svg";
import InputField from "./Common/InputField";
import searchCloseIcon from "../assets/icons/icon-search-close.svg";
import search from "../assets/icons/icon-search.svg";
import EmojiModal from "./modal/EmojiModal";
import AttachFiles from "./modal/AttachFiles";
import FileLibrary from "./modal/FileLibrary";
import UploadFile from "./modal/UploadFile";
import personIcon from "../assets/icons/icon-mailchimp.svg";
import zapierIcon from "../assets/icons/icon-zapier.svg";
import facebookicon from "../assets/icons/icon-facebook-white.svg";
import ChannelProfileList from "./ChannelProfileList";
import HtextIcon from "../assets/icons/icon-h.svg";
import InstagramIcon from "../assets/icons/icon-instagram-white.svg";
import SharpIcon from "../assets/icons/icon-Shape.svg";
import GStextIcon from "../assets/icons/icon-GS.svg";
import emailSharp from "../assets/icons/email-Shape.svg";
import ChatFooterInputBox from "./ChatFooterInputBox";

const replyBy = [
  {
    title: "Email",
  },
  {
    title: "Facebook",
  },
];

const users = [
  {
    name: "None",
  },
  {
    name: "Madison Inouye",
  },
  {
    name: "Olivia Rhye",
  },
  {
    name: "Charles Wundeng",
  },
  {
    name: "Lisa Fotios",
  },
  {
    name: "Peter Parker",
  },
];

const ChannelInstagramConversation = () => {
  const [showAssignToUsersList, setShowAssignToUsersList] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [showPotentialLeadsEmojiModal, setShowPotentialLeadsEmojiModal] =
    useState(false);
  const [showMarketingLeadsEmojiModal, setShowMarketingLeadsEmojiModal] =
    useState(false);
  const [showProductEnquiriesModal, setShowProductEnquiriesEmojiModal] =
    useState(false);
  const [inputVal, setInputVal] = useState("");
  const [showAttachFiles, setShowAttachFiles] = useState(false);
  const [showFileLibrary, setShowFileLibrary] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
	const [showTabs,setShowTabs] = useState(true);

  const dummyData = [
    {
      name: "alexfoster_dp@gmail.com",
    },
    {
      name: "alyonapastu@gmail",
    },
    {
      name: "Abraham Perez",
    },
    {
      name: "Anna Moreva",
    },
    {
      name: "Aldair Lina",
    },
  ];
  const data = [
    {
      name: "Stead",
      profilePic: personIcon,
      subIcon: facebookicon,
    },
    {
      name: "Facebook Pages",
      profilePic: zapierIcon,
      subIcon: facebookicon,
    },
    {
      name: "Instagram",
      profilePic: HtextIcon,
      subIcon: InstagramIcon,
    },
    {
      name: "Hunley Weddings",
      profilePic: SharpIcon,
      subIcon: InstagramIcon,
    },
    {
      name: "Wedding email",
      profilePic: GStextIcon,
      subIcon: emailSharp,
    },
  ];
  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowAssignToUsersList(false);
  };

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <div className="dashboard__sidebar__links">
          <div className="dashboard__sidebar__logo">
            <img
              src={logo}
              alt="eb-logo"
              className="mx-auto"
              style={{ height: 31 }}
            />
          </div>

          <ul className="flex items-center justify-center flex-col mt-8">
            <li className="dashboard__sidebar__links__list active">
              <svg width="22" height="23" viewBox="0 0 22 23" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.25 0.75C2.79822 0.75 0 3.54822 0 7V10.4223V13V15.625V16.25C0 19.4183 2.35752 22.036 5.41405 22.4446C5.92363 22.5626 6.45453 22.625 7 22.625H14.7461C15.2897 22.625 15.8188 22.563 16.3268 22.4458C19.3878 22.0413 21.75 19.4215 21.75 16.25V7C21.75 3.54822 18.9518 0.75 15.5 0.75H6.25ZM19.25 11.8975V7C19.25 4.92893 17.5711 3.25 15.5 3.25H6.25C4.17893 3.25 2.5 4.92893 2.5 7V11.9377C2.5683 12.5356 3.07601 13 3.69219 13H5.74297C6.72077 13 7.55522 13.707 7.71581 14.6715L7.91558 15.8714C8.03603 16.5948 8.66186 17.125 9.39521 17.125H12.3543C13.0876 17.125 13.7134 16.5949 13.8339 15.8716L14.034 14.6712C14.1947 13.7068 15.0291 13 16.0068 13H18.0539C18.6838 13 19.2004 12.5147 19.25 11.8975Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 0H16C19.3137 0 22 2.68629 22 6V16C22 19.3137 19.3137 22 16 22H6C2.68629 22 0 19.3137 0 16V6C0 2.68629 2.68629 0 6 0ZM12.25 6.1875C12.25 5.49714 11.6904 4.9375 11 4.9375C10.3096 4.9375 9.75 5.49714 9.75 6.1875V15.8125C9.75 16.5029 10.3096 17.0625 11 17.0625C11.6904 17.0625 12.25 16.5029 12.25 15.8125V6.1875ZM5.95837 11.8125C6.64873 11.8125 7.20837 12.3721 7.20837 13.0625V15.8125C7.20837 16.5029 6.64873 17.0625 5.95837 17.0625C5.26802 17.0625 4.70837 16.5029 4.70837 15.8125V13.0625C4.70837 12.3721 5.26802 11.8125 5.95837 11.8125ZM17.2916 10.3125C17.2916 9.62214 16.732 9.0625 16.0416 9.0625C15.3513 9.0625 14.7916 9.62214 14.7916 10.3125V15.8125C14.7916 16.5029 15.3513 17.0625 16.0416 17.0625C16.732 17.0625 17.2916 16.5029 17.2916 15.8125V10.3125Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
                <path
                  d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list dashboard__sidebar__links__list--icon-leaf">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7.45459 12C7.45459 14.5104 9.48966 16.5455 12 16.5455C14.5104 16.5455 16.5455 14.5104 16.5455 12"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C10.13 2 6.79667 2 2 2C2 6.85477 2 10.1881 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
                <path
                  d="M7.92334 2C12.914 2.82392 15.788 5.64803 16.5454 10.4723C16.5904 10.9471 16.613 11.2373 16.613 11.3428"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
              </svg>
            </li>
          </ul>

          <ul className="flex items-center justify-center flex-col mt-auto">
            <li className="dashboard__sidebar__links__list">
              <svg width="24" height="24" viewBox="0 0 30 30" fill="none">
                <rect
                  x="4"
                  y="4"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="4"
                  y="16.2222"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="16.2222"
                  y="4"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="16.2222"
                  y="16.2222"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="19" height="20" viewBox="0 0 21 22" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.96925 1.14013C7.30091 0.443651 8.00356 0 8.77497 0H11.5079C12.2793 0 12.982 0.443651 13.3136 1.14013L13.7417 2.03904H18.9415C19.6823 2.03904 20.2829 2.63963 20.2829 3.3805C20.2829 4.12137 19.6823 4.72196 18.9415 4.72196H1.34147C0.600596 4.72196 0 4.12137 0 3.3805C0 2.63963 0.600594 2.03904 1.34146 2.03904H6.5412L6.96925 1.14013ZM18.1903 6.22439H2.09271L3.42742 19.3045C3.58364 20.8355 4.87299 22 6.41192 22H13.8711C15.41 22 16.6993 20.8355 16.8556 19.3045L18.1903 6.22439Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list--profile-avatar">
              <figure
                className="avatar-container avatar-container--full"
                style={{ width: 36, height: 36 }}
              >
                <img src={avatarPic} alt="" className="avatar-pic" />
              </figure>
            </li>
          </ul>
        </div>
        <div className="dashboard__sidebar__expanded-section">
          <h2 className="text-2xl font-semibold text-grey-900">Enquirybox</h2>
          <div className="flex items-center mt-5 pb-18 border-b border-grey-400">
            <div className="flex items-center">
              <figure
                className="avatar-container avatar-container--contain"
                style={{
                  width: 32,
                  height: 32,
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={avatarPic1} alt="" />
              </figure>
              <p className="ml-3 text-base-1 font-semibold text-grey-900">
                Enquiry Assistant
              </p>
            </div>
            <div
              className="flex items-center justify-center text-xs text-white font-semibold bg-red-900 px-2 rounded-full ml-auto"
              style={{ height: 20 }}
            >
              3
            </div>
          </div>
          <div className="">
            <div className="flex items-center justify-between pt-4 pb-2">
              <h3 className="text-lg font-semibold text-grey-900">Boxes</h3>
              <img src={iconPlus} alt="" className="cursor-pointer" />
            </div>
            <ul className="flex flex-col">
              <li className="dashboard__sidebar__nav-list">
                <img
                  src={iconEmojiFire}
                  alt="icon-emoji-fire"
                  onClick={() => {
                    setShowProductEnquiriesEmojiModal(false);
                    setShowMarketingLeadsEmojiModal(false);
                    setShowPotentialLeadsEmojiModal(
                      !showPotentialLeadsEmojiModal
                    );
                  }}
                />
                <span className="text-base-1 font-medium text-grey-900">
                  Potential Leads
                </span>
              </li>
              {showPotentialLeadsEmojiModal && <EmojiModal className="mt-8" />}
              <li className="dashboard__sidebar__nav-list">
                <img
                  src={iconRocket}
                  alt="icon-rocket"
                  onClick={() => {
                    setShowProductEnquiriesEmojiModal(false);
                    setShowPotentialLeadsEmojiModal(false);
                    setShowMarketingLeadsEmojiModal(
                      !showMarketingLeadsEmojiModal
                    );
                  }}
                />
                <span className="text-base-1 font-medium text-grey-900">
                  Marketing Leads
                </span>
              </li>
              {showMarketingLeadsEmojiModal && <EmojiModal className="mt-75" />}
              <li className="dashboard__sidebar__nav-list">
                <img
                  src={iconBall}
                  alt="icon-ball"
                  onClick={() => {
                    setShowPotentialLeadsEmojiModal(false);
                    setShowMarketingLeadsEmojiModal(false);
                    setShowProductEnquiriesEmojiModal(
                      !showProductEnquiriesModal
                    );
                  }}
                />
                <span className="text-base-1 font-medium text-grey-900">
                  Product Enquiries
                </span>
              </li>
              {showProductEnquiriesModal && <EmojiModal className="mt-115" />}
            </ul>
          </div>
          <div className="bg-grey-400 my-6" style={{ height: 1 }}></div>
          <div>
            <div className="flex items-center justify-between pb-2">
              <h3 className="text-lg font-semibold text-grey-900">Channels</h3>
              <img src={iconPlus} alt="" />
            </div>

            <ChannelProfileList lists={data} />
            {/* <Link
                  to="/dashboard-website-chatbot"
                  className="dashboard__sidebar__nav-list"
                >
                  <figure className="">
                    <svg width="18" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M18 8.13559V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V8.13559H18ZM15.5 3C16.8807 3 18 4.11929 18 5.5V6.91525H2V5.5C2 4.11929 3.11929 3 4.5 3H15.5ZM4.44444 4.27119C4.07625 4.27119 3.77778 4.57852 3.77778 4.95763C3.77778 5.33674 4.07625 5.64407 4.44444 5.64407C4.81263 5.64407 5.11111 5.33674 5.11111 4.95763C5.11111 4.57852 4.81263 4.27119 4.44444 4.27119ZM6.66667 4.27119C6.29848 4.27119 6 4.57852 6 4.95763C6 5.33674 6.29848 5.64407 6.66667 5.64407C7.03486 5.64407 7.33333 5.33674 7.33333 4.95763C7.33333 4.57852 7.03486 4.27119 6.66667 4.27119Z"
                        fill="#4C5254"
                      />
                    </svg>
                  </figure>
                  <p className="text-base-1 font-medium text-grey-900">
                    Website Chatbot
                  </p>
                </Link> */}
          </div>
        </div>
        <section className="dashboard__main">
          <div className="dashboard__main__header flex">
            <div className="flex items-center dashboard__main__header__section-left">
              <figure
                className="avatar-container avatar-container--contain"
                style={{
                  width: 52,
                  height: 52,
                  padding: 10,
                  backgroundColor: "#FFDCBB",
                }}
              >
                {/* <img src={item.profilePic} alt="" className="cursor-pointer" /> */}
                <p className="font-bold text-blue-900">HS</p>
                <div className="avatar-container__sup-icon">
                  {/* <img
                src={item.subIcon}
                alt=""
                className="cursor-pointer"
              /> */}
                </div>
              </figure>
              <div className="ml-5">
                <h3 className="text-grey-900 text-xl font-semibold">
                  Hunley Weddings
                </h3>
                <p className="text-base-1 font-medium text-primary">
                  Manage notifications
                </p>
              </div>
            </div>
            <div
              className={`flex items-center dashboard__main__header__section-right ${
                !showSearchInput ? "justify-end" : ""
              }`}
            >
              <div className="search-container">
                {!showSearchInput ? (
                  <img
                    onClick={() => setShowSearchInput(true)}
                    src={iconSearch}
                    alt="icon-search"
                    className="cursor-pointer"
                    style={{ height: 18, width: 18 }}
                  />
                ) : (
                  <div>
                    <div className="input-field--prepend">
                      <div
                        className={`input-field input-field--lg input-field--prepend no-hover ${
                          isInputFocused ? "input-field--prepend--focus" : ""
                        }`}
                      >
                        <img src={search} alt="" className="mr-3 ml-2" />
                        <input
                          onFocus={() => setIsInputFocused(true)}
                          onBlur={() => setIsInputFocused(false)}
                          placeholder="search"
                          value={searchInputValue}
                          onChange={(e) => setSearchInputValue(e.target.value)}
                        />
                      </div>
                      <div
                        className="modal__close-icon"
                        onClick={() => setShowSearchInput(false)}
                      >
                        <img className="ml-3" src={searchCloseIcon} alt="" />
                      </div>
                    </div>
                    {searchInputValue.length >= 1 && (
                      <div className="input-field__search-results">
                        <span className="font-normal text-grey-600 ml-4">
                          Searching...
                        </span>
                        {dummyData.map((item) => {
                          return (
                            <div className="flex">
                              <img
                                src={iconSearch}
                                alt="file"
                                className="mr-3 ml-4"
                              />
                              <p className="font-normal text-grey-800">
                                {item.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <code
                className="bg-grey-400 mx-4"
                style={{ width: 1, height: 26 }}
              ></code>
              <img
                src={iconSettings}
                alt="icon-settings"
                className="cursor-pointer"
                style={{ height: 18, width: 17 }}
              />
            </div>
          </div>
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-auto">
              <h2 className="text-lg font-semibold text-grey-900">Messages</h2>
              <div className="mt-15">
                <label className="text-xs font-medium text-grey-700 uppercase">
                  New
                </label>
                <ul className="">
                  <li
                    className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{ width: 48, height: 48 }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900">John Leon</h3>
                      <div className="flex items-center whitespace-nowrap">
                        <p className="text-base-1 text-grey-800 truncate">
                          You are now connected wedding lorem ipsum text
                        </p>
                        <code
                          className="flex-shrink-0 bg-grey-500 rounded-full"
                          style={{
                            width: 4,
                            height: 4,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        ></code>
                        <span className="text-sm text-grey-700">2d</span>
                      </div>
                    </div>
                  </li>
                  <li
                    className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{ width: 48, height: 48 }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900">
                        Madison Inouye
                      </h3>
                      <div className="flex items-center whitespace-nowrap">
                        <p className="text-base-1 text-grey-800 truncate">
                          Hey
                        </p>
                        <code
                          className="flex-shrink-0 bg-grey-500 rounded-full"
                          style={{
                            width: 4,
                            height: 4,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        ></code>
                        <span className="text-sm text-grey-700">2d</span>
                      </div>
                    </div>
                  </li>
                  <li
                    className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{ width: 48, height: 48 }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900">John Leon</h3>
                      <div className="flex items-center whitespace-nowrap">
                        <p className="text-base-1 text-grey-800 truncate">
                          You are now connected lorem ipsum
                        </p>
                        <code
                          className="flex-shrink-0 bg-grey-500 rounded-full"
                          style={{
                            width: 4,
                            height: 4,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        ></code>
                        <span className="text-xs text-grey-700">2d</span>
                      </div>
                    </div>
                  </li>
                  <li
                    className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{ width: 48, height: 48 }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900">Lisa Fotios</h3>
                      <div className="flex items-center whitespace-nowrap">
                        <p className="text-base-1 text-grey-800 truncate">
                          4 new messages
                        </p>
                        <code
                          className="flex-shrink-0 bg-grey-500 rounded-full"
                          style={{
                            width: 4,
                            height: 4,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        ></code>
                        <span className="text-sm text-grey-700">2d</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="mt-15">
                <label className="text-xs font-medium text-grey-700 uppercase">
                  Seen
                </label>
                <ul className="">
                  <li
                    className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{ width: 48, height: 48 }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900">John Leon</h3>
                      <div className="flex items-center whitespace-nowrap">
                        <p className="text-base-1 text-grey-800 truncate">
                          You are now connected wedding lorem ipsum text
                        </p>
                        <code
                          className="flex-shrink-0 bg-grey-500 rounded-full"
                          style={{
                            width: 4,
                            height: 4,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        ></code>
                        <span className="text-sm text-grey-700">2d</span>
                      </div>
                    </div>
                  </li>
                  <li
                    className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{ width: 48, height: 48 }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900">
                        Madison Inouye
                      </h3>
                      <div className="flex items-center whitespace-nowrap">
                        <img
                          src={iconForward}
                          alt="icon-forward"
                          className="mr-2"
                        />
                        <p className="text-base-1 text-grey-800 truncate">
                          Hey
                        </p>
                        <code
                          className="flex-shrink-0 bg-grey-500 rounded-full"
                          style={{
                            width: 4,
                            height: 4,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        ></code>
                        <span className="text-sm text-grey-700">2d</span>
                      </div>
                    </div>
                  </li>
                  <li
                    className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{ width: 48, height: 48 }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900">John Leon</h3>
                      <div className="flex items-center whitespace-nowrap">
                        <p className="text-base-1 text-grey-800 truncate">
                          You are now connected lorem ipsum
                        </p>
                        <code
                          className="flex-shrink-0 bg-grey-500 rounded-full"
                          style={{
                            width: 4,
                            height: 4,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        ></code>
                        <span className="text-sm text-grey-700">2d</span>
                      </div>
                    </div>
                  </li>
                  {/* <li
                    className="flex items-center py-2 hover:bg-grey-90 cursor-pointer"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{ width: 48, height: 48 }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900">Lisa Fotios</h3>
                      <div className="flex items-center whitespace-nowrap">
                        <p className="text-base-1 text-grey-800 truncate">
                          4 new messages
                        </p>
                        <code
                          className="flex-shrink-0 bg-grey-500 rounded-full"
                          style={{
                            width: 4,
                            height: 4,
                            marginLeft: 10,
                            marginRight: 5,
                          }}
                        ></code>
                        <span className="text-sm text-grey-700">2d</span>
                      </div>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className={`dashboard__main__body__section-right chat-message-container`}>
              <div className="chat-message-header flex items-center">
                <div
                  className="inline-flex items-center border border-grey-400"
                  style={{
                    borderRadius: 30,
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 4,
                    paddingRight: 15,
                  }}
                >
                  <figure
                    className="avatar-container avatar-container--full"
                    style={{ width: 28, height: 28 }}
                  >
                    <img src={avatarPic} alt="avatar-pic" />
                  </figure>
                  <p
                    className="text-13 font-medium ml-2"
                    style={{ color: "#292929" }}
                  >
                    Lisa Fotios
                  </p>
                  <img src={arrowRight} alt="arrow-right" className="ml-2" />
                </div>
                <div className="flex items-center gap-2 ml-auto">
                  <button
                    className="btn btn--primary btn--sm gap-2"
                    style={{
                      borderRadius: 6,
                      padding: "2px 11px",
                      fontSize: 13,
                      fontWeight: "500",
                    }}
                  >
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99743 2.50257H3.5C3.34825 2.50257 3.19913 2.51384 3.05343 2.53558C3.23782 1.80097 3.90257 1.25701 4.69437 1.25701H9.84937C11.2755 1.25701 12.4315 2.41309 12.4315 3.83919V8.99418C12.4315 9.86735 11.7701 10.586 10.9209 10.6764C10.971 10.459 10.9974 10.2326 10.9974 10V5.50257C10.9974 3.84572 9.65429 2.50257 7.99743 2.50257ZM0.5 5.50257C0.5 4.41956 1.07388 3.47059 1.93413 2.94316C1.93716 1.42129 3.1718 0.188526 4.69437 0.188526H9.84937C11.8656 0.188526 13.5 1.82298 13.5 3.83919V8.99418C13.5 10.5186 12.2642 11.7544 10.7398 11.7544H10.4312C9.88634 12.509 8.99923 13 7.99743 13H3.5C1.84314 13 0.5 11.6569 0.5 10L0.5 5.50257ZM5.01124 8.30863C4.80384 8.09877 4.80583 7.76051 5.01569 7.5531L7.07225 5.52063H6.07702C5.78197 5.52063 5.54278 5.28144 5.54278 4.98639C5.54278 4.69133 5.78197 4.45214 6.07702 4.45214H8.35425C8.37216 4.45151 8.39009 4.45178 8.40797 4.45294C8.53923 4.46012 8.66365 4.51552 8.75697 4.60907C8.8572 4.70953 8.91334 4.84574 8.913 4.98765L8.90757 7.28396C8.90688 7.57902 8.66712 7.81764 8.37207 7.81694C8.07702 7.81624 7.83839 7.57649 7.83909 7.28144L7.8415 6.26264L5.76677 8.31308C5.5569 8.52048 5.21865 8.51849 5.01124 8.30863Z"
                        fill="white"
                      />
                    </svg>
                    Move to
                  </button>
                  <div>
                    <button
                      onClick={() => setShowAssignToUsersList(true)}
                      className="btn btn--primary gap-2"
                      style={{
                        borderRadius: 6,
                        padding: "2px 11px",
                        fontSize: 13,
                        fontWeight: "500",
                      }}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.24016 7.00016C1.24016 3.819 3.819 1.24016 7.00016 1.24016C10.1813 1.24016 12.7602 3.819 12.7602 7.00016C12.7602 8.27684 12.3448 9.45652 11.6418 10.4114C11.6008 9.4488 10.8232 8.575 9.72928 8.575H4.47417C3.30989 8.575 2.46516 9.56838 2.53515 10.6393C1.72564 9.64727 1.24016 8.38041 1.24016 7.00016ZM7.00016 0.160156C3.22253 0.160156 0.160156 3.22253 0.160156 7.00016C0.160156 10.7778 3.22253 13.8402 7.00016 13.8402C10.7778 13.8402 13.8402 10.7778 13.8402 7.00016C13.8402 3.22253 10.7778 0.160156 7.00016 0.160156ZM9.36262 5.425C9.36262 6.72977 8.30489 7.7875 7.00012 7.7875C5.69535 7.7875 4.63762 6.72977 4.63762 5.425C4.63762 4.12023 5.69535 3.0625 7.00012 3.0625C8.30489 3.0625 9.36262 4.12023 9.36262 5.425Z"
                          fill="white"
                        />
                      </svg>
                      Unassigned
                    </button>

                    <div
                      ref={node}
                      className={`dropdown-menu mt-3 ${
                        showAssignToUsersList ? "open" : ""
                      }`}
                      style={{
                        right: "25px",
                        left: "auto",
                        width: "300px",
                        padding: "10px 10px 6px 10px",
                      }}
                    >
                      <InputField
                        placeholder="Search"
                        value={inputValue}
                        onChange={(e) => {
                          setInputValue(e.target.value);
                        }}
                      />
                      <div className="dropdown-menu__overflow-y-scroll mt-2">
                        <p className="text-13 text-grey-700 px-3">Assign To</p>
                        {users.map((user, index) => {
                          return (
                            <div key={index} className="dropdown-menu__list">
                              <img
                                className="mr-3"
                                src={avatarPic}
                                style={{ width: 30, height: 30 }}
                              />
                              <p>{user.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat-message-body">
                <div className="chat-message-list flex">
                  <figure
                    className="avatar-container avatar-container--full"
                    style={{ width: 32, height: 32 }}
                  >
                    <img src={avatarPic} alt="avatar-pic" />
                  </figure>
                  <div className="" style={{ marginLeft: 6 }}>
                    <div>
                      <div
                        className="inline-block border border-grey-400 text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        Hi dude...
                      </div>
                    </div>
                    <div>
                      <div
                        className="inline-block border border-grey-400 text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 6,
                        }}
                      >
                        How is your job?
                      </div>
                    </div>
                    <div>
                      <div
                        className="inline-block border border-grey-400 text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 6,
                        }}
                      >
                        Are you doing anything fun this weekend at home?
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-xs text-grey-700"
                        style={{ marginTop: 6 }}
                      >
                        2d
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-message-list chat-message-list--sender flex">
                  <figure
                    className="avatar-container avatar-container--full"
                    style={{ width: 32, height: 32 }}
                  >
                    <img src={avatarPic} alt="avatar-pic" />
                  </figure>
                  <div className="" style={{ marginRight: 6 }}>
                    <div>
                      <div
                        className="inline-block  text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          backgroundColor: "#F4F9F9",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        Hi dude...
                      </div>
                    </div>
                    <div>
                      <div
                        className="inline-block  text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 6,
                          backgroundColor: "#F4F9F9",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        How is your job?
                      </div>
                    </div>
                    <div>
                      <div
                        className="inline-block text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                          marginTop: 6,
                          backgroundColor: "#F4F9F9",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        Are you doing anything fun this weekend at home?
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-xs text-grey-700"
                        style={{ marginTop: 6 }}
                      >
                        2d
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-message-list flex">
                  <figure
                    className="avatar-container avatar-container--full"
                    style={{ width: 32, height: 32 }}
                  >
                    <img src={avatarPic} alt="avatar-pic" />
                  </figure>
                  <div className="" style={{ marginLeft: 6 }}>
                    <div>
                      <div
                        className="inline-block border border-grey-400 text-base-1 text-grey-900"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        Tristique bibendum finibus nec, consectetu.
                      </div>
                    </div>
                    <div>
                      <div
                        className="text-xs text-grey-700"
                        style={{ marginTop: 6 }}
                      >
                        Now
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ChatFooterInputBox />
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default ChannelInstagramConversation;
