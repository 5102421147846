import React, { useState, useRef } from "react";
import iconGroupChannel from "../../assets/icons/Group132462.svg";
import iconGroupCardDesign from "../../assets/icons/Group13220.svg";
import iconTeamAccess from "../../assets/icons/Group13230.svg";
import iconAutomate from "../../assets/icons/Group13224.svg";
import iconBoxForms from "../../assets/icons/Group13237.svg";
import iconImportContacts from "../../assets/icons/Group13225.svg";
import iconIntegrations from "../../assets/icons/Group13229.svg";
import iconBoringStuff from "../../assets/icons/Group13245.svg";
import SidebarExpanded from "../Common/SidebarExpanded";
import SidebarLinks from "../Common/SidebarLinks";
import GeneralSettingsHedder from "../Common/GeneralSettingsHedder";
import tourVideoPlaceholderPic from "../../assets/images/product-tour-placeholder.png";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import iconCloudUpload from "../../assets/icons/icon-upload-cloud.svg";
import iconDownload from "../../assets/icons/icon-new-download.svg";
import iconUpload from "../../assets/icons/icon-upload.svg";
import iconRollBack from "../../assets/icons/icon-rollback.svg";
import { Link } from "react-router-dom";
import ProgressBar from "../Common/ProgressBar";
import iconStatusCheck from "../../assets/icons/icon-check.svg";
import iconBorderCheckCircle from "../../assets/icons/icon-border-check-circle.svg";


const BoxSettingsUploadedModal = ({showUploadedModal, setShowUploadedModal}) => {
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });
  const [modalShow, hideModalShow] = useState(true);

  const [type, setType] = useState(false);
  const snackbarRef = useRef(null);

  const handleChange = (event) => {
    snackbarRef.current.show();
    event.target.checked ? setType("success") : setType("fail");
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
		<div className={`modal modal--hide-request ${showUploadedModal ? 'show' : ''}`}>
			<div className="modal__container">
				<div className="modal__header">
					<h2 className="modal__heading pr-5">Upload contacts</h2>
					<div
						className="modal__close-icon"
						onClick={() => setShowUploadedModal(false)}
					>
						<img src={modalCloseIcon} alt="" />
					</div>
				</div>
				<div className="modal__body mt-10 pb-8">
					<img
						src={iconBorderCheckCircle}
						alt="icon-border-check-circle"
						className="mx-auto"
					/>
					<p className="text-lg font-semibold text-center mt-4">
						500 Contacts successfully uploaded.
					</p>
					<div className="flex justify-end mt-20 pr-30">
						<button className="btn btn--primary btn--wide-md text-base-1" onClick={() => setShowUploadedModal(false)}>Done</button>
					</div>
				</div>
			</div>
		</div>
  );
};
export default BoxSettingsUploadedModal;