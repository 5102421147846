import React, { useState, useEffect } from "react";
import SidebarLinksNew from "./Common/SideBarLinksNew";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import BoxInfo from "./modal/BoxInfo";
import BoxTemplateHeader from "./Common/BoxTemplateHeader";
import iconTemplateHeader from "../assets/icons/icon-template-heder.svg";
import deleteIcon from "../assets/icons/icon-Delete.svg";
import iconMoreRound from "../assets/icons/icon-more-round.svg";

const BoxTemplatePreview = () => {
  const [openBoxInfo, setOpenBoxInfo] = useState(false);
  const [openBoxTemplate, setOpenBoxTemplate] = useState(false);
  const [showDelete, setShowDelete] = useState(0);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = () => {
    setShowDelete(0);
  };

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew setOpenBoxInfo={setOpenBoxInfo} />
        <section className="dashboard__main">
          <BoxTemplateHeader
            title="Recruitment"
            isPreview={true}
            HeaderIcon={() => (
              <figure
                className="avatar-container avatar-container--contain"
                style={{ width: 50, height: 50 }}
              >
                <img
                  src={iconTemplateHeader}
                  alt="header-icon"
                  style={{ width: 28, height: 28 }}
                />
              </figure>
            )}
          />
          <div className="box-card-wrapper py-5 px-6">
            <div className={`box-card-container mr-5 clip-path`}>
              <div
                className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white"
                style={{
                  width: 40,
                  height: 24,
                  border: "1px solid rgba(4, 145, 171, 0.3)",
                  borderRadius: "20px",
                }}
              >
                £0
              </div>
              <div className="flex justify-between mt-2 ml-3">
                <div className="font-semibold text-lg">Incoming</div>
                <div className="flex items-center">
                  <div
                    className="cursor-pointer relative"
                    onClick={() => setShowDelete(1)}
                  >
                    <img
                      src={iconMoreRound}
                      alt=""
                      style={{ width: 16, height: 4 }}
                    />
                    <div
                      className={`dropdown-menu dropdown-menu--more ${
                        showDelete === 1 ? "open" : ""
                      }`}
                      style={{ top: 10 }}
                    >
                      <div className="dropdown-menu__list red-text">
                        <img
                          src={deleteIcon}
                          alt=""
                          className="mr-2"
                          style={{ width: 16, height: 16 }}
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div
                  className="box-card box-card box-card--empty"
                  style={{ height: 78 }}
                >
                  <div className="text-sm text-center flex items-center px-12">
                    All new enquiries will arrive in incoming by default giving
                    you a chance to see it
                  </div>
                </div>
              </div>
            </div>
            <div className={`box-card-container mr-5 clip-path`}>
              <div
                className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white"
                style={{
                  width: 40,
                  height: 24,
                  border: "1px solid rgba(4, 145, 171, 0.3)",
                  borderRadius: "20px",
                }}
              >
                £0
              </div>
              <div className="flex justify-between mt-2 ml-3">
                <div className="font-semibold text-lg">Contacting</div>
                <div className="flex items-center">
                  <div
                    className="cursor-pointer relative"
                    onClick={() => setShowDelete(2)}
                  >
                    <img
                      src={iconMoreRound}
                      alt=""
                      style={{ width: 16, height: 4 }}
                    />
                    <div
                      className={`dropdown-menu dropdown-menu--more ${
                        showDelete === 2 ? "open" : ""
                      }`}
                      style={{ top: 10 }}
                    >
                      <div className="dropdown-menu__list red-text">
                        <img
                          src={deleteIcon}
                          alt=""
                          className="mr-2"
                          style={{ width: 16, height: 16 }}
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div
                  className="box-card box-card box-card--empty"
                  style={{ height: 78 }}
                >
                  <div className="text-sm text-center flex items-center px-12">
									Drag enquiries to this stage once you have made contact
                  </div>
                </div>
              </div>
            </div>
            <div className={`box-card-container mr-5 clip-path`}>
              <div
                className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white"
                style={{
                  width: 40,
                  height: 24,
                  border: "1px solid rgba(4, 145, 171, 0.3)",
                  borderRadius: "20px",
                }}
              >
                £0
              </div>
              <div className="flex justify-between mt-2 ml-3">
                <div className="font-semibold text-lg">Won</div>
                <div className="flex items-center">
                  <div
                    className="cursor-pointer relative"
                    onClick={() => setShowDelete(3)}
                  >
                    <img
                      src={iconMoreRound}
                      alt=""
                      style={{ width: 16, height: 4 }}
                    />
                    <div
                      className={`dropdown-menu dropdown-menu--more ${
                        showDelete === 3 ? "open" : ""
                      }`}
                      style={{ top: 10 }}
                    >
                      <div className="dropdown-menu__list red-text">
                        <img
                          src={deleteIcon}
                          alt=""
                          className="mr-2"
                          style={{ width: 16, height: 16 }}
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div
                  className="box-card box-card box-card--empty"
                  style={{ height: 78 }}
                >
                  <div className="text-sm text-center flex items-center px-12">
										Drag enquiries to this stage once you have made contact
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <BoxInfo
        setOpenBoxInfo={setOpenBoxInfo}
        openBoxInfo={openBoxInfo}
        setOpenBoxTemplate={setOpenBoxTemplate}
      />
    </main>
  );
};

export default BoxTemplatePreview;
