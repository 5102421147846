import React, { useState } from "react";
import iconClose from "../../assets/icons/icon-close-white.svg";
import CustomToolTip from "./CustomToolTip";

const EmailConversationUserName = ({ name }) => {
  const [showRemoveIcon, setShowRemoveIcon] = useState(false);
  return (
    <div
      className="item__user-name user-name flex items-center"
      onMouseEnter={() => setShowRemoveIcon(true)}
      onMouseLeave={() => setShowRemoveIcon(false)}
    >
      <CustomToolTip position="top" tooltipText="example@email.com">
        <p className="text-13 text-grey-900">{name}</p>
      </CustomToolTip>

      {showRemoveIcon && (
        <CustomToolTip
          position="top"
          tooltipStyle={{ marginLeft: "-25px" }}
          tooltipText="Remove"
        >
          <img
            className="ml-1 cursor-pointer close-icon"
            src={iconClose}
            alt="close"
          />
        </CustomToolTip>
      )}
    </div>
  );
};

export default EmailConversationUserName;
