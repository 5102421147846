import React, { useState } from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import { useHistory } from "react-router-dom";
import avatarIcon1 from "../../assets/icons/icon-avatar-image-2.svg";
import avatarIcon2 from "../../assets/icons/icon-avatar-image-1.svg";
import backIcon from "../../assets/icons/icon-green-back.svg";

const RequestToJoin = ({goToChannels, setGoToChannels, setOpenSuccessModal}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [clickedName, setClickedName] = useState("");
  const [clickedImage, setClickedImage] = useState(null);

  let history = useHistory();

  const goBack = () => {
    history.push("/channel-hide-or-request");
  };

  return (
    <div className={`modal modal--type2 ${goToChannels ? 'show' : ''}`}>
      <div className="modal__container">
        <div className="modal__header">
          <h2 className="modal__heading">Send a request to join</h2>
          <div className="modal__close-icon" onClick={() => setGoToChannels(false)}>
            <img src={modalCloseIcon} alt="" />
          </div>
        </div>
        {!isClicked ? (
          <div className="modal__body px-8 pb-8">
            <p className="text-sm text-grey-700 font-medium mt-4">
              Select the admin to send the request to
            </p>
            <div
              className="modal__invite-card mt-2"
              onClick={() => {
                setIsClicked(true);
                setClickedName("Charles Wundeng");
                setClickedImage(avatarIcon1);
              }}
            >
              <div className="flex items-center">
                <figure
                  className="avatar-container mr-3"
                  style={{
                    width: 32,
                    height: 32,
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={avatarIcon1} alt="" className="" />
                </figure>
                <div className="text-sm font-medium">Charles Wundeng</div>
              </div>
              <div className="modal__invite-link">Select</div>
            </div>
            <div
              className="modal__invite-card"
              style={{ marginTop: 2 }}
              onClick={() => {
                setIsClicked(true);
                setClickedName("Madison Inouye");
                setClickedImage(avatarIcon2);
              }}
            >
              <div className="flex items-center">
                <figure
                  className="avatar-container mr-3"
                  style={{
                    width: 32,
                    height: 32,
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={avatarIcon2} alt="" className="" />
                </figure>
                <div className="text-sm font-medium">Madison Inouye</div>
              </div>
              <div className="modal__invite-link">Select</div>
            </div>
            <div className="flex justify-end mt-12">
              <button className="btn btn--primary btn--wide-md text-base-1">
                Send a request
              </button>
            </div>
          </div>
        ) : (
          <div className="modal__body pb-8">
            <div
              className="flex items-center cursor-pointer pl-30 pt-3 pr-30"
              style={{ borderBottom: "2px solid #E6E4E1", paddingBottom: 10 }}
              onClick={() => setIsClicked(false)}
            >
              <img
                src={backIcon}
                alt=""
                style={{ width: 10, height: 8 }}
                className="mr-1"
              />
              <span className="text-primary text-13 font-medium">Back</span>
            </div>
            <div
              className="flex items-center pl-30 py-9 pr-30"
              style={{ borderBottom: "1px solid #E6E4E1" }}
            >
              <figure
                className="avatar-container mr-3"
                style={{
                  width: 26,
                  height: 26,
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={clickedImage} alt="" className="" />
              </figure>
              <div className="text-sm font-semibold">{clickedName}</div>
            </div>
            <div className="pl-30 pr-30 pt-5">
              <textarea
                className="input-field input-field--md"
                placeholder="Hey Charles, I'd like to join this channel"
                style={{ height: 90 }}
              />
            </div>
            <div className="flex justify-end mt-168 pr-30">
              <button className="btn btn--primary btn--wide-md" onClick={() => {setGoToChannels(false);setOpenSuccessModal(true)}}>
                Send a request
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestToJoin;
