import React, { useState } from "react";
import { Link } from "react-router-dom";
import iconSearch from "../assets/icons/icon-search.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import { useHistory } from "react-router-dom";
import ChatPreviewListSample from "./ChatPreviewListSample";
import iconHelpVideo from "../assets/icons/icon-help-video.svg";
import HelperVideo from "./Common/HelperVideo";
import ChannelConnectLinks from "./Common/ChannelConnectLinks";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";

const ConnectWebsiteForm = () => {
  let history = useHistory();
  const [activeScreen, setActiveScreen] = useState(1);
  const nextScreen = () => {
    history.push("/channel-connected-page");
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew />
        <section className="dashboard__main">
          <GeneralSettingsHedder text="Channel Settings" />
          <div
            className="dashboard__main__body"
            style={{ height: "calc(100% - 79px)" }}
          >
            <div className="dashboard__main__body__section-left overflow-overlay">
              <ChannelConnectLinks
                show={true}
                activeScreen={5}
                setActiveScreen={setActiveScreen}
              />
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new">
                <div>
                  <h2 className="font-semibold text-lg">
                    Connect your Website forms
                  </h2>
                  <p className="text-grey-800 text-sm mt-3">
                    By connecting your website forms you will be able to
                    receiveand reply to form submissions, right here on this
                    channel.
                  </p>
                </div>
                <div className="mt-9 pt-5 px-6 pb-6 bg-grey-300 rounded-lg border border-grey-400">
                  <div className="flex justify-between border-b border-grey-400 pb-3">
                    <h4 className="text-15 text-grey-800 font-semibold">
                      To connect this channel, turn on the switch 👉
                    </h4>
                    <div className="toggle-slider">
                      <input
                        type="checkbox"
                        id="webform-connect"
                        className="toggle-slider__checkbox"
                        name="checkedA"
                      />
                      <label
                        htmlFor="webform-connect"
                        className="toggle-slider__label"
                      >
                        <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                      </label>
                    </div>
                  </div>
                  <p className="mt-5 text-sm text-grey-800">
                    Once turned on, copy this code and place it on the pages
                    that you want to receive webform submissions from.
                  </p>
                  <textarea
                    name=""
                    rows="2"
                    cols="5"
                    placeholder=""
                    value={`<script type="text/javascript" src="https://launcher.enquirybot.com/index.js" data-bot-id="499f969c-1d63-4bc5-9a05-5c8246ba71c8"`}
                    style={{ padding: "15px 20px", fontWeight: "normal" }}
                    className="input-field input-field__textarea border text-13 mt-4 resize-none"
                  ></textarea>
                  <button
                    className="btn btn--primary btn--xs mt-1"
                    onClick={nextScreen}
                  >
                    Copy code
                  </button>
                </div>
                <div className="mt-10">
                  <h4 className="font-semibold">Where do I add the code?</h4>
                  <p className="text-sm text-grey-800 mt-2">
                    {
                      "The easiest way is to add the code to all pages just above the </body> tag. Once added you can setup rules to only receive enquiries from certain pages on your website."
                    }
                  </p>
                </div>
                <div className="mt-8">
                  <HelperVideo />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default ConnectWebsiteForm;
