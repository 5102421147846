import React, { useState, forwardRef, useImperativeHandle } from "react";
import iconCheck from "../../assets/icons/Group5569.svg";
import iconCross from "../../assets/icons/Group12629.svg";


const Snackbar = forwardRef((props, ref) => {
    const [showSnackbar, setShowSnackbar] = useState(false);

    useImperativeHandle(ref, () => ({
        show() {
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
        },
    }));

    return (
        <div
            className="snackbar items-center"
            id={showSnackbar ? "show" : "hide"}
            style={{
                backgroundColor: "black",
                color: "white",
            }}
        >
            <div className="symbol">
                {props.type === "success" ? <img src={iconCheck} alt=''/> : <img src={iconCross} alt=''/>}
            </div>
            <div className="message">{props.message}</div>
        </div>
    );
});

export default Snackbar;