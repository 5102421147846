import React from "react";
import { Link } from "react-router-dom";
import iconSearch from "../assets/icons/icon-search.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import SidebarLinks from "./Common/SidebarLinks";
import SidebarExpanded from "./Common/SidebarExpanded";
import ChannelHeader from "./Common/ChannelHeader";
import { useHistory } from "react-router-dom";
import ChatPreviewListSample from "./ChatPreviewListSample";
import iconHelpVideo from "../assets/icons/icon-help-video.svg";
import HelperVideo from "./Common/HelperVideo";

const DashboardContactForm = () => {
  let history = useHistory();
  const nextScreen = () => {
    history.push("/dashboard-email-address");
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />
        <section className="dashboard__main">
          <ChannelHeader
            text={"Contact Form"}
            linkText="Settings & Notifications"
            HeaderIcon={() => (
              <figure className="avatar-container">
                <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                    fill="#2B3639"
                  />
                </svg>
              </figure>
            )}
          />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left">
              <ChatPreviewListSample />
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new">
                <div>
                  <h2 className="font-semibold text-lg">
                    Connect your Contact forms
                  </h2>
                  <p className="text-grey-800 text-sm mt-2">
                    By connecting your contact forms you will be able to receive
                    and reply to form submissions, right here on this channel.
                  </p>
                </div>
                <div className="mt-8">
                  <h4 className="font-bold">
                    To connect, copy this code and add it to your website
                  </h4>
                  <textarea
                    name=""
                    rows="2"
                    cols="5"
                    placeholder=""
                    value={`<script type="text/javascript" src="https://launcher.enquirybot.com/index.js" data-bot-id="499f969c-1d63-4bc5-9a05-5c8246ba71c8"></script>`}
                    style={{ padding: "15px 20px", fontWeight: "normal" }}
                    className="input-field input-field__textarea border text-13 mt-3 resize-none"
                  ></textarea>
                  <button className="btn btn--primary btn--xs mt-1">
                    Copy code
                  </button>
                </div>
                <div className="mt-8">
                  <h4 className="font-semibold">Where do I add the code?</h4>
                  <p className="text-sm text-grey-800 mt-2">
                    The easiest way is to add the code to all pages just above
                    the tag. Once added you can setup rules to only receive
                    enquiries from certain pages on your website.{" "}
                  </p>
                </div>
                <div className="mt-8">
                  <HelperVideo />
                </div>
                <div className="flex justify-end mt-8">
                  <button className="btn btn--primary btn--sm text-base-1 min-w-100">Next</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default DashboardContactForm;
