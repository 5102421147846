import React, { useState } from "react";
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import searchIcon from "../../assets/icons/icon-search-grey.svg";
import pdfIcon from "../../assets/icons/icon-file-upload-pdf.svg";
import pngIcon from "../../assets/icons/icon-file-upload-png.svg";
import docxIcon from "../../assets/icons/icon-file-upload-docx.svg";
import closeWhite from "../../assets/icons/icon-close-white.svg";
import InputField from "../Common/InputField";

const FileLibrary = ({ setShowFileLibrary, showFileLibrary }) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(true);
  const dummyData = [
    {
      name: "My photos.png",
      type: "png",
    },
    {
      name: "My docs.docx",
      type: "png",
    },
    {
      name: "Profile documents.pdf",
      type: "png",
    },
  ];
  const fileIcons = {
    pdf: pdfIcon,
    png: pngIcon,
    docs: docxIcon,
    docx: docxIcon,
  };

  return (
    <div className={`modal ${showFileLibrary ? "show" : ""}`}>
      <div className="modal__container">
        <div className="modal__header">
          <h2 className="modal__heading pr-5">File library</h2>
          <div className="modal__close-icon">
            <img
              src={modalCloseIcon}
              alt=""
              onClick={() => setShowFileLibrary(false)}
            />
          </div>
        </div>
        <div className="modal__body pt-5 pr-30 pl-30 pb-30">
          {!showSearchInput ? (
            <>
              <div className="flex justify-between">
                <div className="font-semibold">Select files</div>
                <div onClick={() => setShowSearchInput(true)}>
                  <img
                    src={searchIcon}
                    alt=""
                    className="cursor-pointer mr-1"
                  />
                </div>
              </div>
              <ul className="mt-15">
                <li className="select-files-list">
                  <div className="flex items-center">
                    <img src={pdfIcon} alt="" />
                    <p className="text-sm font-medium ml-3">
                      Profile documents.pdf
                    </p>
                  </div>
                  <div className="flex items-center ml-auto custom-checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="custom-checkbox custom-checkbox--sm mr-2"
                      name=""
                    />
                  </div>
                </li>
                <li className="select-files-list">
                  <div className="flex items-center">
                    <img src={pngIcon} alt="" />
                    <p className="text-sm font-medium ml-3">My photos</p>
                  </div>
                  <div className="flex items-center ml-auto custom-checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="custom-checkbox custom-checkbox--sm mr-2"
                      name=""
                    />
                  </div>
                </li>
                <li className="select-files-list">
                  <div className="flex items-center">
                    <img src={pdfIcon} alt="" />
                    <p className="text-sm font-medium ml-3">
                      Profile documents.pdf
                    </p>
                  </div>
                  <div className="flex items-center ml-auto custom-checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="custom-checkbox custom-checkbox--sm mr-2"
                      name=""
                    />
                  </div>
                </li>
                <li className="select-files-list">
                  <div className="flex items-center">
                    <img src={docxIcon} alt="" />
                    <p className="text-sm font-medium ml-3">My docs.docx</p>
                  </div>
                  <div className="flex items-center ml-auto custom-checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="custom-checkbox custom-checkbox--sm mr-2"
                      name=""
                    />
                  </div>
                </li>
              </ul>
              <div className="flex items-center justify-end gap-3 mt-16">
                <button
                  className="btn btn--secondary btn--sm w-1/4"
                  onClick={() => setShowFileLibrary(false)}
                >
                  Cancel
                </button>
                <button className="btn btn--primary btn--sm w-1/4">
                  Attach
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="relative" style={{ minHeight: "250px" }}>
                <div className="input-field--prepend">
                  <InputField
                    value={inputVal}
                    onChange={(e) => setInputVal(e.target.value)}
                    placeholder="Search your file"
                    isMargin={true}
                  />
                  <div
                    className="modal__close-icon"
                    onClick={() => setInputVal("")}
                  >
                    <img
                      className="close-icon ml-3"
                      src={closeWhite}
                      alt=""
                      onClick={() => setInputVal("")}
                    />
                  </div>
                </div>
                <div
                  className={`dropdown-menu dropdown-menu--sm ${
                    inputVal.length >= 1 && "open"
                  }`}
                  style={{ top: 48, width: "96%" }}
                >
                  {dummyData.map((item) => {
                    let type = item.name.split(".").slice(-1)[0];
                    return (
                      <div className="dropdown-menu__list">
                        <img src={fileIcons[type]} alt="file" />
                        <p className="text-sm font-medium ml-3">{item.name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex items-center justify-end gap-3 mt-20">
                <button
                  className="btn btn--secondary btn--sm w-1/4"
                  onClick={() => setShowSearchInput(false)}
                >
                  Cancel
                </button>
                <button className="btn btn--primary btn--sm w-1/4">
                  Attach
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileLibrary;
