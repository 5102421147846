import React, { useState, useRef } from "react";
import modalCloseIcon from "../assets/icons/icon-modal-close.svg";
import iconCloudUpload from "../assets/icons/icon-upload-cloud.svg";

const BoxSettingsUploadContact = ({openUpload, setOpenUpload, setOpenUploadingModal}) => {
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });
  const [modalShow, hideModalShow] = useState(true);

  const [type, setType] = useState(false);
  const snackbarRef = useRef(null);

  const handleChange = (event) => {
    snackbarRef.current.show();
    event.target.checked ? setType("success") : setType("fail");
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
		<div className={`modal ${openUpload ? 'show' : ''}`}>
			<div className="modal__container">
				<div className="modal__header">
					<h2 className="modal__heading pr-5">Upload contacts</h2>
					<div
						className="modal__close-icon"
						onClick={() => setOpenUpload(false)}
					>
						<img src={modalCloseIcon} alt="" />
					</div>
				</div>

				<div className="modal__body px-8 pb-10">
					<div className="mt-8 mb-8 p-5 border border-dashed border-grey-400 rounded-xl">
						<div className="text-center">
							<div
								className="inline-flex items-center justify-center bg-grey-90 rounded-full"
								style={{ width: 70, height: 70 }}
							>
								<img alt="" src={iconCloudUpload}></img>
							</div>
						</div>
						<div className="text-center mt-4">
							<h3 className="text-lg font-semibold">
								Upload your CSV file
							</h3>
							<p className="font-normal text-grey-800 text-sm mt-2 mb-5">
								Drag and drop here or
								<span className="text-primary font-normal cursor-pointer" onClick={() => {setOpenUploadingModal(true); setOpenUpload(false)}}> browse</span>
							</p>
						</div>

						<hr className="text-grey-400 mx-8" />
						<div className="text-center px-10 mt-5">
							<p className="text-center font-normal text-grey-800 text-base-1">
								Use our spreadsheet template to match uploaded fields with
								enquirybox questions
							</p>
							<span className="inline-block text-sm text-primary font-semibold mt-2 cursor-pointer">
								Download template
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
  );
};

export default BoxSettingsUploadContact;
