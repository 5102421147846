import React from 'react'
import { useHistory } from "react-router-dom";

const ToggleContactLink = ({ title, Subtitle = '', SubTitle2 = '', plus = 'false' }) => {
  let history = useHistory();

  const nextScreen = () => {
    history.push("/select-channel");
  };
  return (
    <>
      {plus && <div className="flex items-center cursor-pointer py-3" onClick={nextScreen}>
        <figure className="bg-grey-90 flex justify-center items-center" style={{ width: 48, height: 42, borderRadius: "50%" }}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 1V11"
              stroke="#005C6F"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M11 6L1 6"
              stroke="#005C6F"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </figure>
        <p className="ml-4 text-15 font-semibold text-primary truncate">{title}</p>
      </div>}
      {Subtitle && <h2 className='text-13 font-medium text-grey-700'>{Subtitle}</h2>}
      {SubTitle2 && <h2 className='text-13 font-medium text-grey-700'>{SubTitle2}</h2>}
    </>
  );
}

export default ToggleContactLink;