import React from 'react';
import iconTeamAccess from "../../assets/icons/Group13230.svg";
import iconAutomate from "../../assets/icons/Group13224.svg";
import iconSetup from '../../assets/icons/icon-channels-setup.svg';
import iconBoxConnection from '../../assets/icons/icon-channels-box-connection.svg';

const ChannelsNavLinks = ({setActiveScreen, activeScreen}) => {
	return (
		<ul>
			<li className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ${activeScreen === 1 ? 'active rounded-lg' : ''}`} onClick={() => setActiveScreen(1)}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconTeamAccess} alt='team-access' />
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Team access
						</h3>
						<p className="general-settings__card-list__desc">
							Manage who has access
						</p>
					</div>
				</div>
			</li>
			<li className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ${activeScreen === 2 ? 'active rounded-lg' : ''}`} onClick={() => setActiveScreen(2)}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconBoxConnection} alt='team-access' />
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Box connection
						</h3>
						<p className="general-settings__card-list__desc">
							Connect channel to a box
						</p>
					</div>
				</div>
			</li>
			<li className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ${activeScreen === 3 ? 'active rounded-lg' : ''}`} onClick={() => setActiveScreen(3)}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconAutomate} alt='automate-stuff' />
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Automate stuff
						</h3>
						<p className="general-settings__card-list__desc">
							Replies, Moving stuff {"&"} more
						</p>
					</div>
				</div>
			</li>
			<li className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm mt-2 ${activeScreen === 4 ? 'active rounded-lg' : ''}`} onClick={() => setActiveScreen(4)}>
				<div className="flex items-center gap-4 flex-1 truncate">
					<figure className="general-settings__card-list__avatar-container">
						<img src={iconSetup} alt='team-access' />
					</figure>
					<div className="truncate pr-4">
						<h3 className="general-settings__card-list__title">
							Setup
						</h3>
						<p className="general-settings__card-list__desc">
							Disconnect, Delete, Rename etc
						</p>
					</div>
				</div>
			</li>
		</ul>
	)
}

export default ChannelsNavLinks