import React from "react";

const ToggleSwitchOnOff = () => {
  return (
    <div className="toggle-switch" style={{ width: 90 }}>
      <input
        type="checkbox"
        id="toggleSwitchButton"
        className="toggle-switch__checkbox"
      />
      <label for="toggleSwitchButton" className="toggle-switch__label">
        <span className="toggle-switch__item">On</span>
        <span className="toggle-switch__item">Off</span>
      </label>
    </div>
  );
};

export default ToggleSwitchOnOff;
