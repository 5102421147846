import React from 'react'

const BoxConnection = ({ data }) => {
	return (
		<div>
			<div className='text-center'>
				<div className='font-semibold text-2xl'>Connect this channel to a box</div>
				<div className='mt-3 text-grey-800 text-15'>Connect channels to this box to automatically send enquiries from a channel in here.
					When you connect a channel, it will no longer show on the channel list but don’t worry, the connection is still there.
				</div>
			</div>
			<div className='card-box px-6 py-4 mt-10'>
				<div className='font-semibold text-lg'>Boxes</div>
				<ul className='mt-3'>
					{data.map((ele, index) => {
						return (
							<li className='connect-channels-list bg-color-on-hover' key={index}>
								<div className='flex items-center'>
									<img src={ele.icon} alt='icon' style={{ width: 22, height: 22, marginRight: 10 }} />
									<div className='text-15 font-semibold'>{ele.title}</div>
								</div>
								<div className="toggle-slider">
									<input
										type="checkbox"
										id={ele.id}
										className="toggle-slider__checkbox"
										name="checkedA"
									/>
									<label
										htmlFor={ele.id}
										className="toggle-slider__label"
									>
										<code className="toggle-slider__ball toggle-slider__ball__small"></code>
									</label>
								</div>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

export default BoxConnection