import React, { useState } from 'react';
import Image1 from '../assets/icons/icon-uploaded-images1.svg';
import Image2 from '../assets/icons/icon-uploaded-images2.svg';
import Image3 from '../assets/icons/icon-uploaded-images3.svg';
import Image4 from '../assets/icons/icon-uploaded-images4.svg';
import docsIcon from '../assets/icons/icon-uploaded-docs.svg';
import wordIcon from '../assets/icons/icon-uploaded-word.svg';
import pdfIcon from '../assets/icons/icon-uploaded-pdf.svg';
import moreIcon from '../assets/icons/icon-more-icon.svg';
import previewIcon from '../assets/icons/icon-Preview.svg';
import downloadIcon from '../assets/icons/icon-download.svg';
import copyIcon from '../assets/icons/icon-Copy.svg';
import deleteIcon from '../assets/icons/icon-Delete.svg';

const Files = ({setShowImageModal, setOpenUpload}) => {
	const [showMoreDropdown, setShowMoreDropdown] = useState(0);

	return (
		<div className='files'>
			<div className='files__header'>
				<div className='flex justify-between'>
					<div className='font-semibold'>Images</div>
					<div className='text-primary font-medium text-13 cursor-pointer' onClick={() => setOpenUpload(true)}>+ Upload file</div>
				</div>
				<div className='flex mt-2'>
					<img src={Image1} alt='' className='mr-1 cursor-pointer' style={{ width: 95, height: 98 }} onClick={() => setShowImageModal(1)} />
					<img src={Image2} alt='' className='mr-1 cursor-pointer' style={{ width: 95, height: 98 }} onClick={() => setShowImageModal(2)} />
					<img src={Image3} alt='' className='mr-1 cursor-pointer' style={{ width: 95, height: 98 }} onClick={() => setShowImageModal(3)} />
					<img src={Image4} alt='' className='mr-1 cursor-pointer' style={{ width: 95, height: 98 }} onClick={() => setShowImageModal(4)} />
					<div className='files__more'>
						<div>
							<p className='font-medium text-lg'>10+</p>
							<p className='font-medium text-xs cursor-pointer txt-blue'>More</p>
						</div>
					</div>
				</div>
			</div>
			<div className='files__body'>
				<div className='pl-15 mb-3 font-semibold'>Documents</div>
				<div className='flex files__card items-center justify-between'>
					<div className='flex items-center'>
						<div>
							<img src={docsIcon} alt='' style={{ width: 25, height: 32 }} />
						</div>
						<div className='ml-3 text-sm font-medium'>Fixed assets.docx</div>
					</div>
					<div className='relative flex items-center files__card--right-side opacity-0 invisible cursor-pointer' onClick={() => setShowMoreDropdown(1)}>
						<img src={moreIcon} alt='' style={{ width: 25, height: 32 }} />
						<div className={`dropdown-menu dropdown-menu--more ${showMoreDropdown === 1 ? 'open' : ''}`}>
							<div className="dropdown-menu__list">
								<img src={previewIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />
								Preview</div>
							<div className="dropdown-menu__list">
								<img src={downloadIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Download</div>
							<div className="dropdown-menu__list">
								<img src={copyIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Copy link</div>
							<div className="dropdown-menu__list red-text">
								<img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Delete</div>
						</div>
					</div>
				</div>
				<div className='flex files__card justify-between'>
					<div className='flex items-center'>
						<div>
							<img src={wordIcon} alt='' style={{ width: 25, height: 32 }} />
						</div>
						<div className='ml-3 text-sm font-medium'>Expense receipts.docx</div>
					</div>
					<div className='relative flex items-center files__card--right-side opacity-0 invisible cursor-pointer' onClick={() => setShowMoreDropdown(2)}>
						<img src={moreIcon} alt='' style={{ width: 25, height: 32 }} />
						<div className={`dropdown-menu dropdown-menu--more ${showMoreDropdown === 2 ? 'open' : ''}`}>
							<div className="dropdown-menu__list">
								<img src={previewIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />
								Preview</div>
							<div className="dropdown-menu__list">
								<img src={downloadIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Download</div>
							<div className="dropdown-menu__list">
								<img src={copyIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Copy link</div>
							<div className="dropdown-menu__list red-text">
								<img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Delete</div>
						</div>
					</div>
				</div>
				<div className='flex files__card items-center'>
					<div>
						<img src={pdfIcon} alt='' style={{ width: 25, height: 32 }} />
					</div>
					<div className='ml-3 text-sm font-medium'>Purchase.pdf</div>
				</div>
				<div className='flex files__card items-center'>
					<div>
						<img src={docsIcon} alt='' style={{ width: 25, height: 32 }} />
					</div>
					<div className='ml-3 text-sm font-medium'>Fixed assets.docx</div>
				</div>
				<div className='flex files__card items-center'>
					<div>
						<img src={docsIcon} alt='' style={{ width: 25, height: 32 }} />
					</div>
					<div className='ml-3 text-sm font-medium'>Fixed assets.docx</div>
				</div>
				<div className='flex files__card items-center'>
					<div>
						<img src={docsIcon} alt='' style={{ width: 25, height: 32 }} />
					</div>
					<div className='ml-3 text-sm font-medium'>Fixed assets.docx</div>
				</div>
				<div className='flex files__card items-center'>
					<div>
						<img src={docsIcon} alt='' style={{ width: 25, height: 32 }} />
					</div>
					<div className='ml-3 text-sm font-medium'>Fixed assets.docx</div>
				</div>
				<div className='flex files__card items-center'>
					<div>
						<img src={docsIcon} alt='' style={{ width: 25, height: 32 }} />
					</div>
					<div className='ml-3 text-sm font-medium'>Fixed assets.docx</div>
				</div>
			</div>
		</div>
	)
}

export default Files