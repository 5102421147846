import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/eb-logo.svg";
import iconSearch from "../assets/icons/icon-search.svg";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconAvatar from "../assets/icons/icon-avatar.svg";
import contactFormPlaceholder from "../assets/images/contact-form-placeholder.png";
import avatarPic from "../assets/images/avatar-pic.png";
import avatarPic1 from "../assets/icons/icon-avatar.svg";
import tourVideoPlaceholderPic from "../assets/images/product-tour-placeholder.png";
import iconGeneral from "../assets/icons/icon-table-tennis.svg";
import iconSales from "../assets/icons/icon-suitcase.svg";
import { useHistory } from "react-router-dom";
import HelperVideo from "./Common/HelperVideo";

const Dashboard = () => {
  const [modalShow, hideModalShow] = useState(true);
  let history = useHistory();

  const nextScreen = () => {
    history.push("/dashboard-website-chatbot");
  };
  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <div className="dashboard__sidebar__links">
          <div className="dashboard__sidebar__logo">
            <img
              src={logo}
              alt="eb-logo"
              className="mx-auto"
              style={{ height: 31 }}
            />
          </div>

          <ul className="flex items-center justify-center flex-col mt-8">
            <li className="dashboard__sidebar__links__list active">
              <svg width="22" height="23" viewBox="0 0 22 23" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.25 0.75C2.79822 0.75 0 3.54822 0 7V10.4223V13V15.625V16.25C0 19.4183 2.35752 22.036 5.41405 22.4446C5.92363 22.5626 6.45453 22.625 7 22.625H14.7461C15.2897 22.625 15.8188 22.563 16.3268 22.4458C19.3878 22.0413 21.75 19.4215 21.75 16.25V7C21.75 3.54822 18.9518 0.75 15.5 0.75H6.25ZM19.25 11.8975V7C19.25 4.92893 17.5711 3.25 15.5 3.25H6.25C4.17893 3.25 2.5 4.92893 2.5 7V11.9377C2.5683 12.5356 3.07601 13 3.69219 13H5.74297C6.72077 13 7.55522 13.707 7.71581 14.6715L7.91558 15.8714C8.03603 16.5948 8.66186 17.125 9.39521 17.125H12.3543C13.0876 17.125 13.7134 16.5949 13.8339 15.8716L14.034 14.6712C14.1947 13.7068 15.0291 13 16.0068 13H18.0539C18.6838 13 19.2004 12.5147 19.25 11.8975Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 0H16C19.3137 0 22 2.68629 22 6V16C22 19.3137 19.3137 22 16 22H6C2.68629 22 0 19.3137 0 16V6C0 2.68629 2.68629 0 6 0ZM12.25 6.1875C12.25 5.49714 11.6904 4.9375 11 4.9375C10.3096 4.9375 9.75 5.49714 9.75 6.1875V15.8125C9.75 16.5029 10.3096 17.0625 11 17.0625C11.6904 17.0625 12.25 16.5029 12.25 15.8125V6.1875ZM5.95837 11.8125C6.64873 11.8125 7.20837 12.3721 7.20837 13.0625V15.8125C7.20837 16.5029 6.64873 17.0625 5.95837 17.0625C5.26802 17.0625 4.70837 16.5029 4.70837 15.8125V13.0625C4.70837 12.3721 5.26802 11.8125 5.95837 11.8125ZM17.2916 10.3125C17.2916 9.62214 16.732 9.0625 16.0416 9.0625C15.3513 9.0625 14.7916 9.62214 14.7916 10.3125V15.8125C14.7916 16.5029 15.3513 17.0625 16.0416 17.0625C16.732 17.0625 17.2916 16.5029 17.2916 15.8125V10.3125Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
                <path
                  d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list dashboard__sidebar__links__list--icon-leaf">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7.45459 12C7.45459 14.5104 9.48966 16.5455 12 16.5455C14.5104 16.5455 16.5455 14.5104 16.5455 12"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C10.13 2 6.79667 2 2 2C2 6.85477 2 10.1881 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
                <path
                  d="M7.92334 2C12.914 2.82392 15.788 5.64803 16.5454 10.4723C16.5904 10.9471 16.613 11.2373 16.613 11.3428"
                  stroke="#2B3639"
                  stroke-width="2.5"
                />
              </svg>
            </li>
          </ul>

          <ul className="flex items-center justify-center flex-col mt-auto">
            <li className="dashboard__sidebar__links__list">
              <svg width="24" height="24" viewBox="0 0 30 30" fill="none">
                <rect
                  x="4"
                  y="4"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="4"
                  y="16.2222"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="16.2222"
                  y="4"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
                <rect
                  x="16.2222"
                  y="16.2222"
                  width="9.77778"
                  height="9.77778"
                  rx="3"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list">
              <svg width="19" height="20" viewBox="0 0 21 22" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.96925 1.14013C7.30091 0.443651 8.00356 0 8.77497 0H11.5079C12.2793 0 12.982 0.443651 13.3136 1.14013L13.7417 2.03904H18.9415C19.6823 2.03904 20.2829 2.63963 20.2829 3.3805C20.2829 4.12137 19.6823 4.72196 18.9415 4.72196H1.34147C0.600596 4.72196 0 4.12137 0 3.3805C0 2.63963 0.600594 2.03904 1.34146 2.03904H6.5412L6.96925 1.14013ZM18.1903 6.22439H2.09271L3.42742 19.3045C3.58364 20.8355 4.87299 22 6.41192 22H13.8711C15.41 22 16.6993 20.8355 16.8556 19.3045L18.1903 6.22439Z"
                  fill="#2B3639"
                />
              </svg>
            </li>
            <li className="dashboard__sidebar__links__list--profile-avatar">
              <figure
                className="avatar-container avatar-container--full"
                style={{ width: 36, height: 36 }}
              >
                <img src={avatarPic} alt="" className="avatar-pic" />
              </figure>
            </li>
          </ul>
        </div>
        <div className="dashboard__sidebar__expanded-section">
          <h2 className="text-2xl font-semibold text-grey-900">Enquirybox</h2>
          <div className="flex items-center mt-5 pb-18 border-b border-grey-400">
            <div className="flex items-center">
              <figure
                className="avatar-container avatar-container--contain"
                style={{
                  width: 32,
                  height: 32,
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={avatarPic1} alt="" />
              </figure>
              <p className="ml-3 text-base-1 font-semibold text-grey-900">
                Enquiry Assistant
              </p>
            </div>
            <div
              className="flex items-center justify-center text-xs text-white font-semibold bg-red-900 px-2 rounded-full ml-auto"
              style={{ height: 20 }}
            >
              3
            </div>
          </div>
          <div className="">
            <div className="flex items-center justify-between pt-4 pb-2">
              <h3 className="text-lg font-semibold text-grey-900">Boxes</h3>
              <img src={iconPlus} alt="" className="cursor-pointer" />
            </div>
            <ul className="flex flex-col">
              <li className="dashboard__sidebar__nav-list">
                <img src={iconSales} alt="icon-sales" />
                <span className="text-base-1 font-medium text-grey-900">
                  Sales
                </span>
              </li>
              <li className="dashboard__sidebar__nav-list">
                <img src={iconGeneral} alt="icon-general" />
                <span className="text-base-1 font-medium text-grey-900">
                  Genaral
                </span>
              </li>
            </ul>
          </div>
          <div className="bg-grey-400 my-6" style={{ height: 1 }}></div>
          <div>
            <div className="flex items-center justify-between pb-2">
              <h3 className="text-lg font-semibold text-grey-900">Channels</h3>
              <img src={iconPlus} alt="" />
            </div>
            <ul className="">
              <Link
                to="/dashboard-website-chatbot"
                className="dashboard__sidebar__nav-list active"
              >
                <figure className="">
                  <svg width="18" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M18 8.13559V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V8.13559H18ZM15.5 3C16.8807 3 18 4.11929 18 5.5V6.91525H2V5.5C2 4.11929 3.11929 3 4.5 3H15.5ZM4.44444 4.27119C4.07625 4.27119 3.77778 4.57852 3.77778 4.95763C3.77778 5.33674 4.07625 5.64407 4.44444 5.64407C4.81263 5.64407 5.11111 5.33674 5.11111 4.95763C5.11111 4.57852 4.81263 4.27119 4.44444 4.27119ZM6.66667 4.27119C6.29848 4.27119 6 4.57852 6 4.95763C6 5.33674 6.29848 5.64407 6.66667 5.64407C7.03486 5.64407 7.33333 5.33674 7.33333 4.95763C7.33333 4.57852 7.03486 4.27119 6.66667 4.27119Z"
                      fill="#4C5254"
                    />
                  </svg>
                </figure>
                <p className="text-base-1 font-medium text-grey-900">
                  Website Chatbot
                </p>
              </Link>
              <Link
                to="/dashboard-contact-form"
                className="dashboard__sidebar__nav-list"
              >
                <figure className="">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M10.6055 9.49421C13.2942 9.49421 15.5146 11.4855 15.9735 14.0953C15.9871 14.1722 15.9958 14.3016 15.9997 14.4838C16.0174 15.3034 15.3631 15.9821 14.5382 15.9997C14.5277 15.9999 14.517 16 14.5063 16H1.49377C0.668785 16 0 15.3354 0 14.5156L0.000390732 14.4819C0.00432247 14.3098 0.0126196 14.1871 0.0252673 14.1138C0.464545 11.5666 2.58107 9.60417 5.17632 9.49868L5.39646 9.49421C6.26365 9.98107 7.13149 10.2245 8 10.2245C8.86851 10.2245 9.73702 9.98107 10.6055 9.49421ZM11.0018 1.24219C12.6685 2.89846 12.6685 5.58379 11.0018 7.24007C9.33508 8.89633 6.63286 8.89633 4.96617 7.24007C3.29947 5.5838 3.29947 2.89847 4.96617 1.24219C6.63285 -0.414065 9.33507 -0.414065 11.0018 1.24219Z"
                      fill="#4C5254"
                    />
                  </svg>
                </figure>
                <p className="text-base-1 font-medium text-grey-900">
                  Contact Form
                </p>
              </Link>
              <Link
                to="/dashboard-instagram"
                className="dashboard__sidebar__nav-list"
              >
                <figure className="">
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 3.5V10.5C0 12.433 1.59188 14 3.55556 14H12.4444C14.4081 14 16 12.433 16 10.5V3.5C16 1.567 14.4081 0 12.4444 0H3.55556C1.59188 0 0 1.567 0 3.5ZM3.3009 2.82049C2.93328 2.57031 2.43658 2.67171 2.1915 3.04699C1.94642 3.42228 2.04576 3.92932 2.41338 4.17951L6.9223 7.24808C7.5749 7.69221 8.4251 7.69221 9.0777 7.24808L13.5866 4.17951C13.9543 3.92932 14.0536 3.42228 13.8085 3.04699C13.5634 2.67171 13.0667 2.57031 12.6991 2.82049L8.19018 5.88905C8.07502 5.96743 7.92498 5.96743 7.80982 5.88905L3.3009 2.82049Z"
                      fill="#4C5254"
                    />
                  </svg>
                </figure>
                <p className="text-base-1 font-medium text-grey-900">
                  Enquiries Email
                </p>
                <div className="ml-auto flex items-center gap-2">
                  <span className="text-base-1 font-medium text-grey-600">
                    8
                  </span>
                </div>
              </Link>
              <Link
                to="/dashboard-facebook"
                className="dashboard__sidebar__nav-list"
              >
                <figure className="">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                      fill="#4C5254"
                    />
                  </svg>
                </figure>
                <p className="text-base-1 font-medium text-grey-900">
                  Facebook Page
                </p>
              </Link>
              <Link
                to="/dashboard-instagram"
                className="dashboard__sidebar__nav-list"
              >
                <figure className="">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
                      fill="#4C5254"
                    />
                  </svg>
                </figure>
                <p className="text-base-1 font-medium text-grey-900">
                  Instagram Account
                </p>
              </Link>
            </ul>
          </div>
        </div>

        <section className="dashboard__main">
          <div className="dashboard__main__header">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <figure className="avatar-container">
                  <svg width="28" height="26" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M18 8.13559V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V8.13559H18ZM15.5 3C16.8807 3 18 4.11929 18 5.5V6.91525H2V5.5C2 4.11929 3.11929 3 4.5 3H15.5ZM4.44444 4.27119C4.07625 4.27119 3.77778 4.57852 3.77778 4.95763C3.77778 5.33674 4.07625 5.64407 4.44444 5.64407C4.81263 5.64407 5.11111 5.33674 5.11111 4.95763C5.11111 4.57852 4.81263 4.27119 4.44444 4.27119ZM6.66667 4.27119C6.29848 4.27119 6 4.57852 6 4.95763C6 5.33674 6.29848 5.64407 6.66667 5.64407C7.03486 5.64407 7.33333 5.33674 7.33333 4.95763C7.33333 4.57852 7.03486 4.27119 6.66667 4.27119Z"
                      fill="#2B3639"
                    />
                  </svg>
                </figure>
                <div className="ml-5">
                  <h3 className="text-grey-900 text-xl font-bold">
                    Website Chatbot
                  </h3>
                  <p className="text-base-1 font-medium text-primary">
                    Hide this Channel for now
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="search-container">
                  <img
                    src={iconSearch}
                    alt="icon-search"
                    className="cursor-pointer"
                    style={{ height: 18, width: 18 }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left">
              <h2 className="text-lg font-semibold text-grey-900 ">Messages</h2>
              <div className="mt-15">
                <label className="text-xs font-medium text-grey-700 uppercase">
                  New
                </label>
                <ul className="">
                  <li
                    className="bg-color-on-hover flex items-center py-2 hover:bg-grey-90 cursor-pointer active"
                    style={{ borderRadius: 10 }}
                  >
                    <figure
                      className="avatar-container avatar-container--full bg-white"
                      style={{
                        width: 48,
                        height: 48,
                        border: "1px solid rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <img src={iconAvatar} className="" />
                    </figure>
                    <div className="ml-4 truncate">
                      <h3 className="font-bold text-grey-900 truncate">
                        Enquiry Assistant
                      </h3>
                      <p className="text-base-1 text-grey-800 truncate">
                        Connect your Contact Form
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dashboard__main__body__section-right layout-1">
              <div className="card-container--new border-radius-14">
								<div>
									<h3 className="text-grey-900 font-semibold">
										Connect a Website chatbot
									</h3>
								</div>              
								<div className="mt-4">
									<HelperVideo />
								</div>
                {/* <div className="video-container mt-4">
                  <img
                    src={contactFormPlaceholder}
                    alt=""
                    className="w-full h-full"
                  />
                </div> */}
                <div className="flex items-center justify-between gap-4 mt-8">
                  <button className="btn btn--grey btn--sm w-6/12">
                    Change questions
                  </button>
                  <button className="btn btn--primary btn--sm w-6/12" onClick={nextScreen}>
                    See it on your website
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* <ProductTourVideo /> */}
      {modalShow && (
        <div className="modal show">
          <div className="modal__container">
            <div className="modal__body" style={{padding: "30px 35px 35px"}}>
              <div>
                <div className="text-center m-auto" style={{ width: 450 }}>
                  <h1 className="text-2xl text-primary font-bold"  >
                    Welcome to your Enquirybox
                  </h1>
                  <p className="text-sm text-grey-800 mt-4">
                    Product tours are boring! This 1 minute video is not 😎
                    Learn everything you need to get organised and make more
                    sales.
                  </p>
                </div>
								<div className="mt-9">
									<HelperVideo />
								</div>							
                {/* <img src={tourVideoPlaceholderPic} alt="" className="mt-8 mx-auto"/> */}
                <button
                  className="btn btn--text text-base-1 mt-8 text-center mx-auto"
                  onClick={() => hideModalShow(false)}
                >
                  Watch it later
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Dashboard;
