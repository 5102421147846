import React from 'react';
import iconMyProfile from "../../assets/icons/icon-my-profile.svg";
import iconTeamMembers from "../../assets/icons/icon-team-members.svg";
import iconSubscription from "../../assets/icons/icon-subscription-billing.svg";
import iconQuestions from "../../assets/icons/icon-add-manage-questions.svg";
import iconIntegrations from "../../assets/icons/icon-integrations.svg";
import iconBusiness from "../../assets/icons/icon-business-info.svg";
import iconReplyTemplate from '../../assets/icons/icon-global-settings-reply-template.svg';
import iconLabels from '../../assets/icons/icon-settings-labels.svg';
import { Link } from "react-router-dom";

const GlobelNavLinks = ({ setActiveScreen, activeScreen }) => {
	const NavLinks = [
		{
			label: 'My profile',
			desc: "Login details, email signature",
			icon: iconMyProfile,
			route: '/global-settings-my-profile'
		},
		{
			label: 'Team members',
			desc: "Invite new, manage access",
			icon: iconTeamMembers,
			route: '/global-settings-team-members'

		}, 
		{
			label: 'Labels',
			desc: "Add new, manage labels",
			icon: iconLabels,
			route: '/global-settings-labels'
		},
		{
			label: 'Subscription & billing',
			desc: "Invoices, payment method, upgrades",
			icon: iconSubscription,
			route: '/'

		}, {
			label: 'Questions',
			desc: "Add and manage questions",
			icon: iconQuestions,
			route: '/global-settings-single-questions'
		},
		{
			label: 'Integrations',
			desc: "Manage third party Integrations",
			icon: iconIntegrations,
			route: '/' 
		},
		{
			label: 'Business info',
			desc: "Opening hours, logo & branding",
			icon: iconBusiness,
			route: '/global-settings-business-info'
		},
		{
			label:'Reply templates',
			desc: "Frequently sent templates for replies",
			icon: iconReplyTemplate,
			route: '/global-settings-reply-template' 
		}
	]
	return (
		<ul>
			{NavLinks.map((item, index) => {
				return (
					<Link to={item.route} className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm block ${activeScreen === index ? 'active' : ''}`}>
						<div className="flex items-center gap-4 flex-1 truncate">
							<figure className="general-settings__card-list__avatar-container">
								<img src={item.icon} alt='' />
							</figure>
							<div className="truncate pr-4">
								<h2 className="general-settings__card-list__title">
									{item.label}
								</h2>
								<p className="general-settings__card-list__desc">
									{item.desc}
								</p>
							</div>
						</div>
					</Link>
				);
			})}
		</ul>
	)
}

export default GlobelNavLinks;