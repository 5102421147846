import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import modalCloseIcon from "../../assets/icons/icon-modal-close.svg";
import EmojiModal from './EmojiModal';

const BoxInfo = ({setOpenBoxInfo, openBoxInfo, setOpenBoxTemplate}) => {
	const [openEmoji, setOpenEmoji] = useState(false);

	return (
		<div className={`modal modal--CustomBox ${openBoxInfo ? 'show':''}`}>
			<div className="modal__container">
				<div className="modal__header">
					<h2 className="modal__heading pr-5">Add a box</h2>
					<div className="modal__close-icon" onClick={() => setOpenBoxInfo(false)}>
						<img src={modalCloseIcon} alt="" style={{ width: 10, height: 10 }} />
					</div>
				</div>
				<div className="modal__body" style={{ padding: '25px 30px 30px' }}>
					<div>
						<label className="input-field-label">Box name</label>
						<input
							className="input-field input-field--md"
							placeholder="Give it a name that reflects what you will put in it"
						/>
					</div>
					<div className="mt-25">
						<label className="input-field-label">Add an icon </label>
						<input
							className="input-field input-field--select input-field--md relative"
							placeholder="Select the icon"
							onClick={() => setOpenEmoji(!openEmoji)}
						/>
						{openEmoji && <EmojiModal hideSearch={true} className="mt-1" setOpenEmoji={setOpenEmoji} />}
					</div>
					<div className="flex items-center justify-end gap-3" style={{ marginTop: 60 }}>
						<button
							className="btn btn--grey btn--sm w-1/4"
						>
							Cancel
						</button>
						<Link to="/box-template" className="btn btn--primary btn--sm w-1/4" onClick={() => {
							setOpenBoxTemplate(true)
							setOpenBoxInfo(false)
							}}>
							Add
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BoxInfo