import React from "react";

const ToggleHeaderNew = ({ setIsActive, isActive }) => {
  return (
    <div className="header-toggle">
      <div
        className={`header-toggle__switch ${
          isActive === 1 ? "header-toggle__switch--active" : ""
        }`}
        onClick={() => setIsActive(1)}
        style={{ paddingLeft: 29 }}
      >
        {isActive === 1 ? (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="10"
              height="10"
              rx="2.5"
              stroke="white"
              stroke-width="1.2"
            />
            <path
              d="M1 5V5C1 5.86558 1.62578 6.6043 2.47959 6.7466L3.35327 6.89221C3.74598 6.95766 4.06246 7.24983 4.15902 7.63607L4.25382 8.0153C4.3985 8.59401 4.91848 9 5.51501 9H6.48499C7.08152 9 7.6015 8.59401 7.74618 8.0153L7.84098 7.63607C7.93754 7.24983 8.25402 6.95766 8.64673 6.89221L9.52041 6.7466C10.3742 6.6043 11 5.86558 11 5V5"
              stroke="white"
              stroke-width="1.2"
            />
          </svg>
        ) : (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="10"
              height="10"
              rx="2.5"
              stroke="#4C5254"
              stroke-width="1.2"
            />
            <path
              d="M1 5V5C1 5.86558 1.62578 6.6043 2.47959 6.7466L3.35327 6.89221C3.74598 6.95766 4.06246 7.24983 4.15902 7.63607L4.25382 8.0153C4.3985 8.59401 4.91848 9 5.51501 9H6.48499C7.08152 9 7.6015 8.59401 7.74618 8.0153L7.84098 7.63607C7.93754 7.24983 8.25402 6.95766 8.64673 6.89221L9.52041 6.7466C10.3742 6.6043 11 5.86558 11 5V5"
              stroke="#4C5254"
              stroke-width="1.2"
            />
          </svg>
        )}
        <span
          className={`text-sm ml-1 font-medium ${
            isActive === 1 ? "text-white" : "text-grey-800"
          }`}
        >
          Ebox
        </span>
      </div>
      <div
        className={`header-toggle__switch ${
          isActive === 2 ? "header-toggle__switch--active" : ""
        }`}
        onClick={() => setIsActive(2)}
      >
        {isActive === 2 ? (
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 13.0001C1 11.1581 2.49325 9.66485 4.33525 9.66485H8.16475C10.0068 9.66485 11.5 11.1581 11.5 13.0001"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.50391 1.93364C9.7487 3.17843 9.7487 5.19665 8.50391 6.44144C7.25911 7.68624 5.2409 7.68624 3.9961 6.44144C2.7513 5.19665 2.7513 3.17843 3.9961 1.93364C5.2409 0.688839 7.25911 0.688839 8.50391 1.93364"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 13.0001C1 11.1581 2.49325 9.66485 4.33525 9.66485H8.16475C10.0068 9.66485 11.5 11.1581 11.5 13.0001"
              stroke="#4C5254"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.50391 1.93364C9.7487 3.17843 9.7487 5.19665 8.50391 6.44144C7.25911 7.68624 5.2409 7.68624 3.9961 6.44144C2.7513 5.19665 2.7513 3.17843 3.9961 1.93364C5.2409 0.688839 7.25911 0.688839 8.50391 1.93364"
              stroke="#4C5254"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        <span
          className={`text-sm ml-1 font-medium ${
            isActive === 2 ? "text-white" : "text-grey-800"
          }`}
        >
          Contacts
        </span>
      </div>
      <div
        className={`header-toggle__switch ${
          isActive === 3 ? "header-toggle__switch--active" : ""
        }`}
        onClick={() => setIsActive(3)}
      >
        {isActive === 3 ? (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="10"
              height="10"
              rx="2.5"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 8.1875V3.8125"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.70801 8.1875V6.9375"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.29199 8.1875V5.6875"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="10"
              height="10"
              rx="2.5"
              stroke="#4C5254"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 8.1875V3.8125"
              stroke="#4C5254"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.70801 8.1875V6.9375"
              stroke="#4C5254"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.29199 8.1875V5.6875"
              stroke="#4C5254"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        <span
          className={`text-sm ml-1 font-medium ${
            isActive === 3 ? "text-white" : "text-grey-800"
          }`}
        >
          Reports
        </span>
      </div>
    </div>
  );
};

export default ToggleHeaderNew;
