import React, {useState} from 'react';
import SidebarExpanded from "./Common/SidebarExpanded";
import SidebarLinks from "./Common/SidebarLinks";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import TeamAccess from './TeamAccess';
import BoxConnection from './BoxConnection';
import iconEmojiFire from "../assets/icons/icon-emoji-fire.svg";
import iconRocket from "../assets/icons/icon-emoji-rocket.svg";
import iconBall from "../assets/icons/icon-emoji-ball.svg";
import AutomateStuff from './AutomateStuff';
import AutomateOptionIcon from '../assets/icons/icon-automate-option.svg';
import iconRename from '../assets/icons/icon-edit-white.svg';
import iconDelete from '../assets/icons/icon-delete-white.svg';
import iconFbConnected from '../assets/icons/icon-fb-channel-connected.svg';
import iconEmailConnected from '../assets/icons/icon-email-channel-connected.svg';
import SetUp from './SetUp';
import ChannelsNavLinks from './Common/ChannelsNavLinks';


const users = [
	{
		id: 1,
		name: "John Leon",
	},
	{
		id: 2,
		name: "Madison Inouye",
	},
	{
		id: 3,
		name: "Olivia Rhye",
	},
	{
		id: 4,
		name: "Charles Wundeng",
	},
	{
		id: 5,
		name: "Lisa Fotios",
	},
];

const boxConnected = [
	{
		id: 1,
		icon: iconEmojiFire,
		title: 'Potential Leads'
	},
	{
		id: 2,
		icon: iconRocket,
		title: 'Marketing Leads'
	},
	{
		id: 3,
		icon: iconBall,
		title: 'Product Enquiries'
	}
];

const automateStuffData = [
	{
		id: 1,
		icon: AutomateOptionIcon,
		title: 'Send an email when enquiry comes into channel',
		desc: 'The automation is live and has run 47 times'
	},
	{
		id: 2,
		icon: AutomateOptionIcon,
		title: 'Assign enquiry to team member',
		desc: 'This automation is not live - flick the switch to turn it on'
	}
];

const setUpData = [
	{
		id: 1,
		title: 'Rename channel',
		icon: iconRename,
		iconTitle: 'Rename',
		isDelete: false
	},
	{
		id: 2,
		title: 'Reconnect channel',
		icon: iconFbConnected,
		iconTitle: 'Reconnect page',
		isDelete: false,
		isFb: true
	},
	{
		id: 3,
		title: 'Reconnect channel',
		icon: iconEmailConnected,
		iconTitle: 'Reconnect email',
		isDelete: false,
		isFb: false,
		isEmail: true
	},
	{
		id: 4,
		title: 'Delete channel',
		icon: iconDelete,
		iconTitle: 'Delete',
		isDelete: true
	}
]

const ChannelsSettingsTeamAccess = () => {
	const [activeScreen, setActiveScreen] = useState(0);

	return (
		<main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinks />
        <SidebarExpanded />
				<section className="dashboard__main">
          <GeneralSettingsHedder text={"Channel settings"} />
					<div className="dashboard__main__body general-settings">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <ChannelsNavLinks setActiveScreen={setActiveScreen} activeScreen={activeScreen}/>
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay p-10">
              {activeScreen === 1 && <TeamAccess data={users}/>}
							{activeScreen === 2 && <BoxConnection data={boxConnected}/>}
							{activeScreen === 3 && <AutomateStuff data={automateStuffData}/>}
							{activeScreen === 4 && <SetUp data={setUpData} />}
            </div>
          </div>
				</section>
			</section>
		</main>
	)
}

export default ChannelsSettingsTeamAccess