import React, { useState } from "react";
import GeneralSettingsHedder from "./Common/GeneralSettingsHedder";
import GlobelNavLinks from "./Common/GlobelNavlinks";
import SideBarExpandedNew from "./Common/SideBarExpandedNew";
import SidebarLinksNew from "./Common/SideBarLinksNew";
import AddLabel from "./modal/AddLabel";
import BoxInfo from "./modal/BoxInfo";
import DeleteLabel from "./modal/DeleteLabel";
import InviteModal from "./modal/InviteModal";

const GlobalSettingsLabels = () => {
  const [openBoxInfo, setOpenBoxInfo] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openBoxTemplate, setOpenBoxTemplate] = useState(false);
  const [activeScreen, setActiveScreen] = useState(0);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
	const [openEdit,setOpenEdit] = useState(false);
	const [addLabel,setAddLabel] = useState(false);
	const [openDeleteModal,setOpenDeleteModal] = useState(false)

  return (
    <main className="dashboard">
      <section className="dashboard__container flex items-start">
        <SidebarLinksNew />
        <SideBarExpandedNew
          setOpenBoxInfo={setOpenBoxInfo}
          setOpenInviteModal={setOpenInviteModal}
        />
        <section className="dashboard__main general-settings">
          <GeneralSettingsHedder text={"Account settings"} />
          <div className="dashboard__main__body">
            <div className="dashboard__main__body__section-left overflow-overlay gs-body">
              <GlobelNavLinks
                setActiveScreen={setActiveScreen}
                activeScreen={2}
              />
            </div>
            <div className="dashboard__main__body__section-right overflow-overlay p-10">
              <div className="flex flex-col items-center">
                <h2 className="text-2xl font-semibold">Labels</h2>
                <p className="text-grey-800 text-15 mt-3">
                  Add new, manage labels
                </p>
              </div>
              <div className="mt-14 px-10">
                <div className="flex justify-between">
                  <h4 className="font-semibold text-lg ml-3">Labels</h4>
                  <button className="btn btn--primary btn--xs" onClick={() => setAddLabel(true)}>
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 1V9"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M9 5L1 5"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                    <span className="text-13 font-semibold ml-2">
                      Add label
                    </span>
                  </button>
                </div>
                <div className="mt-4">
                  {!openEdit ? 
									<div className="py-3 px-3 flex justify-between items-center hover-bg-grey">
                    <span className="font-medium">Whitening</span>
                    <div
                      className="cursor-pointer relative"
                      onClick={() => setShowMoreDropdown(!showMoreDropdown)}
                    >
                      <svg
                        width="16"
                        height="4"
                        viewBox="0 0 16 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          r="1.75"
                          transform="matrix(1 0 0 -1 2.25 1.75)"
                          fill="#636566"
                        />
                        <circle
                          r="1.75"
                          transform="matrix(1 0 0 -1 8.25 1.75)"
                          fill="#636566"
                        />
                        <circle
                          r="1.75"
                          transform="matrix(1 0 0 -1 14.25 1.75)"
                          fill="#636566"
                        />
                      </svg>
                      <div
                        className={`dropdown-menu dropdown-menu--more ${
                          showMoreDropdown ? "open" : ""
                        }`}
                        style={{ top: 10 }}
                      >
                        <div className="dropdown-menu__list gap-3" onClick={() => setOpenEdit(true)}>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.27654 2.1201L9.87987 4.72378L3.74963 10.854C3.69174 10.9119 3.62154 10.956 3.54425 10.983L0.724873 11.9693C0.440795 12.0687 0.129943 11.919 0.0305655 11.6349C-0.0101924 11.5184 -0.0101883 11.3915 0.0305768 11.275L1.01692 8.45599C1.04396 8.37872 1.08806 8.30853 1.14595 8.25064L7.27654 2.1201ZM10.8501 0.379714L11.6204 1.15003C12.0928 1.62252 12.1243 2.369 11.7148 2.87799L11.6202 2.98324L10.461 4.14224L7.85729 1.5389L9.01686 0.379557C9.52317 -0.12658 10.3439 -0.12651 10.8501 0.379714Z"
                              fill="#2B3639"
                            />
                          </svg>
                          Rename
                        </div>
                        <div className="dropdown-menu__list red-text gap-3" onClick={() => setOpenDeleteModal(true)}>
                          <svg
                            width="12"
                            height="14"
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.8 4.29167L10.5884 10.787C10.5396 12.2853 9.28622 13.5 7.7997 13.5H4.2003C2.70896 13.5 1.46078 12.2962 1.41161 10.787L1.2 4.29167H10.8ZM7.5 6.35C7.19875 6.35 6.94936 6.59051 6.90651 6.90395L6.9 7V10.25L6.90785 10.3554C6.95435 10.6643 7.20177 10.9 7.5 10.9C7.80125 10.9 8.05064 10.6595 8.09349 10.3461L8.1 10.25V7L8.09215 6.89457C8.04565 6.58572 7.79823 6.35 7.5 6.35ZM4.5 6.35C4.19875 6.35 3.94936 6.59051 3.90651 6.90395L3.9 7V10.25L3.90785 10.3554C3.95435 10.6643 4.20177 10.9 4.5 10.9C4.80125 10.9 5.05064 10.6595 5.09349 10.3461L5.1 10.25V7L5.09215 6.89457C5.04565 6.58572 4.79823 6.35 4.5 6.35ZM6.68285 0.5C7.17071 0.5 7.6037 0.812534 7.75733 1.27557C7.91097 1.7386 8.34396 2.05114 8.83181 2.05114H11.1875C11.6362 2.05114 12 2.4149 12 2.86364C12 3.31237 11.6362 3.67614 11.1875 3.67614H0.8125C0.363769 3.67614 0 3.31237 0 2.86364C0 2.4149 0.363769 2.05114 0.8125 2.05114H3.20202C3.64523 2.05114 4.04133 1.78718 4.21547 1.38776L4.25733 1.27557C4.40171 0.814129 4.82915 0.5 5.31265 0.5H6.68285Z"
                              fill="#F04438"
                            />
                          </svg>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div> : 
										<div>
											<input className="input-field text-sm"/>
										</div>
									}
                  <div className="py-3 px-3 flex justify-between items-center hover-bg-grey">
                    <span className="font-medium">Invisalign</span>
                    <svg
                      width="16"
                      height="4"
                      viewBox="0 0 16 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 2.25 1.75)"
                        fill="#636566"
                      />
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 8.25 1.75)"
                        fill="#636566"
                      />
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 14.25 1.75)"
                        fill="#636566"
                      />
                    </svg>
                  </div>
                  <div className="py-3 px-3 flex justify-between items-center hover-bg-grey">
                    <span className="font-medium">Teeth straightening</span>
                    <svg
                      width="16"
                      height="4"
                      viewBox="0 0 16 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 2.25 1.75)"
                        fill="#636566"
                      />
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 8.25 1.75)"
                        fill="#636566"
                      />
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 14.25 1.75)"
                        fill="#636566"
                      />
                    </svg>
                  </div>
                  <div className="py-3 px-3 flex justify-between items-center hover-bg-grey">
                    <span className="font-medium">NHS Patient</span>
                    <svg
                      width="16"
                      height="4"
                      viewBox="0 0 16 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 2.25 1.75)"
                        fill="#636566"
                      />
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 8.25 1.75)"
                        fill="#636566"
                      />
                      <circle
                        r="1.75"
                        transform="matrix(1 0 0 -1 14.25 1.75)"
                        fill="#636566"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <InviteModal
        addTeamMember={openInviteModal}
        setAddTeamMember={setOpenInviteModal}
        hideTabs={true}
      />
      <BoxInfo
        setOpenBoxInfo={setOpenBoxInfo}
        openBoxInfo={openBoxInfo}
        setOpenBoxTemplate={setOpenBoxTemplate}
      />
			<AddLabel 
			addLabel={addLabel}
			setAddLabel={setAddLabel}
			/>
			<DeleteLabel
			openDeleteModal={openDeleteModal}
			setOpenDeleteModal={setOpenDeleteModal}
			/>
    </main>
  );
};

export default GlobalSettingsLabels;
